/**
 * Copyright © Veeam Software Group GmbH.
 */

// import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers'; - source for roles

export enum PortalUserRoles {
    Unknown = 'unknown',
    PortalAdministrator = 'vacAdministrator',
    LocationAdministrator = 'companyLocationAdministrator',
    LocationUser = 'companyLocationUser',
    CompanyOwner = 'companyOwner',
    CompanyInvoiceAuditor = 'finance',
    Subtenant = 'subtenant',
    PortalOperator = 'vacOperator',
    ServiceProviderGlobalAdministrator = 'resellerOwner',
    ServiceProviderOperator = 'resellerOperator',
    ServiceProviderUser = 'resellerUser',
    ServiceProviderInvoiceAuditor = 'resellerFinance',
    ServiceProviderAdministrator = 'resellerAdministrator',
    SiteAdministrator = 'cloudConnectAdministrator',
    CompanyAdministrator = 'companyAdministrator',
    PortalReadonlyOperator = 'vacReadonlyOperator',
    LocalAdministrator = 'localAdministrator',
}
