/**
 * Copyright © Veeam Software Group GmbH.
 */

import { observable } from 'mobx';
import { deepCopy } from '@veeam-vspc/core';
import { PulseConfigurationModel } from '@veeam-vspc/models/web-controllers';

import type { CloudConnectServerWithDeployInfo } from '@veeam-vspc/models/web-controllers';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { core } from 'core/core-module';
import { CloudConnectService } from 'core/services/cloud-connect-service/cloud-connect-service';
import { getData } from 'core/services/transport/utils/get-data';
import { PulseService } from 'core/services/pulse-service/pulse-service';
import { DataCache } from 'core/utils/data-cache';
import { CompanyService } from 'core/services/company-service';
import { UserTitleRepresentation } from 'core/enums';

import type {
    CountryModel,
    UsaStateModel,
    EmailCustomSectionData,
    ContractModel,
    AvailableBackupResource,
    ReplicaResource,
    WanAccelerator,
    UserTitleModel,
    CompanyVboServer,
    IsExistsNameOrAlias,
    IsExistsNameOrAliasParams,
    CompanyVboBackupRepository,
    CompanyVboProxy,
} from 'core/interfaces';
import type { ResellerData, VcdOrganization } from '../interfaces';

export class ResellerWizardStore {
    readonly initialData: ResellerData;
    @observable isRestAvailable = false;
    @observable emailData: EmailCustomSectionData | null = null;
    private initialEmailData: EmailCustomSectionData | null;
    @observable isRequiredSendEmail = true;
    @observable changePassword = false;
    @observable cloudBackupResourcesEnabled = false;
    @observable cloudReplicationResourcesEnabled = false;
    @observable dataTransferGbEnabled = false;
    @observable microsoft365ResourcesEnabled = false;

    @observable pulseConfiguration: PulseConfigurationModel = null;
    @observable contracts: ContractModel[] = null;
    @observable workstationAgentsEnabled = false;
    @observable serverAgentsEnabled = false;
    @observable cloudVcdResourcesEnabled = false;
    @observable hasInsiderProtection = false;

    readonly userTitles: UserTitleModel[];

    readonly availableBackupResourcesCache = new DataCache<AvailableBackupResource[],
        { cloudConnectAgentUids: string[]; }>(({ cloudConnectAgentUids }) =>
            core.transportService.request('Company/GetAvailableBackupResource', { cloudConnectAgentUids })
                .then((resp: BaseSuccessRequestResponse<{ data: AvailableBackupResource[]; }>) => resp.data.data)
        );

    readonly availableReplicaResourcesCache = new DataCache<ReplicaResource[],
        { cloudConnectAgentUids: string[]; }>(({ cloudConnectAgentUids }) =>
            core.transportService.request('Company/GetAvailableReplicationResource', { cloudConnectAgentUids })
                .then((resp: BaseSuccessRequestResponse<{ data: ReplicaResource[]; }>) => resp.data.data)
        );

    readonly cloudConnectAgentsCache = new DataCache<CloudConnectServerWithDeployInfo[], { resellerId?: number; }>(({ resellerId }) =>
        CloudConnectService.getAgents(resellerId)
    );

    readonly vcdOrganizationsCache = new DataCache<VcdOrganization[],
        { cloudConnectAgentUids: string[]; }>(({ cloudConnectAgentUids }) =>
            core.transportService.request('Reseller/GetVcdOrganization', { cloudConnectAgentUids })
                .then((resp: BaseSuccessRequestResponse<VcdOrganization[]>) => resp.data)
        );

    readonly availableVboServersCache = new DataCache<CompanyVboServer[], void>(() =>
        getData('Company/GetAvailableVboServers')
    );

    readonly availableVboBackupRepositoryCache = new DataCache<CompanyVboBackupRepository[], void>(() =>
        getData('Company/GetAvailableVboBackupRepositories')
    );

    readonly availableVboProxiesCache = new DataCache<CompanyVboProxy[], void>(() =>
        getData('Company/GetAvailableVboProxies')
    );

    readonly isExistsNameOrAliasCache = new DataCache<IsExistsNameOrAlias, IsExistsNameOrAliasParams>(params =>
        CompanyService.isExistsNameOrAlias(params)
    );

    readonly wanAcceleratorsCache = new DataCache<WanAccelerator[], { cloudConnectAgentUids: string[]; }>(({ cloudConnectAgentUids }) =>
        core.transportService.request('Company/GetWanAccelerators', { cloudConnectAgentUids })
            .then((resp: BaseSuccessRequestResponse<{ data: WanAccelerator[]; }>) => resp.data.data)
    );

    readonly countriesCache = new DataCache<CountryModel[], {}>(() =>
        getData('Company/GetCountries')
    );

    readonly usaStatesCache = new DataCache<UsaStateModel[], {}>(() =>
        getData('Company/GetUsaStates')
    );

    constructor(data: ResellerData) {
        this.initialData = deepCopy(data);

        const enumMapper = core.enumMapperService;
        this.userTitles = [
            {
                id: UserTitleRepresentation.Mr,
                name: enumMapper.getEnumDescription('userTitleRepresentation', UserTitleRepresentation.Mr),
            }, {
                id: UserTitleRepresentation.Miss,
                name: enumMapper.getEnumDescription('userTitleRepresentation', UserTitleRepresentation.Miss),
            }, {
                id: UserTitleRepresentation.Mrs,
                name: enumMapper.getEnumDescription('userTitleRepresentation', UserTitleRepresentation.Mrs),
            }, {
                id: UserTitleRepresentation.Ms,
                name: enumMapper.getEnumDescription('userTitleRepresentation', UserTitleRepresentation.Ms),
            },
        ];
    }

    checkIsRestAvailable(companyId: number): void {
        core.transportService.request('Company/IsRestAvailable', { companyId: companyId === -1 ? null : companyId })
            .then((resp: BaseSuccessRequestResponse<boolean>) => this.isRestAvailable = resp.data as boolean);
    }

    loadEmailData(organizationUid: string): void {
        if (this.emailData === null) {
            getData<{ organizationUid: string; }, EmailCustomSectionData>
            ('Reseller/GetWelcomeEmailCustomSection', { organizationUid })
                .then((data) => {
                    const addCustomText = !!data.emailContent;
                    this.emailData = { addCustomText, ...data, isDefault: false };
                    this.initialEmailData = deepCopy(this.emailData);
                });
        }
    }

    isEmailDataChanged(): boolean {
        return this.emailData !== null
            && (
                this.emailData.emailContent !== this.initialEmailData.emailContent
                || (this.initialEmailData.emailContent && !this.emailData.addCustomText)
                || (!this.initialEmailData.emailContent && this.emailData.addCustomText)
                || this.emailData.isDefault !== this.initialEmailData.isDefault
                || this.emailData.isVisibleGeneralSection !== this.initialEmailData.isVisibleGeneralSection
            );
    }

    loadPulseConfiguration(): Promise<PulseConfigurationModel> {
        if (this.pulseConfiguration === null) {
            return PulseService.getPulseConfiguration()
                .then((data) => {
                    this.pulseConfiguration = data;
                    return this.pulseConfiguration;
                });
        }

        return Promise.resolve(this.pulseConfiguration);
    }

    loadContracts(): Promise<ContractModel[]> {
        if (this.contracts === null) {
            return PulseService.getContracts()
                .then((result) => {
                    this.contracts = result['data'];
                    return this.contracts;
                });
        }

        return Promise.resolve(this.contracts);
    }
}
