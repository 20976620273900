/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { AdvancedFilterValue, FilterGroup } from '@veeam-vspc/components';

import { PublicCloudPlatformTypes } from 'core/enums';
import amazonWebServicesPlatformIconSrc from 'images/filters/cloud-platforms/aws.svg';
import microsoftAzurePlatformIconSrc from 'images/filters/cloud-platforms/azure.png';
import googlePlatformIconSrc from 'images/filters/cloud-platforms/google.svg';
import { useLang } from 'views/providers/LangProvider/hooks';

export interface UseCloudPlatformTypesAdvancedFilterProps {
    propName?: string;
}

export const useCloudPlatformTypesAdvancedFilter = (
    { propName = 'platform' }: UseCloudPlatformTypesAdvancedFilterProps = {}
): FilterGroup<AdvancedFilterValue> => {
    const lang = useLang();

    return {
        title: lang.PLATFORM,
        items: [
            {
                iconSrc: amazonWebServicesPlatformIconSrc,
                label: lang.AMAZON_WEB_SERVICES,
                value: {
                    id: PublicCloudPlatformTypes.AmazonEc2,
                    name: propName,
                },
            },
            {
                iconSrc: microsoftAzurePlatformIconSrc,
                label: lang.MICROSOFT_AZURE,
                value: {
                    id: PublicCloudPlatformTypes.AzureCompute,
                    name: propName,
                },
            },
            {
                iconSrc: googlePlatformIconSrc,
                label: lang.GOOGLE_CLOUD,
                value: {
                    id: PublicCloudPlatformTypes.Gcp,
                    name: propName,
                },
            },
        ],
    };
};
