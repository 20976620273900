/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ProtectedVirtualMachineRestoreTypes {
    Unknown = -1,
    Backup = 0,
    Replica = 1,
    Copy = 2,
    Tape = 3,
    Snapshot = 4,
    RemoteSnapshot = 5,
    CdpReplica = 6,
    PublicCloudArchive = 7,
}
