/**
 * Copyright © Veeam Software Group GmbH.
 */

import { getXmlTag } from '../../../helpers';
import { XmlFileParams } from '../../../enums';

export const checkXmlFileValidity = (xml: XMLDocument, isVbrUpgrade: boolean): boolean => {
    const mainTagQuery = isVbrUpgrade ? XmlFileParams.MainConfigUpgradeQuery : XmlFileParams.MainConfigInstallQuery;

    return Boolean(getXmlTag(xml, mainTagQuery));
};
