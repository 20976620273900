/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import worldMap from '@highcharts/map-collection/custom/world.geo.json';

import type { CompanyHealthOverviewParam } from '@veeam-vspc/models/web-controllers/companyHealthOverviewParam';
import type { GeoJSON } from 'highcharts';
import type HighchartsReact from 'highcharts-react-official';

import { WORLD_MAP_ID } from 'views/pages/OverviewPage/configs';
import { CompanyStatus } from 'views/pages/OverviewPage/interfaces';
import { createFilter } from 'views/pages/OverviewPage/helpers/create-filter';
import { checkMapLayer, MapLayer } from 'views/pages/OverviewPage/helpers/check-map-layer';
import { extractCountryId } from 'views/pages/OverviewPage/helpers/extract-country-id';
import { checkIfMapExist } from '../helpers/check-if-map-exist';
import { StatusTypes } from 'core/utils/status-helpers/enums';
import { useIntervalRequest } from 'views/hooks';

import type { Region } from 'views/pages/OverviewPage/interfaces/region';
import type { Point } from 'views/pages/OverviewPage/interfaces';
import type { UnspecifiedCompanies } from '../components/StateByRegions/components/UnspecifiedCompaniesWidget/interfaces';
import type { CompanyHealth } from 'views/pages/OverviewPage/interfaces/company-health';
import type { MapStore } from '../interfaces/map-store';

interface UseMapStoreProps {
    initialMapId: string;
    onMapChange: (mapId: string) => void;
}

export const useMapStore = ({ onMapChange, initialMapId }: UseMapStoreProps): MapStore => {
    const [mapId, setMapId] = useState(initialMapId || WORLD_MAP_ID);
    const [map, setMap] = useState<GeoJSON>(worldMap);
    const isWorldMap = mapId === WORLD_MAP_ID;
    const mapChartRef = useRef<HighchartsReact.RefObject>();
    const upButtonRef = useRef<HTMLElement>();
    const request = useIntervalRequest<CompanyHealthOverviewParam, CompanyHealth[]>(
        'Overview/AggregatedCompanyHealth',
        { filter: createFilter(mapId) }
    );
    const items = request.data as CompanyHealth[] || [];

    useEffect(() => {
        if (mapId === WORLD_MAP_ID) {
            return;
        }

        downloadMap(mapId);
    }, []);

    const emptyMapData: MapStore['series'] = {
        successSeriesData: [],
        warningSeriesData: [],
        errorSeriesData: [],
    };
    const emptyUnspecifiedCompanies: UnspecifiedCompanies = {
        total: 0,
        statuses: [],
    };

    const handleMapChange = (mapId: string) => {
        onMapChange(mapId);
    };

    const downloadMap = useCallback((mapId: string) => {
        if (!checkIfMapExist(mapId)) return;

        if (mapId === WORLD_MAP_ID) {
            setMap(worldMap);
            upButtonRef.current?.blur();
            setMapId(WORLD_MAP_ID);
            mapChartRef.current.chart.zoomOut();
        } else {
            const countryId = extractCountryId(mapId);

            fetch(`react-app/build/static/js/maps/${countryId}/${mapId}-all.geo.json`)
                .then(res => res.json() as Promise<GeoJSON>)
                .then(setMap)
                .then(() => {
                    upButtonRef.current?.blur();
                    setMapId(mapId);
                    mapChartRef.current.chart.zoomOut();
                });
        }
    }, []);

    useEffect(() => {
        request.forceRequest();
    }, [mapId]);

    const upMap = useCallback(() => {
        if (checkMapLayer(mapId) === MapLayer.Region) {
            const countryId = extractCountryId(mapId);

            downloadMap(countryId);
            onMapChange(mapId);
        } else {
            downloadMap(WORLD_MAP_ID);
            onMapChange(WORLD_MAP_ID);
        }
    }, [mapId]);

    const series: MapStore['series'] = useMemo(() => items.reduce((result, item) => {
        let key: string;

        if (item.status === CompanyStatus.Healthy) key = 'successSeriesData';
        if (item.status === CompanyStatus.Warning) key = 'warningSeriesData';
        if (item.status === CompanyStatus.Error) key = 'errorSeriesData';

        result[key].push({
            success: item.healthy.value,
            warning: item.warnings.value,
            error: item.errors.value,
            mapId: item.id,
        } as Point);

        return result;
    }, emptyMapData), [mapId, items]);

    const unspecifiedCompanies: UnspecifiedCompanies = useMemo(
        () =>
            items.reduce(
                (result, { id, errors, healthy, warnings }) => {
                    if (!id) {
                        return {
                            statuses: [
                                {
                                    status: StatusTypes.Error,
                                    count: errors.value,
                                },
                                {
                                    status: StatusTypes.Warning,
                                    count: warnings.value,
                                },
                                {
                                    status: StatusTypes.Success,
                                    count: healthy.value,
                                },
                            ],
                            total: healthy.value + errors.value + warnings.value,
                        };
                    }

                    return result;
                },
                emptyUnspecifiedCompanies
            ),
        [mapId, items]
    );

    const availableMaps = useMemo(() =>
        map.features
            .filter(feature => items.map(item => item.id).includes(feature.properties['hc-key']))
            .filter(feature => checkIfMapExist(feature.properties['hc-key']))
            .filter(feature => feature.properties['hc-group'] === 'admin0')
            .map(feature => ({
                id: feature.properties['hc-key'],
                name: feature.properties.name,
            } as Region))
            .sort((m1, m2) => m1.name.localeCompare(m2.name)),
    [items]
    );

    return {
        series,
        mapId,
        map,
        isWorldMap,
        downloadMap,
        upMap,
        availableMaps,
        mapChartRef,
        upButtonRef,
        unspecifiedCompanies,
        loading: request.loading,
        handleMapChange,
    };
};
