/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { AdvancedFilter, Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE } from '@veeam-vspc/components';

import type { AdvancedFilterOptions } from '@veeam-vspc/components';
import type { AdvancedFilterValue } from '@veeam-vspc/components/src/AdvancedFilter/interfaces/advanced-filter-value';

import serverModeIconSrc from 'images/filters/agent-job-types/server.png';
import workstationModeIconSrc from 'images/filters/agent-job-types/workstation.png';
import yesCloudCopyTypeIconSrc from 'images/filters/backup-agent-cloud-copy-types/yes.png';
import noCloudCopyTypeIconSrc from 'images/filters/backup-agent-cloud-copy-types/no.png';
import entireComputerTypeIconSrc from 'images/filters/protected-computer/backup-os-level.png';
import volumeLevelTypeIconSrc from 'images/filters/protected-computer/backup-volume-level.png';
import fileLevelTypeIconSrc from 'images/filters/protected-computer/backup-file-level.png';
import { EndpointBackupModes, VawOperationModes } from 'core/enums';
import { getStatusIconSrc } from 'core/utils';
import { StatusTypes } from 'core/utils/status-helpers/enums';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useOsTypesAdvancedFilter } from 'views/components/filters/OsTypesFilters';
import { AdvancedToolbar } from 'views/components/AdvancedToolbar';
import { FlrStatuses } from '../../../../enums';

export const ComputersFilterToolbar: React.FC<{}> = () => {
    const lang = useLang();
    const osTypesAdvancedFilter = useOsTypesAdvancedFilter();
    const advancedFilterOptions: AdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_BACKUP_AGENTS_BY}:`,
        cols: [
            {
                groups: [
                    {
                        title: capitalize(lang.OPERATION_MODE, true),
                        items: [
                            {
                                iconSrc: serverModeIconSrc,
                                label: lang.SERVER,
                                value: {
                                    id: VawOperationModes.Server,
                                    name: 'operationMode',
                                },
                            },
                            {
                                iconSrc: workstationModeIconSrc,
                                label: lang.WORKSTATION,
                                value: {
                                    id: VawOperationModes.Workstation,
                                    name: 'operationMode',
                                },
                            },
                        ],
                    },
                    {
                        title: capitalize(lang.CLOUD_COPY, true),
                        items: [
                            {
                                iconSrc: yesCloudCopyTypeIconSrc,
                                label: lang.YES,
                                value: {
                                    id: true,
                                    name: 'cloudCopy',
                                },
                            },
                            {
                                iconSrc: noCloudCopyTypeIconSrc,
                                label: lang.NO,
                                value: {
                                    id: false,
                                    name: 'cloudCopy',
                                },
                            },
                        ],
                    },
                    {
                        title: capitalize(lang.RESTORE_STATUS, true),
                        items: [
                            {
                                iconSrc: getStatusIconSrc(StatusTypes.Pending),
                                label: lang.RESTORING,
                                value: {
                                    id: FlrStatuses.Restoring,
                                    name: 'restoreStatus',
                                },
                            },
                            {
                                iconSrc: getStatusIconSrc(StatusTypes.Error),
                                label: lang.FAILED,
                                value: {
                                    id: FlrStatuses.Error,
                                    name: 'restoreStatus',
                                },
                            },
                            {
                                iconSrc: getStatusIconSrc(StatusTypes.Success),
                                label: lang.SUCCESS,
                                value: {
                                    id: FlrStatuses.Success,
                                    name: 'restoreStatus',
                                },
                            },
                            {
                                iconSrc: getStatusIconSrc(StatusTypes.Warning),
                                label: lang.WARNING,
                                value: {
                                    id: FlrStatuses.Warning,
                                    name: 'restoreStatus',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        title: lang.BACKUP_TYPE,
                        items: [
                            {
                                iconSrc: entireComputerTypeIconSrc,
                                label: lang.ENTIRE_COMPUTER,
                                value: {
                                    id: EndpointBackupModes.EntireComputer,
                                    name: 'backupType',
                                },
                            },
                            {
                                iconSrc: volumeLevelTypeIconSrc,
                                label: lang.VOLUME_LEVEL,
                                value: {
                                    id: EndpointBackupModes.Volume,
                                    name: 'backupType',
                                },
                            },
                            {
                                iconSrc: fileLevelTypeIconSrc,
                                label: lang.FILE_LEVEL,
                                value: {
                                    id: EndpointBackupModes.Folder,
                                    name: 'backupType',
                                },
                            },
                        ],
                    },
                    osTypesAdvancedFilter,
                ],
            },
        ],
    };

    return (
        <AdvancedToolbar
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='name'
                            placeholder={lang.COMPUTER}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <AdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
