/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SortDirection } from '@veeam-vspc/components';

import type { RequestParams } from '@veeam-vspc/components';

import type { AlternateRequestParams } from 'core/interfaces';

export function alternateTransformRequestParams<T extends Record<string, unknown>>({ start, limit, sort, filter, ...rest }: RequestParams<T>) {
    const params: AlternateRequestParams<T> = {} as AlternateRequestParams<T>;

    if (start !== undefined) {
        params.offset = start;
    }

    if (limit !== undefined) {
        params.limit = limit;
    }

    if (sort !== undefined && sort.length) {
        const { property, direction } = sort[0];

        params.orderBy = property;
        params.orderDirection = direction === 'ASC' ? SortDirection.Asc : SortDirection.Desc;
    }

    Object.entries(filter).forEach(([k, v]) => {
        const key: keyof T = k;
        const value: T[keyof T] = v;

        if (Array.isArray(value) && value.length > 0) {
            params[key] = value;
        } else if (Array.isArray(value) && value.length === 0) {
            delete params[key];
        } else {
            params[key] = value;
        }
    });

    return {
        ...params,
        ...rest,
    };
}