/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { FileSharesLocal, FileSharesPublicClouds } from './components';

export interface FileSharesProps {
    gridName: string;
}

export const FileShares: React.FC<FileSharesProps> = ({ gridName, ...restProps }) => {
    switch (gridName) {
        case ('FileSharesLocal'):
            return <FileSharesLocal stateId={'ProtectedDataPage.FileShares'} {...restProps} />;
        case ('FileSharesPublicClouds'):
            return <FileSharesPublicClouds stateId={'ProtectedDataPage.FileShares'} {...restProps} />;
        default:
            return null;
    }
};
