/**
 * Copyright © Veeam Software Group GmbH.
 */

import { capitalize } from '@veeam-vspc/core';

import successIconSrc from 'images/statuses/success.svg';
import infoIconSrc from 'images/statuses/info.svg';
import warningIconSrc from 'images/statuses/warning.svg';
import errorIconSrc from 'images/statuses/error.svg';
import runningIconSrc from 'images/statuses/running.svg';
import stoppedIconSrc from 'images/statuses/stopped.svg';
import stoppingIconSrc from 'images/statuses/stopping.svg';
import pendingIconSrc from 'images/statuses/pending.svg';
import startingIconSrc from 'images/statuses/starting.svg';
import updatingIconSrc from 'images/statuses/updating.svg';
import createdIconSrc from 'images/statuses/created.png';
import enabledIconSrc from 'images/statuses/enabled.svg';
import acknowledgeIconSrc from 'images/statuses/acknowledge.svg';
import backupAgentInstalledIconSrc from 'images/statuses/backup-agent-installed.svg';
import backupAgentNotInstalledIconSrc from 'images/statuses/backup-agent-not-installed.png';
import unlicensedBackupServerIconSrc from 'images/statuses/unlicensed-backup-server.png';
import { ExtendedStatuses, StatusTypes } from './enums';

const dotsStr = '...';

const getStatusIconSrc = (status: string): string => {
    if (typeof status !== 'string') {
        console.error(`Status "${status}" should be string, but it ${typeof status}`, new Error().stack);

        return '';
    }

    const parsedStatus: StatusTypes = ExtendedStatuses[capitalize(status.replace(dotsStr, '')).replace(/\s+/g, '')];

    switch (parsedStatus) {
        case (StatusTypes.Success): return successIconSrc;
        case (StatusTypes.Info): return infoIconSrc;
        case (StatusTypes.Warning): return warningIconSrc;
        case (StatusTypes.Error): return errorIconSrc;
        case (StatusTypes.Running): return runningIconSrc;
        case (StatusTypes.Syncing): return runningIconSrc;
        case (StatusTypes.Stopped): return stoppedIconSrc;
        case (StatusTypes.Stopping): return stoppingIconSrc;
        case (StatusTypes.Pending): return pendingIconSrc;
        case (StatusTypes.Starting): return startingIconSrc;
        case (StatusTypes.Updating): return updatingIconSrc;
        case (StatusTypes.Created): return createdIconSrc;
        case (StatusTypes.Enabled): return enabledIconSrc;
        case (StatusTypes.Acknowledged): return acknowledgeIconSrc;
        case (StatusTypes.Installed): return backupAgentInstalledIconSrc;
        case (StatusTypes.NotInstalled): return backupAgentNotInstalledIconSrc;
        case (StatusTypes.Unlicensed): return unlicensedBackupServerIconSrc;
        default: return '';
    }
};

const getStatusTitle = (lang: Record<string, string>, status: string): string => {
    const isDots = status.indexOf(dotsStr) !== -1;
    const statusTransformedToLangKey = status.replace(dotsStr, '').replace(/\s+/g, '_');

    return `${lang[statusTransformedToLangKey.toUpperCase()]}${isDots ? dotsStr : ''}`;
};

const divideStringToWords = (status: string): string => status.split(/(?=[A-Z])/).join(' ');

export {
    getStatusTitle,
    divideStringToWords,
    getStatusIconSrc,
};
