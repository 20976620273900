/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    DurationColumn,
    GridColumnJustify,
    GridSortDirections,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextColumn,
} from '@veeam-vspc/components';

import type { CellRendererProps } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { SourceItemsColumn } from 'views/components/columns/SourceItemsColumn';
import { FileServersDialogToolbar } from '../FileServersDialogToolbar/FileServersDialogToolbar';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { FileServerStatusColumn } from '../FileServerStatusColumn/FileServerStatusColumn';
import { MAX_INTEGER_32 } from 'core/const';

import type { FileJobSourceFilterModel, FileJobSourceItem, FileJobSourceRequestParams } from '../../interfaces';

export interface FileServersDialogProps {
    deactivate: () => void;
    title: string;
    jobUid: string;
    stateId: string;
}

export const FileServersDialog: React.FC<FileServersDialogProps> = ({ deactivate, title, jobUid, stateId }) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const defaultColumnRender = ({ cellData }: CellRendererProps<FileJobSourceItem>) => <TextColumn>{cellData}</TextColumn>;
    const columns = [
        {
            field: 'statusName',
            title: lang.STATUS,
            cell: ({ rowData }: CellRendererProps<FileJobSourceItem>) => (
                <FileServerStatusColumn
                    status={rowData.status}
                    statusName={rowData.statusName}
                    messages={rowData.messages}
                />
            ),
        },
        {
            field: 'name',
            title: lang.SERVER,
            cell: defaultColumnRender,
        },
        {
            field: 'sourceItemsCount',
            title: lang.FILES_AND_FOLDERS,
            cell: ({ cellData, rowData }: CellRendererProps<FileJobSourceItem>) => (
                <SourceItemsColumn
                    value={cellData}
                    stateId={`${stateId}.Dialogs.SourceItems`}
                    name={rowData.name}
                    sourceItems={rowData.sourceItems}
                    alignRight={true}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'sourceFiles',
            title: lang.N_OF_FILES,
            cell: ({ cellData }: CellRendererProps<FileJobSourceItem>) => <TextColumn defaultValue={lang.N_A}>{cellData}</TextColumn>,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'changedFiles',
            title: lang.CHANGED_FILES,
            cell: defaultColumnRender,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backedUpFilesCount',
            title: lang.TRANSFERRED_FILES,
            width: 145,
            cell: ({ rowData }: CellRendererProps<FileJobSourceItem>) => (
                <TextColumn>{rowData.backedUpFiles}</TextColumn>
            ),
        },
        {
            field: 'transferredData',
            title: lang.TRANSFERRED_DATA,
            cell: ({ cellData }: CellRendererProps<FileJobSourceItem>) => (
                <RestorePointSizeColumn
                    value={cellData as any}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'duration',
            title: lang.DURATION,
            cell: ({ cellData }: CellRendererProps<FileJobSourceItem>) => (
                <DurationColumn
                    value={cellData as any}
                />
            ),
        },
    ];

    return (
        <Dialog
            header={title}
            size={{
                width: DIALOG_SIZE.xxl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.OK, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <AdvancedGrid
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: 'status',
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={columns}
                    data={(requestParams: FileJobSourceRequestParams<FileJobSourceFilterModel>) => transportService
                        .request<typeof requestParams, FileJobSourceItem[]>('/BackupServerJob/GetFileJobSources', {
                            ...requestParams,
                            jobUid,
                        })}
                    toggleable={false}
                    selection={{
                        field: 'instanceUid',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => <FileServersDialogToolbar idProperty={'instanceUid'} extraParams={{ jobUid }} />,
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
