/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { renderShortDateAndTimeForColumn } from '@veeam-vspc/components';
import { deepCopy } from '@veeam-vspc/core';

import { PageWizard } from 'components/wizards/PageWizard';
import { DEFAULT_DATA_TRANSFER_GB } from 'core/const';
import { getData } from 'core/services/transport/utils/get-data';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { CompanyMfaStatuses } from 'core/enums/company-mfa-status';
import { getLicenseStep } from './components/License';
import { getMultiFactorStep } from './components/MultiFactor';
import { getEmailStep } from './components/Email';
import { getQuotaStep } from './components/Quota';
import { getResellerInfoStep } from './components/ResellerInfo';
import { getSiteScopeStep } from './components/SiteScope';
import { getSummaryStep } from './components/Summary';
import { getUserInfoStep } from './components/UserInfo';
import { ResellerWizardStore, ResellerWizardStoreContext } from './stores';
import { UserTitleRepresentation } from 'core/enums';
import { getBandwidthStep } from './components/Bandwidth';
import { AllowedBandwidthUnits } from 'views/components/wizard/enums';

import type { ResellerData } from './interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { PortalFormats } from 'core/entries/portal-data/interfaces';

export interface ResellerWizardProps {
    onFinish: () => void;
    data?: ResellerData;
    isMfaPolicyEnforced?: boolean;
    allowSendEmail: boolean;
}

const getDefaultData = (isMfaPolicyEnforced: boolean, lang: LangsServiceBase, formats: PortalFormats): ResellerData => ({
    country: 0,
    dataTransferGb: 0,
    description: `${lang.CREATED_BY_VEEAM_AT} ${renderShortDateAndTimeForColumn(new Date(), formats.netShortDate, formats.netShortTime)}`,
    id: -1,
    isAllowedToSwitchMfaPolicyStatus: !isMfaPolicyEnforced,
    name: '',
    password: '',
    publicCloudEnabled: false,
    cloudBackupResources: [],
    usState: 0,
    companyMap: [],
    cloudConnectAgents: [],
    workstationAgents: 1,
    serverAgents: 1,
    wanAccelerationEnabled: false,
    insiderProtectionQuota: 0, // Unlimited
    cloudReplicationResources: [],
    cloudVcdResources: [],
    pulsePointsLimit: false,
    userName: '',
    alias: '',
    restEnabled: false,
    companyId: '',
    domain: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    notes: '',
    title: UserTitleRepresentation.Unknown,
    mfaPolicyStatus: isMfaPolicyEnforced ? CompanyMfaStatuses.Enabled : CompanyMfaStatuses.Disabled,
    proPartnerId: '',
    pulseLicenseCreation: false,
    taxId: '',
    pulsePointsLimitValue: 1,
    telephone: '',
    zipCode: '',
    vboResources: [],
    isFileLevelRestoreEnabled: false,
    isUnlimitedBandwidth: true,
    throttlingEnabled: false,
    cloudWanResources: [],
    enabled: true,
    isServerAgentsQuotaUnlimited: true,
    isWorkstationAgentsQuotaUnlimited: true,
    isMappedToPulseTenant: false,
});

const transformDataToSave = (data: ResellerData, store: ResellerWizardStore) => {
    const result = deepCopy(data);

    if (!store.workstationAgentsEnabled) {
        result.workstationAgents = 0;
        result.isWorkstationAgentsQuotaUnlimited = false;
    }

    if (!store.serverAgentsEnabled) {
        result.serverAgents = 0;
        result.isServerAgentsQuotaUnlimited = false;
    }

    if (!store.hasInsiderProtection) {
        result.insiderProtectionQuota = 0;
    }

    if (!store.cloudBackupResourcesEnabled) {
        result.cloudBackupResources = [];
    }

    if (!store.cloudReplicationResourcesEnabled) {
        result.cloudReplicationResources = [];
    }

    if (!store.cloudVcdResourcesEnabled) {
        result.cloudVcdResources = [];
    }

    if (!store.dataTransferGbEnabled) {
        result.dataTransferGb = 0;
    }

    if (!store.microsoft365ResourcesEnabled) {
        result.vboResources = [];
    }

    // TODO: remove after quota.isStorageQuotaUnlimited support on server
    if (result.cloudBackupResources) {
        result.cloudBackupResources.forEach((item) => {
            if (item.quota.quotasIsUnlimited) {
                item.quota.vms = -1;
                item.quota.servers = -1;
                item.quota.workstations = -1;
            }
        });
    }

    if (!data.title) {
        result.title = UserTitleRepresentation.Unknown;
    }

    return result;
};

export const ResellerWizard = observer(({ onFinish, data, isMfaPolicyEnforced, allowSendEmail }: ResellerWizardProps) => {
    const lang = useLang();

    const isEdit = !!data;
    let title = `${isEdit ? lang.EDIT : lang.NEW} ${lang.RESELLER}`;

    if (isEdit) {
        title += ` - ${data.name}`;
    }

    const { formats } = useAppStore();
    const currentData: ResellerData = isEdit ? data : getDefaultData(isMfaPolicyEnforced, lang, formats);
    const store = useMemo(() => new ResellerWizardStore(currentData), []);

    // prepare init data
    if (!currentData.maxConcurrentTasks) {
        currentData.maxConcurrentTasks = 1;
    }
    if (!currentData.throttlingUnit) {
        currentData.throttlingUnit = AllowedBandwidthUnits.MbytePerSec;
    }
    if (!currentData.throttlingValue) {
        currentData.throttlingValue = 1;
    }

    store.workstationAgentsEnabled = currentData.workstationAgents > 0 || currentData.isWorkstationAgentsQuotaUnlimited;
    if (currentData.workstationAgents < 1) {
        if (currentData.workstationAgents === 0) {
            currentData.isWorkstationAgentsQuotaUnlimited = true;
        }
        currentData.workstationAgents = 1;
    }

    store.serverAgentsEnabled = currentData.serverAgents > 0 || currentData.isServerAgentsQuotaUnlimited;
    if (currentData.serverAgents < 1) {
        if (currentData.serverAgents === 0) {
            currentData.isServerAgentsQuotaUnlimited = true;
        }
        currentData.serverAgents = 1;
    }

    store.hasInsiderProtection = currentData.insiderProtectionQuota > 0;
    if (currentData.insiderProtectionQuota === 0) {
        currentData.insiderProtectionQuota = 1;
    }

    store.dataTransferGbEnabled = currentData.dataTransferGb > 0;
    if (currentData.dataTransferGb === 0) {
        currentData.dataTransferGb = DEFAULT_DATA_TRANSFER_GB;
    }

    store.cloudBackupResourcesEnabled = currentData.cloudBackupResources?.length > 0;
    store.cloudReplicationResourcesEnabled = currentData.cloudReplicationResources?.length > 0;
    store.cloudVcdResourcesEnabled = currentData.cloudVcdResources?.length > 0;
    store.microsoft365ResourcesEnabled = currentData.vboResources?.length > 0;

    useEffect(() => {
        store.loadPulseConfiguration();
    }, []);

    return (
        <ResellerWizardStoreContext.Provider value={store}>
            <PageWizard
                title={title}
                isEdit={isEdit}
                tabsWidth={220}
                deactivate={() => undefined}
                steps={[
                    getResellerInfoStep(lang, store),
                    getUserInfoStep(lang, store),
                    getSiteScopeStep(lang, store),
                    getQuotaStep(lang, store),
                    getBandwidthStep(lang),
                    getMultiFactorStep(lang),
                    getEmailStep(lang),
                    getLicenseStep(lang, store),
                    getSummaryStep(lang, allowSendEmail),
                ]}
                data={currentData}
                onFinish={async({ data }) => {
                    // prepare data to save
                    const dataToSave = transformDataToSave(data, store);

                    await getData('Reseller/SaveReseller', {
                        reseller: dataToSave,
                        isRequiredSendEmail: store.isRequiredSendEmail,
                        emailCustomSection: !isEdit || store.isEmailDataChanged()
                            ? {
                                customSection: store.emailData.addCustomText ? store.emailData.emailContent : null,
                                isDefault: store.emailData.isDefault,
                                isVisibleGeneralSection: store.emailData.isVisibleGeneralSection,
                            } : null,
                    });

                    onFinish();
                }}
            />
        </ResellerWizardStoreContext.Provider>
    );
});
