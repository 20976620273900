/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FormValidator } from '@veeam-vspc/components';
import { VbrDeploymentLicenseSourceType } from '@veeam-vspc/models/web-controllers';

import type { FormErrors } from '@veeam-vspc/components';

import { REG_EXP } from 'core/const';

import type { VbrDeploySettingsWizardModel } from '../../entries';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const formValidate = (lang: LangsServiceBase) => (data: VbrDeploySettingsWizardModel): FormErrors<VbrDeploySettingsWizardModel> => {
    const validator = new FormValidator(data);

    if (data.licenseSource === VbrDeploymentLicenseSourceType.LicenseFile) {
        const fileTypeValidator = (value: FileList): string => {
            const fileName = value[0].name;
            const regExp = REG_EXP.licenseFileType;

            return regExp.test(fileName) ? '' : lang.UNSUPPORTED_LICENSE_FORMAT;
        };

        validator.validate('licenseFile')
            .object()
            .required()
            .check(fileTypeValidator);
    }

    return validator.result();
};
