/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum AppViewTypes {
    Dialogs = 'Dialogs',
    Loading = 'Loading',
    Login = 'Login',
    App = 'App',
    AppPage = 'AppPage',
    Error = 'Error',
}
