/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, StackView, Text, ICON_SIZES, STACK_ALIGN, STACK_GAP, TEXT_SIZE } from '@veeam-vspc/components';

import successIcon from 'images/success.svg';
import { useLang } from 'views/providers/LangProvider/hooks';

export const HealthyColumn: React.FC = () => {
    const lang = useLang();

    return (
        <StackView gap={STACK_GAP.s} align={STACK_ALIGN.center}>
            <Icon src={successIcon} size={ICON_SIZES.xl} />
            <Text size={TEXT_SIZE.l}>{lang.HEALTHY}</Text>
        </StackView>
    );
};
