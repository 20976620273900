/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { useIntervalRequest } from 'views/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { PlateWidget } from '../widgets/PlateWidget';
import errorLight from '../../../../../images/error-light.svg';
import warningLight from '../../../../../images/warning-light.svg';
import { AlarmStatus } from '../../enums/alarm-status';
import { TOGGLE_FILTER_ALL_VALUE } from '../../configs';

import type { Alarms } from '../../interfaces';


export const ActiveAlarms: React.FC = () => {
    const lang = useLang();
    const request = useIntervalRequest<{}, Alarms>('Overview/ActiveAlarms');
    const data = request.data as Alarms;
    const errorsCount = data?.errors.value;
    const warningsCount = data?.warnings.value;

    return (
        <PlateWidget
            title={lang.ACTIVE_ALARMS}
            items={[
                errorsCount > 0 && {
                    name: lang.ERRORS,
                    icon: errorLight,
                    count: errorsCount,
                    trend: data?.errors.trend,
                },
                warningsCount > 0 && {
                    name: lang.WARNINGS,
                    icon: warningLight,
                    count: warningsCount,
                    trend: data?.warnings.trend,
                },
            ]}
            isHealthy={errorsCount === 0 && warningsCount === 0}
            loading={request.loading}
            healthyText={lang.NO_ACTIVE_ALARMS}
            onClick={() => {
                const filters = `statusesFilter/${AlarmStatus.Warning},${AlarmStatus.Error}/productsFilter/${TOGGLE_FILTER_ALL_VALUE}/` +
                    `dateFilter/${TOGGLE_FILTER_ALL_VALUE}/scopesFilter/${TOGGLE_FILTER_ALL_VALUE}`;

                window.location.assign(`#home/activeAlarms/tab/alarms/${filters}`);
            }}
            className='fb-50'
        />
    );
};
