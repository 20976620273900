/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { AzureObjectType } from '@veeam-vspc/models/web-controllers';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ProtectedAzureWorkloadsContext } from './contexts/ProtectedAzureWorkloadsContext';
import { ProtectedAzureWorkloadsService } from './services/protected-azure-workloads.service';
import { ProtectedAzureWorkloadsStore } from './stores/ProtectedAzureWorkloadsStore';
import { StatefulCombobox } from '../../../StatefulCombobox';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useProtectedAzureWorkloadsStore } from './hooks/use-protected-azure-workloads-store';

import type { ComboboxItem } from '../../../../interfaces';

export const ChartLayout = observer(() => {
    const lang = useLang();
    const protectedAzureWorkloadsStore = useProtectedAzureWorkloadsStore();

    const params = {
        colors: [palette.B400],
        yTitle: lang.WORKLOADS,
    };

    const title = {
        text: capitalize(lang.PROTECTED_AZURE_WORKLOADS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={protectedAzureWorkloadsStore.setAzureObjectType}
                stateId={'trends.backup-services.protected-azure-workloads.azure-object-type'}
                textGetter={(p: ComboboxItem) => p.text}
                value={protectedAzureWorkloadsStore.azureObjectType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: AzureObjectType.Vm, text: capitalize(lang.VMS) },
                    { id: AzureObjectType.Database, text: capitalize(lang.AZURE_SQL_DATABASES) },
                    { id: AzureObjectType.Files, text: capitalize(lang.AZURE_FILES) },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={protectedAzureWorkloadsStore.azureObjectType}
            params={params}
            service={ProtectedAzureWorkloadsService}
            title={title}
        />
    );
});

export const ProtectedAzureWorkloads: React.FC = () => {
    const protectedAzureWorkloadsStore = useMemo(() => new ProtectedAzureWorkloadsStore(), []);

    return (
        <ProtectedAzureWorkloadsContext.Provider value={protectedAzureWorkloadsStore}>
            <ChartLayout />
        </ProtectedAzureWorkloadsContext.Provider>
    );
};
