/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts, MessageBox, ParsedText, TextColumn } from '@veeam-vspc/components';

import { EJobStates } from 'core/enums';
import { getStatusIconSrc } from 'core/utils';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { FailureMessageDialog, getFailureMessage } from 'views/components/FailureMessageDialog';

export interface BackupStatusColumnProps {
    statusName: string;
    failureMessage: string | null;
    lastSessionStartTime: string;
    lastSessionEndTime: string | null;
    lastSessionUid?: string;
    status?: EJobStates;
    doRequestForMessage?: boolean;
    excludeTimeShiftCorrection?: boolean;
}

export const BackupStatusColumn: React.FC<BackupStatusColumnProps> = (props) => {
    const lang = useLang();
    const { formats } = useAppStore();
    const { netShortDate, netShortTime } = formats;
    const {
        status,
        statusName,
        lastSessionUid,
        lastSessionStartTime,
        lastSessionEndTime,
        failureMessage,
        doRequestForMessage = true,
        excludeTimeShiftCorrection,
    } = props;
    const iconSrc = getStatusIconSrc(statusName);

    return (
        <ActionLinkColumn
            renderContent={() => (
                <TextColumn style={{ color: 'inherit' }}>
                    {statusName || EJobStates[status] || ColumnTexts.DashSymbol}
                </TextColumn>
            )}
            isLink={failureMessage && !['starting', 'stopping'].includes(statusName.toLowerCase())}
            contentIconBefore={iconSrc}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <>
                    {doRequestForMessage && (
                        <FailureMessageDialog
                            url={'/BackupServerJob/GetJobSessionTasksFailureMessages'}
                            deactivate={deactivate}
                            iconSrc={iconSrc}
                            paramsForFailureMessageRequest={{
                                sessionGuid: lastSessionUid,
                            }}
                            paramsForFailureMessage={{
                                excludeTimeShiftCorrection,
                                shortDateFormat: netShortDate,
                                shortTimeFormat: netShortTime,
                                lastSessionTime: lastSessionEndTime,
                                statusName,
                            }}
                        />
                    )}

                    {!doRequestForMessage && (
                        <MessageBox
                            header={lang.JOB_STATUS}
                            onRequestClose={deactivate}
                            actions={[
                                { text: lang.OK, onClick: deactivate },
                            ]}
                            iconSrc={iconSrc}
                        >
                            <ParsedText
                                textOrConfig={getFailureMessage(lang, {
                                    excludeTimeShiftCorrection,
                                    shortDateFormat: netShortDate,
                                    shortTimeFormat: netShortTime,
                                    failureMessage,
                                    lastSessionTime: lastSessionStartTime,
                                    statusName,
                                })}
                            />
                        </MessageBox>
                    )}
                </>
            )}
        />
    );
};
