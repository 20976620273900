/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ProtectedFileSharesService } from './services/protected-file-shares.service';
import { useLang } from 'views/providers/LangProvider/hooks';

export const ProtectedFileShares: React.FC = () => {
    const lang = useLang();

    const params = {
        colors: [palette.B400, palette.Grn200],
        yTitle: lang.FILE_SHARES,
    };

    return (
        <BaseTrendChart
            params={params}
            title={{ text: capitalize(lang.FILE_SHARES) }}
            service={ProtectedFileSharesService}
        />
    );
};
