/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { CompanyWizard } from './components/CompanyWizard';

import type { CompanyWizardProps } from './components/CompanyWizard';

export const COMPANIES_PAGE = 'CompaniesPage';

export const CompaniesPage = (props: CompanyWizardProps) => (
    <CompanyWizard {...props} />
);
