/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FormValidator } from '@veeam-vspc/components';

import type { FormErrors } from '@veeam-vspc/components';

import { REG_EXP } from 'core/const';
import { DistributionType } from '../../enums';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { VbrDeploySettingsWizardModel } from '../../entries';

export const stepValidate = (lang: LangsServiceBase) => (data: VbrDeploySettingsWizardModel): FormErrors<VbrDeploySettingsWizardModel> => {
    const validator = new FormValidator(data);

    if (data.distributionType === DistributionType.DownloadFromFileShare) {
        const locationValidator = (): string => {
            const pathRegExp = REG_EXP.uncPath;
            const fileTypeRegExp = REG_EXP.isoFileType;
            const filePath = data.distribution?.location;

            return !pathRegExp.test(filePath) ? lang.INVALID_LOCATION_PATH :
                !fileTypeRegExp.test(filePath) ? lang.THE_SPECIFIED_FILE_IS_INVALID : '';
        };

        validator.validate('distribution.location')
            .string()
            .maxLength(260)
            .required()
            .check(locationValidator);

        validator.validate('distribution.userName')
            .string()
            .required();
    }

    return validator.result();
};
