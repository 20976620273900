/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { SubscriptionPlanContext } from '../stores';

import type { SubscriptionPlanStore } from '../stores';

export const useSubscriptionPlanStore = (): SubscriptionPlanStore => useContext(SubscriptionPlanContext);
