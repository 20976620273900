/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify, GridSortDirections, TextColumn } from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';
import { EPublicCloudRestoreType } from '@veeam-vspc/models/web-controllers';

import type { PublicCloudEfsModel, PublicCloudEfsParam } from '@veeam-vspc/models/web-controllers';
import type { CellRendererProps, RequestParams } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import {
    FileSharesPublicCloudsActionToolbar,
    FileSharesPublicCloudsFilterToolbar,
    FsPublicCloudsBackupColumn,
} from './components';
import { FlrPortalColumn } from '../../../FlrPortalColumn';
import { ConfigSectionIds } from 'core/services';

export interface FileSharesPublicCloudsProps {
    stateId: string;
}

export const FileSharesPublicClouds: React.FC<FileSharesPublicCloudsProps> = ({ stateId }) => {
    const { transportService, enumMapperService } = useAppServices();
    const lang = useLang();
    const gridStateId = `${stateId}.FileSharesPublicClouds`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<PublicCloudEfsModel>) => <TextColumn>{cellData}</TextColumn>;
    const columns = [
        {
            field: 'vmName',
            title: lang.NAME,
            cell: defaultColumnRender,
        },
        {
            field: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'platformType',
            title: lang.PLATFORM,
            cell: ({ cellData }: CellRendererProps<PublicCloudEfsModel>) => (
                <TextColumn>
                    {enumMapperService.getEnumDescription('PublicCloudPlatformTypeRepresentation', cellData)}
                </TextColumn>
            ),
        },
        {
            field: 'restoreStatusModel.status',
            title: lang.FILE_LEVEL_RESTORE_PORTAL,
            width: 190,
            cell: ({ rowData }: CellRendererProps<PublicCloudEfsModel>) => (
                <FlrPortalColumn
                    instanceUid={rowData.uid}
                    vbServerId={rowData.vbServerId}
                    restoreType={EPublicCloudRestoreType.RestoreEfsFileLevel}
                    restoreStatusModel={rowData.restoreStatusModel}
                />
            ),
        },
        {
            field: 'companyName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'policyName',
            title: lang.POLICY,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'backup.backupCount',
            title: lang.SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudEfsModel>) => (
                <FsPublicCloudsBackupColumn
                    value={rowData.backup?.backupCount}
                    name={`${lang.SNAPSHOT} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: rowData.backup?.backupType,
                    }}
                    stateId={`${gridStateId}.Dialogs.FsPublicCloudsBackup.Backup`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backupCopy.backupCount',
            title: capitalize(lang.REPLICA_SNAPSHOT),
            width: 145,
            cell: ({ rowData }: CellRendererProps<PublicCloudEfsModel>) => (
                <FsPublicCloudsBackupColumn
                    value={rowData.backupCopy?.backupCount}
                    name={`${capitalize(lang.REPLICA_SNAPSHOT)} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: rowData.backupCopy?.backupType,
                    }}
                    stateId={`${gridStateId}.Dialogs.FsPublicCloudsBackup.BackupCopy`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'region',
            title: lang.REGION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'backup.lastBackup',
            title: lang.LAST_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudEfsModel>) => (
                <TimeAgoColumn
                    value={rowData.backup?.lastBackup}
                />
            ),
        },
        {
            field: 'backupCopy.lastBackup',
            title: lang.LAST_REPLICA_SNAPSHOT,
            width: 150,
            cell: ({ rowData }: CellRendererProps<PublicCloudEfsModel>) => (
                <TimeAgoColumn
                    value={rowData.backupCopy?.lastBackup}
                />
            ),
        },
        {
            field: 'policyState',
            title: lang.POLICY_STATE,
            cell: ({ cellData }: CellRendererProps<PublicCloudEfsModel>) => (
                <TextColumn>
                    {enumMapperService.getEnumDescription('JobStateRepresentation', cellData)}
                </TextColumn>
            ),
        },
    ];

    return (
        <AdvancedGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.ProtectedDataFileSharesPublicClouds}
            defaultColumnWidth={100}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.field,
                },
            }}
            columns={columns}
            data={(requestParams: RequestParams<PublicCloudEfsParam['filter']>) => transportService
                .request<typeof requestParams, PublicCloudEfsModel[]>('/PublicCloudEfs/GetProtectedEfs', { ...requestParams })}
            toggleable={true}
            selection={{
                field: 'uid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <FileSharesPublicCloudsFilterToolbar />,
                () => <FileSharesPublicCloudsActionToolbar idProperty={'uid'} selectedOnly />,
            ]}
        />
    );
};
