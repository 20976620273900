/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useAppStore } from '../../../views/providers/AppProvider/hooks';

export const useDateFormats = () => {
    const { formats } = useAppStore();

    return {
        is12HoursFormat: formats.netShortTime.indexOf('h') !== -1,
    };
};
