/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    CONTROL_SIZE,
    Combobox,
    FieldLayout,
    MultipleChoice,
    useFormApi,
    FormValidator,
} from '@veeam-vspc/components';
import { capitalize, formatStr } from '@veeam-vspc/core';

import { useLang } from 'views/providers/LangProvider/hooks';
import { DbGroupingBy } from '../../../enums';
import { useReportStore } from '../../../hooks';
import { ReportModel } from '../../../interfaces';
import { RpoInterval } from './RpoInterval';

export const databasesParametersValidate = (data: ReportModel) => {
    const validator = new FormValidator(data);

    validator
        .validate('parameters.rpoInterval.number')
        .number()
        .required()
        .min(1)
        .max(365);

    validator
        .validate('parameters.jobTypeFilter')
        .string()
        .check((val) => {
            // val is array here
            if (val.length === 0) {
                return 'error';
            }
        });

    return validator.result();
};

export const DatabasesParameters = ({ data, onDataChange }) => {
    const lang = useLang();
    const formApi = useFormApi();
    const reportStore = useReportStore();
    const allJobTypes = reportStore.jobTypeSet.map(item => ({
        id: item.id.toString(),
        name: item.name,
    }));

    return (
        <>
            <RpoInterval />

            <FieldLayout
                label={`${capitalize(lang.JOB_TYPE, true)}:`}
            >
                <MultipleChoice
                    error={data.parameters.jobTypeFilter.length === 0}
                    titleFormatter={(values) => {
                        if (values.length === allJobTypes.length) {
                            return `${lang.ALL} ${lang.JOB_TYPES.toLowerCase()}`;
                        }
                        if (values.length > 0) {
                            return `${formatStr(lang.N_OF_COUNT_TYPES, values.length, allJobTypes.length)}`;
                        }
                        return `${lang.NOT_SELECTED}...`;
                    }}
                    data={allJobTypes}
                    value={data.parameters.jobTypeFilter.map(item => item.toString())}
                    onChange={(value) => {
                        formApi.setValue('parameters.jobTypeFilter', value.map(jobTypeId => parseInt(jobTypeId)));
                        onDataChange(data);
                    }}
                />
            </FieldLayout>

            <Combobox
                name='parameters.groupBy'
                label={lang.GROUP_BY}
                data={
                    [{
                        id: DbGroupingBy.Policy,
                        text: lang.POLICY,
                    }, {
                        id: DbGroupingBy.BackupServer,
                        text: capitalize(lang.BACKUP_SERVER),
                    }, {
                        id: DbGroupingBy.PlatformType,
                        text: capitalize(lang.PLATFORM_TYPE),
                    }]
                }
                valueGetter={item => item.id}
                textGetter={item => item.text}
                size={CONTROL_SIZE.m}
            />
        </>
    );
};
