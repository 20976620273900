/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TitleH2 } from '@veeam-vspc/components';

import { TableItem } from './components/TableItem';
import { TableTitle } from './components/TableTitle';
import { TableWidgetStyled } from './components/TableWidgetStyled';
import { NoData } from './components/NoData';
import { Spinner } from '../Spinner';

import type { TableWidgetProps } from './interfaces';

export const TableWidget = <T,>({
    children,
    className,
    columns,
    data = [],
    height,
    loading,
    noDataConfig,
    onClick,
    shiftColumns,
    title,
}: React.PropsWithChildren<TableWidgetProps<T>>): ReturnType<React.FC<TableWidgetProps<T>>> => {
    const safeData = data || [];

    const getWidgetContent = () => {
        if (loading) {
            return <Spinner delayMs={300} size={50} />;
        }

        if (!data || data.length === 0) {
            return noDataConfig ? <NoData noDataConfig={noDataConfig} /> : <Spinner size={50} />;
        }

        return (
            <div className='table-widget__table'>
                {columns.some(c => c.name) && <TableTitle columns={columns} />}
                {data.map((item, index) => <TableItem columns={columns} data={item} key={index} />)}
            </div>
        );
    };

    return (
        <TableWidgetStyled
            className={className}
            data={safeData}
            height={height}
            onClick={safeData.length === 0 ? undefined : onClick}
            shiftColumns={shiftColumns}
            title={title}
        >
            {title && <TitleH2>{title}</TitleH2>}
            {getWidgetContent()}
            {children}
        </TableWidgetStyled>
    );
};
