/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/components';

const Flex = styled.div`
    display: flex;
    flex: 1;
    justify-content: end;
`;

const HeaderStyled = styled.div <{ whiteBg: boolean; }>`
    background-color: ${props => props.whiteBg ? colors.G000 : colors.G100};
    display: flex;
    flex-shrink: 0;
    height: ${constants.SIZE_XXL};
    padding: ${constants.SPACING_S} ${constants.SPACING_M};
`;

const Title = styled.div`
    align-items: center;
    display: flex;
    font-weight: 700;
    padding: 0 8px;
    text-align: center;
`;

interface HeaderProps {
    title: { text: string; tools; };
    whiteBg?: boolean;
}

export const Header = ({ title, whiteBg }: HeaderProps) => (
    <HeaderStyled whiteBg={whiteBg}>
        <Flex />
        <Title>{title.text}</Title>
        <Flex>{title.tools}</Flex>
    </HeaderStyled>
);
