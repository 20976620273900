/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Form,
    FormLayout,
    FormTitle,
    FormValidator,
    isValid,
    useExternalFormApi,
    dataSizeToString,
    STACK_DIRECTION,
    NumberInput,
    LabelGroup,
    Checkbox,
} from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/use-page-wizard-store';
import { capitalize, formatStr, plural } from '@veeam-vspc/core';

import type { UserUiData } from '@veeam-vspc/models/web-controllers';
import type { WizardStep } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { ToggleField } from 'components/controls/ToggleField';
import { LinkField } from 'components/controls/LinkField';
import { DataSizeInBytes } from 'components/controls/DataSizeInBytes';
import { BackupResources } from './components/BackupResources';
import { ReplicaResources } from './components/ReplicaResources';
import { VcdResources } from './components/VcdResources';
import { Microsoft365Servers } from './components/Microsoft365Servers';
import { Microsoft365Repositories } from './components/Microsoft365Repositories';
import { WanAccelerationServers } from './components/WanAccelerationServers';

import type { ResellerWizardStore } from '../../stores';
import type { ResellerCompanyMap, ResellerData, ResellerWizardStepData } from '../../interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

const MIN_WORKSTATION_AGENTS = 1;
const MAX_WORKSTATION_AGENTS = 99999;

const MIN_SERVER_AGENTS = 1;
const MAX_SERVER_AGENTS = 99999;

const MIN_INSIDER_PROTECTION_QUOTA = 1;
const MAX_INSIDER_PROTECTION_QUOTA = 99;

const MIN_DATA_TRANSFER = 1;
const MAX_DATA_TRANSFER = 999999;

const formValidate = (data: ResellerData, lang: LangsServiceBase, wizardStore: ResellerWizardStore) => {
    const validator = new FormValidator(data);

    if (wizardStore.workstationAgentsEnabled && !data.isWorkstationAgentsQuotaUnlimited) {
        validator.validate('workstationAgents')
            .number()
            .required()
            .min(MIN_WORKSTATION_AGENTS)
            .max(MAX_WORKSTATION_AGENTS);
    }

    if (wizardStore.serverAgentsEnabled && !data.isServerAgentsQuotaUnlimited) {
        validator.validate('serverAgents')
            .number()
            .required()
            .min(MIN_SERVER_AGENTS)
            .max(MAX_SERVER_AGENTS);
    }

    if (wizardStore.dataTransferGbEnabled) {
        const getMaxDataTransferOutQuota = (maxValueInGb: number, fieldValue: number) => {
            const multiplier = 1024;
            if (fieldValue % multiplier === 0) {
                // in TB
                return Math.floor(maxValueInGb / multiplier);
            }
            return maxValueInGb;
        };

        const dataTransferOutQuotaValidator = validator.validate('dataTransferGb').number();
        const maxDataTransferOutQuotaMessage = formatStr(
            dataTransferOutQuotaValidator.defaultMaxErrorMessage,
            getMaxDataTransferOutQuota(MAX_DATA_TRANSFER, data.dataTransferGb)
        );

        dataTransferOutQuotaValidator
            .required()
            .min(MIN_DATA_TRANSFER)
            .max(MAX_DATA_TRANSFER, maxDataTransferOutQuotaMessage);
    }

    if (wizardStore.hasInsiderProtection) {
        validator.validate('insiderProtectionQuota')
            .number()
            .required()
            .min(MIN_INSIDER_PROTECTION_QUOTA)
            .max(MAX_INSIDER_PROTECTION_QUOTA);
    }

    return validator.result();
};

const stepValidate = (data: ResellerData, lang: any, wizardStore: ResellerWizardStore, isEdit: boolean) => {
    if (isValid(value => formValidate(value, lang, wizardStore), data)) {
        return true;
    }

    return false;
};

export const getQuotaStep = (lang: LangsServiceBase, wizardStore: ResellerWizardStore): WizardStep<ResellerData> => ({
    title: lang.SERVICES,
    validate: ({ data, isEdit }) => stepValidate(data, lang, wizardStore, isEdit),
    render: stepData => <QuotaStep {...stepData} wizardStore={wizardStore} />,
});

const QuotaStep = observer((wizardStepData: ResellerWizardStepData) => {
    const lang = useLang();
    const formApi = useExternalFormApi();
    const { data, validationState, onDataChange, wizardStore, isEdit } = wizardStepData;
    const wizardContext = usePageWizardStore<ResellerData>();
    const showBackupResources = () => wizardContext.openPanel(hide => <BackupResources {...wizardStepData} hidePanel={hide} />);
    const showReplicaResources = () => wizardContext.openPanel(hide => <ReplicaResources {...wizardStepData} hidePanel={hide} />);
    const showVcdResources = () => wizardContext.openPanel(hide => <VcdResources {...wizardStepData} hidePanel={hide} />);
    const showMicrosoft365Servers = () => wizardContext.openPanel(hide => <Microsoft365Servers {...wizardStepData} hidePanel={hide} />);
    const showMicrosoft365Repositories = () => wizardContext.openPanel(hide => <Microsoft365Repositories {...wizardStepData} hidePanel={hide} />);
    const showWanAccelerationServers = () => wizardContext.openPanel(hide => <WanAccelerationServers {...wizardStepData} hidePanel={hide} />);

    // TODO: create UserUiData in AppServices instead RCOP.UI_PREFERENCES
    const fileLevelRestoreEnabled = (RCOP.UI_PREFERENCES as UserUiData).visibility.fileLevelRestore;

    const getBackupResourcesText = (data: ResellerData) => {
        const { cloudBackupResources } = data;
        if (cloudBackupResources.length > 1) {
            return formatStr(`${cloudBackupResources.length} ${lang.REPOSITORIES.toLowerCase()}`);
        } else if (cloudBackupResources.length > 0) {
            const quota = cloudBackupResources[0];
            const quotaSize = quota.quota.isStorageQuotaUnlimited
                ? lang.UNLIMITED
                : dataSizeToString(quota.quota.storageGb, 0, 'G');
            return `${quota.cloudRepositoryFriendlyName} (${quotaSize})`;
        }
        return lang.CONFIGURE;
    };

    const getReplicaResourcesText = (data: ResellerData) => {
        const { cloudReplicationResources } = data;
        if (cloudReplicationResources.length > 1) {
            return plural(cloudReplicationResources.length, lang.PLAN.toLowerCase());
        } else if (cloudReplicationResources.length > 0) {
            const hwPlan = cloudReplicationResources[0];
            return hwPlan.cloudConnectResourceName;
        }
        return lang.CONFIGURE;
    };

    const getVcdResourcesText = (data: ResellerData) => {
        const { cloudVcdResources } = data;
        if (cloudVcdResources.length > 1) {
            return `${cloudVcdResources.length} ${lang.OBJECTS.toLowerCase()}`;
        } else if (cloudVcdResources.length > 0) {
            const vcdResource = cloudVcdResources[0];
            return vcdResource.cloudConnectResourceName;
        }
        return lang.CONFIGURE;
    };

    // const getPublicCloudResourcesText = (data: ResellerData) => {
    //     const { hwPlans } = data;
    //     if (hwPlans.length > 1) {
    //         return plural(hwPlans.length, lang.PLAN.toLowerCase());
    //     } else if (hwPlans.length > 0) {
    //         const hwPlan = hwPlans[0];
    //         return hwPlan.cloudConnectResourceName;
    //     }
    //     return lang.CONFIGURE;
    // };

    const getMicrosoft365ServersText = (data: ResellerData) => {
        const { vboResources } = data;
        if (vboResources.length > 1) {
            return `${vboResources.length} ${lang.SERVERS.toLowerCase()}`;
        } else if (vboResources.length > 0) {
            const server = vboResources[0];
            return server.vboFriendlyName;
        }
        return lang.CONFIGURE;
    };

    const getMicrosoft365RepositoriesText = (data: ResellerData) => {
        const { vboResources } = data;
        const repositoryCount = vboResources.reduce((prevValue, curRepository) => prevValue + curRepository.vboRepositories.length, 0);
        if (repositoryCount > 1) {
            return `${repositoryCount} ${lang.REPOSITORIES.toLowerCase()}`;
        } else if (repositoryCount > 0) {
            const repository = vboResources.find(res => res.vboRepositories.length === 1).vboRepositories[0];
            return wizardStore.availableVboBackupRepositoryCache.value
                ? wizardStore.availableVboBackupRepositoryCache.value
                    .find(availableRepository => availableRepository.instanceUid === repository.repositoryUid)?.name
                : '';
        }
        return lang.CONFIGURE;
    };

    const getCompaniesNames = (companyMap: ResellerCompanyMap[], hasResourcesGetter: (item: ResellerCompanyMap) => boolean) => {
        const names = [];

        data.companyMap.forEach((item) => {
            if (hasResourcesGetter(item)) {
                names.push(item.companyName);
            }
        });

        return names;
    };

    const getWanAccelerationServersText = (data: ResellerData) => {
        const { cloudWanResources } = data;
        if (cloudWanResources.length > 1) {
            return plural(cloudWanResources.length, lang.SERVER.toLowerCase());
        } else if (cloudWanResources.length > 0) {
            const server = cloudWanResources[0];
            return server.cloudConnectResourceName;
        }
        return lang.CONFIGURE;
    };

    const canSwitchOffResources = (
        companyMap: ResellerCompanyMap[],
        hasResourcesGetter: (item: ResellerCompanyMap) => boolean,
        resourcesName: string
    ) => {
        const names = getCompaniesNames(companyMap, hasResourcesGetter);

        if (names.length > 0) {
            const msg = formatStr(lang.RESOURCES_CANNOT_BE_DISABLED, resourcesName, names.join(', '));
            core.notificationService.error(resourcesName, msg);
            return false;
        }

        return true;
    };

    const canLimitDataTransferOut = (companyMap: ResellerCompanyMap[]) => {
        const names = getCompaniesNames(companyMap, item => item.isUnlimitedDataTransferOutQuota);

        if (names.length > 0) {
            const msg = Ext.String.format(RCOP.Lang.CANNOT_LIMIT_THE_DATA, names.join(', '));
            core.notificationService.error(lang.DATA_TRANSFER_OUT, msg);
            return false;
        }

        return true;
    };

    const canSwitchOffFeature = (
        companyMap: ResellerCompanyMap[],
        hasResourcesGetter: (item: ResellerCompanyMap) => boolean,
        featureName: string
    ) => {
        const names = getCompaniesNames(companyMap, hasResourcesGetter);

        if (names.length > 0) {
            const msg = Ext.String.format(RCOP.Lang.CANNOT_DISABLE_THIS_FEATURE, names.join(', '));
            core.notificationService.error(featureName, msg);
            return false;
        }

        return true;
    };

    const [wanAccelerationDisabledManually, setWanAccelerationDisabledManually] = useState(isEdit);
    const [hasInsiderProtectionDisabledManually, setHasInsiderProtectionDisabledManually] = useState(isEdit);

    const updateWanAccelerationEnabled = () => {
        const oneOrTwoEnabled = wizardStore.cloudBackupResourcesEnabled || wizardStore.cloudReplicationResourcesEnabled;
        if ((oneOrTwoEnabled && !wanAccelerationDisabledManually) || !oneOrTwoEnabled) {
            formApi.setValue('wanAccelerationEnabled', oneOrTwoEnabled);
        }
    };

    const updateHasInsiderProtection = () => {
        if ((wizardStore.cloudBackupResourcesEnabled && !hasInsiderProtectionDisabledManually) || !wizardStore.cloudBackupResourcesEnabled) {
            wizardStore.hasInsiderProtection = wizardStore.cloudBackupResourcesEnabled;
        }
    };

    useEffect(() => {
        wizardStore.availableVboBackupRepositoryCache.load();
    }, []);

    return (
        <StepLayout
            title={lang.SERVICES}
            description={lang.SPECIFY_SERVICES_THAT_THE_RESELLER}
        >
            <Form
                value={data}
                validate={(data: ResellerData) => formValidate(data, lang, wizardStore)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.BACKUP_AGENTS}</FormTitle>

                    <LabelGroup label={`${lang.WORKSTATION_AGENTS}:`}>
                        <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                            <ToggleField
                                value={wizardStore.workstationAgentsEnabled}
                                onChange={value => wizardStore.workstationAgentsEnabled = value}
                                showSuffix={true}
                            />

                            <NumberInput
                                name={'workstationAgents'}
                                disabled={!wizardStore.workstationAgentsEnabled || data.isWorkstationAgentsQuotaUnlimited}
                                minValue={MIN_WORKSTATION_AGENTS}
                                maxValue={MAX_WORKSTATION_AGENTS}
                            />

                            <Checkbox
                                name={'isWorkstationAgentsQuotaUnlimited'}
                                disabled={!wizardStore.workstationAgentsEnabled}
                            >
                                {lang.DO_NOT_SET_ANY_LIMIT}
                            </Checkbox>
                        </FormLayout>
                    </LabelGroup>

                    <LabelGroup label={`${lang.SERVER_AGENTS}:`}>
                        <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                            <ToggleField
                                value={wizardStore.serverAgentsEnabled}
                                onChange={value => wizardStore.serverAgentsEnabled = value}
                                showSuffix={true}
                            />

                            <NumberInput
                                name={'serverAgents'}
                                disabled={!wizardStore.serverAgentsEnabled || data.isServerAgentsQuotaUnlimited}
                                minValue={MIN_SERVER_AGENTS}
                                maxValue={MAX_SERVER_AGENTS}
                            />

                            <Checkbox
                                name={'isServerAgentsQuotaUnlimited'}
                                disabled={!wizardStore.serverAgentsEnabled}
                            >
                                {lang.DO_NOT_SET_ANY_LIMIT}
                            </Checkbox>
                        </FormLayout>
                    </LabelGroup>

                    <ToggleField
                        name={'isFileLevelRestoreEnabled'}
                        label={lang.FILE_LEVEL_RESTORE}
                        showSuffix={true}
                        onChange={(value) => {
                            if (value && !fileLevelRestoreEnabled) {
                                core.notificationService.error(lang.FILE_LEVEL_RESTORE_CAPITALIZED, lang.THIS_FEATURE_ALLOWS_RESELLER_TO_PERFORM);
                                formApi.setValue('isFileLevelRestoreEnabled', false);
                            }
                        }}
                    />

                    <FormTitle>{lang.CLOUD_CONNECT_RESOURCES}</FormTitle>

                    <ToggleField
                        name={null}
                        value={wizardStore.cloudBackupResourcesEnabled}
                        onChange={(value) => {
                            if (!value && !canSwitchOffResources(
                                data.companyMap,
                                item => item.hasCloudBackupResources,
                                lang.BACKUP_RESOURCES)) {
                                return;
                            }
                            wizardStore.cloudBackupResourcesEnabled = value;
                            updateWanAccelerationEnabled();
                            updateHasInsiderProtection();
                        }}
                        label={lang.BACKUP_RESOURCES}
                        showSuffix={true}
                    />

                    {wizardStore.cloudBackupResourcesEnabled && (
                        <LinkField
                            label={lang.BACKUP_REPOSITORY}
                            onClick={showBackupResources}
                        >
                            {getBackupResourcesText(data)}
                        </LinkField>
                    )}

                    <ToggleField
                        name={null}
                        value={wizardStore.cloudReplicationResourcesEnabled}
                        onChange={(value) => {
                            if (!value && !canSwitchOffResources(
                                data.companyMap,
                                item => item.hasCloudReplicationResources,
                                lang.REPLICATION_RESOURCES)) {
                                return;
                            }
                            wizardStore.cloudReplicationResourcesEnabled = value;
                            updateWanAccelerationEnabled();
                        }}
                        label={lang.REPLICATION_RESOURCES}
                        showSuffix={true}
                    />

                    {wizardStore.cloudReplicationResourcesEnabled && (
                        <LinkField
                            label={lang.HARDWARE_PLAN}
                            onClick={showReplicaResources}
                        >
                            {getReplicaResourcesText(data)}
                        </LinkField>
                    )}

                    <ToggleField
                        name={null}
                        value={wizardStore.cloudVcdResourcesEnabled}
                        onChange={(value) => {
                            if (!value && !canSwitchOffResources(
                                data.companyMap,
                                item => item.hasCloudVcdResources,
                                lang.VMWARE_CLOUD_DIRECTOR_RESOURCES)) {
                                return;
                            }
                            wizardStore.cloudVcdResourcesEnabled = value;
                        }}
                        label={lang.VMWARE_CLOUD_DIRECTOR_RESOURCES}
                        showSuffix={true}
                    />

                    {wizardStore.cloudVcdResourcesEnabled && (
                        <LinkField
                            label={lang.VCD_ORGANIZATION}
                            onClick={showVcdResources}
                        >
                            {getVcdResourcesText(data)}
                        </LinkField>
                    )}

                    <LabelGroup label={`${capitalize(lang.DATA_TRANSFER_OUT, true)}:`}>
                        <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                            <ToggleField
                                value={wizardStore.dataTransferGbEnabled}
                                onChange={(value) => {
                                    if (value && !canLimitDataTransferOut(data.companyMap)) {
                                        return;
                                    }
                                    wizardStore.dataTransferGbEnabled = value;
                                }}
                                showSuffix={true}
                            />

                            <DataSizeInBytes
                                name={'dataTransferGb'}
                                disabled={!wizardStore.dataTransferGbEnabled}
                                unitType={'G'}
                                minValue={MIN_DATA_TRANSFER}
                                maxValue={MAX_DATA_TRANSFER}
                            />
                        </FormLayout>
                    </LabelGroup>

                    <ToggleField
                        name={'wanAccelerationEnabled'}
                        label={lang.WAN_ACCELERATION}
                        onChange={(value) => {
                            if (
                                !value
                                && !canSwitchOffFeature(
                                    data.companyMap,
                                    item => item.usedWanAcceleration,
                                    lang.WAN_ACCELERATION)
                            ) {
                                formApi.setValue('wanAccelerationEnabled', true);
                                return;
                            }
                            setWanAccelerationDisabledManually(!value);
                        }}
                        showSuffix={true}
                        disabled={
                            !wizardStore.cloudBackupResourcesEnabled
                            && !wizardStore.cloudReplicationResourcesEnabled
                            && !wizardStore.cloudVcdResourcesEnabled
                        }
                    />

                    {data.wanAccelerationEnabled && (
                        <LinkField
                            label={lang.WAN_ACCELERATION_SERVERS}
                            onClick={showWanAccelerationServers}
                        >
                            {getWanAccelerationServersText(data)}
                        </LinkField>
                    )}

                    <LabelGroup
                        disabled={!wizardStore.cloudBackupResourcesEnabled}
                        label={`${lang.ALLOW_KEEPING_DELETED_BACKUP_FILES}:`}
                    >
                        <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                            <ToggleField
                                value={wizardStore.hasInsiderProtection}
                                onChange={(value) => {
                                    if (
                                        !value
                                        && !canSwitchOffFeature(
                                            data.companyMap,
                                            item => item.usedInsiderProtectionPeriod,
                                            lang.ALLOW_KEEPING_DELETED_BACKUP_FILES)
                                    ) {
                                        return;
                                    }
                                    wizardStore.hasInsiderProtection = value;
                                    setHasInsiderProtectionDisabledManually(!value);
                                }}
                                showSuffix={true}
                                disabled={!wizardStore.cloudBackupResourcesEnabled} // TODO: use disabled from LabelGroup
                            />

                            <NumberInput
                                name={'insiderProtectionQuota'}
                                label={lang.MAX_DAYS}
                                disabled={!wizardStore.hasInsiderProtection}
                                minValue={MIN_INSIDER_PROTECTION_QUOTA}
                                maxValue={MAX_INSIDER_PROTECTION_QUOTA}
                            />
                        </FormLayout>
                    </LabelGroup>

                    <FormTitle>{lang.MICROSOFT_365}</FormTitle>

                    <ToggleField
                        name={null}
                        value={wizardStore.microsoft365ResourcesEnabled}
                        onChange={(value) => {
                            if (
                                !value
                                && !canSwitchOffResources(
                                    data.companyMap,
                                    item => item.hasVboResources,
                                    lang.MICROSOFT_365_MANAGED_BACKUP)
                            ) {
                                return;
                            }
                            wizardStore.microsoft365ResourcesEnabled = value;
                        }}
                        label={lang.MICROSOFT_365_MANAGED_BACKUP}
                        showSuffix={true}
                        helpText={lang.ENABLE_THE_POSSIBILITY_TO_PROTECT}
                    />

                    {wizardStore.microsoft365ResourcesEnabled && (
                        <>
                            <LinkField
                                label={lang.BACKUP_SERVER}
                                onClick={showMicrosoft365Servers}
                            >
                                {getMicrosoft365ServersText(data)}
                            </LinkField>

                            <LinkField
                                label={lang.BACKUP_REPOSITORY}
                                onClick={showMicrosoft365Repositories}
                                disabled={(data.vboResources.length ?? 0) === 0}
                            >
                                {getMicrosoft365RepositoriesText(data)}
                            </LinkField>
                        </>
                    )}

                    <FormTitle>{capitalize(lang.PUBLIC_CLOUD, true)}</FormTitle>

                    <ToggleField
                        name={'publicCloudEnabled'}
                        label={lang.VEEAM_BACKUP_FOR_PUBLIC_CLOUD_DEPLOYMENT}
                        showSuffix={true}
                        helpText={lang.ENABLE_THE_POSSIBILITY_TO_DEPLOY_AND_MANAGE}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
