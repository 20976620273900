/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { PortalSpinner } from '@veeam-vspc/components';

import { useConfiguratorService } from './hooks';
import { ConfiguratorContext } from './stores';

export interface ConfiguratorProviderProps {
    children: React.ReactElement | null;
    withoutConfigurator?: boolean;
}

export const ConfiguratorProvider: React.FC<ConfiguratorProviderProps> = ({ children, withoutConfigurator = false }) => {
    const { configurator, loading } = useConfiguratorService();

    if (withoutConfigurator) {
        return children;
    }

    return (
        <ConfiguratorContext.Provider value={configurator}>
            {!loading && children}
            {loading && <PortalSpinner />}
        </ConfiguratorContext.Provider>
    );
};
