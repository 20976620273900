/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { LinkButton, TextColumn } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';

export interface RunningJobsColumnProps {
    onClick: () => void;
    runningJobsCount: number;
    totalJobsCount: number;
}

export const RunningJobsColumn: React.FC<RunningJobsColumnProps> = ({ onClick, runningJobsCount = 0, totalJobsCount = 0 }) => {
    const lang = useLang();

    return (
        <TextColumn>
            <LinkButton
                onClick={onClick}
            >
                {`${runningJobsCount} ${lang.OF} ${totalJobsCount}`}
            </LinkButton>
        </TextColumn>
    );
};
