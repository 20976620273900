/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect, useState } from 'react';

import { PortalData } from '../../../../core/entries';
import { AppStore } from '../stores';
import { AppViewTypes } from '../enums';

import type { AppActions, AppServices, UseAppInitData } from '../interfaces';
import type { PageParams } from '../../RouterProvider/interfaces';

const initialState = {
    viewType: AppViewTypes.Loading,
    appStore: new AppStore(new PortalData()),
};
const getPageInitialState = (portalData: PortalData) => ({
    viewType: AppViewTypes.AppPage,
    appStore: new AppStore(portalData),
});

export const useAppInit = (appServices: AppServices, { pageName, portalData }: PageParams = {}): UseAppInitData => {
    const { tokenManagerService, portalService, transportService } = appServices;
    const [appState, setAppState] = useState(pageName ? getPageInitialState(portalData) : initialState);
    const { viewType, appStore } = appState;
    const setViewType = (viewType: AppViewTypes): void => setAppState({ ...appState, viewType });
    const appActions: AppActions = {
        loadLogin: (homeUrl = '/') => {
            transportService.request('/login/logout')
                .then(() => tokenManagerService.logout(homeUrl))
                .catch(() => tokenManagerService.logout(homeUrl));
            // setViewType(AppViewTypes.Loading); // uncomment when remove reload in logout
        },
        openLogin: (homeUrl = '/') => {
            transportService.request('/login/logout')
                .then(() => tokenManagerService.logout(homeUrl))
                .catch(() => tokenManagerService.logout(homeUrl));
            // setViewType(AppViewTypes.Login); // uncomment when remove reload in logout
        },
        loadApp: () => setViewType(AppViewTypes.Loading),
        openApp: () => setViewType(AppViewTypes.App),
    };
    const getPortalData = (): Promise<PortalData> => tokenManagerService.checkAuthorization()
        .then(() => false)
        .catch(() => true)
        .then((noAuth: boolean) => portalService.getData({ noAuth }));
    const hasBeforeAppDialogs = ({ portalUser, message }: PortalData): boolean =>
        (portalUser && portalUser.companyStatus === RCOP.Enums.CompanyStatus.Disabled) || !!message;

    useEffect(() => {
        if (viewType === AppViewTypes.Loading) {
            getPortalData()
                .then((data: PortalData) => {

                    if (data.logged) {
                        setAppState({
                            viewType: hasBeforeAppDialogs(data) ? AppViewTypes.Dialogs : AppViewTypes.App,
                            appStore: new AppStore(data),
                        });
                    } else {
                        setAppState({ viewType: AppViewTypes.Login, appStore: new AppStore(data) });
                    }
                })
                .catch((err) => {
                    // Error in getting portal data
                    // @TODO show error page
                    console.error(err);
                    setAppState({ ...appState, viewType: AppViewTypes.Error });
                });
        }
    });

    return { viewType, appStore, appActions };
};
