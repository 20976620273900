/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { ReportWizard } from './components/wizards/ReportWizard';

import type { ReportWizardProp } from './components/wizards/ReportWizard';

export const REPORT_PAGE = 'ReportPage';

export const ReportPage = (props: ReportWizardProp) => (
    <ReportWizard {...props} />
);
