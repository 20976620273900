/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Theme } from 'views/providers/AppProvider/interfaces';

const getProductTheme = (portalThemeName: string): Theme => {
    switch (portalThemeName) {
        case ('turquoise'): return 'teal';
        default: return portalThemeName as Theme;
    }
};

export {
    getProductTheme,
};
