/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormValidator,
    TextInput,
    Textarea,
    CONTROL_SIZE,
    FormLayout,
    isValid,
    useExternalFormApi,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { WizardStep, WizardStepData, FormErrors } from '@veeam-vspc/components';
import type { Vb365BackupJob } from '@veeam-vspc/models/rest';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useJobWizardStore } from '../../stores';

import type { JobWizardStore } from '../../stores';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const stepValidate = (data: Vb365BackupJob): FormErrors<Vb365BackupJob> => {
    const validator = new FormValidator(data);

    validator
        .validate('name')
        .string()
        .required()
        .maxLength(128);
    validator
        .validate('description')
        .string()
        .maxLength(2048);

    return validator.result();
};

export const getJobInfoStep = (lang: LangsServiceBase, store: JobWizardStore): WizardStep<Vb365BackupJob> => ({
    title: capitalize(lang.JOB_NAME),
    validate: () => {
        const validationResult = isValid(stepValidate, store.data);

        return validationResult;
    },
    render: data => <JobInfoStep {...data} />,
});

const JobInfoStep = ({ validationState }: WizardStepData<Vb365BackupJob>) => {
    const lang = useLang();
    const formApi = useExternalFormApi();
    const wizardStore = useJobWizardStore();

    const handleDataChange = (data: Vb365BackupJob) => {
        wizardStore.updateJobData(data);
    };

    return (
        <StepLayout
            title={capitalize(lang.JOB_NAME)}
            description={lang.SPECIFY_JOB_NAME_AND_ITS_DESCRIPTION}
        >
            <Form
                value={wizardStore.data}
                validate={stepValidate}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={handleDataChange}
            >
                <FormLayout inlineLabel>
                    <TextInput
                        name='name'
                        label={lang.NAME}
                        size={CONTROL_SIZE.xl}
                    />

                    <Textarea
                        name='description'
                        label={lang.DESCRIPTION}
                        size={CONTROL_SIZE.xl}
                        rows={8}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
};
