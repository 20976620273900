/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { AdvancedFilterKit, FieldLayout } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { HeatmapJobType } from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/core';

import { useJobsOverviewStore } from '../../../../store';
import { useLang } from '../../../../../../../../providers/LangProvider/hooks';

export const JobTypesFilter: React.FC = observer(() => {
    const store = useJobsOverviewStore();
    const lang = useLang();

    return (
        <FieldLayout label={`${capitalize(lang.JOB_TYPES, true)}:`}>
            <AdvancedFilterKit
                label={lang.FILTER}
                options={{
                    header: `${capitalize(lang.JOB_TYPES, true)}:`,
                    cols: [
                        {
                            groups: [
                                {
                                    items: [
                                        {
                                            label: lang.BACKUP,
                                            value: HeatmapJobType.Backup,
                                        },
                                        {
                                            label: lang.REPLICATION,
                                            value: HeatmapJobType.Replica,
                                        },
                                        {
                                            label: lang.BACKUP_COPY,
                                            value: HeatmapJobType.BackupCopy,
                                        },
                                        {
                                            label: lang.BACKUP_TO_TAPE,
                                            value: HeatmapJobType.BackupToTape,
                                        },
                                        {
                                            label: lang.FILE_TO_TAPE,
                                            value: HeatmapJobType.FileToTape,
                                        },
                                        {
                                            label: lang.COPY,
                                            value: HeatmapJobType.Copy,
                                        },
                                        {
                                            label: lang.SURE_BACKUP,
                                            value: HeatmapJobType.SureBackup,
                                        },
                                        {
                                            label: lang.SNAPSHOT,
                                            value: HeatmapJobType.Snapshot,
                                        },
                                        {
                                            label: lang.ARCHIVE,
                                            value: HeatmapJobType.Archive,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                }}
                value={store.jobTypes}
                onChange={store.changeJobTypes}
            />
        </FieldLayout>
    );
});
