/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { Icon, ICON_SIZES, STACK_DIRECTION, STACK_GAP, StackView } from '@veeam-vspc/components';

import infoSrc from 'images/info-light-gray.svg';
import { EmptyPlateStyled } from './EmptyPlate.styled';
import { Header } from '../Header';
import { TREND_WIDGET_HEIGHT } from '../../../BaseTrendChart/const';

export const EmptyPlate = ({ emptyText, title }) => (
    <StackView
        direction={STACK_DIRECTION.column}
        style={{ height: TREND_WIDGET_HEIGHT }}
    >
        <Header title={title} />

        <EmptyPlateStyled>
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <Icon size={ICON_SIZES.xl} src={infoSrc} style={{ width: '100%' }} />
                <div>{emptyText}</div>
            </StackView>
        </EmptyPlateStyled>
    </StackView>
);
