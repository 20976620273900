/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FileSizeUnitsInBytes, FileSizeUnitNames } from '@veeam-vspc/core';
import { TimeUnit } from '@veeam-vspc/models/web-controllers';

import type { LineChartData, ReportingPeriodInfo, SeriesName, TrendItem } from '../interfaces';

export const transformBaseChartData = (
    data: TrendItem[],
    reportingPeriodInfo: ReportingPeriodInfo,
    seriesNames: SeriesName[],
    formatDimension = false,
): Promise<LineChartData> => {
    const categories = [];
    const series = [];
    const unitName: string = FileSizeUnitNames.B;
    const unitPrefixes: string[] = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    const unitSize: number = FileSizeUnitsInBytes.KB;
    let empty = true;
    let max = 0;
    let unitIndex = 0;

    seriesNames.forEach((sName) => {
        series.push({ name: sName.label, data: new Array(12).fill(null, 0, reportingPeriodInfo.interval.count) });
    });

    data.forEach((item, i) => {
        const d = new Date(item.date.slice(0, -6));

        categories.push(reportingPeriodInfo.aggregation.unit === TimeUnit.Months
            ? `${RCOP.Const.MonthNames[d.getMonth()].short}`
            : `${d.getDate()} ${RCOP.Const.MonthNames[d.getMonth()].short}`
        );

        item.counters.forEach((c) => {
            series.find(s => s.name === seriesNames.find(sName => sName.type === c.type).label).data[i] = c.value;

            if (c.value !== null) {
                empty = false;
            }
        });
    });

    if (formatDimension) {
        series.forEach(s => s.data.forEach((v) => {
            max = v > max ? max = v : max;
        }));

        for (let i = 1; i < unitPrefixes.length; i += 1) {
            if (max < unitSize) {
                break;
            }

            max /= unitSize;
            unitIndex += 1;
        }

        series.forEach(s => s.data.forEach((v, i) => {
            s.data[i] = v === null ? v : Math.round(v / Math.pow(2, 10 * unitIndex) * 100) / 100;
        }));
    }

    return Promise.resolve({
        categories,
        dimension: formatDimension ? `${unitPrefixes[unitIndex]}${unitName}` : null,
        empty,
        series,
    });
};
