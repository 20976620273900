/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ACTION_VIEW, SortingMode } from '@veeam-vspc/components';
import { capitalize, deepCopy } from '@veeam-vspc/core';

import type { ResellerCloudWanResourceMapModel } from '@veeam-vspc/models/web-controllers';
import type { WizardStepData, GridStore } from '@veeam-vspc/components';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { ActionsToolbar } from './components/ActionsToolbar';

import type { ResellerData } from '../../../../interfaces';

type GridItemType = ResellerCloudWanResourceMapModel;

export interface BackupResourcesProps extends WizardStepData<ResellerData> {
    hidePanel: () => void;
}

export const WanAccelerationServers = observer((props: BackupResourcesProps) => {
    const lang = useLang();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<GridItemType, void, void>>();

    const [items] = useState(deepCopy(data.cloudWanResources));

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={capitalize(lang.WAN_ACCELERATION_SERVERS)}
            description={lang.SELECT_ONE_OR_MORE_WAN_ACCELERATION_SERVERS}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.cloudWanResources = items;

                        onDataChange(data);

                        hidePanel();
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid <GridItemType, void, void>
                defaultColumnWidth={100}
                columns={[
                    {
                        field: 'cloudConnectResourceName',
                        title: capitalize(lang.WAN_ACCELERATION_SERVER),
                    },
                ]}
                data={() => Promise.resolve({
                    data: items,
                    meta: {
                        pagingInfo: {
                            total: items?.length,
                        },
                    },
                } as BaseSuccessRequestResponse<GridItemType[]>)}
                toggleable={false}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'cloudConnectResourceUid',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => (
                        <ActionsToolbar
                            items={items}
                            cloudConnectAgentUids={data.cloudConnectAgents.map(agent => agent.cloudConnectAgentUid)}
                        />
                    ),
                ]}
                sortingMode={SortingMode.Local}
                api={api}
            />
        </SidePanelForm>
    );
});
