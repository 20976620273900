/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, StackView, Text, ICON_SIZES, STACK_ALIGN, STACK_GAP, TEXT_SIZE } from '@veeam-vspc/components';

interface NameColumnProps {
    icon: string;
    name: string;
}

export const NameColumn: React.FC<NameColumnProps> = ({ icon, name }) => (
    <StackView gap={STACK_GAP.s} align={STACK_ALIGN.center}>
        <Icon src={icon} size={ICON_SIZES.xl} />
        <Text size={TEXT_SIZE.l}>{name}</Text>
    </StackView>
);
