
/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE } from '@veeam-vspc/components';

import { AdvancedToolbar } from 'views/components/AdvancedToolbar';
import { useLang } from 'views/providers/LangProvider/hooks';

export const AvailableOrganizationsToolbar: React.FC = () => {
    const lang = useLang();

    return (
        <AdvancedToolbar
            view={TOOLBAR_VIEW_TYPE.default}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            hasSearchButton={true}
                            maxLength={100}
                            placeholder={lang.ORGANIZATION}
                            name='name'
                        />
                    ),
                },
            ]}
        />
    );
};
