/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { useAppStore } from 'views/providers/AppProvider/hooks';
import {
    StateByRegions,
    ActiveAlarms,
    ManagementAgents,
    VeeamCloudConnect,
    CompaniesOverview,
    VeeamBackupReplication,
    VeeamBackupForMicrosoft365,
    ProductUpdates,
    OverviewPageStyled,
    SummarySection,
    InfrastructureHealthSection,
    CompaniesHealthSection,
    ProductUpdatesSection,
    VeeamOneAndAgents,
} from './components';

export const OverviewPage: React.FC = () => {
    const { portalUser } = useAppStore();

    const hideCloudConnect = portalUser.isServiceProviderGroup() || portalUser.isReadOnlyRole() || portalUser.isPortalOperator();

    return (
        <OverviewPageStyled>
            <SummarySection>
                <ActiveAlarms />
                <ManagementAgents />
            </SummarySection>

            <CompaniesHealthSection>
                <CompaniesOverview />
                <StateByRegions />
            </CompaniesHealthSection>

            <InfrastructureHealthSection>
                { !hideCloudConnect && <VeeamCloudConnect /> }
                <VeeamBackupReplication />
                <VeeamBackupForMicrosoft365 />
                <VeeamOneAndAgents />
            </InfrastructureHealthSection>

            <ProductUpdatesSection>
                <ProductUpdates />
            </ProductUpdatesSection>
        </OverviewPageStyled>
    );
};
