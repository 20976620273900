/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { VmBackupsService } from './services/vm-backups.service';
import { useLang } from 'views/providers/LangProvider/hooks';

export const VmBackups: React.FC = () => {
    const lang = useLang();

    const params = {
        colors: [palette.B400],
        yTitle: lang.BACKUPS,
    };

    return (
        <BaseTrendChart
            params={params}
            service={VmBackupsService}
            title={{ text: capitalize(lang.VM_BACKUPS) }}
        />
    );
};
