/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { ToolbarWithExport } from 'views/components/ToolbarWithExport';

import type { GridExportProps } from 'views/hooks';

export interface RestorePointDialogToolbarProps extends GridExportProps {
    method?: string;
}

export const RestorePointDialogToolbar: React.FC<RestorePointDialogToolbarProps> = ({ method = 'GetRestorePoints', ...restProps }) => (
    <ToolbarWithExport
        {...restProps}
        method={method}
    />
);
