/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    LinkButton,
    Icon,
    STACK_ALIGN,
    ICON_SIZES,
    STACK_DISTRIBUTION,
    SPACE_FILL,
} from '@veeam-vspc/components';
import * as React from 'react';

import learnMore from 'images/learn-more.svg';

import type { TableWidgetProps } from '../../interfaces';

interface NoDataProps {
    noDataConfig: TableWidgetProps<unknown>['noDataConfig'];
}

export const NoData: React.FC<NoDataProps> = props => (
    <StackView
        direction={STACK_DIRECTION.column}
        gap={STACK_GAP.s}
        align={STACK_ALIGN.center}
        distribution={STACK_DISTRIBUTION.center}
        spaceFill={SPACE_FILL.all}
    >
        <Icon src={props.noDataConfig.icon} size={props.noDataConfig.iconSize || ICON_SIZES.xxl} />

        <LinkButton
            onClick={props.noDataConfig.linkHandler}
            iconBefore={<Icon src={learnMore} />}
        >
            {props.noDataConfig.linkText}
        </LinkButton>
    </StackView>
);
