/**
 * Copyright © Veeam Software Group GmbH.
 */

import { immediatelyValidateFlag } from '@veeam-vspc/components';
import { FormValidator } from '@veeam-vspc/components/src/index';

import { PortalUserRoles } from 'core/entries/portal-user/enums';

import type { CompanyUserModel } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/company-user-model';

export const stepValidate = (data: CompanyUserModel, isEdit: boolean) => {
    const validator = new FormValidator(data);

    if (data.userRole === PortalUserRoles.Subtenant) {
        validator.validate('description')
            .string()
            .maxLength(1000);
    }

    if (!isEdit && data.isVcdCompany && data.userRole === PortalUserRoles.Subtenant) {
        validator.validate('vcdUserId')
            .string()
            .required();
    } else if (isEdit) {
        if (data.password || data.confirmPassword) {
            validator.validate('password')
                .string()
                .required();

            validator.validate('confirmPassword')
                .string()
                .required()
                .check((value) => {
                    if (value !== validator.value.password) {
                        return RCOP.Lang.PASSWORDS_DO_NOT_MATCH;
                    }
                });

            data.selfEdit && validator.validate('oldPassword')
                .string()
                .required();
        }
    } else {
        validator.validate('login')
            .string()
            .maxLength(128)
            .required()
            .check((value) => {
                const result = RCOP.utils.Validator.vacUserName(value);

                if (typeof result === 'string') {
                    return `${result}${immediatelyValidateFlag}`;
                }
            });

        validator.validate('password')
            .string()
            .required();

        validator.validate('confirmPassword')
            .string()
            .required()
            .check((value) => {
                if (value !== validator.value.password) {
                    return RCOP.Lang.PASSWORDS_DO_NOT_MATCH;
                }
            });
    }

    return validator.result();
};
