/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { UserUiData } from '@veeam-vspc/models/web-controllers';

import { RegionsGrid } from './components/RegionsGrid';
import { RegionsMap } from './components/RegionsMap';

export const StateByRegions: React.FC = () => {
    const isMapAvailable = (RCOP.UI_PREFERENCES as UserUiData).visibility.mapWidget;

    if (isMapAvailable) {
        return <RegionsMap />;
    }

    return <RegionsGrid />;
};
