/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Checkbox,
    Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    NoLabelGroup,
    NumberInput,
    TextInput,
    useExternalFormApi,
    ValidationState,
    Text,
    StackView,
    STACK_DIRECTION,
    STACK_ALIGN,
    STACK_DISTRIBUTION,
    SIZE_XL,
    TEXT_WEIGHT,
    STACK_GAP,
    dataSizeToString,
} from '@veeam-vspc/components';
import { deepCopy, formatStr } from '@veeam-vspc/core';

import type { ContentRendererProps } from '@veeam-vspc/components';

import { DataSizeInBytes } from 'components/controls/DataSizeInBytes';
import { useLang } from 'views/providers/LangProvider/hooks';
import { WanAcceleration } from 'views/components/WanAcceleration';
import { MAX_INTEGER_32 } from 'core/const';
import { useCompanyWizardStore } from '../../../../../../hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { getMaxQuotaOnCc, isPbAvailableOnCc } from 'core/utils/is-pb-vailable-on-cc';

import type { AvailableBackupResource } from 'core/interfaces';
import type { BackupResource } from '../../../../../../interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

interface Props {
    deactivate: () => void;
    onConfirm: (data: BackupResource) => void;

    data?: BackupResource;
    existingItems: BackupResource[];
    companyId?: number;
    cloudConnectAgentUid: string;
}

const MIN_QUOTA = 0;
const MAX_QUOTA = MAX_INTEGER_32;

const MIN_STORAGE_QUOTA = 1;

const getAvailableQuotaSizeGb = (uniqueUid: string, ccServers: AvailableBackupResource[], pbAvailable: boolean, currentQuotaSizeGb: number) => {
    const selection = ccServers.find(item => item.uniqueUid === uniqueUid);

    if (!selection) {
        return 0;
    }
    const gbMultiplier = Math.pow(1024, 3);

    if (selection.availableQuotaSize === -1) {
        return getMaxQuotaOnCc(pbAvailable);
    }

    return selection.availableQuotaSize / gbMultiplier + currentQuotaSizeGb;
};

const formValidate = (
    data: BackupResource,
    lang: LangsServiceBase,
    isEdit: boolean,
    existingItems: BackupResource[],
    ccServers: AvailableBackupResource[],
    pbAvailable: boolean,
    currentQuotaSizeGb: number
) => {
    const validator = new FormValidator(data);

    validator.validate('uniqueUid')
        .string()
        .required();

    validator.validate('cloudRepositoryFriendlyName')
        .string()
        .required()
        .maxLength(256)
        .check((value => existingItems.find(item => item.cloudRepositoryFriendlyName === value) ? lang.CLOUD_REPOSITORY_WITH_THE_SAME_NAME : ''));

    const maxQuota = getAvailableQuotaSizeGb(data.uniqueUid, ccServers, pbAvailable, currentQuotaSizeGb);

    if (data.uniqueUid) {
        validator.validate('quota.storageGb')
            .number()
            .required()
            .min(MIN_STORAGE_QUOTA)
            .max(maxQuota, formatStr(lang.QUOTA_LIMIT_FOR_THIS, dataSizeToString(maxQuota, 0, 'G')));

        if (!data.quota.quotasIsUnlimited) {
            validator.validate('quota.vms')
                .number()
                .required()
                .min(MIN_QUOTA)
                .max(MAX_QUOTA);

            validator.validate('quota.workstations')
                .number()
                .required()
                .min(MIN_QUOTA)
                .max(MAX_QUOTA);

            validator.validate('quota.servers')
                .number()
                .required()
                .min(MIN_QUOTA)
                .max(MAX_QUOTA);
        }

        if (data.isWanAccelerationEnabled) {
            validator.validate('wanAcceleratorUid')
                .string()
                .required();
        }
    }

    return validator.result();
};

const getDefaultData = (): BackupResource => ({
    id: -1,
    isDefault: false,
    cloudRepositoryFriendlyName: '',
    cloudConnectAgentUid: null,
    cloudConnectResourceName: '',
    cloudConnectResourceUid: null,
    quota: {
        quotasIsUnlimited: true,
        storageGb: 100,
        servers: 0,
        vms: 0,
        workstations: 0,
        isStorageQuotaUnlimited: false,
    },
    isWanAccelerationEnabled: false,
    countOfSubtenants: 0,
});

export const QuotaDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;
    const { notificationService } = useAppServices();

    const isEdit = !!props.data;

    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.cloudRepositoryFriendlyName === props.data.cloudRepositoryFriendlyName), 1);
        }
        return result;
    });

    const [filteredAvailableBackupResources, setFilteredAvailableBackupResources] = useState<AvailableBackupResource[]>([]);

    const [data, setData] = useState<BackupResource>(() => {
        const dataToEdit = props.data;
        const defaultData = getDefaultData();

        if (dataToEdit && dataToEdit.quota.quotasIsUnlimited) {
            // unlimited quotas
            dataToEdit.quota.vms = defaultData.quota.vms;
            dataToEdit.quota.servers = defaultData.quota.servers;
            dataToEdit.quota.workstations = defaultData.quota.workstations;
        }

        return dataToEdit ? deepCopy(dataToEdit) : defaultData;
    });

    const formApi = useExternalFormApi<BackupResource>();
    const [loading, setLoading] = useState(false);
    const [validationState] = useState(new ValidationState());

    const wizardStore = useCompanyWizardStore();

    const [pbAvailable] = useState(isPbAvailableOnCc(wizardStore.cloudConnectAgentsCache.value, props.cloudConnectAgentUid));

    const [currentQuotaSizeGb] = useState<number>(wizardStore.initialData?.quotas
        .find(item => item.uniqueUid === data.uniqueUid)?.quota.storageGb || 0);

    useEffect(() => {
        setLoading(wizardStore.availableBackupResourcesCache.loading);
        wizardStore.availableBackupResourcesCache.load({
            cloudConnectAgentUid: props.cloudConnectAgentUid,
            companyId: props.companyId,
        })
            .then((items) => {
                setFilteredAvailableBackupResources(items.filter(item => !existingItems
                    .find(existingItem => existingItem.uniqueUid === item.uniqueUid)
                ));
                setLoading(false);
            });

        wizardStore.wanAcceleratorsCache.load({ cloudConnectAgentUid: props.cloudConnectAgentUid });
    }, []);

    return (
        <Dialog
            header={`${isEdit ? lang.EDIT : lang.ADD} ${lang.QUOTA}`}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            if (data.quota.quotasIsUnlimited) {
                                data.quota.vms = -1;
                                data.quota.servers = -1;
                                data.quota.workstations = -1;
                            }

                            props.onConfirm && props.onConfirm(formApi.value);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={loading}
        >
            <Form <BackupResource>
                value={data}
                validate={data => formValidate(
                    data,
                    lang,
                    isEdit,
                    existingItems,
                    wizardStore.availableBackupResourcesCache.value,
                    pbAvailable,
                    currentQuotaSizeGb
                )}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'uniqueUid'}
                        label={RCOP.Lang.BACKUP_REPOSITORY}
                        placeholder={lang.SELECT_AN_EXISTING_BACKUP_REPOSITORY}
                        data={filteredAvailableBackupResources}
                        valueGetter={item => item.uniqueUid}
                        textGetter={item => item.cloudConnectResourceName}
                        size={defaultFieldWidth}
                        optionRenderer={({ text, option }: ContentRendererProps<AvailableBackupResource>) => (
                            <StackView
                                size={{ height: SIZE_XL }}
                                direction={STACK_DIRECTION.row}
                                align={STACK_ALIGN.center}
                                distribution={STACK_DISTRIBUTION.firstFill}
                            >
                                <Text truncate>{`${text}`}</Text>

                                <StackView direction={STACK_DIRECTION.row} align={STACK_ALIGN.center} gap={STACK_GAP.xxs}>
                                    <Text>{`${lang.AVAILABLE_SPACE}:`}</Text>
                                    <Text weight={TEXT_WEIGHT.bold}>{`${dataSizeToString(option.availableSpace, 1)}`}</Text>
                                </StackView>
                            </StackView>
                        )}
                        onChange={(value, itemIndex) => {
                            const item = filteredAvailableBackupResources[itemIndex];
                            formApi.setValue('cloudConnectAgentUid', item.cloudConnectAgentUid);
                            formApi.setValue('cloudConnectResourceName', item.cloudConnectResourceName);
                            formApi.setValue('cloudConnectResourceUid', item.cloudConnectResourceUid);
                        }}
                    />

                    <TextInput
                        name={'cloudRepositoryFriendlyName'}
                        label={lang.CLOUD_REPOSITORY_NAME}
                        placeholder={lang.SPECIFY_A_FRIENDLY_REPOSITORY}
                        size={defaultFieldWidth}
                    />

                    <NoLabelGroup
                        content={(
                            <FormLayout inlineLabel>
                                <DataSizeInBytes
                                    disabled={!data.uniqueUid}
                                    name={'quota.storageGb'}
                                    label={lang.REPOSITORY_QUOTA}
                                    unitType={'G'}
                                    maxUnit={pbAvailable ? 'P' : 'T'}
                                    minValue={MIN_STORAGE_QUOTA}
                                    maxValue={getAvailableQuotaSizeGb(
                                        data.uniqueUid,
                                        wizardStore.availableBackupResourcesCache.value,
                                        pbAvailable,
                                        currentQuotaSizeGb
                                    )}
                                />
                            </FormLayout>
                        )}
                    />

                    <NoLabelGroup
                        content={(
                            <Checkbox
                                name={'quota.quotasIsUnlimited'}
                            >
                                {lang.SET_UNLIMITED_QUOTA}
                            </Checkbox>
                        )}
                        subContent={(
                            <FormLayout inlineLabel disabled={data.quota.quotasIsUnlimited}>
                                <NumberInput name={'quota.vms'} label={lang.VMS_QUOTA} minValue={MIN_QUOTA} maxValue={MAX_QUOTA} />
                                <NumberInput name={'quota.workstations'} label={lang.WORKSTATIONS_QUOTA} minValue={MIN_QUOTA} maxValue={MAX_QUOTA} />
                                <NumberInput name={'quota.servers'} label={lang.SERVERS_QUOTA} minValue={MIN_QUOTA} maxValue={MAX_QUOTA} />
                            </FormLayout>
                        )}
                    />

                    <WanAcceleration
                        value={data}
                        wanAccelerators={wizardStore.wanAcceleratorsCache.value}
                        hidden={!wizardStore.isWanAcceleratorsEnabled}
                        disabled={!data.uniqueUid}
                        onChangeEnabled={(checked) => {
                            const currentBackupRepository = wizardStore.availableBackupResourcesCache.value
                                .find(res => res.uniqueUid === data.uniqueUid);

                            if (checked && !currentBackupRepository?.isWanAccelerationAvailable) {
                                formApi.setValue('isWanAccelerationEnabled', false);
                                notificationService.error(lang.SERVICES, lang.THIS_REPOSITORY_DOES_NOT);
                            }
                        }}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
});
