/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    Combobox,
    CONTROL_SIZE,
    Dialog,
    Form,
    FormLayout,
    SPACE_FILL,
    STACK_DIRECTION,
    STACK_DISTRIBUTION,
    STACK_GAP,
    StackView,
    useModal,
} from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { MODAL_SIZE_MS } from '@veeam-vspc/components/src/constants';

import type { ExternalFormApi } from '@veeam-vspc/components';

import { AuthTypes, FormFieldNames } from './enums';
import { useEmailServerSettingsStore } from '../../hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useEmailServerSettingsDialogController } from './hooks';
import { formValidate, getAuthTypes, resetHostField } from './helpers';
import {
    EmailServerBasicSettingsFields,
    EmailServerSettingsDialogNotes,
    EmailServerModernSettingsFields,
    UntrustedCertificateDialog,
} from './components';

import type { EmailServerSettingsFormData, UntrustedCertificateDialogData, EmailServiceSettingsDialogProps } from './interfaces';

export const EmailServerSettingsDialog: React.FC<EmailServiceSettingsDialogProps> = observer((props) => {
    const lang = useLang();
    const { deactivateDialog } = props;
    const serverSettingsStore = useEmailServerSettingsStore();
    const { storeFormValidationState, originalEmailServerSettings, storeFormData, storeFormApi } = serverSettingsStore;

    const [hasOAuth2, setHasOAuth2] = useState(Boolean(originalEmailServerSettings.smtp?.oAuth2Credential));

    const [untrustedCertDialog, untrustedCertDialogActions] = useModal<UntrustedCertificateDialogData>({
        render: ({ deactivate, data }) => <UntrustedCertificateDialog deactivateDialog={deactivate} {...data} />,
    });

    const { dialogTitle, onFormChange, onSaveActionClick } = useEmailServerSettingsDialogController(untrustedCertDialogActions);

    const onAuthTypeChange = (newValue: AuthTypes, formApi: ExternalFormApi<EmailServerSettingsFormData>): void => {
        if (newValue === AuthTypes.Modern) {
            setHasOAuth2(true);
            return;
        }

        resetHostField(formApi);
        setHasOAuth2(false);
    };

    return (
        <>
            {untrustedCertDialog}

            <Dialog
                onRequestClose={deactivateDialog}
                header={dialogTitle}
                size={{
                    width: MODAL_SIZE_MS.width,
                    height: MODAL_SIZE_MS.height,
                }}
                actions={[
                    {
                        text: hasOAuth2 ? lang.AUTHORIZE : lang.SAVE,
                        onClick: () => onSaveActionClick(deactivateDialog),
                    },
                    {
                        text: lang.CANCEL,
                        onClick: deactivateDialog,
                        view: ACTION_VIEW.secondary,
                    },
                ]}
            >
                <StackView
                    gap={STACK_GAP.s}
                    direction={STACK_DIRECTION.column}
                    spaceFill={SPACE_FILL.all}
                    distribution={STACK_DISTRIBUTION.firstFill}
                >
                    <Form
                        value={storeFormData}
                        externalFormApi={storeFormApi}
                        validationState={storeFormValidationState}
                        validate={formData => formValidate(formData)}
                        onChange={onFormChange}
                    >
                        <StackView
                            gap={STACK_GAP.m}
                            direction={STACK_DIRECTION.column}
                        >
                            <FormLayout inlineLabel>
                                <Combobox
                                    data={getAuthTypes(lang)}
                                    size={CONTROL_SIZE.m}
                                    name={FormFieldNames.AuthType}
                                    label={lang.AUTHENTICATION_TYPE}
                                    valueGetter={item => item.id}
                                    textGetter={item => item.name}
                                    onChange={(value: AuthTypes) => onAuthTypeChange(value, storeFormApi)}
                                />

                                {hasOAuth2
                                    ? <EmailServerModernSettingsFields />
                                    : <EmailServerBasicSettingsFields />}
                            </FormLayout>
                        </StackView>
                    </Form>

                    {hasOAuth2 && <EmailServerSettingsDialogNotes />}
                </StackView>
            </Dialog>
        </>
    );
});
