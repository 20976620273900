/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
} from '@veeam-vspc/components';

import type { ToolbarItems } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { Microsoft365RepositoryDialog } from '../Microsoft365RepositoryDialog';
import addIcon from 'images/actions/add.svg';
import removeIcon from 'images/actions/remove.svg';
import editIcon from 'images/actions/edit.svg';
import { CompanyService } from 'core/services/company-service';
import { useAppServices } from 'views/providers/AppProvider/hooks';

import type { VboServer } from '../../../../../../interfaces';
import type { VboRepository } from 'core/interfaces';

interface Props {
    items: VboRepository[];
    companyId?: number;
    companyUid?: string;
    vboServers: VboServer[];
}

export const ActionsToolbar = ({ items, companyId, companyUid, vboServers }: Props) => {
    const lang = useLang();
    const gridApi = useGridApi<VboRepository, void, void>();
    const { notificationService } = useAppServices();

    const [quotaDialog, quotaDialogActions] = useModal<VboRepository>({
        render: ({ deactivate, data }) => (
            <Microsoft365RepositoryDialog
                data={data}
                existingItems={items}
                companyId={companyId}
                companyUid={companyUid}
                vboServers={vboServers}
                onConfirm={(newData) => {
                    if (data) {
                        items[items.findIndex(item => item.repositoryUid === data.repositoryUid)] = newData;
                    } else {
                        items.push(newData);
                    }
                    gridApi.reloadGrid();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const toolbarItems: ToolbarItems = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: addIcon,
            text: lang.ADD,
            onClick: () => {
                quotaDialogActions.activate();
            },
            decorators: [
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: editIcon,
            text: lang.EDIT,
            onClick: async() => {
                const selected = gridApi.selected[0];
                quotaDialogActions.activate(selected);
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: removeIcon,
            text: lang.REMOVE,
            onClick: async() => {
                const selected = gridApi.selected[0];

                if (companyUid && selected.instanceUid) {
                    const serverInstanceUid = vboServers.find(server => server.vboServerUid === selected.vboServerUid).instanceUid;
                    const resourceUsage = await CompanyService.getCompanyVb365RepositoryUsage(companyUid, serverInstanceUid, selected.instanceUid);
                    if (resourceUsage.length > 0) {
                        notificationService.error(lang.VEEAM_BACKUP_FOR_MICROSOFT_365_REPOSITORIES, lang.CANNOT_REMOVE_THE_SELECTED_REPOSITORY);
                        return;
                    }
                }

                items.splice(items.findIndex(item => item.repositoryUid === selected.repositoryUid), 1);
                gridApi.reloadGrid();
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
    ];

    return (
        <>
            <Toolbar items={toolbarItems} />
            {quotaDialog}
        </>
    );
};
