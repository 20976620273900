/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    NoteBar,
    NOTEBAR_STATUS,
    ToggleKit,
    useExternalFormApi,
    Text,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    FieldLayout,
} from '@veeam-vspc/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { CompanyMfaStatuses } from 'core/enums';

import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';

interface WizardData {
    mfaPolicyStatus: CompanyMfaStatuses;
    isAllowedToSwitchMfaPolicyStatus: boolean;
}

interface Props extends WizardStepData<WizardData> {
    description: string;
    note: string[];
}

export const getCommonMultiFactorStep = (lang: LangsServiceBase, description: string, note: string[]): WizardStep<WizardData> => ({
    title: lang.MULTI_FACTOR_AUTHENTICATION,
    render: stepData => <MultiFactorStep {...stepData} description={description} note={note} />,
});

const MultiFactorStep = ({ data, validationState, onDataChange, description, note }: Props) => {
    const lang = useLang();
    const formApi = useExternalFormApi();

    return (
        <StepLayout
            title={lang.MULTI_FACTOR_AUTHENTICATION}
            description={description}
        >
            <Form
                value={data}
                validationState={validationState}
                onChange={onDataChange}
                externalFormApi={formApi}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                    <FieldLayout
                        inlineLabel
                        label={`${lang.ENFORCE_MFA_FOR_LOCAL_USERS}:`}
                    >
                        <ToggleKit
                            value={data.mfaPolicyStatus === CompanyMfaStatuses.Enabled}
                            onChange={(checked) => {
                                if (!checked && !data.isAllowedToSwitchMfaPolicyStatus) {
                                    core.notificationService.error(lang.ENFORCE_THE_MFA_ACCESS, lang.CANNOT_DISABLE_THE_MFA_FEATURE);
                                    formApi.setValue('mfaPolicyStatus', CompanyMfaStatuses.Enabled);
                                } else {
                                    formApi.setValue('mfaPolicyStatus', checked ? CompanyMfaStatuses.Enabled : CompanyMfaStatuses.Disabled);
                                }
                            }}
                            showSuffix={true}

                        />
                    </FieldLayout>

                    <NoteBar status={NOTEBAR_STATUS.info}>
                        <StackView style={{ flexShrink: 1 }} direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                            {note.map(s => (<Text>{s}</Text>))}
                        </StackView>
                    </NoteBar>
                </StackView>
            </Form>
        </StepLayout>
    );
};
