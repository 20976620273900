/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ConfigStates } from '../enums';

import type { SectionConfigItemData } from '../interfaces';
import type { ConfigSectionIds } from '../enums';

export class SectionConfigItem {

    id: ConfigSectionIds;
    actions: SectionConfigItemData['actions'];
    columns: SectionConfigItemData['columns'];
    items: SectionConfigItemData['items'];

    protected defaultActionsState: ConfigStates;
    protected defaultColumnsState: ConfigStates;
    protected defaultItemsState: ConfigStates;

    constructor({ id, actions, columns, items, defaultActionsState, defaultColumnsState, defaultItemsState }: SectionConfigItemData) {
        if (!id) {
            throw Error('"id" property is required');
        }

        this.id = id;
        this.actions = actions ?? {};
        this.columns = columns ?? {};
        this.items = items ?? {};
        this.defaultActionsState = defaultActionsState ?? ConfigStates.Visible;
        this.defaultColumnsState = defaultColumnsState ?? ConfigStates.Visible;
        this.defaultItemsState = defaultItemsState ?? ConfigStates.Visible;
    }

    getActionState(actionNameOrId: string): ConfigStates {
        return this.actions[actionNameOrId] ?? this.defaultActionsState;
    }

    getColumnState(columnField: string): ConfigStates {
        return this.columns[columnField] ?? this.defaultColumnsState;
    }

    getItemState(itemId: string): ConfigStates {
        return this.items[itemId] ?? this.defaultItemsState;
    }

}
