/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    ComboboxKit,
    constants,
    CONTROL_SIZE,
    Icon,
    ICON_SIZES,
    StackView,
    STACK_ALIGN,
    STACK_GAP,
    Text,
    TEXT_SIZE,
    useAppStorage,
} from '@veeam-vspc/components';
import { Microsoft365Workloads, ProtectedDataFilterBy } from '@veeam-vspc/models/web-controllers';
import { createLinkControl } from '@veeam/components';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import type { BaseProtectedDataWorkloadOverviewMicrosoft365Workloads, ProtectedDataOverviewRequest } from '@veeam-vspc/models/web-controllers';

import { TableWidget } from 'views/components/TableWidget';
import { NameColumn } from 'views/components/TableWidget/components/NameColumn';
import successIcon from 'images/success.svg';
import groupIcon from 'images/pages/protected-data-summary/microsoft365-group.svg';
import siteIcon from 'images/pages/protected-data-summary/microsoft365-site.svg';
import teamIcon from 'images/pages/protected-data-summary/microsoft365-team.svg';
import userIcon from 'images/pages/protected-data-summary/microsoft365-user.svg';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useIntervalRequest } from 'views/hooks';
import { EmptyColumnPlaceholder } from 'views/components/TableWidget/components/EmptyColumnPlaceholder';
import { NoInfoColumn } from 'views/components/TableWidget/components/NoInfoColumn';
import { getBeginDate, getEndDate } from '../../utils';
import { getHelpResourceLink } from 'core/utils';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import vboNotConnected from 'images/vbo-not-connected.svg';
import { useProtectedDataSummaryStore } from '../../store';

type ComboboxItem = { value: ProtectedDataFilterBy; text: string; };

const StyledCombobox = styled(ComboboxKit)`
    position: absolute;
    top: ${constants.SPACING_M};
    right: ${constants.SPACING_M};
`;

const icons = {
    [Microsoft365Workloads.Groups]: groupIcon,
    [Microsoft365Workloads.Sites]: siteIcon,
    [Microsoft365Workloads.Teams]: teamIcon,
    [Microsoft365Workloads.Users]: userIcon,
};

const FILTER_TYPE_KEY = 'protectedDataSummary.microsoft365.filter-type';

export const Microsoft365: React.FC = observer(() => {
    const appStorage = useAppStorage();
    const store = useProtectedDataSummaryStore();
    const lang = useLang();
    const didMount = useRef(false);
    const abortController = useRef(new AbortController());
    const { portalUser } = useAppStore();

    const names = useMemo(() => ({
        [Microsoft365Workloads.Groups]: lang.GROUPS,
        [Microsoft365Workloads.Sites]: lang.SITES,
        [Microsoft365Workloads.Teams]: lang.TEAMS,
        [Microsoft365Workloads.Users]: lang.USERS,
    }), []);

    const savedFilterType: ProtectedDataFilterBy = appStorage.getItem(FILTER_TYPE_KEY) as ProtectedDataFilterBy ?? ProtectedDataFilterBy.Rpo;
    const [filterType, setFilterType] = useState<ProtectedDataFilterBy>(savedFilterType);

    const handleFilterTypeChange = (value: ProtectedDataFilterBy) => {
        appStorage.setItem(FILTER_TYPE_KEY, value);
        setFilterType(value);
    };

    const request = useIntervalRequest<ProtectedDataOverviewRequest, BaseProtectedDataWorkloadOverviewMicrosoft365Workloads[]>(
        '/ProtectedOverview/Microsoft365',
        {
            beginDate: getBeginDate(store.timePeriod).toISOString(),
            endDate: getEndDate(store.timePeriod).toISOString(),
            filterType,
            slaPercent: store.slaPercent,
            rpoDays: store.rpoDays,
        },
        {
            signal: abortController.current.signal,
        });

    useEffect(() => {
        if (didMount.current) {
            abortController.current.abort();

            abortController.current = new AbortController();
            return request.forceRequest();
        }

        didMount.current = true;
    }, [store.timePeriod, store.slaPercent, store.rpoDays, filterType]);

    const items = request.isError || !Array.isArray(request.data)
        ? []
        : (request.data as BaseProtectedDataWorkloadOverviewMicrosoft365Workloads[]);

    const isResellerUser = portalUser.isServiceProviderGroup();
    const noDataUrl = isResellerUser ? getHelpResourceLink('651') : getHelpResourceLink('653');

    return (
        <TableWidget
            data={items}
            columns={[
                {
                    name: lang.MICROSOFT_365,
                    cellRenderer: ({ rowData, key }) => (
                        <NameColumn
                            icon={icons[rowData.workloadType]}
                            name={names[rowData.workloadType]}
                            key={key}
                        />
                    ),
                },
                {
                    cellRenderer: ({ key }) => <EmptyColumnPlaceholder key={key} />,
                },
                {
                    cellRenderer: ({ rowData, key }) => {
                        const protectedCount = rowData.protectionData.reduce((acc, item) => acc + item.protectedCount.value, 0);
                        const overallCount = rowData.protectionData.reduce((acc, item) => acc + item.overallCount, 0);

                        if (overallCount === 0) {
                            return (
                                <StackView
                                    align={STACK_ALIGN.center}
                                    gap={STACK_GAP.s}
                                    key={key}
                                >
                                    <NoInfoColumn />
                                </StackView>
                            );
                        }

                        if (protectedCount !== overallCount) {
                            return (
                                <StackView
                                    align={STACK_ALIGN.end}
                                    gap={STACK_GAP.s}
                                    key={key}
                                >
                                    <Text size={TEXT_SIZE.xl}>{protectedCount}</Text>
                                    <Text size={TEXT_SIZE.l}>of</Text>
                                    <Text size={TEXT_SIZE.xl}>{overallCount}</Text>
                                </StackView>
                            );
                        }

                        return (
                            <StackView
                                align={STACK_ALIGN.center}
                                gap={STACK_GAP.s}
                                key={key}
                            >
                                <Icon src={successIcon} size={ICON_SIZES.xl} />
                                <Text size={TEXT_SIZE.xl}>{overallCount}</Text>
                            </StackView>
                        );
                    },
                },
            ]}
            loading={request.loading}
            noDataConfig={{
                linkText: lang.START_MANAGING_VEEAM_BACKUP_FOR_MICROSOFT_365,
                icon: vboNotConnected,
                linkHandler: () => window.open(noDataUrl, '_blank', 'noopener, noreferrer'),
            }}
        >
            <StyledCombobox
                value={filterType}
                data={[
                    {
                        value: ProtectedDataFilterBy.Rpo,
                        text: lang.RPO,
                    },
                    {
                        value: ProtectedDataFilterBy.Sla,
                        text: lang.SLA,
                    },
                ]}
                controlRenderer={(props) => {
                    const Control = createLinkControl();

                    return <Control {...props} size={CONTROL_SIZE.xxs} />;
                }}
                valueGetter={(item: ComboboxItem) => item.value}
                textGetter={(item: ComboboxItem) => item.text}
                onChange={handleFilterTypeChange}
            />
        </TableWidget>
    );
});
