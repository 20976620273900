/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { PortalUserRoles } from 'core/entries/portal-user/enums';

interface PanelProps {
    active: boolean;
}

const Panel = styled.div<PanelProps>`
    position: fixed;
    z-index: 100;
    right: 0;
    bottom: 20px;
    padding: 8px;
    border: 1px solid rebeccapurple;
    border-right: none;
    background-color: #fff;
    transform: translateX(${props => props.active ? 0 : 'calc(100% + 1px)'});
    transition: transform .2s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    border-radius: 4px 0 0 0;
`;

const Label = styled.label`
    display: block;
    margin: 8px 0;
`;

const ToggleBtn = styled.button`
    appearance: none;
    all: unset;
    position: absolute;
    bottom: -1px;
    left: 0;
    transform: translateX(-100%);
    padding: 4px;
    background-color: #fff;
    border: 1px solid rebeccapurple;
    border-right: none;
    border-radius: 4px 0 0 4px;
`;

function setNativeValue(element, value) {
    const inputEvent = new Event('input', { bubbles: true });
    const focusEvent = new Event('focus', { bubbles: true });
    const blurEvent = new Event('blur', { bubbles: true });

    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
    nativeInputValueSetter.call(element, value);

    element.dispatchEvent(focusEvent);
    element.dispatchEvent(inputEvent);
    element.dispatchEvent(blurEvent);
}

export const DevPanel: React.FC = () => {
    const [state, setState] = useState(false);
    const [role, setRole] = useState<PortalUserRoles>(PortalUserRoles.Unknown);
    const [login, setLogin] = useState(true);
    const [livereload, setLivereload] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const role = new URLSearchParams(window.location.search).get('userRole');

        if (role) {
            setRole(role as PortalUserRoles);
        }

        const noLogin = localStorage.getItem('no-login');

        setLogin(!Boolean(noLogin));

        const noLivereload = localStorage.getItem('no-livereload');
        setLivereload(!Boolean(noLivereload));

        const savedUsername = localStorage.getItem('dev__username');
        setUsername(savedUsername || '');

        const savedPassword = localStorage.getItem('dev__password');
        setPassword(savedPassword || '');
    }, []);

    const fillCredentials = () => {
        if (username) {
            const container = document.querySelector('[data-tid="LoginStartForm_login"]');
            const field = container.querySelector('input');

            setNativeValue(field, username);
        }

        if (password) {
            const container = document.querySelector('[data-tid="LoginStartForm_password"]');
            const field = container.querySelector('input');

            setNativeValue(field, password);
        }
    };

    const handleRoleChange = (role: string) => {
        const search = new URLSearchParams(window.location.search);

        if (role === '0') {
            search.delete('userRole');
        } else {
            search.set('userRole', role);
        }

        window.location.search = search.toString();
    };

    const handleLoginChange = (value: boolean) => {
        setLogin(value);

        if (value) {
            localStorage.removeItem('no-login');
            localStorage.removeItem(`${RCOP.Lang.VSPC}-token`);
        } else {
            localStorage.setItem('no-login', 'true');
        }
    };

    const handleLivereloadChange = (value: boolean) => {
        setLivereload(value);

        if (value) {
            localStorage.removeItem('no-livereload');
        } else {
            localStorage.setItem('no-livereload', 'true');
        }
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);

        localStorage.setItem('dev__username', event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);

        localStorage.setItem('dev__password', event.target.value);
    };

    const roles = Object.entries(PortalUserRoles).filter(([title]) => !Number.isFinite(Number(title)));

    const hideConstantly = () => {
        localStorage.setItem('no-dev-panel', 'true');
        window.location.reload();
    };

    return (
        <Panel active={state}>
            <h2>VSPC Dev Tools</h2>
            <ToggleBtn onClick={() => setState(!state)}>{state ? '>>' : '<<'}</ToggleBtn>
            <Label htmlFor='role'>Role: </Label>

            <select
                id='role'
                onChange={e => handleRoleChange(e.target.value)}
                value={role}
            >
                {roles.map(([title, value], index) => <option key={index} value={value}>{title}</option>)}
            </select>

            <Label>
                <input
                    type='checkbox'
                    checked={login}
                    onChange={() => handleLoginChange(!login)}
                />

                {' Show auth'}
            </Label>

            <Label>
                <input
                    type='checkbox'
                    checked={livereload}
                    onChange={() => handleLivereloadChange(!livereload)}
                />

                {' Livereload'}

            </Label>

            <Label>
                Credentials
                <div><input name='username' value={username} onChange={handleUsernameChange} /></div>
                <div><input name='password' value={password} onChange={handlePasswordChange} /></div>

                <div>
                    <button onClick={fillCredentials}>Fill</button>
                </div>
            </Label>

            <hr />

            <Label>
                <button onClick={hideConstantly}>Hide constantly</button>
            </Label>
        </Panel>
    );
};
