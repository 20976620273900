/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ValScheduleTypeRepresentations {
    NotScheduled = -1,
    Weekly = 0,
    Monthly = 1,
    Periodically = 2,

    Continuously = 99999, // Random number, should be corrected. It added for matching types
}
