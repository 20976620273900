/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormValidator,
    TextInput,
    Textarea,
    CONTROL_SIZE,
    FormLayout,
    isValid,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { extjsRegExpValidate } from 'core/utils/validators';
import { REG_EXP } from 'core/const';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { ReportModel } from '../../interfaces';

export const stepValidate = (data: ReportModel, lang = {} as LangsServiceBase) => {
    const validator = new FormValidator(data);

    const nameValidator = (value: string): string => {
        const errorText = lang?.SPECIFIED_VALUE_IS_INVALID ?? '-';

        if (value === '.') return errorText;

        return (
            !extjsRegExpValidate(value, REG_EXP.defaultNameLight, errorText) ?
                extjsRegExpValidate(value, REG_EXP.includesNumberOrLetter, errorText) :
                errorText
        );
    };

    validator
        .validate('name')
        .string()
        .required()
        .check(nameValidator)
        .maxLength(128);
    validator
        .validate('description')
        .string()
        .maxLength(2048);

    return validator.result();
};

export const getNameStep = (title: string): WizardStep<ReportModel> => ({
    title,
    validate: ({ data }) => isValid(stepValidate, data),
    render: data => <NameStep {...data} />,
});

const NameStep = ({ data, validationState, onDataChange }: WizardStepData<ReportModel>) => {
    const lang = useLang();

    return (
        <StepLayout
            title={capitalize(lang.NAME)}
            description={lang.TYPE_IN_REPORT_NAME_AND}
        >
            <Form
                value={data}
                validate={data => stepValidate(data, lang)}
                validationState={validationState}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <TextInput
                        name='name'
                        label={lang.NAME}
                        size={CONTROL_SIZE.l}
                    />

                    <Textarea
                        name='description'
                        label={lang.DESCRIPTION}
                        size={CONTROL_SIZE.l}
                        rows={4}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
};
