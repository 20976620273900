/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { WizardStep } from '@veeam-vspc/components';

import { getCommonMultiFactorStep } from 'views/components/wizard/MultiFactorStep';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { CompanyData } from '../../interfaces';

export const getMultiFactorStep = (lang: LangsServiceBase): WizardStep<CompanyData> => getCommonMultiFactorStep(
    lang,
    lang.CONFIGURE_MFA_ACCESS_FOR_THE_COMPANY,
    [
        lang.THIS_OPTION_ENFORCES_MFA,
        lang.UPON_ENABLING_THIS_OPTION_ALL_USERS_OF_THIS_COMPANY,
        lang.IF_THIS_COMPANY_IS_USING_BACKUP_PORTAL,
        lang.TO_RESOLVE_THAT_ADMINISTRATORS_OF_THIS_COMPANY,
    ]
);
