/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { INDENT, Text, TEXT_SIZE, TEXT_VIEW, TEXT_WHITE_SPACE } from '@veeam-vspc/components';

export const FormNote: React.FC = props => (
    <Text
        leader={INDENT.s}
        size={TEXT_SIZE.s}
        view={TEXT_VIEW.secondary}
        whitespace={TEXT_WHITE_SPACE.normal}
    >
        {props.children}
    </Text>
);
