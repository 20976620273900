/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Checkbox, Combobox, CONTROL_SIZE, NoLabelGroup } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';

import type { WanAcceleratorSupportedResource, WanAccelerator } from 'core/interfaces/';

export interface WanAccelerationProps {
    value: WanAcceleratorSupportedResource;
    wanAccelerators: WanAccelerator[];
    hidden?: boolean;
    disabled?: boolean;
    onChangeEnabled?: (checked) => void;
}

export const WanAcceleration: React.FC<WanAccelerationProps> = (
    {
        value,
        wanAccelerators,
        onChangeEnabled,
        hidden = false,
        disabled,
    }: WanAccelerationProps) => {
    const lang = useLang();

    return hidden ? null : (
        <NoLabelGroup
            disabled={disabled}
            content={(
                <Checkbox
                    name={'isWanAccelerationEnabled'}
                    disabled={disabled}
                    onChange={onChangeEnabled}
                >
                    {`${lang.ENABLE_WAN_ACCELERATION_THROUGH}:`}
                </Checkbox>
            )}
            subContent={(
                <Combobox
                    name={'wanAcceleratorUid'}
                    placeholder={lang.SELECT_A_WAN_ACCELERATOR}
                    data={wanAccelerators}
                    valueGetter={item => item.cloudConnectResourceUid}
                    textGetter={item => item.cloudConnectResourceName}
                    disabled={!value.isWanAccelerationEnabled}
                    size={CONTROL_SIZE.full}
                />
            )}
        />
    );
};
