/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { capitalize, deepCopy } from '@veeam-vspc/core';
import {
    ACTION_VIEW,
    TextColumn,
    STACK_DIRECTION,
    SPACE_FILL,
    STACK_GAP,
    StackView,
    Form,
    Checkbox,
    NumberInput,
    LinkButton,
    useModal,
    FormValidator,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { WizardStepData, GridStore } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import editIcon from 'images/actions/edit.svg';
import { ActionsToolbar } from './components/ActionsToolbar';
import { PublicIpAddressesDialog } from './components/PublicIpAddressesDialog';

import type { CompanyNetworkExtension, CompanyData } from '../../../../interfaces';

const MIN_NUMBER_OF_PUBLIC_IP = 1;
const MAX_NUMBER_OF_PUBLIC_IP = 9999;

const formValidate = (data: CompanyData) => {
    const validator = new FormValidator(data);

    if (data.publicIpEnabled) {
        validator.validate('numberOfPublicIp')
            .number()
            .required()
            .min(MIN_NUMBER_OF_PUBLIC_IP)
            .max(MAX_NUMBER_OF_PUBLIC_IP);
    }

    return validator.result();
};

export interface Props extends WizardStepData<CompanyData> {
    hidePanel: () => void;
}

export const NetworkExtensionSettings = observer((props: Props) => {
    const lang = useLang();
    const { hidePanel, onDataChange } = props;
    const gridApi = useRef<GridStore<CompanyNetworkExtension, void, void>>();
    const formApi = useExternalFormApi<CompanyData>();

    const [data, setData] = useState(deepCopy(props.data));
    const [validationState] = useState(new ValidationState());

    const [addressesDialog, addressesDialogActions] = useModal({
        render: ({ deactivate }) => (
            <PublicIpAddressesDialog
                cloudConnectAgentUid={data.cloudConnectAgentUid}
                deactivate={deactivate}
            />
        ),
    });

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={capitalize(lang.NETWORK_EXTENSION_APPLIANCE)}
            description={lang.SELECT_NETWORK_EXTENSION}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        if (formApi.validate()) {
                            Object.assign(props.data, data);
                            onDataChange(data);
                            hidePanel();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} spaceFill={SPACE_FILL.all} gap={STACK_GAP.s}>
                <AdvancedGrid <CompanyNetworkExtension, void, void>
                    defaultColumnWidth={100}
                    columns={[
                        { field: 'name', title: lang.NAME },
                        { field: 'networkName', title: lang.NETWORK },
                        {
                            field: 'ipAddress',
                            title: capitalize(lang.IP_ADDRESS),
                            cell: ({ rowData }) => (
                                <TextColumn>{rowData.dhcpEnabled ? lang.OBTAIN_AUTOMATICALLY : rowData.ipAddress}</TextColumn>
                            ),
                        },
                    ]}
                    data={() => Promise.resolve({
                        data: data.networks,
                        meta: {
                            pagingInfo: {
                                total: data.networks?.length,
                            },
                        },
                    } as BaseSuccessRequestResponse<CompanyNetworkExtension[]>)}
                    toggleable={false}
                    disablePagination={true}
                    disableAutoUpdate={true}
                    selection={{
                        field: 'name',
                        multiple: false,
                        checkbox: false,
                    }}
                    toolbars={[
                        () => <ActionsToolbar items={data.networks} />,
                    ]}
                    api={gridApi}
                />

                <Form
                    value={data}
                    validate={formValidate}
                    onChange={(v) => {
                        setData({ ...v });
                    }}
                    externalFormApi={formApi}
                    validationState={validationState}
                >
                    <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                        <Checkbox
                            name={'publicIpEnabled'}
                            // disabled={data.gatewaySelectionType === CloudGatewaySelectionType.StandaloneGateways}
                        >
                            {lang.ALLOCATE_THE_FOLLOWING_NUMBER}
                        </Checkbox>

                        <NumberInput
                            name={'numberOfPublicIp'}
                            disabled={!data.publicIpEnabled}
                            minValue={MIN_NUMBER_OF_PUBLIC_IP}
                            maxValue={MAX_NUMBER_OF_PUBLIC_IP}
                        />

                        <LinkButton iconBefore={editIcon} onClick={() => addressesDialogActions.activate()}>{lang.MANAGE}</LinkButton>
                    </StackView>
                </Form>
            </StackView>

            {addressesDialog}
        </SidePanelForm>
    );
});
