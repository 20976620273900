/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { WorkloadTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import { core } from 'core/core-module';

export const getWorkloadTypeName = (enumValue: WorkloadTypeRepresentation): string => (
    core.enumMapperService.getEnumDescription('WorkloadTypeRepresentation', enumValue)
);
