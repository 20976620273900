/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { DurationColumn, GridColumnJustify, GridSortDirections, TextColumn } from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { CellRendererProps, RequestParams } from '@veeam-vspc/components';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { ConfigSectionIds } from 'core/services/configurator/enums';
import {
    BackupFileJobStatusColumn,
    FileServerColumn,
    FileSharesLocalActionToolbar,
    FileSharesLocalFilterToolbar,
} from './components';

import type { BackupServerFileJobFilterModel, BackupServerFileJobItem } from './interfaces';

export interface FileSharesLocalProps {
    stateId?: string;
}

export const FileSharesLocal: React.FC<FileSharesLocalProps> = ({ stateId }) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const gridStateId = `${stateId}.FileSharesLocal`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<BackupServerFileJobItem>) => <TextColumn>{cellData}</TextColumn>;
    const columns = [
        {
            field: 'statusName',
            title: lang.JOB_STATUS,
            cell: ({ rowData }: CellRendererProps<BackupServerFileJobItem>) => (
                <BackupFileJobStatusColumn
                    status={rowData.status}
                    statusName={rowData.statusName}
                    lastSessionEndTime={rowData.lastSessionEndTime}
                    lastSessionUid={rowData.lastSessionUid}
                />
            ),
        },
        {
            field: 'name',
            title: lang.JOB,
            cell: defaultColumnRender,
        },
        {
            field: 'backupServerName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: defaultColumnRender,
        },
        {
            field: 'companyName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'fileServers',
            title: lang.SERVER,
            cell: ({ cellData, rowData }: CellRendererProps<BackupServerFileJobItem>) => (
                <FileServerColumn
                    value={cellData}
                    jobType={rowData.jobType}
                    name={rowData.name}
                    instanceUid={rowData.instanceUid}
                    stateId={`${stateId}.Dialogs.FileServers`}
                />
            ),
        },
        {
            field: 'jobTypeName',
            title: lang.JOB_TYPE,
            cell: defaultColumnRender,
        },
        {
            field: 'sourceAmountOfData',
            title: lang.SOURCE_SIZE,
            cell: ({ cellData }: CellRendererProps<BackupServerFileJobItem>) => (
                <RestorePointSizeColumn value={cellData as any} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backedUpFilesCount',
            title: lang.BACKED_UP_FILES,
            cell: ({ rowData }: CellRendererProps<BackupServerFileJobItem>) => (
                <FileServerColumn
                    value={rowData.backedUpFiles}
                    jobType={rowData.jobType}
                    name={rowData.name}
                    instanceUid={rowData.instanceUid}
                    stateId={`${stateId}.Dialogs.BackupUpFiles`}
                    alignRight={true}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'destination',
            title: lang.DESTINATION,
            cell: defaultColumnRender,
        },
        {
            field: 'lastSessionStartTime',
            title: lang.LAST_RUN,
            cell: ({ cellData }: CellRendererProps<BackupServerFileJobItem>) => (
                <TimeAgoColumn
                    value={cellData}
                />
            ),
        },
        {
            field: 'longTermDestination',
            title: lang.ARCHIVE_REPOSITORY,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'lastSessionDuration',
            title: lang.DURATION,
            cell: ({ cellData }: CellRendererProps<BackupServerFileJobItem>) => (
                <DurationColumn
                    value={cellData as any}
                />
            ),
            hidden: true,
        },
        {
            field: 'avgDuration',
            title: lang.AVG_DURATION,
            cell: ({ cellData }: CellRendererProps<BackupServerFileJobItem>) => (
                <DurationColumn
                    value={cellData as any}
                />
            ),
            hidden: true,
        },
        {
            field: 'processingRate',
            title: lang.PROCESSING_RATE,
            cell: ({ cellData }: CellRendererProps<BackupServerFileJobItem>) => (
                <RestorePointSizeColumn
                    value={cellData as any}
                    unitName={'B/s'}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'transferred',
            title: lang.TRANSFERRED_DATA,
            cell: ({ cellData }: CellRendererProps<BackupServerFileJobItem>) => (
                <RestorePointSizeColumn
                    value={cellData as any}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'scheduleState',
            title: lang.SCHEDULE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'bottleneck',
            title: lang.BOTTLENECK,
            cell: defaultColumnRender,
            hidden: true,
        },
    ];

    return (
        <AdvancedGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.BackupJobsFileSharesLocal}
            defaultColumnWidth={100}
            initialState={{
                sort: {
                    key: 'status',
                    direction: GridSortDirections.Asc,
                },
            }}
            columns={columns}
            data={(requestParams: RequestParams<BackupServerFileJobFilterModel>) => transportService
                .request<typeof requestParams, BackupServerFileJobItem[]>('/BackupServerJob/GetFileJobs', { ...requestParams })}
            isRowInactive={({ rowData }) => !rowData.scheduleEnabled}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <FileSharesLocalFilterToolbar />,
                () => <FileSharesLocalActionToolbar idProperty={'instanceUid'} selectedOnly />,
            ]}
        />
    );
};
