/**
 * Copyright © Veeam Software Group GmbH.
 */


import type { PulseConfigurationModel } from '@veeam-vspc/models/web-controllers';
import type { BaseRequestResponse } from '@veeam-vspc/core';

import { getData } from 'core/services/transport/utils/get-data';
import { core } from '../../core-module';

import type { ContractModel } from '../../interfaces';

export class PulseService {
    static getPulseConfiguration(): Promise<PulseConfigurationModel> {
        return getData('Pulse/GetPulseConfiguration');
    }

    static getContracts(): Promise<BaseRequestResponse<ContractModel>> {
        return core.transportService.request('Pulse/GetContracts', {});
    }
}
