/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { toJS } from 'mobx';

import { DateTitle } from '../DayBase';
import { useJobsOverviewStore } from '../../../../store';
import { Tooltip } from '../../../Tooltip';
import { DayWithJobsWrapper, FailStatus, StatusesWrapper, SuccessStatus, WarningStatus } from './DayWithJobs.styled';

import type { DayProps } from '../../../../interfaces/day-props';

export const DayWithJobs: React.FC<DayProps> = ({ id }) => {
    const store = useJobsOverviewStore();
    const record = store.findById(id);
    const requestParams = toJS(store.requestParams);

    return (
        <>
            <DayWithJobsWrapper
                data-for={id}
                data-tip={id}
                onClick={() => store.openJobsDialog({
                    dateString: id,
                    jobType: requestParams.filter?.jobType,
                    platformType: requestParams.filter?.platformType,
                    workloadType: requestParams.filter?.workloadType,
                })}
            >
                <DateTitle>
                    {record.displayDateForCell}
                </DateTitle>

                <StatusesWrapper>
                    <FailStatus percents={record.jobsPercents.fail} />
                    <WarningStatus percents={record.jobsPercents.warning} />
                    <SuccessStatus percents={record.jobsPercents.success} />
                </StatusesWrapper>
            </DayWithJobsWrapper>

            <Tooltip id={id} />
        </>
    );
};
