/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridColumnJustify,
    GridSortDirections,
    STACK_DIRECTION,
    STACK_GAP,
    StackView, TextColumn,
} from '@veeam-vspc/components';

import type { CellRendererProps } from '@veeam-vspc/components';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { MAX_INTEGER_32 } from 'core/const';

import type { OldRestorePointItem, OldRestorePointRequestParams, OldRestorePointExtraParams } from '../../interfaces';


export interface OldRestorePointDialogProps {
    stateId?: string;
    name: string;
    extraParams: OldRestorePointExtraParams;
    deactivate: () => void;
    isExtendedColumns?: boolean;
}

export const OldRestorePointDialog: React.FC<OldRestorePointDialogProps> = (props) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const {
        stateId,
        name,
        extraParams,
        deactivate,
        isExtendedColumns = false,
    } = props;

    return (
        <Dialog
            header={`${lang.RESTORE_POINTS} - ${name}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <AdvancedGrid
                    {...(stateId ? { stateId } : {})}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Desc,
                            key: 'creationDateUtc',
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={[
                        isExtendedColumns && {
                            field: 'backupedItems',
                            title: lang.BACKED_UP_ITEMS,
                            cell: ({ cellData }: CellRendererProps<OldRestorePointItem>) => <TextColumn>{cellData}</TextColumn>,
                        },
                        {
                            field: 'creationDateUtc',
                            title: lang.DATE,
                            cell: ({ cellData }: CellRendererProps<OldRestorePointItem>) => <GridDateColumn value={cellData} />,
                        },
                        {
                            field: 'usedSourceSize',
                            title: lang.SOURCE_SIZE,
                            cell: ({ cellData }: CellRendererProps<OldRestorePointItem>) => <RestorePointSizeColumn value={cellData as any} />,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'incrementDataSize',
                            title: lang.BACKED_UP_DATA,
                            cell: ({ cellData }: CellRendererProps<OldRestorePointItem>) => <RestorePointSizeColumn value={cellData as any} />,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'size',
                            title: lang.RESTORE_POINT_SIZE,
                            cell: ({ cellData }: CellRendererProps<OldRestorePointItem>) => <RestorePointSizeColumn value={cellData as any} />,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                    ].filter(col => col)}
                    data={(requestParams: OldRestorePointRequestParams<{}>) => transportService
                        .request<typeof requestParams, OldRestorePointItem[]>('/BackupAgent/GetRestorePoints', {
                            ...requestParams,
                            ...extraParams,
                        })}
                    toggleable={false}
                    selection={{
                        field: 'creationDateUtc',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => (
                            <ToolbarWithExport
                                extraParams={extraParams}
                                idProperty={'creationDateUtc'}
                                method={'GetComputersRestorePoints'}
                            />
                        ),
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
