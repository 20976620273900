/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';

export const EmptyPlateStyled = styled.div`
    align-items: center;
    background-color: transparent;
    display: flex;
    height: 100%;
    justify-content: center;
    justify-items: center;
    width: 100%;
`;
