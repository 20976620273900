/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridSortDirections,
    PortalSpinner,
    TextColumn,
} from '@veeam-vspc/components';
import { Limits } from '@veeam-vspc/core';

import type {
    CloudConnectServerWithDeployInfo,
    GetVspcSitesParam,
    PublicCloudAvailableVbJson,
    PublicCloudAvailableVbModel,
    SiteModel,
} from '@veeam-vspc/models/web-controllers';
import type { CellRendererProps, GridStore } from '@veeam-vspc/components';

import { useTransportRequest } from 'views/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { SelectVbFilterToolbar } from './components';

export interface SelectVbDialogProps {
    onClose: () => void;
    onSelect: (vbItemId: PublicCloudAvailableVbModel) => void;
}

export const SelectVbDialog: React.FC<SelectVbDialogProps> = ({ onSelect, onClose }) => {
    const lang = useLang();
    const { transportService, enumMapperService } = useAppServices();
    const {
        loading,
        data: cloudConnectServers,
    } = useTransportRequest<GetVspcSitesParam, SiteModel[]>('/VbPlugin/GetSites', {
        start: 0,
        limit: Limits.MaxInteger32,
        sort: [{ property: 'siteName', direction: GridSortDirections.Asc }],
    });
    const [selectedVb, setSelectedVb] = useState<PublicCloudAvailableVbModel>(null);
    const defaultColumnRender = ({ cellData }: CellRendererProps<PublicCloudAvailableVbModel>) => (
        <TextColumn>{cellData}</TextColumn>
    );
    const api = useRef<GridStore<PublicCloudAvailableVbModel, unknown, PublicCloudAvailableVbJson['filter']>>();
    const [selectedAgentGuid, setSelectedAgentGuid] = useState<string>(null);

    useEffect(() => {
        const agentGuid = selectedAgentGuid ?? null;

        setSelectedAgentGuid(agentGuid);
        api.current?.applyFilters({
            agentGuid,
        });
    }, [selectedAgentGuid]);

    return (
        <Dialog
            header={lang.CREATE_POLICY}
            title={lang.SELECT_A_CLOUD_APPLIANCE_TO_CREATE}
            size={{
                width: DIALOG_SIZE.xxl,
                height: DIALOG_SIZE.xxl,
            }}
            onRequestClose={onClose}
            actions={[
                {
                    text: lang.CREATE,
                    onClick: () => onSelect(selectedVb),
                    disabled: !selectedVb,
                },
                { text: lang.CLOSE, onClick: onClose },
            ]}
        >
            {loading && <PortalSpinner whiteBg />}

            {!loading && (
                <AdvancedGrid
                    initialState={{
                        sort: {
                            key: 'hostName',
                            direction: GridSortDirections.Asc,
                        },
                    }}
                    columns={[
                        {
                            field: 'hostName',
                            title: lang.HOSTNAME,
                            cell: defaultColumnRender,
                        },
                        {
                            field: 'companyName',
                            title: lang.COMPANY,
                            cell: defaultColumnRender,
                        },
                        {
                            field: 'dnsName',
                            title: lang.ADDRESS,
                            cell: defaultColumnRender,
                        },
                        {
                            field: 'platform',
                            title: lang.PLATFORM,
                            cell: ({ rowData }: CellRendererProps<PublicCloudAvailableVbModel>) => (
                                <TextColumn>
                                    {enumMapperService.getEnumDescription('PublicCloudPlatformTypeRepresentation', rowData.platform)}
                                </TextColumn>
                            ),
                        },
                    ]}
                    data={(requestParams: PublicCloudAvailableVbJson) => transportService
                        .request<typeof requestParams, PublicCloudAvailableVbModel[]>('/VbPlugin/GetAvailableVb', requestParams)}
                    toggleable={false}
                    api={api}
                    selection={{
                        field: 'serverTag',
                        checkbox: false,
                        multiple: false,
                    }}
                    onSelectionChange={(selected: PublicCloudAvailableVbModel[]) => {
                        setSelectedVb(selected[0]);
                    }}
                    toolbars={[
                        () => (
                            <SelectVbFilterToolbar
                                cloudConnectServers={cloudConnectServers as CloudConnectServerWithDeployInfo[]}
                                selectedAgentGuid={selectedAgentGuid}
                                onChangeAgentGuid={(newAgentGuid: string) => {
                                    setSelectedAgentGuid(newAgentGuid);
                                }}
                            />
                        ),
                    ]}
                />
            )}
        </Dialog>
    );
};
