/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, computed, observable } from 'mobx';

import { TimePeriod } from '../enums';
import { ProtectedDataSummaryFilters } from '../interfaces';


export class ProtectedDataSummaryStore {
    @observable slaPercent = 90;
    @observable rpoDays = 1;
    @observable timePeriod: TimePeriod = TimePeriod.Last30Days;

    constructor(
        filters: ProtectedDataSummaryFilters,
    ) {
        this.slaPercent = filters.slaPercent;
        this.rpoDays = filters.rpoDays;
        this.timePeriod = filters.timePeriod;
    }

    @computed
    get filters(): ProtectedDataSummaryFilters {
        return {
            slaPercent: this.slaPercent,
            rpoDays: this.rpoDays,
            timePeriod: this.timePeriod,
        };
    }

    @action.bound
    setFilters(filters: ProtectedDataSummaryFilters) {
        this.slaPercent = filters.slaPercent;
        this.rpoDays = filters.rpoDays;
        this.timePeriod = filters.timePeriod;
    }
}
