/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { AppContext } from '../stores';

import type { AppContextData, AppServices } from '../interfaces';

export const useAppServices = (): AppServices => useContext<AppContextData>(AppContext).services;
