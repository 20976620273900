/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { STACK_DIRECTION, STACK_GAP, StackView, SPACE_FILL } from '@veeam-vspc/components';

import type { ReactNode } from 'react';

import { PageWizardContentHeader } from 'components/layouts/PageWizardContentHeader';

interface StepLayoutProps {
    title: string;
    description?: string;
    children?: ReactNode;
}

export const StepLayout = ({ title, description, children }: StepLayoutProps) => (
    <StackView
        direction={STACK_DIRECTION.column}
        gap={STACK_GAP.m}
        spaceFill={SPACE_FILL.all}
    >
        <PageWizardContentHeader
            title={title}
            description={description}
        />

        <StackView
            direction={STACK_DIRECTION.column}
            spaceFill={SPACE_FILL.all}
        >
            {children}
        </StackView>
    </StackView>
);

