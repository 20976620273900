/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { AppContext } from '../stores';

import type { AppActions, AppContextData } from '../interfaces';

export const useAppActions = (): AppActions => useContext<AppContextData>(AppContext).actions;
