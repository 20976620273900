/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { CloudConnectServerWithDeployInfo } from '@veeam-vspc/models/web-controllers';

import { compareVersion } from 'core/utils/version-helpers';

export const isPublicCloudAvailable = (cloudConnectAgents: CloudConnectServerWithDeployInfo[], ccAgentUid: string) => {
    const cloudConnectAgentVersion = cloudConnectAgents?.find(agent => agent.agentGuid === ccAgentUid)?.serverVersion;
    if (cloudConnectAgentVersion) {
        const minVersion = '12.0.0.0';
        if (compareVersion(cloudConnectAgentVersion, minVersion) < 0) {
            return false;
        }
    } else {
        return false;
    }

    return true;
};
