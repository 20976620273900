/**
 * Copyright © Veeam Software Group GmbH.
 */

import { XmlFileParams } from '../enums';
import { readAsText } from 'core/utils/file-helpers';

export type TagSelectorResult = Element | null;

export const getXmlFromFile = async(file: File): Promise<XMLDocument> => {
    const xmlString = await readAsText(file);
    return getXmlFromString(xmlString);
};

export const getXmlFromString = (xmlString: string): XMLDocument => {
    const parser = new DOMParser();
    return parser.parseFromString(xmlString, XmlFileParams.ParsingType);
};

export const getXmlTag = (xml: XMLDocument, qSelector: string): TagSelectorResult => xml.querySelector(qSelector);

export const getXmlTags = (xml: XMLDocument, qSelectors: string[]): TagSelectorResult[] => qSelectors.map(selector => getXmlTag(xml, selector));

export const getTagValueAttr = (xmlTag: TagSelectorResult): string => xmlTag?.getAttribute(XmlFileParams.ValueAttr);
