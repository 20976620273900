/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ColumnTexts } from '@veeam-vspc/components';
import { Vb365BackupJobSchedulePolicy } from '@veeam-vspc/models/rest';

import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const getPeriodicallyReadableValues = (lang: LangsServiceBase) => ({
    [Vb365BackupJobSchedulePolicy.PeriodicallyEveryEnum.Minutes5]: `5 ${lang.MINUTES.toLowerCase()}`,
    [Vb365BackupJobSchedulePolicy.PeriodicallyEveryEnum.Minutes10]: `10 ${lang.MINUTES.toLowerCase()}`,
    [Vb365BackupJobSchedulePolicy.PeriodicallyEveryEnum.Minutes15]: `15 ${lang.MINUTES.toLowerCase()}`,
    [Vb365BackupJobSchedulePolicy.PeriodicallyEveryEnum.Minutes30]: `30 ${lang.MINUTES.toLowerCase()}`,
    [Vb365BackupJobSchedulePolicy.PeriodicallyEveryEnum.Hours1]: `1 ${lang.HOUR}`,
    [Vb365BackupJobSchedulePolicy.PeriodicallyEveryEnum.Hours2]: `2 ${lang.HOURS}`,
    [Vb365BackupJobSchedulePolicy.PeriodicallyEveryEnum.Hours4]: `4 ${lang.HOURS}`,
    [Vb365BackupJobSchedulePolicy.PeriodicallyEveryEnum.Hours8]: `8 ${lang.HOURS}`,
    [Vb365BackupJobSchedulePolicy.PeriodicallyEveryEnum.Unknown]: ColumnTexts.DashSymbol,
});
