/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Form,
    FormLayout,
    Text,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    Textarea,
    Checkbox,
    LinkButton,
    SignPost,
    FieldLayout,
    FormValidator,
    CONTROL_SIZE,
    Separator,
    Toggle,
    NoLabelGroup,
} from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/use-page-wizard-store';
import { capitalize } from '@veeam-vspc/core';
import { CompanyTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { PreviewEmail } from 'views/components/PreviewEmail';
import { useResellerWizardStore } from '../../hooks';
import previewEmailIcon from 'images/actions/preview-email.png';

import type { ResellerData } from '../../interfaces';
import type { EmailCustomSectionData } from 'core/interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';

export const getEmailStep = (lang: LangsServiceBase): WizardStep<ResellerData> => ({
    title: lang.WELCOME_EMAIL,
    render: stepData => <EmailStep {...stepData} />,
});

const formValidate = (data: EmailCustomSectionData) => {
    const validator = new FormValidator(data);

    if (data.addCustomText) {
        validator.validate('welcomeEmailCustomSection')
            .string()
            .maxLength(1000);
    }

    return validator.result();
};

const EmailStep = observer((wizardStepData: WizardStepData<ResellerData>) => {
    const lang = useLang();
    const wizardStore = useResellerWizardStore();
    const wizardContext = usePageWizardStore<ResellerData>();
    const { portalUser } = useAppStore();
    const { data } = wizardStepData;
    const showPreview = () => wizardContext.openPanel(hide => (
        <PreviewEmail
            {...wizardStepData}
            emailData={wizardStore.emailData}
            organizationType={CompanyTypeRepresentation.Reseller}
            gateways={data.cloudConnectAgents.map(agent => ({ cloudAgentUid: agent.cloudConnectAgentUid }))}
            hidePanel={hide}
        />
    ));

    const emailData = wizardStore.emailData;

    useEffect(() => {
        wizardStore.loadEmailData(data.organizationUid);
    }, []);

    wizardContext.setLoading(emailData === null);

    return (
        <StepLayout
            title={lang.WELCOME_EMAIL}
            description={lang.ADD_CUSTOM_TEXT_TO_WELCOME_EMAIL}
        >
            {emailData !== null && (
                <Form<EmailCustomSectionData>
                    value={emailData}
                    validate={value => formValidate(value)}
                >
                    <FormLayout inlineLabel>
                        <Toggle
                            name={'addCustomText'}
                            label={lang.INCLUDE_CUSTOM_TEXT}
                            showSuffix={true}
                        />

                        <NoLabelGroup
                            disabled={!emailData.addCustomText}
                            content={(
                                <FormLayout>
                                    <FieldLayout
                                        size={CONTROL_SIZE.full}
                                        label={(
                                            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                                                <Text>{`${lang.SPECIFY_THE_TEXT_TO_ADD_TO_WELCOME_EMAIL}:`}</Text>
                                                <SignPost text={lang.SUPPORTED_FORMATS} />
                                            </StackView>
                                        )}
                                    >
                                        <Textarea
                                            name={'emailContent'}
                                            rows={10}
                                            size={CONTROL_SIZE.full}
                                        />
                                    </FieldLayout>

                                    <Checkbox name={'isVisibleGeneralSection'} disabled={!emailData.addCustomText}>
                                        {lang.INCLUDE_SELF_SERVICE}
                                    </Checkbox>

                                    <LinkButton
                                        iconBefore={previewEmailIcon}
                                        onClick={showPreview}
                                    >
                                        {`${capitalize(lang.PREVIEW_WELCOME_EMAIL, true)}...`}
                                    </LinkButton>

                                    {portalUser.userRole !== PortalUserRoles.PortalOperator
                            && portalUser.userRole !== PortalUserRoles.ServiceProviderOperator
                            && (
                                <>
                                    <Separator />

                                    <Checkbox name={'isDefault'} disabled={!emailData.addCustomText}>
                                        {lang.USE_THIS_TEMPLATE_AS_DEFAULT_RESELLERS}
                                    </Checkbox>
                                </>
                            )}
                                </FormLayout>
                            )}
                        />
                    </FormLayout>
                </Form>
            )}
        </StepLayout>
    );
});
