/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum EConnectionStatuses {
    Online = 0,
    Offline = 1,
    Rejected = 2,
    Rebooting = 3,
    Unverified = 4,
}
