/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette, CONTROL_SIZE } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { ProductType } from '@veeam-vspc/models/web-controllers';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { StatefulCombobox } from '../../../StatefulCombobox';
import { ConsumedPointsContext } from './contexts/ConsumedPointsContext';
import { ConsumedPointsService } from './services/consumed-points.service';
import { ConsumedPointsStore } from './stores/ConsumedPointsStore';
import { useConsumedPointsStore } from './hooks/use-consumed-points-store';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { ComboboxItem } from '../../../../interfaces';

export const ChartLayout = observer(() => {
    const consumedStore = useConsumedPointsStore();
    const lang = useLang();

    const params = {
        colors: [palette.B400],
        yTitle: lang.POINTS,
    };

    const title = {
        text: capitalize(lang.CONSUMED_POINTS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={consumedStore.setProductType}
                stateId={'trends.infrastructure.consumed-points.product-type'}
                textGetter={(p: ComboboxItem) => p.text}
                value={consumedStore.productType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: ProductType.All, text: lang.ALL },
                    { id: ProductType.Vspc, text: lang.VEEAM_SERVICE_PROVIDER_CONSOLE },
                    { id: ProductType.CloudConnectServer, text: lang.VEEAM_CLOUD_CONNECT },
                    { id: ProductType.BackupServer, text: lang.VEEAM_BACKUP_AND_REPLICATION },
                    { id: ProductType.VOne, text: lang.VEEAM_ONE },
                    { id: ProductType.Vbo, text: lang.VEEAM_BACKUP_FOR_MICROSOFT },
                ]}
                size={CONTROL_SIZE.m}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={consumedStore.productType}
            params={params}
            service={ConsumedPointsService}
            title={title}
        />
    );
});

export const ConsumedPoints: React.FC = () => {
    const consumedPointsStore = useMemo(() => new ConsumedPointsStore(), []);

    return (
        <ConsumedPointsContext.Provider value={consumedPointsStore}>
            <ChartLayout />
        </ConsumedPointsContext.Provider>
    );
};
