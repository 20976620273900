/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect } from 'react';

import { useAppServices } from '../../AppProvider/hooks';

import type { GlobalFiltersData } from 'core/services/global-filters/interfaces';


export const useGlobalFiltersChangeHandler = (cb: (filters: GlobalFiltersData) => void): void => {
    const { globalFiltersService } = useAppServices();

    useEffect(() => {
        const unsubscribe = globalFiltersService.onFiltersChange((filters: GlobalFiltersData) => cb(filters));

        return () => unsubscribe();
    }, [globalFiltersService, cb]);
};
