/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { RequestAdditionalParams } from '@veeam-vspc/core/services/transport/interfaces/request-additional-params';

import { core } from '../../../core-module';

import type { BaseDataResponse } from '../interfaces';

export const getData = <reqDataT, respDataT>(
    url: string,
    data?: reqDataT,
    params?: RequestAdditionalParams
) => core.transportService.request(url, data, undefined, params)
        .then((respData: BaseDataResponse<respDataT>) => respData.data);
