/**
 * Copyright © Veeam Software Group GmbH.
 */

import { PortalUserRoles } from '../../../entries/portal-user/enums';
import { ConfigActionIds, ConfigSectionIds, ConfigStates } from '../enums';
import { baseConfig } from './base.config';

import type { BaseUserConfig } from '../interfaces';

export const companyAdministratorConfig: BaseUserConfig = {
    baseConfig: baseConfig,
    roleInt: PortalUserRoles.CompanyAdministrator,
    roleName: PortalUserRoles[PortalUserRoles.CompanyAdministrator] as keyof typeof PortalUserRoles,
    routes: [],
    sections: [
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByBackupServer,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructure,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            actions: {
                separatorBeforeDownloadRemoteConsole: ConfigStates.Hidden,
                downloadRemoteConsole: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesLocal,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsSelectVbDialogFilterToolbar,
            actions: {
                [ConfigActionIds.FilterToolbarSiteSearch]: ConfigStates.Hidden,
                [ConfigActionIds.FilterToolbarCompanySearch]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataFileSharesLocal,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsCloudDatabases,
            columns: {
                company: ConfigStates.Hidden,
                site: ConfigStates.Hidden,
                vbServerName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesPublicClouds,
            columns: {
                company: ConfigStates.Hidden,
                site: ConfigStates.Hidden,
                vbServerName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesPublicClouds,
            columns: {
                company: ConfigStates.Hidden,
                site: ConfigStates.Hidden,
                vbServerName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataCloudDatabases,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
                vbServerName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataFileSharesLocal,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataFileSharesPublicClouds,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
                vbServerName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataVirtualMachinesVirtualInfrastructure,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataVirtualMachinesPublicClouds,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
                vbServerName: ConfigStates.Hidden,
            },
        },
    ],
};
