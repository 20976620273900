/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { ResellerWizard } from './components/ResellerWizard';

import type { ResellerWizardProps } from './components/ResellerWizard';

export const RESELLERS_PAGE = 'ResellersPage';

export const ResellerPage = (props: ResellerWizardProps) => (
    <ResellerWizard {...props} />
);
