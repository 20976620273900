/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import { colors, constants, Spinner, STACK_DIRECTION, StackView } from '@veeam-vspc/components';

import { EmptyPlate } from './components/EmptyPlate';
import { Header } from './components/Header';
import { TREND_WIDGET_HEIGHT } from '../BaseTrendChart/const';
import { useLang } from '../../../../providers/LangProvider/hooks';

const SpinnerPlate = styled.div`
    align-items: center;
    background-color: ${colors.G000};
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
`;

export const HighchartsWrapper = ({ empty, loading, options, title }) => {
    const lang = useLang();

    const extendedOptions: Highcharts.Options = {
        exporting: {
            enabled: false,
            ...(options.exporting || {}),
        },
        ...options,
    };

    return (
        <>
            {empty && <EmptyPlate emptyText={lang.NO_DATA_AVAILABLE} title={title} />}

            {!empty && (
                <StackView
                    direction={STACK_DIRECTION.column}
                    style={{ height: TREND_WIDGET_HEIGHT }}
                >
                    <Header title={title} whiteBg />

                    {loading && (
                        <SpinnerPlate>
                            <Spinner size={48} style={{ marginTop: `-${constants.SPACING_XXL}` }} />
                        </SpinnerPlate>
                    )}

                    {!loading && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={extendedOptions}
                        />
                    )}
                </StackView>
            )}
        </>
    );
};
