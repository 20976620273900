/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepClone } from '@veeam-vspc/core';

import type { Vb365JobItemGroup, Vb365JobItemSite, Vb365JobItemTeam, Vb365JobItemUser } from '@veeam-vspc/models/rest';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { FetchMethod, GridStore, RequestParams } from '@veeam-vspc/components';

import { core } from 'core/core-module';
import { alternateTransformRequestParams } from 'core/utils';
import { MAX_INTEGER_32 } from 'core/const';

import type { GroupsFilters, SitesFilters, TeamsFilters, UsersFilters } from '../interfaces';

const genericEmptyResponse: BaseSuccessRequestResponse = {
    data: [],
    meta: {
        pagingInfo: {
            total: 0,
            count: 0,
            offset: 0,
        },
    },
};

export class BackupModeService {
    static abortController = new AbortController();

    static abortPreviousRequest() {
        BackupModeService.abortController.abort();
        BackupModeService.abortController = new AbortController();
    }

    static addWildcardToSearchQuery(params: RequestParams<unknown>) {
        if (!params.filter) {
            return params;
        }

        const target = deepClone(params);
        target.filter = {};

        for (const key in params.filter) {
            if (params.filter[key] === undefined || params.filter[key] === '') {
                continue;
            }

            target.filter[key] = `*${params.filter[key]}*`;
        }

        return target;
    }

    static calculateTotalCount(requestParams: RequestParams<unknown>, response: BaseSuccessRequestResponse<unknown>) {
        const { pagingInfo } = response.meta;
        const { limit, page } = requestParams;

        // enable when there be an information about the end
        // return isEnd ? limit * page - (limit - pagingInfo.count) : MAX_INTEGER_32;
        return pagingInfo.count < limit ? limit * page - (limit - pagingInfo.count) : MAX_INTEGER_32;
    }

    static getGroups(
        vb365ServerUid: string,
        instanceUid: string,
        gridApi: GridStore<Vb365JobItemGroup, unknown, GroupsFilters>
    ): FetchMethod<Vb365JobItemGroup, GroupsFilters> {
        return (params: RequestParams<GroupsFilters>) => {
            BackupModeService.abortPreviousRequest();

            return core.transportService
                .get<{}, Vb365JobItemGroup[]>(
                    `/infrastructure/vb365Servers/${vb365ServerUid}/organizations/${instanceUid}/groups/sync`,
                    alternateTransformRequestParams(BackupModeService.addWildcardToSearchQuery(params)),
                    { signal: BackupModeService.abortController.signal }
                )
                .then((response: BaseSuccessRequestResponse<Vb365JobItemGroup[]>) => {
                    response.meta.pagingInfo.total = BackupModeService.calculateTotalCount(params, response);

                    return response;
                })
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                        return genericEmptyResponse;
                    }

                    return err;
                });
        };
    }

    static getUsers(
        vb365ServerUid: string,
        instanceUid: string,
        gridApi: GridStore<Vb365JobItemUser, unknown, UsersFilters>
    ): FetchMethod<Vb365JobItemUser, UsersFilters> {
        return (params: RequestParams<UsersFilters>) => {
            BackupModeService.abortPreviousRequest();

            return core.transportService
                .get<{}, Vb365JobItemUser[]>(
                    `/infrastructure/vb365Servers/${vb365ServerUid}/organizations/${instanceUid}/users/sync`,
                    alternateTransformRequestParams(BackupModeService.addWildcardToSearchQuery(params)),
                    { signal: BackupModeService.abortController.signal }
                )
                .then((response: BaseSuccessRequestResponse<Vb365JobItemUser[]>) => {
                    response.meta.pagingInfo.total = BackupModeService.calculateTotalCount(params, response);

                    return response;
                })
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                        return genericEmptyResponse;
                    }

                    return err;
                });
        };
    }

    static getSites(
        vb365ServerUid: string,
        instanceUid: string,
        gridApi: GridStore<Vb365JobItemSite, unknown, SitesFilters>
    ): FetchMethod<Vb365JobItemSite, SitesFilters> {
        return (params: RequestParams<SitesFilters>) => {
            BackupModeService.abortPreviousRequest();

            return core.transportService
                .get<{}, Vb365JobItemSite[]>(
                    `/infrastructure/vb365Servers/${vb365ServerUid}/organizations/${instanceUid}/sites/sync`,
                    alternateTransformRequestParams(BackupModeService.addWildcardToSearchQuery(params)),
                    { signal: BackupModeService.abortController.signal }
                )
                .then((response: BaseSuccessRequestResponse<Vb365JobItemSite[]>) => {
                    response.meta.pagingInfo.total = BackupModeService.calculateTotalCount(params, response);

                    return response;
                })
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                        return genericEmptyResponse;
                    }

                    return err;
                });
        };
    }

    static getTeams(
        vb365ServerUid: string,
        instanceUid: string,
        gridApi: GridStore<Vb365JobItemTeam, unknown, TeamsFilters>
    ): FetchMethod<Vb365JobItemTeam, TeamsFilters> {
        return (params: RequestParams<TeamsFilters>) => {
            BackupModeService.abortPreviousRequest();

            return core.transportService
                .get<{}, Vb365JobItemTeam[]>(
                    `/infrastructure/vb365Servers/${vb365ServerUid}/organizations/${instanceUid}/teams/sync`,
                    alternateTransformRequestParams(BackupModeService.addWildcardToSearchQuery(params)),
                    { signal: BackupModeService.abortController.signal }
                )
                .then((response: BaseSuccessRequestResponse<Vb365JobItemTeam[]>) => {
                    response.meta.pagingInfo.total = BackupModeService.calculateTotalCount(params, response);

                    return response;
                })
                .catch((err) => {
                    if (err?.code === DOMException.ABORT_ERR) {
                        setTimeout(() => {
                            if (gridApi) {
                                gridApi.loading = true;
                            }
                        });
                    }

                    return err;
                });
        };
    }
}
