/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { createLinkControl } from '@veeam/components';
import { TimeUnit } from '@veeam-vspc/models/web-controllers';
import { useMemo } from 'react';

import { AgentBackups } from './components/AgentBackups';
import { ConsumedStorage } from './components/ConsumedStorage';
import { FilterContext } from '../../contexts';
import { FilterStore } from '../../stores';
import { SobrUsage } from './components/SobrUsage';
import { StatefulCombobox } from '../StatefulCombobox';
import { VmBackups } from './components/VmBackups';
import { VmReplicas } from './components/VmReplicas';
import { WidgetColumnsStyled, WidgetViewStyled, WidgetRowsStyled } from '../../../../../components/layouts/WidgetView';
import { useFilterStore } from '../../hooks';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { ComboboxItem } from '../../interfaces';

const CloudConnectLayout = observer(() => {
    const filterStore = useFilterStore();
    const lang = useLang();

    return (
        <WidgetViewStyled>
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                label={`${lang.REPORTING_PERIOD}:`}
                onChange={filterStore.setReportingPeriod}
                stateId={'trends.cloud-connect.reporting-period'}
                textGetter={(p: ComboboxItem) => p.text}
                value={filterStore.reportingPeriod}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: TimeUnit.Weeks, text: lang.WEEKLY },
                    { id: TimeUnit.Months, text: lang.MONTHLY },
                ]}
            />

            <WidgetColumnsStyled>
                <WidgetRowsStyled>
                    <VmBackups />
                    <AgentBackups />
                    <SobrUsage />
                </WidgetRowsStyled>

                <WidgetRowsStyled>
                    <VmReplicas />
                    <ConsumedStorage />
                </WidgetRowsStyled>
            </WidgetColumnsStyled>
        </WidgetViewStyled>
    );
});

export const CloudConnect: React.FC = () => {
    const filterStore = useMemo(() => new FilterStore(), []);

    return (
        <FilterContext.Provider value={filterStore}>
            <CloudConnectLayout />
        </FilterContext.Provider>
    );
};
