/**
 * Copyright © Veeam Software Group GmbH.
 */

import { core } from 'core/core-module';

export const getClientDocUrl = (): string => {
    const { portalUser } = core.portalService.getPortalData();

    if (portalUser.isAdminPortal()) {
        return RCOP.utils.HelpCenter.getHelpResource(643);
    }

    if (portalUser.isResellerPortal()) {
        return RCOP.utils.HelpCenter.getHelpResource(645);
    }

    if (portalUser.isClientPortal()) {
        return RCOP.utils.HelpCenter.getHelpResource(644);
    }

    throw new Error('You are in an unknown portal');
};
