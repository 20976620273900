/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    LinkButton,
    Notification,
} from '@veeam-vspc/components';

import helpIcon from 'images/view_help.png';
import hideIcon from 'images/hide.png';
import { useLang } from 'views/providers/LangProvider/hooks';

export const ToggleInfo: React.FC = ({ children }) => {
    const lang = useLang();
    const [isOpen, setIsOpen] = useState(false);
    const currentIcon = isOpen ? hideIcon : helpIcon;
    const currentText = isOpen ? lang.HIDE_HELP : lang.VIEW_HELP;

    return (
        <>
            <LinkButton
                iconBefore={currentIcon}
                onClick={() => setIsOpen(!isOpen)}
            >
                {currentText}
            </LinkButton>

            {isOpen && (
                <Notification>
                    {children}
                </Notification>
            )}
        </>
    );
};
