/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { useJobsOverviewStore } from '../../../../store';
import { DateTitle } from '../DayBase';
import { OutOfPeriodWrapper } from './OutOfPeriod.styled';

import type { DayProps } from '../../../../interfaces/day-props';

export const OutOfPeriod: React.FC<DayProps> = ({ id }) => {
    const store = useJobsOverviewStore();
    const record = store.findById(id);

    return (
        <OutOfPeriodWrapper>
            <DateTitle>
                {record.displayDateForCell}
            </DateTitle>
        </OutOfPeriodWrapper>
    );
};
