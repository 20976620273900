/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ACTION_VIEW, TextColumn, SortingMode } from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { WizardStepData, GridStore } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useCompanyWizardStore } from '../../../../hooks';
import { ActionsToolbar } from './components/ActionsToolbar';
import { getVboProxy } from './utils';
import { getVb365RepositoryStorageTypes } from 'core/utils';

import type { VboRepository } from 'core/interfaces';
import type { CompanyData } from '../../../../interfaces';

export interface Microsoft365RepositoriesProps extends WizardStepData<CompanyData> {
    hidePanel: () => void;
}

export const Microsoft365Repositories = observer((props: Microsoft365RepositoriesProps) => {
    const lang = useLang();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<VboRepository, void, void>>();
    const wizardStore = useCompanyWizardStore();

    const [vb365RepositoryStorageTypes] = useState(getVb365RepositoryStorageTypes(lang));

    const [repositories] = useState([...data.vboInfrastructure.vboRepositories]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Promise.all([
            wizardStore.availableVboBackupRepositoryCache.load({ companyId: data.id }),
            wizardStore.availableVboProxiesCache.load({ companyId: data.id }),
        ])
            .then(() => setLoading(false));
    }, []);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={capitalize(lang.VEEAM_BACKUP_FOR_MICROSOFT_365_REPOSITORIES)}
            description={lang.SELECT_A_BACKUP_SERVER_TO_ASSIGN_TO_THE_COMPANY}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.vboInfrastructure.vboRepositories = api.current.pageData;

                        onDataChange(data);

                        hidePanel();
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid <VboRepository, void, void>
                defaultColumnWidth={100}
                columns={[
                    {
                        title: capitalize(lang.BACKUP_PROXY),
                        field: 'proxy',
                        sorterFn(a, b) {
                            const first = getVboProxy(
                                a.repositoryUid,
                                wizardStore.availableVboProxiesCache.value,
                                wizardStore.availableVboBackupRepositoryCache.value
                            )?.name;

                            const second = getVboProxy(
                                b.repositoryUid,
                                wizardStore.availableVboProxiesCache.value,
                                wizardStore.availableVboBackupRepositoryCache.value
                            )?.name;

                            return `${first}`.localeCompare(`${second}`, 'kn', { numeric: true, sensitivity: 'base' });
                        },
                        cell: ({ rowData }) => (
                            <TextColumn>
                                {getVboProxy(
                                    rowData.repositoryUid,
                                    wizardStore.availableVboProxiesCache.value,
                                    wizardStore.availableVboBackupRepositoryCache.value
                                )?.name}
                            </TextColumn>
                        ),
                    },
                    {
                        title: capitalize(lang.BACKUP_REPOSITORY),
                        field: 'repository',
                        sorterFn(a, b) {
                            const first = wizardStore.availableVboBackupRepositoryCache.value
                                ?.find(item => item.instanceUid === a.repositoryUid)?.name;
                            const second = wizardStore.availableVboBackupRepositoryCache.value
                                ?.find(item => item.instanceUid === b.repositoryUid)?.name;

                            return `${first}`.localeCompare(`${second}`, 'kn', { numeric: true, sensitivity: 'base' });
                        },
                        cell: ({ rowData }) => (
                            <TextColumn>
                                {wizardStore.availableVboBackupRepositoryCache.value?.find(item => item.instanceUid === rowData.repositoryUid)?.name}
                            </TextColumn>
                        ),
                    },
                    {
                        title: lang.TYPE,
                        field: 'storageType',
                        sorterFn(a, b) {
                            const firstStorageType = wizardStore.availableVboBackupRepositoryCache.value
                                ?.find(item => item.instanceUid === a.repositoryUid)?.storageType;
                            const first = vb365RepositoryStorageTypes.find(item => item.id === firstStorageType)?.name;

                            const secondStorageType = wizardStore.availableVboBackupRepositoryCache.value
                                ?.find(item => item.instanceUid === b.repositoryUid)?.storageType;
                            const second = vb365RepositoryStorageTypes.find(item => item.id === secondStorageType)?.name;

                            return first.localeCompare(second, undefined, { sensitivity: 'base' });
                        },
                        cell: ({ rowData }) => {
                            const storageType = wizardStore.availableVboBackupRepositoryCache.value
                                ?.find(item => item.instanceUid === rowData.repositoryUid)?.storageType;
                            return <TextColumn>{vb365RepositoryStorageTypes.find(item => item.id === storageType)?.name}</TextColumn>;
                        },
                    },
                    {
                        title: capitalize(lang.USERS_QUOTA),
                        field: 'quota',
                        sorterFn(a, b) {
                            const first = a.isUserQuotaUnlimited ? lang.UNLIMITED : a.userQuota;
                            const second = b.isUserQuotaUnlimited ? lang.UNLIMITED : b.userQuota;

                            return `${first}`.localeCompare(`${second}`, 'kn', { numeric: true, sensitivity: 'base' });
                        },
                        cell: ({ rowData }) => (
                            <TextColumn>
                                {rowData.isUserQuotaUnlimited ? lang.UNLIMITED : rowData.userQuota}
                            </TextColumn>
                        ),
                    },
                ]}
                data={() => Promise.resolve({
                    data: repositories,
                    meta: {
                        pagingInfo: {
                            total: repositories?.length,
                        },
                    },
                } as BaseSuccessRequestResponse<VboRepository[]>)}
                toggleable={false}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'repositoryUid',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => (
                        <ActionsToolbar
                            items={repositories}
                            companyId={data.id}
                            companyUid={data.organizationUid}
                            vboServers={data.vboInfrastructure.vboServers}
                        />
                    ),
                ]}
                api={api}
                isLoading={loading}
                sortingMode={SortingMode.Local}
            />
        </SidePanelForm>
    );
});
