/**
 * Copyright © Veeam Software Group GmbH.
 */

import { formatStr } from '@veeam-vspc/core';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Checkbox,
    CONTROL_SIZE,
    defaultMaskPlaceholder,
    Form,
    FormLayout,
    FormValidator,
    isValid,
    LabelGroup,
    LinkButton,
    NoLabelGroup,
    PasswordInput,
    STACK_DIRECTION,
    Textarea,
    TextInput,
    useExternalFormApi,
} from '@veeam-vspc/components';

import type { ExternalFormApi, WizardStep } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { CheckItemExistType } from 'core/enums';
import { extjsValidate } from 'core/utils/validators';
import { CommonService } from 'core/services/common-service';
import { trimTextFields } from 'core/utils/string-helpers';
import { useResellerWizardStore } from '../../hooks/';
import keyIcon from 'images/actions/key.png';

import type { ResellerWizardStepData, ResellerData } from '../../interfaces';
import type { ResellerWizardStore } from '../../stores';
import type { IsItemExistsParams } from 'core/interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

interface StepData {
    formApi?: ExternalFormApi<ResellerData>;
}

const isPasswordFieldDisabled =
    (isEdit: boolean, initialData: ResellerData, changePassword: boolean) => isEdit && initialData.password && !changePassword;
const isConfirmPasswordFieldHidden = (isEdit: boolean, initialData: ResellerData) => isEdit && initialData.password;

const formValidate = (data: ResellerData, lang: LangsServiceBase, wizardStore: ResellerWizardStore, isEdit: boolean) => {
    const validator = new FormValidator(data);
    const { changePassword } = wizardStore;
    const initialData = wizardStore.initialData;

    validator.validate('userName')
        .string()
        .required()
        .maxLength(128)
        .check(v => extjsValidate(v, RCOP.utils.Validator.tenantName));

    if (!isPasswordFieldDisabled(isEdit, initialData, changePassword)) {
        validator.validate('password')
            .password()
            .required(lang.PASSWORD_FIELD_CANNOT_BE_EMPTY)
            .maxLength(128);

        if (!isConfirmPasswordFieldHidden(isEdit, initialData)) {
            validator.validate('confirmPassword')
                .password()
                .required()
                .check((v) => {
                    if (v && data.password !== v) {
                        return lang.PASSWORDS_DO_NOT_MATCH;
                    }
                    return '';
                });
        }
    }

    validator.validate('description')
        .string()
        .maxLength(1000);

    return validator.result();
};

const stepValidate = (data: ResellerData, lang: any, wizardStore: ResellerWizardStore, isEdit: boolean, stepData: StepData) => {
    // trim text fields
    trimTextFields(data, ['userName']);
    stepData.formApi?.setValue(data);

    if (isValid(value => formValidate(value, lang, wizardStore, isEdit), data)) {
        const checkData: IsItemExistsParams = {
            id: isEdit ? data.userId : null,
            type: CheckItemExistType.ResellerOwner,
            name: data.userName,
            additionalId: isEdit ? data.id : -1,
        };

        return CommonService.isItemExist(checkData)
            .then((exist) => {
                if (exist) {
                    stepData.formApi.setExternalErrors({
                        userName: lang.THIS_USERNAME_IS_ALREADY_USED,
                    });
                }
                return !exist;
            });
    }

    return false;
};

export const getUserInfoStep = (lang: any, wizardStore: ResellerWizardStore): WizardStep<ResellerData> => ({
    title: lang.USER_INFO,
    validate: ({ data, isEdit, stepData }) => stepValidate(data, lang, wizardStore, isEdit, stepData),
    render: stepData => <UserInfoStep {...stepData} wizardStore={wizardStore} />,
});

const UserInfoStep = observer((wizardStepData: ResellerWizardStepData) => {
    const { data, validationState, onDataChange, isEdit } = wizardStepData;
    const stepData = wizardStepData.stepData as StepData;
    const lang = useLang();
    const formApi = useExternalFormApi<ResellerData>();
    const defaultFieldWidth = CONTROL_SIZE.l;
    const wizardStore = useResellerWizardStore();

    const checkErrorsForResources = (data: ResellerData) => {
        const names = [];

        if (data.companyMap) {
            data.companyMap.forEach((company) => {
                if (company.isRestEnabled) {
                    names.push(company.companyName);
                }
            });
        }

        if (names.length) {
            core.notificationService.error(lang.DISABLE_ACCESS, formatStr(lang.CANNOT_DISABLE_RESTFUL_APIS_ACCESS, names.join(', ')));
            return false;
        }

        return true;
    };

    useEffect(() => {
        stepData.formApi = formApi;

        return () => {
            delete stepData.formApi;
        };
    }, []);

    return (
        <StepLayout
            title={lang.USER_INFO}
            description={lang.CREATE_USER_ACCOUNT_FOR_THE_RESELLER}
        >
            <Form
                value={data}
                validate={(data: ResellerData) => formValidate(data, lang, wizardStore, isEdit)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <TextInput
                        name={'userName'}
                        label={lang.USERNAME}
                        size={defaultFieldWidth}
                    />

                    <LabelGroup label={`${lang.PASSWORD}:`}>
                        <FormLayout direction={STACK_DIRECTION.row}>
                            <PasswordInput
                                name={'password'}
                                size={defaultFieldWidth}
                                disabled={isPasswordFieldDisabled(isEdit, wizardStore.initialData, wizardStore.changePassword)}
                                autocomplete={'new-password'}
                                hasValueMask={defaultMaskPlaceholder}
                            />

                            {isPasswordFieldDisabled(isEdit, wizardStore.initialData, wizardStore.changePassword) && (
                                <LinkButton
                                    iconBefore={keyIcon}
                                    onClick={() => {
                                        data.password = '';
                                        formApi.setValue(data);
                                        wizardStore.changePassword = true;
                                    }}
                                >
                                    {lang.CHANGE_PASSWORD}
                                </LinkButton>
                            )}
                        </FormLayout>
                    </LabelGroup>

                    {!isConfirmPasswordFieldHidden(isEdit, wizardStore.initialData) && (
                        <PasswordInput
                            name={'confirmPassword'}
                            label={lang.CONFIRM_PASSWORD}
                            size={defaultFieldWidth}
                            autocomplete={'new-password'}
                        />
                    )}

                    <NoLabelGroup
                        content={(
                            <FormLayout>
                                <Checkbox
                                    name={'restEnabled'}
                                    onChange={(checked) => {
                                        if (!checked && !checkErrorsForResources(data)) {
                                            data.restEnabled = true;
                                            formApi.setValue(data);
                                        }
                                    }}
                                >
                                    {lang.ENABLE_ACCESS_TO_REST_API}
                                </Checkbox>

                                <Textarea
                                    name={'description'}
                                    label={lang.DESCRIPTION}
                                    size={CONTROL_SIZE.full}
                                    rows={10}
                                />
                            </FormLayout>
                        )}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
