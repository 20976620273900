/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import set from 'lodash/set';
import {
    debounce,
    throttle,
    makeTwoDigit,
    makePercentFromNumber,
    deepEqual,
    deepCopy,
    safelyParseJson,
    delay,
    getParamValueFromUrl,
    prepareUrl,
    getDevelopmentHeaders,
    addParamsToQuery,
    download,
    AppStorage,
    generateGuid,
    generateRandomValue,
    htmlEncode,
    htmlDecode,
    getStrBetween,
    observe,
    copyTextToClipboard,
    TimeUnitsInMs,
} from '@veeam-vspc/core';

import { AppProvider } from 'views/providers/AppProvider';
import { baseUrl } from '../../consts';
import {
    calcRoundingPercents,
    getHelpResourceLink,
    getProductTheme,
    markdownToHtml,
    sanitizeComplexObject,
} from 'core/utils';
import { ExtTransportService } from '../ext-transport/ext-transport-service';

import type { CoreModule } from 'core/core-module';

export class ReactToExtService {
    private extTransportService: ExtTransportService;

    private components: Record<string, (props: any) => JSX.Element> = {
        AppProvider,
    };

    private utils: Record<string, any> = {
        debounce,
        throttle,
        makeTwoDigit,
        makePercentFromNumber,
        deepEqual,
        deepCopy,
        safelyParseJson,
        delay,
        getParamValueFromUrl,
        prepareUrl,
        getDevelopmentHeaders,
        addParamsToQuery,
        download,
        AppStorage,
        generateGuid,
        generateRandomValue,
        htmlEncode,
        htmlDecode,
        getStrBetween,
        observe,
        copyTextToClipboard,
        getProductTheme,
        calcRoundingPercents,
        sanitizeComplexObject,
        getHelpResourceLink,
        lodash: { get, set },
        baseUrl,
        TimeUnitsInMs,
        markdownToHtml,
    };

    constructor(private readonly coreModule: CoreModule) {}

    renderTo(componentName: string, container: HTMLElement, props: Record<string, any> = {}): void {
        ReactDOM.render(
            React.createElement(this.getComponent(componentName), props),
            container
        );
    }

    unmountFrom(container: HTMLElement): void {
        ReactDOM.unmountComponentAtNode(container);
    }

    getComponent(name: string): (props: any) => JSX.Element {
        if (!this.components[name]) {
            throw Error(`Failed to find ${name} component`);
        }

        return this.components[name];
    }

    getService(name: string): any {
        if (name === 'transportService') {
            if (!this.extTransportService) {
                this.extTransportService = new ExtTransportService(this.coreModule.transportService, this.coreModule.enumMapperService);
            }

            return this.extTransportService;
        }

        return this.coreModule.getServices()[name];
    }

    getUtils(name: string): any {
        return this.utils[name];
    }

}
