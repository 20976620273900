/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridSortDirections, TextColumn } from '@veeam-vspc/components';

import type { CellRendererProps } from '@veeam-vspc/components';
import type { PublicCloudRdsJobModel, PublicCloudRdsJobParam } from '@veeam-vspc/models/web-controllers';

import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { CloudDatabasesJobActionToolbar, CloudDatabasesJobFilterToolbar } from './components';
import { StatusAgoColumn } from '../StatusAgoColumn';
import { ConfigSectionIds } from 'core/services';

export interface CloudDatabasesProps {
    stateId: string;
}

export const CloudDatabases: React.FC<CloudDatabasesProps> = ({ stateId }) => {
    const lang = useLang();
    const { transportService, enumMapperService } = useAppServices();
    const gridStateId = `${stateId}.CloudDatabases`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<PublicCloudRdsJobModel>) => <TextColumn>{cellData}</TextColumn>;
    const columns = [
        {
            field: 'vmName',
            title: lang.INSTANCE,
            cell: defaultColumnRender,
        },
        {
            field: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: defaultColumnRender,
        },
        {
            field: 'company',
            title: lang.COMPANY,
            cell: defaultColumnRender,
        },
        {
            field: 'site',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'location',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'resourceId',
            title: lang.RESOURCE_ID,
            cell: defaultColumnRender,
        },
        {
            field: 'platformType',
            title: lang.PLATFORM,
            cell: ({ cellData }: CellRendererProps<PublicCloudRdsJobModel>) => (
                <TextColumn>
                    {enumMapperService.getEnumDescription('PublicCloudPlatformTypeRepresentation', cellData)}
                </TextColumn>
            ),
        },
        {
            field: 'jobName',
            title: lang.POLICY,
            cell: defaultColumnRender,
        },
        {
            field: 'enabled',
            title: lang.POLICY_STATE,
            cell: ({ cellData }: CellRendererProps<PublicCloudRdsJobModel>) => (
                <TextColumn>{cellData ? lang.ENABLED : lang.DISABLED}</TextColumn>
            ),
            hidden: true,
        },
        {
            field: 'lastSnapshot.sessionStateName',
            title: lang.LAST_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsJobModel>) => (
                <StatusAgoColumn
                    url={'/PublicCloudRdsJob/GetSnapshotTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastSnapshot}
                />
            ),
        },
        {
            field: 'lastRemoteSnapshot.sessionStateName',
            title: lang.LAST_REPLICA_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsJobModel>) => (
                <StatusAgoColumn
                    url={'/PublicCloudRdsJob/GetRemoteSnapshotTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastRemoteSnapshot}
                />
            ),
            width: 200,
        },
        {
            field: 'lastBackup.sessionStateName',
            title: lang.LAST_BACKUP,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsJobModel>) => (
                <StatusAgoColumn
                    url={'/PublicCloudRdsJob/GetBackupTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastBackup}
                />
            ),
        },
        {
            field: 'lastArchive.sessionStateName',
            title: lang.LAST_ARCHIVE,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsJobModel>) => (
                <StatusAgoColumn
                    url={'/PublicCloudRdsJob/GetArchiveTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastArchive}
                />
            ),
        },
        {
            field: 'nextRun',
            title: lang.NEXT_RUN,
            cell: ({ cellData }: CellRendererProps<PublicCloudRdsJobModel>) => (
                <GridDateColumn value={cellData} />
            ),
            hidden: true,
        },
        {
            field: 'backupServerName',
            title: lang.SERVER_NAME,
            cell: defaultColumnRender,
            hidden: true,
        },
    ];

    return (
        <AdvancedGrid
            sectionId={ConfigSectionIds.BackupJobsCloudDatabases}
            stateId={gridStateId}
            defaultColumnWidth={100}
            initialState={{
                sort: {
                    key: 'status',
                    direction: GridSortDirections.Asc,
                },
            }}
            columns={columns}
            data={(requestParams: PublicCloudRdsJobParam) => transportService
                .request<typeof requestParams, PublicCloudRdsJobModel[]>('/PublicCloudRdsJob/GetRdsJob', { ...requestParams })}
            isRowInactive={({ rowData }) => !rowData.enabled}
            toggleable={true}
            selection={{
                field: 'id',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <CloudDatabasesJobFilterToolbar />,
                () => <CloudDatabasesJobActionToolbar idProperty={'id'} selectedOnly />,
            ]}
        />
    );
};
