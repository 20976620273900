/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    CONTROL_SIZE,
    Form,
    FormLayout,
    FormTitle,
    FieldLayout,
    NumberInput,
    NoLabelGroup,
    Checkbox,
    FormValidator,
    useExternalFormApi, isValid,
} from '@veeam-vspc/components';
import { formatStr } from '@veeam-vspc/core';
import { ThrottlingUnitRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStepData } from '@veeam-vspc/components/src/DialogWizard/interfaces/wizard-step-data';
import type { WizardStep } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { PerBytesField } from 'components/controls/PerBytesField';
import { ToggleField } from 'components/controls/ToggleField';
import { getBandwidthUnits, getBandwidthUnitsText } from 'views/components/wizard/utils';
import { core } from 'core/core-module';

import type { ResellerData } from '../../interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

const MAX_CONCURRENT_TASK = 10000;
const MIN_CONCURRENT_TASK = 1;

const MAX_ALLOWED_THROTTLING = 9999;
const MIN_ALLOWED_THROTTLING = 1;

const minThrottling = {
    value: MIN_ALLOWED_THROTTLING,
    unit: ThrottlingUnitRepresentation.KbytePerSec,
};

const getSpeedKbitPerSec = ({ value, unit }: { value: number; unit: ThrottlingUnitRepresentation; }) => {
    switch (unit) {
        case ThrottlingUnitRepresentation.KbytePerSec: return value * 8;
        case ThrottlingUnitRepresentation.MbytePerSec: return value * 8000;
        case ThrottlingUnitRepresentation.MbitPerSec: return value * 1000;
    }
};

const getAllowedMinConcurrentTask = (data: ResellerData) =>
    data.companyMap.length > 0
        ? data.companyMap.reduce((acc, item) => item.maxConcurrentTasks > acc ? item.maxConcurrentTasks : acc, MIN_CONCURRENT_TASK)
        : MIN_CONCURRENT_TASK;

const getAllowedMinBandwidth = (data: ResellerData) => data.companyMap.length > 0
    ? data.companyMap.reduce(
        (acc, item) => {
            const companyThrottling = {
                value: item.throttlingValue,
                unit: item.throttlingUnit,
            };

            return getSpeedKbitPerSec(companyThrottling) > getSpeedKbitPerSec(acc) ? companyThrottling : acc;
        },
        minThrottling
    )
    : minThrottling;

const formValidate = (data: ResellerData) => {
    const validator = new FormValidator(data);

    if (!data.isUnlimitedBandwidth) {
        validator.validate('maxConcurrentTasks')
            .number()
            .required()
            .min(MIN_CONCURRENT_TASK)
            .max(MAX_CONCURRENT_TASK);

        if (data.throttlingEnabled) {
            validator.validate('throttlingValue')
                .number()
                .required()
                .min(MIN_ALLOWED_THROTTLING)
                .max(MAX_ALLOWED_THROTTLING);
        }
    }

    return validator.result();
};

const stepValidate = (data: ResellerData, lang: LangsServiceBase) => {
    if (isValid(formValidate, data)) {
        if (!data.isUnlimitedBandwidth) {
            const allowedMinConcurrentTask = getAllowedMinConcurrentTask(data);
            if (data.maxConcurrentTasks < allowedMinConcurrentTask) {
                core.notificationService.error(lang.BANDWIDTH, formatStr(lang.MAX_CONCURRENT_TASKS_NUMBER_CANNOT, allowedMinConcurrentTask));
                return false;
            }

            if (data.throttlingEnabled) {
                if (data.companyMap.some(company => company.throttlingValue === null)) {
                    core.notificationService.error(
                        lang.BANDWIDTH,
                        lang.CANNOT_SET_A_LIMIT_TO_THE_INCOMING_NETWORK_TRAFFIC
                    );
                    return false;
                }

                const allowedMinBandwidth = getAllowedMinBandwidth(data);
                if (getSpeedKbitPerSec({
                    value: data.throttlingValue,
                    unit: data.throttlingUnit,
                }) < getSpeedKbitPerSec(allowedMinBandwidth)) {
                    core.notificationService.error(
                        lang.BANDWIDTH,
                        formatStr(
                            lang.CANNOT_SET_A_LIMIT_TO,
                            allowedMinBandwidth.value,
                            getBandwidthUnitsText(allowedMinBandwidth.unit, lang)
                        )
                    );
                    return false;
                }
            }
        }

        return true;
    }

    return false;
};

export const getBandwidthStep = (lang: LangsServiceBase): WizardStep<ResellerData> => ({
    title: lang.BANDWIDTH,
    validate: ({ data }) => stepValidate(data, lang),
    render: stepData => <BandwidthStep {...stepData} />,
});

const BandwidthStep = observer((wizardStepData: WizardStepData<ResellerData>) => {
    const lang = useLang();
    const { data, onDataChange, validationState } = wizardStepData;
    const formApi = useExternalFormApi<ResellerData>();

    return (
        <StepLayout
            title={lang.BANDWIDTH}
            description={lang.SPECIFY_MAXIMUM_NUMBER_OF_TASK_SLOTS}
        >
            <Form
                value={data}
                validate={formValidate}
                validationState={validationState}
                onChange={onDataChange}
                externalFormApi={formApi}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.CONCURRENT_TASKS}</FormTitle>

                    <NoLabelGroup
                        content={(
                            <FormLayout inlineLabel>
                                <ToggleField
                                    inlineLabel={true}
                                    label={lang.LIMIT_BANDWIDTH}
                                    value={!data.isUnlimitedBandwidth}
                                    showSuffix={true}
                                    onChange={(value) => {
                                        formApi.setValue('isUnlimitedBandwidth', !value);
                                    }}
                                />
                            </FormLayout>
                        )}
                        subContent={(
                            <FormLayout
                                inlineLabel
                                disabled={data.isUnlimitedBandwidth}
                            >
                                <FieldLayout
                                    helpText={`${lang.MAX_CONCURRENT_TASK_DESCRIPTION} ${lang.RESELLER_WILL_NOT_BE_ABLE_TO_CONFIGURE}`}
                                >
                                    <NumberInput
                                        inlineLabel={true}
                                        name={'maxConcurrentTasks'}
                                        label={lang.MAX_CONCURRENT_TASKS}
                                        minValue={MIN_CONCURRENT_TASK}
                                        maxValue={MAX_CONCURRENT_TASK}
                                        suffix={lang.PER_COMPANY}
                                    />
                                </FieldLayout>

                                <NoLabelGroup
                                    disabled={data.isUnlimitedBandwidth}
                                    content={(
                                        <Checkbox
                                            name={'throttlingEnabled'}
                                            disabled={data.isUnlimitedBandwidth} // TODO: fix in parent NoLabelGroup
                                        >
                                            {lang.LIMIT_INCOMING_NETWORK_TRAFFIC_TO}
                                        </Checkbox>
                                    )}
                                    subContent={(
                                        <FormLayout
                                            inlineLabel
                                            disabled={!data.throttlingEnabled}
                                        >
                                            <FieldLayout
                                                helpText={lang.DEFINES_MAXIMUM_INCOMING_NETWORK_TRAFFIC}
                                            >
                                                <PerBytesField
                                                    valueName={'throttlingValue'}
                                                    unitsName={'throttlingUnit'}
                                                    units={getBandwidthUnits(lang)}
                                                    unitSize={CONTROL_SIZE.xs}
                                                    minValue={MIN_ALLOWED_THROTTLING}
                                                    maxValue={MAX_ALLOWED_THROTTLING}
                                                />
                                            </FieldLayout>
                                        </FormLayout>
                                    )}
                                />
                            </FormLayout>
                        )}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
