/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum AlarmStatus {
    Unknown,
    Resolve,
    Info,
    Warning,
    Error,
    Acknowledge,
}
