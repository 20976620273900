/**
 * Copyright © Veeam Software Group GmbH.
 */

import { CompanyStatus } from '../enums/company-status';

export const allowEditVcd = (status: CompanyStatus): boolean => [
    CompanyStatus.Phantom,
    CompanyStatus.Creating,
    CompanyStatus.SiteResourceFailedOnCreating,
].includes(status);
