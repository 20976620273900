/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { LangContext } from '../stores';

import type { LangsServiceBase } from 'core/services/langs/interfaces';


export const useLang = (): LangsServiceBase => useContext<LangsServiceBase>(LangContext);
