/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { MonitoredObjectType } from '@veeam-vspc/models/web-controllers';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { MonitoredObjectsContext } from './contexts/MonitoredObjectsContext';
import { MonitoredObjectsStore } from './stores/MonitoredObjectsStore';
import { MonitoredObjectsService } from './services/monitored-objects.service';
import { StatefulCombobox } from '../../../StatefulCombobox';
import { useMonitoredObjectsStore } from './hooks/use-monitored-objects-store';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { ComboboxItem } from '../../../../interfaces';

export const ChartLayout = observer(() => {
    const lang = useLang();
    const monitoredObjectsStore = useMonitoredObjectsStore();

    const params = {
        colors: [palette.B400],
        yTitle: lang.OBJECTS,
    };

    const title = {
        text: capitalize(lang.MONITORED_WORKLOADS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={monitoredObjectsStore.setMonitoredObjectType}
                stateId={'trends.backup-services.monitored-objects.monitored-object-type'}
                textGetter={(p: ComboboxItem) => p.text}
                value={monitoredObjectsStore.monitoredObjectType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: MonitoredObjectType.Vm, text: capitalize(lang.VMS) },
                    { id: MonitoredObjectType.Agent, text: capitalize(lang.AGENTS) },
                    { id: MonitoredObjectType.FileShare, text: capitalize(lang.FILE_SHARES) },
                    { id: MonitoredObjectType.CloudVm, text: capitalize(lang.CLOUD_VMS) },
                    { id: MonitoredObjectType.CloudDatabase, text: capitalize(lang.CLOUD_DATABASES) },
                    { id: MonitoredObjectType.CloudFileShare, text: capitalize(lang.CLOUD_FILE_SHARES) },
                    { id: MonitoredObjectType.Microsoft365User, text: capitalize(lang.MICROSOFT_365_10_USERS) },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={monitoredObjectsStore.monitoredObjectType}
            params={params}
            service={MonitoredObjectsService}
            title={title}
        />
    );
});

export const MonitoredObjects: React.FC = () => {
    const monitoredObjectsStore = useMemo(() => new MonitoredObjectsStore(), []);

    return (
        <MonitoredObjectsContext.Provider value={monitoredObjectsStore}>
            <ChartLayout />
        </MonitoredObjectsContext.Provider>
    );
};
