/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ValueTypes } from 'core/utils/sanitize-complex-object/enums/value-types';
import { calcValueType } from './calc-value-type';

export const sanitizeComplexObject = <T extends ({} & E[]), E extends T>(obj: T, dontSanitizeThese?: string[]) => {
    dontSanitizeThese = dontSanitizeThese || [];
    const valueType = calcValueType(obj);
    const sanitizeString = DOMPurify.sanitize;

    switch (valueType) {
        case ValueTypes.Object:
            for (const [key, value] of Object.entries(obj)) {
                const valueType = calcValueType(value);

                if (valueType === ValueTypes.String) {
                    obj[key] = dontSanitizeThese.includes(key) ? value : sanitizeString(value);
                } else if (valueType === ValueTypes.Object || valueType === ValueTypes.Array) {
                    sanitizeComplexObject(value, dontSanitizeThese);
                }
            }

            break;
        case ValueTypes.Array:
            obj.forEach((value, index, array) => {
                const valueType = calcValueType(value);

                if (valueType === ValueTypes.String) {
                    array[index] = sanitizeString(value);
                } else if (valueType === ValueTypes.Object || valueType === ValueTypes.Array) {
                    sanitizeComplexObject(value, dontSanitizeThese);
                }
            });

            break;
    }
};
