/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { CloudDatabases, Computers, FileShares, VirtualMachines, VBObjects } from './components';


export interface BackupJobsPageProps {
    viewName: string;
    // For starting ext.js wizards from react
    extController: any; // @TODO Remove when move BackupJobs wizards to React
}

export const BACKUP_JOBS_PAGE = 'BackupJobsPage';

export const BackupJobsPage: React.FC<BackupJobsPageProps> = ({ viewName, ...restProps }) => (
    <>
        {viewName === 'ComputersManagedByConsole' && <Computers {...restProps} gridName='ManagedByConsole' />}
        {viewName === 'ComputersManagedByBackupServer' && <Computers {...restProps} gridName='ManagedByBackupServer' />}
        {viewName === 'VirtualMachinesVirtualInfrastructure' && <VirtualMachines {...restProps} gridName='VirtualInfrastructure' />}
        {viewName === 'VirtualMachinesPublicClouds' && <VirtualMachines {...restProps} gridName='PublicClouds' />}
        {viewName === 'FileSharesLocal' && <FileShares {...restProps} gridName='FileSharesLocal' />}
        {viewName === 'FileSharesPublicClouds' && <FileShares {...restProps} gridName='FileSharesPublicClouds' />}
        {viewName === 'CloudDatabases' && <CloudDatabases stateId={'BackupJobsPage'} {...restProps} />}
        {viewName === 'VBObjects' && <VBObjects {...restProps} />}
    </>
);
