/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    NumberInput,
    STACK_DIRECTION,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';
import { capitalize, formatStr } from '@veeam-vspc/core';

import type { ResellerCloudConnectAgent } from '@veeam-vspc/models/web-controllers';

import { useLang } from 'views/providers/LangProvider/hooks';
import { ToggleField } from 'components/controls/ToggleField';
import { useResellerWizardStore } from '../../../../hooks';
import { DEFAULT_TENANT_QUOTA } from '../../const';

interface Props {
    deactivate: () => void;
    onConfirm: () => void;
    items: ResellerCloudConnectAgent[];
}

interface FormData {
    tenantQuota: number;
    isTenantQuotaEnabled: boolean;
}

const MAX_TENANT_QUOTA = 99999;

const formValidate = (data: FormData, minTenantQuota: number) => {
    const validator = new FormValidator(data);

    if (data.isTenantQuotaEnabled) {
        validator.validate('tenantQuota')
            .number()
            .required()
            .min(minTenantQuota)
            .max(MAX_TENANT_QUOTA);
    }

    return validator.result();
};

export const TenantQuotaDialog = (props: Props) => {
    const lang = useLang();
    const wizardStore = useResellerWizardStore();

    const [items] = useState(props.items);
    const [data, setData] = useState<FormData>(() => {
        const overallTenantQuota = Math.max(...items.map(item => item.tenantQuota));
        return {
            isTenantQuotaEnabled: overallTenantQuota !== -1,
            tenantQuota: overallTenantQuota !== -1 ? overallTenantQuota : DEFAULT_TENANT_QUOTA,
        };
    });
    const [minTenantQuota] = useState(() => Math.max(
        ...items.map(item => wizardStore.cloudConnectAgentsCache.value.find(agent => agent.agentGuid === item.cloudConnectAgentUid).tenantQuotaUsage)
    ) || 1);

    const formApi = useExternalFormApi<FormData>();
    const [validationState] = useState(new ValidationState());

    return (
        <Dialog
            header={capitalize(lang.SET_QUOTA)}
            description={items.length > 1 ? formatStr(lang.SET_QUOTA_FOR, items.length) : lang.SET_QUOTA_FOR_THE}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        if (formApi.validate()) {
                            const tenantQuota = data.isTenantQuotaEnabled ? data.tenantQuota : -1;
                            items.forEach(item => item.tenantQuota = tenantQuota);

                            props.onConfirm();
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: props.deactivate,
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.auto,
            }}
        >
            <Form <FormData>
                value={data}
                validate={data => formValidate(data, minTenantQuota)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                        <ToggleField
                            name={'isTenantQuotaEnabled'}
                            label={lang.QUOTA}
                            showSuffix={true}
                        />

                        <NumberInput
                            inlineLabel={true}
                            name={'tenantQuota'}
                            suffix={capitalize(lang.MANAGED_COMPANIES)}
                            disabled={!data.isTenantQuotaEnabled}
                            minValue={minTenantQuota}
                            maxValue={MAX_TENANT_QUOTA}
                        />
                    </FormLayout>
                </FormLayout>
            </Form>
        </Dialog>
    );
};
