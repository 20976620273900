/**
 * Copyright © Veeam Software Group GmbH.
 */

import { baseConfig } from './base.config';
import { PortalUserRoles } from '../../../entries/portal-user/enums';
import { ConfigSectionIds, ConfigStates } from '../enums';
import { ExportActionIds } from '../../../../views/components/ToolbarWithExport/enums';
import { ComputersActionIds } from 'core/enums';

import type { BaseUserConfig } from '../interfaces';

export const portalReadonlyOperatorConfig: BaseUserConfig = {
    baseConfig: baseConfig,
    roleInt: PortalUserRoles.PortalReadonlyOperator,
    roleName: PortalUserRoles[PortalUserRoles.PortalReadonlyOperator] as keyof typeof PortalUserRoles,
    routes: [],
    sections: [
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByConsole,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByBackupServer,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructure,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesLocal,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataComputers,
            actions: {
                [ComputersActionIds.FileLevelRestorePortalTBD]: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsCloudDatabases,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsVirtualMachinesPublicClouds,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesPublicClouds,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataCloudDatabases,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataFileSharesPublicClouds,
            defaultActionsState: ConfigStates.Hidden,
            actions: {
                [ExportActionIds.ExportFillSpace]: ConfigStates.Visible,
                [ExportActionIds.ExportToGroup]: ConfigStates.Visible,
                [ExportActionIds.ExportCSV]: ConfigStates.Visible,
                [ExportActionIds.ExportXML]: ConfigStates.Visible,
            },
        },
    ],
};
