/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { PortalSpinner, TOOLBAR_ITEM_TYPE, toolbarItemDecorators, useGridApi } from '@veeam-vspc/components';
import { EPublicCloudRestoreType } from '@veeam-vspc/models/web-controllers';

import type { GetVbRestoreLinkParams, PublicCloudRdsModel, PublicCloudRdsFilter } from '@veeam-vspc/models/web-controllers';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { useLang } from 'views/providers/LangProvider/hooks';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import databaseRestoreIconSrc from 'images/actions/database-restore.svg';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { ConfigSectionIds } from 'core/services';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';

export interface CloudDatabasesActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items'> {
    method?: string;
}

export const CloudDatabasesActionToolbar: React.FC<CloudDatabasesActionToolbarProps> = (props) => {
    const { method = 'GetPublicCloudRds', ...restProps } = props;
    const lang = useLang();
    const { transportService } = useAppServices();
    const [loading, setLoading] = useState(false);
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSingleSelection(),
    ];
    const gridApi = useGridApi<PublicCloudRdsModel, unknown, PublicCloudRdsFilter>();
    const getLink = (restoreType: EPublicCloudRestoreType) => {
        const { selected } = gridApi;

        setLoading(true);

        transportService.request<GetVbRestoreLinkParams, string>('/VbPlugin/GetRestoreLink', {
            uid: selected[0].uid,
            vbServerId: selected[0].vbServerId,
            restoreType,
            backUrl: window.location.href,
        })
            .then((resp: BaseSuccessRequestResponse<string>) => {
                setLoading(false);
                window.open(resp.data as string, '_self');
            })
            .catch((err) => {
                if (err) {
                    console.error(err);
                }
                setLoading(false);
            });
    };

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                sectionId={ConfigSectionIds.ProtectedDataCloudDatabases}
                method={method}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: databaseRestoreIconSrc,
                        text: lang.RESTORE,
                        onClick: () => getLink(EPublicCloudRestoreType.RestoreRds),
                        decorators: defaultDisabledDecorators,
                    },
                ]}
            />

            {loading && <PortalSpinner />}
        </>
    );
};
