/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FormValidator } from '@veeam-vspc/components';

import type { FormErrors } from '@veeam-vspc/components';

import { domainUserNameNotLocalhost } from 'core/utils/validators';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { VbrDeploySettingsWizardModel } from '../../../entries';

export const stepValidate = (lang: LangsServiceBase) => (data: VbrDeploySettingsWizardModel): FormErrors<VbrDeploySettingsWizardModel> => {
    const validator = new FormValidator(data);

    validator
        .validate('adminUserName')
        .string()
        .maxLength(128)
        .required()
        .check(v => domainUserNameNotLocalhost(v, lang));

    validator
        .validate('adminPassword')
        .password()
        .maxLength(256)
        .required();

    return validator.result();
};
