/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import {
    INDENT,
    SPACE_FILL,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TitleH1,
} from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { ContentWrapper } from './components/ContentWrapper';

export const InfrastructureHealthSection: React.FC = (props) => {
    const lang = useLang();

    return (
        <StackView
            direction={STACK_DIRECTION.column}
            indentBottom={INDENT.l}
            spaceFill={SPACE_FILL.all}
            gap={STACK_GAP.s}
            className='infrastructure-health-section'
        >
            <TitleH1>
                {lang.INFRASTRUCTURE_HEALTH}
            </TitleH1>

            <ContentWrapper>
                {props.children}
            </ContentWrapper>
        </StackView>
    );
};
