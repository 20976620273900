/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { deepCopy } from '@veeam-vspc/core';
import {
    ACTION_VIEW,
    Checkbox,
    Combobox,
    CONTROL_SIZE,
    createSearchableControl,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    NoLabelGroup,
    NoteBar,
    NOTEBAR_STATUS,
    NumberInput,
    Text,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { CompanyVboRepositoryStorageType } from 'core/enums';
import { useCompanyWizardStore } from '../../../../../../hooks';
import { getVboProxy } from '../../utils';
import { DEFAULT_BV365_USER_QOUTA } from '../../../../../../consts';
import { getVb365RepositoryStorageTypes } from 'core/utils';

import type {
    CompanyVboProxy,
    VboRepository,
} from 'core/interfaces';
import type { VboServer } from '../../../../../../interfaces';

interface Props {
    deactivate: () => void;
    onConfirm: (data: VboRepository) => void;

    data?: VboRepository;
    existingItems: VboRepository[];
    companyId?: number;
    companyUid?: string;
    vboServers: VboServer[];
}

const MIN_USER_QUOTA = 1;
const MAX_USER_QUOTA = 999999;

const formValidate = (data: VboRepository, lang: any, isEdit: boolean) => {
    const validator = new FormValidator(data);

    validator.validate('proxyId')
        .string()
        .required();

    validator.validate('storageType')
        .string()
        .required();

    validator.validate('repositoryUid')
        .string()
        .required();

    if (!data.isUserQuotaUnlimited) {
        validator.validate('userQuota')
            .number()
            .required()
            .min(MIN_USER_QUOTA)
            .max(MAX_USER_QUOTA);
    }

    return validator.result();
};

const getDefaultData = (): VboRepository => ({
    repositoryUid: null,
    isUserQuotaUnlimited: true,
    repositoryFriendlyName: '',
    userQuota: DEFAULT_BV365_USER_QOUTA,
    vboServerUid: null,
    storageType: null,
});

export const Microsoft365RepositoryDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;
    const wizardStore = useCompanyWizardStore();

    const isEdit = !!props.data;
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.repositoryUid === props.data.repositoryUid), 1);
        }
        return result;
    });
    const [data, setData] = useState<VboRepository>(isEdit ? deepCopy(props.data) : getDefaultData());

    const [vb365RepositoryStorageTypes] = useState(getVb365RepositoryStorageTypes(lang));

    const filteredAvailableRepositories = data.proxyId && wizardStore.availableVboBackupRepositoryCache.value
        ? wizardStore.availableVboBackupRepositoryCache.value
            .filter(item => !existingItems.find(existingItem => existingItem.repositoryUid === item.instanceUid)
                && (!data.storageType || item.storageType === data.storageType)
                && getVboProxy(
                    item.instanceUid,
                    wizardStore.availableVboProxiesCache.value,
                    wizardStore.availableVboBackupRepositoryCache.value
                ).instanceUid === data.proxyId
            )
            .sort((a, b) => a.name.localeCompare(b.name, 'kn', { numeric: true, sensitivity: 'base' }))
        : [];


    const [filteredAvailableVboProxies, setFilteredAvailableVboProxies] = useState<CompanyVboProxy[]>([]);
    const [validationState] = useState(new ValidationState());

    const formApi = useExternalFormApi<VboRepository>();

    useEffect(() => {
        Promise.all([
            wizardStore.availableVboProxiesCache.load({ companyId: props.companyId }),
            wizardStore.availableVboBackupRepositoryCache.load({ companyId: props.companyId }),
        ])
            .then(([proxies, availableRepositories]) => {
                setFilteredAvailableVboProxies(proxies
                    .filter(proxy => props.vboServers.find(server => server.vboServerUid === proxy.vboServerInstanceUid))
                    .sort((a, b) => a.name.localeCompare(b.name, 'kn', { numeric: true, sensitivity: 'base' }))
                );

                if (data.repositoryUid) {
                    const currentAvailableProxy = getVboProxy(data.repositoryUid, proxies, availableRepositories);
                    formApi.setValue('proxyId', currentAvailableProxy.instanceUid);

                    const currentAvailableRepository = availableRepositories
                        .find(availableRepository => availableRepository.instanceUid === data.repositoryUid);
                    formApi.setValue('storageType', currentAvailableRepository.storageType);
                }
            });
    }, []);

    return (
        <Dialog
            header={`${isEdit ? lang.EDIT : lang.ADD} ${lang.REPOSITORY}`}
            actions={[
                {
                    text: lang.OK,
                    onClick: async() => {
                        if (formApi.validate()) {
                            props.onConfirm && props.onConfirm(formApi.value);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={
                wizardStore.availableVboProxiesCache.loading
                || wizardStore.availableVboBackupRepositoryCache.loading
            }
        >
            <Form <VboRepository>
                value={data}
                validate={data => formValidate(data, lang, isEdit)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'proxyId'}
                        label={lang.BACKUP_PROXY}
                        onChange={(value) => {
                            formApi.setValue('storageType', null);
                            formApi.setValue('repositoryUid', null);
                        }}
                        placeholder={`${lang.SELECT_A_PROXY_SERVER}...`}
                        data={filteredAvailableVboProxies}
                        valueGetter={item => item.instanceUid}
                        textGetter={item => item.name}
                        size={defaultFieldWidth}
                        controlRenderer={createSearchableControl()}
                    />

                    <Combobox
                        name={'storageType'}
                        label={lang.REPOSITORY_TYPE}
                        onChange={() => {
                            formApi.setValue('repositoryUid', null);
                        }}
                        placeholder={`${lang.SELECT_A_REPOSITORY_TYPE}...`}
                        data={vb365RepositoryStorageTypes}
                        valueGetter={item => item.id}
                        textGetter={item => item.name}
                        size={defaultFieldWidth}
                        disabled={!data.proxyId}
                    />

                    <Combobox
                        name={'repositoryUid'}
                        label={lang.BACKUP_REPOSITORY}
                        placeholder={`${lang.SELECT_A_BACKUP_REPOSITORY}...`}
                        data={filteredAvailableRepositories}
                        valueGetter={item => item.instanceUid}
                        textGetter={item => item.name}
                        size={defaultFieldWidth}
                        onChange={(value, itemIndex) => {
                            const item = filteredAvailableRepositories[itemIndex];
                            formApi.setValue('repositoryFriendlyName', item.name);
                            formApi.setValue('vboServerUid', item.vboServerInstanceUid);
                        }}
                        disabled={!data.proxyId || !data.storageType}
                        controlRenderer={createSearchableControl()}
                    />

                    <NoLabelGroup
                        disabled={data.storageType === CompanyVboRepositoryStorageType.ArchiveObjectStorage}
                        content={(
                            <Checkbox
                                name={'isUserQuotaUnlimited'}
                                disabled={data.storageType === CompanyVboRepositoryStorageType.ArchiveObjectStorage}
                            >
                                {lang.SET_UNLIMITED_QUOTA}
                            </Checkbox>
                        )}
                        subContent={(
                            <FormLayout inlineLabel disabled={data.isUserQuotaUnlimited}>
                                <NumberInput
                                    name={'userQuota'}
                                    label={lang.USERS}
                                    minValue={MIN_USER_QUOTA}
                                    maxValue={MAX_USER_QUOTA}
                                    size={CONTROL_SIZE.xs}
                                />
                            </FormLayout>
                        )}
                    />

                    <NoteBar status={NOTEBAR_STATUS.info}>
                        <Text>{lang.SETTING_LIMIT_FOR_USERS}</Text>
                    </NoteBar>
                </FormLayout>
            </Form>
        </Dialog>
    );
});
