/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import {
    SPACE_FILL,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
} from '@veeam-vspc/components';

import { VeeamAgents } from '../VeeamAgents';
import { VeeamOne } from '../VeeamOne';

export const VeeamOneAndAgents: React.FC = () => (
    <StackView
        direction={STACK_DIRECTION.column}
        gap={STACK_GAP.s}
        className='fb-50'
        spaceFill={SPACE_FILL.all}
    >
        <VeeamAgents />
        <VeeamOne />
    </StackView>
);
