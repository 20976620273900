/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ERdsBackupTypes {
    Snapshot = 0,
    RemoteSnapshot = 1,
    Backup = 2,
    Archive = 3,
}
