/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/core';
import dayjs, { Dayjs } from 'dayjs';

import type { JobHeatMapOverviewStatus } from '@veeam-vspc/models/web-controllers/jobHeatMapOverviewStatus';

import { Period } from '../enums';
import { MAX_HEAT_MAP_DAYS } from '../const';

import type { DayModelResult, JobsOverviewStoreAbstract } from '../interfaces';

export const insertPlaceholderDates = (days: JobHeatMapOverviewStatus[], store: JobsOverviewStoreAbstract): DayModelResult[] => {
    const startDatesPlaceholders: DayModelResult[] = [];
    const middleDatesPlaceholders: DayModelResult[] = [];
    const endDatesPlaceholders: DayModelResult[] = [];
    const getDateString = (date: Dayjs) => date.add(date.utcOffset(), 'minute').toISOString();
    const copiedDays: JobHeatMapOverviewStatus[] = deepCopy(days);
    const { beginDate, endDate, selectedPeriod } = store;

    // 1. make necessary day stubs at the beginning (out of period, gray zone)
    let copiedBeginDate = beginDate.clone();
    for (let i = copiedBeginDate.day() - 1; i >= 0; i -= 1) {
        copiedBeginDate = copiedBeginDate.subtract(1, 'day');

        startDatesPlaceholders.unshift({
            date: getDateString(copiedBeginDate),
            outOfPeriod: true,
            companies: [],
            failJobsCount: undefined,
            successJobsCount: undefined,
            warningJobsCount: undefined,
        });
    }

    // 2. make necessary day stubs in the middle, then they will be replaced by the real data from Backend
    for (let i = dayjs(beginDate); !i.isAfter(endDate); i = i.add(1, 'day')) {
        middleDatesPlaceholders.push({
            date: getDateString(i),
            outOfPeriod: false,
            companies: [],
            failJobsCount: undefined,
            successJobsCount: undefined,
            warningJobsCount: undefined,
        });
    }

    // 3. make necessary day stubs in the end (out of period, gray zone)
    const daysCount = endDate.diff(beginDate, 'days') + 1;
    let copiedEndTime = endDate.clone();
    for (let i = 1; i <= (MAX_HEAT_MAP_DAYS - startDatesPlaceholders.length - daysCount); i += 1) {
        copiedEndTime = copiedEndTime.add(1, 'day');

        endDatesPlaceholders.push({
            date: getDateString(copiedEndTime),
            outOfPeriod: !(selectedPeriod === Period.CurrentMonth && endDate.month() === copiedEndTime.month()),
            companies: [],
            failJobsCount: undefined,
            successJobsCount: undefined,
            warningJobsCount: undefined,
        });
    }

    middleDatesPlaceholders.forEach((placeholder, index) => {
        const result = copiedDays.find(item => dayjs(item.date).isSame(dayjs(placeholder.date), 'day')); // if we find the match

        if (result) {
            middleDatesPlaceholders[index] = result; // replace a placeholder by the real data
        }
    });

    const result = [...startDatesPlaceholders, ...middleDatesPlaceholders, ...endDatesPlaceholders];

    return result;
};
