/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { Login } from './components';

export const LoginPage: React.FC<{}> = () => (
    <Login />
);
