/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Vb365JobItemComposed } from '@veeam-vspc/models/rest';

import type { JobWizardStore } from '../../../stores';

export function resourceValueGetter(item: Vb365JobItemComposed, property: string, store: JobWizardStore) {
    switch (property) {
        case 'itemType': {
            if (item.itemType === Vb365JobItemComposed.ItemTypeEnum.User) {
                return item.user.userType;
            }

            if (item.itemType === Vb365JobItemComposed.ItemTypeEnum.Group) {
                return item.group.groupType;
            }

            return item.itemType;
        }

        case 'name': {
            if (item.itemType === Vb365JobItemComposed.ItemTypeEnum.User) {
                return item.user.displayName;
            }

            if (item.itemType === Vb365JobItemComposed.ItemTypeEnum.Group) {
                return item.group.displayName;
            }

            if (item.itemType === Vb365JobItemComposed.ItemTypeEnum.Site) {
                return item.site.title;
            }

            if (item.itemType === Vb365JobItemComposed.ItemTypeEnum.Team) {
                return item.team.displayName;
            }

            if (item.hasOwnProperty('name')) {
                return (item as Vb365JobItemComposed & { name: string; }).name;
            }

            break;
        }

        case 'options': {
            const options = store.getProcessingOptions(item);
            const enabledOptions = options.filter(option => item[option] === true);

            if (options.length === enabledOptions.length) {
                return 'all';
            }

            return enabledOptions.join('/');
        }

        default: {
            return String(item[property]);
        }
    }
}
