/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SizeColumn } from '@veeam-vspc/components/src/Grid/columns/SizeColumn';
import { ACTION_VIEW, SortingMode, TextColumn } from '@veeam-vspc/components';
import { capitalize, FileSizeUnitsInBytes } from '@veeam-vspc/core';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { GridStore, WizardStepData } from '@veeam-vspc/components';
import type { ResellerCloudBackupResourceMapModel } from '@veeam-vspc/models/web-controllers';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { ActionsToolbar } from './components/ActionsToolbar';
import { sortFreeSpaceFn, sortQuotaFn } from '../../helpers';

import type { ResellerData } from '../../../../interfaces';

export interface BackupResourcesProps extends WizardStepData<ResellerData> {
    hidePanel: () => void;
}

export const BackupResources = observer((props: BackupResourcesProps) => {
    const lang = useLang();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<ResellerCloudBackupResourceMapModel, void, void>>();

    const [items] = useState([...data.cloudBackupResources]);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={capitalize(lang.BACKUP_RESOURCES)}
            description={lang.SELECT_BACKUP_REPOSITORY_AND_ASSIGN}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.cloudBackupResources = api.current.pageData;

                        onDataChange(data);

                        hidePanel();
                    },
                    // disabled: quotas?.length === 0,
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid <ResellerCloudBackupResourceMapModel, void, void>
                defaultColumnWidth={100}
                columns={[
                    { field: 'cloudConnectResourceName', title: lang.BACKUP_REPOSITORY },
                    { field: 'cloudRepositoryFriendlyName', title: lang.CLOUD_REPOSITORY },
                    {
                        field: 'quota.storageGb',
                        title: lang.QUOTA,
                        sorterFn: sortQuotaFn('storageGb'),
                        cell: ({ rowData }) => (
                            rowData.quota.isStorageQuotaUnlimited
                                ? <TextColumn>{lang.UNLIMITED}</TextColumn>
                                : <SizeColumn value={rowData.quota.storageGb} startUnit={'G'} />
                        ),
                    },
                    {
                        title: lang.FREE_SPACE,
                        cell: ({ rowData }) => {
                            if (rowData.quota.isStorageQuotaUnlimited) {
                                return <TextColumn>{lang.UNLIMITED}</TextColumn>;
                            }
                            const freeSpaceB = rowData.quota.storageGb * FileSizeUnitsInBytes.GB - rowData.usedSpace;
                            if (freeSpaceB <= 0) {
                                return <TextColumn>{0}</TextColumn>;
                            }
                            return <SizeColumn value={freeSpaceB} pointFixed={2} />;
                        },
                        sorterFn: sortFreeSpaceFn,
                    },
                    {
                        field: 'usedSpace',
                        title: lang.USED_SPACE,
                        cell: ({ rowData }) => <SizeColumn value={rowData.usedSpace} pointFixed={2} />,
                    },
                ]}
                data={() => Promise.resolve({
                    data: items,
                    meta: {
                        pagingInfo: {
                            total: items?.length,
                        },
                    },
                } as BaseSuccessRequestResponse<ResellerCloudBackupResourceMapModel[]>)}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'cloudRepositoryFriendlyName',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => (
                        <ActionsToolbar
                            items={items}
                            cloudConnectAgentUids={data.cloudConnectAgents.map(agent => agent.cloudConnectAgentUid)}
                        />
                    ),
                ]}
                api={api}
                sortingMode={SortingMode.Local}
            />
        </SidePanelForm>
    );
});
