/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Vb365JobItemComposed } from '@veeam-vspc/models/rest';

import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const resourceTypes = (lang: LangsServiceBase): Array<{ text: string; value: Vb365JobItemComposed.ItemTypeEnum; }> => [
    {
        text: lang.USER,
        value: Vb365JobItemComposed.ItemTypeEnum.User,
    },
    {
        text: lang.GROUP,
        value: Vb365JobItemComposed.ItemTypeEnum.Group,
    },
    {
        text: lang.SITE,
        value: Vb365JobItemComposed.ItemTypeEnum.Site,
    },
    {
        text: lang.TEAMS,
        value: Vb365JobItemComposed.ItemTypeEnum.Team,
    },
    {
        text: lang.PERSONAL_SITES,
        value: Vb365JobItemComposed.ItemTypeEnum.PersonalSites,
    },
    {
        text: lang.CURRENT_ORGANIZATION,
        value: Vb365JobItemComposed.ItemTypeEnum.PartialOrganization,
    },
];
