/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { NumberInput } from '@veeam-vspc/components';

import type { NumberInputProps } from '@veeam-vspc/components/src/form/components';

export const PerMonthField = (props: NumberInputProps) => (
    <NumberInput
        allowDecimal
        precision={2}
        minValue={0}
        size={props.size}
        {...props}
    />
);
