/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';
import { capitalize, deepCopy } from '@veeam-vspc/core';

import type { ResellerCloudWanResourceMapModel } from '@veeam-vspc/models/web-controllers';

import { useLang } from 'views/providers/LangProvider/hooks';
import { useResellerWizardStore } from '../../../../../../hooks';

import type { WanAccelerator } from 'core/interfaces';

type DialogDataType = ResellerCloudWanResourceMapModel;

interface Props {
    deactivate: () => void;
    onConfirm: (data: DialogDataType) => void;

    data?: DialogDataType;
    existingItems: DialogDataType[];
    cloudConnectAgentUids: string[];
}

const formValidate = (data: DialogDataType) => {
    const validator = new FormValidator(data);

    validator.validate('cloudConnectResourceUid')
        .string()
        .required();

    return validator.result();
};

const getDefaultData = (): DialogDataType => ({
    cloudConnectResourceName: '',
    cloudConnectResourceUid: null,
    cloudConnectAgentUid: null,
});

export const WanServerDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;

    const isEdit = !!props.data;
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.cloudConnectResourceUid === props.data.cloudConnectResourceUid), 1);
        }
        return result;
    });
    const [filteredWanAccelerators, setFilteredWanAccelerators] = useState<WanAccelerator[]>([]);
    const [data, setData] = useState(isEdit ? deepCopy(props.data) : getDefaultData());

    const formApi = useExternalFormApi<DialogDataType>();
    const [validationState] = useState(new ValidationState());

    const wizardStore = useResellerWizardStore();

    useEffect(() => {
        wizardStore.wanAcceleratorsCache.load({ cloudConnectAgentUids: props.cloudConnectAgentUids })
            .then((items) => {
                setFilteredWanAccelerators(items.filter(item => !existingItems
                    .find(existingItem => existingItem.cloudConnectResourceUid === item.cloudConnectResourceUid)
                ));
            });
    }, []);

    return (
        <Dialog
            header={capitalize(lang.WAN_ACCELERATION_SERVER)}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            props.onConfirm && props.onConfirm(data);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={wizardStore.wanAcceleratorsCache.loading}
        >
            <Form <DialogDataType>
                value={data}
                validate={data => formValidate(data)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'cloudConnectResourceUid'}
                        label={lang.WAN_ACCELERATION_SERVER}
                        data={filteredWanAccelerators}
                        placeholder={`${lang.SELECT_A_SERVER_FOR_WAN_ACCELERATION}...`}
                        valueGetter={item => item.cloudConnectResourceUid}
                        textGetter={item => item.cloudConnectResourceName}
                        size={defaultFieldWidth}
                        disabled={data.companyMap?.length > 0}
                        onChange={(value, itemIndex) => {
                            const item = filteredWanAccelerators[itemIndex];
                            formApi.setValue('cloudConnectResourceName', item.cloudConnectResourceName);
                            formApi.setValue('cloudConnectAgentUid', item.cloudConnectAgentUid);
                        }}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
});
