/**
 * Copyright © Veeam Software Group GmbH.
 */

import { computed, observable } from 'mobx';
import { deepCopy } from '@veeam-vspc/core';

import type {
    AvailableResourcesWanAccelerator,
    CloudConnectServerWithDeployInfo,
    CompanyBandwidthLimitModel,
    ResellerModel,
    SubscriptionPlanForAssignParam,
} from '@veeam-vspc/models/web-controllers';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core/services/transport/interfaces/base-success-request-response';

import { AvailableDataTransferOutQuota } from '../interfaces';
import { CloudConnectService } from 'core/services/cloud-connect-service/cloud-connect-service';
import { CompanyService } from 'core/services/company-service';
import { DataCache } from 'core/utils/data-cache';
import { EAgentCommonStatuses } from 'core/enums/e-agent-common-statuses';
import { UserTitleRepresentation } from 'core/enums';
import { core } from 'core/core-module';
import { getData } from 'core/services/transport/utils/get-data';
import { isSiteDisabled } from '../utils';

import type {
    CountryModel,
    UsaStateModel,
    AvailableBackupResource,
    CompanyVboBackupRepository,
    CompanyVboProxy,
    CompanyVboServer,
    EmailCustomSectionData,
    IsExistsNameOrAlias,
    IsExistsNameOrAliasParams,
    ReplicaResource,
    UserTitleModel,
    WanAccelerator,
} from 'core/interfaces';
import type { CompanyVcdNetworkExtension, AvailableDataCenterResource, CompanyData } from '../interfaces';
import type { SubscriptionPlanModel } from 'core/interfaces/subscription-plan-model';
import type { VcdOrganizationsData } from '../components/VcdUserInfo/interfaces';

export class CompanyWizardStore {
    readonly data: CompanyData;
    readonly initialData: CompanyData;
    readonly isEdit: boolean;
    @observable isRestAvailable = false;
    @observable emailData: EmailCustomSectionData | null = null;
    private initialEmailData: EmailCustomSectionData | null;
    @observable isRequiredSendEmail = true;
    @observable changePassword = false;
    @observable dataTransferGbEnabled = false;
    @observable availableDataTransferOutQuota: AvailableDataTransferOutQuota = null;
    readonly userTitles: UserTitleModel[];

    readonly smtpIsConfiguredCache = new DataCache<boolean>(() =>
        getData<void, { success: boolean; }>('Company/CanSendEmail', undefined, { notShowDefaultError: true })
            .then(({ success }) => success)
    );

    readonly availableDataCenterResourcesCache = new DataCache<AvailableDataCenterResource[],
        { cloudConnectAgentUid: string; organizationUid: string; }>(({ cloudConnectAgentUid, organizationUid }) =>
            core.transportService.request('Company/GetVcdDataCenters', { cloudConnectAgentUids: [cloudConnectAgentUid], organizationUid })
                .then((resp: BaseSuccessRequestResponse<AvailableDataCenterResource[]>) => resp.data)
        );

    readonly availableBackupResourcesCache = new DataCache<AvailableBackupResource[],
        { cloudConnectAgentUid?: string; companyId?: number; }>(({ cloudConnectAgentUid, companyId }) =>
            core.transportService.request('Company/GetAvailableBackupResource', {
                cloudConnectAgentUids: cloudConnectAgentUid ? [cloudConnectAgentUid] : null,
                companyId,
            })
                .then((resp: BaseSuccessRequestResponse<{ data: AvailableBackupResource[]; }>) => resp.data.data)
        );

    readonly availableReplicaResourcesCache = new DataCache<ReplicaResource[],
        { cloudConnectAgentUid?: string; }>(({ cloudConnectAgentUid }) =>
            core.transportService.request('Company/GetAvailableReplicationResource', {
                cloudConnectAgentUids: cloudConnectAgentUid ? [cloudConnectAgentUid] : null,
            })
                .then((resp: BaseSuccessRequestResponse<{ data: ReplicaResource[]; }>) => resp.data.data)
        );

    readonly availableVboServersCache = new DataCache<CompanyVboServer[], { companyId?: number; }>(({ companyId }) =>
        getData('Company/GetAvailableVboServers', { companyId })
    );

    readonly availableVboBackupRepositoryCache = new DataCache<CompanyVboBackupRepository[], { companyId?: number; }>(({ companyId }) =>
        getData('Company/GetAvailableVboBackupRepositories', { companyId })
    );

    readonly availableVboProxiesCache = new DataCache<CompanyVboProxy[], { companyId?: number; }>(({ companyId }) =>
        getData('Company/GetAvailableVboProxies', { companyId })
    );

    readonly isExistsNameOrAliasCache = new DataCache<IsExistsNameOrAlias, IsExistsNameOrAliasParams>(params =>
        CompanyService.isExistsNameOrAlias(params)
    );

    readonly subscriptionPlansCache = new DataCache<SubscriptionPlanModel[], SubscriptionPlanForAssignParam>((data = {}) =>
        core.transportService.request('SubscriptionPlan/GetPlansForAssignTo', data)
            .then((resp: BaseSuccessRequestResponse<SubscriptionPlanModel[]>) => resp.data)
    );

    readonly vcdOrganizationsDataItemsCache = new DataCache<VcdOrganizationsData[], {
        cloudConnectAgentUid: string;
        vcdOrganizationUid: string;
    }>(({ cloudConnectAgentUid, vcdOrganizationUid }) =>
        core.transportService.request('Company/GetVcdOrganizations', { cloudConnectAgentUid, vcdOrganizationUid })
            .then((resp: BaseSuccessRequestResponse<VcdOrganizationsData[]>) => resp.data)
    );

    readonly wanAcceleratorsCache = new DataCache<WanAccelerator[], { cloudConnectAgentUid: string; }>(({ cloudConnectAgentUid }) =>
        core.transportService.request('Company/GetWanAccelerators', { cloudConnectAgentUids: cloudConnectAgentUid ? [cloudConnectAgentUid] : null })
            .then((resp: BaseSuccessRequestResponse<AvailableResourcesWanAccelerator>) => resp.data.data)
    );

    readonly vcdNetworkExtensionsCache = new DataCache<CompanyVcdNetworkExtension[], {
        agentUid: string;
        companyId?: number;
        vdcUids: string[];
    }>(params =>
        core.transportService.request<typeof params, CompanyVcdNetworkExtension[]>('Company/GetVcdNetworkExtensions', params)
            .then((resp: BaseSuccessRequestResponse<CompanyVcdNetworkExtension[]>) => resp.data)
    );

    readonly companyBandwidthLimitCache = new DataCache<CompanyBandwidthLimitModel, { companyId?: number; }>(({ companyId }) =>
        getData('Company/GetAvailableBandwithLimit', { companyId })
    );

    readonly cloudConnectAgentsCache = new DataCache<CloudConnectServerWithDeployInfo[], {}>(
        () => {
            if (isSiteDisabled(this.isEdit, this.data.companyStatus)) { // see #215166
                return Promise.resolve<CloudConnectServerWithDeployInfo[]>([{
                    agentGuid: this.data.cloudConnectAgentUid,
                    siteName: this.data.siteInfrastructure.siteName,
                    serverVersion: this.data.siteInfrastructure.version,
                    agentStatus: EAgentCommonStatuses.NotSet,
                    agentStatusName: '',
                    lastContactTime: null,
                }]);
            }

            return CloudConnectService.getAgents();
        }
    );

    readonly resellerCache = new DataCache<ResellerModel, { resellerId?: number; }>(({ resellerId }) =>
        getData('Reseller/GetReseller', { id: resellerId })
    );

    readonly countriesCache = new DataCache<CountryModel[], {}>(() =>
        getData('Company/GetCountries')
    );

    readonly usaStatesCache = new DataCache<UsaStateModel[], {}>(() =>
        getData('Company/GetUsaStates')
    );

    constructor(data: CompanyData, isEdit: boolean) {
        this.isEdit = isEdit;
        this.data = data;
        this.initialData = deepCopy(data);

        const enumMapper = core.enumMapperService;
        this.userTitles = [
            {
                id: UserTitleRepresentation.Mr,
                name: enumMapper.getEnumDescription('userTitleRepresentation', UserTitleRepresentation.Mr),
            }, {
                id: UserTitleRepresentation.Miss,
                name: enumMapper.getEnumDescription('userTitleRepresentation', UserTitleRepresentation.Miss),
            }, {
                id: UserTitleRepresentation.Mrs,
                name: enumMapper.getEnumDescription('userTitleRepresentation', UserTitleRepresentation.Mrs),
            }, {
                id: UserTitleRepresentation.Ms,
                name: enumMapper.getEnumDescription('userTitleRepresentation', UserTitleRepresentation.Ms),
            },
        ];
    }

    @computed
    get isWanAcceleratorsEnabled(): boolean {
        return !!this.wanAcceleratorsCache.value && this.wanAcceleratorsCache.value.length > 0;
    }

    checkIsRestAvailable(companyId?: number): void {
        core.transportService.request('Company/IsRestAvailable', { companyId })
            .then((resp: BaseSuccessRequestResponse<boolean>) => this.isRestAvailable = resp.data as boolean);
    }

    loadEmailData(organizationUid: string): void {
        if (this.emailData === null) {
            getData<{ organizationUid: string; }, EmailCustomSectionData>
            ('Company/GetWelcomeEmailCustomSection', { organizationUid })
                .then((data) => {
                    const addCustomText = !!data.emailContent;
                    this.emailData = { addCustomText, ...data, isDefault: false };
                    this.initialEmailData = deepCopy(this.emailData);
                });
        }
    }

    isEmailDataChanged(): boolean {
        return this.emailData !== null
            && (
                this.emailData.emailContent !== this.initialEmailData.emailContent
                || (this.initialEmailData.emailContent && !this.emailData.addCustomText)
                || (!this.initialEmailData.emailContent && this.emailData.addCustomText)
                || this.emailData.isDefault !== this.initialEmailData.isDefault
                || this.emailData.isVisibleGeneralSection !== this.initialEmailData.isVisibleGeneralSection
            );
    }

    loadAvailableDataTransferOutQuota(organizationUid: string): Promise<AvailableDataTransferOutQuota> {
        if (this.availableDataTransferOutQuota === null) {
            return getData<{ companyUid: string; }, AvailableDataTransferOutQuota>
            ('Company/GetAvailableDataTransferOutQuota', { companyUid: organizationUid })
                .then((data) => {
                    this.availableDataTransferOutQuota = data;
                    return data;
                });
        }

        return Promise.resolve(this.availableDataTransferOutQuota);
    }
}
