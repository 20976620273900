/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { LinkButton, TextColumn } from '@veeam-vspc/components';

import { getStatusIconSrc } from 'core/utils';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { EJobStatuses } from '../../../../../../enums';

export interface SuccessfulJobsColumnProps {
    onClick: () => void;
    successJobsCount: number;
    totalJobsCount: number;
    jobsStatus: EJobStatuses;
}

export const SuccessfulJobsColumn: React.FC<SuccessfulJobsColumnProps> = ({ onClick, successJobsCount = 0, totalJobsCount = 0, jobsStatus }) => {
    const lang = useLang();

    return (
        <TextColumn>
            <LinkButton
                onClick={onClick}
                iconBefore={getStatusIconSrc(jobsStatus)}
            >
                {`${successJobsCount} ${lang.OF} ${totalJobsCount}`}
            </LinkButton>
        </TextColumn>
    );
};
