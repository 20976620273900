/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    disableForSelectionWithPropValueCallback,
    disableForSelectionWithPropValues,
    processDecoratorsForDisabledByPriority,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
} from '@veeam-vspc/components';
import { JobStateRepresentation } from '@veeam-vspc/models/web-controllers';

import type { VbrAgentBackupJobModel, VbrBackupAgentJobContainerFilter } from '@veeam-vspc/models/web-controllers';

import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { useLang } from 'views/providers/LangProvider/hooks';
import startActionIconSrc from 'images/actions/start.svg';
import stopActionIconSrc from 'images/actions/stop.svg';
import enableActionIconSrc from 'images/actions/enable.png';
import disableActionIconSrc from 'images/actions/disable.svg';
import { useAsyncAction } from 'views/hooks';
import { ConfigSectionIds } from 'core/services/configurator/enums';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';

export interface ManagedByBackupServerActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items'> {
    method?: string;
}

export const ManagedByBackupServerActionToolbar: React.FC<ManagedByBackupServerActionToolbarProps> = (props) => {
    const { method = 'GetVBRAgentJobContainers', ...restProps } = props;
    const lang = useLang();
    const gridApi = useGridApi<VbrAgentBackupJobModel, unknown, VbrBackupAgentJobContainerFilter>();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSelection(),
    ];

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                method={method}
                sectionId={ConfigSectionIds.BackupJobsComputersManagedByBackupServer}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: startActionIconSrc,
                        text: lang.START,
                        onClick: () => doAsyncAction('/BackupServerAgentManagement/StartJob', lang.START_JOB, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('status', [
                                    JobStateRepresentation.Running,
                                    JobStateRepresentation.Starting,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopActionIconSrc,
                        text: lang.STOP,
                        onClick: () => doAsyncAction('/BackupServerAgentManagement/StopJob', lang.STOP_JOB, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('status', [
                                    JobStateRepresentation.Failed,
                                    JobStateRepresentation.Success,
                                    JobStateRepresentation.Warning,
                                    JobStateRepresentation.Starting,
                                    JobStateRepresentation.Disabling,
                                    JobStateRepresentation.Enabling,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: enableActionIconSrc,
                        text: lang.ENABLE,
                        onClick: () => doAsyncAction('/BackupServerAgentManagement/EnableJob', lang.ENABLE_JOB, {
                            ...gridApi.requestParamsForActions,
                            enable: true,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('scheduleEnabled', value => value === true),
                                disableForSelectionWithPropValues('status', [
                                    JobStateRepresentation.Enabling,
                                    JobStateRepresentation.Disabling,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: disableActionIconSrc,
                        text: lang.DISABLE,
                        onClick: () => doAsyncAction('/BackupServerAgentManagement/EnableJob', lang.DISABLE_JOB, {
                            ...gridApi.requestParamsForActions,
                            enable: false,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('scheduleEnabled', value => Boolean(value) === false),
                                disableForSelectionWithPropValues('status', [
                                    JobStateRepresentation.Enabling,
                                    JobStateRepresentation.Disabling,
                                ]),
                            ]),
                        ],
                    },
                ]}
            />

            {asyncActionLoader}
        </>
    );
};
