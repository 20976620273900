/**
 * Copyright © Veeam Software Group GmbH.
 */

import { baseConfig } from './base.config';
import { PortalUserRoles } from '../../../entries/portal-user/enums';
import { ConfigSectionIds, ConfigStates } from '../enums';

import type { BaseUserConfig } from '../interfaces';

export const companyInvoiceAuditorConfig: BaseUserConfig = {
    baseConfig: baseConfig,
    roleInt: PortalUserRoles.CompanyInvoiceAuditor,
    roleName: PortalUserRoles[PortalUserRoles.CompanyInvoiceAuditor] as keyof typeof PortalUserRoles,
    routes: [],
    sections: [
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByBackupServer,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
    ],
};
