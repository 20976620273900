/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as Highcharts from 'highcharts';
import highchartsMap from 'highcharts/modules/map';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';

export class HighchartsService {
    constructor() {
        this.initOverrides();

        this.initModules();
    }

    private initOverrides(): void {
        // Generic plugin that adds support for listening to container resize rather than window resize
        Highcharts.wrap(Highcharts.Chart.prototype, 'setReflow', function(proceed, reflow) {
            proceed.call(this, reflow);

            if (reflow !== false && typeof ResizeObserver === 'function') {
                // unbind window.onresize handler so we don't do double redraws
                if (this.unbindReflow) {
                    this.unbindReflow();
                }

                const ro = new ResizeObserver(() => {
                    if (this.options?.chart) {
                        this.reflow();
                    }
                });

                ro.observe(this.renderTo);
            }
        });

        // fix drawing of legend symbol for area chart as in linear
        Highcharts.seriesTypes.area.prototype['drawLegendSymbol'] = Highcharts.seriesTypes.line.prototype['drawLegendSymbol'];
    }

    private initModules(): void {
        highchartsMap(Highcharts);
        exporting(Highcharts);
        offlineExporting(Highcharts);
    }


}
