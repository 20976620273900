/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, StackView, STACK_ALIGN, STACK_DIRECTION, STACK_GAP, TextColumn } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { getColumnContentParams } from './utils';

import type { EProductUpgradeStatus } from 'core/enums';

export interface UpdatesColumnProps {
    upgradeVersion: string;
    upgradeStatus: EProductUpgradeStatus;
}

export const UpdatesColumn: React.FC<UpdatesColumnProps> = (props) => {
    const lang = useLang();
    const { upgradeStatus, upgradeVersion } = props;
    const { iconSrc, columnText } = getColumnContentParams(lang, upgradeStatus, upgradeVersion);

    return (
        <StackView
            gap={STACK_GAP.s}
            align={STACK_ALIGN.center}
            direction={STACK_DIRECTION.row}
            size={{ width: '100%' }}
        >
            <Icon src={iconSrc} />

            <TextColumn>
                {columnText}
            </TextColumn>
        </StackView>
    );
};
