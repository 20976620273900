/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    isValid,
    FormLayout,
    Checkbox,
    NoLabelGroup,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { CdpParameters, cdpParametersValidate } from './components/CdpParameters';
import { ReportAggregationModeRepresentation, ReportType } from '../../enums';
import { VmParameters, vmParametersValidate } from './components/VmParameters';
import { ComputersParameters, computersParametersValidate } from './components/ComputersParameters';
import { FileSharesParameters, fileSharesParametersValidate } from './components/FileSharesParameters';
import { DatabasesParameters, databasesParametersValidate } from './components/DatabasesParameters';
import { Vb360Parameters, vb360ParametersValidate } from './components/Vb360Parameters';

import type { ReportModel } from '../../interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const stepValidate = (data: ReportModel, cdpErrorMessage: string) => {
    if (data.type === ReportType.ProtectedVms) {
        return vmParametersValidate(data);
    }

    if (data.type === ReportType.ProtectedVmsCdp) {
        return cdpParametersValidate(data, `${cdpErrorMessage}!immediately`);
    }

    if (data.type === ReportType.ProtectedComputers) {
        return computersParametersValidate(data);
    }

    if (data.type === ReportType.ProtectedFiles) {
        return fileSharesParametersValidate(data);
    }

    if (data.type === ReportType.ProtectedDatabases) {
        return databasesParametersValidate(data);
    }

    if (data.type === ReportType.ProtectedVboObjectsSla) {
        return vb360ParametersValidate(data, `${cdpErrorMessage}!immediately`);
    }

    return {};
};

export const getParametersStep = (lang: LangsServiceBase): WizardStep<ReportModel> => ({
    title: lang.PARAMETERS,
    validate: ({ data }) => isValid(v => stepValidate(v, lang.DASH_SYMBOL), data),
    render: data => <ParametersStep {...data} />,
});

const ParametersStep = ({ data, validationState, onDataChange }: WizardStepData<ReportModel>) => {
    const lang = useLang();

    return (
        <StepLayout
            title={capitalize(lang.PARAMETERS)}
            description={lang.SPECIFY_PARAMETERS_FOR_THIS_BACKUP_REPORT_MSG}
        >
            <Form
                value={data}
                validate={v => stepValidate(v, lang.END_DATE_MUST_BE_LATER)}
                validationState={validationState}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    {data.type === ReportType.ProtectedComputers && (
                        <ComputersParameters
                            data={data}
                            onDataChange={onDataChange}
                        />
                    )}

                    {data.type === ReportType.ProtectedVms && (
                        <VmParameters
                            data={data}
                            onDataChange={onDataChange}
                        />
                    )}

                    {data.type === ReportType.ProtectedFiles && (
                        <FileSharesParameters data={data} />
                    )}

                    {data.type === ReportType.ProtectedVmsCdp && (
                        <CdpParameters
                            data={data}
                            onDataChange={onDataChange}
                        />
                    )}

                    {(data.type === ReportType.ProtectedDatabases) && (
                        <DatabasesParameters
                            data={data}
                            onDataChange={onDataChange}
                        />
                    )}

                    {(data.type === ReportType.ProtectedVboObjectsSla) && (
                        <Vb360Parameters
                            data={data}
                            onDataChange={onDataChange}
                        />
                    )}

                    {data.parameters.aggregationMode === ReportAggregationModeRepresentation.MultiCompany && (
                        <NoLabelGroup
                            content={(
                                <Checkbox
                                    name={'parameters.includeCompaniesDetails'}
                                >
                                    {lang.INCLUDE_DETAILED_INFORMATION_TO}
                                </Checkbox>
                            )}
                        />
                    )}
                </FormLayout>

            </Form>
        </StepLayout>
    );
};
