/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TextColumn } from '@veeam-vspc/components';

import type { TextColumnProps } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { transformTimeToAgoString } from './utils/time-ago-helper';

export interface TimeAgoColumnProps extends Partial<Omit<TextColumnProps, 'value'>> {
    value: Date | string | null;
}

export const TimeAgoColumn = ({ value, ...restProps }: TimeAgoColumnProps) => {
    const lang = useLang();

    return (
        <TextColumn {...restProps}>
            {transformTimeToAgoString(value, lang)}
        </TextColumn>
    );
};
