/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { BasicFilter, Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE } from '@veeam-vspc/components';

import { EndpointBackupModes, JobTargetKinds, VawOperationModes } from 'core/enums';
import serverModeIconSrc from 'images/filters/agent-job-types/server.png';
import workstationModeIconSrc from 'images/filters/agent-job-types/workstation.png';
import localTargetTypeIconSrc from 'images/filters/fplan-types/local.png';
import cloudTargetTypeIconSrc from 'images/filters/fplan-types/cloud.png';
import entireComputerTypeIconSrc from 'images/filters/protected-computer/backup-os-level.png';
import volumeLevelTypeIconSrc from 'images/filters/protected-computer/backup-volume-level.png';
import fileLevelTypeIconSrc from 'images/filters/protected-computer/backup-file-level.png';
import { useLang } from 'views/providers/LangProvider/hooks';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { JobKinds } from 'views/pages/BackupJobsPage/enums';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';

export interface BackupJobsDialogToolbarProps extends Omit<ToolbarWithExportProps, 'items'> {
    jobKind: JobKinds;
}

export const BackupJobsDialogToolbar = (props: BackupJobsDialogToolbarProps) => {
    const { jobKind, ...restProps } = props;
    const lang = useLang();
    const items = [
        {
            type: TOOLBAR_ITEM_TYPE.customControl,
            render: () => (
                <Search
                    name='name'
                    placeholder={capitalize(lang.JOB_NAME)}
                    hasSearchButton={true}
                />
            ),
        },
        {
            type: TOOLBAR_ITEM_TYPE.customControl,
            render: () => (
                <BasicFilter
                    name={'operationMode'}
                    label={capitalize(lang.OPERATION_MODE, true)}
                    hasAllButton={true}
                    buttons={[
                        {
                            title: lang.SERVER,
                            value: `${VawOperationModes.Server}`,
                            icon: serverModeIconSrc,
                        },
                        {
                            title: lang.WORKSTATION,
                            value: `${VawOperationModes.Workstation}`,
                            icon: workstationModeIconSrc,
                        },
                    ]}
                />
            ),
        },
        {
            type: TOOLBAR_ITEM_TYPE.customControl,
            render: () => (
                <BasicFilter
                    name={'backupTargetType'}
                    label={lang.BACKUP_TARGET}
                    hasAllButton={true}
                    buttons={[
                        {
                            title: lang.LOCAL,
                            value: `${JobTargetKinds.Local}`,
                            icon: localTargetTypeIconSrc,
                        },
                        {
                            title: lang.OFFSITE,
                            value: `${JobTargetKinds.Cloud}`,
                            icon: cloudTargetTypeIconSrc,
                        },
                    ]}
                />
            ),
        },
        jobKind !== JobKinds.CopyJob && {
            type: TOOLBAR_ITEM_TYPE.customControl,
            render: () => (
                <BasicFilter
                    name={'backupType'}
                    label={lang.BACKUP_TYPE}
                    hasAllButton={true}
                    buttons={[
                        {
                            title: lang.ENTIRE_COMPUTER,
                            value: `${EndpointBackupModes.EntireComputer}`,
                            icon: entireComputerTypeIconSrc,
                        },
                        {
                            title: lang.VOLUME_LEVEL,
                            value: `${EndpointBackupModes.Volume}`,
                            icon: volumeLevelTypeIconSrc,
                        },
                        {
                            title: capitalize(lang.FILE_LEVEL, true),
                            value: `${EndpointBackupModes.Folder}`,
                            icon: fileLevelTypeIconSrc,
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <ToolbarWithExport
            {...restProps}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={items.filter(item => item)}
        />
    );
};
