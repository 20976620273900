/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useGridApi } from '@veeam-vspc/components';
import { transformRequestParams } from '@veeam-vspc/components/src/Grid/helpers';

import type { GridExportProps } from '../use-grid-export-params';
import type { GridRestExportParams } from './interfaces';

export const useGridRestExportParams = <RowDataT, TotalRowDataT, FilterT>(props: GridExportProps = {}): GridRestExportParams => {
    const {
        excludeFromExport = [],
        extraParams,
        selectedOnly = false,
        forceSelectedId = null,
        idProperty = 'id',
    } = props;
    const gridApi = useGridApi<RowDataT, TotalRowDataT, FilterT> ();
    const { columns, requestParams, selected, requestRESTParamsForActions } = gridApi;

    const selectedResult = forceSelectedId
        ? gridApi.pageData.filter(item => item[idProperty] === forceSelectedId)
        : selected;

    const exportParams: GridRestExportParams = {
        propertiesForExport: columns
            .filter(column => !excludeFromExport.includes(column.field))
            .map(column => `${column.title}:${column.field}`),
        ...(extraParams ?? transformRequestParams(requestParams)),
    };

    if (selectedOnly && selectedResult.length > 0) {
        const { onlyResourcesWithTheseUids, excludeResourcesWithTheseUids } = requestRESTParamsForActions;

        if (onlyResourcesWithTheseUids.length) {
            exportParams.onlyResourcesWithTheseUids = onlyResourcesWithTheseUids as string[];
        }

        if (excludeResourcesWithTheseUids.length) {
            exportParams.excludeResourcesWithTheseUids = excludeResourcesWithTheseUids as string[];
        }
    }

    return exportParams;
};
