/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { LinkButton, TextColumn } from '@veeam-vspc/components';

import type { TextColumnProps } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { getColumnIconAndText } from './utils';

import type { VawTemplateStatuses } from 'core/enums';

export interface BackupPolicyColumnProps {
    value?: string | null;
    commonVawTemplateStatus: VawTemplateStatuses;
    multipleJobs: boolean;
    onClick: () => void;
    defaultValue?: TextColumnProps['defaultValue'];
}

export const BackupPolicyColumn: React.FC<BackupPolicyColumnProps> = ({ value, commonVawTemplateStatus, multipleJobs, onClick, defaultValue }) => {
    const lang = useLang();
    const { icon, text } = getColumnIconAndText(lang, value, commonVawTemplateStatus, multipleJobs);

    return (
        <TextColumn defaultValue={defaultValue}>
            {value !== null && value !== undefined
                ? <LinkButton onClick={onClick} iconBefore={icon}>{text}</LinkButton>
                : null}
        </TextColumn>
    );
};
