/**
 * Copyright © Veeam Software Group GmbH.
 */

import { VawTemplateStatuses } from 'core/enums';
import assignedBackupAgentPolicyIconSrc from 'images/filters/backup-agent-policies/assigned.png';
import infoStatusIconSrc from 'images/statuses/info.svg';
import warningStatusIconSrc from 'images/statuses/warning.svg';
import notAssignedBackupAgentPolicyIconSrc from 'images/filters/backup-agent-policies/not-assigned.png';
import errorIconSrc from 'images/statuses/error.svg';

export const getColumnIconAndText = (
    lang: Record<string, string>,
    value: string | null,
    commonVawTemplateStatus: VawTemplateStatuses,
    multipleJobs: boolean
): { icon: string; text: string; } => {
    let icon = '';
    let text = '';

    switch (commonVawTemplateStatus) {
        case VawTemplateStatuses.Assigned:
            icon = assignedBackupAgentPolicyIconSrc;
            text = value;
            break;
        case VawTemplateStatuses.Custom:
            icon = infoStatusIconSrc;

            if (multipleJobs) {
                text = value;
                break;
            }

            text = !value ? lang.CUSTOM : `${lang.CUSTOM} [${value}]`;
            break;
        case VawTemplateStatuses.Outdated:
            icon = warningStatusIconSrc;
            text = `${lang.OUT_OF_DATE} [${value}]`;
            break;
        case VawTemplateStatuses.Applying:
            icon = infoStatusIconSrc;
            text = !value ? `${lang.APPLYING}...` : `${lang.APPLYING} [${value}]...`;
            break;
        case VawTemplateStatuses.FailedToApply:
            icon = errorIconSrc;

            if (multipleJobs) {
                text = value;
                break;
            }

            text = !value ? lang.FAILED_TO_APPLY : `${lang.FAILED_TO_APPLY} [${value}]`;
            break;
        case VawTemplateStatuses.Warning:
            icon = warningStatusIconSrc;

            if (multipleJobs) {
                text = value;
                break;
            }

            text = !value ? lang.WARNING : `${lang.WARNING} [${value}]`;
            break;
        case VawTemplateStatuses.Deleting:
            icon = infoStatusIconSrc;
            text = !value ? `${lang.DELETING}...` : `${lang.DELETING} [${value}]...`;
            break;
        case VawTemplateStatuses.Unassigned:
            icon = notAssignedBackupAgentPolicyIconSrc;
            text = lang.NOT_ASSIGNED;
            break;
        default:
            icon = infoStatusIconSrc;
            text = value;
            break;
    }

    return {
        icon,
        text,
    };
};
