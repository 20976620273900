/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { LicenseProductType } from '@veeam-vspc/models/web-controllers';
import {
    capitalize,
    plural,
    formatStr,
    htmlDecode,
} from '@veeam-vspc/core';

import { useAppStore } from 'views/providers/AppProvider/hooks';
import { core } from 'core/core-module';
import { useIntervalRequest } from 'views/hooks';
import updatesAvailable from 'images/updates-available.svg';
import infoIcon from 'images/info-light-gray.svg';
import updatesNotAvailableIcon from 'images/updates-no-data-available.svg';
import { useLang } from 'views/providers/LangProvider/hooks';
import successBold from 'images/success-status.svg';
import { TableCompactWidget } from '../widgets/TableCompactWidget';

import type { ProductUpdates as ProductUpdatesType } from '../../interfaces';

export const ProductUpdates: React.FC = () => {
    const { portalUser } = useAppStore();
    const lang = useLang();
    const request = useIntervalRequest<{}, ProductUpdatesType[]>('Overview/Updates');

    const hideCloudConnect = portalUser.isServiceProviderGroup() || portalUser.isReadOnlyRole() || portalUser.isPortalOperator();

    const data = (request.data as ProductUpdatesType[] ?? []).filter((x) => {
        if (hideCloudConnect) {
            return x.productType !== LicenseProductType.CloudConnectServer;
        }

        return true;
    });
    const { enumMapperService } = core;

    return (
        <TableCompactWidget
            loading={request.loading}
            noData={data?.every(item => item.overallCount === 0) || data?.length === 0}
            noDataConfig={{
                text: lang.INFORMATION_ABOUT_PRODUCT_UPDATES,
                icon: updatesNotAvailableIcon,
            }}
            items={data?.map((item) => {
                let status: string;
                let icon: string;
                let linkHandler: () => void;
                const noInfo = item.overallCount === 0;

                if (item.outdatedObjects > 0) {
                    let pluralText = plural(item.outdatedObjects, lang.SERVER.toLowerCase());
                    const statusMsg = item.outdatedObjects === 1 ? lang.ITEMS_COUNT_NEEDS_UPDATE : lang.ITEMS_COUNT_NEED_UPDATE;

                    icon = updatesAvailable;
                    status = formatStr(statusMsg, pluralText);

                    switch (item.productType) {
                        case LicenseProductType.CloudConnectServer:
                            linkHandler = () => window.location.assign('#config/cloudConnectServers'); break;

                        case LicenseProductType.VspcEndpoint:
                            pluralText = plural(item.outdatedObjects, lang.AGENT.toLowerCase());
                            status = formatStr(statusMsg, pluralText);
                            linkHandler = () => window.location.assign('#home/discovery/tab/backupAgents');
                            break;

                        case LicenseProductType.BackupServer:
                            linkHandler = () => window.location.assign('#home/discovery/tab/backupServers'); break;

                        case LicenseProductType.VOne:
                            linkHandler = () => window.location.assign('#home/discovery/tab/oneServers'); break;

                        case LicenseProductType.Vbo:
                            linkHandler = () => window.location.assign('#home/discovery/tab/vboServers'); break;

                        default: break;
                    }
                } else if (noInfo) {
                    icon = infoIcon;
                    status = lang.NO_INFO;
                } else {
                    icon = successBold;
                    status = lang.UP_TO_DATE;
                }

                return {
                    name: htmlDecode(enumMapperService.getEnumDescription('licenseProductType', item.productType)),
                    noInfo,
                    icon,
                    status: capitalize(status, true),
                    linkHandler,
                };
            })}
        />
    );
};
