/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Icon, STACK_DIRECTION, STACK_GAP, StackView, Text } from '@veeam-vspc/components';

import errorStatusIconSrc from 'images/error-status.svg';
import successStatusIconSrc from 'images/success-status.svg';
import warningStatusIconSrc from 'images/warning-status.svg';
import { CompaniesCount, Company, JobsCount, MoreCompanies, TooltipStyles } from './Tooltip.styled';
import { useJobsOverviewStore } from '../../store';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { TooltipProps } from '../../interfaces/tooltip-props';

const xAxisTooltipOffset = -115; // approved design

export const Tooltip: React.FC<TooltipProps> = ({ id }) => {
    const lang = useLang();
    const store = useJobsOverviewStore();
    const record = store.findById(id);
    const { affectedCompanies } = record;

    return (
        <ReactTooltip
            effect='float'
            className='customTheme'
            id={id}
            place='bottom'
            offset={{ left: xAxisTooltipOffset }}
            type='light'
            overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
                const { documentElement } = document;

                left = Math.min(documentElement.clientWidth - node.clientWidth, left);
                top = Math.min(documentElement.clientHeight - node.clientHeight, top);

                left = Math.max(0, left);
                top = Math.max(0, top);

                return { top, left };
            }}
        >
            <TooltipStyles />
            <div>{record.displayDateForTooltip}</div>
            <JobsCount>{`Job Sessions (${record.allJobsCount})`}</JobsCount>

            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                <Text>{`${lang.STATE}:`}</Text>

                <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.m}>
                    <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.xs}>
                        <Icon src={errorStatusIconSrc} />
                        <Text>{record.failJobsCount}</Text>
                    </StackView>

                    <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.xs}>
                        <Icon src={warningStatusIconSrc} />
                        <Text>{record.warningJobsCount}</Text>
                    </StackView>

                    <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.xs}>
                        <Icon src={successStatusIconSrc} />
                        <Text>{record.successJobsCount}</Text>
                    </StackView>
                </StackView>
            </StackView>

            {record.affectedCompanies.length > 0 && (
                <>
                    <CompaniesCount>
                        {`Affected Companies (${affectedCompanies.length}):`}
                    </CompaniesCount>

                    {affectedCompanies.splice(0, 10).map((company, index) => <Company key={index}>{company.name}</Company>)}

                    {affectedCompanies.length > 0 && (
                        <MoreCompanies>
                            {`${affectedCompanies.length} more...`}
                        </MoreCompanies>
                    )}
                </>
            )}
        </ReactTooltip>
    );
};
