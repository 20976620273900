/**
 * Copyright © Veeam Software Group GmbH.
 */

import { CompanyStatus } from '../enums/company-status';

const allowEditCC = (companyStatus: CompanyStatus): boolean => [
    CompanyStatus.Phantom,
    CompanyStatus.Creating,
    CompanyStatus.SiteResourceFailedOnCreating,
].includes(companyStatus);

export const isSiteDisabled = (isEdit: boolean, companyStatus: CompanyStatus): boolean => isEdit && !allowEditCC(companyStatus);
