/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { formatStr } from '@veeam-vspc/core';
import {
    Dialog,
    DIALOG_SIZE,
    GridColumnJustify,
    GridSortDirections,
    NoteBar,
    NOTEBAR_STATUS,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    ParsedText,
} from '@veeam-vspc/components';

import type { CellRendererProps } from '@veeam-vspc/components';
import type { BackupJobRestorePointModel, JobRestorePointParam } from '@veeam-vspc/models/web-controllers';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { getRestorePointsHelpResource } from 'core/utils';
import { useAppServices, useAppStore } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { RestorePointDialogToolbar } from '../RestorePointDialogToolbar/RestorePointDialogToolbar';
import { MAX_INTEGER_32 } from 'core/const';

export const RestorePointColumnsFields: Record<string, keyof BackupJobRestorePointModel> = {
    Date: 'date',
    SourceSize: 'sourceSizeKb',
    BackedUpData: 'backedUpDataKb',
    RestorePointSize: 'restorePointSizeKb',
};

export interface RestorePointDialogProps {
    stateId: string;
    name: string;
    extraParams: Record<string, string>;
    deactivate: () => void;
    noteBarIsHidden?: boolean;
    hiddenColumns?: (keyof typeof RestorePointColumnsFields)[] | [];
}

export const RestorePointDialog: React.FC<RestorePointDialogProps> = (
    {
        name,
        stateId,
        deactivate,
        extraParams,
        noteBarIsHidden = false,
        hiddenColumns = [],
    }) => {
    const lang = useLang();
    const { portalUser } = useAppStore();
    const { transportService } = useAppServices();

    return (
        <Dialog
            header={`${lang.RESTORE_POINTS} - ${name}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                {!noteBarIsHidden && (
                    <NoteBar status={NOTEBAR_STATUS.info} style={{ backgroundColor: RCOP.utils.Colors.Y50 }}>
                        <ParsedText textOrConfig={formatStr(lang.TO_SEE_BACKED_UP_DATA, getRestorePointsHelpResource(portalUser.userRole))} />
                    </NoteBar>
                )}

                <AdvancedGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Desc,
                            key: RestorePointColumnsFields.Date,
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={[
                        {
                            field: RestorePointColumnsFields.Date,
                            title: lang.DATE,
                            cell: ({ cellData }: CellRendererProps<BackupJobRestorePointModel>) => (
                                <GridDateColumn value={cellData} />
                            ),
                        },
                        {
                            field: RestorePointColumnsFields.SourceSize,
                            title: lang.SOURCE_SIZE,
                            cell: ({ rowData }: CellRendererProps<BackupJobRestorePointModel>) => (
                                <RestorePointSizeColumn value={(rowData[RestorePointColumnsFields.SourceSize] as number)} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: RestorePointColumnsFields.BackedUpData,
                            title: lang.BACKED_UP_DATA,
                            cell: ({ rowData }: CellRendererProps<BackupJobRestorePointModel>) => (
                                <RestorePointSizeColumn value={(rowData[RestorePointColumnsFields.BackedUpData] as number)} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: RestorePointColumnsFields.RestorePointSize,
                            title: lang.RESTORE_POINT_SIZE,
                            cell: ({ rowData }: CellRendererProps<BackupJobRestorePointModel>) => (
                                <RestorePointSizeColumn value={(rowData[RestorePointColumnsFields.RestorePointSize] as number)} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                    ].filter(column => !hiddenColumns.includes(column.field))}
                    data={(requestParams: JobRestorePointParam) => transportService
                        .request<typeof requestParams, BackupJobRestorePointModel[]>('/BackupServerJob/GetRestorePoints', {
                            ...requestParams,
                            ...extraParams,
                        })}
                    toggleable={false}
                    selection={{
                        field: 'instanceUid',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => <RestorePointDialogToolbar extraParams={extraParams} idProperty={'instanceUid'} />,
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
