/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';

import { TableCompactItem } from './components/TableCompactItem';
import { NoData } from './components/NoData';
import { Spinner } from 'views/components/Spinner';
import { TableCompactWidgetStyled } from './components/TableCompactWidgetStyled';

import type { TableCompactWidgetProps } from './interfaces';

export const TableCompactWidget: React.FC<TableCompactWidgetProps> = (props) => {
    let widgetContent: JSX.Element | JSX.Element[];

    if (props.noData) {
        widgetContent = <NoData {...props} />;
    } else {
        widgetContent = props.items?.map((itemProps, index) => <TableCompactItem {...itemProps} key={index} />);
    }

    return (
        <TableCompactWidgetStyled
            noData={props.noData}
            loading={props.loading}
        >
            {props.loading && <Spinner delayMs={300} size={50} />}
            {widgetContent}
        </TableCompactWidgetStyled>
    );
};
