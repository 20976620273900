/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { deepCopy } from '@veeam-vspc/core';
import {
    ACTION_VIEW, Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    TextInput,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';

import type { ResellerVboResourceMapModel } from '@veeam-vspc/models/web-controllers';

import { useLang } from 'views/providers/LangProvider/hooks';
import { useResellerWizardStore } from '../../../../../../hooks';
import { trimTextFields } from 'core/utils';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { ResellerVboResource } from '../../../../../../interfaces';
import type { CompanyVboServer } from 'core/interfaces/company-vbo-server';

interface Props {
    deactivate: () => void;
    onConfirm: (data: ResellerVboResource) => void;

    data?: ResellerVboResource;
    existingItems: ResellerVboResource[];
}

const formValidate = (data: ResellerVboResource, existingItems: ResellerVboResourceMapModel[], lang: LangsServiceBase) => {
    const validator = new FormValidator(data);

    validator.validate('vboInstanceUid')
        .string()
        .required();

    if (data.vboInstanceUid) {
        validator.validate('vboFriendlyName')
            .string()
            .required()
            .maxLength(256)
            .check((value => existingItems.find(item => item.vboFriendlyName === value.trim()) ? lang.THIS_FRIENDLY_NAME : ''));
    }

    return validator.result();
};

const getDefaultData = (): ResellerVboResource => ({
    vboInstanceUid: null,
    vboFriendlyName: '',
    vboRepositories: [],
});

export const Microsoft365ServerDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;

    const isEdit = !!props.data;
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.vboInstanceUid === props.data.vboInstanceUid), 1);
        }
        return result;
    });

    const [filteredAvailableVboServers, setFilteredAvailableVboServers] = useState<CompanyVboServer[]>([]);
    const [data, setData] = useState(isEdit ? deepCopy(props.data) : getDefaultData());

    const formApi = useExternalFormApi<ResellerVboResource>();
    const [validationState] = useState(new ValidationState());

    const wizardStore = useResellerWizardStore();

    useEffect(() => {
        wizardStore.availableVboServersCache.load()
            .then((items) => {
                if (items) {
                    setFilteredAvailableVboServers(items.filter(item => !existingItems
                        .find(existingItem => existingItem.vboInstanceUid === item.instanceUid)
                    ));
                }
            });
    }, []);


    return (
        <Dialog
            header={`${isEdit ? lang.EDIT : lang.ADD} ${lang.SERVER}`}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            trimTextFields(formApi.value, ['vboFriendlyName']);
                            props.onConfirm && props.onConfirm(formApi.value);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={wizardStore.availableVboServersCache.loading}
        >
            <Form <ResellerVboResource>
                value={data}
                validate={v => formValidate(v, existingItems, lang)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'vboInstanceUid'}
                        label={lang.VEEAM_BACKUP_FOR_MICROSOFT_365_SERVER}
                        placeholder={`${lang.SELECT_A_BACKUP_SERVER}...`}
                        data={filteredAvailableVboServers}
                        valueGetter={item => item.instanceUid}
                        textGetter={item => item.name}
                        size={defaultFieldWidth}
                    />

                    <TextInput
                        name={'vboFriendlyName'}
                        label={lang.FRIENDLY_NAME}
                        placeholder={lang.SPECIFY_A_FRIENDLY_SERVER_NAME}
                        size={defaultFieldWidth}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
});
