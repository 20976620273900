/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum FeatureNames {
    Sso = 'sso',
    SsoSaml2 = 'sso/saml2',
    Mfa = 'mfa',
    MfaTotp = 'mfa/totp',
    MfaStrictPolicy = 'mfa-strict-policy',
    Apikey = 'apikey',
    UserManagementPasswordReset = 'user-management/password-reset',
    UserManagementLoginAs = 'user-management/login-as',
    RemoteComputer = 'remote-computer',
    RemoteComputerReboot = 'remote-computer/reboot',
    RemoteComputerTerminalSessions = 'remote-computer/terminal-sessions',
    PluginsVb365provider = 'plugins/vb365provider',
    PluginsVo = 'plugins/vo',
    PluginsPulse = 'plugins/pulse',
    PluginsCwa = 'plugins/cwa',
    PluginsCwm = 'plugins/cwm',
    PluginsGrafana = 'plugins/grafana',
    PluginsVbr = 'plugins/vbr',
    AppearanceHideEmptyWidgets = 'appearance/hide-empty-widgets',
}
