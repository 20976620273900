/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { JobMode, VbrBackupAgentJobKind, BackupServerMbaLicenseRepresentation, JobStateRepresentation } from '@veeam-vspc/models/web-controllers';
import { AdvancedFilter, Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE } from '@veeam-vspc/components';

import type { AdvancedFilterOptions, ToolbarProps, AdvancedFilterValue } from '@veeam-vspc/components';

import managedByBackupServerModeIconSrc from 'images/filters/vbr-job-modes/job.svg';
import managedByAgentModeIconSrc from 'images/filters/vbr-job-modes/policy.svg';
import backupJobKindIconSrc from 'images/filters/job-types/backup.png';
import backupCopyJobKindIconSrc from 'images/filters/job-types/backup-copy.png';
import serverAgentLicenseTypeIconSrc from 'images/filters/agent-job-types/server.png';
import sureBackupJobTypeIconSrc from 'images/filters/job-types/sure-backup.png';
import workstationAgentLicenseTypeIconSrc from 'images/filters/agent-job-types/workstation.png';
import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedToolbar } from 'views/components/AdvancedToolbar';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { useOsTypesAdvancedFilter } from 'views/components/filters/OsTypesFilters';

export interface ManagedByBackupServerFilterToolbarProps extends Omit<ToolbarProps, 'items'> {
}

export const ManagedByBackupServerFilterToolbar: React.FC<ManagedByBackupServerFilterToolbarProps> = (props) => {
    const lang = useLang();
    const { None, Success, Warning, Failed, Running } = JobStateRepresentation;
    const statuses = [
        { title: lang.SUCCESS, value: Success },
        { title: lang.WARNING, value: Warning },
        { title: lang.ERROR, value: Failed },
        { title: lang.RUNNING, value: Running },
        { title: lang.INFO, value: None },
    ];
    const osTypesAdvancedFilter = useOsTypesAdvancedFilter();
    const advancedFilterOptions: AdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_BACKUP_AGENTS_BY}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.JOB_MODE,
                        items: [
                            {
                                iconSrc: managedByBackupServerModeIconSrc,
                                label: lang.MANAGED_BY_BACKUP_SERVER_JOB,
                                value: {
                                    id: JobMode.ManagedByBackupServer,
                                    name: 'modes',
                                },
                            },
                            {
                                iconSrc: managedByAgentModeIconSrc,
                                label: lang.MANAGED_BY_AGENT,
                                value: {
                                    id: JobMode.ManagedByAgent,
                                    name: 'modes',
                                },
                            },
                        ],
                    },
                    osTypesAdvancedFilter,
                ],
            },
            {
                groups: [
                    {
                        title: capitalize(lang.JOB_TYPE, true),
                        items: [
                            {
                                iconSrc: backupJobKindIconSrc,
                                label: lang.BACKUP,
                                value: {
                                    id: VbrBackupAgentJobKind.BackupJob,
                                    name: 'jobKind',
                                },
                            },
                            {
                                iconSrc: backupCopyJobKindIconSrc,
                                label: lang.BACKUP_COPY,
                                value: {
                                    id: VbrBackupAgentJobKind.CopyJob,
                                    name: 'jobKind',
                                },
                            },
                            {
                                iconSrc: sureBackupJobTypeIconSrc,
                                label: lang.SUREBACKUP,
                                value: {
                                    id: VbrBackupAgentJobKind.SureBackupJob,
                                    name: 'jobKind',
                                },
                            },
                        ],
                    },
                    {
                        title: capitalize(lang.OPERATION_MODE, true),
                        items: [
                            {
                                iconSrc: serverAgentLicenseTypeIconSrc,
                                label: lang.SERVER,
                                value: {
                                    id: BackupServerMbaLicenseRepresentation.Server,
                                    name: 'licenseTypes',
                                },
                            },
                            {
                                iconSrc: workstationAgentLicenseTypeIconSrc,
                                label: lang.WORKSTATION,
                                value: {
                                    id: BackupServerMbaLicenseRepresentation.Workstation,
                                    name: 'licenseTypes',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return (
        <AdvancedToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='computerName'
                            placeholder={lang.COMPUTER}
                            hasSearchButton={true}
                            maxLength={512}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='jobName'
                            placeholder={lang.POLICY_OR_JOB}
                            hasSearchButton={true}
                            maxLength={512}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='backupServerName'
                            placeholder={capitalize(lang.BACKUP_SERVER)}
                            hasSearchButton={true}
                            maxLength={512}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            statuses={statuses}
                            propName={'statuses'}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.separator,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <AdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
