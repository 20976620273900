/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';

import { useIntervalRequest } from 'views/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { PlateWidget } from '../widgets/PlateWidget';
import errorLight from '../../../../../images/error-light.svg';
import warningLight from '../../../../../images/warning-light.svg';

import type { Alarms } from '../../interfaces';


export const ManagementAgents: React.FC = () => {
    const lang = useLang();
    const request = useIntervalRequest<{}, Alarms>('Overview/AgentStateAlarms');
    const data = request.data as Alarms;
    const errorsCount = data?.errors.value;
    const warningsCount = data?.warnings.value;

    return (
        <PlateWidget
            title={lang.MANAGEMENT_AGENTS}
            items={[
                errorsCount > 0 && {
                    name: lang.INACCESSIBLE,
                    icon: errorLight,
                    count: errorsCount,
                    trend: data?.errors.trend,
                },
                warningsCount > 0 && {
                    name: lang.WARNINGS,
                    icon: warningLight,
                    count: warningsCount,
                    trend: data?.warnings.trend,
                },
            ]}
            isHealthy={errorsCount === 0 && warningsCount === 0}
            loading={request.loading}
            healthyText={lang.HEALTHY}
            onClick={() => window.location.assign('#home/discovery/tab/discoveredComputersTab/s/discoveredComputers')}
            className='fb-50'
        />
    );
};
