/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { VcdOrganization, VcdOrganizationsData } from '../interfaces';

export const getOrganization = (organizationsData: VcdOrganizationsData[], id: string) => {
    let result: VcdOrganization = null;

    if (organizationsData && id) {
        for (let i = 0; i < organizationsData.length; i++) {
            result = organizationsData[i].organizations.find(org => org.cloudConnectResourceUid === id);
            if (result) {
                break;
            }
        }
    }

    return result;
};
export const getOrganizationData = (organizationsData: VcdOrganizationsData[], id: string) => {
    if (organizationsData && id) {
        return organizationsData.find(od => od.organizations.find(org => org.cloudConnectResourceUid === id));
    }

    return null;
};
