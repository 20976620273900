/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { ToolbarItemDecorator } from '@veeam/components/lib/Toolbar/types';

// TODO: fix decorators in @veeam/components
// or remove after fix in UIKit 327573 https://tfs.veeam.dev/DefaultCollection/UIKit/_workitems?id=327573
export const correctDecorators = (input: ToolbarItemDecorator[]) => {
    const result = (payload, api) => {
        let disabled = false;
        let hidden = false;
        input.forEach((decorator) => {
            const decoratorResult = decorator(payload, api);
            disabled = disabled || decoratorResult.disabled;
            hidden = hidden || decoratorResult.hidden;
        });
        return {
            disabled,
            hidden,
        };
    };

    return [ result ];
};
