/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { CompanyData, CompanyVcdNetworkExtension } from '../interfaces';
import type { CompanyWizardStore } from '../stores';

export const syncVcdNetworksExtensions = async(data: CompanyData, wizardStore: CompanyWizardStore): Promise<CompanyVcdNetworkExtension[]> => {
    const params = {
        agentUid: data.cloudConnectAgentUid,
        companyId: data.id,
        vdcUids: data.vcdReplicationResources.map(item => item.dataCenterUid),
    };

    const serverItems = await wizardStore.vcdNetworkExtensionsCache.load(params);

    let result = [...data.vcdNetworksExtensions];

    // add new items
    serverItems.forEach((serverItem) => {
        if (!result.find(localItem => serverItem.dataCenterUid === localItem.dataCenterUid)) {
            result.push(serverItem);
        }
    });

    // looking for networks that need to be deleted
    result = result.filter(localItem => serverItems.find(serverItem => serverItem.dataCenterUid === localItem.dataCenterUid));

    return result;
};
