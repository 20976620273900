/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { deepCopy } from '@veeam-vspc/core';
import {
    ACTION_VIEW, Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    TextInput,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { useCompanyWizardStore } from '../../../../../../hooks';
import { trimTextFields } from 'core/utils';

import type { CompanyVboServer } from 'core/interfaces/company-vbo-server';
import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';
import type { VboServer } from '../../../../../../interfaces';

interface Props {
    deactivate: () => void;
    onConfirm: (data: VboServer) => void;

    data?: VboServer;
    existingItems: VboServer[];
    companyId?: number;
}

const formValidate = (data: VboServer, existingItems: VboServer[], lang: LangsServiceBase) => {
    const validator = new FormValidator(data);

    validator.validate('vboServerUid')
        .string()
        .required();

    validator.validate('friendlyName')
        .string()
        .required()
        .maxLength(256)
        .check((value => existingItems.find(item => item.friendlyName === value.trim()) ? lang.THIS_FRIENDLY_NAME : ''));

    return validator.result();
};

const getDefaultData = (): VboServer => ({
    vboServerUid: null,
    friendlyName: '',
    isJobManagementAllowed: true,
});

export const Microsoft365ServerDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;
    const wizardStore = useCompanyWizardStore();

    const isEdit = !!props.data;
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.vboServerUid === props.data.vboServerUid), 1);
        }
        return result;
    });

    const [filteredAvailableVboServers] = useState<CompanyVboServer[]>(wizardStore.availableVboServersCache.value
        .filter(item => !existingItems.find(existingItem => existingItem.vboServerUid === item.instanceUid)));
    const [data, setData] = useState(isEdit ? deepCopy(props.data) : getDefaultData());

    const formApi = useExternalFormApi<VboServer>();
    const [validationState] = useState(new ValidationState());

    return (
        <Dialog
            header={`${isEdit ? lang.EDIT : lang.ADD} ${lang.SERVER}`}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            trimTextFields(formApi.value, ['friendlyName']);
                            props.onConfirm && props.onConfirm(formApi.value);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={wizardStore.availableVboServersCache.loading}
        >
            <Form <VboServer>
                value={data}
                validate={v => formValidate(v, existingItems, lang)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'vboServerUid'}
                        label={lang.VEEAM_BACKUP_FOR_MICROSOFT_365_SERVER}
                        placeholder={`${lang.SELECT_A_BACKUP_SERVER}...`}
                        data={filteredAvailableVboServers}
                        valueGetter={item => item.instanceUid}
                        textGetter={item => item.name}
                        size={defaultFieldWidth}
                    />

                    <TextInput
                        name={'friendlyName'}
                        label={lang.FRIENDLY_NAME}
                        placeholder={lang.SPECIFY_A_FRIENDLY_SERVER_NAME}
                        size={defaultFieldWidth}
                    />

                </FormLayout>
            </Form>
        </Dialog>
    );
});
