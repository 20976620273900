/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { ResellerCloudVcdResourceMapModel } from '@veeam-vspc/models/web-controllers';

import { useLang } from 'views/providers/LangProvider/hooks';
import { DisplayField } from 'components/controls/DisplayField';
import { useResellerWizardStore } from '../../../../../../hooks';

interface Props {
    deactivate: () => void;
    onConfirm: (data: ResellerCloudVcdResourceMapModel) => void;

    data?: ResellerCloudVcdResourceMapModel;
    existingItems: ResellerCloudVcdResourceMapModel[];
    cloudConnectAgentUids: string[];
}

const formValidate = (data: ResellerCloudVcdResourceMapModel, lang: any, isEdit: boolean, existingItems: ResellerCloudVcdResourceMapModel[]) => {
    const validator = new FormValidator(data);

    validator.validate('cloudConnectResourceUid')
        .string()
        .required();

    return validator.result();
};

export const VcdResourceDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;
    const wizardStore = useResellerWizardStore();

    const defaultData: ResellerCloudVcdResourceMapModel = {
        cloudConnectResourceName: '',
        cloudConnectResourceUid: null,
        cloudConnectAgentUid: null,
        uniqueUid: null,
    };

    const isEdit = !!props.data;
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.cloudConnectResourceUid === props.data.cloudConnectResourceUid), 1);
        }
        return result;
    });

    const [filteredVcdOrganizations] = useState(wizardStore.vcdOrganizationsCache.value
        .filter(item => !existingItems
            .find(existingItem => existingItem.cloudConnectResourceUid === item.cloudConnectResourceUid)
        )
    );

    const [data, setData] = useState(props.data ?? defaultData);

    const formApi = useExternalFormApi<ResellerCloudVcdResourceMapModel>();
    const [validationState] = useState(new ValidationState());

    return (
        <Dialog
            header={lang.VMWARE_CLOUD_DIRECTOR_RESOURCE}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            props.onConfirm && props.onConfirm(formApi.value);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
        >
            <Form <ResellerCloudVcdResourceMapModel>
                value={data}
                validate={data => formValidate(data, lang, isEdit, existingItems)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'cloudConnectResourceUid'}
                        label={lang.ASSIGN_VMWARE_CLOUD_DIRECTOR_ORGANIZATION_TO_THE_RESELLER}
                        placeholder={lang.SELECT_AN_ORGANIZATION}
                        data={filteredVcdOrganizations}
                        valueGetter={item => item.cloudConnectResourceUid}
                        textGetter={item => item.cloudConnectResourceName}
                        size={defaultFieldWidth}
                        disabled={data.companyMap?.length > 0}
                        onChange={(value, itemIndex) => {
                            const item = filteredVcdOrganizations[itemIndex];
                            formApi.setValue('cloudConnectResourceName', item.cloudConnectResourceName);
                            formApi.setValue('cloudConnectAgentUid', item.cloudConnectAgentUid);
                            formApi.setValue('uniqueUid', item.uniqueUid);
                            formApi.setValue('dataCenterCount', item.dataCenterCount);
                        }}
                    />

                    <FormLayout inlineLabel>
                        <DisplayField
                            value={
                                wizardStore.vcdOrganizationsCache.value
                                    .find(vcd => vcd.cloudConnectResourceUid === data.cloudConnectResourceUid)
                                    ?.dataCenterCount
                            }
                            label={capitalize(lang.N_OF_VIRTUAL_DATA_CENTERS, true)}
                        />
                    </FormLayout>
                </FormLayout>
            </Form>
        </Dialog>
    );
});
