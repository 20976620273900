/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { BaseTrendChartContext } from './contexts';
import { BaseTrendChartStore } from './stores';
import { HighchartsWrapper } from '../HighchartsWrapper';
import { getHighchartsOptions } from '../../helpers';
import { useBaseTrendChartStore } from './hooks';
import { useFilterStore } from '../../hooks';
import { useGlobalFiltersChangeHandler } from '../../../../providers/GlobalFiltersProvider/hooks';

import type { BaseChartProps, BaseTrendChartProps } from './interfaces';

const BaseChart = observer(({ objectsType, params, title }: BaseChartProps) => {
    const store = useBaseTrendChartStore();
    const { categories, dimension, empty, loading, series } = store;
    const { backupServicesType, reportingPeriodInfo } = useFilterStore();
    const [options, setOptions] = useState({});

    const fetchData = () => {
        store.fetchData({ backupServicesType, objectsType, reportingPeriodInfo });
    };

    useEffect(fetchData, [backupServicesType, objectsType, reportingPeriodInfo, store]);

    useEffect(() => {
        setOptions(getHighchartsOptions({
            categories,
            series,
            ...Object.assign({}, params, {
                yTitle: dimension ? `${params.yTitle}, ${dimension}` : params.yTitle,
            }),
        }));
    }, [categories, dimension, params, series]);

    useGlobalFiltersChangeHandler(fetchData);

    return (
        <HighchartsWrapper
            empty={empty}
            loading={loading}
            options={Object.assign(options, { title: { text: '' } })}
            title={title}
        />
    );
});

export const BaseTrendChart = ({ service, ...rest }: BaseTrendChartProps) => {
    const baseTrendChartStore = useMemo(() => new BaseTrendChartStore(service), [service]);

    return (
        <BaseTrendChartContext.Provider value={baseTrendChartStore}>
            <BaseChart {...rest} />
        </BaseTrendChartContext.Provider>
    );
};
