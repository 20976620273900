/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ConfigSectionIds {
    BackupJobsComputersManagedByConsole = 'BackupJobs.Computers.ManagedByConsole',
    BackupJobsComputersManagedByBackupServer = 'BackupJobs.Computers.ManagedByBackupServer',
    BackupJobsVirtualMachinesVirtualInfrastructure = 'BackupJobs.VirtualMachines.VirtualInfrastructure',
    BackupJobsVirtualMachinesPublicClouds = 'BackupJobs.VirtualMachines.PublicClouds',
    BackupJobsFileSharesPublicClouds = 'BackupJobs.FileShares.PublicClouds',
    BackupJobsCloudDatabases = 'BackupJobs.CloudDatabases',
    BackupJobsFileSharesLocal = 'BackupJobs.FileShares.Local',
    BackupJobsSelectVbDialogFilterToolbar = 'BackupJobs.SelectVbDialog.FilterToolbar',
    ProtectedDataComputers = 'ProtectedData.Computers',
    ProtectedDataFileSharesLocal = 'ProtectedData.FileShares.Local',
    ProtectedDataFileSharesPublicClouds = 'ProtectedData.FileShares.PublicClouds',
    ProtectedDataCloudDatabases = 'ProtectedData.CloudDatabases',
    ProtectedDataVirtualMachinesVirtualInfrastructure = 'ProtectedData.VirtualMachines.VirtualInfrastructure',
    ProtectedDataVirtualMachinesPublicClouds = 'ProtectedData.VirtualMachines.PublicClouds',
    ReportsConfigurationsWizard = 'Reports.Configurations.Wizard',
}
