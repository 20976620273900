/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Text,
    StackView,
    STACK_DIRECTION,
    TitleH1,
    TEXT_WHITE_SPACE,
    PluginEntry,
    SPACE_FILL,
    SPACING_M,
    FieldLayout,
    ComboboxKit,
    STACK_GAP,
    PortalSpinner,
    useAppStorage,
    colors,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { useAppServices, useAppStore } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import successIcon from 'images/statuses/success.svg';
import errorIcon from 'images/statuses/error.svg';
import warningIcon from 'images/statuses/warning.svg';
import infoIcon from 'images/statuses/info.svg';
import { ConnectionStatus, PluginNames, PluginsFilter } from '../enums';

import type { PluginItem, WithCount } from '../interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

const ITEM_WIDTH = 512;
const FILTER_KEY = 'pluginsLibraryFilter';

const LibraryContainer = styled(StackView)`
    overflow: auto;
    position: relative;
`;

const LibraryHeader = styled(StackView)`
    position: sticky;
    top: 0;
    z-index: 2;
    padding: ${SPACING_M};
    background-color: ${colors.G000};
`;

const LibraryGrid = styled.div<WithCount>`
    display: grid;
    grid-template-columns: repeat(auto-fill, ${ITEM_WIDTH}px);
    gap: ${SPACING_M};
    padding: ${SPACING_M};
    padding-top: 0;

    /*
        Columns number limit

        Explanation:
        If we have 4 or less items they have to be rendered as grid 2x2, otherwise 3xInfinity.

        Math:
        2 Columns: Item Width * Amount of columns + Padding Left + Padding Right + Gap
        3 Columns: Item Width * Amount of columns + Padding Left + Padding Right + Gap * 2
    */
    max-width: ${props => props.itemsCount <= 4
        ? `${(ITEM_WIDTH * 2) + parseInt(SPACING_M) * 3}px`
        : `${(ITEM_WIDTH * 3) + (parseInt(SPACING_M) * 4)}px`};

`;

const filterRules: { [key in PluginsFilter]?: PluginNames[]; } = {
    [PluginsFilter.PublicCloud]: [PluginNames.Vb, PluginNames.Vb365provider],
    [PluginsFilter.Licensing]: [PluginNames.Pulse, PluginNames.VeeamOne, PluginNames.Vb, PluginNames.Vb365provider],
    [PluginsFilter.Billing]: [PluginNames.ConnectWise],
    [PluginsFilter.Storage]: [PluginNames.ObjectStorage],
    [PluginsFilter.RMM]: [PluginNames.Vb, PluginNames.Vb365provider, PluginNames.Vbr, PluginNames.ConnectWiseAutomate, PluginNames.Agents],
    [PluginsFilter.RESTAPI]: [PluginNames.Grafana],
};

function getPluginStatus(plugin: PluginItem, lang: LangsServiceBase) {
    let statusIcon = infoIcon;
    let statusText = capitalize(lang.NOT_CONFIGURED);

    switch (plugin.status) {
        case ConnectionStatus.Connect:
            statusText = lang.CONFIGURED;
            statusIcon = successIcon;
            break;
        case ConnectionStatus.Failed:
            statusText = lang.ERROR;
            statusIcon = errorIcon;
            break;
        case ConnectionStatus.Warning:
            statusText = lang.WARNING;
            statusIcon = warningIcon;
            break;
        case ConnectionStatus.Disconnected:
            statusText = lang.DISCONNECTED;
            statusIcon = errorIcon;
            break;
        case ConnectionStatus.NoConnection:
            statusText = lang.DISCONNECTED;
            statusIcon = warningIcon;
            break;
        case ConnectionStatus.NotApplicable:
            statusText = lang.DASH_SYMBOL;
            statusIcon = '';
            break;

        default:
            statusText = lang.NOT_CONFIGURED;
            statusIcon = infoIcon;
    }

    if (!plugin.isInstalled) {
        statusIcon = infoIcon;
        statusText = lang.NOT_INSTALLED;
    }

    if (plugin.isOutOfDate) {
        statusIcon = warningIcon;
        statusText = lang.UPDATE_REQUIRED;
    }

    return { statusIcon, statusText };
}


export const PluginLibrary: React.FC = () => {
    const lang = useLang();
    const { transportService, notificationService } = useAppServices();
    const { portalUser } = useAppStore();
    const [plugins, setPlugins] = useState<PluginItem[]>([]);
    const [filteredPlugins, setFilteredPlugins] = useState<PluginItem[]>([]);
    const [filter, setFilter] = useState<PluginsFilter>(PluginsFilter.All);
    const [loading, setLoading] = useState<boolean>();
    const storage = useAppStorage();

    useEffect(() => {
        const savedFilter = storage.getItem(FILTER_KEY);

        if (savedFilter) {
            setFilter(Number(savedFilter));
        }
    }, []);

    useEffect(() => {
        const items = plugins.filter(plugin => filter === PluginsFilter.All || filterRules[filter].includes(plugin.integratorName));

        setFilteredPlugins(items);
    }, [filter, plugins]);

    useEffect(() => {
        transportService
            .request<{}, PluginItem[]>('Integrator/GetIntegrators')
            .then((resp: BaseSuccessRequestResponse<PluginItem[]>) => setPlugins(resp.data));
    }, []);

    const handleFilterChange = (value: PluginsFilter) => {
        setFilter(value);

        storage.setItem(FILTER_KEY, String(value));
    };

    const checkConnectionInfo = (connectorHost: string, skipBaseUrl = false, repeatNumber = 0) => {
        const repeatCount = 1;

        return transportService.request(`${connectorHost}/ConnectionInfo`, null, { method: 'GET' }, { notShowDefaultError: true, skipBaseUrl })
            .catch((resp) => {
                if (repeatNumber === repeatCount) {
                    // no more repeats
                    return Promise.reject(resp);
                }

                // repeat ConnectionInfo if it has been failed first
                // try to fix bug 133368
                return checkConnectionInfo(connectorHost, skipBaseUrl, repeatNumber + 1);
            });
    };

    const goToPlugin = (connectorHost: string) => {
        document.location.href = `${connectorHost}/index.html`;
    };

    const handleClick = (plugin: PluginItem) => {
        const { integratorName, isInstalled, uiCheckOn } = plugin;
        const connectorHost = `/Connectors/${integratorName}`;

        const remotePlugin = [
            PluginNames.ConnectWise,
        ].includes(plugin.integratorName);

        if (!isInstalled) {
            return;
        }

        setLoading(true);

        if (!remotePlugin) {
            document.location.href = `plugins/${integratorName.toLowerCase()}`;

            return;
        }

        if (!uiCheckOn) {
            document.location.href = `${connectorHost}/`;

            return;
        }

        const isSkipBaseUrl = [
            'ConnectWise',
        ].some(name => integratorName === name);

        return checkConnectionInfo(connectorHost, isSkipBaseUrl)
            .then(() => goToPlugin(connectorHost))
            .catch((resp) => {
                if (resp && typeof resp.status !== 'undefined') {
                    return goToPlugin(connectorHost);
                }

                setLoading(false);

                return notificationService
                    .error(lang.CONNECTWISE_MANAGE_PLUGIN, lang.CANNOT_ACCESS_CONNECTWISE);
            });
    };

    const filterBox = (
        <FieldLayout inlineLabel label={`${lang.CATEGORY}:`}>
            <ComboboxKit
                value={filter}
                onChange={handleFilterChange}
                data={[
                    { value: PluginsFilter.All, text: lang.ALL },
                    { value: PluginsFilter.PublicCloud, text: lang.PUBLIC_CLOUD },
                    { value: PluginsFilter.Licensing, text: lang.LICENSING },
                    { value: PluginsFilter.Billing, text: lang.BILLING },
                    { value: PluginsFilter.Storage, text: lang.OBJECT_STORAGE },
                    { value: PluginsFilter.RMM, text: lang.REMOTE_MANAGEMENT },
                    { value: PluginsFilter.RESTAPI, text: lang.REST_API },
                ]}
                valueGetter={x => x.value}
                textGetter={x => x.text}
            />
        </FieldLayout>
    );

    const filterBoxAvailable =
        !portalUser.isCompanyOwner() &&
        !portalUser.isServiceProviderGroup() &&
        !portalUser.isCompanyAdministrator();

    return (
        <LibraryContainer
            direction={STACK_DIRECTION.column}
            spaceFill={SPACE_FILL.all}
            gap={STACK_GAP.m}
        >
            <LibraryHeader
                direction={STACK_DIRECTION.column}
                gap={STACK_GAP.m}
            >
                <StackView direction={STACK_DIRECTION.column}>
                    <TitleH1>{lang.WELCOME_TO_THE_VEEAM_SERVICE}</TitleH1>
                    <Text whitespace={TEXT_WHITE_SPACE.preWrap}>{lang.HERE_YOU_CAN_FIND_ALL_INTEGRATIONS}</Text>
                </StackView>

                {filterBoxAvailable && filterBox}
            </LibraryHeader>

            <LibraryGrid itemsCount={filteredPlugins.length}>
                {filteredPlugins.map(plugin => (
                    <PluginEntry
                        key={plugin.integratorTitle}
                        title={plugin.integratorTitle}
                        icon={`resources/images/integrations/${plugin.integratorName}.png`}
                        icon2x={`resources/images/integrations/${plugin.integratorName}@2x.png`}
                        description={plugin.description || ''}
                        disabled={!plugin.isInstalled}
                        notAvailable={!plugin.isAvailable}
                        onClick={() => handleClick(plugin)}
                        {...getPluginStatus(plugin, lang)}
                    />
                ))}
            </LibraryGrid>

            {loading && <PortalSpinner />}
        </LibraryContainer>
    );
};
