/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { WeekdaysNames } from '@veeam-vspc/core';

export enum AllWeekdaysName {
    All = 'All',
}

export type ScheduleWeekdays = AllWeekdaysName | WeekdaysNames;
