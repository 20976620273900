/**
 * Copyright © Veeam Software Group GmbH.
 */
import { JobSessionTaskStatusRepresentation } from '@veeam-vspc/models/web-controllers';

export enum JobSessionCdpTaskStatusRepresentationText {
    Success = 'Success',
    Unknown = 'Unknown',
    Failed = 'Failed',
    Warning = 'Warning',
    InProgress = 'Syncing...',
    Pending = 'Pending',
}

export const getJobSessionCdpTaskStatusRepresentationText = (status: JobSessionTaskStatusRepresentation) => {
    switch (status) {
        case JobSessionTaskStatusRepresentation.Success:
            return JobSessionCdpTaskStatusRepresentationText.Success;

        case JobSessionTaskStatusRepresentation.Unknown:
            return JobSessionCdpTaskStatusRepresentationText.Unknown;

        case JobSessionTaskStatusRepresentation.Failed:
            return JobSessionCdpTaskStatusRepresentationText.Failed;

        case JobSessionTaskStatusRepresentation.Warning:
            return JobSessionCdpTaskStatusRepresentationText.Warning;

        case JobSessionTaskStatusRepresentation.InProgress:
            return JobSessionCdpTaskStatusRepresentationText.InProgress;

        case JobSessionTaskStatusRepresentation.Pending:
            return JobSessionCdpTaskStatusRepresentationText.Pending;

        default: return JobSessionCdpTaskStatusRepresentationText.Unknown;
    }
};
