/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts, isPositiveNumber } from '@veeam-vspc/components';

import { OldRestorePointDialog } from './components/OldRestorePointDialog/OldRestorePointDialog';

import type { OldRestorePointDialogProps } from './components/OldRestorePointDialog/OldRestorePointDialog';

export interface OldRestorePointColumnProps extends Omit<OldRestorePointDialogProps, 'deactivate'> {
    value: string | number | null;
    isLink?: boolean;
}

export const OldRestorePointColumn: React.FC<OldRestorePointColumnProps> = ({ value, isLink, ...restProps }) => (
    <ActionLinkColumn
        content={isPositiveNumber(value) ? value : ColumnTexts.DashSymbol}
        isLink={isLink !== undefined ? isLink : isPositiveNumber(value)}
        isExtMode={true}
        alignRight={true}
        renderModal={({ deactivate }) => (
            <OldRestorePointDialog
                {...restProps}
                deactivate={deactivate}
            />
        )}
    />
);
