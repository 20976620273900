/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { NotificationDialogsTextKeys } from '@veeam-vspc/core';

import type { NotificationResponse } from '@veeam-vspc/core';

import { useAppActions, useAppServices, useAppStore } from '../../hooks';
import { useLang } from '../../../LangProvider/hooks';

export const BeforeAppDialogs = () => {
    const { notificationService } = useAppServices();
    const { openLogin, openApp } = useAppActions();
    const appStore = useAppStore();
    const { portalUser, message } = appStore;
    const lang = useLang();

    Promise.resolve()
        .then(() => {
            if (portalUser && portalUser.companyStatus === RCOP.Enums.CompanyStatus.Disabled) {
                return notificationService.confirm(lang.COMPANY_STATE, lang.READ_ONLY_COMPANY_STATUS)
                    .then((btnKey: NotificationResponse) => btnKey === NotificationDialogsTextKeys.Ok ? Promise.resolve() : Promise.reject());
            }
        })
        .then(() => {
            if (message) {
                return notificationService.warning(lang.LOGIN, message);
            }
        })
        .then(() => openApp())
        .catch(() => openLogin());

    return <div />;
};
