/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Vb365JobItemComposed } from '@veeam-vspc/models/rest';

import group from 'images/filters/protected-data-type/group.svg';
import site from 'images/filters/protected-data-type/site.svg';
import teams from 'images/filters/protected-data-type/teams.svg';
import user from 'images/filters/protected-data-type/user.svg';
import tbd from 'images/tbd.svg';

export const resourceTypesIcons = {
    [Vb365JobItemComposed.ItemTypeEnum.User]: user,
    [Vb365JobItemComposed.ItemTypeEnum.Group]: group,
    [Vb365JobItemComposed.ItemTypeEnum.Site]: site,
    [Vb365JobItemComposed.ItemTypeEnum.Team]: teams,
    [Vb365JobItemComposed.ItemTypeEnum.PartialOrganization]: tbd,
};

