/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Toolbar } from '@veeam-vspc/components';

import type { ToolbarItem, ToolbarProps } from '@veeam-vspc/components';

import { useConfiguredToolbar } from './hooks';

import type { ConfigSectionIds } from 'core/services/configurator/enums';


export interface AdvancedToolbarProps extends Partial<ToolbarProps> {
    sectionId?: ConfigSectionIds;
}

export const AdvancedToolbar: React.FC<AdvancedToolbarProps> = ({ sectionId, groups = [], items = [], ...restProps }) => {
    const { configuredGroups, configuredItems } = useConfiguredToolbar(sectionId, groups, items as ToolbarItem[]);


    if (configuredItems.length === 0) {
        return null;
    }

    return (
        <Toolbar
            {...restProps}
            groups={configuredGroups}
            items={configuredItems}
        />
    );
};
