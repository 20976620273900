/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    ACTION_VIEW,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    disableForSelectionWithPropValueCallback,
    disableForSelectionWithPropValues,
    processDecoratorsForDisabledByPriority,
} from '@veeam-vspc/components';
import { NotificationDialogsTextKeys } from '@veeam-vspc/core';
import { EPublicCloudObjectCreatingState } from '@veeam-vspc/models/web-controllers';

import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { useLang } from 'views/providers/LangProvider/hooks';
import startActionIconSrc from 'images/actions/start.svg';
import stopActionIconSrc from 'images/actions/stop.svg';
import retryActionIconSrc from 'images/actions/retry.png';
import enableActionIconSrc from 'images/actions/enable.png';
import disableActionIconSrc from 'images/actions/disable.svg';
import downloadLogsActionIconSrc from 'images/actions/download-logs.svg';
import { EJobStates, JobTypes } from 'core/enums';
import { useAsyncAction } from 'views/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { ConfigSectionIds } from 'core/services/configurator/enums';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';
import type { BackupServerFileJobFilterModel, BackupServerFileJobItem } from '../../interfaces';

export interface FileSharesLocalActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items'> {
    method?: string;
}

export const FileSharesLocalActionToolbar: React.FC<FileSharesLocalActionToolbarProps> = (props) => {
    const { method = 'GetFileJobs', ...restProps } = props;
    const lang = useLang();
    const { notificationService } = useAppServices();
    const gridApi = useGridApi<BackupServerFileJobItem, unknown, BackupServerFileJobFilterModel>();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSelection(),
    ];

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                method={method}
                sectionId={ConfigSectionIds.BackupJobsFileSharesLocal}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: startActionIconSrc,
                        text: lang.START,
                        onClick: () => doAsyncAction('/BackupServerJob/StartFileJob', lang.START, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('status', [
                                    EJobStates.Idle,
                                    EJobStates.Running,
                                    EJobStates.Starting,
                                    EJobStates.Stopping,
                                    EJobStates.Enabling,
                                    EJobStates.Disabling,
                                    EJobStates.WaitingTape,
                                    EJobStates.WaitingRepository,
                                ]),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopActionIconSrc,
                        text: lang.STOP,
                        onClick: () => {
                            const { selected } = gridApi;
                            const doAction = (gracefully: boolean) => doAsyncAction('/BackupServerJob/StopFileJob', lang.STOP, {
                                ...gridApi.requestParamsForActions,
                                gracefully,
                            });
                            const skipGracefullyFor = [
                                JobTypes.SureBackup,
                                JobTypes.FileTapeBackup,
                                JobTypes.VmTapeBackup,
                            ];

                            if (selected.every(item => skipGracefullyFor.includes(item.jobType))) {
                                return doAction(false);
                            }

                            return notificationService.info(lang.STOP_JOB, lang.WE_CAN_STOP_THIS_JOB_GRACEFULLY, {
                                buttons: [
                                    {
                                        type: NotificationDialogsTextKeys.Ok,
                                        text: lang.GRACEFULLY,
                                    },
                                    {
                                        type: NotificationDialogsTextKeys.Yes,
                                        text: lang.IMMEDIATELY,
                                    },
                                    {
                                        type: NotificationDialogsTextKeys.Cancel,
                                        text: lang.CANCEL,
                                        view: ACTION_VIEW.secondary,
                                    },
                                ],
                            })
                                .then((btn) => {
                                    if (btn === NotificationDialogsTextKeys.Ok || btn === NotificationDialogsTextKeys.Yes) {
                                        return doAction(btn === NotificationDialogsTextKeys.Ok);
                                    }
                                });
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('status', value => value !== EJobStates.Running),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: retryActionIconSrc,
                        text: lang.RETRY,
                        onClick: () => doAsyncAction('/BackupServerJob/RetryFileJob', lang.RETRY, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.FileCopy,
                                    JobTypes.FileTapeBackup,
                                ]),
                                disableForSelectionWithPropValueCallback('status', value => value !== EJobStates.Failed),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: enableActionIconSrc,
                        text: lang.ENABLE,
                        onClick: () => doAsyncAction('/BackupServerJob/EnableFileJob', lang.ENABLE, {
                            ...gridApi.requestParamsForActions,
                            enable: true,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('scheduleState', value => value !== lang.DISABLED),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: disableActionIconSrc,
                        text: lang.DISABLE,
                        onClick: () => doAsyncAction('/BackupServerJob/EnableFileJob', lang.DISABLE, {
                            ...gridApi.requestParamsForActions,
                            enable: false,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('scheduleState', value => (
                                    [lang.DISABLED, lang.NOT_SCHEDULED].includes(value as string)
                                )),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: downloadLogsActionIconSrc,
                        text: lang.DOWNLOAD_LOGS,
                        onClick: () => {
                            Ext.create('RCOP.utils.DownloadLogsManager', {
                                title: lang.DOWNLOAD_LOGS,
                                url: '/BackupServerJob/DownloadFileJobLogs',
                                params: gridApi.requestParamsForActions,
                                showDialog: true,
                            }).start();
                        },
                        decorators: defaultDisabledDecorators,
                    },
                ]}
            />

            {asyncActionLoader}
        </>
    );
};
