/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormLayout,
    FormValidator,
    isValid,
    NoteBar,
    NOTEBAR_STATUS,
    PasswordInput,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TextInput,
} from '@veeam-vspc/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';
import type { BaseFailedRequestResponse, BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { ValidationResult, VbrDeployTenantValidationParam } from '@veeam-vspc/models/web-controllers';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { DataCache } from 'core/utils/data-cache';
import { core } from 'core/core-module';
import { VbrDeployType } from '../../enums';

import type { AppServices } from 'views/providers/AppProvider/interfaces';
import type { VbrDeploySettingsWizardModel } from '../../entries';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

const formValidate = (data: VbrDeploySettingsWizardModel) => {
    const validator = new FormValidator(data);

    validator.validate('tenantPassword')
        .string()
        .required();

    return validator.result();
};

const checkTenantCredentials = new DataCache<ValidationResult, VbrDeployTenantValidationParam>(
    data => core.transportService.request<VbrDeployTenantValidationParam, ValidationResult>('/DiscoveryRule/CheckTenantCredentials', {
        organizationUid: data.organizationUid,
        tenantName: data.tenantName,
        password: data.password,
    }).then((resp: BaseSuccessRequestResponse<ValidationResult>) => resp.data)
);

const tenantInformationStepValidate = (
    data: VbrDeploySettingsWizardModel,
    lang: LangsServiceBase,
    appServices: AppServices
): boolean | Promise<boolean> => {
    const { notificationService } = appServices;

    if (isValid(value => formValidate(value), data)) {
        return checkTenantCredentials.load({
            organizationUid: data.organizationUid,
            tenantName: data.tenantName,
            password: data.tenantPassword,
        }).then((data: ValidationResult) => {
            if (!data.validationPass) {
                notificationService.error(lang.COMPANY_INFO, data.reasonText);
            }

            return data.validationPass;
        })
            .catch((err: BaseFailedRequestResponse) => {
                err && console.error(err);

                return false;
            });
    }

    return false;
};

const TenantInformation = (wizardStepData: WizardStepData<VbrDeploySettingsWizardModel>) => {
    const { data, onDataChange, validationState } = wizardStepData;
    const lang = useLang();

    return (
        <Form
            value={data}
            validate={formValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={lang.COMPANY_INFO}
                description={`${lang.SPECIFY_THE_COMPANY_USER_INFO}.`}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                    <FormLayout inlineLabel>
                        <TextInput
                            disabled={!data.isVcdTenant}
                            name='tenantName'
                            label={lang.USERNAME}
                        />

                        <PasswordInput
                            name='tenantPassword'
                            label={lang.PASSWORD}
                        />
                    </FormLayout>

                    {data.isVcdTenant && (
                        <NoteBar status={NOTEBAR_STATUS.info}>
                            <Text>{lang.SPECIFY_ADMIN_ACCOUNT_FROM_VMWARE_CLOUD}</Text>
                        </NoteBar>
                    )}
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getTenantInformationStep = (
    title: string,
    lang: LangsServiceBase,
    appServices: AppServices
): WizardStep<VbrDeploySettingsWizardModel> => ({
    title,
    isHidden: ({ data }) => data.vbrDeployType === VbrDeployType.Upgrade || data.isInfrastructureAgent === true,
    validate: ({ data }) => tenantInformationStepValidate(data, lang, appServices),
    render: data => <TenantInformation {...data} />,
});
