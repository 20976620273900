/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Combobox,
    CONTROL_SIZE,
    Form,
    FormLayout,
    FormValidator,
    immediatelyValidateFlag,
    isValid,
    TextInput,
} from '@veeam-vspc/components';

import type {
    WizardStep,
} from '@veeam-vspc/components';
import type { WizardStepData } from '@veeam-vspc/components/src/index';

import { UserTitle } from 'views/pages/RolesAndUsersPage/components/Base/components/UserInfo/user-title';
import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { UserInfoModel } from './user-info-model';

export const stepValidate = (data: UserInfoModel) => {
    const validator = new FormValidator(data);

    if (data.firstName) {
        validator.validate('firstName')
            .string()
            .maxLength(128)
            .check((value) => {
                if (typeof RCOP.utils.Validator.vacFirstAndLastName(value) === 'string') {
                    return `${RCOP.Lang.INVALID_CHARACTER}${immediatelyValidateFlag}`;
                }
            });
    }

    if (data.lastName) {
        validator.validate('lastName')
            .string()
            .maxLength(128)
            .check((value) => {
                if (typeof RCOP.utils.Validator.vacFirstAndLastName(value) === 'string') {
                    return `${RCOP.Lang.INVALID_CHARACTER}${immediatelyValidateFlag}`;
                }
            });
    }

    if (data.email) {
        validator.validate('email')
            .string()
            .maxLength(128)
            .match(RCOP.utils.Validator.EMAIL_REG_EXP, RCOP.Lang.THIS_FIELD_SHOULD_BE_AN_EMAIL_ADDRESS); // TODO: change it when our form api updates
    }

    return validator.result();
};

export const UserInfo = ({ data, onDataChange, validationState }: WizardStepData<UserInfoModel>) => {
    const lang = useLang();

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={lang.USER_INFO}
                description={lang.ENTER_USERNAME_AND_CONTACT_INFORMATION}
            >
                <FormLayout inlineLabel>
                    <Combobox
                        name='userTitle'
                        data={[
                            { value: UserTitle.Mr, text: lang.MR },
                            { value: UserTitle.Miss, text: lang.MISS },
                            { value: UserTitle.Mrs, text: lang.MRS },
                            { value: UserTitle.Ms, text: lang.MS },
                        ]}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        size={CONTROL_SIZE.m}
                        label={lang.TITLE}
                    />

                    <TextInput
                        name='firstName'
                        label={lang.FIRST_NAME}
                    />

                    <TextInput
                        name='lastName'
                        label={lang.LAST_NAME}
                    />

                    <TextInput
                        name='email'
                        label={lang.EMAIL_ADDRESS}
                    />
                </FormLayout>
            </StepLayout>
        </Form>
    );
};

export const getUserInfoStep = (title: string): WizardStep<UserInfoModel> => ({
    title,
    validate: ({ data }) => isValid(stepValidate, data),
    render: data => <UserInfo {...data} />,
});
