/**
 * Copyright © Veeam Software Group GmbH.
 */

import { prepareUrl } from '@veeam-vspc/core';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { core } from 'core/core-module';

import type {
    FlrAvailability,
    FlrAvailabilityParams,
    FlrRedirectParams,
    FlrRedirectToken,
    OpenFlrParams,
} from '../interfaces';

export class ComputersService {

    static async openFlr({ agentId, tabUrl, userId, vspcVersion }: OpenFlrParams): Promise<void> {
        core.transportService.request<FlrAvailabilityParams, FlrAvailability>('/FileLevelRestore/IsFlrAvailable', { agentId })
            .then((response: BaseSuccessRequestResponse<FlrAvailability>) => {
                const data = response.data as FlrAvailability;

                if (data.flrAvailable === false) {
                    core.notificationService.error(RCOP.Lang.ERROR, data.message);

                    return;
                }

                core.transportService
                    .request<FlrRedirectParams, FlrRedirectToken>('/FileLevelRestore/GetRedirectToken', { agentId })
                    .then((response: BaseSuccessRequestResponse<FlrRedirectToken>) => {
                        const url = new URL(prepareUrl((response.data as FlrRedirectToken)?.redirectUrl));

                        url.searchParams.append('code', (response.data as FlrRedirectToken)?.authCode);
                        url.searchParams.append('agentId', agentId);
                        url.searchParams.append('userId', userId);
                        url.searchParams.append('vspcVersion', vspcVersion);

                        if (tabUrl && typeof tabUrl === 'string') {
                            url.hash = tabUrl;
                        }

                        window.open(url.href, '_blank', 'noopener,noreferrer');
                    });
            });
    }
}
