/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import type React from 'react';

import { useAppServices, useAppStore } from '../AppProvider/hooks';

export interface GlobalFiltersProviderProps {
    children: React.ReactElement;
}

export const GlobalFiltersProvider = observer(({ children }: GlobalFiltersProviderProps) => {
    const { portalUser, globalFilters } = useAppStore();
    const { globalFiltersService } = useAppServices();

    useEffect(() => {
        globalFiltersService.setPortalUser(portalUser);
    }, [globalFiltersService, portalUser]);

    useEffect(() => {
        globalFiltersService.updateFilters(globalFilters);
    }, [globalFiltersService, globalFilters]);

    return children;
});
