/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ColumnTexts, isDate } from '@veeam-vspc/components';
import { plural, TimeUnitsInMs, formatStr } from '@veeam-vspc/core';

const transformTimeToAgoString = (value: Date | string, lang: Record<string, string>): string => {
    if (!(value instanceof Date)) {
        value = new Date(Date.parse(value as string));
    }

    if (!isDate(value) || Number.isNaN((value as Date).getTime()) || value <= new Date(0)) {
        return ColumnTexts.DashSymbol;
    }

    const now = new Date();

    if (value > now) {
        return formatStr(lang.AGO, `0 ${lang.MINUTES.toLowerCase()}`);
    }

    const inWeek = TimeUnitsInMs.Day * 7;
    const stampValue = (value as Date).getTime();
    const stampNow = Date.now();
    const delta = stampNow - stampValue;
    const floorAndFormat = (valueToFloorAndFormat, base, unitName) => formatStr(lang.AGO, plural(Math.floor(valueToFloorAndFormat / base), unitName));

    if (delta >= inWeek) {
        return floorAndFormat(delta, inWeek, lang.WEEK.toLowerCase());
    }

    if (delta >= TimeUnitsInMs.Day) {
        return floorAndFormat(delta, TimeUnitsInMs.Day, lang.DAY.toLowerCase());
    }

    if (delta >= TimeUnitsInMs.Hour) {
        return floorAndFormat(delta, TimeUnitsInMs.Hour, lang.HOUR.toLowerCase());
    }

    if (delta >= TimeUnitsInMs.Minute) {
        return floorAndFormat(delta, TimeUnitsInMs.Minute, lang.MINUTE.toLowerCase());
    }

    return floorAndFormat(delta, TimeUnitsInMs.Second, lang.SECOND.toLowerCase());
};

export {
    transformTimeToAgoString,
};
