/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/components';

export const EmptyPlateStyled = styled.div`
    align-items: center;
    background-color: ${colors.G100};
    display: flex;
    height: 100%;
    justify-content: center;
    justify-items: center;
    padding-bottom: ${constants.SPACING_XL};
    width: 100%;
`;
