/**
 * Copyright © Veeam Software Group GmbH.
 */

export class EventEmitter {

    private subscriptions: Record<string, Array<{ context: Object; callback: (...args: any[]) => void; }>> = {};

    dispatch(eventName: string, ...args: any[]): void {
        this.subscriptions[eventName]?.forEach(sub => sub.callback(...args));
    }

    subscribe(eventName: string, callback: (...args: any[]) => void, context: Object = this): () => void {
        if (!this.subscriptions[eventName]) {
            this.subscriptions[eventName] = [];
        }

        this.subscriptions[eventName].push({
            context,
            callback,
        });

        return () => {
            this.unsubscribe(eventName, context);
        };
    }

    unsubscribe(eventName: string, context: Object = this): void {
        this.subscriptions[eventName] = this.subscriptions[eventName]?.filter(sub => sub.context !== context) || [];

        if (this.subscriptions[eventName].length === 0) {
            delete this.subscriptions[eventName];
        }
    }

}
