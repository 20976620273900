/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TOOLBAR_ITEM_TYPE, toolbarItemDecorators, useGridApi } from '@veeam-vspc/components';

import flrActionIconSrc from 'images/actions/flr.png';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices, useAppStore } from 'views/providers/AppProvider/hooks';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { ComputersService } from '../../../../services';
import { ConfigSectionIds } from 'core/services/configurator/enums';
import { ComputersActionIds } from 'core/enums';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';
import type { ProtectedComputerItem, ProtectedComputersFilterModel } from '../../../../interfaces';

export interface ComputersActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items'> {
    method?: string;
}

export const ComputersActionToolbar: React.FC<ComputersActionToolbarProps> = (props) => {
    const { method = 'GetComputers', selectedOnly = true, ...restProps } = props;
    const lang = useLang();
    const { notificationService } = useAppServices();
    const gridApi = useGridApi<ProtectedComputerItem, any, ProtectedComputersFilterModel>();
    const { portalUser, version } = useAppStore();

    const actions = [
        {
            id: ComputersActionIds.FileLevelRestorePortalTBD,
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: flrActionIconSrc,
            text: lang.FILE_LEVEL_RESTORE_PORTAL,
            onClick: () => {
                const { agentId } = gridApi.selected.length > 0 ? gridApi.selected[0] : {} as ProtectedComputerItem;

                ComputersService.openFlr({
                    agentId,
                    userId: portalUser.instanceUid,
                    vspcVersion: version,
                })
                    .then(() => gridApi.reloadGrid())
                    .catch(resp => notificationService.multi(lang.FILE_LEVEL_RESTORE_PORTAL, resp));
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
    ];

    return (
        <ToolbarWithExport
            {...restProps}
            method={method}
            selectedOnly={selectedOnly}
            sectionId={ConfigSectionIds.ProtectedDataComputers}
            items={actions}
        />
    );
};
