/**
 * Copyright © Veeam Software Group GmbH.
 */

import { TextColumn } from '@veeam-vspc/components';
import React from 'react';

import type { GridColumnProps } from '@veeam-vspc/components';

import type { WanAcceleratorSupportedResource } from 'core/interfaces';
import type { CompanyWizardStore } from '../../../stores/company-wizard-store';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const getWanAcceleratorColumn = (
    wizardStore: CompanyWizardStore,
    lang: LangsServiceBase
): GridColumnProps<WanAcceleratorSupportedResource> => ({
    field: 'wanAcceleratorUid',
    title: lang.WAN_ACCELERATOR,
    cell: ({ rowData }) => {
        const enabled = rowData.isWanAccelerationEnabled;
        const wanUid = rowData.wanAcceleratorUid;

        const result = !enabled || !wanUid
            ? lang.NOT_AVAILABLE
            : wizardStore.wanAcceleratorsCache.value
                ?.find(item => item.cloudConnectResourceUid === wanUid)?.cloudConnectResourceName;

        return (<TextColumn>{result}</TextColumn>);
    },
    sorterFn: (rowData1: WanAcceleratorSupportedResource, rowData2: WanAcceleratorSupportedResource) => {
        const enabled1 = rowData1.isWanAccelerationEnabled;
        const wanUid1 = rowData1.wanAcceleratorUid;
        const enabled2 = rowData2.isWanAccelerationEnabled;
        const wanUid2 = rowData2.wanAcceleratorUid;

        if (!enabled1 || !wanUid1) {
            return 1;
        }
        if (!enabled2 || !wanUid2) {
            return -1;
        }

        const name1 = wizardStore.wanAcceleratorsCache.value
            ?.find(item => item.cloudConnectResourceUid === wanUid1)?.cloudConnectResourceName;
        const name2 = wizardStore.wanAcceleratorsCache.value
            ?.find(item => item.cloudConnectResourceUid === wanUid2)?.cloudConnectResourceName;

        if (name1 < name2) {
            return 1;
        } else {
            return -1;
        }
    },
});
