/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    Form,
    FormLayout,
    isValid,
    FormValidator,
    FormTitle,
} from '@veeam-vspc/components';
import { capitalize, FileSizeUnitsInBytes, formatStr } from '@veeam-vspc/core';

import type { WizardStepData } from '@veeam-vspc/components';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { PerMonthField } from 'components/controls/PerMonthField';
import { DataSizeInBytes } from 'components/controls/DataSizeInBytes';

import type { SubscriptionPlanModel } from 'core/interfaces';

export const panelValidate = (data: SubscriptionPlanModel, maxErrorTextTemplate = '-') => {
    const validator = new FormValidator(data);
    const gbError = `${formatStr(maxErrorTextTemplate, 1024 * 1024)}!immediately`;
    const tbError = `${formatStr(maxErrorTextTemplate, 1024)}!immediately`;
    const getMaxErrorText = (propName: string) => {
        const val = data[propName] as number;
        if (val && val % FileSizeUnitsInBytes.TB === 0) {
            return tbError;
        }

        return gbError;
    };

    validator
        .validate('vbM365ProtectedUserFree')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('vbM365BackupStorageSpaceFree')
        .number()
        .min(0)
        .max(FileSizeUnitsInBytes.TB * 1024, getMaxErrorText('vbM365BackupStorageSpaceFree'));
    validator
        .validate('vbM365ArchiveStorageSpaceFree')
        .number()
        .min(0)
        .max(FileSizeUnitsInBytes.TB * 1024, getMaxErrorText('vbM365ArchiveStorageSpaceFree'));

    return validator.result();
};

interface Props extends WizardStepData<SubscriptionPlanModel> {
    hide: () => void;
}

export const Microsoft365Advanced = ({ hide, onDataChange, validationState, data }: Props) => {
    const lang = useLang();
    const [localData] = useState<SubscriptionPlanModel>(() => Object.assign({}, data));

    return (
        <SidePanelForm
            onRequestClose={hide}
            title={capitalize(lang.ADVANCED_SETTINGS)}
            description={lang.SPECIFY_THE_NUMBER_OF_USERS}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        if (isValid(panelValidate, localData)) {
                            Object.assign(data, localData);
                            onDataChange(data);
                            hide();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hide,
                },
            ]}
        >
            <Form
                value={localData}
                validationState={validationState}
                validate={data => panelValidate(data, lang.MAX_VALUE_IS)}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.FREE_OF_CHARGE}</FormTitle>

                    <PerMonthField
                        name='vbM365ProtectedUserFree'
                        label={lang.PROTECTED_USERS}
                        allowDecimal={false}
                    />

                    <DataSizeInBytes
                        name={'vbM365BackupStorageSpaceFree'}
                        label={`${lang.STANDARD_STORAGE}:`}
                        allowDecimal={false}
                    />

                    <DataSizeInBytes
                        name={'vbM365ArchiveStorageSpaceFree'}
                        label={`${lang.ARCHIVE_STORAGE}:`}
                        allowDecimal={false}
                    />
                </FormLayout>
            </Form>
        </SidePanelForm>
    );
};
