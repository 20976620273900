/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts, isPositiveNumber } from '@veeam-vspc/components';

import { CloudDatabasesDialog } from './components/CloudDatabasesDialog/CloudDatabasesDialog';

import type { CloudDatabasesDialogProps } from './components/CloudDatabasesDialog/CloudDatabasesDialog';

export interface CloudDatabasesColumnProps extends Omit<CloudDatabasesDialogProps, 'deactivate'> {
    value: string | number | null;
}

export const CloudDatabasesColumn: React.FC<CloudDatabasesColumnProps> = ({ value, ...restProps }) => (
    <ActionLinkColumn
        content={isPositiveNumber(value) ? value : ColumnTexts.DashSymbol}
        isLink={isPositiveNumber(value)}
        isExtMode={true}
        alignRight={true}
        renderModal={({ deactivate }) => (
            <CloudDatabasesDialog
                {...restProps}
                deactivate={deactivate}
            />
        )}
    />
);

