/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo, useRef } from 'react';
import { Icon, ICON_SIZES, StackView, STACK_ALIGN, STACK_GAP, Text, TEXT_SIZE } from '@veeam-vspc/components';
import { CloudConnectWorkloads, ProtectedDataFilterBy } from '@veeam-vspc/models/web-controllers';
import { observer } from 'mobx-react-lite';

import type { BaseProtectedDataWorkloadOverviewCloudConnectWorkloads, ProtectedDataOverviewRequest } from '@veeam-vspc/models/web-controllers';

import { TableWidget } from 'views/components/TableWidget';
import { NameColumn } from 'views/components/TableWidget/components/NameColumn';
import successIcon from 'images/success.svg';
import serverIcon from 'images/pages/protected-data-summary/cc-server.svg';
import vmIcon from 'images/pages/protected-data-summary/cc-vm.svg';
import workstationIcon from 'images/pages/protected-data-summary/cc-workstation.svg';
import vmReplicaIcon from 'images/pages/protected-data-summary/cc-vm-replica.svg';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useIntervalRequest } from 'views/hooks';
import { EmptyColumnPlaceholder } from 'views/components/TableWidget/components/EmptyColumnPlaceholder';
import { NoInfoColumn } from 'views/components/TableWidget/components/NoInfoColumn';
import cloudConnectedNotConnected from 'images/cloud-connect-not-connected.svg';
import { useProtectedDataSummaryStore } from '../../store';

const icons = {
    [CloudConnectWorkloads.Replications]: vmReplicaIcon,
    [CloudConnectWorkloads.Servers]: serverIcon,
    [CloudConnectWorkloads.VirtualMachines]: vmIcon,
    [CloudConnectWorkloads.Workstations]: workstationIcon,
};

export const CloudConnect: React.FC = observer(() => {
    const store = useProtectedDataSummaryStore();
    const lang = useLang();
    const didMount = useRef(false);
    const abortController = useRef(new AbortController());

    const names = useMemo(() => ({
        [CloudConnectWorkloads.Replications]: lang.VM_REPLICAS,
        [CloudConnectWorkloads.Servers]: lang.SERVER_BACKUPS,
        [CloudConnectWorkloads.VirtualMachines]: lang.VM_BACKUPS,
        [CloudConnectWorkloads.Workstations]: lang.WORKSTATION_BACKUPS,
    }), []);

    const request = useIntervalRequest<ProtectedDataOverviewRequest, BaseProtectedDataWorkloadOverviewCloudConnectWorkloads[]>(
        '/ProtectedOverview/CloudConnect', {
            rpoDays: store.rpoDays,
            filterType: ProtectedDataFilterBy.Rpo,
        },
        {
            signal: abortController.current.signal,
        });

    useEffect(() => {
        if (didMount.current) {
            abortController.current.abort();

            abortController.current = new AbortController();
            return request.forceRequest();
        }

        didMount.current = true;
    }, [store.rpoDays]);

    const items = request.isError || !Array.isArray(request.data)
        ? []
        : (request.data as BaseProtectedDataWorkloadOverviewCloudConnectWorkloads[]);

    return (
        <TableWidget
            data={items}
            columns={[
                {
                    name: lang.VEEAM_CLOUD_CONNECT,
                    cellRenderer: ({ rowData, key }) => (
                        <NameColumn
                            icon={icons[rowData.workloadType]}
                            name={names[rowData.workloadType]}
                            key={key}
                        />
                    ),
                },
                {
                    cellRenderer: ({ key }) => <EmptyColumnPlaceholder key={key} />,
                },
                {
                    cellRenderer: ({ rowData, key }) => {
                        const protectedCount = rowData.protectionData.reduce((acc, item) => acc + item.protectedCount.value, 0);
                        const overallCount = rowData.protectionData.reduce((acc, item) => acc + item.overallCount, 0);

                        if (overallCount === 0) {
                            return (
                                <StackView
                                    align={STACK_ALIGN.center}
                                    gap={STACK_GAP.s}
                                    key={key}
                                >
                                    <NoInfoColumn />
                                </StackView>
                            );
                        }

                        if (protectedCount !== overallCount) {
                            return (
                                <StackView
                                    align={STACK_ALIGN.end}
                                    gap={STACK_GAP.s}
                                    key={key}
                                >
                                    <Text size={TEXT_SIZE.xl}>{protectedCount}</Text>
                                    <Text size={TEXT_SIZE.l}>of</Text>
                                    <Text size={TEXT_SIZE.xl}>{overallCount}</Text>
                                </StackView>
                            );
                        }

                        return (
                            <StackView
                                align={STACK_ALIGN.center}
                                gap={STACK_GAP.s}
                                key={key}
                            >
                                <Icon src={successIcon} size={ICON_SIZES.xl} />
                                <Text size={TEXT_SIZE.xl}>{overallCount}</Text>
                            </StackView>
                        );
                    },
                },
            ]}
            loading={request.loading}
            noDataConfig={{
                linkText: lang.START_USING_VEEAM_CLOUD_CONNECT,
                icon: cloudConnectedNotConnected,
                linkHandler: () => window.location.assign('#config/cloudConnectServers'),
            }}
        />
    );
});
