/**
 * Copyright © Veeam Software Group GmbH.
 */

import 'mobx-react-lite/batchingForReactDom';

import { core } from './core/core-module';
import { ReactToExtService } from './core/services/react-to-ext';
import { renderDevPanel } from 'components/controls/DevPanel';

(window as any).reactToExtService = new ReactToExtService(core);

if (process.env.NODE_ENV === 'development') {
    renderDevPanel();
}
