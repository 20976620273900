/**
 * Copyright © Veeam Software Group GmbH.
 */

import { addParamsToQuery } from '@veeam-vspc/core';
import { themes } from '@veeam-vspc/components';

import type { BaseSuccessRequestResponse, TransportService } from '@veeam-vspc/core';
import type { IdentityProviderSettings } from '@veeam-vspc/models/rest';

import { PortalData } from '../../entries';

import type {
    LoginData,
    LoginResponse,
    PortalDataRequestOptions,
    PortalFeatureResponse,
    PortalServiceParams,
    EnumDescriptorsMap,
} from './interfaces';
import type { PortalDataParams } from '../../entries/portal-data/interfaces';


export class PortalService {

    protected static instance: PortalService;

    protected readonly portalDataUrl: string;
    protected readonly enabledFeaturesUrl: string | void;
    protected readonly identityProvidersUrl: string | void;
    protected readonly enumDescriptorsUrl: string;

    protected portalData: PortalData | null = null;

    constructor(
        protected readonly transportService: TransportService,
        { portalDataUrl, enabledFeaturesUrl, identityProvidersUrl, enumDescriptorsUrl }: PortalServiceParams
    ) {
        if (!PortalService.instance) {
            PortalService.instance = this;

            this.portalDataUrl = portalDataUrl;
            this.enabledFeaturesUrl = enabledFeaturesUrl;
            this.identityProvidersUrl = identityProvidersUrl;
            this.enumDescriptorsUrl = enumDescriptorsUrl;
        }

        return PortalService.instance;
    }

    getData(requestOptions: PortalDataRequestOptions = {}): Promise<PortalData> {
        return this.getAllPortalData(requestOptions)
            .then(([loginData, enumDescriptors, identityProviders, featuresList]) => this.savePortalData({
                ...((loginData as BaseSuccessRequestResponse<LoginData>).data as LoginData),
                identityProviders,
                featuresList,
                enumDescriptors,
            }));
    }

    getPortalData(): PortalData | null {
        return this.portalData;
    }

    getProductTheme(): themes.Theme {
        const { theme } = this.portalData;
        const vspcThemesMap = {
            blue: themes.blue,
            green: themes.green,
            yellow: themes.yellow,
            turquoise: themes.teal,
            red: themes.red,
        };

        return vspcThemesMap[theme];
    }

    protected getAllPortalData(
        { noAuth = false, brandingUrl }: PortalDataRequestOptions
    ): Promise<[LoginResponse, EnumDescriptorsMap, IdentityProviderSettings[] | null, PortalFeatureResponse[] | null]> {
        return Promise.all([

            this.transportService.request<void, LoginData>(this.portalDataUrl, null, null, { noAuth }),
            this.getEnumDescriptors(this.enumDescriptorsUrl),
            this.getIdentityProviders(noAuth),
            this.getEnabledFeatures(noAuth, brandingUrl),
        ]);
    }

    protected getIdentityProviders(noAuth: boolean): Promise<IdentityProviderSettings[] | null> {
        return (noAuth && this.identityProvidersUrl)
            ? this.transportService.get<void, IdentityProviderSettings[]>(this.identityProvidersUrl, null, null, { noAuth })
                .then((resp: BaseSuccessRequestResponse<IdentityProviderSettings[]>) => resp.data)
            : Promise.resolve(null);
    }

    protected getEnabledFeatures(noAuth: boolean, brandingUrl?: string): Promise<PortalFeatureResponse[] | null> {
        const url = this.enabledFeaturesUrl
            ? addParamsToQuery(this.enabledFeaturesUrl, brandingUrl ? { brandingUrl } : {})
            : null;

        return url && !noAuth
            ? this.transportService.get<void, PortalFeatureResponse[]>(url, null, null, { noAuth: true })
                .then((resp: BaseSuccessRequestResponse<PortalFeatureResponse[]>) => resp.data)
            : Promise.resolve(null);
    }

    protected getEnumDescriptors(enumDescriptorsUrl: string): Promise<EnumDescriptorsMap> {
        return this.transportService
            .request<void, EnumDescriptorsMap>(enumDescriptorsUrl, null, null, { noAuth: true })
            .then((resp: BaseSuccessRequestResponse<EnumDescriptorsMap>) => {
                const successResp = resp;
                return successResp.data as EnumDescriptorsMap;
            });
    }

    protected savePortalData(loginData: PortalDataParams): PortalData {
        this.setPortalData(new PortalData(loginData));

        return this.getPortalData();
    }

    protected setPortalData(portalData: PortalData): void {
        this.portalData = portalData;
    }

}
