/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Vb365OrganizationBase } from '@veeam-vspc/models/rest';

import type { ConfigurableOption } from '../interfaces';

export const getUsersProcessingOptions = (protectedServices: Vb365OrganizationBase.ProtectedServicesEnum[]): ConfigurableOption[] => {
    const options: ConfigurableOption[] = [];

    if (protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.ExchangeOnline)) {
        options.push(
            'backupMailbox',
            'backupArchiveMailbox',
        );
    }

    if (protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.SharePointOnlineAndOneDriveForBusiness)) {
        options.push(
            'backupOneDrive',
            'backupPersonalSite',
        );
    }

    return options;
};

export const getPublicMailboxUsersProcessingOptions =
    (protectedServices: Vb365OrganizationBase.ProtectedServicesEnum[]): ConfigurableOption[] => {
        const options: ConfigurableOption[] = [];

        if (protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.ExchangeOnline)) {
            options.push(
                'backupMailbox',
            );
        }

        return options;
    };

export const getGroupsProcessingOptions = (protectedServices: Vb365OrganizationBase.ProtectedServicesEnum[]): ConfigurableOption[] => {
    const options: ConfigurableOption[] = [
        'backupMembers',
    ];

    if (protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.ExchangeOnline)) {
        options.push(
            'backupMemberMailbox',
            'backupMemberArchiveMailbox',
        );
    }

    if (protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.SharePointOnlineAndOneDriveForBusiness)) {
        options.push(
            'backupMemberOneDrive',
            'backupMemberSite',
        );
    }

    return options;
};

export const getOffice365GroupProcessingOptions = (protectedServices: Vb365OrganizationBase.ProtectedServicesEnum[]): ConfigurableOption[] => {
    const options: ConfigurableOption[] = [
        'backupMembers',
    ];

    if (protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.ExchangeOnline)) {
        options.push(
            'backupMemberMailbox',
            'backupMemberArchiveMailbox',
            'backupMailbox',
        );
    }

    if (protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.SharePointOnlineAndOneDriveForBusiness)) {
        options.push(
            'backupMemberOneDrive',
            'backupMemberSite',
            'backupGroupSite',
        );
    }

    return options;
};

export const sitesProcessingOptions: ConfigurableOption[] = [];
export const teamsProcessingOptions: ConfigurableOption[] = ['backupTeamsChats'];
export const personalSitesProcessingOptions: ConfigurableOption[] = [];

export const getOrganizationProcessingOptions = (protectedServices: Vb365OrganizationBase.ProtectedServicesEnum[]): ConfigurableOption[] => {
    const options: ConfigurableOption[] = [];

    if (protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.ExchangeOnline)) {
        options.push(
            'backupMailbox',
            'backupArchiveMailbox',
        );
    }

    if (protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.SharePointOnlineAndOneDriveForBusiness)) {
        options.push(
            'backupOneDrive',
            'backupSites',
        );
    }

    if (protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.MicrosoftTeams)) {
        options.push('backupTeams');
        options.push('backupTeamsChats');
    }

    return options;
};
