/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify, GridSortDirections, TextColumn } from '@veeam-vspc/components';

import type { CellRendererProps, RequestParams } from '@veeam-vspc/components';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { TimeAgoColumn, transformTimeToAgoString } from 'views/components/columns/TimeAgoColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { CustomNoteColumn } from 'views/components/columns/CustomNoteColumn';
import { JobKinds } from 'views/pages/BackupJobsPage/enums';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { ConfigSectionIds } from 'core/services/configurator/enums';
import { BackupJobsColumn } from '../BackupJobsColumn';
import { FlrStatusColumn } from '../FlrStatusColumn';
import { ComputersActionToolbar, ComputersFilterToolbar } from './components';

import type { ProtectedComputerItem, ProtectedComputersFilterModel } from '../../interfaces';

export interface ManagedByConsoleProps {
    stateId: string;
}

export const ManagedByConsole: React.FC<ManagedByConsoleProps> = ({ stateId }) => {
    const { transportService } = useAppServices();
    const lang = useLang();
    const gridStateId = `${stateId}.ManagedByConsole`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<ProtectedComputerItem>) => (
        <TextColumn defaultValue={lang.NO_INFO}>{cellData}</TextColumn>
    );
    const columns = [
        {
            field: 'name',
            title: lang.NAME,
            cell: defaultColumnRender,
        },
        {
            field: 'agentCustomNote',
            title: lang.TAG,
            cell: ({ cellData, rowData }: CellRendererProps<ProtectedComputerItem>) => (
                <CustomNoteColumn
                    value={cellData}
                    agentUid={rowData.agentId}
                    itemParams={{
                        managementMode: rowData.managementMode,
                    }}
                />
            ),
        },
        {
            field: 'totalJobsCount',
            title: lang.BACKUPS,
            cell: ({ rowData }: CellRendererProps<ProtectedComputerItem>) => (
                <BackupJobsColumn
                    stateId={`${gridStateId}.Dialog.BackupJobs`}
                    value={rowData.totalJobsCount}
                    title={`${lang.BACKUPS} - ${rowData.name}`}
                    instanceUid={rowData.instanceUid}
                    systemType={rowData.systemType}
                    jobKind={JobKinds.BackupJob}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'totalCopyJobsCount',
            title: lang.BACKUP_COPIES,
            cell: ({ rowData }: CellRendererProps<ProtectedComputerItem>) => (
                <BackupJobsColumn
                    stateId={`${gridStateId}.Dialog.BackupCopyJobs`}
                    value={rowData.totalCopyJobsCount}
                    title={`${lang.BACKUP_COPIES} - ${rowData.name}`}
                    instanceUid={rowData.instanceUid}
                    systemType={rowData.systemType}
                    jobKind={JobKinds.CopyJob}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'guestOs',
            title: lang.GUEST_OS,
            cell: defaultColumnRender,
        },
        {
            field: 'lastRestorePoint',
            title: lang.LATEST_RESTORE_POINT,
            cell: ({ cellData }: CellRendererProps<ProtectedComputerItem>) => (
                <TimeAgoColumn
                    defaultValue={lang.NO_INFO}
                    value={cellData}
                />
            ),
            width: 180,
        },
        {
            field: 'lastCloudRestorePoint',
            title: lang.CLOUD_COPY,
            cell: ({ cellData, rowData }: CellRendererProps<ProtectedComputerItem>) => {
                if (!rowData.cloudCopy) {
                    return <TextColumn defaultValue={lang.NO_INFO}>{lang.NO}</TextColumn>;
                } else if (!cellData) {
                    return <TextColumn>{lang.YES}</TextColumn>;
                }

                return (
                    <TextColumn>
                        {`${lang.YES} (${transformTimeToAgoString(cellData, lang)})`}
                    </TextColumn>
                );
            },
        },
        {
            field: 'companyName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'lastHeartbeat',
            title: lang.LAST_HEARTBEAT,
            cell: ({ cellData }: CellRendererProps<ProtectedComputerItem>) => (
                <TimeAgoColumn
                    defaultValue={lang.NO_INFO}
                    value={cellData}
                />
            ),
        },
        {
            field: 'flrStatusInfo.lastRestore',
            title: lang.LAST_RESTORE,
            cell: ({ rowData }: CellRendererProps<ProtectedComputerItem>) => (
                <GridDateColumn
                    defaultValue={lang.NO_INFO}
                    value={rowData.flrStatusInfo?.lastRestore}
                />
            ),
        },
        {
            field: 'flrStatusInfo.status',
            title: lang.RESTORE_STATUS,
            cell: ({ rowData }: CellRendererProps<ProtectedComputerItem>) => (
                <FlrStatusColumn
                    agentId={rowData.agentId}
                    name={rowData.name}
                    systemType={rowData.systemType}
                    flrStatusInfo={rowData.flrStatusInfo}
                />
            ),
            width: 150,
        },
        {
            field: 'operationModeRendered',
            title: lang.OPERATION_MODE,
            cell: defaultColumnRender,
            hidden: true,
        },
    ];

    return (
        <AdvancedGrid
            sectionId={ConfigSectionIds.ProtectedDataComputers}
            stateId={gridStateId}
            defaultColumnWidth={80}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.field,
                },
            }}
            columns={columns.filter(column => column)}
            data={(requestParams: RequestParams<ProtectedComputersFilterModel>) => transportService
                .request<typeof requestParams, ProtectedComputerItem[]>('/ProtectedWorkloads/GetComputers', { ...requestParams })}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <ComputersFilterToolbar />,
                () => <ComputersActionToolbar idProperty={'instanceUid'} />,
            ]}
        />
    );
};
