/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/core';

import { CloudStorageSpaceMode, SpaceSizeUnit } from 'core/enums';
import { removeEmptyProperties } from 'core/utils';

import type { SubscriptionPlanModel } from 'core/interfaces';

export class SubscriptionPlanWizardStore {
    private readonly initialModel: SubscriptionPlanModel;
    readonly defaultModel: SubscriptionPlanModel = {
        name: '',
        description: '',
        companyName: '',

        currency: '',
        vatAlias: null,
        vat: 0,
        discount: 0,

        managedServicesPrice: 0,
        monitoredServicesPrice: 0,

        managedVmPrice: 0,
        managedCdpVmPrice: 0,
        managedCloudVmPrice: 0,
        managedWorkstationPrice: 0,
        managedServerPrice: 0,
        managedVmFree: 0,
        managedCloudVmFree: 0,
        managedCdpVmFree: 0,
        workstationAgentFree: 0,
        serverAgentFree: 0,
        managedAgentDesktopWindowsExtraPrice: 0,
        managedAgentServerWindowsExtraPrice: 0,
        managedAgentLinuxExtraPrice: 0,
        managedAgentMacExtraPrice: 0,

        sourceAmountOfDataPrice: 0,
        sourceAmountOfDataMultiplier: SpaceSizeUnit.TB,
        nasShortTermUsedSpacePrice: 0,
        nasShortTermUsedSpaceMultiplier: SpaceSizeUnit.TB,
        nasLongTermUsedSpacePrice: 0,
        nasLongTermUsedSpaceMultiplier: SpaceSizeUnit.TB,
        sourceAmountOfDataFree: 0,
        nasShortTermUsedSpaceFree: 0,
        nasLongTermUsedSpaceFree: 0,

        cbVmPrice: 0,
        cbServerPrice: 0,
        cbWorkstationPrice: 0,
        cbVmTrafficPrice: 0,
        cbVmTrafficMultiplier: SpaceSizeUnit.GB,
        cbRepoCountAlgorithm: CloudStorageSpaceMode.AllocatedSpaceMode,
        cbRepoAllocatedSpacePrice: 0,
        cbRepoAllocatedSpaceMultiplier: SpaceSizeUnit.TB,
        cbVmRepoUsedSpacePrice: 0,
        cbVmRepoUsedSpaceMultiplier: SpaceSizeUnit.TB,
        cbVmRepoUsedSpaceFree: 0,
        cbPerformanceTierUsedSpacePrice: 0,
        cbPerformanceTierUsedSpaceMultiplier: SpaceSizeUnit.TB,
        cbCapacityTierUsedSpacePrice: 0,
        cbCapacityTierUsedSpaceMultiplier: SpaceSizeUnit.TB,
        cbArchiveTierUsedSpacePrice: 0,
        cbArchiveTierUsedSpaceMultiplier: SpaceSizeUnit.TB,
        cbInsiderProtectionUsedSpacePrice: 0,
        cbInsiderProtectionUsedSpaceMultiplier: SpaceSizeUnit.TB,

        vbM365ProtectedUserPrice: 0,
        vbM365ProtectedUserFree: 0,
        vbM365BackupStorageSpacePrice: 0,
        vbM365BackupStorageSpaceMultiplier: SpaceSizeUnit.TB,
        vbM365BackupStorageSpaceFree: 0,
        vbM365ArchiveStorageSpacePrice: 0,
        vbM365ArchiveStorageSpaceMultiplier: SpaceSizeUnit.TB,
        vbM365ArchiveStorageSpaceFree: 0,

        publicCloudFileSharePrice: 0,
        publicCloudFileShareFree: 0,
        publicCloudDatabasePrice: 0,
        publicCloudDatabaseFree: 0,
        publicCloudBackupSpacePrice: 0,
        publicCloudBackupSpaceMultiplier: SpaceSizeUnit.TB,
        publicCloudBackupSpaceFree: 0,
        publicCloudArchiveSpacePrice: 0,
        publicCloudArchiveSpaceMultiplier: SpaceSizeUnit.TB,
        publicCloudArchiveSpaceFree: 0,

        crVmPrice: 0,
        crCdpVmStoragePrice: 0,
        crCdpVmStorageMultiplier: SpaceSizeUnit.GB,
        crVmStoragePrice: 0,
        crVmStorageMultiplier: SpaceSizeUnit.TB,
        crVmComputeTimePrice: 0,
        crVmComputeTimeMultiplier: SpaceSizeUnit.GB,
        crVmTrafficPrice: 0,
        crVmTrafficMultiplier: SpaceSizeUnit.GB,
        crVmStorageFree: 0,
        crVmComputeTimeFree: 0,
        cRVmComputeTimeFreeMagnitude: 0,
    };

    constructor(model: SubscriptionPlanModel) {
        this.initialModel = deepCopy(model);
    }

    getEditModel(): SubscriptionPlanModel {
        return Object.assign(this.defaultModel, removeEmptyProperties(this.initialModel));
    }
}
