/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { ComboboxKit, FieldLayout, constants } from '@veeam-vspc/components';
import { WorkloadTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import { observer } from 'mobx-react-lite';

import { getWorkloadTypeName } from './helpers';
import { useJobsOverviewStore } from '../../../../store';
import { useLang } from 'views/providers/LangProvider/hooks';

export const WorkloadTypeFilter: React.FC = observer(() => {
    const jobsOverviewStore = useJobsOverviewStore();
    const lang = useLang();

    return (
        <FieldLayout label={`${lang.WORKLOAD_TYPE}:`}>
            <ComboboxKit
                size={constants.CONTROL_SIZE_XXM}
                onChange={jobsOverviewStore.changeWorkloadType}
                textGetter={item => item.name}
                value={jobsOverviewStore.workloadType}
                valueGetter={item => item.id}
                data={[
                    { id: WorkloadTypeRepresentation.Unknown, name: lang.ALL },
                    { id: WorkloadTypeRepresentation.Agent, name: getWorkloadTypeName(WorkloadTypeRepresentation.Agent) },
                    { id: WorkloadTypeRepresentation.Vm, name: getWorkloadTypeName(WorkloadTypeRepresentation.Vm) },
                    { id: WorkloadTypeRepresentation.CloudVM, name: getWorkloadTypeName(WorkloadTypeRepresentation.CloudVM) },
                    { id: WorkloadTypeRepresentation.CloudRds, name: getWorkloadTypeName(WorkloadTypeRepresentation.CloudRds) },
                    { id: WorkloadTypeRepresentation.CloudEfs, name: getWorkloadTypeName(WorkloadTypeRepresentation.CloudEfs) },
                    { id: WorkloadTypeRepresentation.Nas, name: getWorkloadTypeName(WorkloadTypeRepresentation.Nas) },
                    { id: WorkloadTypeRepresentation.Logs, name: getWorkloadTypeName(WorkloadTypeRepresentation.Logs) },
                    { id: WorkloadTypeRepresentation.FileCopy, name: getWorkloadTypeName(WorkloadTypeRepresentation.FileCopy) },
                    { id: WorkloadTypeRepresentation.VB365, name: getWorkloadTypeName(WorkloadTypeRepresentation.VB365) },
                ]}
            />
        </FieldLayout>
    );
});
