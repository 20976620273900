/**
 * Copyright © Veeam Software Group GmbH.
 */

export class LangsService<T extends {}> {

    protected locale: string;
    protected lang: T = {} as T;

    loadLangFor(locale: string): Promise<void> {
        // @TODO make upload lang for current locale
        if (this.locale !== locale) {
            return Promise.resolve(RCOP?.Lang)
                .then((lang: T) => this.setLang(lang, locale));
        }

        return Promise.resolve();
    }

    getLang(): T {
        return this.lang;
    }

    getLocale(): string {
        return this.locale;
    }

    protected setLang(lang: T, locale: string): void {
        this.locale = locale;
        Object.keys(lang).forEach((key: string) => this.lang[key] = lang[key]);
    }

}
