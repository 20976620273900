/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    GridColumnJustify,
    GridSortDirections,
    PageDialog,
    Search,
    TextColumn,
    TOOLBAR_ITEM_TYPE,
    TOOLBAR_VIEW_TYPE,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { CellRendererProps, PageDialogProps } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { ProtectedVirtualMachineRestoreTypes } from 'core/enums';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { RestorePointColumn } from 'views/pages/ProtectedDataPage/components/RestorePointColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { getDestinationColumnHeader, getDialogTitle, getJobNameColumnHeader, getSearchFieldPlaceholder } from '../../utils/title-helpers';
import { VirtualInfrastructureRestorePointColumn } from '../../../VirtualInfrastructureRestorePointColumn';
import { RestorePointColumnsFields } from 'views/pages/ProtectedDataPage/components/RestorePointColumn/components/RestorePointDialog';

import type {
    VirtualInfrastructureDialogModel,
    VirtualInfrastructureExtraParams,
    VirtualInfrastructureFilterModel,
    VirtualInfrastructureRequestParams,
} from '../../interfaces';

export interface VirtualInfrastructureDialogProps extends Omit<PageDialogProps, 'title' | 'content'> {
    stateId: string;
    name: string;
    extraParams: VirtualInfrastructureExtraParams;
    vmInstanceUid?: string;
}

export const VirtualInfrastructureDialog: React.FC<VirtualInfrastructureDialogProps> = (props) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const idProperty = 'backupUid';
    const {
        description,
        disabledBack,
        hasBack,
        footer,
        onClose,
        stateId,
        name,
        extraParams,
    } = props;
    const { protectionType } = extraParams;
    const defaultCellRenderer = ({ cellData }: CellRendererProps<VirtualInfrastructureDialogModel>) => <TextColumn>{cellData}</TextColumn>;
    const columns = [
        {
            field: 'jobName',
            title: getJobNameColumnHeader(protectionType, lang),
            cell: defaultCellRenderer,
        },
        {
            field: 'lastRun',
            title: lang.LAST_RUN,
            cell: ({ cellData }: CellRendererProps<VirtualInfrastructureDialogModel>) => (
                <TimeAgoColumn value={cellData} />
            ),
        },
        protectionType !== ProtectedVirtualMachineRestoreTypes.CdpReplica && {
            field: 'sourceSize',
            title: lang.SOURCE_SIZE,
            cell: ({ cellData }: CellRendererProps<VirtualInfrastructureDialogModel>) => <RestorePointSizeColumn value={cellData as any} />,
        },
        {
            field: 'restorePoints',
            title: lang.RESTORE_POINTS,
            cell: ({ cellData, rowData }: CellRendererProps<VirtualInfrastructureDialogModel>) => {
                const dialogStateId = `${stateId}.${ProtectedVirtualMachineRestoreTypes[protectionType]}`;
                const isReplicaType = protectionType === ProtectedVirtualMachineRestoreTypes.Replica ||
                    protectionType === ProtectedVirtualMachineRestoreTypes.CdpReplica;

                if (isReplicaType) {
                    return (
                        <RestorePointColumn
                            noteBarIsHidden
                            value={cellData}
                            stateId={dialogStateId}
                            name={rowData.jobName}
                            extraParams={{
                                vmInstanceUid: rowData.vmInstanceUid,
                                backupUid: rowData.backupUid,
                            }}
                            hiddenColumns={[
                                RestorePointColumnsFields.SourceSize,
                                RestorePointColumnsFields.BackedUpData,
                                RestorePointColumnsFields.RestorePointSize,
                            ]}
                        />
                    );
                }

                // @TODO transform to universal RestorePointColumn
                return (
                    <VirtualInfrastructureRestorePointColumn
                        value={cellData}
                        stateId={dialogStateId}
                        name={rowData.jobName}
                        protectionType={protectionType}
                        isOneBackupPerVmEnabled={rowData.isOneBackupPerVmEnabled}
                        extraParams={{
                            backupUid: rowData.backupUid,
                            vmInstanceUid: rowData.vmInstanceUid,
                        }}
                    />
                );
            },
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        ![ProtectedVirtualMachineRestoreTypes.Replica, ProtectedVirtualMachineRestoreTypes.CdpReplica].includes(protectionType) && {
            field: 'backupSize',
            title: lang.BACKUP_SIZE,
            cell: ({ cellData }: CellRendererProps<VirtualInfrastructureDialogModel>) => <RestorePointSizeColumn value={cellData as any} />,
        },
        {
            field: 'destination',
            title: getDestinationColumnHeader(protectionType, lang),
            cell: defaultCellRenderer,
        },
        {
            field: 'backupServer',
            title: capitalize(lang.BACKUP_SERVER),
            cell: defaultCellRenderer,
        },
    ];

    return (
        <PageDialog
            title={`${getDialogTitle(protectionType, lang)} - ${name}`}
            description={description}
            disabledBack={disabledBack}
            hasBack={hasBack}
            footer={footer}
            onClose={onClose}
            content={(
                <AdvancedGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: columns?.[0]?.field,
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={columns.filter(item => item)}
                    data={(requestParams: VirtualInfrastructureRequestParams<VirtualInfrastructureFilterModel>) => transportService
                        .request<typeof requestParams, VirtualInfrastructureDialogModel[]>(
                            '/ProtectedWorkloads/GetVmsRestorePoints',
                            {
                                ...requestParams,
                                ...extraParams,
                            },
                        )}
                    toggleable={false}
                    selection={{
                        field: idProperty,
                        checkbox: true,
                        multiple: true,
                    }}
                    toolbars={[
                        () => (
                            <ToolbarWithExport
                                idProperty={idProperty}
                                extraParams={extraParams}
                                view={TOOLBAR_VIEW_TYPE.spotted}
                                method={'GetProtectedVmRestorePoints'}
                                items={[
                                    {
                                        type: TOOLBAR_ITEM_TYPE.customControl,
                                        render: () => (
                                            <Search
                                                name='jobName'
                                                placeholder={getSearchFieldPlaceholder(protectionType, lang)}
                                                hasSearchButton={true}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        ),
                    ]}
                />
            )}
        />
    );
};
