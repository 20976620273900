/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { deepCopy } from '@veeam-vspc/core';

import type { FC } from 'react';
import type { Vb365BackupJob, Vb365CopyJob, Vb365OrganizationBase } from '@veeam-vspc/models/rest';

import { useLang } from 'views/providers/LangProvider/hooks';
import { PageWizard } from 'components/wizards/PageWizard';
import { CopyJobWizardStore, CopyJobWizardStoreContext } from './stores';
import { getBackupRepositoryStep } from './components/BackupRepository/BackupRepository';
import { getScheduleStep } from './components/Schedule/Schedule';
import { getSummaryStep } from './components/Summary/Summary';
import { isScheduleHidden } from '../../helpers';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { copyJobDefaults } from './helpers/copyJobDefaults';

interface CopyJobWizardProps {
    organizations: Vb365OrganizationBase[];
    isEdit?: boolean;
    data?: Vb365CopyJob;
    sourceJob?: Vb365BackupJob;
    onFinish: () => void;
    onClose: () => void;
}

export const CopyJobWizard: FC<CopyJobWizardProps> = observer(({ organizations, isEdit, data, sourceJob, onFinish, onClose }) => {
    const { portalUser } = useAppStore();
    const selectedOrganization = organizations.find(x => x.instanceUid === sourceJob.vb365OrganizationUid);
    const scheduleHidden = isScheduleHidden(portalUser, selectedOrganization);

    let model: Vb365CopyJob;

    if (isEdit) {
        model = data;
    } else {
        model = { ...copyJobDefaults, sourceBackupJobUid: sourceJob.instanceUid };

        if (scheduleHidden) {
            delete model.schedulePolicy;
        } else {
            model.schedulePolicy.dailyTime = model.schedulePolicy.dailyTime ?? copyJobDefaults.schedulePolicy.dailyTime;
            model.schedulePolicy.dailyType = model.schedulePolicy.dailyType ?? copyJobDefaults.schedulePolicy.dailyType;
            model.schedulePolicy.periodicallyEvery = model.schedulePolicy.periodicallyEvery ?? copyJobDefaults.schedulePolicy.periodicallyEvery;
        }
    }

    const lang = useLang();
    const wizardStore = useMemo(() => new CopyJobWizardStore(sourceJob.vb365ServerUid, deepCopy(model), sourceJob), []);

    const title = isEdit ? lang.EDIT_COPY_JOB : lang.NEW_BACKUP_COPY_JOB;

    return (
        <CopyJobWizardStoreContext.Provider value={wizardStore}>
            <PageWizard
                title={title}
                isEdit={isEdit}
                deactivate={onClose}
                steps={[
                    getBackupRepositoryStep(lang),
                    getScheduleStep(lang, scheduleHidden),
                    getSummaryStep(lang),
                ]}
                data={model}
                onFinish={({ data }) => {
                    if (isEdit) {
                        return wizardStore.patchJob(data).then(onFinish);
                    } else {
                        return wizardStore.createJob(data).then(onFinish);
                    }
                }}
                onClose={onClose}
            />
        </CopyJobWizardStoreContext.Provider>
    );
});

