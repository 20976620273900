/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { ProcessedVmsCdpJobsDialog } from '../ProcessedVmsColumn/components';

export interface CdpErrorsColumnProps {
    errorsCount: number;
    jobUid: string;
    jobName: string;
    stateId: string;
}

export const CdpErrorsColumn: React.FC<CdpErrorsColumnProps> = ({ errorsCount, jobName, stateId, jobUid }) => {
    const lang = useLang();
    const content = errorsCount > 0 ? errorsCount : ColumnTexts.DashSymbol;

    return (
        <ActionLinkColumn
            alignRight
            isExtMode
            content={content}
            isLink={!!errorsCount}
            renderPageDialog={({ onClose }) => (
                <ProcessedVmsCdpJobsDialog
                    stateId={stateId}
                    onClose={onClose}
                    jobUid={jobUid}
                    title={`${lang.PROCESSED_OBJECTS_LAST_12_HOURS} - ${jobName}`}
                />
            )}
        />
    );
};
