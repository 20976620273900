/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Form,
    FormLayout,
    FormTitle,
    FormValidator,
    isValid,
    useExternalFormApi,
    dataSizeToString,
    HelpText,
    ToggleKit,
    FieldLayout,
    Separator,
    Label,
} from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/use-page-wizard-store';
import { capitalize } from '@veeam-vspc/core';
import { plural } from '@veeam-vspc/core/utils/string-helpers/string-helpers';

import type { WizardStep, ExternalFormApi } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { CloudTenantType } from 'core/enums/cloud-tenant-type';
import { LinkField } from 'components/controls/LinkField';
import { CompanyService } from 'core/services/company-service';
import { useCompanyWizardStore } from '../../hooks';
import {
    isPublicCloudAvailable,
    syncHwPlansToNetwork,
    syncVboInfrastructure,
    syncVcdNetworksExtensions,
} from '../../utils';
import { BackupResources } from './components/BackupResources';
import { Microsoft365Repositories } from './components/Microsoft365Repositories';
import { Microsoft365Servers } from './components/Microsoft365Servers';
import { NetworkExtensionSettings } from './components/NetworkExtensionSettings';
import { ReplicaResources } from './components/ReplicaResources';
import { VcdNetworkExtensionSettings } from './components/VcdNetworkExtensionSettings';
import { VcdReplicaResources } from './components/VcdReplicaResources';
import { useAppStore } from 'views/providers/AppProvider/hooks';

import type { CompanyWizardStore } from '../../stores';
import type { CompanyData, CompanyWizardStepData } from '../../interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { PortalUser } from 'core/entries';

interface ServiceSwitcherProps {
    data: CompanyData;
    label: string;
    helpText: string;
    getValue: (data: CompanyData) => boolean;
    setValue: (data: CompanyData, value: boolean) => void;
    formApi: ExternalFormApi<CompanyData>;
    beforeChange?: (value: boolean) => boolean | Promise<boolean>;
    disabled?: boolean;
}

const ServiceSwitcher: React.FC<ServiceSwitcherProps> = ({
    data,
    label,
    helpText,
    getValue,
    setValue,
    formApi,
    beforeChange,
    disabled,
}: ServiceSwitcherProps) => {
    const LABEL_WIDTH = 600;
    const LABEL_DELIMITER = ':';

    return (
        <FormLayout inlineLabel>
            <FieldLayout
                disabled={disabled}
                label={(
                    <Label style={{ width: LABEL_WIDTH }}>
                        {label + LABEL_DELIMITER}
                    </Label>
                )}
            >
                <ToggleKit
                    value={getValue(data)}
                    onChange={async(value) => {
                        if (!beforeChange || await beforeChange(value)) {
                            setValue(data, value);
                            formApi.setValue(data);
                        }
                    }}
                    showSuffix={true}
                />
            </FieldLayout>

            <FormLayout style={{ width: LABEL_WIDTH }}>
                <HelpText>{helpText}</HelpText>
            </FormLayout>
        </FormLayout>
    );
};

interface ServiceLinkProps {
    data: CompanyData;
    label: string;
    getSwitcherValue: (data: CompanyData) => boolean;
    onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
    getText: (data: CompanyData) => string;
    disabled?: boolean;
}

const ServiceLink: React.FC<ServiceLinkProps> = ({ data, label, getSwitcherValue, onClick, getText, disabled }: ServiceLinkProps) =>
    (getSwitcherValue(data) && (
        <FormLayout disabled={disabled} inlineLabel>
            <LinkField
                label={label}
                onClick={onClick}
            >
                {getText(data)}
            </LinkField>
        </FormLayout>
    ));

interface ServiceLinksProps {
    data: CompanyData;
    getSwitcherValue: (data: CompanyData) => boolean;
    items: Omit<ServiceLinkProps, 'data' | 'getSwitcherValue'>[];
}

const ServiceLinks: React.FC<ServiceLinksProps> = ({ data, getSwitcherValue, items }: ServiceLinksProps) =>
    (getSwitcherValue(data) && (
        <FormLayout inlineLabel>
            {items.map(item => (
                <LinkField
                    label={item.label}
                    onClick={item.onClick}
                    disabled={item.disabled}
                >
                    {item.getText(data)}
                </LinkField>
            ))}
        </FormLayout>
    ));

const canEnableAgentBackupManagement = async(wizardStore: CompanyWizardStore, portalUser: PortalUser) => {
    if (portalUser.isResellerPortal()) {
        const resellerData = await wizardStore.resellerCache.load({ resellerId: portalUser.companyId });
        return resellerData.workstationAgents > 0 || resellerData.isWorkstationAgentsQuotaUnlimited;
    }

    return true;
};

const canEnableServerBackupManagement = async(wizardStore: CompanyWizardStore, portalUser: PortalUser) => {
    if (portalUser.isResellerPortal()) {
        const resellerData = await wizardStore.resellerCache.load({ resellerId: portalUser.companyId });
        return resellerData.serverAgents > 0 || resellerData.isServerAgentsQuotaUnlimited;
    }

    return true;
};

const formValidate = (data: CompanyData, lang: any) => {
    const validator = new FormValidator(data);

    return validator.result();
};

const stepValidate = (data: CompanyData, lang: LangsServiceBase) => {
    if (isValid(value => formValidate(value, lang), data)) {
        const notConfiguredResourcesNames = [];

        if (data.cloudBackupResourceEnabled && (data.quotas?.length ?? 0) === 0) {
            notConfiguredResourcesNames.push(lang.BACKUP_RESOURCES_ARE_SWITCHED_ON_BUT_NOT_CONFIGURED);
        }
        if (data.cloudReplicationResourceEnabled && (
            data.tenantType === CloudTenantType.General && (data.hwPlans?.length ?? 0) === 0
            || data.tenantType === CloudTenantType.Vcd && (data.vcdReplicationResources?.length ?? 0) === 0)
        ) {
            notConfiguredResourcesNames.push(lang.REPLICATION_RESOURCES_ARE_SWITCHED_ON_BUT_NOT_CONFIGURED);
        }
        if (data.vB365IntegrationEnabled && (data.vboInfrastructure?.vboServers.length ?? 0) === 0) {
            notConfiguredResourcesNames.push(lang.MICROSOFT_365_MANAGED_BACKUP_IS_SWITCHED_ON_BUT_NOT_CONFIGURED);
        }

        if (notConfiguredResourcesNames.length > 0) {

            core.notificationService.warning(
                capitalize(capitalize(lang.CONFIGURE_MANAGED_SERVICES)),
                notConfiguredResourcesNames[0]
            );

            return false;
        }

        return true;
    }

    return false;
};

export const getServicesStep = (lang: LangsServiceBase, wizardStore: CompanyWizardStore): WizardStep<CompanyData> => ({
    title: lang.SERVICES,
    validate: ({ data }) => stepValidate(data, lang),
    render: stepData => <ServicesStep {...stepData} wizardStore={wizardStore} />,
});

const ServicesStep = observer((wizardStepData: CompanyWizardStepData) => {
    const lang = useLang();
    const formApi = useExternalFormApi<CompanyData>();
    const { data, validationState, onDataChange } = wizardStepData;
    const wizardContext = usePageWizardStore<CompanyData>();
    const wizardStore = useCompanyWizardStore();
    const { portalUser } = useAppStore();

    const showBackupResources = () => wizardContext.openPanel(hide => <BackupResources {...wizardStepData} hidePanel={hide} />);
    const showReplicaResources = () => wizardContext.openPanel(hide => data.tenantType === CloudTenantType.General
        ? <ReplicaResources {...wizardStepData} hidePanel={hide} />
        : <VcdReplicaResources {...wizardStepData} hidePanel={hide} />
    );
    const showMicrosoft365Servers = () => wizardContext.openPanel(hide => <Microsoft365Servers {...wizardStepData} hidePanel={hide} />);
    const showMicrosoft365Repositories = () => wizardContext.openPanel(hide => <Microsoft365Repositories {...wizardStepData} hidePanel={hide} />);
    const showNetworkExtensionSettings = () => {
        if (data.tenantType === CloudTenantType.General) {
            wizardContext.openPanel(hide => <NetworkExtensionSettings {...wizardStepData} hidePanel={hide} />);
        } else {
            wizardContext.openPanel(hide => <VcdNetworkExtensionSettings {...wizardStepData} hidePanel={hide} />);
        }
    };

    const getBackupResourcesText = (data: CompanyData) => {
        const { quotas } = data;
        if (quotas.length > 1) {
            return plural(quotas.length, lang.REPOSITORY.toLowerCase(), lang.REPOSITORIES.toLowerCase());
        } else if (quotas.length > 0) {
            const quota = quotas[0];
            return `${quota.cloudRepositoryFriendlyName} (${dataSizeToString(quota.quota.storageGb, 0, 'G')})`;
        }
        return lang.CONFIGURE;
    };

    const getNetworkFailoverResourceText = (data: CompanyData) => {
        const { networks } = data;
        if (networks?.length > 1) {
            return plural(networks.length, lang.APPLIANCE.toLowerCase());
        } else if (networks?.length > 0) {
            const network = networks[0];
            return network.name;
        }
        return lang.CONFIGURE;
    };

    const getVcdNetworkFailoverResourceText = (data: CompanyData) => {
        const { vcdNetworksExtensions } = data;
        if (vcdNetworksExtensions?.length > 1) {
            return plural(vcdNetworksExtensions.length, lang.APPLIANCE.toLowerCase());
        } else if (vcdNetworksExtensions?.length > 0) {
            const network = vcdNetworksExtensions[0];
            return network.name;
        }
        return lang.CONFIGURE;
    };

    const getReplicaResourcesText = (data: CompanyData) => {
        const { hwPlans } = data;
        if (hwPlans?.length > 1) {
            return plural(hwPlans?.length, lang.PLAN.toLowerCase());
        } else if (hwPlans?.length > 0) {
            const hwPlan = hwPlans[0];
            return hwPlan.cloudConnectResourceName;
        }
        return lang.CONFIGURE;
    };

    const getVcdReplicaResourcesText = (data: CompanyData) => {
        const { vcdReplicationResources } = data;
        if (vcdReplicationResources && vcdReplicationResources.length > 1) {
            return `${vcdReplicationResources.length} ${lang.OBJECTS.toLowerCase()}`;
        } else if (vcdReplicationResources && vcdReplicationResources.length > 0) {
            const vcdReplicationResource = vcdReplicationResources[0];
            return vcdReplicationResource.dataCenterName;
        }
        return lang.CONFIGURE;
    };

    const getMicrosoft365ServersText = (data: CompanyData) => {
        const { vboServers } = data.vboInfrastructure;
        if (vboServers.length > 1) {
            return `${vboServers.length} ${lang.SERVERS.toLowerCase()}`;
        } else if (vboServers.length > 0) {
            const server = vboServers[0];
            return wizardStore.availableVboServersCache.value
                ? wizardStore.availableVboServersCache.value.find(availableServer => availableServer.instanceUid === server.vboServerUid)?.name
                : '';
        }
        return lang.CONFIGURE;
    };

    const getMicrosoft365RepositoriesText = (data: CompanyData) => {
        const { vboRepositories } = data.vboInfrastructure;
        if (vboRepositories.length > 1) {
            return `${vboRepositories.length} ${lang.REPOSITORIES.toLowerCase()}`;
        } else if (vboRepositories.length > 0) {
            const server = vboRepositories[0];
            return server.repositoryFriendlyName;
        }
        return lang.CONFIGURE;
    };

    const cloudReplicationResourceIsEmpty = data.tenantType === CloudTenantType.General
        ? (data.hwPlans?.length ?? 0) === 0
        : (data.vcdReplicationResources?.length ?? 0) === 0;

    useEffect(() => {
        wizardStore.cloudConnectAgentsCache.load();

        if (data.tenantType === CloudTenantType.General) {
            data.networks = syncHwPlansToNetwork(data, lang);
            onDataChange(data);
        }

        syncVboInfrastructure(data.vboInfrastructure, wizardStore, data.id)
            .then((infrastructure) => {
                data.vboInfrastructure = infrastructure;
                onDataChange(data);
            });

        if (data.tenantType === CloudTenantType.Vcd) {
            syncVcdNetworksExtensions(data, wizardStore)
                .then((items) => {
                    data.vcdNetworksExtensions = items;
                    onDataChange(data);
                });
        }
    }, []);

    return (
        <StepLayout
            title={lang.SERVICES}
            description={lang.CONFIGURE_SERVICES_THAT_YOU_WANT}
        >
            <Form
                value={data}
                validate={(data: CompanyData) => formValidate(data, lang)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{capitalize(lang.MANAGED_BACKUP, true)}</FormTitle>

                    <ServiceSwitcher
                        data={data}
                        label={lang.BACKUP_AGENTS_MANAGEMENT}
                        helpText={lang.ENABLE_THE_POSSIBILITY_TO_COLLECT_DATA_FROM_COMPANY}
                        formApi={formApi}
                        getValue={data => data.backupAgentManagementEnabled}
                        setValue={(data, value) => data.backupAgentManagementEnabled = value}
                        beforeChange={async(value) => {
                            if (value) {
                                if (!await canEnableAgentBackupManagement(wizardStore, portalUser)) {
                                    core.notificationService.error(
                                        capitalize(lang.CONFIGURE_MANAGED_SERVICES),
                                        lang.CANNOT_ACTIVATE_THE_BACKUP_AGENT_MANAGEMENT);

                                    return false;
                                }

                                return true;
                            } else {
                                if (!data.allowToSwitchBackupAgentManagement) {
                                    core.notificationService.error(
                                        capitalize(lang.CONFIGURE_MANAGED_SERVICES),
                                        lang.CANNOT_DEACTIVATE_THE_BACKUP_AGENT_MANAGEMENT);

                                    return false;
                                }

                                return true;
                            }
                        }}
                    />

                    <Separator />

                    <ServiceSwitcher
                        data={data}
                        label={lang.BACKUP_SERVERS_MANAGEMENT}
                        helpText={lang.ENABLE_THE_POSSIBILITY_TO_COLLECT_DATA}
                        formApi={formApi}
                        getValue={data => data.backupServerManagementEnabled}
                        setValue={(data, value) => data.backupServerManagementEnabled = value}
                        beforeChange={async(value) => {
                            if (value) {
                                if (!await canEnableServerBackupManagement(wizardStore, portalUser)) {
                                    core.notificationService.error(
                                        capitalize(lang.CONFIGURE_MANAGED_SERVICES),
                                        lang.CANNOT_ACTIVATE_THE_BACKUP_SERVER_MANAGEMENT);

                                    return false;
                                }

                                return true;
                            } else {
                                if (!data.allowToSwitchBackupServerManagement) {
                                    core.notificationService.error(
                                        capitalize(lang.CONFIGURE_MANAGED_SERVICES),
                                        lang.CANNOT_DEACTIVATE_THE_BACKUP_SERVER_MANAGEMENT
                                    );
                                    return false;
                                }

                                return true;
                            }
                        }}
                    />

                    <FormTitle>{lang.CLOUD_CONNECT_RESOURCES}</FormTitle>

                    <ServiceSwitcher
                        data={data}
                        label={capitalize(lang.BACKUP_RESOURCES, true)}
                        helpText={lang.ENABLE_THE_POSSIBILITY_TO_STORE_BACKUPS}
                        formApi={formApi}
                        getValue={data => data.cloudBackupResourceEnabled}
                        setValue={(data, value) => data.cloudBackupResourceEnabled = value}
                    />

                    <ServiceLink
                        data={data}
                        label={capitalize(lang.BACKUP_REPOSITORY, true)}
                        onClick={showBackupResources}
                        getText={getBackupResourcesText}
                        getSwitcherValue={data => data.cloudBackupResourceEnabled}
                    />

                    <Separator />

                    <ServiceSwitcher
                        data={data}
                        label={lang.REPLICATION_RESOURCES}
                        helpText={data.tenantType === CloudTenantType.Vcd
                            ? lang.ENABLE_THE_POSSIBILITY_TO_TARGET_REPLICATION
                            : lang.ENABLE_THE_POSSIBILITY_TO_REPLICATE}
                        formApi={formApi}
                        getValue={data => data.cloudReplicationResourceEnabled}
                        setValue={(data, value) => data.cloudReplicationResourceEnabled = value}
                    />

                    <ServiceLink
                        data={data}
                        label={capitalize(data.tenantType === CloudTenantType.General ? lang.HARDWARE_PLAN : lang.VIRTUAL_DATA_CENTER, true)}
                        onClick={showReplicaResources}
                        getText={data.tenantType === CloudTenantType.General ? getReplicaResourcesText : getVcdReplicaResourcesText}
                        getSwitcherValue={data => data.cloudReplicationResourceEnabled}
                    />

                    {data.cloudReplicationResourceEnabled && (
                        <>
                            <Separator />

                            <ServiceSwitcher
                                data={data}
                                label={lang.USE_VEEAM_NETWORK_MANAGEMENT}
                                helpText={lang.ENABLE_THE_POSSIBILITY_TO_USE_NETWORK_RESOURCES}
                                formApi={formApi}
                                getValue={data => data.networkFailoverResourcesEnabled}
                                setValue={(data, value) => data.networkFailoverResourcesEnabled = value}
                                disabled={cloudReplicationResourceIsEmpty}
                            />

                            <ServiceLink
                                data={data}
                                label={capitalize(lang.NETWORK_EXTENSION_APPLIANCE)}
                                onClick={showNetworkExtensionSettings}
                                getText={data.tenantType === CloudTenantType.General
                                    ? getNetworkFailoverResourceText
                                    : getVcdNetworkFailoverResourceText}
                                getSwitcherValue={data => data.networkFailoverResourcesEnabled}
                                disabled={cloudReplicationResourceIsEmpty}
                            />
                        </>
                    )}

                    <FormTitle>{lang.MICROSOFT_365}</FormTitle>

                    <ServiceSwitcher
                        data={data}
                        label={lang.MICROSOFT_365_MANAGED_BACKUP}
                        helpText={lang.ENABLE_THE_POSSIBILITY_TO_PROTECT}
                        formApi={formApi}
                        getValue={data => data.vB365IntegrationEnabled}
                        setValue={(data, value) => data.vB365IntegrationEnabled = value}
                        beforeChange={async(value) => {
                            if (!value) {
                                try {
                                    await Promise.all(data.vboInfrastructure.vboServers
                                        .map(server => CompanyService.checkVboResourceCanBeDeleted({
                                            companyUid: data.organizationUid,
                                            vboServerUid: server.vboServerUid,
                                        })));
                                    return true;
                                } catch (e) {
                                    return false;
                                }
                            }
                            return true;
                        }}
                    />

                    <ServiceLinks
                        data={data}
                        getSwitcherValue={data => data.vB365IntegrationEnabled}
                        items={[
                            {
                                label: lang.BACKUP_SERVER,
                                onClick: showMicrosoft365Servers,
                                getText: getMicrosoft365ServersText,
                            },
                            {
                                label: lang.BACKUP_REPOSITORY,
                                onClick: showMicrosoft365Repositories,
                                getText: getMicrosoft365RepositoriesText,
                                disabled: (data.vboInfrastructure?.vboServers.length ?? 0) === 0,
                            },
                        ]}
                    />

                    <FormTitle>{capitalize(lang.PUBLIC_CLOUD, true)}</FormTitle>

                    <ServiceSwitcher
                        data={data}
                        label={lang.PUBLIC_CLOUD_MANAGED_BACKUP}
                        helpText={lang.ENABLE_THE_POSSIBILITY_TO_DEPLOY_AND_MANAGE}
                        formApi={formApi}
                        getValue={data => data.vbPublicCloudManagementEnabled}
                        setValue={(data, value) => data.vbPublicCloudManagementEnabled = value}
                        beforeChange={(value) => {
                            if (value) {
                                if (!isPublicCloudAvailable(wizardStore.cloudConnectAgentsCache.value, data.cloudConnectAgentUid)) {
                                    core.notificationService.error(
                                        capitalize(lang.CONFIGURE_MANAGED_SERVICES),
                                        portalUser.isResellerPortal()
                                            ? lang.CANNOT_ENABLE_THIS_FEATURE_CONTACT
                                            : lang.CANNOT_ENABLE_THIS_FEATURE_UPGRADE_YOUR_CLOUD
                                    );
                                    return false;
                                }

                                return true;
                            } else {
                                if (!data.allowToSwitchVbPublicCloudManagement) {
                                    core.notificationService.error(
                                        capitalize(lang.CONFIGURE_MANAGED_SERVICES),
                                        lang.CANNOT_DISABLE_THE_PUBLIC_CLOUD
                                    );
                                    return false;
                                }

                                return true;
                            }
                        }}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
