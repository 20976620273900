/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    StackView,
    STACK_DISTRIBUTION,
    SPACE_FILL,
    STACK_ALIGN,
    Spinner,
} from '@veeam-vspc/components';

// TODO: export SpinnerProps in @veeam-vspc/components from @veeam/components/lib/Spinner/Spinner
export interface SpinnerProps {
    size?: number;
}

export const CenterSpinner: React.FC<SpinnerProps> = (props: SpinnerProps) => (
    <StackView
        distribution={STACK_DISTRIBUTION.center}
        spaceFill={SPACE_FILL.all}
        align={STACK_ALIGN.center}
    >
        <Spinner {...props} />
    </StackView>
);
