/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/components';

const Legend = styled.div`
  margin-right: ${constants.SPACING_M};

  &::before {
    content: '';
    display: inline-block;
    width: ${constants.SIZE_S};
    height: ${constants.SIZE_S};
    border-radius: ${constants.SIZE_S};
    margin-right: ${constants.SPACING_XS};
  }
`;

export const FailedLabel = styled(Legend)`
  &::before {
    background-color: ${colors.R800};
  }
`;

export const WarningLabel = styled(Legend)`
  &::before {
    background-color: #F2C973;
  }
`;

export const SuccessLabel = styled(Legend)`
  &::before {
    background-color: ${colors.Grn400};
  }
`;
