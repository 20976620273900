/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TextColumn } from '@veeam-vspc/components';
import { Vb365JobItemComposed } from '@veeam-vspc/models/rest';

import type { FC } from 'react';

import { useLang } from 'views/providers/LangProvider/hooks';
import { resourceValueGetter } from '../../helpers';

import type { ObjectCellProps } from '../../../../../../interfaces';

export const ObjectCell: FC<ObjectCellProps> = ({ rowData, wizardStore }) => {
    const lang = useLang();

    if (rowData.itemType === Vb365JobItemComposed.ItemTypeEnum.PersonalSites) {
        return <TextColumn>{lang.PERSONAL_SITES}</TextColumn>;
    }

    if (rowData.itemType === Vb365JobItemComposed.ItemTypeEnum.PartialOrganization) {
        return <TextColumn>{wizardStore.selectedOrganization.name}</TextColumn>;
    }

    return <TextColumn>{resourceValueGetter(rowData, 'name', wizardStore)}</TextColumn>;
};
