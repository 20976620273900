/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { ThemeProvider as KitThemeProvider, themes } from '@veeam-vspc/components';

import { useAppServices, useAppStore } from '../AppProvider/hooks';

export interface ThemeProviderProps {
    children: React.ReactElement;
}

export const ThemeProvider = observer((props: ThemeProviderProps) => {
    const appStore = useAppStore();
    const { notificationService } = useAppServices();

    notificationService.setTheme(appStore.theme);

    return (
        <KitThemeProvider theme={themes[appStore.theme]}>
            {props.children}
        </KitThemeProvider>
    );
});
