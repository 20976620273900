/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { FormLayout, NoteBar, NOTEBAR_STATUS } from '@veeam-vspc/components';
import { FIELD_LAYOUT_HELP_TEXT_VIEW } from '@veeam/components/lib/FieldLayout/types';

import type { ExternalFormApi } from '@veeam-vspc/components/src/form/interfaces';

import { DistributionType } from '../../../../enums';
import { RadioGroup } from 'components/controls/RadioGroup';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppStore } from 'views/providers/AppProvider/hooks';

import type { VbrDeploySettingsWizardModel } from '../../../../entries';

export interface NoAutoLkRadioGroupProps {
    distributionType: VbrDeploySettingsWizardModel['distributionType'];
    formApi: ExternalFormApi<VbrDeploySettingsWizardModel>;
}

export const NoAutoLkRadioGroup: React.FC<NoAutoLkRadioGroupProps> = ({ formApi, distributionType }) => {
    const lang = useLang();
    const { portalUser } = useAppStore();

    return (
        <FormLayout>
            <RadioGroup
                value={distributionType}
                onChange={(value: DistributionType) => {
                    formApi.setValue('distributionType', value); // otherwise we don't see errors
                }}
                buttons={[
                    {
                        disabled: true,
                        label: `${lang.DOWNLOAD_THE_BACKUP_SERVER_SETUP_FILE_FROM} (${lang.RECOMMENDED})`,
                        value: DistributionType.DownloadFromVeeamServers,
                        helpTextView: FIELD_LAYOUT_HELP_TEXT_VIEW.normal,
                        helpText: (
                            <NoteBar status={NOTEBAR_STATUS.warning}>
                                {portalUser.isAdminPortal() ? lang.ENABLE_PRODUCT_UPDATES : lang.CONTACT_YOUR_SERVICE_PROVIDER}
                            </NoteBar>
                        ),
                    },
                    {
                        label: lang.USE_THE_BACKUP_SERVER_SETUP_FILE,
                        value: DistributionType.DownloadFromFileShare,
                        helpText: `${lang.SPECIFY_PATH_TO_THE_BACKUP_SERVER}:`,
                        helpTextView: FIELD_LAYOUT_HELP_TEXT_VIEW.labelDescription,
                    },
                ]}
            />
        </FormLayout>
    );
};
