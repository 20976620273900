/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridSortDirections, TextColumn } from '@veeam-vspc/components';

import type { CellRendererProps, RequestParams } from '@veeam-vspc/components';
import type { PublicCloudJobFilter, PublicCloudJobModel } from '@veeam-vspc/models/web-controllers';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { PublicCloudsActionToolbar, PublicCloudsFilterToolbar } from './components';
import { StatusAgoColumn } from '../../../StatusAgoColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ConfigSectionIds } from 'core/services';

export interface PublicCloudsProps {
    stateId?: string;
}

export const PublicClouds: React.FC<PublicCloudsProps> = ({ stateId }) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const gridStateId = `${stateId}.PublicClouds`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<PublicCloudJobModel>) => (
        <TextColumn>{cellData}</TextColumn>
    );
    const columns = [
        {
            field: 'vmName',
            title: lang.VM,
            cell: defaultColumnRender,
            width: 75,
        },
        {
            field: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: defaultColumnRender,
        },
        {
            field: 'company',
            title: lang.COMPANY,
            cell: defaultColumnRender,
        },
        {
            field: 'site',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'location',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'resourceId',
            title: lang.RESOURCE_ID,
            cell: defaultColumnRender,
        },
        {
            field: 'platformTypeName',
            title: lang.PLATFORM,
            cell: defaultColumnRender,
        },
        {
            field: 'jobName',
            title: lang.POLICY,
            cell: defaultColumnRender,
        },
        {
            field: 'displayPolicyState',
            title: lang.POLICY_STATE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'lastSnapshot.sessionStateName',
            title: lang.LAST_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudJobModel>) => (
                <StatusAgoColumn
                    url={'/PublicCloudVmJob/GetSnapshotTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastSnapshot}
                />
            ),
        },
        {
            field: 'lastBackup.sessionStateName',
            title: lang.LAST_BACKUP,
            cell: ({ rowData }: CellRendererProps<PublicCloudJobModel>) => (
                <StatusAgoColumn
                    url={'/PublicCloudVmJob/GetBackupTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastBackup}
                />
            ),
        },
        {
            field: 'lastRemoteSnapshot.sessionStateName',
            title: lang.LAST_REPLICA_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudJobModel>) => (
                <StatusAgoColumn
                    url={'/PublicCloudVmJob/GetRemoteSnapshotTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastRemoteSnapshot}
                />
            ),
            width: 200,
        },
        {
            field: 'lastArchive.sessionStateName',
            title: lang.LAST_ARCHIVE,
            cell: ({ rowData }: CellRendererProps<PublicCloudJobModel>) => (
                <StatusAgoColumn
                    url={'/PublicCloudVmJob/GetArchiveTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastArchive}
                />
            ),
        },
        {
            field: 'nextRun',
            title: lang.NEXT_RUN,
            cell: ({ cellData }: CellRendererProps<PublicCloudJobModel>) => (
                <GridDateColumn
                    value={cellData}
                />
            ),
            width: 100,
        },
        {
            field: 'backupServerName',
            title: lang.SERVER_NAME,
            cell: defaultColumnRender,
            hidden: true,
        },
    ];

    return (
        <AdvancedGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.BackupJobsVirtualMachinesPublicClouds}
            defaultColumnWidth={100}
            initialState={{
                sort: {
                    key: columns[0]?.field,
                    direction: GridSortDirections.Asc,
                },
            }}
            columns={columns}
            data={(requestParams: RequestParams<PublicCloudJobFilter>) => transportService
                .request<typeof requestParams, PublicCloudJobModel[]>('/PublicCloudVmJob/GetVmJobs', { ...requestParams })}
            isRowInactive={({ rowData }) => !rowData.enabled}
            toggleable={true}
            selection={{
                field: 'id',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <PublicCloudsFilterToolbar />,
                () => <PublicCloudsActionToolbar idProperty={'id'} selectedOnly />,
            ]}
        />
    );
};
