/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled, { createGlobalStyle } from 'styled-components';
import { constants } from '@veeam-vspc/components';
import React from 'react';

const MapStyled = styled.div`
    height: 100%;

    & > * {
      height: 100%;
    }
`;

const UpRegionButtonStyled = createGlobalStyle`
  .up-region-button {
    [data-selector-id='icon-before'], [data-selector-id='icon-image'] {
      height: ${constants.SIZE_XL};
      width: ${constants.SIZE_XL};
    }
  }
`;

export const MapWrapper: React.FC = ({ children }) => (
    <MapStyled>
        <UpRegionButtonStyled />
        {children}
    </MapStyled>
);
