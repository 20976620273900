/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants, Text } from '@veeam-vspc/components';
import * as React from 'react';

import { useLang } from 'views/providers/LangProvider/hooks';


const HelpStyled = styled.div<{ isHelpVisible: boolean; }>`
  background-color: ${colors.Y50};
  width: 600px;
  display: ${props => props.isHelpVisible ? 'block' : 'none'};
  margin-bottom: ${constants.SPACING_S};
  padding: ${constants.SPACING_S};
`;

export const Help = ({ install, isHelpVisible }: { install: boolean; isHelpVisible: boolean; }) => {
    const lang = useLang();

    return (
        <HelpStyled isHelpVisible={isHelpVisible}>
            <Text>{`${lang.CONFIGURATION_FILE_CONTAINS}:`}</Text>
            <br />
            <b>{RCOP.Lang.ACCEPT_EULA}</b>
            {` - ${RCOP.Lang.SPECIFY_1_TO_ACCEPT_VEEAM_LICENSE_AGREEMENT}.`}
            <br />
            <b>{RCOP.Lang.ACCEPT_LICENSING_POLICY}</b>
            {` - ${RCOP.Lang.SPECIFY_1_TO_ACCEPT_VEEAM_LICENSE_AGREEMENT}.`}
            <br />
            <b>{RCOP.Lang.ACCEPT_THIRDPARTY_LICENSES}</b>
            {` - ${RCOP.Lang.SPECIFY_1_TO_ACCEPT_THE_LICENSE_AGREEMENT_FOR_3RD}.`}
            <br />
            <b>{RCOP.Lang.ACCEPT_REQUIRED_SOFTWARE}</b>
            {` - ${RCOP.Lang.SPECIFY_1_TO_ACCEPT_ALL_REQUIRED_SOFTWARE_LICENSE}.`}
            <br />
            <b>{RCOP.Lang.VBR_LICENSE_FILE}</b>
            {` - ${RCOP.Lang.PATH_TO_THE_LICENSE_FILE}.`}
            <br />
            <b>{RCOP.Lang.VBR_LICENSE_AUTOUPDATE}</b>
            {` - ${RCOP.Lang.SPECIFY_1_TO_ENABLE_AUTOMATIC_LICENSE_UPDATE}.`}

            {
                install && (
                    <>
                        <br />
                        <b>{RCOP.Lang.VBR_SERVICE_USER}</b>
                        {` - ${RCOP.Lang.USER_ACCOUNT_UNDER_WHICH_THE_VEEAM}.`}
                    </>
                )
            }

            <br />
            <b>{RCOP.Lang.VBR_SERVICE_PASSWORD}</b>
            {` - ${RCOP.Lang.PASSWORD_FOR_THE_ACCOUNT_UNDER}.`}

            {
                install && (
                    <>
                        <br />
                        <b>{RCOP.Lang.VBR_SQLSERVER_INSTALL}</b>
                        {` - ${RCOP.Lang.SPECIFY_0_TO_USE_AN_EXISTING_SQL}.`}
                        <br />
                        <b>{RCOP.Lang.VBR_SQLSERVER_ENGINE}</b>
                        {` - ${RCOP.Lang.SPECIFY_0_TO_USE_POSTGRESQL_ENGINE}.`}
                        <br />
                        <b>{RCOP.Lang.VBR_SQLSERVER_SERVER}</b>
                        {` - ${RCOP.Lang.SQL_SERVER_AND_INSTANCE_FOR_MICROSOFT_SQL}.`}
                        <br />
                        <b>{RCOP.Lang.VBR_SQLSERVER_DATABASE}</b>
                        {` - ${RCOP.Lang.CONFIGURATION_DATABASE_NAME}.`}
                        <br />
                        <b>{RCOP.Lang.VBR_SQLSERVER_AUTHENTICATION}</b>
                        {` - ${RCOP.Lang.SPECIFIES_AUTHENTICATION_MODE_TO_CONNECT}.`}
                        <br />
                        <b>{RCOP.Lang.VBR_SQLSERVER_USERNAME}</b>
                        {` - ${RCOP.Lang.LOGINID_TO_CONNECT_TO_THE_MICROSOFT_SQL}.`}
                    </>
                )
            }

            <br />
            <b>{RCOP.Lang.VBR_SQLSERVER_PASSWORD}</b>
            {` - ${RCOP.Lang.PASSWORD_TO_CONNECT_TO_THE_MICROSOFT_SQL}.`}

            {
                install && (
                    <>
                        <br />
                        <b>{RCOP.Lang.VBRC_SERVICE_PORT}</b>
                        {` - ${RCOP.Lang.TCP_PORT_THAT_WILL_BE_USED_BY_THE_VEEAM_GUEST_CATALOG_SERVICE}.`}
                        <br />
                        <b>{RCOP.Lang.VBR_SERVICE_PORT}</b>
                        {` - ${RCOP.Lang.TCP_PORT_THAT_WILL_BE_USED_BY_THE_VEEAM_BACKUP_SERVICE}.`}
                        <br />
                        <b>{RCOP.Lang.VBR_SECURE_CONNECTIONS_PORT}</b>
                        {` - ${RCOP.Lang.PORT_USED_FOR_COMMUNICATION_BETWEEN_THE_MOUNT_SERVER}.`}
                        <br />
                        <b>{RCOP.Lang.VBR_RESTSERVICE_PORT}</b>
                        {` - ${RCOP.Lang.PORT_USED_FOR_COMMUNICATION_WITH_REST}.`}
                        <br />
                        <b>{RCOP.Lang.INSTALLDIR}</b>
                        {` - ${RCOP.Lang.PATH_TO_THE_DIRECTORY_WHERE}.`}
                        <br />
                        <b>{RCOP.Lang.VM_CATALOGPATH}</b>
                        {` - ${RCOP.Lang.PATH_TO_THE_CATALOG_FOLDER}.`}
                        <br />
                        <b>{RCOP.Lang.VBR_IRCACHE}</b>
                        {` - ${RCOP.Lang.PATH_TO_THE_FOLDER_WHERE}.`}
                        <br />
                        <b>{RCOP.Lang.VBR_CHECK_UPDATES}</b>
                        {` - ${RCOP.Lang.SPECIFY_1_TO_AUTOMATICALLY_CHECK_FOR_NEW_PRODUCT}.`}
                        <br />
                        <b>{RCOP.Lang.AHV_INSTALL}</b>
                        {` - ${RCOP.Lang.SPECIFY_1_TO_INSTALL_NUTANIX_AHV}.`}
                        <br />
                        <b>{RCOP.Lang.RHV_INSTALL}</b>
                        {` - ${RCOP.Lang.SPECIFY_1_TO_INSTALL_NUTANIX_RHV}.`}
                        <br />
                        <b>{RCOP.Lang.AWS_INSTALL}</b>
                        {` - ${RCOP.Lang.SPECIFY_1_TO_INSTALL_AWS_PLUGIN}.`}
                        <br />
                        <b>{RCOP.Lang.AZURE_INSTALL}</b>
                        {` - ${RCOP.Lang.SPECIFY_1_TO_INSTALL_MICROSOFT_AZURE}.`}
                        <br />
                        <b>{RCOP.Lang.GCP_INSTALL}</b>
                        {` - ${RCOP.Lang.SPECIFY_1_TO_INSTALL_GOOGLE_CLOUD}.`}
                        <br />
                        <b>{RCOP.Lang.KASTEN_INSTALL}</b>
                        {` - ${RCOP.Lang.SPECIFY_1_TO_INSTALL_KASTEN}.`}
                    </>
                )
            }
        </HelpStyled>
    );
};


