/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts, TextColumn } from '@veeam-vspc/components';

import { EJobStates } from 'core/enums';
import { getStatusIconSrc } from 'core/utils';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { FailureMessageDialog } from 'views/components/FailureMessageDialog';

export interface BackupFileJobStatusColumnProps {
    status: EJobStates;
    statusName: string;
    lastSessionEndTime: string;
    lastSessionUid?: string;
}

export const BackupFileJobStatusColumn: React.FC<BackupFileJobStatusColumnProps> = (props) => {
    const { formats } = useAppStore();
    const { netShortDate, netShortTime } = formats;
    const {
        status,
        statusName,
        lastSessionUid,
        lastSessionEndTime,
    } = props;
    const iconSrc = getStatusIconSrc(statusName);

    return (
        <ActionLinkColumn
            renderContent={() => (
                <TextColumn style={{ color: 'inherit' }}>
                    {statusName || ColumnTexts.DashSymbol}
                </TextColumn>
            )}
            isLink={statusName ? [EJobStates.Failed, EJobStates.Warning].includes(status) : false}
            contentIconBefore={statusName ? iconSrc : ''}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <FailureMessageDialog
                    url={'/BackupServerJob/GetJobSessionTasksFailureMessages'}
                    deactivate={deactivate}
                    iconSrc={iconSrc}
                    paramsForFailureMessageRequest={{
                        sessionGuid: lastSessionUid,
                    }}
                    paramsForFailureMessage={{
                        shortDateFormat: netShortDate,
                        shortTimeFormat: netShortTime,
                        lastSessionTime: lastSessionEndTime,
                        statusName,
                    }}
                />
            )}
        />
    );
};
