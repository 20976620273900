/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/core';

import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';
import type { CompanyData } from '../interfaces/company-data';

export const syncHwPlansToNetwork = ({ hwPlans, networks, userName }: CompanyData, lang: LangsServiceBase) => {
    let result = networks ? deepCopy(networks) : [];

    if (hwPlans) {
        // generate empty network for hwplan
        hwPlans.forEach((itemHwPlan) => {
            if (!result.find(oneNetwork => itemHwPlan.cloudConnectResourceUid === oneNetwork.hardwarePlan)) {
                result.push({
                    defaultGateway: '',
                    dhcpEnabled: true,
                    hardwarePlan: itemHwPlan.cloudConnectResourceUid,
                    hostname: itemHwPlan.hostname,
                    ipAddress: '',
                    name: `${lang.NETWORK_EXTENSION_APPLIANCE} ${userName || ''}(${itemHwPlan.hostname})`,
                    networkName: lang.OBTAIN_FROM_BACKUP_SERVER,
                    rootHostname: itemHwPlan.rootHostname,
                    subnetMask: '',
                });
            }
        });

        // looking for networks that need to be deleted, because there is no hwplan for them
        result = result.filter(network => hwPlans.find(oneHwPlan => oneHwPlan.cloudConnectResourceUid === network.hardwarePlan));
    }

    return result;
};
