/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';

import { useAppActions, useAppStore } from 'views/providers/AppProvider/hooks';
import { LoginContext, LoginStore } from '../../stores';
import { LoginBackground } from '../LoginBackground';
import { LoginFactorsContainer } from '../LoginFactorsContainer';

export const Login: React.FC<{}> = () => {
    const { identityProviders, message: error } = useAppStore();
    const { loadApp, loadLogin } = useAppActions();
    const loginStore = useMemo(
        () => new LoginStore({ loadApp, loadLogin, identityProviders, error }),
        [loadApp, loadLogin, identityProviders, error]
    );

    return (
        <LoginContext.Provider value={loginStore}>
            <LoginBackground>
                <LoginFactorsContainer />
            </LoginBackground>
        </LoginContext.Provider>
    );
};
