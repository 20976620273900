/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ACTION_VIEW, SortingMode, TextColumn } from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { WizardStepData, GridStore } from '@veeam-vspc/components';
import type { ResellerCloudVcdResourceMapModel } from '@veeam-vspc/models/web-controllers';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useResellerWizardStore } from '../../../../hooks';
import { ActionsToolbar } from './components/ActionsToolbar';

import type { ResellerData } from '../../../../interfaces';

export interface BackupResourcesProps extends WizardStepData<ResellerData> {
    hidePanel: () => void;
}

export const VcdResources = observer((props: BackupResourcesProps) => {
    const lang = useLang();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<ResellerCloudVcdResourceMapModel, void, void>>();
    const wizardStore = useResellerWizardStore();

    const [items] = useState([...data.cloudVcdResources]);

    useEffect(() => {
        wizardStore.vcdOrganizationsCache.load({ cloudConnectAgentUids: data.cloudConnectAgents.map(agent => agent.cloudConnectAgentUid) });
    }, []);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={capitalize(lang.VMWARE_CLOUD_DIRECTOR_RESOURCES)}
            description={lang.SELECT_ONE_OR_MORE_VCD_ORGANIZATIONS}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.cloudVcdResources = api.current.pageData;

                        onDataChange(data);

                        hidePanel();
                    },
                    // disabled: hwPlans?.length === 0,
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid <ResellerCloudVcdResourceMapModel, void, void>
                defaultColumnWidth={100}
                columns={[
                    { field: 'cloudConnectResourceName', title: capitalize(lang.VCD_ORGANIZATION) },
                    {
                        field: 'dataCenterCount',
                        title: lang.N_OF_VIRTUAL_DATA_CENTERS,
                        cell: ({ rowData }) => (
                            <TextColumn>
                                {wizardStore.vcdOrganizationsCache.value
                                    ?.find(vcd => rowData.cloudConnectResourceUid === vcd.cloudConnectResourceUid)
                                    ?.dataCenterCount}
                            </TextColumn>
                        ),
                    },
                ]}
                data={() => Promise.resolve({
                    data: items,
                    meta: {
                        pagingInfo: {
                            total: items?.length,
                        },
                    },
                } as BaseSuccessRequestResponse<ResellerCloudVcdResourceMapModel[]>)}
                toggleable={false}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'cloudConnectResourceUid',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => (
                        <ActionsToolbar
                            items={items}
                            cloudConnectAgentUids={data.cloudConnectAgents.map(agent => agent.cloudConnectAgentUid)}
                        />
                    ),
                ]}
                api={api}
                sortingMode={SortingMode.Local}
                isLoading={wizardStore.vcdOrganizationsCache.loading}
            />
        </SidePanelForm>
    );
});
