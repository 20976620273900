/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/core';
import React from 'react';
import {
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
} from '@veeam-vspc/components';

import type { ToolbarItems } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { CompanyService } from 'core/services/company-service';
import { Microsoft365ServerDialog } from '../Microsoft365ServerDialog';
import addIcon from 'images/actions/add.svg';
import removeIcon from 'images/actions/remove.svg';
import editIcon from 'images/actions/edit.svg';

import type { ActionsToolbarProps } from '../../interfaces';
import type { VboServer } from '../../../../../../interfaces';

export const ActionsToolbar = ({ items, setItems, companyId, companyUid }: ActionsToolbarProps) => {
    const lang = useLang();
    const gridApi = useGridApi<VboServer, void, void>();

    const [quotaDialog, quotaDialogActions] = useModal<VboServer>({
        render: ({ deactivate, data }) => (
            <Microsoft365ServerDialog
                data={data}
                existingItems={items}
                companyId={companyId}
                onConfirm={(newData) => {
                    const newItems = deepCopy(items);
                    if (data) {
                        newItems[newItems.findIndex(item => item.vboServerUid === data.vboServerUid)] = newData;
                    } else {
                        newItems.push(newData);
                    }
                    setItems(newItems);
                }}
                deactivate={deactivate}
            />
        ),
    });

    const toolbarItems: ToolbarItems = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: addIcon,
            text: lang.ADD,
            onClick: () => {
                quotaDialogActions.activate();
            },
            decorators: [
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: editIcon,
            text: lang.EDIT,
            onClick: async() => {
                const selected = gridApi.selected[0];
                quotaDialogActions.activate(selected);
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: removeIcon,
            text: lang.REMOVE,
            onClick: async() => {
                const selected = gridApi.selected[0];

                await CompanyService.checkVboResourceCanBeDeleted({ companyUid, vboServerUid: selected.vboServerUid });

                const newItems = deepCopy(items);
                newItems.splice(newItems.findIndex(item => item.vboServerUid === selected.vboServerUid), 1);
                setItems(newItems);
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
    ];

    return (
        <>
            <Toolbar items={toolbarItems} />
            {quotaDialog}
        </>
    );
};
