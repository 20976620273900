/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { delay } from '@veeam-vspc/core';
import { Spinner as SpinnerKit } from '@veeam/components';

import { SpinnerWrapperStyled } from './SpinnerWrapper.styled';

export const Spinner = ({ delayMs = 0, size }) => {
    const isDelayActive = delayMs > 0;
    const [isShowSpinner, setIsShowSpinner] = useState(!isDelayActive);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isDelayActive) {
            delay(delayMs)
                .then(() => {
                    if (isMounted.current) {
                        setIsShowSpinner(true);
                    }
                });
        }
    }, [isDelayActive, delayMs]);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        isShowSpinner && (
            <SpinnerWrapperStyled>
                <SpinnerKit size={size} />
            </SpinnerWrapperStyled>
        )
    );
};
