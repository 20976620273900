/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { FieldLayout, Notification, NOTIFICATION_STATUS } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';

export const OAuthNotification = () => {
    const lang = useLang();

    return (
        <FieldLayout>
            <Notification status={NOTIFICATION_STATUS.ok}>
                {lang.APPLICATION_IS_AUTHORIZED}
            </Notification>
        </FieldLayout>
    );
};
