/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    CheckboxKit,
    dataSizeToString,
    WizardSummary,
} from '@veeam-vspc/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { UserMfaStatuses } from '../../../Base/components/MultiFactorAuthentication/user-mfa-statuses';
import { ROLES } from 'core/const';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { CompanyUserModel } from '../../interfaces';

const isLocationBlockAvailable = (role: PortalUserRoles) => role === PortalUserRoles.LocationUser
    || role === PortalUserRoles.LocationAdministrator
    || role === PortalUserRoles.Subtenant;

export const Summary: React.FC<WizardStepData<CompanyUserModel>> = ({ data, onDataChange }) => {
    const lang = useLang();

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_USER_DETAILS}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: `${lang.USER_INFO}:`,
                        fields: [
                            {
                                label: `${lang.FIRST_NAME}:`,
                                value: data.firstName,
                            },
                            {
                                label: `${lang.LAST_NAME}:`,
                                value: data.lastName,
                            },
                            {
                                label: `${lang.EMAIL_ADDRESS}:`,
                                value: data.email,
                            },
                            {
                                label: `${lang.USERNAME}:`,
                                value: data.login || data.vcdUserName,
                            },
                        ],
                    },
                    {
                        title: `${lang.ROLE}:`,
                        fields: [
                            {
                                label: `${lang.ROLE}:`,
                                value: data.userRole,
                                transform: (value: PortalUserRoles) => ROLES[value],
                            },
                        ],
                    },
                    isLocationBlockAvailable(data.userRole) && {
                        title: `${lang.LOCATIONS}:`,
                        fields: [
                            {
                                label: `${lang.LOCATIONS}:`,
                                value: data.locations.map(location => location.name).join(', '),
                            },
                        ],
                    },
                    data.userRole === PortalUserRoles.Subtenant && {
                        title: `${lang.QUOTA}:`,
                        fields: [
                            {
                                label: `${lang.CLOUD_REPOSITORY_NAME}:`,
                                value: data.repositoryFriendlyName,
                            },
                            {
                                label: `${lang.USER_QUOTA}:`,
                                value: data.repositoryQuota,
                                transform: (repositoryQuota: number) => data.isUnlimited
                                    ? lang.UNLIMITED
                                    : dataSizeToString(repositoryQuota, 0, 'G'),
                            },
                        ],
                    },
                    {
                        title: `${lang.MULTI_FACTOR_AUTHENTICATION}:`,
                        fields: [
                            {
                                label: `${lang.MFA_STATUS}:`,
                                value: data.mfaPolicyStatus,
                                transform: (value: UserMfaStatuses) => value === UserMfaStatuses.Disabled ? lang.NOT_ENFORCED : lang.ENFORCED,
                            },
                        ],
                    },
                    {
                        renderBody: (data.userRole === PortalUserRoles.CompanyOwner || data.userRole === PortalUserRoles.CompanyInvoiceAuditor) && (
                            <CheckboxKit
                                checked={data.sendInvoice}
                                onChange={(isChecked) => {
                                    data.sendInvoice = isChecked;

                                    onDataChange(data);
                                }}
                            >
                                {lang.ENABLE_AUTOMATIC_DELIVERY_OF_INVOICES}
                            </CheckboxKit>
                        ),
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getSummaryStep = (title: string): WizardStep<CompanyUserModel> => ({
    title,
    render: data => <Summary {...data} />,
});
