/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { ROLES_AND_USERS_PAGE, RolesAndUsersPage } from 'views/pages/RolesAndUsersPage';
import { OverviewPage } from 'views/pages/OverviewPage';
import { OVERVIEW_PAGE } from 'views/pages/OverviewPage/configs';
import { BACKUP_JOBS_PAGE, BackupJobsPage } from 'views/pages/BackupJobsPage';
import { PROTECTED_DATA_PAGE, ProtectedDataPage } from 'views/pages/ProtectedDataPage';
import { DISCOVERY_PAGE, DiscoveryPage } from 'views/pages/DiscoveryPage';
import { PageProvider } from '../PageProvider';
import { COMPANIES_PAGE } from '../../pages/CompaniesPage';
import { REPORT_PAGE, ReportPage } from '../../pages/ReportPage';
import { SUBSCRIPTION_PLAN_PAGE, SubscriptionPlanPage } from 'views/pages/SubscriptionPlanPage';
import { CompanyWizard } from '../../pages/CompaniesPage/components/CompanyWizard';
import { RESELLERS_PAGE } from '../../pages/ResellersPage';
import { ResellerWizard } from '../../pages/ResellersPage/components/ResellerWizard';
import { TRENDS_PAGE, TrendsPage } from '../../pages/TrendsPage';
import { JOBS_SUMMARY, JobsSummaryPage } from '../../pages/JobsSummaryPage';
import { PLUGIN_LIBRARY_PAGE, PluginLibraryPage } from '../../pages/PluginLibraryPage';
import { PROTECTED_DATA_SUMMARY_PAGE, ProtectedDataSummary } from '../../pages/ProtectedDataSummary';
import { NOTIFICATIONS_PAGE, NotificationsPage } from 'views/pages/NotificationsPage';
import { AppBell } from 'views/components/AppBell';

import type { NotificationsPageProps } from 'views/pages/NotificationsPage';
import type { ReportWizardProp } from 'views/pages/ReportPage/components/wizards/ReportWizard';
import type { SubscriptionPlanProp } from 'views/pages/SubscriptionPlanPage/components/wizards/SubscriptionPlan';
import type { JobsOverviewProps } from 'views/pages/JobsSummaryPage/components/JobsOverview/interfaces/jobs-overview-props';
import type { RouterProviderProps } from './interfaces';
import type { RolesAndUsersProps } from 'views/pages/RolesAndUsersPage/interfaces/roles-and-users-props';
import type { CompanyWizardProps } from '../../pages/CompaniesPage/components/CompanyWizard';
import type { ResellerWizardProps } from '../../pages/ResellersPage/components/ResellerWizard';
import type { DiscoveryPageProps } from 'views/pages/DiscoveryPage';
import type { ProtectedDataPageProps } from 'views/pages/ProtectedDataPage';
import type { BackupJobsPageProps } from 'views/pages/BackupJobsPage';
import type { TrendsPageProps } from '../../pages/TrendsPage';

export const RouterProvider: React.FC<RouterProviderProps> = ({ pageName, pageProps }) => (
    <PageProvider>
        {(() => {
            switch (pageName) {
                case ROLES_AND_USERS_PAGE:
                    return <RolesAndUsersPage {...pageProps as RolesAndUsersProps} />;
                case REPORT_PAGE:
                    return <ReportPage {...pageProps as ReportWizardProp} />;
                case SUBSCRIPTION_PLAN_PAGE:
                    return <SubscriptionPlanPage {...pageProps as SubscriptionPlanProp} />;
                case BACKUP_JOBS_PAGE:
                    return <BackupJobsPage {...pageProps as BackupJobsPageProps} />;
                case PROTECTED_DATA_PAGE:
                    return <ProtectedDataPage {...pageProps as ProtectedDataPageProps} />;
                case DISCOVERY_PAGE:
                    return <DiscoveryPage {...pageProps as DiscoveryPageProps} />;
                case OVERVIEW_PAGE:
                    return <OverviewPage />;
                case JOBS_SUMMARY:
                    return <JobsSummaryPage {...pageProps as JobsOverviewProps} />;
                case TRENDS_PAGE:
                    return <TrendsPage {...pageProps as TrendsPageProps} />;
                case COMPANIES_PAGE:
                    return <CompanyWizard {...pageProps as CompanyWizardProps} />;
                case RESELLERS_PAGE:
                    return <ResellerWizard {...pageProps as ResellerWizardProps} />;
                case PLUGIN_LIBRARY_PAGE:
                    return <PluginLibraryPage />;
                case PROTECTED_DATA_SUMMARY_PAGE:
                    return <ProtectedDataSummary />;
                case NOTIFICATIONS_PAGE:
                    return <NotificationsPage {...pageProps as NotificationsPageProps} />;
                case 'AppBell':
                    return <AppBell />;
                default:
                    return <div />;
            }
        })()}
    </PageProvider>
);
