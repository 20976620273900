/**
 * Copyright © Veeam Software Group GmbH.
 */

import { PlatformTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import { InfrastructureType } from '../../../../enums/infrastructure-type';

import type { LangsServiceBase } from '../../../../../../../../../core/services/langs/interfaces';

export const getPlatformOptions = (infrastructureType: InfrastructureType, lang: LangsServiceBase) => {
    const optionsDefault = [
        { id: PlatformTypeRepresentation.Unknown, name: lang.ALL },
    ];
    const optionsLocal = [
        { id: PlatformTypeRepresentation.VSphere, name: lang.VSPHERE },
        { id: PlatformTypeRepresentation.HyperV, name: lang.HYPER_V },
        { id: PlatformTypeRepresentation.Nutanix, name: lang.AHV },
        { id: PlatformTypeRepresentation.Physical, name: lang.PHYSICAL },
    ];
    const optionsCloud = [
        { id: PlatformTypeRepresentation.Aws, name: lang.AMAZON_WEB_SERVICES },
        { id: PlatformTypeRepresentation.Azure, name: lang.MICROSOFT_AZURE },
        { id: PlatformTypeRepresentation.Google, name: lang.GOOGLE_CLOUD },
        { id: PlatformTypeRepresentation.Microsoft365, name: lang.MICROSOFT_365 },
    ];

    if (infrastructureType === InfrastructureType.Local) {
        return [...optionsDefault, ...optionsLocal];
    }

    if (infrastructureType === InfrastructureType.Cloud) {
        return [...optionsDefault, ...optionsCloud];
    }

    if (infrastructureType === InfrastructureType.All) {
        return [...optionsDefault, ...optionsCloud, ...optionsLocal];
    }
};
