/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { PortalSpinner, useGridApi } from '@veeam-vspc/components';

import type { NotificationParams } from '@veeam-vspc/core';

import { useAppServices } from 'views/providers/AppProvider/hooks';

import type { UseAsyncActionData } from './interfaces';

export const useRestAsyncAction = (params: NotificationParams = {}): UseAsyncActionData => {
    const [loading, setLoading] = useState(false);
    const { transportService, notificationService } = useAppServices();
    const gridApi = useGridApi();
    const asyncActionLoader = loading && <PortalSpinner />;

    const request = (method: 'get' | 'post' | 'patch' | 'put' | 'delete') =>
        (url: string, title: string, data: Record<string, any> = {}): Promise<void> => {
            setLoading(true);

            const callback = (resp) => {
                setLoading(false);

                return notificationService.multi(title, resp, params)
                    .then(() => gridApi.reloadGrid());
            };

            return transportService[method](url, data)
                .then(resp => callback(resp))
                .catch(resp => callback(resp));
        };

    const doAsyncAction = {
        get: request('get'),
        post: request('post'),
        patch: request('patch'),
        put: request('put'),
        delete: request('delete'),
    };

    return [asyncActionLoader, doAsyncAction];
};
