/**
 * Copyright © Veeam Software Group GmbH.
 */

import { PortalUserRoles } from '../../../entries/portal-user/enums';
import { ConfigSectionIds, ConfigStates } from '../enums';
import { baseConfig } from './base.config';

import type { BaseUserConfig } from '../interfaces';

export const subtenantConfig: BaseUserConfig = {
    baseConfig: baseConfig,
    roleInt: PortalUserRoles.Subtenant,
    roleName: PortalUserRoles[PortalUserRoles.Subtenant] as keyof typeof PortalUserRoles,
    routes: [],
    sections: [
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByConsole,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
            defaultActionsState: ConfigStates.Hidden,
        },
        {
            id: ConfigSectionIds.BackupJobsComputersManagedByBackupServer,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.BackupJobsFileSharesLocal,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataComputers,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
        {
            id: ConfigSectionIds.ProtectedDataFileSharesLocal,
            columns: {
                companyName: ConfigStates.Hidden,
                siteName: ConfigStates.Hidden,
            },
        },
    ],
};
