/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TextColumn } from '@veeam-vspc/components';
import { Vb365JobItemComposed } from '@veeam-vspc/models/rest';

import type { CellRendererProps } from '@veeam-vspc/components';
import type { FC } from 'react';

import { useLang } from 'views/providers/LangProvider/hooks';
import { groupTypeTitles, resourceTypesTitles } from '../../helpers';

export const ObjectTypeCell: FC<CellRendererProps<Vb365JobItemComposed>> = ({ rowData }) => {
    const lang = useLang();

    if (rowData.itemType === Vb365JobItemComposed.ItemTypeEnum.User) {
        return <TextColumn>{resourceTypesTitles(rowData.user.userType, lang)}</TextColumn>;
    }

    if (rowData.itemType === Vb365JobItemComposed.ItemTypeEnum.Group) {
        return <TextColumn>{groupTypeTitles(rowData.group.groupType, lang)}</TextColumn>;
    }

    return <TextColumn>{resourceTypesTitles(rowData.itemType, lang)}</TextColumn>;
};
