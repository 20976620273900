/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
} from '@veeam-vspc/components';

import type { ResellerCloudWanResourceMapModel } from '@veeam-vspc/models/web-controllers';
import type { ToolbarItems } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { canRemoveResource } from '../../../../utils';
import { WanServerDialog } from '../WanServerDialog';
import addIcon from 'images/actions/add.svg';
import removeIcon from 'images/actions/remove.svg';


type GridItemType = ResellerCloudWanResourceMapModel;

interface Props {
    items: GridItemType[];
    cloudConnectAgentUids: string[];
}

export const ActionsToolbar = ({ items, cloudConnectAgentUids }: Props) => {
    const lang = useLang();
    const gridApi = useGridApi<GridItemType, void, void>();

    const [wanServerDialog, wanServerDialogActions] = useModal<GridItemType>({
        render: ({ deactivate, data }) => (
            <WanServerDialog
                data={data}
                existingItems={items}
                cloudConnectAgentUids={cloudConnectAgentUids}
                onConfirm={(newData) => {
                    if (data) {
                        items[items.findIndex(item => item.cloudConnectResourceUid === data.cloudConnectResourceUid)] = newData;
                    } else {
                        items.push(newData);
                    }
                    gridApi.reloadGrid();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const toolbarItems: ToolbarItems = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: addIcon,
            text: lang.ADD,
            onClick: () => {
                wanServerDialogActions.activate();
            },
            decorators: [
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: removeIcon,
            text: lang.REMOVE,
            onClick: async() => {
                const selected = gridApi.selected[0];
                if (
                    await canRemoveResource(
                        selected,
                        lang.CANNOT_DELETE_THE_WAN,
                        lang.THIS_ACTION_WILL_REMOVE_WAN,
                        lang
                    )
                ) {
                    items.splice(items.findIndex(item => item.cloudConnectResourceUid === selected.cloudConnectResourceUid), 1);
                    gridApi.reloadGrid();
                }
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
    ];

    return (
        <>
            <Toolbar items={toolbarItems} />
            {wanServerDialog}
        </>
    );
};
