/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn } from '@veeam-vspc/components';

import { JobTypes } from 'core/enums';
import { useLang } from 'views/providers/LangProvider/hooks';
import { CdpJobStatisticsDialog, JobStatisticsDialog } from './components';

export interface JobStatisticsColumnProps {
    jobType: JobTypes;
    jobUid: string;
    jobName: string;
}

export const JobStatisticsColumn: React.FC<JobStatisticsColumnProps> = ({ jobType, jobUid, jobName }) => {
    const lang = useLang();
    const title = `${lang.JOB_STATISTICS} - ${jobName}`;

    return (
        <ActionLinkColumn
            content={lang.DETAILS}
            isLink={true}
            isExtMode={true}
            renderModal={({ deactivate }) => {
                if (jobType === JobTypes.CdpReplica) {
                    return <CdpJobStatisticsDialog deactivate={deactivate} jobUid={jobUid} title={title} />;
                }

                return <JobStatisticsDialog deactivate={deactivate} jobUid={jobUid} title={title} />;
            }}
        />
    );
};
