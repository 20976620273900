/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { Dialog, DIALOG_SIZE, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE, Search, ACTION_VIEW, TextColumn, SortingMode } from '@veeam-vspc/components';
import { Vb365JobItemComposed } from '@veeam-vspc/models/rest';

import type { GridStore, CellRendererProps } from '@veeam-vspc/components';
import type { FC } from 'react';
import type { Vb365JobItemSite } from '@veeam-vspc/models/rest';

import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { AdvancedToolbar } from 'views/components/AdvancedToolbar';
import { useJobWizardStore } from '../../../../stores';
import { BackupModeService } from '../../services';

import type { SitesModalProps, SitesFilters } from '../../interfaces';

export const SitesModal: FC<SitesModalProps> = ({ onCancel, onApply }) => {
    const lang = useLang();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<Vb365JobItemSite, unknown, unknown>>();

    const apply = () => {
        const selected = api.current.selected;

        onApply(selected);
    };

    const { vb365ServerUid, instanceUid } = wizardStore.selectedOrganization;

    const defaultColumnRender = ({ cellData }: CellRendererProps<Vb365JobItemSite>) => <TextColumn>{cellData}</TextColumn>;

    const isRowDisabled = (rowData: Vb365JobItemSite) => !rowData.isAvailable ||
        wizardStore.isItemSelected(rowData.id, Vb365JobItemComposed.ItemTypeEnum.Site);

    return (
        <Dialog
            header={lang.ADD_SITES}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={onCancel}
            actions={[
                { text: lang.ADD, onClick: apply },
                { text: lang.CLOSE, onClick: onCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <AdvancedGrid<Vb365JobItemSite, unknown, SitesFilters>
                api={api}
                columns={[
                    { field: 'title', title: lang.TITLE, cell: defaultColumnRender },
                    { field: 'url', title: lang.URL, cell: defaultColumnRender },
                ]}
                data={BackupModeService.getSites(vb365ServerUid, instanceUid, api.current)}
                disableAutoUpdate
                sortingMode={SortingMode.Local}
                useCompactPaginationWithCurrentPage
                toolbars={[
                    () => (
                        <AdvancedToolbar
                            view={TOOLBAR_VIEW_TYPE.spotted}
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='multifilter'
                                            placeholder={lang.TITLE}
                                            hasSearchButton
                                            maxLength={256}
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
                selection={{
                    field: 'id',
                    checkbox: true,
                    multiple: true,
                }}
                isRowExcludedFromAllSelection={isRowDisabled}
                isRowDisabled={({ rowData }) => isRowDisabled(rowData)}
            />
        </Dialog>
    );
};
