/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    LabelGroup,
    STACK_DIRECTION,
    StackView,
    STACK_GAP,
    Combobox,
    CONTROL_SIZE,
    NumberInput,
} from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';

import type { AllowedBandwidthUnits } from 'views/components/wizard/enums';

interface Props {
    as?: any;
    label?: string | React.ReactElement<{
        disabled?: boolean;
    }>;
    valueName: string;
    unitsName: string;
    suffix?: string;
    suffix2?: string;
    unitSize?: CONTROL_SIZE;
    hasSubOffset?: boolean;
    disabled?: boolean;
    allowDecimal?: boolean;
    onChange?: () => void;
    units?: { name: string; value: AllowedBandwidthUnits; }[];
    minValue?: number;
    maxValue?: number;
}

export const PerBytesField = (({
    as,
    label,
    valueName,
    unitsName,
    suffix,
    suffix2,
    hasSubOffset,
    disabled,
    unitSize,
    allowDecimal,
    units,
    minValue,
    maxValue }: Props) => {
    const lang = useLang();

    const unitList: { name: string; value: number | string; }[] = units ?? [
        {
            name: lang.GB,
            value: 1,
        }, {
            name: lang.TB,
            value: 2,
        },
    ];

    const resultLabel = hasSubOffset ? (<div style={{ marginLeft: '28px' }}>{label}</div>) : label;

    return (
        <LabelGroup asTag={as} label={resultLabel} disabled={disabled}>
            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                <NumberInput
                    name={valueName}
                    allowDecimal={allowDecimal !== false}
                    precision={4}
                    suffix={suffix}
                    minValue={minValue || 0}
                    maxValue={maxValue}
                />

                <Combobox
                    name={unitsName}
                    data={unitList}
                    valueGetter={item => item.value}
                    textGetter={item => item.name}
                    size={unitSize || CONTROL_SIZE.xxs}
                    suffix={suffix2}
                />
            </StackView>
        </LabelGroup>
    );
});
