/**
 * Copyright © Veeam Software Group GmbH.
 */

import { formatStr, NotificationDialogsTextKeys } from '@veeam-vspc/core';

import type { NotificationResponse } from '@veeam-vspc/core';

import { core } from 'core/core-module';

import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';
import type { ResellerCompanyMapResource } from '../../../interfaces';

export const canRemoveResource = (res: ResellerCompanyMapResource, errorText: string, confirmText: string, lang: LangsServiceBase) => {
    const companyNames = res.companyMap?.map(item => item.companyName);
    if (companyNames?.length > 0) {
        core.notificationService.error(
            lang.REMOVE_RESOURCE, formatStr(errorText, res.cloudConnectResourceName, companyNames.join(', '))
        );
        return Promise.resolve(false);
    }

    return core.notificationService.confirm(lang.REMOVE_RESOURCE, confirmText)
        .then((btnKey: NotificationResponse) => btnKey === NotificationDialogsTextKeys.Yes);
};

export const canRemoveVboResource = (res: ResellerCompanyMapResource, errorText: string, confirmText: string, lang: LangsServiceBase) => {
    const companyNames = res.companyMap?.map(item => item.companyName);
    if (companyNames?.length > 0) {
        core.notificationService.error(
            lang.REMOVE_RESOURCE, formatStr(errorText, companyNames.join(', '))
        );
        return Promise.resolve(false);
    }

    return core.notificationService.confirm(lang.REMOVE_RESOURCE, confirmText)
        .then((btnKey: NotificationResponse) => btnKey === NotificationDialogsTextKeys.Yes);
};
