/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable } from 'mobx';
import { MonitoredObjectType } from '@veeam-vspc/models/web-controllers';

export class MonitoredObjectsStore {
    @observable monitoredObjectType = MonitoredObjectType.Vm;

    @action.bound
    setMonitoredObjectType(v: MonitoredObjectType): void {
        this.monitoredObjectType = v;
    }
}
