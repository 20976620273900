/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';

import { InstallAndUpdateVBRWizard } from './components/InstallAndUpdateVBRWizard';
import { ScheduledUpdatesDialog } from './components/ScheduledUpdatesDialog';
import { DiscoveryPageDialogTypes, DiscoveryPageWizardTypes } from './enums';

import type { DiscoveryPageDialogsProps, DiscoveryPageWizardsProps } from './interfaces';

export const DISCOVERY_PAGE = 'DiscoveryPage';

export interface DiscoveryPageProps extends DiscoveryPageDialogsProps, DiscoveryPageWizardsProps {}

export const DiscoveryPage: React.FC<DiscoveryPageProps> = props => (
    <>
        {props.dialogName === DiscoveryPageDialogTypes.ScheduledUpdates && <ScheduledUpdatesDialog {...props.dialogData} />}
        {props.wizardName === DiscoveryPageWizardTypes.InstallAndUpdateVBRWizard && <InstallAndUpdateVBRWizard {...props.wizardData} />}
    </>
);
