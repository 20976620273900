/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Combobox } from '@veeam/components';
import {
    ACTION_VIEW,
    DIALOG_SIZE,
    Icon,
    LinkButton,
    SelectLink,
    SPACE_FILL,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    useModal,
} from '@veeam-vspc/components';
import { Vb365JobItemComposed, Vb365OrganizationBase } from '@veeam-vspc/models/rest';

import type { GridStore, RequestParams } from '@veeam-vspc/components';
import type { Vb365JobItemGroup, Vb365JobItemSite, Vb365JobItemTeam, Vb365JobItemUser } from '@veeam-vspc/models/rest';
import type { FC } from 'react';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useJobWizardStore } from '../../../../stores';
import { resourceTypes, resourceValueGetter, sortResources } from '../../helpers';
import protectedObjectsIconSrc from 'images/actions/protected-objects.png';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { UsersModal } from '../UsersModal/UsersModal';
import { ObjectCell } from '../ObjectCell/ObjectCell';
import { OptionsCell } from '../OptionsCell/OptionsCell';
import { GroupsModal } from '../GroupsModal/GroupsModal';
import { SitesModal } from '../SitesModal/SitesModal';
import { TeamsModal } from '../TeamsModal/TeamsModal';
import { ProcessingOptions } from '../ProcessingOptions/ProcessingOptions';
import { BackupOptionsToolbar } from '../BackupOptionsToolbar/BackupOptionsToolbar';
import { BackupOptionsTarget } from '../../../../enums';
import { ObjectTypeCell } from '../ObjectTypeCell/ObjectTypeCell';

import type { ConfigurableOption, ConfigureBackupObjectsProps } from '../../interfaces';

export const ConfigureBackupObjects: FC<ConfigureBackupObjectsProps> = observer(({ hidePanel }) => {
    const lang = useLang();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<Vb365JobItemComposed, unknown, unknown>>();
    const [itemType, setItemType] = useState<Vb365JobItemComposed.ItemTypeEnum>(Vb365JobItemComposed.ItemTypeEnum.User);

    const [optionsModal, { activate: activateOptionsModal }] = useModal<Vb365JobItemComposed[]>({
        render: ({ deactivate, data }) => {
            if (!data) {
                return;
            }

            let tree: Partial<Record<ConfigurableOption, ConfigurableOption[]>>;
            switch (data[0].itemType) {
                case Vb365JobItemComposed.ItemTypeEnum.Group:
                    tree = {
                        backupMembers: ['backupMemberMailbox', 'backupMemberArchiveMailbox', 'backupMemberOneDrive', 'backupMemberSite'],
                    };
                    break;
                case Vb365JobItemComposed.ItemTypeEnum.PartialOrganization:
                    tree = {
                        backupTeams: ['backupTeamsChats'],
                    };
                    break;
                default:
                    tree = {};
            }

            const heights = {
                [Vb365JobItemComposed.ItemTypeEnum.Group]: DIALOG_SIZE.l,
                [Vb365JobItemComposed.ItemTypeEnum.User]: DIALOG_SIZE.s,
                [Vb365JobItemComposed.ItemTypeEnum.PartialOrganization]: DIALOG_SIZE.m,
            };

            const disabledItems: ConfigurableOption[] = !wizardStore.selectedOrganization.protectedServices
                .includes(Vb365OrganizationBase.ProtectedServicesEnum.MicrosoftTeamsChats)
                ? ['backupTeamsChats']
                : [];

            return (
                <ProcessingOptions
                    onClose={deactivate}
                    onSave={saveOptions}
                    resources={data}
                    store={wizardStore}
                    branches={tree}
                    disabledItems={disabledItems}
                    height={heights[data[0].itemType]}
                />
            );
        },
    });

    const saveOptions = (resources: Vb365JobItemComposed[]) => {
        wizardStore.updateResources(resources, BackupOptionsTarget.SelectedItems);
    };

    const handleSelectItems = (
        selected?: Array<Vb365JobItemGroup | Vb365JobItemTeam | Vb365JobItemSite | Vb365JobItemUser>,
        deactivate?: () => void,
    ) => {
        switch (itemType) {
            case Vb365JobItemComposed.ItemTypeEnum.User:
                wizardStore.addUsersToResources(selected as Vb365JobItemUser[], BackupOptionsTarget.SelectedItems);
                break;
            case Vb365JobItemComposed.ItemTypeEnum.Group:
                wizardStore.addGroupsToResources(selected as Vb365JobItemGroup[], BackupOptionsTarget.SelectedItems);
                break;
            case Vb365JobItemComposed.ItemTypeEnum.Team:
                wizardStore.addTeamsToResources(selected as Vb365JobItemTeam[], BackupOptionsTarget.SelectedItems);
                break;
            case Vb365JobItemComposed.ItemTypeEnum.Site:
                wizardStore.addSitesToResources(selected as Vb365JobItemSite[], BackupOptionsTarget.SelectedItems);
                break;
            case Vb365JobItemComposed.ItemTypeEnum.PartialOrganization:
                wizardStore.addCurrentOrganizationToResources(BackupOptionsTarget.SelectedItems);
                break;
            case Vb365JobItemComposed.ItemTypeEnum.PersonalSites:
                wizardStore.addPersonalSiteToResources(BackupOptionsTarget.SelectedItems);
                break;
        }

        deactivate && deactivate();
        api.current?.fetchData();
    };

    const handleDeleteItems = (items: Vb365JobItemComposed[]) => {
        wizardStore.removeResourcesFromBackupResources(items);
        api.current?.reloadGrid();
    };

    const showOptionsModal = (resources: Vb365JobItemComposed[]) => {
        if (!resources || !resources.length) {
            return;
        }

        const observableResources = resources.map(r => wizardStore.selectedItems.find(x => r.id === x.id));

        activateOptionsModal(observableResources);
    };

    const getItemName = (x: Vb365JobItemComposed) => {
        if (x.itemType === Vb365JobItemComposed.ItemTypeEnum.PersonalSites) {
            return lang.PERSONAL_SITES;
        }

        if (x.itemType === Vb365JobItemComposed.ItemTypeEnum.PartialOrganization) {
            return wizardStore.selectedOrganization.name;
        }

        return resourceValueGetter(x, 'name', wizardStore);
    };

    const fetchData = (
        { page, limit, sort, filter }: RequestParams<Vb365JobItemComposed & { name: string; }>,
    ): Promise<BaseSuccessRequestResponse<Vb365JobItemComposed[]>> => {
        let items = wizardStore.selectedItems.slice();

        if (sort && sort.length) {
            sortResources(items, sort, wizardStore);
        }

        items = items
            .filter(x => getItemName(x)
                .toLowerCase()
                .includes((filter.name || '').trim().toLowerCase()),
            );

        const chunk = items
            .slice((page * limit - limit), page * limit)
            .map((x) => {
                if (x.itemType === Vb365JobItemComposed.ItemTypeEnum.PersonalSites) {
                    return { ...x, name: lang.PERSONAL_SITES };
                }

                if (x.itemType === Vb365JobItemComposed.ItemTypeEnum.PartialOrganization) {
                    return { ...x, name: wizardStore.selectedOrganization.name };
                }

                return x;
            });

        return Promise.resolve({
            data: chunk,
            meta: {
                pagingInfo: {
                    total: items.length,
                },
            },
        } as BaseSuccessRequestResponse<Vb365JobItemComposed[]>);
    };

    const handleApply = () => {
        wizardStore.updateSelectedItems();
        hidePanel();
    };

    const handleCancel = () => {
        wizardStore.cancelSelectedItems();
        hidePanel();
    };

    const couldBeSitesSelected = () => wizardStore.selectedOrganization.protectedServices
        .includes(Vb365OrganizationBase.ProtectedServicesEnum.SharePointOnlineAndOneDriveForBusiness)
        || wizardStore.selectedOrganization.protectedServices
            .includes(Vb365OrganizationBase.ProtectedServicesEnum.MicrosoftSharePointServer);

    return (
        <SidePanelForm
            title={lang.OBJECTS_TO_BACK_UP}
            description={lang.SPECIFY_OBJECTS_TO_INCLUDE_IN_THE_BACKUP}
            onRequestClose={handleCancel}
            actions={[
                { text: lang.APPLY, onClick: handleApply },
                { text: lang.CANCEL, onClick: handleCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.xxxl} spaceFill={SPACE_FILL.all}>
                <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.xl}>
                    <Combobox
                        value={itemType}
                        onChange={setItemType}
                        data={resourceTypes(lang).filter((x) => {
                            if (
                                x.value === Vb365JobItemComposed.ItemTypeEnum.PersonalSites &&
                                wizardStore.excludedItems.find(x => x.itemType === Vb365JobItemComposed.ItemTypeEnum.PersonalSites)
                            ) {
                                return false;
                            }

                            if (
                                x.value === Vb365JobItemComposed.ItemTypeEnum.Team &&
                                !wizardStore.selectedOrganization.protectedServices
                                    .includes(Vb365OrganizationBase.ProtectedServicesEnum.MicrosoftTeams)
                            ) {
                                return false;
                            }

                            if (x.value === Vb365JobItemComposed.ItemTypeEnum.Site || x.value === Vb365JobItemComposed.ItemTypeEnum.PersonalSites) {
                                return couldBeSitesSelected();
                            }

                            return true;
                        })}
                        valueGetter={x => x.value}
                        textGetter={x => x.text}
                    />

                    {
                        itemType === Vb365JobItemComposed.ItemTypeEnum.PartialOrganization ||
                        itemType === Vb365JobItemComposed.ItemTypeEnum.PersonalSites
                            ? (
                                <LinkButton onClick={() => handleSelectItems()}>
                                    {lang.ADD}
                                </LinkButton>
                            )
                            : (
                                <SelectLink
                                    renderModal={({ deactivate }) => {
                                        if (itemType === Vb365JobItemComposed.ItemTypeEnum.User) {
                                            return (
                                                <UsersModal
                                                    onCancel={deactivate}
                                                    onApply={items => handleSelectItems(items, deactivate)}
                                                />
                                            );
                                        }

                                        if (itemType === Vb365JobItemComposed.ItemTypeEnum.Group) {
                                            return (
                                                <GroupsModal
                                                    onCancel={deactivate}
                                                    onApply={items => handleSelectItems(items, deactivate)}
                                                />
                                            );
                                        }

                                        if (itemType === Vb365JobItemComposed.ItemTypeEnum.Site) {
                                            return (
                                                <SitesModal
                                                    onCancel={deactivate}
                                                    onApply={items => handleSelectItems(items, deactivate)}
                                                />
                                            );
                                        }

                                        if (itemType === Vb365JobItemComposed.ItemTypeEnum.Team) {
                                            return (
                                                <TeamsModal
                                                    onCancel={deactivate}
                                                    onApply={items => handleSelectItems(items, deactivate)}
                                                />
                                            );
                                        }
                                    }}
                                    renderContent={() => lang.BROWSE}
                                />
                            )
                    }
                </StackView>

                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m} spaceFill={SPACE_FILL.all}>
                    <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                        <Icon src={protectedObjectsIconSrc} />
                        <Text>{`${lang.PROTECTED_OBJECTS} (${wizardStore.selectedItems.length})`}</Text>
                    </StackView>

                    <AdvancedGrid
                        api={api}
                        columns={[
                            { field: 'name', title: lang.OBJECT, cell: params => <ObjectCell {...params} wizardStore={wizardStore} /> },
                            { field: 'itemType', title: lang.TYPE, cell: ObjectTypeCell },
                            {
                                field: 'options',
                                title: lang.PROCESSING_OPTIONS,
                                cell: params => (
                                    <OptionsCell
                                        {...params}
                                        store={wizardStore}
                                        collection={BackupOptionsTarget.SelectedItems}
                                        onClick={() => showOptionsModal([params.rowData])}
                                    />
                                ),
                            },
                        ]}
                        data={fetchData}
                        disableAutoUpdate
                        selection={{
                            field: 'id',
                            checkbox: true,
                            multiple: true,
                        }}
                        toolbars={[
                            () => (
                                <BackupOptionsToolbar
                                    onEdit={showOptionsModal}
                                    onRemove={handleDeleteItems}
                                    viewType={BackupOptionsTarget.SelectedItems}
                                />
                            ),
                        ]}
                    />
                </StackView>
            </StackView>

            {optionsModal}
        </SidePanelForm>
    );
});
