/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, computed, observable } from 'mobx';
import { BackupServicesType, TimeUnit } from '@veeam-vspc/models/web-controllers';

import { ReportingPeriodInfo } from '../interfaces';

export class FilterStore {
    @observable backupServicesType = BackupServicesType.All;
    @observable reportingPeriod = TimeUnit.Months;

    @action.bound
    setBackupServicesType(backupServicesType: BackupServicesType): void {
        this.backupServicesType = backupServicesType;
    }

    @action.bound
    setReportingPeriod(timeUnit: TimeUnit): void {
        this.reportingPeriod = timeUnit;
    }

    @computed
    get reportingPeriodInfo(): ReportingPeriodInfo {
        return {
            aggregation: { unit: this.reportingPeriod },

            interval: {
                count: this.reportingPeriod === TimeUnit.Months ? 12 : 9,
                unit: this.reportingPeriod,
            },
        };
    }
}
