/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    CONTROL_SIZE,
    NodeData,
    SearchKit,
    SEARCH_MODE,
    SPACE_FILL,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TreeView,
} from '@veeam-vspc/components';

import type { WizardStepData } from '@veeam-vspc/components';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import cloudServiceIcon from 'images/icons/vcd/cloud-service.png';
import vcdSystemIcon from 'images/icons/vcd/vcd-system.png';
import { useCompanyWizardStore } from '../../../../hooks';
import { getOrganization } from '../../constants';

import type { StepData, VcdCommonData, VcdOrganization, VcdOrganizationsData } from '../../interfaces';
import type { CompanyData } from '../../../../interfaces';


interface Props extends WizardStepData<CompanyData> {
    hidePanel: () => void;
}

export const Organizations = observer((props: Props) => {
    const lang = useLang();
    const { data, onDataChange, hidePanel } = props;
    const wizardStore = useCompanyWizardStore();

    const orgToNode = (org: VcdOrganization): NodeData<VcdCommonData, string> => new NodeData<VcdCommonData, string>(org, org.uniqueUid, 1);

    const vcdDataToNodes = (items: VcdOrganizationsData[]) => {
        const result: NodeData<VcdCommonData, string>[] = [];

        items.forEach((item) => {
            const node = new NodeData<VcdCommonData, string>(item, item.cloudConnectResourceUid);
            if (item.organizations) {
                node.children = item.organizations.map(orgToNode);
            }
            result.push(node);
        });

        return result;
    };

    const [nodes] = useState<NodeData<VcdCommonData, string>[]>(
        vcdDataToNodes(wizardStore.vcdOrganizationsDataItemsCache.value)
    );
    const [filteredNodes, setFilteredNodes] = useState<NodeData<VcdCommonData, string>[]>(nodes);
    const [selectedIds, setSelectedIds] = useState(() => {
        const uniqueUid = getOrganization(wizardStore.vcdOrganizationsDataItemsCache.value, data.vcdOrganizationUid)?.uniqueUid;
        if (uniqueUid) {
            return [uniqueUid];
        }
        return [];
    });
    const [searchValue, setSearchValue] = useState('');

    const filter = (value: string) => {
        const lcSearchValue = value.toLowerCase();
        const filtered: NodeData<VcdCommonData, string>[] = [];

        nodes.forEach((ccServerNode) => {
            const children = ccServerNode.children.filter(orgNode =>
                !lcSearchValue
                || orgNode.data.cloudConnectResourceName.toLowerCase().includes(lcSearchValue)
            );
            if (!lcSearchValue || children.length > 0 || ccServerNode.data.cloudConnectResourceName.toLowerCase().includes(lcSearchValue)) {
                const newCcServerNode = new NodeData(ccServerNode.data, ccServerNode.id);
                newCcServerNode.children = children;
                filtered.push(newCcServerNode);
            }
        });

        setFilteredNodes(filtered);
    };

    const onSearch = (value: string) => {
        filter(value);
    };

    const onClear = () => {
        filter('');
    };

    const getNodeById = (nodes: NodeData<VcdCommonData, string>[], id: string) => {
        let result: NodeData<VcdCommonData, string>;
        nodes.forEach((node) => {
            if (!result) {
                result = node.getById(id);
            }
        });
        return result;
    };

    return (
        <SidePanelForm
            title={lang.SELECT_ORGANIZATION}
            description={`${lang.SELECT_AN_ORGANIZATION_FOR}.`}
            onRequestClose={hidePanel}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        if (selectedIds.length === 1) {
                            const node = getNodeById(filteredNodes, selectedIds[0]);
                            if (node) {
                                if (node.data.cloudConnectResourceUid !== data.vcdOrganizationUid) {
                                    // clear vcdReplicationResources and vcdNetworksExtensions when VCD Organization has been changed
                                    data.vcdReplicationResources = [];
                                    data.vcdNetworksExtensions = [];
                                    data.networkFailoverResourcesEnabled = false;
                                }

                                data.vcdOrganizationUid = node.data.cloudConnectResourceUid;
                                data.cloudConnectAgentUid = node.data.cloudConnectAgentUid;
                                (props.stepData as StepData).setBrowseButtonError(false);
                                onDataChange(data);

                                hidePanel();
                            }
                        }
                    },
                    disabled: selectedIds.length === 0 || !getNodeById(filteredNodes, selectedIds[0]),
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s} spaceFill={SPACE_FILL.all}>
                <SearchKit
                    size={CONTROL_SIZE.full}
                    placeholder={lang.NAME}
                    value={searchValue}
                    onChange={setSearchValue}
                    onSearch={onSearch}
                    onClear={onClear}
                    mode={SEARCH_MODE.automatic}
                />

                <TreeView
                    nodeData={filteredNodes}
                    selected={selectedIds}
                    onSelect={setSelectedIds}
                    nodeRenderer={item => item.data.cloudConnectResourceName}
                    iconRenderer={({ node }) => node.depth === 0 ? vcdSystemIcon : cloudServiceIcon}
                    expandAll
                />
            </StackView>
        </SidePanelForm>
    );
});
