/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Vb365JobItemComposed, Vb365JobItemUser } from '@veeam-vspc/models/rest';

import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const resourceTypesTitles = (
    type: Vb365JobItemComposed.ItemTypeEnum | Vb365JobItemUser.UserTypeEnum,
    lang: LangsServiceBase
) => ({
    [Vb365JobItemComposed.ItemTypeEnum.Group]: lang.GROUP,
    [Vb365JobItemComposed.ItemTypeEnum.User]: lang.USER,
    [Vb365JobItemUser.UserTypeEnum.Public]: lang.PUBLIC_MAILBOX,
    [Vb365JobItemUser.UserTypeEnum.Shared]: lang.SHARED_MAILBOX,
    [Vb365JobItemUser.UserTypeEnum.User]: lang.USER,
    [Vb365JobItemComposed.ItemTypeEnum.Site]: lang.SITE,
    [Vb365JobItemComposed.ItemTypeEnum.PartialOrganization]: lang.ORGANIZATION,
    [Vb365JobItemComposed.ItemTypeEnum.PersonalSites]: lang.SITE,
    [Vb365JobItemComposed.ItemTypeEnum.OneDriveFolders]: lang.ONEDRIVE_FOLDER,
    [Vb365JobItemComposed.ItemTypeEnum.Team]: lang.TEAMS,
}[type]);
