/**
 * Copyright © Veeam Software Group GmbH.
 */

import { webControllersEnumsMap } from '../../../swagger/webControllers/webControllersEnumsMap';

import type { EnumMap } from '../../../swagger/webControllers/interfaces/enum-map';
import type { EnumDescriptorsMap } from '../portal/interfaces';
import type { PortalService } from '../portal';

export class EnumMapperService {
    private portalService: PortalService;

    constructor(portalService: PortalService) {
        this.portalService = portalService;
    }

    getEnumDescriptors(): EnumDescriptorsMap {
        return this.portalService.getPortalData().enumDescriptors;
    }

    getEnumDescription(enumName: string, enumValue: string): string {
        const enumDescriptors = this.portalService.getPortalData().enumDescriptors;
        const fixedEnumName = `${enumName.charAt(0).toLowerCase()}${enumName.slice(1)}`;

        if (enumDescriptors[fixedEnumName]) {
            return enumDescriptors[fixedEnumName]
                .find(enumDescriptorItem => enumDescriptorItem.literalValue === enumValue)
                ?.description;
        }
    }

    getEnumDescriptionByNumberEnum(enumName: string, enumNumValue: number): string {
        const enumDescriptors = this.portalService.getPortalData().enumDescriptors;
        const fixedEnumName = `${enumName.charAt(0).toLowerCase()}${enumName.slice(1)}`;

        if (enumDescriptors[fixedEnumName]) {
            return enumDescriptors[fixedEnumName]
                .find(enumDescriptorItem => enumDescriptorItem.intValue === enumNumValue)
                ?.description;
        }
    }

    getEnumIntValue(enumName: string, enumValue: string): number {
        const enumDescriptors = this.portalService.getPortalData().enumDescriptors;
        const fixedEnumName = `${enumName.charAt(0).toLowerCase()}${enumName.slice(1)}`;

        if (enumDescriptors[fixedEnumName]) {
            return enumDescriptors[fixedEnumName]
                .find(enumDescriptorItem => enumDescriptorItem.literalValue === enumValue)
                ?.intValue;
        }
    }

    getEnumStringValue(enumName: string, enumValue: number): string {
        const enumDescriptors = this.portalService.getPortalData().enumDescriptors;
        const fixedEnumName = `${enumName.charAt(0).toLowerCase()}${enumName.slice(1)}`;

        if (enumDescriptors[fixedEnumName]) {
            return enumDescriptors[fixedEnumName]
                .find(enumDescriptorItem => enumDescriptorItem.intValue === enumValue)
                ?.literalValue;
        }
    }

    replaceEnumsInData(dataType: string, data: Object): void {
        const enumDescriptors = this.portalService.getPortalData().enumDescriptors;

        const handleDataItem = (dataItem: Object, enumMap: EnumMap, stepI = 0) => {
            const isLastStep = stepI === enumMap.path.length - 1;
            if (isLastStep) {
                const pathValue = enumMap.path[stepI];
                const enumValue = dataItem ? dataItem[pathValue] as string : null;
                if (enumValue) {
                    const extractEnumFromDescriptor = (enumName) => {
                        const enumDescriptor = enumDescriptors[enumName]
                            .find(enumDescriptorItem => enumDescriptorItem.literalValue.toLowerCase() === enumValue.toLowerCase());

                        if (enumDescriptor) {
                            dataItem[pathValue] = enumDescriptor.intValue;
                            dataItem[`${pathValue}_literalValue`] = enumDescriptor.literalValue;
                            dataItem[`${pathValue}_description`] = enumDescriptor.description;
                        }
                    };
                    if (enumDescriptors[enumMap.enumName]) {
                        extractEnumFromDescriptor(enumMap.enumName);
                    } else {
                        if (enumMap.enumName.indexOf('nullable') === 0) {
                            const enumName = enumMap.enumName.replace(/^nullable([\d\D])([\d\D]+$)/, (a, b, c) => b.toLowerCase() + c);
                            extractEnumFromDescriptor(enumName);
                            console.warn(`(Handled) No enum "${enumMap.enumName}" in enum descriptors. Data: "${dataType}"`);
                        } else {
                            console.error(`No enum "${enumMap.enumName}" in enum descriptors. Data: "${dataType}"`);
                        }
                    }
                }
            } else {
                const isArrayStep = enumMap.path[stepI] === '$i';
                if (isArrayStep) {
                    const dataItemArray = dataItem as Array<Object>;
                    if (dataItemArray) {
                        dataItemArray.forEach(dataSubItem => handleDataItem(dataSubItem, enumMap, stepI + 1));
                    }
                } else {
                    if (dataItem) {
                        handleDataItem(dataItem[enumMap.path[stepI]], enumMap, stepI + 1);
                    }
                }
            }
        };

        if (dataType in webControllersEnumsMap) {
            webControllersEnumsMap[dataType].response.forEach(
                enumMap => handleDataItem(data, enumMap)
            );

        } else {
            console.error(`No model "${dataType}" in webControllersEnumsMap. Add model to list modelsForHandle in handleSwaggerConfig.js`);
        }
    }

    restoreStringEnumsInData(dataType: string, data: Object): void {
        const enumDescriptors = this.portalService.getPortalData().enumDescriptors;


        const handleDataItem = (dataItem: Object, enumMap: EnumMap, stepI = 0) => {
            const isLastStep = stepI === enumMap.path.length - 1;
            const handleEnumValue = (obj: Object, propName: string|number) => {
                const enumValue = obj[propName] as number;
                if (typeof enumValue === 'number') {
                    if (!enumDescriptors[enumMap.enumName]) {
                        console.error(`No enum "${enumMap.enumName}" in enum descriptors. Data type: "${dataType}"`);
                    } else {
                        const enumDescriptor = enumDescriptors[enumMap.enumName]
                            .find(enumDescriptorItem => enumDescriptorItem.intValue === enumValue);

                        if (enumDescriptor) {
                            obj[propName] = enumDescriptor.literalValue;
                        }
                    }
                }
            };

            if (!dataItem) {
                return;
            }

            if (isLastStep) {
                const pathValue = enumMap.path[stepI];
                if (pathValue === '$i') {
                    (dataItem as Array<string>).forEach((param, i) => handleEnumValue(dataItem, i));
                } else {
                    handleEnumValue(dataItem, pathValue);
                }
            } else {
                const isArrayStep = enumMap.path[stepI] === '$i';
                if (isArrayStep) {
                    const dataItemArray = dataItem as Array<Object>;
                    if (dataItemArray) {
                        dataItemArray.forEach(dataSubItem => handleDataItem(dataSubItem, enumMap, stepI + 1));
                    }
                } else {
                    if (dataItem) {
                        handleDataItem(dataItem[enumMap.path[stepI]], enumMap, stepI + 1);
                    }
                }
            }
        };


        if (dataType in webControllersEnumsMap) {
            webControllersEnumsMap[dataType].response.forEach(
                enumMap => handleDataItem(data, enumMap)
            );

        } else {
            console.error(`No model "${dataType}" in webControllersEnumsMap. Add model to list modelsForHandle in handleSwaggerConfig.js`);
        }
    }
}
