/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ACTION_VIEW, SortingMode, TextColumn } from '@veeam-vspc/components';
import { SizeColumn } from '@veeam-vspc/components/src/Grid/columns/SizeColumn';
import { capitalize, deepCopy } from '@veeam-vspc/core';

import type { WizardStepData, GridStore } from '@veeam-vspc/components';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useResellerWizardStore } from '../../../../hooks';
import { ActionsToolbar } from './components/ActionsToolbar';

import type { ResellerReplicaResource, ResellerData } from '../../../../interfaces';

export interface BackupResourcesProps extends WizardStepData<ResellerData> {
    hidePanel: () => void;
}

export const ReplicaResources = observer((props: BackupResourcesProps) => {
    const lang = useLang();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<ResellerReplicaResource, void, void>>();
    const wizardStore = useResellerWizardStore();

    const [items] = useState(deepCopy(data.cloudReplicationResources));

    useEffect(() => {
        if (data.wanAccelerationEnabled) {
            wizardStore.wanAcceleratorsCache.load({ cloudConnectAgentUids: data.cloudConnectAgents.map(agent => agent.cloudConnectAgentUid) });
        }
    }, []);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={capitalize(lang.REPLICATION_RESOURCES)}
            description={lang.SELECT_ONE_OR_MORE_HARDWARE_RESELLER}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.cloudReplicationResources = items;

                        onDataChange(data);

                        hidePanel();
                    },
                    // disabled: hwPlans?.length === 0,
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid <ResellerReplicaResource, void, void>
                defaultColumnWidth={100}
                columns={[
                    { field: 'cloudConnectResourceName', title: lang.NAME },
                    {
                        field: 'cpuUsageLimitMhz',
                        title: lang.CPU,
                        cell: ({ rowData }) => {
                            const value = rowData.cpuUsageLimitMhz;
                            const resultValue = value >= 1000 ? (value / 1000).toFixed(2) : value;
                            const suffix = value >= 1000 ? 'GHz' : 'MHz';
                            return <TextColumn>{value === -1 ? lang.UNLIMITED : `${resultValue} ${suffix}`}</TextColumn>;
                        },
                    },
                    {
                        field: 'ramUsageLimitMb',
                        title: lang.MEMORY,
                        cell: ({ rowData }) => rowData.ramUsageLimitMb === -1
                            ? <TextColumn>{lang.UNLIMITED}</TextColumn>
                            : <SizeColumn value={rowData.ramUsageLimitMb} startUnit={'M'} pointFixed={1} />,
                    },
                    {
                        field: 'storageGb',
                        title: lang.STORAGE,
                        cell: ({ rowData }) => rowData.storageGb === -1
                            ? <TextColumn>{lang.UNLIMITED}</TextColumn>
                            : <SizeColumn value={rowData.storageGb} startUnit={'G'} pointFixed={1} />,
                    },
                    { field: 'tenantsPerPlan', title: lang.MAX_NUMBER_OF_TENANTS, width: 200 },
                    {
                        field: 'wanAcceleratorName',
                        title: lang.WAN_ACCELERATOR,
                        width: 150,
                        cell: ({ rowData }) => (
                            <TextColumn>
                                {rowData.isWanAccelerationEnabled
                                    ? wizardStore.wanAcceleratorsCache.value
                                        ?.find(item => item.cloudConnectResourceUid === rowData.wanAcceleratorUid)
                                        ?.cloudConnectResourceName
                                    : null}
                            </TextColumn>
                        ),
                    },
                ]}
                data={() => Promise.resolve({
                    data: items,
                    meta: {
                        pagingInfo: {
                            total: items?.length,
                        },
                    },
                } as BaseSuccessRequestResponse<ResellerReplicaResource[]>)}
                toggleable={false}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'cloudConnectResourceUid',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => (
                        <ActionsToolbar
                            items={items}
                            cloudConnectAgentUids={data.cloudConnectAgents.map(agent => agent.cloudConnectAgentUid)}
                            wanAccelerationIsEnabled={data.wanAccelerationEnabled}
                        />
                    ),
                ]}
                sortingMode={SortingMode.Local}
                isLoading={wizardStore.wanAcceleratorsCache.loading}
                api={api}
            />
        </SidePanelForm>
    );
});
