/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/components/index';

import type { TableCompactWidgetProps } from '../../interfaces';

export const TableCompactWidgetStyled = styled.div<Partial<TableCompactWidgetProps>>`
  padding: ${constants.SPACING_M} ${constants.SPACING_XXL};
  background-color: ${props => props.noData ? colors.G100 : colors.G000};
  flex-grow: 1;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: ${props => props.noData || props.loading ? '1fr' : '1fr 1fr'};
  grid-template-columns: ${props => props.noData || props.loading ? '1fr' : '1fr 1fr'};
`;
