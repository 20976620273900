/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    Form,
    FormLayout,
    isValid,
    FormTitle,
    FormValidator,
} from '@veeam-vspc/components';
import { capitalize, FileSizeUnitsInBytes, formatStr } from '@veeam-vspc/core';

import type { WizardStepData } from '@veeam-vspc/components';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { PerMonthField } from 'components/controls/PerMonthField';
import { DataSizeInBytes } from 'components/controls/DataSizeInBytes';

import type { SubscriptionPlanModel } from 'core/interfaces';

export const panelValidate = (data: SubscriptionPlanModel, maxErrorTextTemplate = '-') => {
    const validator = new FormValidator(data);
    const gbError = `${formatStr(maxErrorTextTemplate, 1024 * 1024)}!immediately`;
    const tbError = `${formatStr(maxErrorTextTemplate, 1024)}!immediately`;
    const getMaxErrorText = (propName: string) => {
        const val = data[propName] as number;
        if (val && val % FileSizeUnitsInBytes.TB === 0) {
            return tbError;
        }

        return gbError;
    };

    validator
        .validate('workstationAgentFree')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('serverAgentFree')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('managedVmFree')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('managedCdpVmFree')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('sourceAmountOfDataFree')
        .number()
        .min(0)
        .max(FileSizeUnitsInBytes.TB * 1024, getMaxErrorText('sourceAmountOfDataFree'));
    validator
        .validate('nasShortTermUsedSpaceFree')
        .number()
        .min(0)
        .max(FileSizeUnitsInBytes.TB * 1024, getMaxErrorText('nasShortTermUsedSpaceFree'));
    validator
        .validate('nasLongTermUsedSpaceFree')
        .number()
        .min(0)
        .max(FileSizeUnitsInBytes.TB * 1024, getMaxErrorText('nasLongTermUsedSpaceFree'));


    validator
        .validate('managedAgentDesktopWindowsExtraPrice')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('managedAgentServerWindowsExtraPrice')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('managedAgentLinuxExtraPrice')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('managedAgentMacExtraPrice')
        .number()
        .min(0)
        .max(1000000);

    return validator.result();
};

interface Props extends WizardStepData<SubscriptionPlanModel> {
    hide: () => void;
}

export const ManagedServicesAdvanced = ({ hide, onDataChange, validationState, data }: Props) => {
    const lang = useLang();
    const [localData] = useState<SubscriptionPlanModel>(() => Object.assign({}, data));

    const currencyPerAgent = formatStr(lang.CURRENCY_PER_AGENT, data.currency);

    return (
        <SidePanelForm
            onRequestClose={hide}
            title={capitalize(lang.ADVANCED_SETTINGS)}
            description={lang.SPECIFY_MANAGED_BACKUP_SERVICES_PROVIDED_FREE_OF_CHARGE}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        if (isValid(panelValidate, localData)) {
                            Object.assign(data, localData);
                            onDataChange(data);
                            hide();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hide,
                },
            ]}
        >
            <Form
                value={localData}
                validationState={validationState}
                validate={data => panelValidate(data, lang.MAX_VALUE_IS)}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.FREE_OF_CHARGE}</FormTitle>

                    <PerMonthField
                        name='workstationAgentFree'
                        label={lang.WORKSTATION_AGENTS}
                        allowDecimal={false}
                    />

                    <PerMonthField
                        name='serverAgentFree'
                        label={lang.SERVER_AGENTS}
                        allowDecimal={false}
                    />

                    <PerMonthField
                        name='managedVmFree'
                        label={lang.MANAGED_VMS}
                        allowDecimal={false}
                    />

                    <PerMonthField
                        name='managedCdpVmFree'
                        label={lang.VMS_WITH_CDP_ENABLED}
                        allowDecimal={false}
                    />

                    <DataSizeInBytes
                        name={'sourceAmountOfDataFree'}
                        label={`${lang.FILE_SHARE_SIZE}:`}
                        allowDecimal={false}
                    />

                    <DataSizeInBytes
                        name={'nasShortTermUsedSpaceFree'}
                        label={lang.FILE_SHARE_BACKUP_SIZE}
                        allowDecimal={false}
                    />

                    <DataSizeInBytes
                        name={'nasLongTermUsedSpaceFree'}
                        label={capitalize(lang.FILE_SHARE_ARCHIVE_SIZE, true)}
                        allowDecimal={false}
                    />

                    <FormTitle>{capitalize(lang.GUEST_OPERATING_SYSTEMS, true)}</FormTitle>

                    <PerMonthField
                        name='managedAgentDesktopWindowsExtraPrice'
                        label={capitalize(lang.WINDOWS_WORKSTATION, true)}
                        suffix={currencyPerAgent}
                    />

                    <PerMonthField
                        name='managedAgentServerWindowsExtraPrice'
                        label={capitalize(lang.WINDOWS_SERVER, true)}
                        suffix={currencyPerAgent}
                    />

                    <PerMonthField
                        name='managedAgentLinuxExtraPrice'
                        label={lang.LINUX}
                        suffix={currencyPerAgent}
                    />

                    <PerMonthField
                        name='managedAgentMacExtraPrice'
                        label={lang.MAC_OS}
                        suffix={currencyPerAgent}
                    />
                </FormLayout>
            </Form>
        </SidePanelForm>
    );
};
