/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import {
    Search,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    ACTION_VIEW,
    GridSortDirections,
} from '@veeam-vspc/components';

import type { GridStore, RequestParams } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { MAX_INTEGER_32 } from 'core/const';

import type { Company } from 'views/pages/RolesAndUsersPage/components/AdminUser/interfaces/company';
import type {
    ManagedCompaniesFilterModel,
    ManagedCompaniesProps,
} from 'views/pages/RolesAndUsersPage/components/AdminSSORule/interfaces';

export const ManagedCompaniesPanel = (props: ManagedCompaniesProps) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<Company, {}, ManagedCompaniesFilterModel>>();
    const [isApplyDisabled, setApplyToDisabledState] = useState(data.selectedCompanies?.length === 0);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.COMPANIES}
            description={lang.SELECT_COMPANIES_ACCESSIBLE_TO_THIS_USER}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.selectedCompanies = api.current.selected;

                        onDataChange(data);

                        hidePanel();
                    },
                    disabled: isApplyDisabled,
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid
                initialState={{
                    selected: data.selectedCompanies,
                    sort: {
                        direction: GridSortDirections.Asc,
                        key: 'name',
                    },
                }}
                defaultColumnWidth={100}
                columns={[
                    { field: 'name', title: lang.NAME },
                    { field: 'siteName', title: lang.SITE },
                    { field: 'locations', title: lang.LOCATIONS },
                    { field: 'companyStatus', title: lang.STATE },
                    { field: 'managerCompanyName', title: lang.MANAGED_BY },
                ]}
                data={(requestParams: RequestParams<ManagedCompaniesFilterModel>) => transportService
                    .request<typeof requestParams, Company[]>('AccountManagement/GetCompanies', requestParams)}
                toggleable={false}
                selection={{
                    field: 'companyUid',
                    checkbox: true,
                    multiple: true,
                }}
                onSelectionChange={(selected) => {
                    if (selected.length === 0) {
                        setApplyToDisabledState(true);
                    } else {
                        setApplyToDisabledState(false);
                    }
                }}
                paginationLimit={MAX_INTEGER_32}
                toolbars={[
                    () => (
                        <Toolbar
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='companyName'
                                            placeholder={lang.COMPANY_NAME}
                                            hasSearchButton={true}
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
                api={api}
            />
        </SidePanelForm>
    );
};
