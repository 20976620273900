/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { View, INDENT } from '@veeam-vspc/components';

import type { WizardStepData } from '@veeam-vspc/components';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { SubscriptionPlanSummary } from 'views/components/wizard/SubscriptionPlanSummary/SubscriptionPlanSummary';

import type { CompanyData } from '../../../../interfaces';
// TODO: move SubscriptionPlan Summary to views/components
import type { SubscriptionPlanModel } from 'core/interfaces/subscription-plan-model';

interface PlanSummaryProps extends WizardStepData<CompanyData> {
    hidePanel: () => void;
    planData: SubscriptionPlanModel;
}

export const PlanSummary = observer((props: PlanSummaryProps) => {
    const lang = useLang();
    const { hidePanel, planData } = props;

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            actions={[
                {
                    text: lang.CLOSE,
                    onClick: hidePanel,
                },
            ]}
        >
            <View spaceRight={INDENT.s}>
                <SubscriptionPlanSummary data={planData} />
            </View>
        </SidePanelForm>
    );
});
