/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { CloudConnectServerWithDeployInfo } from '@veeam-vspc/models/web-controllers';

import { compareVersion } from 'core/utils/version-helpers';

export const getMaxQuotaOnCc = (pbAvailable: boolean) => {
    const OLD_DEFAULT_AVAILABLE_QUOTA_SIZE_GB = 2097151; // 2097151 GB = 2 PB - 1 GB
    const NEW_DEFAULT_AVAILABLE_QUOTA_SIZE_GB = 999 * Math.pow(1024, 2); // 999 PB

    return pbAvailable ? NEW_DEFAULT_AVAILABLE_QUOTA_SIZE_GB : OLD_DEFAULT_AVAILABLE_QUOTA_SIZE_GB;
};

export const isPbAvailableOnCc = (cloudConnectAgents: CloudConnectServerWithDeployInfo[], ccAgentUid: string) => {
    const cloudConnectAgentVersion = cloudConnectAgents?.find(agent => agent.agentGuid === ccAgentUid)?.serverVersion;
    if (cloudConnectAgentVersion) {
        const minVersion = '12.0.0.0';
        if (compareVersion(cloudConnectAgentVersion, minVersion) < 0) {
            return false;
        }
    } else {
        return false;
    }

    return true;
};
