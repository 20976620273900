/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { GridColumnJustify, GridSortDirections, TextColumn } from '@veeam-vspc/components';
import { EPublicCloudRestoreType, PublicCloudVmRestoreType } from '@veeam-vspc/models/web-controllers';

import type { CellRendererProps, RequestParams } from '@veeam-vspc/components';
import type { PublicCloudProtectedVmModel, PublicCloudProtectedVmParam } from '@veeam-vspc/models/web-controllers';


import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { PublicCloudsActionToolbar, PublicCloudsColumn, PublicCloudsFilterToolbar } from './components';
import { FlrPortalColumn } from '../../../FlrPortalColumn';
import { ConfigSectionIds } from 'core/services';

export interface PublicCloudsProps {
    stateId: string;
}

export const PublicClouds: React.FC<PublicCloudsProps> = ({ stateId }) => {
    const defaultColumnRender = ({ cellData }: CellRendererProps<PublicCloudProtectedVmModel>) => <TextColumn>{cellData}</TextColumn>;
    const gridStateId = `${stateId}.PublicClouds`;
    const lang = useLang();
    const { enumMapperService, transportService } = useAppServices();

    const columns = [
        {
            field: 'vmName',
            title: lang.VM,
            cell: defaultColumnRender,
        },
        {
            field: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: defaultColumnRender,
        },
        {
            field: 'platformType',
            title: lang.PLATFORM,
            cell: ({ cellData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <TextColumn>
                    {enumMapperService.getEnumDescription('PublicCloudPlatformTypeRepresentation', cellData)}
                </TextColumn>
            ),
        },
        {
            field: 'restoreStatusModel.status',
            title: lang.FILE_LEVEL_RESTORE_PORTAL,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <FlrPortalColumn
                    instanceUid={rowData.instanceUid}
                    vbServerId={rowData.vbServerId}
                    restoreType={EPublicCloudRestoreType.RestoreVmFileLevel}
                    restoreStatusModel={rowData.restoreStatusModel}
                />
            ),
        },
        {
            field: 'backupServerName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'companyName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'sourceSize',
            title: lang.SOURCE_SIZE,
            cell: ({ cellData }: CellRendererProps<PublicCloudProtectedVmModel>) => <RestorePointSizeColumn value={cellData as any} />,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backup.backupCount',
            title: lang.BACKUPS,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <PublicCloudsColumn
                    value={rowData.backup?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.Backup`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.Backup,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backup.lastBackup',
            title: lang.LAST_BACKUP,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <TimeAgoColumn
                    value={rowData.backup?.lastBackup}
                />
            ),
        },
        {
            field: 'backup.backupSize',
            title: lang.BACKUP_SIZE,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <RestorePointSizeColumn
                    value={rowData.backup?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backup.destination',
            title: capitalize(lang.BACKUP_TARGET),
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => <TextColumn>{rowData.backup?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'snapshot.backupCount',
            title: lang.SNAPSHOTS,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <PublicCloudsColumn
                    value={rowData.snapshot?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.Snapshot`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.Snapshot,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'snapshot.lastBackup',
            title: lang.LAST_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <TimeAgoColumn
                    value={rowData.snapshot?.lastBackup}
                />
            ),
        },
        {
            field: 'snapshot.destination',
            title: lang.VM_REGION,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => <TextColumn>{rowData.snapshot?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'remoteSnapshot.backupCount',
            title: lang.REPLICA_SNAPSHOTS,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <PublicCloudsColumn
                    value={rowData.remoteSnapshot?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.RemoteSnapshot`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.RemoteSnapshot,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'remoteSnapshot.lastBackup',
            title: lang.LAST_REPLICA_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <TimeAgoColumn
                    value={rowData.remoteSnapshot?.lastBackup}
                />
            ),
        },
        {
            field: 'remoteSnapshot.destination',
            title: lang.REPLICA_SNAPSHOT_REGION,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => <TextColumn>{rowData.remoteSnapshot?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'archive.backupCount',
            title: lang.ARCHIVES,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <PublicCloudsColumn
                    value={rowData.archive?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.PublicCloudArchive`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.Archive,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'archive.lastBackup',
            title: lang.LAST_ARCHIVE,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <TimeAgoColumn
                    value={rowData.archive?.lastBackup}
                />
            ),
        },
        {
            field: 'archive.backupSize',
            title: lang.ARCHIVE_SIZE,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <RestorePointSizeColumn
                    value={rowData.archive?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'archive.destination',
            title: lang.ARCHIVE_TARGET,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => <TextColumn>{rowData.archive?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'backupCopy.backupCount',
            title: lang.BACKUP_COPIES,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <PublicCloudsColumn
                    value={rowData.backupCopy?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.BackupCopy`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.Copy,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backupCopy.lastBackup',
            title: lang.LAST_BACKUP_COPY,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <TimeAgoColumn
                    value={rowData.backupCopy?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            field: 'backupCopy.backupSize',
            title: lang.BACKUP_COPY_SIZE,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <RestorePointSizeColumn
                    value={rowData.backupCopy?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backupCopy.destination',
            title: lang.BACKUP_COPY_TARGET,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => <TextColumn>{rowData.backupCopy?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'tape.backupCount',
            title: lang.BACKUPS_ON_TAPE,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <PublicCloudsColumn
                    value={rowData.tape?.backupCount}
                    stateId={`${gridStateId}.Dialogs.PublicClouds.Tape`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        platformType: rowData.platformType,
                        backupType: PublicCloudVmRestoreType.Tape,
                        vmUid: rowData.vmInstanceUid,
                    }}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'tape.lastBackup',
            title: lang.LAST_BACKUP_ON_TAPE,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <TimeAgoColumn
                    value={rowData.tape?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            field: 'tape.backupSize',
            title: lang.BACKUPS_ON_TAPE_SIZE,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => (
                <RestorePointSizeColumn
                    value={rowData.tape?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'tape.destination',
            title: lang.TAPE_MEDIA_POOL,
            cell: ({ rowData }: CellRendererProps<PublicCloudProtectedVmModel>) => <TextColumn>{rowData.tape?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'resourceId',
            title: lang.RESOURCE_ID,
            cell: defaultColumnRender,
            hidden: true,
        },
    ];

    return (
        <AdvancedGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.ProtectedDataVirtualMachinesPublicClouds}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.field,
                },
            }}
            defaultColumnWidth={100}
            columns={columns}
            data={(requestParams: RequestParams<PublicCloudProtectedVmParam['filter']>) => transportService
                .request<typeof requestParams, PublicCloudProtectedVmModel[]>('/PublicCloudVm/GetProtectedVms', { ...requestParams })}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <PublicCloudsFilterToolbar />,
                () => <PublicCloudsActionToolbar idProperty={'instanceUid'} selectedOnly />,
            ]}
        />
    );
};
