/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useRef } from 'react';

import type { RefObject } from 'react';
import type { GridProps, GridStore } from '@veeam-vspc/components';

import { useGlobalFiltersChangeHandler } from 'views/providers/GlobalFiltersProvider/hooks';

export const useGlobalFiltersReload = <RowDataT, TotalRowDataT, FilterT>(
    api?: GridProps<RowDataT, TotalRowDataT, FilterT>['api']
): GridProps<RowDataT, TotalRowDataT, FilterT>['api'] => {
    const currentGridApi = useRef<GridStore<RowDataT, TotalRowDataT, FilterT>>();
    const gridApi = api ?? currentGridApi;

    useGlobalFiltersChangeHandler(() => (gridApi as RefObject<GridStore<RowDataT, TotalRowDataT, FilterT>>).current?.reloadGrid());

    return gridApi;
};
