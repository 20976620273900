/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { ResellerWizardStoreContext } from '../stores';

import type { ResellerWizardStore } from '../stores';

export const useResellerWizardStore = (): ResellerWizardStore => useContext(ResellerWizardStoreContext);
