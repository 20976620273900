/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { MessageBox, ParsedText, PortalSpinner } from '@veeam-vspc/components';

import { useTransportRequest } from 'views/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { getFailureMessage } from './utils';

import type { FailureMessageParams } from './interfaces';

export interface FailureMessageDialogProps {
    header?: string;
    deactivate: () => void;
    iconSrc: string;
    url: string;
    paramsForFailureMessageRequest: Record<string, any>;
    paramsForFailureMessage: Omit<FailureMessageParams, 'failureMessage'>;
}

export const FailureMessageDialog: React.FC<FailureMessageDialogProps> = (props) => {
    const lang = useLang();
    const {
        header = lang.JOB_STATUS,
        deactivate,
        iconSrc,
        url,
        paramsForFailureMessageRequest,
        paramsForFailureMessage,
    } = props;
    const { loading, data } = useTransportRequest<FailureMessageDialogProps['paramsForFailureMessageRequest'], string[]>(
        url,
        paramsForFailureMessageRequest,
    );

    return (
        <MessageBox
            header={header}
            onRequestClose={deactivate}
            actions={[
                { text: lang.OK, onClick: deactivate },
            ]}
            iconSrc={iconSrc}
        >
            <>
                {loading && <PortalSpinner whiteBg />}

                {!loading && (
                    <ParsedText
                        textOrConfig={getFailureMessage(lang, {
                            ...paramsForFailureMessage,
                            failureMessage: data as string[],
                        })}
                    />
                )}
            </>
        </MessageBox>
    );
};
