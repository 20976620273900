/**
 * Copyright © Veeam Software Group GmbH.
 */
import { Icon, STACK_GAP, StackView, Text } from '@veeam-vspc/components';
import React from 'react';

import { getStatusIconSrc } from 'core/utils';

import type { UnspecifiedCompaniesStatusProps } from './interfaces';


export const UnspecifiedCompaniesStatus: React.FC<UnspecifiedCompaniesStatusProps> = ({ status, count }) => (
    <StackView gap={STACK_GAP.s}>
        <Icon src={getStatusIconSrc(status)} />
        <Text>{count}</Text>
    </StackView>
);
