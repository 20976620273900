/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    Form,
    FormLayout,
    isValid,
    FormTitle,
    FormValidator,
} from '@veeam-vspc/components';
import { FileSizeUnitsInBytes, formatStr } from '@veeam-vspc/core';

import type { WizardStepData } from '@veeam-vspc/components';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { PerMonthField } from 'components/controls/PerMonthField';
import { DataSizeInBytes } from 'components/controls/DataSizeInBytes';

import type { SubscriptionPlanModel } from 'core/interfaces';

export const panelValidate = (data: SubscriptionPlanModel, maxErrorTextTemplate = '-') => {
    const validator = new FormValidator(data);
    const gbError = `${formatStr(maxErrorTextTemplate, 1024 * 1024)}!immediately`;
    const tbError = `${formatStr(maxErrorTextTemplate, 1024)}!immediately`;
    const getMaxErrorText = (propName: string) => {
        const val = data[propName] as number;
        if (val && val % FileSizeUnitsInBytes.TB === 0) {
            return tbError;
        }

        return gbError;
    };

    validator
        .validate('managedCloudVmFree')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('publicCloudFileShareFree')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('publicCloudDatabaseFree')
        .number()
        .min(0)
        .max(1000000);

    validator
        .validate('publicCloudBackupSpaceFree')
        .number()
        .min(0)
        .max(FileSizeUnitsInBytes.TB * 1024, getMaxErrorText('publicCloudBackupSpaceFree'));
    validator
        .validate('publicCloudArchiveSpaceFree')
        .number()
        .min(0)
        .max(FileSizeUnitsInBytes.TB * 1024, getMaxErrorText('publicCloudArchiveSpaceFree'));

    return validator.result();
};

interface Props extends WizardStepData<SubscriptionPlanModel> {
    hide: () => void;
}

export const PublicCloudAdvanced = ({ hide, onDataChange, validationState, data }: Props) => {
    const lang = useLang();
    const [localData] = useState<SubscriptionPlanModel>(() => Object.assign({}, data));

    const instancesSuffix = lang.INSTANCES.toLowerCase();

    return (
        <SidePanelForm
            onRequestClose={hide}
            title={lang.PUBLIC_CLOUD_BACKUP_ADVANCED_SETTINGS}
            description={lang.SET_PUBLIC_CLOUD_RESOURCES}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        if (isValid(panelValidate, localData)) {
                            Object.assign(data, localData);
                            onDataChange(data);
                            hide();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hide,
                },
            ]}
        >
            <Form
                value={localData}
                validationState={validationState}
                validate={data => panelValidate(data, lang.MAX_VALUE_IS)}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.FREE_OF_CHARGE}</FormTitle>

                    <PerMonthField
                        name='managedCloudVmFree'
                        label={lang.CLOUD_VMS}
                        allowDecimal={false}
                    />

                    <PerMonthField
                        name='publicCloudFileShareFree'
                        label={lang.CLOUD_FILE_SHARES}
                        allowDecimal={false}
                        suffix={instancesSuffix}
                    />

                    <PerMonthField
                        name='publicCloudDatabaseFree'
                        label={lang.CLOUD_DATABASES}
                        allowDecimal={false}
                        suffix={instancesSuffix}
                    />

                    <DataSizeInBytes
                        name={'publicCloudBackupSpaceFree'}
                        label={`${lang.BACKUP_STORAGE}:`}
                        allowDecimal={false}
                    />

                    <DataSizeInBytes
                        name={'publicCloudArchiveSpaceFree'}
                        label={`${lang.ARCHIVE_STORAGE}:`}
                        allowDecimal={false}
                    />
                </FormLayout>
            </Form>
        </SidePanelForm>
    );
};
