/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    Checkbox,
    Combobox,
    CONTROL_SIZE,
    createDefaultDatetimeInputControl,
    Form,
    FormLayout,
    FormValidator,
    Icon,
    isValid,
    LabelGroup,
    LinkButton,
    NoLabelGroup,
    NoteBar,
    NOTEBAR_STATUS,
    PasswordInput,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    Textarea,
    TextInput,
    useExternalFormApi,
    formatDate,
    normalizeOldDateFormat,
    defaultMaskPlaceholder,
} from '@veeam-vspc/components';
import { Datetime } from '@veeam-vspc/components/src/form/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import type { CloudConnectServerWithDeployInfo } from '@veeam-vspc/models/web-controllers';
import type { ExternalFormApi, WizardStep, DefaultDatetimeInputControlValueFormatterPayload } from '@veeam-vspc/components';

import { core } from 'core/core-module';
import { StepLayout } from 'components/layouts/StepLayout';
import { CheckItemExistType, CloudGatewaySelectionType } from 'core/enums';
import { CloudTenantType } from 'core/enums/cloud-tenant-type';
import { CommonService } from 'core/services/common-service';
import { trimTextFields } from 'core/utils/string-helpers';
import { extjsValidate } from 'core/utils/validators';
import keyIcon from 'images/actions/key.png';
import warningIcon from 'images/warning-status.svg';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppStore } from 'views/providers/AppProvider/hooks/use-app-store';
import { useCompanyWizardStore } from '../../hooks/';
import { isPublicCloudAvailable, isSiteDisabled } from '../../utils';

import type { CompanyData, CompanyWizardStepData } from '../../interfaces';
import type { CompanyWizardStore } from '../../stores';
import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { IsItemExistsParams } from 'core/interfaces';

interface StepData {
    formApi?: ExternalFormApi<CompanyData>;
}

const isUsernameDisabled = (data: CompanyData): boolean => {
    const { portalUser } = core.portalService.getPortalData();

    return (portalUser.isCompanyOwner() || data.tenantType === CloudTenantType.Vcd);
};

const isPasswordFieldDisabled =
    (isEdit: boolean, initialData: CompanyData, changePassword: boolean) => isEdit && initialData.password !== '' && !changePassword;
const isConfirmPasswordFieldHidden = (isEdit: boolean, initialData: CompanyData) => isEdit && initialData.password !== '';

const formValidate = (data: CompanyData, lang: LangsServiceBase, wizardStore: CompanyWizardStore, isEdit: boolean) => {
    const validator = new FormValidator(data);
    const { changePassword } = wizardStore;
    const initialData = wizardStore.initialData;

    if (!isUsernameDisabled(data)) {
        validator.validate('userName')
            .string()
            .required()
            .maxLength(128)
            .check(v => extjsValidate(v, RCOP.utils.Validator.tenantName));
    }

    if (!isPasswordFieldDisabled(isEdit, initialData, changePassword)) {
        validator.validate('password')
            .password()
            .required(lang.PASSWORD_FIELD_CANNOT_BE_EMPTY)
            .maxLength(128)
            .check((value) => {
                if (value.trim() === '') {
                    return lang.PASSWORD_FIELD_CANNOT_ONLY;
                }
                return;
            });

        if (!isConfirmPasswordFieldHidden(isEdit, initialData)) {
            validator.validate('confirmPassword')
                .password()
                .required()
                .check((v) => {
                    if (v && data.password !== v) {
                        return lang.PASSWORDS_DO_NOT_MATCH;
                    }
                    return '';
                });
        }
    }

    if (!isSiteDisabled(isEdit, data.companyStatus)) {
        validator.validate('cloudConnectAgentUid')
            .string()
            .required()
            .check(() => {
                const ccAgent = wizardStore.cloudConnectAgentsCache.value?.find(agent => agent.agentGuid === data.cloudConnectAgentUid);
                const tenantQuota = ccAgent?.tenantQuota || 0;
                const tenantQuotaUsage = ccAgent?.tenantQuotaUsage || -1;

                if (tenantQuota > -1 && tenantQuotaUsage >= tenantQuota) {
                    return lang.CANNOT_CREATE_A_NEW_COMPANY_PLEASE_SELECT;
                }

                return '';
            });
    }

    if (data.expirationEnabled) {
        validator.validate('expirationTime')
            .date()
            .required();
    }

    validator.validate('description')
        .string()
        .maxLength(1000);

    return validator.result();
};

const stepValidate = (data: CompanyData, lang: any, wizardStore: CompanyWizardStore, isEdit: boolean, stepData: StepData) => {
    // trim text fields
    trimTextFields(data, ['userName']);
    stepData.formApi?.setValue(data);

    if (isValid(value => formValidate(value, lang, wizardStore, isEdit), data)) {
        if (!isPublicCloudAvailable(wizardStore.cloudConnectAgentsCache.value, data.cloudConnectAgentUid)) {
            stepData.formApi?.setValue('vbPublicCloudManagementEnabled', false);
        }

        if (isUsernameDisabled(data) || !stepData.formApi) {
            return true;
        }

        const checkData: IsItemExistsParams = {
            id: isEdit ? data.userId : null,
            type: CheckItemExistType.Tenant,
            name: data.userName,
            cloudConnectAgentUid: data.cloudConnectAgentUid,
        };

        return CommonService.isItemExist(checkData)
            .then((exist) => {
                if (exist) {
                    stepData.formApi.setExternalErrors({
                        userName: lang.THIS_USERNAME_IS_ALREADY_USED,
                    });
                }
                return !exist;
            });
    }

    return false;
};

export const getUserInfoStep = (lang: LangsServiceBase, wizardStore: CompanyWizardStore): WizardStep<CompanyData> => ({
    title: lang.USER_INFO,
    validate: ({ data, isEdit, stepData }) => stepValidate(data, lang, wizardStore, isEdit, stepData),
    render: stepData => <UserInfoStep {...stepData} wizardStore={wizardStore} />,
    isHidden: ({ data }) => data.tenantType !== CloudTenantType.General,
});

const MAX_EXPIRATION_DATE = new Date(2099, 11, 31);

const UserInfoStep = observer((wizardStepData: CompanyWizardStepData) => {
    const { data, validationState, onDataChange, isEdit } = wizardStepData;
    const stepData = wizardStepData.stepData as StepData;
    const lang = useLang();
    const formApi = useExternalFormApi<CompanyData>();
    const defaultFieldWidth = CONTROL_SIZE.l;
    const wizardStore = useCompanyWizardStore();
    const { formats } = useAppStore();

    const [isCompanyExists, setIsCompanyExists] = useState(false);

    const checkCompanyExists = (data: CompanyData) => {
        CommonService.isItemExist({
            id: data.id,
            name: data.name,
            cloudConnectAgentUid: data.cloudConnectAgentUid,
            type: CheckItemExistType.Company,
        })
            .then(exists => setIsCompanyExists(exists));
    };

    useEffect(() => {
        stepData.formApi = formApi;

        return () => {
            delete stepData.formApi;
        };
    }, []);

    useEffect(() => {
        wizardStore.cloudConnectAgentsCache.load();
        wizardStore.checkIsRestAvailable(data.id);
        checkCompanyExists(data);
    }, []);

    return (
        <StepLayout
            title={lang.USER_INFO}
            description={lang.CREATE_THE_BACKUP_PORTAL_USER_ACCOUNT}
        >
            <Form
                value={data}
                validate={(data: CompanyData) => formValidate(data, lang, wizardStore, isEdit)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <TextInput
                        name={'userName'}
                        label={lang.USERNAME}
                        size={defaultFieldWidth}
                        disabled={isUsernameDisabled(data)}
                    />

                    <LabelGroup label={`${lang.PASSWORD}:`}>
                        <FormLayout direction={STACK_DIRECTION.row}>
                            <PasswordInput
                                name={'password'}
                                size={defaultFieldWidth}
                                disabled={isPasswordFieldDisabled(isEdit, wizardStore.initialData, wizardStore.changePassword)}
                                autocomplete={'new-password'}
                                hasValueMask={defaultMaskPlaceholder}
                            />

                            {isPasswordFieldDisabled(isEdit, wizardStore.initialData, wizardStore.changePassword) && (
                                <LinkButton
                                    iconBefore={keyIcon}
                                    onClick={() => {
                                        formApi.setValue('password', '');
                                        wizardStore.changePassword = true;
                                    }}
                                >
                                    {lang.CHANGE_PASSWORD}
                                </LinkButton>
                            )}
                        </FormLayout>
                    </LabelGroup>

                    {!isConfirmPasswordFieldHidden(isEdit, wizardStore.initialData) && (
                        <PasswordInput
                            name={'confirmPassword'}
                            label={lang.CONFIRM_PASSWORD}
                            size={defaultFieldWidth}
                            autocomplete={'new-password'}
                        />
                    )}

                    <Combobox<CloudConnectServerWithDeployInfo, string>
                        name={'cloudConnectAgentUid'}
                        label={lang.SITE}
                        data={wizardStore.cloudConnectAgentsCache.value || []}
                        valueGetter={item => item.agentGuid}
                        textGetter={item => item.siteName}
                        disabled={isSiteDisabled(isEdit, data.companyStatus)}
                        size={defaultFieldWidth}
                        onChange={(value, itemIndex) => {
                            formApi.setValue('gatewayPool', []);
                            formApi.setValue('gatewaySelectionType', CloudGatewaySelectionType.StandaloneGateways);
                            formApi.setValue('cloudBackupResourceEnabled', false);
                            formApi.setValue('quotas', []);
                            formApi.setValue('cloudReplicationResourceEnabled', false);
                            formApi.setValue('hwPlans', []);
                            checkCompanyExists(data);
                        }}
                    />

                    <NoLabelGroup
                        content={(
                            <FormLayout>
                                {isCompanyExists && (
                                    <NoteBar status={NOTEBAR_STATUS.warning}>
                                        {lang.CANNOT_CREATE_A_NEW_COMPANY}
                                    </NoteBar>
                                )}

                                {wizardStore.isRestAvailable && (
                                    <Checkbox name={'restEnabled'}>
                                        {lang.ENABLE_ACCESS_TO_REST_API}
                                    </Checkbox>
                                )}

                                <FormLayout direction={STACK_DIRECTION.row}>
                                    <Checkbox
                                        name={'expirationEnabled'}
                                    >
                                        {lang.DISABLE_ACCOUNT_AUTOMATICALLY_ON}
                                    </Checkbox>

                                    <Datetime
                                        name={'expirationTime'}
                                        disabled={!data.expirationEnabled}
                                        suffix={(data.expirationEnabled && data.expirationTime && data.expirationTime <= new Date()) && (
                                            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                                                <Icon src={warningIcon} />
                                                <Text>{lang.ACCOUNT_IS_DISABLED}</Text>
                                            </StackView>
                                        )}
                                        endDatetime={MAX_EXPIRATION_DATE}
                                        // TODO: add prop for format to Datetime
                                        controlRenderer={createDefaultDatetimeInputControl({
                                            valueFormatter: ({ value }: DefaultDatetimeInputControlValueFormatterPayload): string =>
                                                value ? formatDate(value, normalizeOldDateFormat(formats.netShortDate)) : '',
                                        })}
                                    />
                                </FormLayout>

                                <Textarea
                                    name={'description'}
                                    label={lang.DESCRIPTION}
                                    size={CONTROL_SIZE.full}
                                    rows={10}
                                />
                            </FormLayout>
                        )}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
