/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ACTION_VIEW, SortingMode } from '@veeam-vspc/components';

import type { WizardStepData, GridStore } from '@veeam-vspc/components';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useCompanyWizardStore } from '../../../../hooks';
import { syncVcdNetworksExtensions } from '../../../../utils';
import { getWanAcceleratorColumn } from '../../utils';
import { ActionsToolbar } from './components/ActionsToolbar';

import type { VcdReplicaResource, CompanyData } from '../../../../interfaces';

interface Props extends WizardStepData<CompanyData> {
    hidePanel: () => void;
}

export const VcdReplicaResources = observer((props: Props) => {
    const lang = useLang();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<VcdReplicaResource, void, void>>();
    const wizardStore = useCompanyWizardStore();

    const [vcdReplicationResources] = useState([...data.vcdReplicationResources]);

    useEffect(() => {
        wizardStore.wanAcceleratorsCache.load({ cloudConnectAgentUid: data.cloudConnectAgentUid });
    }, []);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.REPLICA_RESOURCES_VCD}
            description={lang.SELECT_A_VIRTUAL_DATA_CENTER_FOR}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: async() => {
                        data.vcdReplicationResources = vcdReplicationResources;
                        data.vcdNetworksExtensions = await syncVcdNetworksExtensions(data, wizardStore);

                        onDataChange(data);

                        hidePanel();
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid <VcdReplicaResource, void, void>
                defaultColumnWidth={100}
                columns={[
                    { field: 'dataCenterName', title: lang.NAME },
                    getWanAcceleratorColumn(wizardStore, lang),
                ]}
                data={() => Promise.resolve({
                    data: vcdReplicationResources,
                    meta: {
                        pagingInfo: {
                            total: vcdReplicationResources?.length,
                        },
                    },
                } as BaseSuccessRequestResponse<VcdReplicaResource[]>)}
                toggleable={false}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'dataCenterUid',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => (
                        <ActionsToolbar
                            items={vcdReplicationResources}
                            cloudConnectAgentUid={data.cloudConnectAgentUid}
                            organizationUid={data.vcdOrganizationUid}
                        />
                    ),
                ]}
                api={api}
                sortingMode={SortingMode.Local}
                isLoading={wizardStore.wanAcceleratorsCache.loading}
            />
        </SidePanelForm>
    );
});
