/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { capitalize, deepCopy } from '@veeam-vspc/core';
import { ACTION_VIEW, TextColumn, SortingMode } from '@veeam-vspc/components';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { WizardStepData, GridStore } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useCompanyWizardStore } from '../../../../hooks';
import { syncVboInfrastructure } from '../../../../utils';
import { ActionsToolbar } from './components/ActionsToolbar';

import type { VboServer, CompanyData } from '../../../../interfaces';

export interface Microsoft365ServersProps extends WizardStepData<CompanyData> {
    hidePanel: () => void;
}

export const Microsoft365Servers = observer((props: Microsoft365ServersProps) => {
    const lang = useLang();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<VboServer, void, void>>();
    const wizardStore = useCompanyWizardStore();

    const [items, setItems] = useState(deepCopy(data.vboInfrastructure.vboServers));

    useEffect(() => {
        api.current.reloadGrid();
    }, [items]);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.MICROSOFT_365_SERVERS}
            description={lang.SELECT_A_BACKUP_SERVER_TO_ASSIGN_TO_THE_COMPANY}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: async() => {
                        data.vboInfrastructure.vboServers = items;
                        data.vboInfrastructure = await syncVboInfrastructure(data.vboInfrastructure, wizardStore, data.id);
                        onDataChange(data);

                        hidePanel();
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid <VboServer, void, void>
                defaultColumnWidth={100}
                columns={[
                    {
                        field: 'vboServerUid',
                        title: lang.SERVER,
                        cell: ({ rowData }) => (
                            <TextColumn>
                                {wizardStore.availableVboServersCache.value?.find(item => item.instanceUid === rowData.vboServerUid).name}
                            </TextColumn>
                        ),
                    },
                    { field: 'friendlyName', title: capitalize(lang.FRIENDLY_NAME) },
                ]}
                data={() => Promise.resolve({
                    data: items,
                    meta: {
                        pagingInfo: {
                            total: items?.length,
                        },
                    },
                } as BaseSuccessRequestResponse<VboServer[]>)}
                toggleable={false}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'vboServerUid',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => <ActionsToolbar items={items} setItems={setItems} companyId={data.id} companyUid={data.organizationUid} />,
                ]}
                api={api}
                sortingMode={SortingMode.Local}
            />
        </SidePanelForm>
    );
});
