/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ExportActionIds {
    ExportToGroup = 'ExportToGroup',
    ExportFillSpace = 'ExportFillSpace',
    ExportSeparator = 'ExportSeparator',
    ExportCSV = 'ExportCSV',
    ExportXML = 'ExportXML',
}
