/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    hideForFilterWithoutPropValue,
    hideForSelectionWithoutPropValue,
    processDecoratorsForDisabledByPriority,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    disableForSelectionWithPropValueCallback,
} from '@veeam-vspc/components';
import { BackupAgentGuiModeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { BackupAgentJobContainerFilter, BackupAgentJobContainerModel } from '@veeam-vspc/models/web-controllers';
import type { CustomToolbarPayload } from '@veeam-vspc/components';

import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { useLang } from 'views/providers/LangProvider/hooks';
import startActionIconSrc from 'images/actions/start.svg';
import stopActionIconSrc from 'images/actions/stop.svg';
import createJobIconSrc from 'images/actions/add.svg';
import createWindowJobIconSrc from 'images/filters/os/windows.svg';
import createLinuxJobIconSrc from 'images/filters/os/linux.svg';
import createMacJobIconSrc from 'images/filters/os/mac.svg';
import uiModeIconSrc from 'images/actions/gui-mode.png';
import readonlyUiModeIconSrc from 'images/actions/gui-mode-readonly.png';
import adminUiModeIconSrc from 'images/actions/gui-mode-admin.png';
import enableActionIconSrc from 'images/actions/enable.png';
import disableActionIconSrc from 'images/actions/disable.svg';
import settingsActionIconSrc from 'images/actions/settings.svg';
import downloadLogsActionIconSrc from 'images/actions/download-logs.svg';
import { SystemTypeRepresentations } from 'core/enums';
import { useAsyncAction, useDependentToolbarButton } from 'views/hooks';
import { ConfigSectionIds } from 'core/services/configurator/enums';
import { useAppServices } from 'views/providers/AppProvider/hooks';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';

export interface ManagedByConsoleActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items'> {
    method?: string;
    extController: any;
}

export const ManagedByConsoleActionToolbar: React.FC<ManagedByConsoleActionToolbarProps> = (props) => {
    const createJobGroupId = 'createJobGroupId';
    const uiModeGroupId = 'uiModeGroupId';
    const { method = 'GetBackupAgents', extController, ...restProps } = props;
    const lang = useLang();
    const { enumMapperService } = useAppServices();
    const gridApi = useGridApi<BackupAgentJobContainerModel, unknown, BackupAgentJobContainerFilter>();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSelection(),
    ];

    const { group: actionGroup, items: actionItems } = useDependentToolbarButton<
        BackupAgentJobContainerModel,
        unknown,
        BackupAgentJobContainerFilter
    >(
        {
            id: createJobGroupId,
            title: lang.CREATE_JOB,
            iconSrc: createJobIconSrc,
        },
        [
            {
                type: TOOLBAR_ITEM_TYPE.button,
                iconSrc: createWindowJobIconSrc,
                text: lang.WINDOWS,
                groupId: createJobGroupId,
                onClick: () => {
                    const windowsExtValue = enumMapperService.getEnumIntValue('systemTypeRepresentation', SystemTypeRepresentations.Windows);
                    extController.createJobHandler(windowsExtValue, gridApi.selected)
                        .then(() => gridApi.reloadGrid());
                },
                decorators: defaultDisabledDecorators,
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Windows),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Windows),
                ],
            },
            {
                type: TOOLBAR_ITEM_TYPE.button,
                iconSrc: createLinuxJobIconSrc,
                text: lang.LINUX,
                groupId: createJobGroupId,
                onClick: () => {
                    const linuxExtValue = enumMapperService.getEnumIntValue('systemTypeRepresentation', SystemTypeRepresentations.Linux);
                    extController.createJobHandler(linuxExtValue, gridApi.selected)
                        .then(() => gridApi.reloadGrid());
                },
                decorators: defaultDisabledDecorators,
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Linux),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Linux),
                ],
            },
            {
                type: TOOLBAR_ITEM_TYPE.button,
                iconSrc: createMacJobIconSrc,
                text: lang.MAC,
                groupId: createJobGroupId,
                onClick: () => {
                    const macExtValue = enumMapperService.getEnumIntValue('systemTypeRepresentation', SystemTypeRepresentations.Mac);
                    extController.createJobHandler(macExtValue, gridApi.selected)
                        .then(() => gridApi.reloadGrid());
                },
                decorators: defaultDisabledDecorators,
                dependencies: [
                    hideForFilterWithoutPropValue('osTypes', SystemTypeRepresentations.Mac),
                    hideForSelectionWithoutPropValue('systemType', SystemTypeRepresentations.Mac),
                ],
            },
        ],
    );

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                method={method}
                sectionId={ConfigSectionIds.BackupJobsComputersManagedByConsole}
                groups={[
                    actionGroup,
                    {
                        id: uiModeGroupId,
                        title: lang.BACKUP_AGENT_UI,
                        iconSrc: uiModeIconSrc,
                    },
                ]}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: startActionIconSrc,
                        text: lang.START,
                        onClick: () => doAsyncAction('/BackupAgent/StartBackupAgentJob', lang.START, gridApi.requestParamsForActions),
                        decorators: defaultDisabledDecorators,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopActionIconSrc,
                        text: lang.STOP,
                        onClick: () => doAsyncAction('/BackupAgent/StopBackupAgentJob', lang.STOP, gridApi.requestParamsForActions),
                        decorators: defaultDisabledDecorators,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    ...actionItems,
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: readonlyUiModeIconSrc,
                        text: lang.SWITCH_TO_READ_ONLY_UI,
                        groupId: uiModeGroupId,
                        onClick: () => doAsyncAction('/BackupAgent/SetReadonly', lang.SWITCH_TO_READ_ONLY_UI, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('guiMode', value => value === BackupAgentGuiModeRepresentation.ReadOnly),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: adminUiModeIconSrc,
                        text: lang.SWITCH_TO_FULL_ADMIN_ACCESS,
                        groupId: uiModeGroupId,
                        onClick: () => doAsyncAction('/BackupAgent/SetFullMode', lang.SWITCH_TO_FULL_ADMIN_ACCESS, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('guiMode', value => value !== BackupAgentGuiModeRepresentation.ReadOnly),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.text,
                        text: `${lang.SCHEDULING}:`,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: enableActionIconSrc,
                        text: lang.ENABLE,
                        onClick: () => doAsyncAction('/BackupAgent/SetDisabled', lang.ENABLE, {
                            ...gridApi.requestParamsForActions,
                            value: false,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('disabled', value => Boolean(value) === false),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: disableActionIconSrc,
                        text: lang.DISABLE,
                        onClick: () => doAsyncAction('/BackupAgent/SetDisabled', lang.DISABLE, {
                            ...gridApi.requestParamsForActions,
                            value: true,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValueCallback('disabled', value => Boolean(value) === true),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: settingsActionIconSrc,
                        text: lang.SETTINGS,
                        onClick: () => {
                            extController.setSettingsHandler(gridApi.selected, gridApi.requestParamsForActions)
                                .then(() => gridApi.reloadGrid());
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                (payload) => {
                                    const { filters, selectedItems, flags } = (payload as CustomToolbarPayload);
                                    const osTypesFilter = filters?.osTypes as SystemTypeRepresentations[] || null;

                                    if (
                                        (osTypesFilter?.length === 1 && osTypesFilter[0] === SystemTypeRepresentations.Windows)
                                        || flags.isAllPageMode
                                    ) {
                                        return { disabled: false };
                                    }

                                    return {
                                        disabled: selectedItems
                                            .every(item => item.systemType !== SystemTypeRepresentations.Windows || item.isSettingsChanging),
                                    };
                                },
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: downloadLogsActionIconSrc,
                        text: lang.DOWNLOAD_LOGS,
                        onClick: () => {
                            const { selected } = gridApi;

                            Ext.create('RCOP.utils.DownloadAgentLogsManager', {
                                title: lang.DOWNLOAD_LOGS,
                                url: '/BackupAgent/DownloadLogs',
                                params: {
                                    onlyThese: selected.map(item => item.agentId),
                                },
                                systemTypeData: {
                                    onlyThese: selected.map(item => item.systemType),
                                },
                            }).start();
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                payload => ({ disabled: payload.selectedItems.length > 10 }),
                            ]),
                        ],
                    },
                ]}
            />

            {asyncActionLoader}
        </>
    );
};
