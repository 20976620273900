/**
 * Copyright © Veeam Software Group GmbH.
 */

import { computed, observable } from 'mobx';

import type { IdentityProviderSettings } from '@veeam-vspc/models/rest';

import { PortalData, PortalUser, PortalFeatures } from 'core/entries';
import { getProductTheme } from 'core/utils';
import { PortalFormats } from 'core/entries/portal-data/interfaces';
import { GlobalFiltersData } from 'core/services/global-filters/interfaces';
import { Theme } from '../interfaces';

export class AppStore {

    @observable theme: Theme;
    @observable locale: string;
    @observable formats: PortalFormats;

    @observable private portalNameValue: string; // Be aware! It has getter/setter

    version: string;

    appMinWidth = 960;
    appMinHeight = 800;

    message: string | null = null;
    identityProviders: IdentityProviderSettings[] | null;

    @observable globalFilters: GlobalFiltersData;

    @observable portalUser: PortalUser;
    @observable portalFeatures: PortalFeatures;
    @observable portalData: PortalData;

    constructor(portalData: PortalData) {
        const {
            theme,
            locale,
            formats,
            version,
            portalUser,
            portalName,
            message,
            identityProviders,
            portalFeatures,
        } = portalData;

        this.theme = getProductTheme(theme);
        this.locale = locale;
        this.formats = formats;
        this.version = version;
        this.portalName = portalName;

        this.message = message;
        this.identityProviders = identityProviders;

        this.portalUser = portalUser;
        this.portalFeatures = portalFeatures;
        this.portalData = portalData;

        // @TODO Remove when leftside menu will be on React
        (window as any)?.RCOP?.getApplication()?.on(RCOP.Const.events.GLOBAL_FILTER_CHANGE, () => {
            this.globalFilters = RCOP.globalFilters.getSubmitValues();
        });
    }

    @computed get portalName(): string {
        return this.portalNameValue;
    }

    set portalName(value: string) {
        this.portalNameValue = value;
        this.setDocumentTitle(value);

        // TODO: Do not forget to move part of this logic from index.html
        window.localStorage.setItem('vspc-portalName', value);
    }

    private setDocumentTitle(title: string): void {
        document && (document.title = title);
    }

}
