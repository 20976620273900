/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Form,
    FormLayout,
    FormTitle,
    INDENT,
    NoLabelGroup,
    Radiobutton,
    Search,
    SortingMode,
    STACK_GAP,
    StackView,
    Text,
    TextColumn,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    useExternalFormApi,
    TEXT_VIEW,
} from '@veeam-vspc/components';
import { capitalize, deepClone } from '@veeam-vspc/core';
import { CompanyTypeRepresentation, ReportTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { CellRendererProps, GridStore, WizardStep, WizardStepData } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { FixToggle } from 'components/controls/FixToggle';
import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { ManagerType, ReportAccessMode, ReportAggregationModeRepresentation } from '../../enums';
import { useReportStore } from '../../hooks';
import { ConfigSectionIds } from 'core/services';
import { CompanyStatus } from '../../../../../../CompaniesPage/components/CompanyWizard/enums';
import { MAX_INTEGER_32 } from 'core/const';
import { useAppServices, useAppStore } from 'views/providers/AppProvider/hooks';

import type { ReportModel, CompanyModel } from '../../interfaces';
import type { ReportStore } from '../../stores';

export const getCompaniesStep = (title: string, store: ReportStore, isCompanyRole: boolean): WizardStep<ReportModel> => ({
    title,
    validate: ({ stepData, data }) => {
        if (store.companies.length === 0) {
            return false;
        }

        if (stepData.hasInactiveCompanies && data.parameters.aggregationMode === ReportAggregationModeRepresentation.SingleCompany) {
            stepData.showHasInactiveCompanies();
            return false;
        }

        if (stepData.showHasInactiveCompanies) {
            const { showHasInactiveCompanies } = stepData;
            delete stepData.showHasInactiveCompanies;

            return showHasInactiveCompanies();
        }

        return true;
    },
    isHidden: ({ data, isEdit }) => isCompanyRole
        || isEdit && data.parameters.aggregationMode === ReportAggregationModeRepresentation.SingleCompany,
    render: data => <CompaniesStep {...data} />,
    beforeClose: ({ data, stepData }) => {
        const isIndividualMode = data.parameters.aggregationMode === ReportAggregationModeRepresentation.SingleCompany;
        if (isIndividualMode && stepData.isJustProviderCompanies) {
            data.parameters.accessMode = ReportAccessMode.Internal;
        }
    },
});

const CompaniesStep = observer(({ data, onDataChange, validationState, isEdit, stepData }: WizardStepData<ReportModel>) => {
    const lang = useLang();
    const reportStore = useReportStore();
    const { enumMapperService } = useAppServices();
    const { portalUser } = useAppStore();
    const companies = reportStore.companies;
    const [companiesList, setCompaniesList] = useState<CompanyModel[]>([]);
    const [companiesWasChanged, setCompaniesWasChanged] = useState(false);
    const [isGridReadyToShow, setGridReadyToShow] = useState(false);
    const api = useRef<GridStore<CompanyModel, any, any>>();
    const formApi = useExternalFormApi<ReportModel>();
    const defaultColumnRender = ({ cellData }: CellRendererProps<CompanyModel>) => <TextColumn>{cellData}</TextColumn>;
    const title = `${isEdit ? lang.EDIT : lang.NEW} ${lang.REPORT}`;
    const isIndividualMode = data.parameters.aggregationMode === ReportAggregationModeRepresentation.SingleCompany;
    const getCompaniesInGrid = () => api.current.data[1];
    const getSuitableCompanies = () => {
        if (api.current) {
            const filterName = api.current.filters.companyName ? api.current.filters.companyName.toLowerCase() : '';
            return companiesList.filter(
                (company) => {
                    const typeCondition = company.type !== CompanyTypeRepresentation.Reseller
                        || data.type === ReportTypeRepresentation.ProtectedDatabases
                        || data.type === ReportTypeRepresentation.ProtectedVms
                        || data.type === ReportTypeRepresentation.ProtectedFiles;

                    const roleCondition = !(company.type === CompanyTypeRepresentation.Reseller
                        && (portalUser.isServiceProviderOperator() || portalUser.isServiceProviderUser()));

                    const resellerCondition = data.parameters.includeResellerCompanies
                        || (!data.parameters.includeResellerCompanies && company.managerType !== ManagerType.Reseller);

                    const nameCondition = !filterName || company.name.toLowerCase().indexOf(filterName) > -1;

                    return nameCondition && resellerCondition && typeCondition && roleCondition;
                },
            );
        } else {
            return companiesList;
        }
    };
    const buildCompaniesAsResponse = (): Promise<BaseSuccessRequestResponse<CompanyModel[]>> => {
        const companies = getSuitableCompanies();

        return Promise.resolve({
            data: companies,
            meta: {
                pagingInfo: {
                    total: companies.length,
                },
            },
        } as BaseSuccessRequestResponse<CompanyModel[]>);
    };
    const updateCompaniesListInGrid = () => {
        const companies = getSuitableCompanies();
        api.current.updateData(companies, companies.length);

        let companiesForSelect;

        if (data.parameters.allCompaniesAndNewlyAdded) {
            companiesForSelect = companies;
        } else {
            companiesForSelect = [...api.current.selected].filter(company => companies.includes(company));
        }

        api.current.onSelectionChange({ selected: [...companiesForSelect], current: null });

        // hack for triggering local sorting with right direction
        // TODO fix hack when contract first will merged
        if (api.current.sortingConfig) {
            api.current.applySorting(api.current.sortingConfig.key);
            api.current.applySorting(api.current.sortingConfig.key);
        }
    };

    const oldHasInactiveCompanies = stepData.hasInactiveCompanies;
    stepData.hasInactiveCompanies = companiesList
        .filter(company => companies.includes(company.id))
        .some(company =>
            company.status === CompanyStatus.Disabled
            || company.status === CompanyStatus.Expired,
        );

    if (!oldHasInactiveCompanies && stepData.hasInactiveCompanies && companiesWasChanged || isIndividualMode) {
        stepData.showHasInactiveCompanies = () => core.notificationService.warning(title, lang.THE_SELECTED_REPORT_SCOPE);
    }

    if (!stepData.hasInactiveCompanies) {
        delete stepData.showHasInactiveCompanies;
    }

    useEffect(() => {
        reportStore.companiesListCache.load()
            .then((items) => {
                const changedItems: CompanyModel[] = deepClone(items);
                changedItems.forEach(item =>
                    item.statusDescription = enumMapperService.getEnumDescription('companyStatusRepresentation', item.status));
                setCompaniesList(changedItems);
            });
    }, []);

    useEffect(() => {
        if (companiesList.length > 0) {
            api.current.reloadGrid();
            if (companies) {
                api.current.selected = companies.map(id => ({ id: id })) as CompanyModel[];
            }
        }
    }, [companiesList]);

    useEffect(() => {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (companies.length === 0) {
                core.notificationService.warning(title, lang.PLEASE_SELECT_AT_LEAST_ONE);
            }
        }
    });

    const isIndividualAndEdit = isIndividualMode && isEdit;
    useEffect(() => {
        if (api.current) {
            api.current.disabled = isIndividualAndEdit;
        }
    });

    const formContainerRef = useRef<HTMLDivElement>();
    const gridContainerRef = useRef<HTMLDivElement>();

    useLayoutEffect(() => {
        const height = formContainerRef.current.clientHeight;
        const notGridHeight = 235;
        gridContainerRef.current.style.height = `${height - notGridHeight}px`;
        setGridReadyToShow(true);
    }, []);

    const isReseller = portalUser.isServiceProviderGroup();
    const isResellerOperator = portalUser.isServiceProviderOperator();
    const isPortalOperatorOrSiteAdmin = portalUser.isPortalOperator() || portalUser.isSiteAdministrator();

    const updateDefaultEmailOptions = (newAggregationMode: ReportAggregationModeRepresentation) => {
        if (!reportStore.isDefaultEmailOptions) {
            return;
        }

        const isAggregated = newAggregationMode === ReportAggregationModeRepresentation.MultiCompany;
        data.parameters.emailOptions = reportStore.getDefaultEmail(isAggregated);
    };

    useLayoutEffect(() => {
        updateDefaultEmailOptions(data.parameters.aggregationMode);
    }, []);

    useEffect(() => {
        if (api.current && reportStore.companyFilter) {
            api.current.filters.companyName = reportStore.companyFilter;
            reportStore.companyFilter = '';
        }
    });
    useEffect(() => () => reportStore.companyFilter = api.current.filters.companyName, []);

    return (
        <StepLayout
            title={capitalize(lang.COMPANIES)}
            description={lang.SELECT_COMPANIES_TO_CREATE_A_REPORT}
        >
            <div
                style={{ height: '100%' }}
                ref={formContainerRef}
            >
                <Form
                    value={data}
                    onChange={onDataChange}
                    externalFormApi={formApi}
                >
                    <FormLayout inlineLabel>
                        <FormTitle>{lang.REPORT_CONFIGURATION}</FormTitle>

                        <Radiobutton
                            name={'parameters.aggregationMode'}
                            value={ReportAggregationModeRepresentation.MultiCompany}
                            onChange={updateDefaultEmailOptions}
                            disabled={isEdit}
                            helpText={lang.CREATES_AN_OVERVIEW_REPORT}
                        >
                            {lang.SUMMARY_REPORT}
                        </Radiobutton>

                        <Radiobutton
                            name={'parameters.aggregationMode'}
                            value={ReportAggregationModeRepresentation.SingleCompany}
                            disabled={isEdit}
                            onChange={(newVal) => {
                                if (data.parameters.allCompaniesAndNewlyAdded) {
                                    formApi.setValue('parameters.allCompaniesAndNewlyAdded', false);
                                }
                                updateDefaultEmailOptions(newVal);
                            }}
                            helpText={lang.CREATES_A_SEPARATE_REPORT}
                        >
                            {lang.INDIVIDUAL_REPORTS}
                        </Radiobutton>

                        <FormTitle>{lang.REPORT_SCOPE}</FormTitle>

                        <NoLabelGroup
                            content={(
                                <div
                                    style={{ display: 'flex' }}
                                    ref={gridContainerRef}
                                >
                                    {isGridReadyToShow && (
                                        <AdvancedGrid
                                            sectionId={ConfigSectionIds.ReportsConfigurationsWizard}
                                            columns={[
                                                {
                                                    field: 'name',
                                                    title: lang.COMPANY,
                                                    cell: defaultColumnRender,
                                                },
                                                {
                                                    field: 'siteName',
                                                    title: lang.SITE,
                                                    cell: defaultColumnRender,
                                                },
                                                {
                                                    field: 'managedBy',
                                                    title: capitalize(lang.MANAGED_BY),
                                                    cell: defaultColumnRender,
                                                    width: 160,
                                                },
                                                {
                                                    field: 'statusDescription',
                                                    title: lang.STATE,
                                                    cell: defaultColumnRender,
                                                    width: 110,
                                                },
                                            ]}
                                            data={buildCompaniesAsResponse}
                                            disableAutoUpdate={true}
                                            paginationLimit={MAX_INTEGER_32}
                                            selection={{
                                                field: 'id',
                                                multiple: true,
                                                checkbox: true,
                                            }}
                                            toolbars={[
                                                () => (
                                                    <Toolbar
                                                        items={[
                                                            {
                                                                type: TOOLBAR_ITEM_TYPE.customControl,
                                                                render: () => (
                                                                    <Search
                                                                        name='companyName'
                                                                        placeholder={lang.COMPANY_NAME}
                                                                        hasSearchButton={false}
                                                                        disabled={isIndividualAndEdit}
                                                                    />
                                                                ),
                                                            },
                                                            {
                                                                type: TOOLBAR_ITEM_TYPE.customControl,
                                                                render: () => (
                                                                    <StackView
                                                                        gap={STACK_GAP.s}
                                                                    >
                                                                        <Text
                                                                            leader={INDENT.s}
                                                                            view={isIndividualMode ? TEXT_VIEW.light : TEXT_VIEW.inherit}
                                                                        >
                                                                            {lang.ALL_COMPANIES_INCLUDING_NEWLY_ADDED}
                                                                        </Text>

                                                                        <FixToggle
                                                                            showSuffix={true}
                                                                            value={data.parameters.allCompaniesAndNewlyAdded}
                                                                            onChange={(newVal) => {
                                                                                if (newVal) {
                                                                                    api.current.selectAll();
                                                                                }

                                                                                formApi.setValue('parameters.allCompaniesAndNewlyAdded', newVal);
                                                                            }}
                                                                            disabled={isIndividualMode}
                                                                        />
                                                                    </StackView>
                                                                ),
                                                                hidden: isPortalOperatorOrSiteAdmin || isResellerOperator,
                                                            },
                                                            {
                                                                type: TOOLBAR_ITEM_TYPE.separator,
                                                                hidden: isReseller || isPortalOperatorOrSiteAdmin,
                                                            },
                                                            {
                                                                type: TOOLBAR_ITEM_TYPE.customControl,
                                                                render: () => (
                                                                    <StackView
                                                                        gap={STACK_GAP.s}
                                                                    >
                                                                        <Text
                                                                            leader={INDENT.s}
                                                                        >
                                                                            {lang.INCLUDE_COMPANIES_MANAGED_BY_RESELLERS}
                                                                        </Text>

                                                                        <FixToggle
                                                                            showSuffix={true}
                                                                            value={data.parameters.includeResellerCompanies}
                                                                            onChange={(newVal) => {
                                                                                formApi.setValue('parameters.includeResellerCompanies', newVal);
                                                                                updateCompaniesListInGrid();
                                                                            }}
                                                                        />
                                                                    </StackView>
                                                                ),
                                                                hidden: isReseller || isPortalOperatorOrSiteAdmin,
                                                            },
                                                        ]}
                                                    />
                                                ),
                                            ]}
                                            onSelectionChange={(selected) => {
                                                const selectedCompanies = selected.map(selectedCompany => selectedCompany.id);
                                                reportStore.setCompanies(selectedCompanies);
                                                stepData.isJustProviderCompanies = selected.every(
                                                    company => company.type === CompanyTypeRepresentation.Provider
                                                );
                                                setCompaniesWasChanged(true);

                                                if (selected.length < getCompaniesInGrid().length && data.parameters.allCompaniesAndNewlyAdded) {
                                                    formApi.setValue('parameters.allCompaniesAndNewlyAdded', false);
                                                }

                                                updateDefaultEmailOptions(data.parameters.aggregationMode);
                                            }}
                                            api={api}
                                            sortingMode={SortingMode.Local}
                                            isRowInactive={({ rowData }) =>
                                                rowData.status === CompanyStatus.Disabled
                                                || rowData.status === CompanyStatus.Expired}
                                        />
                                    )}
                                </div>
                            )}
                        />
                    </FormLayout>
                </Form>
            </div>
        </StepLayout>
    );
});
