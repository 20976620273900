/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    DurationColumn,
    DurationFormatTypes,
    GridColumnJustify,
    GridSortDirections,
    PageDialog,
    Text,
    TextColumn,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';
import { JobSessionTaskStatusRepresentation } from '@veeam-vspc/models/web-controllers';

import type { CellRendererProps, PageDialogProps } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices, useAppStore } from 'views/providers/AppProvider/hooks';
import { StatusColumn } from 'views/components/columns/StatusColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { getJobSessionCdpTaskStatusRepresentationText } from '../../../../../../../../enums';
import { ProcessedVmsDialogToolbar } from '../ProcessedVmsDialogToolbar';
import { getFailureMessage } from 'views/components/FailureMessageDialog';
import { getMarkedList } from 'core/utils';

import type { CdpJobProcessedVmItem, ProcessedVmJobRequestParams, ProcessedVmsCdpJobsFilterModel } from '../../interfaces';

export interface ProcessedVmsCdpJobsDialogProps extends Omit<PageDialogProps, 'content'> {
    jobUid: string;
    stateId: string;
}

export const ProcessedVmsCdpJobsDialog: React.FC<ProcessedVmsCdpJobsDialogProps> = (props) => {
    const lang = useLang();
    const { formats } = useAppStore();
    const { netShortDate, netShortTime } = formats;
    const { enumMapperService, transportService } = useAppServices();
    const { jobUid, stateId, title, description, disabledBack, hasBack, footer, onClose } = props;
    const defaultColumnRender = ({ cellData }: CellRendererProps<CdpJobProcessedVmItem>) => (
        <TextColumn>{cellData}</TextColumn>
    );

    return (
        <PageDialog
            title={title}
            description={description}
            disabledBack={disabledBack}
            hasBack={hasBack}
            footer={footer}
            onClose={onClose}
            content={(
                <AdvancedGrid
                    stateId={`${stateId}.Dialogs.ProcessedVms.Cdp`}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: 'name',
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={[
                        {
                            field: 'name',
                            title: lang.OBJECT,
                            cell: defaultColumnRender,
                        },
                        {
                            field: 'status',
                            title: lang.STATUS,
                            cell: ({ cellData, rowData }: CellRendererProps<CdpJobProcessedVmItem>) => {
                                const statusName = rowData.status;
                                const linkStatuses = [
                                    JobSessionTaskStatusRepresentation.Failed,
                                    JobSessionTaskStatusRepresentation.Warning,
                                ];

                                const message = linkStatuses.includes(statusName)
                                    ? getFailureMessage(lang, {
                                        shortDateFormat: netShortDate,
                                        shortTimeFormat: netShortTime,
                                        failureMessage: rowData.failureMessage,
                                        lastSessionTime: rowData.lastSessionEndTime,
                                        statusName: rowData.status,
                                    })
                                    : getMarkedList(rowData.failureMessage);

                                return (
                                    <StatusColumn
                                        status={getJobSessionCdpTaskStatusRepresentationText(cellData as JobSessionTaskStatusRepresentation)}
                                        messageBoxTitle={lang.LAST_OBSERVED_ERRORS}
                                        message={message}
                                    />
                                );
                            },
                        },
                        {
                            field: 'sla',
                            title: lang.SLA,
                            cell: ({ cellData }: CellRendererProps<CdpJobProcessedVmItem>) => <Text>{`${cellData}%`}</Text>,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'bottleneck',
                            title: lang.BOTTLENECK,
                            cell: ({ rowData }: CellRendererProps<CdpJobProcessedVmItem>) => (
                                <TextColumn>
                                    {enumMapperService.getEnumDescription('jobSessionTaskStatusRepresentation', rowData.bottleneck)}
                                </TextColumn>
                            ),
                            hidden: true,
                        },
                        {
                            field: 'maxDelaySec',
                            title: lang.MAX_DELAY,
                            cell: ({ cellData }: CellRendererProps<CdpJobProcessedVmItem>) => (
                                <DurationColumn value={cellData as any} formatType={DurationFormatTypes.MTIMEw} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'avgDurationSec',
                            title: lang.AVG_DURATION,
                            cell: ({ cellData }: CellRendererProps<CdpJobProcessedVmItem>) => (
                                <DurationColumn value={cellData as any} formatType={DurationFormatTypes.MTIMEw} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'maxDurationSec',
                            title: lang.MAX_DURATION,
                            cell: ({ cellData }: CellRendererProps<CdpJobProcessedVmItem>) => (
                                <DurationColumn value={cellData as any} formatType={DurationFormatTypes.MTIMEw} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'intervalSec',
                            title: capitalize(lang.SYNC_INTERVAL),
                            cell: ({ cellData }: CellRendererProps<CdpJobProcessedVmItem>) => (
                                <DurationColumn value={cellData as any} formatType={DurationFormatTypes.MTIMEw} />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'successfulSessionsCount',
                            title: lang.SUCCESSFUL_SESSIONS,
                            cell: defaultColumnRender,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'failedSessionsCount',
                            title: lang.FAILED_SESSIONS,
                            cell: defaultColumnRender,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'warningsCount',
                            title: lang.WARNINGS,
                            cell: defaultColumnRender,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'avgTransferredDataKb',
                            title: lang.AVG_DATA,
                            cell: ({ cellData }: CellRendererProps<CdpJobProcessedVmItem>) => <RestorePointSizeColumn value={cellData as any} />,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                            hidden: true,
                        },
                        {
                            field: 'maxTransferredDataKb',
                            title: lang.MAXIMUM_DATA,
                            cell: ({ cellData }: CellRendererProps<CdpJobProcessedVmItem>) => <RestorePointSizeColumn value={cellData as any} />,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                            hidden: true,
                        },
                        {
                            field: 'totalTransferredDataKb',
                            title: lang.TOTAL_DATA,
                            cell: ({ cellData }: CellRendererProps<CdpJobProcessedVmItem>) => <RestorePointSizeColumn value={cellData as any} />,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                    ]}
                    data={(requestParams: ProcessedVmJobRequestParams<ProcessedVmsCdpJobsFilterModel>) => transportService
                        .request<typeof requestParams, CdpJobProcessedVmItem[]>(
                            '/BackupServerJob/GetCdpProcessedVms',
                            { jobUid, ...requestParams },
                        )}
                    toggleable={true}
                    selection={{
                        field: 'vmUid',
                        checkbox: true,
                        multiple: true,
                    }}
                    toolbars={[
                        () => (
                            <ProcessedVmsDialogToolbar
                                method={'CdpProcessedVms'}
                                idProperty={'vmUid'}
                                extraParams={{ jobUid }}
                            />
                        ),
                    ]}
                />
            )}
        />
    );
};
