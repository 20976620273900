/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { Dialog, DIALOG_SIZE, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE, Search, ACTION_VIEW, TextColumn, SortingMode } from '@veeam-vspc/components';
import { Vb365JobItemComposed } from '@veeam-vspc/models/rest';

import type { Vb365JobItemUser } from '@veeam-vspc/models/rest';
import type { GridStore, CellRendererProps } from '@veeam-vspc/components';
import type { FC } from 'react';

import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { AdvancedToolbar } from 'views/components/AdvancedToolbar';
import { useJobWizardStore } from '../../../../stores';
import { BackupModeService } from '../../services';
import { ObjectTypeCell } from '../ObjectTypeCell/ObjectTypeCell';

import type { UsersModalProps } from '../../interfaces';

export const UsersModal: FC<UsersModalProps> = ({ onCancel, onApply }) => {
    const lang = useLang();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<Vb365JobItemUser, unknown, unknown>>();

    const apply = () => {
        const selected = api.current.selected;

        onApply(selected);
    };

    const { vb365ServerUid, instanceUid } = wizardStore.selectedOrganization;

    const defaultColumnRender = ({ cellData }: CellRendererProps<Vb365JobItemUser>) => <TextColumn>{cellData}</TextColumn>;

    return (
        <Dialog
            header={lang.ADD_USERS}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={onCancel}
            actions={[
                { text: lang.ADD, onClick: apply },
                { text: lang.CLOSE, onClick: onCancel, view: ACTION_VIEW.secondary },
            ]}
        >
            <AdvancedGrid
                api={api}
                columns={[
                    { field: 'displayName', title: lang.NAME, cell: defaultColumnRender },
                    { field: 'name', title: lang.EMAIL, cell: defaultColumnRender },
                    { field: 'officeName', title: lang.OFFICE, cell: defaultColumnRender },
                    {
                        field: 'userType',
                        title: lang.TYPE,
                        cell: ({ rowData, rowIndex }) => (
                            <ObjectTypeCell
                                rowData={
                                    {
                                        user: { userType: rowData.userType },
                                        itemType: Vb365JobItemComposed.ItemTypeEnum.User,
                                    } as Vb365JobItemComposed
                                }
                                rowIndex={rowIndex}
                            />
                        ),
                    },
                ]}
                data={BackupModeService.getUsers(vb365ServerUid, instanceUid, api.current)}
                defaultColumnWidth={100}
                disableAutoUpdate
                sortingMode={SortingMode.Local}
                useCompactPaginationWithCurrentPage
                toolbars={[
                    () => (
                        <AdvancedToolbar
                            view={TOOLBAR_VIEW_TYPE.spotted}
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='userDisplayNameFilter'
                                            placeholder={lang.USER}
                                            hasSearchButton
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
                selection={{
                    field: 'id',
                    checkbox: true,
                    multiple: true,
                }}
                isRowExcludedFromAllSelection={rowData => wizardStore.isItemSelected(rowData.id, Vb365JobItemComposed.ItemTypeEnum.User)}
                isRowDisabled={({ rowData }) => wizardStore.isItemSelected(rowData.id, Vb365JobItemComposed.ItemTypeEnum.User)}
            />
        </Dialog>
    );
};
