/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { JobsOverviewStoreContext } from './jobs-overview-store-context';

import type { JobsOverviewStore } from './jobs-overview-store';

export const useJobsOverviewStore = (): JobsOverviewStore => useContext(JobsOverviewStoreContext);
