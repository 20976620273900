/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts, isPositiveNumber } from '@veeam-vspc/components';

import { PublicCloudsRestorePointDialog } from './components';

import type { PublicCloudsRestorePointDialogProps } from './components';

export interface PublicCloudsRestorePointColumnProps extends Omit<PublicCloudsRestorePointDialogProps, 'deactivate'> {
    value: string | number | null;
}

export const PublicCloudsRestorePointColumn: React.FC<PublicCloudsRestorePointColumnProps> = ({ value, ...restProps }) => (
    <ActionLinkColumn
        content={isPositiveNumber(value) ? value : ColumnTexts.DashSymbol}
        isLink={isPositiveNumber(value)}
        isExtMode={true}
        renderModal={({ deactivate }) => (
            <PublicCloudsRestorePointDialog
                {...restProps}
                deactivate={deactivate}
            />
        )}
    />
);

