/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { PublicClouds, VirtualInfrastructure } from './components';

export interface VirtualMachinesProps {
    gridName: string;
}

export const VirtualMachines: React.FC<VirtualMachinesProps> = ({ gridName, ...restProps }) => {
    switch (gridName) {
        case ('VirtualInfrastructure'): return <VirtualInfrastructure stateId={'BackupJobsPage.VirtualMachines'} {...restProps} />;
        case ('PublicClouds'): return <PublicClouds stateId={'BackupJobsPage.PublicClouds'} {...restProps} />;
        default: return null;
    }
};
