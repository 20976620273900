/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    colors,
    ComboboxKit,
    constants,
    CONTROL_SIZE,
    createSearchableControl,
    GridSortDirections,
    Icon,
    INDENT,
    Link,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TextColumn,
    TitleH2,
} from '@veeam-vspc/components';
import React, { useEffect, useRef, useState } from 'react';
import { CompanyTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { CompanyGetCountriesWithCompaniesResponse } from '@veeam-vspc/models/web-controllers';
import type { GridStore, RequestParams } from '@veeam-vspc/components';
import type { CompanyHealthOverviewModel } from '@veeam-vspc/models/web-controllers/companyHealthOverviewModel';
import type { CountryWithCompanies } from '@veeam-vspc/models/web-controllers/countryWithCompanies';
import type { CompanyHealthOverviewParam } from '@veeam-vspc/models/web-controllers/companyHealthOverviewParam';

import { DASHBOARD_MAP_HEIGHT, WORLD_MAP_ID } from '../../../../configs';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { CompanyStatus } from '../../../../interfaces';
import successIcon from 'images/success-status.svg';
import warningIcon from 'images/warning-status.svg';
import errorIcon from 'images/error-status.svg';
import questionIcon from 'images/question.svg';
import noLocationSrc from 'images/statuses/no-location.svg';
import { core } from 'core/core-module';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useTransportRequest } from 'views/hooks';
import { createFilter } from '../../../../helpers/create-filter';
import { useGlobalFiltersChangeHandler } from 'views/providers/GlobalFiltersProvider/hooks';
import { useAppStore } from 'views/providers/AppProvider/hooks';

import type { CompanyHealthRequest } from '../../../../interfaces';
import type { VspcTransportBaseData } from 'core/services';

export const RegionsGrid: React.FC = () => {
    const { portalUser } = useAppStore();
    const lang = useLang();
    const api = useRef<GridStore<CompanyHealthOverviewModel, {}, CompanyHealthRequest>>();
    const [countryId, setCountryId] = useState(WORLD_MAP_ID);
    const request = useTransportRequest<{}, CompanyGetCountriesWithCompaniesResponse>('Company/GetCountriesWithCompanies');
    const regions = request.data as CountryWithCompanies[];
    const hasRegions = regions?.length > 0;
    const onlyAllRegion: CountryWithCompanies[] = [{ name: lang.ALL, id: WORLD_MAP_ID }];

    useGlobalFiltersChangeHandler(() => {
        request.forceRequest();
    });

    useEffect(() => api.current?.applyFilters({ country: countryId }), [countryId]);

    const getUrl = (organizationType: CompanyTypeRepresentation) => {
        const urls = {
            [CompanyTypeRepresentation.Tenant]: '/#home/companies',
            [CompanyTypeRepresentation.Provider]: '/#config/companyInfo/tab/profile',
            [CompanyTypeRepresentation.Reseller]: portalUser.isResellerPortal() ?
                '/#config/companyInfo/tab/profile' :
                '/#home/resellers',
        };

        return urls[organizationType];
    };

    return (
        <StackView
            direction={STACK_DIRECTION.column}
            spaceAll={INDENT.m}
            style={{
                backgroundColor: colors.G000,
                flex: 1,
            }}
        >
            <TitleH2 style={{ marginBottom: constants.SPACING_M }}>{lang.COMPANIES_STATE_BY_REGIONS}</TitleH2>

            <ComboboxKit
                value={countryId}
                onChange={(countryId) => {
                    setCountryId(countryId ?? WORLD_MAP_ID);
                }}
                data={hasRegions ? [...onlyAllRegion, ...regions] : onlyAllRegion}
                disabled={request.loading || !hasRegions}
                size={CONTROL_SIZE.full}
                valueGetter={item => item.id}
                textGetter={item => item.name}
                controlRenderer={createSearchableControl()}
            />

            <StackView style={{ height: DASHBOARD_MAP_HEIGHT }}>
                <AdvancedGrid
                    defaultColumnWidth={100}
                    initialState={{
                        filters: {
                            country: WORLD_MAP_ID,
                        },
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: 'name',
                        },
                    }}
                    columns={[
                        { field: 'name', title: lang.COMPANY },
                        {
                            field: 'status',
                            title: lang.STATE,
                            cell({ rowData }) {
                                switch (rowData.status) {
                                    case CompanyStatus.Healthy:
                                        return (
                                            <StackView gap={STACK_GAP.s}>
                                                <Icon src={successIcon} />
                                                <Text>{lang.HEALTHY}</Text>
                                            </StackView>
                                        );
                                    case CompanyStatus.Warning:
                                        return (
                                            <StackView gap={STACK_GAP.s}>
                                                <Icon src={warningIcon} />
                                                <Text>{lang.WARNING}</Text>
                                            </StackView>
                                        );
                                    case CompanyStatus.Error:
                                        return (
                                            <StackView gap={STACK_GAP.s}>
                                                <Icon src={errorIcon} />
                                                <Text>{lang.ERROR}</Text>
                                            </StackView>
                                        );
                                    default:
                                        return (
                                            <StackView gap={STACK_GAP.s}>
                                                <Icon src={questionIcon} />
                                                <Text>{lang.UNKNOWN}</Text>
                                            </StackView>
                                        );
                                }
                            },
                        },
                        {
                            field: 'regionName',
                            title: lang.REGION,
                            cell({ cellData, rowData }) {


                                return (
                                    <TextColumn>
                                        {cellData || (
                                            <StackView align={STACK_ALIGN.center} gap={STACK_GAP.s}>
                                                <Icon src={noLocationSrc} />

                                                <Link href={getUrl(rowData.organizationType)}>
                                                    {`${lang.NOT_SET}...`}
                                                </Link>
                                            </StackView>
                                        )}
                                    </TextColumn>
                                );
                            },
                        },
                    ]}
                    data={(requestParams: RequestParams<CompanyHealthRequest>) =>
                        core.transportService.request<CompanyHealthOverviewParam & VspcTransportBaseData, CompanyHealthOverviewModel[]>(
                            'Overview/CompanyHealth',
                            {
                                filter: createFilter(requestParams.filter?.country),
                                limit: requestParams.limit,
                                sort: requestParams.sort,
                                start: requestParams.start,
                                rcopAutoUpdate: requestParams.rcopAutoUpdate,
                            },
                        )}
                    toggleable={false}
                    resizable={false}
                    api={api}
                />
            </StackView>
        </StackView>
    );
};
