/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ColumnTexts } from '@veeam-vspc/components';
import { Vb365JobItemGroup } from '@veeam-vspc/models/rest';

import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const groupTypeTitles = (type: Vb365JobItemGroup.GroupTypeEnum, lang: LangsServiceBase) => ({
    [Vb365JobItemGroup.GroupTypeEnum.Distribution]: lang.DISTRIBUTION_GROUP,
    [Vb365JobItemGroup.GroupTypeEnum.DynamicDistribution]: lang.DYNAMIC_DISTRIBUTION_GROUP,
    [Vb365JobItemGroup.GroupTypeEnum.Office365]: lang.MICROSOFT_365_GROUP,
    [Vb365JobItemGroup.GroupTypeEnum.Security]: lang.SECURITY_GROUP,
    [Vb365JobItemGroup.GroupTypeEnum.Unknown]: ColumnTexts.DashSymbol,
}[type]);