/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { CompanyVboArchiveRepository, CompanyVboBackupRepository, CompanyVboProxy } from 'core/interfaces';

export const getVboProxy = (repositoryUid: string, proxies: CompanyVboProxy[], repositories: CompanyVboBackupRepository[]) =>
    repositoryUid && proxies && repositories
        ? proxies.find(proxy => proxy.instanceUid === repositories
            .find(repository => repository.instanceUid === repositoryUid).vboProxyInstanceUid)
        : null;

export const getVboProxyInArchiveRepositories = (repositoryUid: string, proxies: CompanyVboProxy[], repositories: CompanyVboArchiveRepository[]) =>
    repositoryUid && proxies && repositories
        ? proxies.find(proxy => proxy.instanceUid === repositories
            .find(repository => repository.instanceUid === repositoryUid).vboProxyInstanceUid)
        : null;
