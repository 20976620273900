/**
 * Copyright © Veeam Software Group GmbH.
 */

import { PortalUserRoles } from '../../entries/portal-user/enums';

const helpVersion = 70;

const getHelpResourceLink = (resourceId: string) => `https://redirect.veeam.com/helpvac${helpVersion}topicid=${resourceId}`;

const getRestorePointsHelpResource = (userRole: PortalUserRoles) => {
    let resourceId;

    if (
        userRole === PortalUserRoles.LocationAdministrator
        || userRole === PortalUserRoles.CompanyOwner
        || userRole === PortalUserRoles.CompanyAdministrator
        || userRole === PortalUserRoles.LocationUser
    ) {
        resourceId = '527';
    } else if (
        userRole === PortalUserRoles.ServiceProviderGlobalAdministrator
        || userRole === PortalUserRoles.ServiceProviderAdministrator
        || userRole === PortalUserRoles.ServiceProviderOperator
    ) {
        resourceId = '526';
    } else {
        resourceId = '525';
    }

    return getHelpResourceLink(resourceId);
};

export {
    getHelpResourceLink,
    getRestorePointsHelpResource,
};
