/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    SortingMode,
    SPACE_FILL,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextColumn,
} from '@veeam-vspc/components';
import { NotificationDialogsTextKeys } from '@veeam-vspc/core';

import type { GridStore, RequestParams } from '@veeam-vspc/components';
import type { BaseSuccessRequestResponse, NotificationResponse } from '@veeam-vspc/core';
import type { FC } from 'react';
import type { Vb365OrganizationBase } from '@veeam-vspc/models/rest';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useJobWizardStore } from '../../../../stores';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import {
    AvailableOrganizationsToolbar,
} from './components/AvailableOrganizationsToolbar/AvailableOrganizationsToolbar';

interface AvailableOrganizationsProps {
    hidePanel: () => void;
}

export const AvailableOrganizations: FC<AvailableOrganizationsProps> = observer(({ hidePanel }) => {
    const lang = useLang();
    const { notificationService } = useAppServices();
    const wizardStore = useJobWizardStore();
    const api = useRef<GridStore<Vb365OrganizationBase, unknown, unknown>>();

    const fetchData = ({ page, limit, filter }: RequestParams<Vb365OrganizationBase>):
        Promise<BaseSuccessRequestResponse<Vb365OrganizationBase[]>> => {
        const items = wizardStore.organizations
            .filter(x => x.name.toLowerCase().includes((filter.name || '').toLowerCase().trim()));

        const chunk = items.filter((x, index) => index >= (page * limit) - limit && index < page * limit);

        return Promise.resolve({
            data: chunk,
            meta: {
                pagingInfo: {
                    total: items.length,
                },
            },
        } as BaseSuccessRequestResponse<Vb365OrganizationBase[]>);
    };

    const handleApply = () => {
        const organization = api.current?.selected[0];

        if (!organization) {
            return;
        }

        if (organization.instanceUid === wizardStore.vbOrganizationUid) {
            hidePanel();

            return;
        }

        if (!wizardStore.selectedOrganization) {
            wizardStore.selectOrganization(organization);

            hidePanel();

            return;
        }

        notificationService
            .confirm(lang.AVAILABLE_ORGANIZATIONS, lang.ALL_CONFIGURED_OBJECTS_TO_BACK_UP_WILL_BE_REMOVED)
            .then((btnKey: NotificationResponse) => {
                if (btnKey === NotificationDialogsTextKeys.Yes) {
                    wizardStore.selectOrganization(organization);
                    wizardStore.resetJobSettings();

                    hidePanel();
                }
            });
    };

    return (
        <SidePanelForm
            title={lang.AVAILABLE_ORGANIZATIONS}
            description={lang.SELECT_ORGANIZATION_FROM_THE_LIST}
            onRequestClose={hidePanel}
            actions={[
                { text: lang.APPLY, onClick: handleApply, disabled: api.current?.selected.length === 0 },
                { text: lang.CANCEL, onClick: hidePanel, view: ACTION_VIEW.secondary },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} spaceFill={SPACE_FILL.all} gap={STACK_GAP.s}>
                <AdvancedGrid
                    api={api}
                    columns={[
                        {
                            field: 'name',
                            title: lang.ORGANIZATION,
                            cell: ({ rowData }) => <TextColumn>{rowData.name}</TextColumn>,
                        },
                        {
                            field: 'vb365ServerName',
                            title: lang.SERVER,
                            cell: ({ rowData }) => <TextColumn>{rowData.vb365ServerName}</TextColumn>,
                        },
                    ]}
                    data={fetchData}
                    initialState={{
                        selected: wizardStore.selectedOrganization ? [wizardStore.selectedOrganization] : [],
                    }}
                    disableAutoUpdate
                    selection={{
                        field: 'instanceUid',
                        checkbox: false,
                        multiple: false,
                    }}
                    sortingMode={SortingMode.Local}
                    toolbars={[
                        () => <AvailableOrganizationsToolbar />,
                    ]}
                />
            </StackView>
        </SidePanelForm>
    );
});
