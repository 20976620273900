/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/use-page-wizard-store';
import { capitalize, formatStr } from '@veeam-vspc/core';
import {
    CONTROL_SIZE,
    Form,
    FormLayout,
    FormTitle,
    FieldLayout,
    LinkButton,
    NumberInput,
    NoLabelGroup,
    Checkbox,
    STACK_DIRECTION,
    Spacer,
    FormValidator,
    isValid,
    useExternalFormApi,
} from '@veeam-vspc/components';
import { ThrottlingUnitRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { DataSizeInBytes } from 'components/controls/DataSizeInBytes';
import { PerBytesField } from 'components/controls/PerBytesField';
import { core } from 'core/core-module';
import { ToggleField } from 'components/controls/ToggleField';
import { DisplayField } from 'components/controls/DisplayField';
import { DEFAULT_DATA_TRANSFER_GB } from 'core/const';
import { useCompanyWizardStore } from '../../hooks';
import { GatewayPools } from './components/GatewayPools';
import { getGatewayNames } from '../../utils';
import { getBandwidthUnits, getBandwidthUnitsText } from 'views/components/wizard/utils';

import type { AvailableDataTransferOutQuota, CompanyData, CompanyWizardStepData } from '../../interfaces';
import type { CompanyWizardStore } from '../../stores';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

const MAX_CONCURRENT_TASK = 10000;
const MIN_CONCURRENT_TASK = 1;

const MAX_ALLOWED_BANDWIDTH = 9999;
const MIN_ALLOWED_BANDWIDTH = 1;

const MIN_DATA_TRANSFER_OUT_QUOTA = 1;

const getSpeedKbitPerSec = ({ value, unit }: { value: number; unit: ThrottlingUnitRepresentation; }) => {
    switch (unit) {
        case ThrottlingUnitRepresentation.KbytePerSec: return value * 8;
        case ThrottlingUnitRepresentation.MbytePerSec: return value * 8000;
        case ThrottlingUnitRepresentation.MbitPerSec: return value * 1000;
    }
};

const isDataTransferOutQuotaDisabled = (data: CompanyData): boolean => !data.cloudBackupResourceEnabled && !data.cloudReplicationResourceEnabled;

const getMaxDataTransferOutQuotaGb = (availableDataTransferOutQuota: AvailableDataTransferOutQuota) => {
    const defaultMaxDataTransferOutQuota = 999999;
    return !availableDataTransferOutQuota || availableDataTransferOutQuota.isUnlimited
        ? defaultMaxDataTransferOutQuota
        : availableDataTransferOutQuota.availableQuota;
};

const formValidate = (data: CompanyData, lang: LangsServiceBase, wizardStore: CompanyWizardStore) => {
    const validator = new FormValidator(data);

    validator.validate('maxConcurrentTask')
        .number()
        .required()
        .min(MIN_CONCURRENT_TASK)
        .max(MAX_CONCURRENT_TASK);

    if (data.bandwidthThrottlingEnabled) {
        validator.validate('allowedBandwidth')
            .number()
            .required()
            .min(MIN_ALLOWED_BANDWIDTH)
            .max(MAX_ALLOWED_BANDWIDTH);
    }

    const getMaxDataTransferOutQuota = (maxValueInGb: number, fieldValue: number) => {
        const multiplier = 1024;
        if (fieldValue % multiplier === 0) {
            // in TB
            return Math.floor(maxValueInGb / multiplier);
        }
        return maxValueInGb;
    };

    if (!isDataTransferOutQuotaDisabled(data) && wizardStore.dataTransferGbEnabled && wizardStore.availableDataTransferOutQuota) {
        const maxDataTransferOutQuotaGb = getMaxDataTransferOutQuotaGb(wizardStore.availableDataTransferOutQuota);
        const dataTransferOutQuotaValidator = validator.validate('dataTransferGb').number();
        const maxDataTransferOutQuotaMessage = formatStr(
            dataTransferOutQuotaValidator.defaultMaxErrorMessage,
            getMaxDataTransferOutQuota(maxDataTransferOutQuotaGb, data.dataTransferGb)
        );

        dataTransferOutQuotaValidator
            .required()
            .min(MIN_DATA_TRANSFER_OUT_QUOTA)
            .max(maxDataTransferOutQuotaGb, maxDataTransferOutQuotaMessage);
    }

    return validator.result();
};

const stepValidate = async(data: CompanyData, lang: any, wizardStore: CompanyWizardStore) => {
    if (!isValid(value => formValidate(value, lang, wizardStore), data)) {
        return false;
    }

    // Check available data transfer out quota
    if ((data.cloudBackupResourceEnabled || data.cloudReplicationResourceEnabled) && !wizardStore.dataTransferGbEnabled
        && wizardStore.availableDataTransferOutQuota && !wizardStore.availableDataTransferOutQuota.isUnlimited) {
        core.notificationService.error(lang.DATA_TRANSFER_OUT, lang.CANNOT_SET_THE_UNLIMITED_DATA);
        return false;
    }

    const resellerLimit = await wizardStore.companyBandwidthLimitCache.load({ companyId: data.id });
    if (!resellerLimit.isUnlimitedBandwidth) {
        // Check available max concurrent tasks
        if (data.maxConcurrentTask > resellerLimit.maxConcurrentTasks) {
            core.notificationService.error(
                lang.BANDWIDTH,
                formatStr(lang.CANNOT_LIMIT_INCOMING_MAX_CONCURRENT_TASKS, resellerLimit.maxConcurrentTasks)
            );
            return false;
        }

        if (resellerLimit.isThrottlingEnabled) {
            if (data.bandwidthThrottlingEnabled) {
                // Check available bandwidth limit
                const companyBandwidthSpeedKbitPerSec = getSpeedKbitPerSec({
                    value: data.allowedBandwidth,
                    unit: data.allowedBandwidthUnits,
                });

                const resellerBandwidthSpeedKbitPerSec = getSpeedKbitPerSec({
                    value: resellerLimit.throttlingValue,
                    unit: resellerLimit.throttlingUnit,
                });

                if (resellerLimit.isThrottlingEnabled && companyBandwidthSpeedKbitPerSec > resellerBandwidthSpeedKbitPerSec) {
                    core.notificationService.error(
                        lang.BANDWIDTH,
                        formatStr(
                            lang.CANNOT_LIMIT_INCOMING_NETWORK_TRAFFIC,
                            resellerLimit.throttlingValue,
                            getBandwidthUnitsText(resellerLimit.throttlingUnit, lang)
                        )
                    );
                    return false;
                }
            } else {
                core.notificationService.error(lang.BANDWIDTH, `${lang.YOU_MUST_SET_A_LIMIT_FOR_INCOMING}.`);
                return false;
            }
        }
    }

    return true;
};

export const getBandwidthStep = (lang: any, wizardStore: CompanyWizardStore): WizardStep<CompanyData> => ({
    title: lang.BANDWIDTH,
    validate: ({ data }) => stepValidate(data, lang, wizardStore),
    render: stepData => <BandwidthStep {...stepData} wizardStore={wizardStore} />,
});

const BandwidthStep = observer((wizardStepData: CompanyWizardStepData) => {
    const lang = useLang();
    const { data, onDataChange, validationState, isEdit } = wizardStepData;
    const wizardContext = usePageWizardStore<CompanyData>();
    const wizardStore = useCompanyWizardStore();
    const showGatewayPools = () => wizardContext.openPanel(hide => <GatewayPools {...wizardStepData} hidePanel={hide} />);
    const formApi = useExternalFormApi<CompanyData>();

    const canSelectGateways = (cloudConnectAgentVersion: string) => {
        const minCloudConnectAgentVersion = 954;
        let version = -1;
        const numbers = cloudConnectAgentVersion?.split('.') ?? [];
        if (numbers && numbers.length >= 2) {
            version = parseInt(numbers.slice(0, 3).join(''), 10);
        }
        return version >= minCloudConnectAgentVersion;
    };

    useEffect(() => {
        wizardStore.cloudConnectAgentsCache.load();

        if (wizardStore.availableDataTransferOutQuota === null) {
            wizardStore.loadAvailableDataTransferOutQuota(data.organizationUid)
                .then((availableQuota) => {
                    if (!isEdit || data.dataTransferGb === 0) {
                        data.dataTransferGb = !availableQuota.isUnlimited && DEFAULT_DATA_TRANSFER_GB > availableQuota.availableQuota
                            ? availableQuota.availableQuota
                            : DEFAULT_DATA_TRANSFER_GB;

                        formApi.setValue(data);
                    }
                });
        }

        wizardStore.companyBandwidthLimitCache.load({ companyId: data.id })
            .then((resellerLimit) => {
                if (!isEdit && !resellerLimit.isUnlimitedBandwidth && resellerLimit.isThrottlingEnabled) {
                    formApi.setValue('bandwidthThrottlingEnabled', true);
                }
            });
    }, []);

    return (
        <StepLayout
            title={lang.BANDWIDTH}
            description={lang.SPECIFY_MAXIMUM_NUMBER_OF_TASK}
        >
            <Form
                value={data}
                validate={(data: CompanyData) => formValidate(data, lang, wizardStore)}
                validationState={validationState}
                onChange={onDataChange}
                externalFormApi={formApi}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{capitalize(lang.CONCURRENT_TASKS, true)}</FormTitle>

                    <FieldLayout
                        size={CONTROL_SIZE.full}
                        helpText={lang.MAX_CONCURRENT_TASK_DESCRIPTION}
                    >
                        <NumberInput
                            inlineLabel={true}
                            name={'maxConcurrentTask'}
                            label={lang.MAX_CONCURRENT_TASKS}
                            minValue={MIN_CONCURRENT_TASK}
                            maxValue={MAX_CONCURRENT_TASK}
                        />
                    </FieldLayout>

                    <NoLabelGroup
                        content={(
                            <Checkbox
                                name={'bandwidthThrottlingEnabled'}
                            >
                                {lang.LIMIT_INCOMING_NETWORK_TRAFFIC_TO}
                            </Checkbox>
                        )}
                        subContent={(
                            <FormLayout
                                inlineLabel
                                disabled={!data.bandwidthThrottlingEnabled}
                            >
                                <FieldLayout
                                    helpText={lang.LIMIT_NETWORK_TRAFFIC_DESCRIPTION}
                                >
                                    <PerBytesField
                                        valueName={'allowedBandwidth'}
                                        unitsName={'allowedBandwidthUnits'}
                                        units={getBandwidthUnits(lang)}
                                        unitSize={CONTROL_SIZE.xs}
                                        minValue={MIN_ALLOWED_BANDWIDTH}
                                        maxValue={MAX_ALLOWED_BANDWIDTH}
                                    />
                                </FieldLayout>
                            </FormLayout>
                        )}
                    />

                    <FormTitle>{lang.GATEWAY_POOL}</FormTitle>

                    <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                        <DisplayField label={lang.ENABLED_OPTION} value={getGatewayNames(data, lang)} />
                        <Spacer />

                        <LinkButton
                            onClick={() => {
                                if (!canSelectGateways(wizardStore.cloudConnectAgentsCache.value
                                    ?.find(agent => agent.agentGuid === data.cloudConnectAgentUid)?.serverVersion)) {
                                    core.notificationService.warning(
                                        capitalize(lang.GATEWAY_SELECTION),
                                        lang.GATEWAY_POOLS_ARE_NOT_SUPPORTED
                                    );
                                } else {
                                    showGatewayPools();
                                }
                            }}
                        >
                            {lang.CHOOSE}
                        </LinkButton>
                    </FormLayout>

                    {(data.cloudBackupResourceEnabled || data.cloudReplicationResourceEnabled) && (
                        <>
                            <FormTitle>{capitalize(lang.DATA_TRANSFER_OUT, true)}</FormTitle>

                            <FieldLayout helpText={lang.SET_DATA_TRANSFER_OUT}>
                                <FormLayout inlineLabel direction={STACK_DIRECTION.row} disabled={isDataTransferOutQuotaDisabled(data)}>
                                    <ToggleField
                                        value={wizardStore.dataTransferGbEnabled}
                                        onChange={value => wizardStore.dataTransferGbEnabled = value}
                                        label={lang.QUOTA}
                                        showSuffix={true}
                                        disabled={isDataTransferOutQuotaDisabled(data)} // TODO: use disabled from FormLayout
                                    />

                                    <DataSizeInBytes
                                        name={'dataTransferGb'}
                                        allowDecimal={false}
                                        unitType={'G'}
                                        disabled={!wizardStore.dataTransferGbEnabled}
                                        minValue={MIN_DATA_TRANSFER_OUT_QUOTA}
                                        maxValue={getMaxDataTransferOutQuotaGb(wizardStore.availableDataTransferOutQuota)}
                                    />
                                </FormLayout>
                            </FieldLayout>
                        </>
                    )}
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
