/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { AdvancedFilter, Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE } from '@veeam-vspc/components';

import type { AdvancedFilterOptions, ToolbarProps, AdvancedFilterValue } from '@veeam-vspc/components';

import { EJobStates } from 'core/enums';
import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedToolbar } from 'views/components/AdvancedToolbar';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { useJobFileTypesAdvancedFilter } from 'views/components/filters/JobTypesFilters';

export interface FileSharesLocalFilterToolbarProps extends Omit<ToolbarProps, 'items'> {
}

export const FileSharesLocalFilterToolbar: React.FC<FileSharesLocalFilterToolbarProps> = (props) => {
    const lang = useLang();
    const statuses = [
        { title: lang.SUCCESS, value: EJobStates.Success },
        { title: lang.WARNING, value: EJobStates.Warning },
        { title: lang.FAILED, value: EJobStates.Failed },
        { title: lang.RUNNING, value: EJobStates.Running },
        {
            title: lang.INFORMATION,
            value: [
                EJobStates.None,
                EJobStates.Idle,
                EJobStates.Starting,
                EJobStates.Stopping,
                EJobStates.Enabling,
                EJobStates.Disabling,
                EJobStates.WaitingTape,
                EJobStates.WaitingRepository,
            ],
        },
    ];
    const jobFileTypesAdvancedFilter = useJobFileTypesAdvancedFilter();
    const advancedFilterOptions: AdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_JOBS_BY}:`,
        cols: [
            {
                groups: [
                    jobFileTypesAdvancedFilter,
                ],
            },
        ],
    };

    return (
        <AdvancedToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='jobName'
                            placeholder={lang.JOB}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='serverName'
                            placeholder={lang.SERVER}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            statuses={statuses}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.separator,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <AdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
