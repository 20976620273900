/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, comparer, computed, observable, reaction } from 'mobx';
import { makeTwoDigit, Months } from '@veeam-vspc/core';
import {
    JobsHeatMapPeriodParam,
    PlatformTypeRepresentation,
    WorkloadTypeRepresentation,
} from '@veeam-vspc/models/web-controllers';
import dayjs, { Dayjs } from 'dayjs';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type {
    DashboardGetJobsHeatMapOverviewStatisticsResponse,
    HeatmapJobType,
} from '@veeam-vspc/models/web-controllers';
import type { JobHeatMapOverviewStatus } from '@veeam-vspc/models/web-controllers/jobHeatMapOverviewStatus';

import { core } from 'core/core-module';
import { DayType, Period } from '../enums';
import { calcPercents, insertPlaceholderDates } from '../helpers';
import { JobsOverviewFilters } from '../interfaces/jobs-overview-filters';
import { InfrastructureType } from '../enums/infrastructure-type';

import type { Day, DayModelResult, JobsOverviewStoreAbstract, JobsOverviewStoreProps, OpenJobsDialog } from '../interfaces';

export class JobsOverviewStore implements JobsOverviewStoreAbstract {
    @observable.shallow days: Day[] = [];
    @observable selectedPeriod: Period = Period.Last30Days;
    @observable workloadType: WorkloadTypeRepresentation = WorkloadTypeRepresentation.Unknown;
    @observable jobTypes: HeatmapJobType[];
    @observable platformType: PlatformTypeRepresentation = PlatformTypeRepresentation.Unknown;
    @observable infrastructureType: InfrastructureType = InfrastructureType.All;

    openJobsDialog: OpenJobsDialog;

    constructor(props: JobsOverviewStoreProps) {
        this.openJobsDialog = props.openJobsDialog;

        this.selectedPeriod = props.selectedPeriod ?? this.selectedPeriod;
        this.workloadType = props.workloadType ?? this.workloadType;
        this.jobTypes = props.jobTypes ?? this.jobTypes;
        this.platformType = props.platformType ?? this.platformType;
        this.infrastructureType = props.infrastructureType ?? this.infrastructureType;

        reaction(
            () => this.filters,
            () => {
                this.getJobsStatistics();
                props.onFilterChange(this.filters);
            },
            {
                equals: comparer.structural,
                fireImmediately: true,
            }
        );
    }

    @computed
    get filters(): JobsOverviewFilters {
        return {
            selectedPeriod: this.selectedPeriod,
            workloadType: this.workloadType,
            jobTypes: this.jobTypes,
            platformType: this.platformType,
            infrastructureType: this.infrastructureType,
        };
    }

    @computed
    get requestParams(): JobsHeatMapPeriodParam {
        const workloadType: WorkloadTypeRepresentation[] = this.workloadType === WorkloadTypeRepresentation.Unknown ? undefined : [this.workloadType];
        const jobType: HeatmapJobType[] = this.jobTypes?.length > 0 ? this.jobTypes : undefined;
        let platformType: PlatformTypeRepresentation[] | undefined;

        if (this.platformType === PlatformTypeRepresentation.Unknown) { // Platform: All
            if (this.infrastructureType === InfrastructureType.Local) {
                platformType = [
                    PlatformTypeRepresentation.VSphere,
                    PlatformTypeRepresentation.Nutanix,
                    PlatformTypeRepresentation.HyperV,
                    PlatformTypeRepresentation.Physical,
                ];
            }

            if (this.infrastructureType === InfrastructureType.Cloud) {
                platformType = [
                    PlatformTypeRepresentation.Aws,
                    PlatformTypeRepresentation.Azure,
                    PlatformTypeRepresentation.Google,
                    PlatformTypeRepresentation.Microsoft365,
                ];
            }
        } else {
            platformType = [this.platformType];
        }

        const hasFilters = Boolean(workloadType || jobType || platformType);

        return {
            beginDate: this.formatDate(this.beginDate),
            endDate: this.formatDate(this.endDate),
            filter: hasFilters ? {
                workloadType,
                jobType,
                platformType,
            } : undefined,
        };
    }

    findById(id: string): Day {
        return this.days.find(day => day.id === id);
    }

    @action.bound
    changePeriod(period: Period): void {
        this.selectedPeriod = period;
    }

    @action.bound
    changeWorkloadType(workloadType: WorkloadTypeRepresentation): void {
        this.workloadType = workloadType;
    }

    @action.bound
    changeJobTypes(jobTypes: HeatmapJobType[]): void {
        this.jobTypes = jobTypes;
    }

    @action.bound
    changePlatformType(platformType: PlatformTypeRepresentation): void {
        this.platformType = platformType;
    }

    @action.bound
    changeInfrastructureType(infrastructureType: InfrastructureType): void {
        this.infrastructureType = infrastructureType;
    }

    @action.bound
    getJobsStatistics(): void {
        core.transportService.request<JobsHeatMapPeriodParam, DashboardGetJobsHeatMapOverviewStatisticsResponse>(
            'dashboard/getjobsheatmapoverviewstatistics',
            this.requestParams
        )
            .then(resp =>
                insertPlaceholderDates(
                    (resp as BaseSuccessRequestResponse<Array<JobHeatMapOverviewStatus>>).data,
                    this
                )
            )
            .then(days => this.days = days.map(this.createDay));
    }

    @computed
    get beginDate(): Dayjs {
        switch (this.selectedPeriod) {
            case RCOP.Enums.MonthPeriod.CurrentMonth: return dayjs()
                .startOf('month');
            case RCOP.Enums.MonthPeriod.LastMonth: return dayjs()
                .startOf('month')
                .subtract(1, 'month');
            case RCOP.Enums.MonthPeriod.Last30Days: return dayjs()
                .startOf('day')
                .subtract(30, 'day');
        }
    }

    @computed
    get endDate(): Dayjs {
        switch (this.selectedPeriod) {
            case Period.CurrentMonth: return dayjs()
                .startOf('day');
            case Period.LastMonth: return dayjs()
                .subtract(1, 'month')
                .endOf('month');
            case Period.Last30Days: return dayjs()
                .startOf('day');
        }
    }

    private createDay(day: DayModelResult): Day {
        const date = dayjs.utc(day.date);
        let dayType: DayType;

        if (day.successJobsCount === 0 && day.warningJobsCount === 0 && day.failJobsCount === 0) {
            dayType = DayType.DayWithoutJobs;
        } else if (day.outOfPeriod) {
            dayType = DayType.OutOfPeriod;
        } else if (
            !day.outOfPeriod
            && (day.successJobsCount === undefined && day.warningJobsCount === undefined && day.failJobsCount === undefined)
        ) {
            dayType = DayType.NoData;
        } else {
            dayType = DayType.DayWithJobs;
        }

        return {
            successJobsCount: day.successJobsCount,
            warningJobsCount: day.warningJobsCount,
            failJobsCount: day.failJobsCount,
            id: day.date,
            date: date.toDate(),
            companies: day.companies,
            affectedCompanies: day.companies.filter(company => company.failJobsCount > 0 || company.warningJobsCount > 0),
            allJobsCount: day.warningJobsCount + day.successJobsCount + day.failJobsCount,
            dateForBackend: `${makeTwoDigit(date.date())}.${makeTwoDigit(date.month() + 1)}.${date.year()}`,
            dayType,
            displayDateForCell: date.date() === 1 ? `${date.date()} ${Months[date.month()]}` : date.date().toString(),
            displayDateForTooltip: `${date.date()} ${Months[date.month()]}, ${date.year()}`,
            jobsPercents: calcPercents([day.successJobsCount || 0, day.warningJobsCount || 0, day.failJobsCount || 0]),
            outOfPeriod: day.outOfPeriod,
        };
    }

    private formatDate(date: Dayjs): string {
        return `${date.year()}-${makeTwoDigit(date.month() + 1)}-${makeTwoDigit(date.date())}T00:00:00.0000000Z`;
    }
}
