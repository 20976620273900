/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo, useState } from 'react';
import { PortalSpinner, useExternalFormApi, useModal } from '@veeam-vspc/components';

import { EmailServerSettingsDialog } from './components';
import { EmailServerSettingsDialogParamsContext } from './interfaces';
import { EmailServerSettingsStore, EmailServerSettingsStoreContext } from './stores';

import type { EmailServerSettingsProps } from './interfaces';
import type { EmailServerSettingsFormData } from './components/EmailServerSettingsDialog/interfaces';

export const EmailServerSettings: React.FC<EmailServerSettingsProps> = (props) => {
    const { originalEmailServerSettings, onSuccessfulServerSettingsPatchCb } = props;
    const [isLoading, setIsLoading] = useState(false);
    const formApi = useExternalFormApi<EmailServerSettingsFormData>();

    const serverSettingsStore = useMemo(() => new EmailServerSettingsStore(originalEmailServerSettings, formApi),
        [originalEmailServerSettings, formApi]);

    const [emailServerSettingsDialog] = useModal({
        initialActiveState: true,
        render: ({ deactivate }) => <EmailServerSettingsDialog deactivateDialog={deactivate} />,
    });
    const params = useMemo(() => ({ setIsLoading, onSuccessfulServerSettingsPatchCb }), []);

    return (
        <EmailServerSettingsStoreContext.Provider value={serverSettingsStore}>
            <EmailServerSettingsDialogParamsContext.Provider value={params}>
                {isLoading && <PortalSpinner />}
                {emailServerSettingsDialog}
            </EmailServerSettingsDialogParamsContext.Provider>
        </EmailServerSettingsStoreContext.Provider>
    );
};
