/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import {
    Dialog,
    DIALOG_SIZE,
    DurationColumn,
    DurationFormatTypes,
    FieldsetBlocks,
    PortalSpinner,
    SizeColumn,
} from '@veeam-vspc/components';

import type { FieldsetBlockProps } from '@veeam-vspc/components';

import { core } from 'core/core-module';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useTransportRequest } from 'views/hooks';
import { JobStatisticsLabelText as LabelText } from '../JobStatisticsLabelText/JobStatisticsLabelText';
import { JobStatisticsValueText as ValueText } from '../JobStatisticsValueText/JobStatisticsValueText';

import type { BackupJobStatisticsModel, JobStatisticsRequestData } from '../../interfaces';

export interface JobStatisticsDialogProps {
    deactivate: () => void;
    jobUid: string;
    title: string;
}

export const JobStatisticsDialog: React.FC<JobStatisticsDialogProps> = ({ deactivate, jobUid, title }) => {
    const lang = useLang();
    const {
        loading,
        data,
    } = useTransportRequest<JobStatisticsRequestData, BackupJobStatisticsModel>('/BackupServerJob/GetJobStatistics', { jobUid });

    const { durationSec, avgDurationSec, processingRate, bottleneck, transferredData } = (data as BackupJobStatisticsModel) || {};
    const { enumMapperService } = core;

    const fieldsetBlocksData: FieldsetBlockProps[] = data
        ? [
            {
                sections: [
                    {
                        title: <LabelText text={lang.SUMMARY} isBold />,
                        items: [
                            {
                                label: <LabelText text={lang.DURATION} />,
                                value: (
                                    <ValueText
                                        text={(
                                            <DurationColumn
                                                value={durationSec}
                                                formatType={DurationFormatTypes.TIMEw8}
                                            />
                                        )}
                                    />
                                ),
                            },
                            {
                                label: <LabelText text={capitalize(lang.AVG_DURATION, true)} />,
                                value: (
                                    <ValueText
                                        text={(
                                            <DurationColumn
                                                value={avgDurationSec}
                                                formatType={DurationFormatTypes.TIMEw8}
                                            />
                                        )}
                                    />
                                ),
                            },
                            {
                                label: <LabelText text={capitalize(lang.PROCESSING_RATE, true)} />,
                                value: (
                                    <ValueText
                                        text={(
                                            <SizeColumn
                                                value={processingRate}
                                                unitName='B/s'
                                                pointFixed={1}
                                            />
                                        )}
                                    />
                                ),
                            },
                            {
                                label: <LabelText text={lang.BOTTLENECK} />,
                                value: (
                                    <ValueText
                                        text={enumMapperService.getEnumDescription('cdpBottleneckRepresentation', bottleneck)}
                                    />
                                ),
                            },
                        ],
                    },
                    {
                        title: <LabelText text={lang.DATA} isBold />,
                        items: [
                            {
                                label: <LabelText text={lang.READ} />,
                                value: <ValueText text={<SizeColumn value={transferredData?.readDataKb} pointFixed={1} />} />,
                            },
                            {
                                label: <LabelText text={lang.TRANSFERRED} />,
                                value: <ValueText text={<SizeColumn value={transferredData?.transferredDataKb} pointFixed={1} />} />,
                            },
                            {
                                label: <LabelText text={lang.TOTAL} />,
                                value: <ValueText text={<SizeColumn value={transferredData?.processedDataKb} pointFixed={1} />} />,
                            },
                        ],
                    },
                ],
            },
        ]
        : [];

    return (
        <Dialog
            header={title}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.xs,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            {loading && <PortalSpinner whiteBg />}
            {!loading && <FieldsetBlocks items={fieldsetBlocksData} />}
        </Dialog>
    );
};
