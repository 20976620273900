/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { JobSessionTaskStatusRepresentation } from '../../../../../../../../enums';

import type { GridExportProps } from 'views/hooks';

export interface ProcessedVmsDialogToolbarProps extends GridExportProps {
    method?: string;
}

export const ProcessedVmsDialogToolbar: React.FC<ProcessedVmsDialogToolbarProps> = (props) => {
    const { method = 'GetProcessedVms', ...restProps } = props;
    const lang = useLang();
    const { Success, Warning, Failed, InProgress, Pending } = JobSessionTaskStatusRepresentation;
    const statuses = [
        { title: lang.SUCCESS, value: Success },
        { title: lang.WARNING, value: Warning },
        { title: lang.ERROR, value: Failed },
        { title: `${lang.RUNNING}...`, value: InProgress },
        { title: lang.PENDING, value: Pending },
    ];

    return (
        <ToolbarWithExport
            {...restProps}
            method={method}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='name'
                            placeholder={lang.OBJECT}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => <StatusToggleFilter statuses={statuses} />,
                },
            ]}
        />
    );
};
