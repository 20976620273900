/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo } from 'react';
import { capitalize } from '@veeam-vspc/core';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { core } from 'core/core-module';
import { PageWizard } from 'components/wizards/PageWizard';
import { useLang } from 'views/providers/LangProvider/hooks';
import { getAccessModeStep } from './components/AccessMode';
import { getNameStep } from './components/NameStep';
import { getCompaniesStep } from './components/Companies';
import { getLocationsStep } from './components/Locations';
import { getParametersStep } from './components/ParametersStep';
import { getScheduleStep } from './components/Schedule';
import { getReportTypeStep } from './components/ReportType';
import {
    DailyKindsEnums,
    DayInMonth,
    DayOfWeek,
    EGroupBy,
    DbGroupingBy,
    Months,
    Platform,
    ProtectedFileRetentionType,
    ReportAggregationModeRepresentation,
    ReportScheduleType,
    ReportType,
    RpoPeriod,
    SlaPeriodRepresentation,
    VmsPlatform,
    VawReportOperationModes,
    ReportRmmProviderModes,
    ReportAccessMode,
} from './enums';
import { getSummaryStep } from './components/Summary';
import { mergeModels, removeEmptyProperties } from 'core/utils';
import { useAppServices, useAppStore } from 'views/providers/AppProvider/hooks';
import { ReportContext, ReportStore } from './stores';
import { localToUtc, toIsoStringWithIgnoreTime } from 'core/utils/date-helpers';

import type { ReportModel } from './interfaces';
import type { RolesAndUsersWizardType } from 'views/pages/RolesAndUsersPage/enums/roles-and-users-wizard-type';

export interface ReportWizardProp {
    isEdit: boolean;
    reportType?: ReportType;
    companyIdOfCurrentUser?: number;
    wizard: RolesAndUsersWizardType;
    onFinish: () => void;
    model?: ReportModel;
}

export const ReportWizard = ({ isEdit, model, onFinish, reportType, companyIdOfCurrentUser }: ReportWizardProp) => {
    const lang = useLang();
    const { notificationService } = useAppServices();
    const { portalUser, portalData } = useAppStore();
    const reportStore = useMemo(() => new ReportStore(lang, portalUser), []);
    const titleByType = {
        [ReportType.ProtectedVmsCdp]: lang.PROTECTED_VMS,
        [ReportType.ProtectedVms]: lang.PROTECTED_VMS,
        [ReportType.ProtectedFiles]: lang.PROTECTED_FILE_SHARES,
        [ReportType.ProtectedComputers]: capitalize(lang.PROTECTED_COMPUTERS),
        [ReportType.ProtectedDatabases]: lang.PROTECTED_DATABASES,
        [ReportType.ProtectedVboObjectsSla]: lang.PROTECTED_MICROSOFT_365_OBJECTS,
    };

    const isCompanyRole = portalUser.isCompanyAdministrator() || portalUser.isLocationAdminOrCompanyOwner();
    const isReseller = portalUser.isServiceProviderGroup();

    const defaultModel: ReportModel = {
        type: reportType,

        name: '',
        description: '',

        parameters: {
            accessMode: isCompanyRole ? ReportAccessMode.Internal : ReportAccessMode.Public,
            aggregationMode: ReportAggregationModeRepresentation.MultiCompany,
            locations: [],
            companies: [],
            rpoInterval: {
                number: 1,
                period: RpoPeriod.Day,
            },
            excludeMask: '',
            groupBy: reportType === ReportType.ProtectedDatabases ? DbGroupingBy.Policy : EGroupBy.Agent,

            retentionTypeFilter: [ProtectedFileRetentionType.Default],
            includeCompaniesDetails: false,
            allCompaniesAndNewlyAdded: false,
            includeResellerCompanies: isReseller,
            emailOptions: '',
        },
        schedule: {
            type: ReportScheduleType.NotScheduled,
            daily: {
                time: '07:00',
                kind: DailyKindsEnums.EveryDay,
                days: [
                    DayOfWeek.Sunday,
                    DayOfWeek.Monday,
                    DayOfWeek.Tuesday,
                    DayOfWeek.Wednesday,
                    DayOfWeek.Thursday,
                    DayOfWeek.Friday,
                    DayOfWeek.Saturday,
                ],
            },
            monthly: {
                time: '07:00',
                week: DayInMonth.First,
                day: DayOfWeek.Sunday,
                dayNumber: 1,
                months: [
                    Months.January,
                    Months.February,
                    Months.March,
                    Months.April,
                    Months.May,
                    Months.June,
                    Months.July,
                    Months.August,
                    Months.September,
                    Months.October,
                    Months.November,
                    Months.December,
                ],
            },
            timeZoneId: portalData.serviceTimeZone.id,
        },
    };

    const currentModel: ReportModel = isEdit ?
        mergeModels(defaultModel, removeEmptyProperties(model))
        :
        defaultModel;

    const title = `${isEdit ? lang.EDIT : lang.NEW} ${titleByType[currentModel.type]} ${lang.REPORT}`;

    const params = currentModel.parameters;

    params.platformFilter = params.platformFilter && params.platformFilter.length > 0
        ? params.platformFilter
        : (currentModel.type === ReportType.ProtectedFiles ? [Platform.All] : [VmsPlatform.All]);
    params.operationModeFilter = params.operationModeFilter || [VawReportOperationModes.All];
    params.managementTypeFilter = params.managementTypeFilter || [ReportRmmProviderModes.Unknown];
    params.retentionTypeFilter = params.retentionTypeFilter || [ProtectedFileRetentionType.Default];
    params.jobTypeFilter = params.jobTypeFilter
        || (currentModel.type === ReportType.ProtectedDatabases ? reportStore.jobTypeSet.map(item => item.id)
            : reportStore.getVmReportJobTypesByPlatform(params.platformFilter[0] as VmsPlatform).map(item => item.id));
    params.virtualPlatformFilter = params.virtualPlatformFilter || reportStore.virtualReportPlatformTypeSet.map(item => item.id);
    params.guestOsFilter = params.guestOsFilter || reportStore.systemTypeRepresentationSet.map(item => item.id);

    if (params.aggregationMode === null || params.aggregationMode === undefined) {
        params.aggregationMode = ReportAggregationModeRepresentation.MultiCompany;
    }

    if (isCompanyRole) {
        params.aggregationMode = ReportAggregationModeRepresentation.SingleCompany;

        if (!isEdit) {
            currentModel.parameters.companies = [companyIdOfCurrentUser];
        }
    }

    params.emailOptions = params.emailOptions
        || reportStore.getDefaultEmail(params.aggregationMode === ReportAggregationModeRepresentation.MultiCompany);

    if (currentModel.parameters.slaSettings?.from) {
        currentModel.parameters.slaSettings.fromAsDate = localToUtc(new Date(currentModel.parameters.slaSettings.from));
    }
    if (currentModel.parameters.slaSettings?.to) {
        currentModel.parameters.slaSettings.toAsDate = localToUtc(new Date(currentModel.parameters.slaSettings.to));
    }

    if (
        currentModel.type === ReportType.ProtectedVboObjectsSla
        || currentModel.type === ReportType.ProtectedVms
        || currentModel.type === ReportType.ProtectedVmsCdp
    ) {
        if (!isEdit) {
            currentModel.parameters.slaSettings = currentModel.parameters.slaSettings || {
                from: null,
                to: null,
                sla: 90,
                timeZoneId: portalData.serviceTimeZone.id,
            };
            currentModel.parameters.slaSettings.isCustomPeriod = false;
            currentModel.parameters.slaSettings.periodType = SlaPeriodRepresentation.Day;

            if (currentModel.type === ReportType.ProtectedVboObjectsSla) {
                currentModel.parameters.vboObjectTypes = [];
            }
        } else {
            if (currentModel.parameters.slaSettings && currentModel.parameters.slaSettings.periodType === SlaPeriodRepresentation.CustomPeriod) {
                currentModel.parameters.slaSettings.isCustomPeriod = true;
                currentModel.parameters.slaSettings.periodType = SlaPeriodRepresentation.Day;
            } else {
                if (currentModel.parameters.slaSettings) {
                    currentModel.parameters.slaSettings.isCustomPeriod = false;
                    currentModel.parameters.slaSettings.timeZoneId = currentModel.parameters.slaSettings.timeZoneId
                    || portalData.serviceTimeZone.id;
                } else {
                    currentModel.parameters.slaSettings = {
                        from: null,
                        to: null,
                        sla: null,
                        isCustomPeriod: false,
                        timeZoneId: portalData.serviceTimeZone.id,
                    };
                }
            }
        }
    }

    reportStore.setCompanies(currentModel.parameters.companies);

    const prepareDataToSave = (data: ReportModel): ReportModel => {
        const result = Object.assign({}, data);

        result.parameters = Object.assign({}, result.parameters);
        result.schedule = Object.assign({}, result.schedule);

        if (result.parameters.slaSettings) {
            result.parameters.slaSettings = Object.assign({}, result.parameters.slaSettings);
        }

        if (isCompanyRole) {
            result.parameters.companies = null;
        } else {
            result.parameters.companies = reportStore.companies;
        }

        if (params.aggregationMode === ReportAggregationModeRepresentation.MultiCompany) {
            result.parameters.accessMode = ReportAccessMode.Internal;
        }

        if (result.parameters.aggregationMode === ReportAggregationModeRepresentation.MultiCompany) {
            result.parameters.locations = null;
        }

        if (result.type === ReportType.ProtectedVms) {
            delete result.parameters.operationModeFilter;
            delete result.parameters.managementTypeFilter;
            delete result.parameters.groupBy;
            delete result.parameters.guestOsFilter;

            delete result.parameters.retentionTypeFilter;

            if (result.parameters.platformFilter[0] !== VmsPlatform.VirtualInfrastructure) {
                result.parameters.virtualPlatformFilter = null;
            }

            if (result.parameters.slaSettings) {
                delete result.parameters.slaSettings;
            }
        }

        if (result.type === ReportType.ProtectedComputers) {
            delete result.parameters.platformFilter;
            delete result.parameters.jobTypeFilter;
            delete result.parameters.virtualPlatformFilter;

            delete result.parameters.retentionTypeFilter;

            if (result.parameters.slaSettings) {
                delete result.parameters.slaSettings;
            }
        }

        if (result.type === ReportType.ProtectedFiles) {
            delete result.parameters.operationModeFilter;
            delete result.parameters.managementTypeFilter;
            delete result.parameters.groupBy;
            delete result.parameters.guestOsFilter;

            delete result.parameters.jobTypeFilter;
            delete result.parameters.virtualPlatformFilter;

            if (result.parameters.platformFilter[0] === Platform.All) {
                result.parameters.platformFilter = [];
            }

            if (result.parameters.slaSettings) {
                delete result.parameters.slaSettings;
            }
        }

        if (result.type === ReportType.ProtectedVmsCdp) {
            delete result.parameters.operationModeFilter;
            delete result.parameters.managementTypeFilter;
            delete result.parameters.groupBy;
            delete result.parameters.guestOsFilter;
            delete result.parameters.rpoInterval;

            delete result.parameters.platformFilter;
            delete result.parameters.jobTypeFilter;
            delete result.parameters.virtualPlatformFilter;

            delete result.parameters.retentionTypeFilter;

            if (result.parameters.slaSettings.isCustomPeriod) {
                result.parameters.slaSettings.periodType = SlaPeriodRepresentation.CustomPeriod;
                result.parameters.slaSettings.from = toIsoStringWithIgnoreTime(result.parameters.slaSettings.fromAsDate);
                result.parameters.slaSettings.to = toIsoStringWithIgnoreTime(result.parameters.slaSettings.toAsDate, true);
            }
            delete result.parameters.slaSettings.isCustomPeriod;
            delete result.parameters.slaSettings.fromAsDate;
            delete result.parameters.slaSettings.toAsDate;
        }

        if (result.type === ReportType.ProtectedVboObjectsSla) {
            delete result.parameters.operationModeFilter;
            delete result.parameters.managementTypeFilter;
            delete result.parameters.groupBy;
            delete result.parameters.guestOsFilter;
            delete result.parameters.rpoInterval;

            delete result.parameters.platformFilter;
            delete result.parameters.jobTypeFilter;
            delete result.parameters.virtualPlatformFilter;

            delete result.parameters.retentionTypeFilter;

            if (result.parameters.slaSettings.isCustomPeriod) {
                result.parameters.slaSettings.periodType = SlaPeriodRepresentation.CustomPeriod;
                result.parameters.slaSettings.from = toIsoStringWithIgnoreTime(result.parameters.slaSettings.fromAsDate);
                result.parameters.slaSettings.to = toIsoStringWithIgnoreTime(result.parameters.slaSettings.toAsDate, true);
            }
            delete result.parameters.slaSettings.isCustomPeriod;
            delete result.parameters.slaSettings.fromAsDate;
            delete result.parameters.slaSettings.toAsDate;
        }

        return result;
    };

    useEffect(() => {
        reportStore.isDefaultEmailOptions = !isEdit;
    }, []);

    const stepsList = [
        getNameStep(lang.NAME),
        getReportTypeStep(lang.REPORT_TYPE),
        getCompaniesStep(lang.COMPANIES, reportStore, isCompanyRole),
        getLocationsStep(lang.LOCATIONS),
        getAccessModeStep(lang.ACCESS_TYPE, isCompanyRole),
        getParametersStep(lang),
        getScheduleStep(lang.SCHEDULE),
        getSummaryStep(lang.SUMMARY),
    ];

    return (
        <ReportContext.Provider value={reportStore}>
            <PageWizard
                title={title}
                isEdit={isEdit}
                deactivate={() => undefined}
                steps={stepsList}
                data={currentModel}
                onFinish={(wizardData) => {
                    const dataForSave = prepareDataToSave(wizardData.data);

                    return core.transportService.request('Report/Save', dataForSave)
                        .then((resp) => {
                            notificationService.multi(title, resp as BaseSuccessRequestResponse)
                                .then(onFinish);
                        });
                }}
            />
        </ReportContext.Provider>
    );
};
