/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { GridProps } from '@veeam-vspc/components';

import { useConfigurator } from 'views/providers/ConfiguratorProvider/hooks';
import { ConfigStates } from 'core/services/configurator/enums';

export const useConfiguredGrid = <RowDataT, TotalRowDataT, FilterT>(
    sectionId: string,
    columns: GridProps<RowDataT, TotalRowDataT, FilterT>['columns']
): GridProps<RowDataT, TotalRowDataT, FilterT>['columns'] => {
    const configurator = useConfigurator();
    const sectionConfig = configurator?.getSectionConfig(sectionId);

    return (sectionId && sectionConfig)
        ? columns.filter(column => sectionConfig.getColumnState(column.field) === ConfigStates.Visible)
        : columns;
};
