/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SPACING_M, useAppStorage } from '@veeam-vspc/components';

import type { ProtectedDataOverviewRequest } from '@veeam-vspc/models/web-controllers';

import { VBR, AWS, CloudConnect, Azure, Microsoft365, GoogleCloud, VeeamAgents } from './components';
import { ProtectedDataSummaryStyled } from './components/ProtectedDataSymmaryStyled';
import { ProtectedDataSummaryToolbar } from './components/ProtectedDataSummaryToolbar';
import { TimePeriod } from './enums';
import { ProtectedDataSummaryStore, ProtectedDataSummaryStoreContext } from './store';

import type { ProtectedDataSummaryFilters } from './interfaces';

export const PROTECTED_DATA_SUMMARY_PAGE = 'ProtectedDataSummaryPage';

const ProtectedDataSummaryColumns = styled.div`
    display: grid;
    gap: ${SPACING_M};
    grid-template-columns: 1fr 1fr;
    overflow: auto;
    padding-right: ${SPACING_M};
    width: 100%;
`;

const ProtectedDataSummaryRows = styled.div`
    display: grid;
    gap: ${SPACING_M};
`;

const DATA_FILTER_KEY = 'protectedDataSummary.data-filter';
const PERIOD_KEY = 'protectedDataSummary.period';

export const ProtectedDataSummary = () => {
    const appStorage = useAppStorage();

    const period: TimePeriod = appStorage.getObjItem(PERIOD_KEY)?.value ?? TimePeriod.Last30Days;
    const dataFilters: ProtectedDataOverviewRequest = appStorage.getObjItem(DATA_FILTER_KEY) as ProtectedDataOverviewRequest ?? {
        rpoDays: 1,
        slaPercent: 90,
    };

    const updateFilter = (values: ProtectedDataSummaryFilters) => {
        const dataFilterValues: ProtectedDataOverviewRequest = {
            slaPercent: values.slaPercent,
            rpoDays: values.rpoDays,
        };

        appStorage.setObjItem(PERIOD_KEY, { value: values.timePeriod });
        appStorage.setObjItem(DATA_FILTER_KEY, dataFilterValues);
    };

    const store = useMemo(() => new ProtectedDataSummaryStore(
        {
            ...dataFilters,
            timePeriod: period,
        },
    ), []);

    return (
        <ProtectedDataSummaryStoreContext.Provider value={store}>
            <ProtectedDataSummaryStyled>
                <ProtectedDataSummaryToolbar onChange={updateFilter} />

                <ProtectedDataSummaryColumns>
                    <ProtectedDataSummaryRows>
                        <VBR />
                        <CloudConnect />
                        <Microsoft365 />
                    </ProtectedDataSummaryRows>

                    <ProtectedDataSummaryRows>
                        <VeeamAgents />
                        <AWS />
                        <Azure />
                        <GoogleCloud />
                    </ProtectedDataSummaryRows>
                </ProtectedDataSummaryColumns>
            </ProtectedDataSummaryStyled>
        </ProtectedDataSummaryStoreContext.Provider>
    );
};
