/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import {
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    STACK_DISTRIBUTION,
    Text,
    TEXT_SIZE,
    TEXT_ALIGN,
    ICON_SIZES,
    Icon,
    STACK_ALIGN, INDENT,
} from '@veeam-vspc/components';

import up from 'images/up.svg';
import down from 'images/down.svg';
import unchanged from 'images/unchanged.svg';
import { OverviewTrend } from '../../../../../enums';

import type { PlateItemProps } from './interfaces';

const trends = {
    [OverviewTrend.Growth]: up,
    [OverviewTrend.Fall]: down,
    [OverviewTrend.NotChanged]: unchanged,
};

export const PlateItem: React.FC<PlateItemProps> = props => (
    <StackView
        direction={STACK_DIRECTION.column}
        gap={STACK_GAP.s}
        align={STACK_ALIGN.center}
        indentHorizontal={INDENT.xxl}
    >
        <Icon src={props.icon} size={ICON_SIZES.xxl} />

        <Text
            size={TEXT_SIZE.l}
            align={TEXT_ALIGN.center}
        >
            {props.name}
        </Text>

        <StackView
            distribution={STACK_DISTRIBUTION.center}
            gap={STACK_GAP.xs}
        >
            <Text size={TEXT_SIZE.xl}>{props.count}</Text>

            <StackView>
                <img src={trends[props.trend]} alt='' />
            </StackView>
        </StackView>
    </StackView>
);

