/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { constants } from '@veeam-vspc/components';

import { DayWrapper } from '../DayBase';

export const DayWithoutJobsWrapper = styled(DayWrapper)`
  &::before {
    content: 'No jobs were run this day';
    font-size: ${constants.FONT_SIZE_S};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`;
