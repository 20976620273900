
/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { StepLayout } from 'components/layouts/StepLayout';
import { SubscriptionPlanSummary } from 'views/components/wizard/SubscriptionPlanSummary';

import type { SubscriptionPlanModel } from 'core/interfaces';

export const getSummaryStep = (title: string): WizardStep<SubscriptionPlanModel> => ({
    title,
    render: data => <SummaryStep {...data} />,
});

export const SummaryStep = ({ data }: WizardStepData<SubscriptionPlanModel>) => {
    const lang = useLang();

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_AND_COPY_MSG}
        >
            <SubscriptionPlanSummary data={data} />
        </StepLayout>
    );
};
