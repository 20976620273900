/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormValidator,
    isValid,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Textarea,
    TextInput,
    immediatelyValidateFlag,
} from '@veeam-vspc/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { RuleInfoModel } from './rule-info-model';


const stepValidate = (data: RuleInfoModel) => {
    const validator = new FormValidator(data);

    validator.validate('model.name').string()
        .required()
        .maxLength(50)
        .check((value) => {
            if (data.rules.map(rule => rule.name).includes(value)) {
                return `${RCOP.Lang.THE_RULE_WITH_THE_SAME_NAME_ALREADY_EXISTS}${immediatelyValidateFlag}`;
            }
        });
    validator.validate('model.description').string()
        .maxLength(500);

    return validator.result();
};

export const RuleInfo = ({ data, onDataChange, validationState }: WizardStepData<RuleInfoModel>) => {
    const lang = useLang();

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={lang.RULE_INFO}
                description={lang.SPECIFY_RULE}
            >
                <StackView gap={STACK_GAP.m} direction={STACK_DIRECTION.column}>
                    <TextInput
                        name='model.name'
                        label={lang.NAME}
                    />

                    <Textarea
                        name='model.description'
                        label={lang.DESCRIPTION}
                        rows={16}
                    />
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRuleInfoStep = (title: string): WizardStep<RuleInfoModel> => ({
    title,
    validate: ({ data }) => isValid(stepValidate, data),
    render: data => <RuleInfo {...data} />,
    id: 'ruleInfo',
});
