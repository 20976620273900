/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import {
    Combobox,
    CONTROL_SIZE,
    FormLayout,
    INDENT,
    SelectButton,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextInput,
} from '@veeam-vspc/components';

import type { ExternalFormApi } from '@veeam-vspc/components';
import type { IdentityProviderClaimMatchRule } from '@veeam-vspc/models/rest';

import addIcon from 'images/actions/add.svg';
import removeIcon from 'images/actions/remove.svg';
import hideHelpIcon from 'images/hide.png';
import showHelpIcon from 'images/view_help.png';
import { MatchRule } from '../../enums/identity-provider-claim-match-rule';
import { Help } from '../Help';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { SSORuleModel } from 'views/pages/RolesAndUsersPage/components/Base/interfaces/sso-rule-model';

export const RulesList = ({ data, formApi }: { data: SSORuleModel; formApi: ExternalFormApi<SSORuleModel>; }) => {
    const lang = useLang();
    const newRule: IdentityProviderClaimMatchRule = {
        claimType: '',
        operator: MatchRule.Contains,
        value: '',
        matchCase: false,
    };
    const addButton = useRef<HTMLButtonElement>();
    const [isHelpVisible, showHelp] = useState(false);

    return (
        <div>
            <StackView
                spaceBottom={INDENT.m}
                gap={STACK_GAP.m}
            >
                <SelectButton
                    onClick={() => {
                        data.model.additionalMappings.push({ ...newRule });

                        formApi.setValue('model.additionalMappings', data.model.additionalMappings);

                        addButton.current.blur();
                    }}
                    iconAfter={null}
                    iconBefore={addIcon}
                    ref={addButton}
                >
                    {lang.ADD}
                </SelectButton>

                <SelectButton
                    onClick={() => {
                        showHelp(isHelpVisible => !isHelpVisible);

                        addButton.current.blur();
                    }}
                    iconAfter={null}
                    iconBefore={isHelpVisible ? hideHelpIcon : showHelpIcon}
                    ref={addButton}
                >
                    {isHelpVisible ? lang.HIDE_HELP : lang.VIEW_HELP}
                </SelectButton>
            </StackView>

            <Help isHelpVisible={isHelpVisible} />

            {data.model.additionalMappings.map((rule, id) => (
                <StackView key={id} direction={STACK_DIRECTION.row} style={{ marginBottom: '16px' }}>
                    <FormLayout inlineLabel>
                        <TextInput
                            name={`model.additionalMappings[${id}].claimType`}
                            label={lang.CLAIM}
                        />

                        <Combobox
                            name={`model.additionalMappings[${id}].operator`}
                            data={[
                                { value: MatchRule.Equals, text: lang.EQUALS },
                                { value: MatchRule.Contains, text: lang.CONTAINS },
                                { value: MatchRule.NotContains, text: lang.DOES_NOT_CONTAIN },
                                { value: MatchRule.MatchRegex, text: lang.REGULAR_EXPRESSION },
                            ]}
                            valueGetter={item => item.value}
                            textGetter={item => item.text}
                            size={CONTROL_SIZE.m}
                            label={lang.EXPRESSION}
                        />

                        <TextInput
                            name={`model.additionalMappings[${id}].value`}
                            label={lang.VALUE}
                        />
                    </FormLayout>

                    <StackView align={STACK_ALIGN.center} style={{ marginLeft: '32px' }}>
                        <SelectButton
                            onClick={() => {
                                data.model.additionalMappings = data.model.additionalMappings.filter((claim, index) => index !== id);

                                formApi.setValue('model.additionalMappings', data.model.additionalMappings);
                            }}
                            iconAfter={null}
                            iconBefore={removeIcon}
                        >
                            {lang.DELETE}
                        </SelectButton>
                    </StackView>
                </StackView>
            ))}
        </div>
    );
};
