/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { CompanyHealthOverviewFilter } from '@veeam-vspc/models/web-controllers/companyHealthOverviewFilter';

import { checkMapLayer, MapLayer } from 'views/pages/OverviewPage/helpers/check-map-layer';
import { extractCountryId } from 'views/pages/OverviewPage/helpers/extract-country-id';

export const createFilter = (mapId: string): CompanyHealthOverviewFilter => {
    const mapLayer = checkMapLayer(mapId);

    if (mapLayer === MapLayer.World) return {};

    if (mapLayer === MapLayer.Region) {
        const countryId = extractCountryId(mapId);

        return {
            admin0: countryId, // admin0 is a highcharts term means country layer
            admin1: mapId, // admin1 is a highcharts term means regions/states layer
        };
    }

    if (mapLayer === MapLayer.Country) {
        return {
            admin0: mapId, // admin0 is a highcharts term means country layer
        };
    }
};
