/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';

import { EmptyPlate } from '../EmptyPlate';
import { useLang } from 'views/providers/LangProvider/hooks';

export const NotApplicablePlate = ({ title }) => {
    const lang = useLang();

    return (
        <EmptyPlate emptyText={lang.NOT_APPLICABLE} title={title} />
    );
};
