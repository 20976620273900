/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { PortalSpinner, SPACE_FILL, STACK_DIRECTION, StackView, useAppStorage } from '@veeam-vspc/components';
import { PlatformTypeRepresentation, WorkloadTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import { WeekTitle } from './components/WeekTitle';
import { Days } from './components/Days';
import { Legend } from './components/Legend';
import { JobsOverviewStore, JobsOverviewStoreContext } from './store';
import { FilterToolbar } from './components/FilterToolbar';
import { InfrastructureType, Period } from './enums';

import type { JobsOverviewProps, JobsOverviewFilters } from './interfaces';

const FILTERS_KEY = 'jobsSummary.jobsOverview.filters';

export const JobsOverview: React.FC<JobsOverviewProps> = observer((props) => {
    const appStorage = useAppStorage();

    const filters: JobsOverviewFilters = appStorage.getObjItem(FILTERS_KEY) as JobsOverviewFilters ?? {
        selectedPeriod: Period.Last30Days,
        workloadType: WorkloadTypeRepresentation.Unknown,
        jobTypes: [],
        platformType: PlatformTypeRepresentation.Unknown,
        infrastructureType: InfrastructureType.All,
    };

    const store = useMemo(() => new JobsOverviewStore({
        ...filters,
        ...props,
        onFilterChange: appStorage.setObjItem.bind(appStorage, FILTERS_KEY),
    }), []);

    if (store.days.length === 0) {
        return <PortalSpinner whiteBg={true} />;
    }

    return (
        <JobsOverviewStoreContext.Provider value={store}>
            <StackView
                direction={STACK_DIRECTION.column}
                spaceFill={SPACE_FILL.all}
            >
                <FilterToolbar />
                <WeekTitle />
                <Days />
                <Legend />
            </StackView>
        </JobsOverviewStoreContext.Provider>
    );
});
