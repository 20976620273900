/**
 * Copyright © Veeam Software Group GmbH.
 */

export const REG_EXP = {
    isoFileType: /\.iso$/,
    xmlFileType: /\.xml$/,
    licenseFileType: /\.lic$/,
    defaultNameLight: /^[^<>=%~]+$/,
    includesNumberOrLetter: /(?=\p{L})|(?=\d)/u,
    uncPath: /^(\\\\[^/\\\][":;|<>+=,?* _]+\\[^/\\\][":;|<>+=,?*]+)((?:\\[^\\/:*?"<>|]+)*\\?)$/, // \\computer\folder
};
