/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Icon,
    STACK_ALIGN,
    ICON_SIZES,
    Text,
    SPACE_FILL,
    STACK_DISTRIBUTION,
} from '@veeam-vspc/components';
import * as React from 'react';

import type { TableCompactWidgetProps } from '../../interfaces';

export const NoData: React.FC<TableCompactWidgetProps> = props => (
    <StackView
        direction={STACK_DIRECTION.column}
        distribution={STACK_DISTRIBUTION.center}
        gap={STACK_GAP.l}
        align={STACK_ALIGN.center}
        spaceFill={SPACE_FILL.all}
    >
        <Icon src={props.noDataConfig.icon} size={props.noDataConfig.iconSize || ICON_SIZES.xxl} />
        <Text>{props.noDataConfig.text}</Text>
    </StackView>
);
