/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors } from '@veeam-vspc/components';

import { DayWrapper, DateTitle } from '../DayBase';

export const OutOfPeriodWrapper = styled(DayWrapper)`
  background-color: ${colors.G200};

  ${DateTitle} {
      color: ${colors.G350};
  }
`;
