/**
 * Copyright © Veeam Software Group GmbH.
 */

import { OAuth2ClientSettings } from '@veeam-vspc/models/rest';

import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const getMailServicesTypes = (lang: LangsServiceBase) => [
    { id: OAuth2ClientSettings.KindEnum.Google, name: lang.GOOGLE },
    { id: OAuth2ClientSettings.KindEnum.Azure, name: lang.MICROSOFT },
];
