/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable } from 'mobx';
import { ManagedAgentMode } from '@veeam-vspc/models/web-controllers';

export class ManagedBackupAgentsStore {
    @observable managedBackupAgentType = ManagedAgentMode.All;

    @action.bound
    setManagedBackupAgentType(v: ManagedAgentMode): void {
        this.managedBackupAgentType = v;
    }
}
