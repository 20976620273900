/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { PortalSpinner } from '@veeam-vspc/components';

import { core } from 'core/core-module';
import { AppViewTypes } from '../../enums';
import { PageProvider } from '../../../PageProvider';
import { BeforeAppDialogs } from '../BeforeAppDialogs/BeforeAppDialogs';
import { ThemeProvider } from '../../../ThemeProvider';
import { RouterProvider } from '../../../RouterProvider';
import { LoginPage } from '../../../../pages/LoginPage';
import { useAppStore } from '../../hooks';

import type { PageProps } from '../../../RouterProvider/interfaces';

export interface AppContentProps {
    viewType: AppViewTypes;
    pageName?: string;
    pageProps?: PageProps;
}

export const AppContent = ({ viewType, pageName, pageProps }: AppContentProps) => {
    const appStore = useAppStore();

    switch (viewType) {
        case (AppViewTypes.Dialogs):
            return (
                <PageProvider withoutConfigurator>
                    <BeforeAppDialogs />
                </PageProvider>
            );
        case (AppViewTypes.Loading):
            return (
                <ThemeProvider>
                    <PortalSpinner whiteBg={true} />
                </ThemeProvider>
            );
        case (AppViewTypes.App):
            // Open app
            // @TODO when full react app
            // return {/* @TODO React Router */};

            RCOP.getApplication().closeLoginAndRunApp(appStore.portalData);
            return null;
        case (AppViewTypes.AppPage):
            // Open definite page
            // Only for period of moving from Ext.js to React
            return <RouterProvider pageName={pageName} pageProps={pageProps} />;
        case (AppViewTypes.Login):
            // Open login
            return (
                <PageProvider withoutConfigurator>
                    <LoginPage />
                </PageProvider>
            );
        default:
            // Open error
            setTimeout(() => { // for async rendering error dialog
                core.tokenManagerService.showHttpError();
            }, 10);
            return null;
    }
};
