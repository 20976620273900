/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { WizardStep } from '@veeam-vspc/components';

import { getCommonMultiFactorStep } from 'views/components/wizard/MultiFactorStep';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { ResellerData } from '../../interfaces';

export const getMultiFactorStep = (lang: LangsServiceBase): WizardStep<ResellerData> => getCommonMultiFactorStep(
    lang,
    lang.CONFIGURE_MFA_ACCESS_FOR_THIS_RESELLER,
    [
        lang.UPON_ENABLING_THIS_OPTION_ALL_USERS_OF_THIS_RESELLER,
        lang.IF_THIS_RESELLER_OR_HIS_MANAGED_COMPANIES,
        lang.TO_RESOLVE_THAT_RESELLERS_AND_HIS_MANAGED,
    ]
);
