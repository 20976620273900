/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { ManagedByConsole, ManagedByBackupServer } from './components';

export interface ComputersProps {
    gridName: string;
    extController: any;
}

export const Computers: React.FC<ComputersProps> = ({ gridName, ...restProps }) => {
    switch (gridName) {
        case ('ManagedByConsole'): return <ManagedByConsole stateId={'BackupJobsPage.Computers'} {...restProps} />;
        case ('ManagedByBackupServer'): return <ManagedByBackupServer stateId={'BackupJobsPage.Computers'} {...restProps} />;
        default: return null;
    }
};
