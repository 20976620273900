/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants, insertDefaultFontSettings } from '@veeam-vspc/components';

import { NARROW_HEAT_MAP_HEIGHT_BREAKPOINT } from '../../../../const';

export const DateTitle = styled.div`
  height: 22px;
  padding-left: ${constants.SPACING_XS};
  font-size: ${constants.FONT_SIZE_M};
  display: flex;
  align-items: center;
  font-weight: ${constants.BASE_FONT_SEMI_BOLD_WEIGHT};
  color: ${colors.G600};

  @media only screen and (max-height: ${NARROW_HEAT_MAP_HEIGHT_BREAKPOINT}) {
    height: 13px;
  }
`;

export const DayWrapper = styled.div.attrs<{ 'data-tid'?: string; }>({ 'data-tid': 'day' })`
  display: flex;
  flex-direction: column;
  border-color: ${colors.G300};
  border-style: solid;
  border-width: ${constants.BASE_BORDER_WIDTH} ${constants.BASE_BORDER_WIDTH} 0 0;
  background-color: ${colors.G000};
  position: relative;

  ${insertDefaultFontSettings}
`;
