/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
} from '@veeam-vspc/components';

import type { ToolbarItems } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { Microsoft365RepositoryDialog } from '../Microsoft365RepositoryDialog';
import addIcon from 'images/actions/add.svg';
import removeIcon from 'images/actions/remove.svg';
import editIcon from 'images/actions/edit.svg';
import { canRemoveVboResource } from '../../../../utils';

import type { VboServerRepository } from '../../types';
import type { ResellerVboResource } from '../../../../../../interfaces';

export const ActionsToolbar = ({ items, vboResources }: { items: VboServerRepository[]; vboResources: ResellerVboResource[]; }) => {
    const lang = useLang();
    const gridApi = useGridApi<VboServerRepository, void, void>();

    const [quotaDialog, quotaDialogActions] = useModal<VboServerRepository>({
        render: ({ deactivate, data }) => (
            <Microsoft365RepositoryDialog
                data={data}
                existingItems={items}
                vboServers={vboResources}
                onConfirm={(newData) => {
                    if (data) {
                        items[items.findIndex(item => item.repositoryUid === data.repositoryUid)] = newData;
                    } else {
                        items.push(newData);
                    }
                    gridApi.reloadGrid();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const toolbarItems: ToolbarItems = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: addIcon,
            text: lang.ADD,
            onClick: () => {
                quotaDialogActions.activate();
            },
            decorators: [
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: editIcon,
            text: lang.EDIT,
            onClick: async() => {
                const selected = gridApi.selected[0];
                quotaDialogActions.activate(selected);
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: removeIcon,
            text: lang.REMOVE,
            onClick: async() => {
                const selected = gridApi.selected[0];

                if (
                    await canRemoveVboResource(
                        selected,
                        lang.CANNOT_REMOVE_THE_MICROSOFT_365_REPOSITORY,
                        lang.THIS_ACTION_WILL_REMOVE_MICROSOFT_365_RESOURCES,
                        lang
                    )
                ) {
                    items.splice(items.findIndex(item => item.repositoryUid === selected.repositoryUid), 1);
                    gridApi.reloadGrid();
                }
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
    ];

    return (
        <>
            <Toolbar items={toolbarItems} />
            {quotaDialog}
        </>
    );
};
