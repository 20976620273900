/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FormValidator } from '@veeam-vspc/components';

import type { FormErrors } from '@veeam-vspc/components';

import { DeploymentScheduleType } from '../../enums';

import type { VbrDeploySettingsWizardModel } from '../../entries';

export const stepValidate = (data: VbrDeploySettingsWizardModel): FormErrors<VbrDeploySettingsWizardModel> => {
    const validator = new FormValidator(data);
    const now = new Date();

    if (data.deploymentSchedule === DeploymentScheduleType.ScheduleUpgrade) {
        validator.validate('scheduleDate')
            .date()
            .required()
            .min(now, RCOP.Lang.THE_SCHEDULE_DATE_CANNOT);
    }

    return validator.result();
};
