/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    CONTROL_SIZE,
    Combobox,
    FormValidator,
    useFormApi,
} from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { ProtectedFileRetentionType, Platform } from '../../../enums';
import { RpoInterval } from './RpoInterval';
import { ReportModel } from '../../../interfaces';

export const fileSharesParametersValidate = (data: ReportModel) => {
    const validator = new FormValidator(data);

    validator
        .validate('parameters.rpoInterval.number')
        .number()
        .required()
        .min(1)
        .max(365);

    return validator.result();
};

interface Props {
    data: ReportModel;
}

export const FileSharesParameters = ({ data }: Props) => {
    const lang = useLang();
    const formApi = useFormApi();

    const allBackupTypes = [{
        id: ProtectedFileRetentionType.Default,
        text: lang.ALL,
    }, {
        id: ProtectedFileRetentionType.Backup,
        text: lang.PRIMARY_BACKUP,
    }, {
        id: ProtectedFileRetentionType.Archive,
        text: lang.ARCHIVE,
    }, {
        id: ProtectedFileRetentionType.Snapshot,
        text: lang.SNAPSHOT,
    }];

    const suitableBackupTypes = allBackupTypes.filter(type =>
        (data.parameters.platformFilter[0] !== Platform.Local || type.id !== ProtectedFileRetentionType.Snapshot)
        && (data.parameters.platformFilter[0] !== Platform.PublicCloud || type.id === ProtectedFileRetentionType.Snapshot));

    return (
        <>
            <RpoInterval label={lang.RPO_PUBLIC_CLOUD_ONLY} />

            <Combobox
                name='parameters.platformFilter[0]'
                label={lang.PLATFORM}
                data={
                    [{
                        id: Platform.All,
                        text: lang.ALL,
                    }, {
                        id: Platform.Local,
                        text: lang.ON_PREMISES,
                    }, {
                        id: Platform.PublicCloud,
                        text: lang.PUBLIC_CLOUD,
                    }]
                }
                valueGetter={item => item.id}
                textGetter={item => item.text}
                size={CONTROL_SIZE.m}
                onChange={(value) => {
                    if (value === Platform.Local
                        && data.parameters.retentionTypeFilter[0] === ProtectedFileRetentionType.Snapshot) {
                        formApi.setValue('parameters.retentionTypeFilter[0]', ProtectedFileRetentionType.Default);
                    }
                    if (value === Platform.PublicCloud
                        && data.parameters.retentionTypeFilter[0] !== ProtectedFileRetentionType.Snapshot) {
                        formApi.setValue('parameters.retentionTypeFilter[0]', ProtectedFileRetentionType.Snapshot);
                    }
                }}
            />

            <Combobox
                name='parameters.retentionTypeFilter[0]'
                label={lang.BACKUP_TYPE}
                data={suitableBackupTypes}
                valueGetter={item => item.id}
                textGetter={item => item.text}
                size={CONTROL_SIZE.m}
            />
        </>
    );
};
