/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { SubscriptionPlan } from './components/wizards/SubscriptionPlan';
import { useAppStore } from '../../providers/AppProvider/hooks';
import { SubscriptionSummary } from './components/wizards/SubscriptionSummary';

import type { SubscriptionPlanProp } from './components/wizards/SubscriptionPlan';

export const SUBSCRIPTION_PLAN_PAGE = 'SubscriptionPlanPage';

export const SubscriptionPlanPage = (props: SubscriptionPlanProp) => {
    const { portalUser } = useAppStore();

    return (
        portalUser.isSiteAdministrator() ?
            <SubscriptionSummary {...props} /> :
            <SubscriptionPlan {...props} />
    );
};
