/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { ManagedVmType } from '@veeam-vspc/models/web-controllers';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ManagedVmsContext } from './contexts/ManagedVmsContext';
import { ManagedVmsService } from './services/managed-vms.service';
import { ManagedVmsStore } from './stores/ManagedVmsStore';
import { StatefulCombobox } from '../../../StatefulCombobox';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useManagedVmsStore } from './hooks/use-managed-vms-store';

import type { ComboboxItem } from '../../../../interfaces';

export const ChartLayout = observer(() => {
    const lang = useLang();
    const managedVmStore = useManagedVmsStore();

    const params = {
        colors: [palette.B400],
        yTitle: lang.VMS,
    };

    const title = {
        text: capitalize(lang.MANAGED_VMS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={managedVmStore.setManagedVmType}
                stateId={'trends.backup-services.managed-vms.managed-vm-type'}
                textGetter={(p: ComboboxItem) => p.text}
                value={managedVmStore.managedVmType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: ManagedVmType.All, text: lang.ALL },
                    { id: ManagedVmType.VSphere, text: lang.VSPHERE },
                    { id: ManagedVmType.HyperV, text: lang.HYPER_V },
                    { id: ManagedVmType.Ahv, text: lang.AHV },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={managedVmStore.managedVmType}
            params={params}
            service={ManagedVmsService}
            title={title}
        />
    );
});

export const ManagedVms: React.FC = () => {
    const managedVmsStore = useMemo(() => new ManagedVmsStore(), []);

    return (
        <ManagedVmsContext.Provider value={managedVmsStore}>
            <ChartLayout />
        </ManagedVmsContext.Provider>
    );
};
