/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect, useState } from 'react';

import type {
    BaseFailedRequestResponse,
    BaseRequestResponse,
    BaseSuccessRequestResponse,
    RequestAdditionalParams,
} from '@veeam-vspc/core';

import { useAppServices } from 'views/providers/AppProvider/hooks';

import type { UseRequestsData } from './interfaces';

// Cache for problem with multiple request when use that hook in dialogs, because ReactTransition calls a few re-renders when do animations on show
const requestCache: Record<string, Promise<any>> = {};

export const useTransportRequest = <T, R extends {}>(
    url: string,
    requestParams?: T,
    customOptions?: RequestInit,
    params?: RequestAdditionalParams & { responsePropName?: string; },
): UseRequestsData<R> => {
    const { transportService } = useAppServices();
    const [isForcedRequest, setIsForcedRequest] = useState(false);
    const [requestData, setRequestData] = useState<Omit<UseRequestsData<R>, 'forceRequest'>>({
        loading: true,
        isError: false,
        data: null,
    });
    const { loading, data } = requestData;

    useEffect(() => {
        if ((loading && !data) || isForcedRequest) {
            if (isForcedRequest) {
                setIsForcedRequest(false);
                setRequestData({
                    loading: true,
                    isError: false,
                    data,
                });
            }

            let requestPromise;
            const { responsePropName = 'data', ...restParams } = params ?? {};
            const requestParamsStr = JSON.stringify(requestParams);
            const requestId = `${url}$$$${requestParamsStr}`;
            const clearRequestFromCache = () => {
                delete requestCache[requestId];
            };

            if (requestCache[requestId]) {
                requestPromise = requestCache[requestId];
            } else {
                requestPromise = transportService.request<T, BaseRequestResponse<R>>(url, requestParams, customOptions, restParams);
                requestCache[requestId] = requestPromise;
            }


            requestPromise
                .then((resp: BaseSuccessRequestResponse<R>) => {
                    clearRequestFromCache();
                    setRequestData({
                        data: resp[responsePropName] as R,
                        loading: false,
                        isError: false,
                    });
                })
                .catch((errResp: BaseFailedRequestResponse) => {
                    clearRequestFromCache();

                    if (errResp instanceof DOMException && errResp.code === DOMException.ABORT_ERR) {
                        return setRequestData({
                            data: errResp,
                            loading: true,
                            isError: false,
                        });
                    }

                    setRequestData({
                        data: errResp,
                        loading: false,
                        isError: true,
                    });
                });
        }
    }, [transportService, loading, data, isForcedRequest, url, requestParams, customOptions, params]);

    return {
        ...requestData,
        forceRequest: () => setIsForcedRequest(true),
    };
};
