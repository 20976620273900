
/**
 * Copyright © Veeam Software Group GmbH.
 */

import { PortalUserRoles } from './enums';

import type { LoginUser } from '../../services/portal/interfaces';
import type { PortalUserCreationSources, PortalUserStatuses } from './enums';
import type { MfaPolicyStatuses } from '../../services/portal/enums';

export class PortalUser {

    readonly id: number;
    readonly instanceUid: string;
    readonly userRole: PortalUserRoles;
    readonly creationSource: PortalUserCreationSources;

    login: string;
    email: string | null;
    firstName: string;
    lastName: string;
    userStatus: PortalUserStatuses;
    mfaPolicyStatus: MfaPolicyStatuses;
    requireChangePassword: boolean;
    address: string | null;
    isVcdCompany: boolean;
    companyId: number;
    organizationUid: string;
    companyName: string | null;
    companyStatus: number; // TODO add companyStatus enum when add company service
    havePasswordLogin: boolean;

    constructor(loginUser: LoginUser) {
        const {
            id,
            instanceUid,
            userRole,
            creationSource,
            login,
            email,
            firstName,
            lastName,
            userStatus,
            mfaPolicyStatus,
            requireChangePassword,
            address,
            isVcdCompany,
            companyId,
            organizationUid,
            companyName,
            companyStatus,
            companyUid,
            havePasswordLogin,
        } = loginUser;

        this.id = id;
        this.instanceUid = instanceUid;
        this.userRole = userRole;
        this.creationSource = creationSource;
        this.login = login;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.userStatus = userStatus;
        this.mfaPolicyStatus = mfaPolicyStatus;
        this.requireChangePassword = requireChangePassword;
        this.address = address;
        this.isVcdCompany = isVcdCompany;
        this.companyId = companyId;
        this.organizationUid = organizationUid;
        this.companyName = companyName;
        this.companyStatus = companyStatus;
        this.organizationUid = companyUid;
        this.havePasswordLogin = havePasswordLogin;
    }

    isPortalAdministrator(): boolean {
        return this.userRole === PortalUserRoles.PortalAdministrator;
    }

    isLocationAdministrator(): boolean {
        return this.userRole === PortalUserRoles.LocationAdministrator;
    }

    isLocationUser(): boolean {
        return this.userRole === PortalUserRoles.LocationUser;
    }

    isCompanyOwner(): boolean {
        return this.userRole === PortalUserRoles.CompanyOwner;
    }

    isCompanyInvoiceAuditor(): boolean {
        return this.userRole === PortalUserRoles.CompanyInvoiceAuditor;
    }

    isSubtenant(): boolean {
        return this.userRole === PortalUserRoles.Subtenant;
    }

    isPortalOperator(): boolean {
        return this.userRole === PortalUserRoles.PortalOperator;
    }

    isServiceProviderGlobalAdministrator(): boolean {
        return this.userRole === PortalUserRoles.ServiceProviderGlobalAdministrator;
    }

    isServiceProviderOperator(): boolean {
        return this.userRole === PortalUserRoles.ServiceProviderOperator;
    }

    isServiceProviderUser(): boolean {
        return this.userRole === PortalUserRoles.ServiceProviderUser;
    }

    isServiceProviderInvoiceAuditor(): boolean {
        return this.userRole === PortalUserRoles.ServiceProviderInvoiceAuditor;
    }

    isServiceProviderAdministrator(): boolean {
        return this.userRole === PortalUserRoles.ServiceProviderAdministrator;
    }

    isSiteAdministrator(): boolean {
        return this.userRole === PortalUserRoles.SiteAdministrator;
    }

    isPortalReadonlyOperator(): boolean {
        return this.userRole === PortalUserRoles.PortalReadonlyOperator;
    }

    isCompanyAdministrator(): boolean {
        return this.userRole === PortalUserRoles.CompanyAdministrator;
    }

    // composite role checks
    isLocationAdminOrCompanyOwner(): boolean {
        return this.isLocationAdministrator() || this.isCompanyOwner() || this.isCompanyAdministrator();
    }

    isPortalOrSiteAdmin(): boolean {
        return this.isPortalAdministrator() || this.isSiteAdministrator() || this.isPortalReadonlyOperator();
    }

    // service provider === reseller (historical reasons)
    isServiceProviderGlobalOrUsualAdmin(): boolean {
        return this.isServiceProviderGlobalAdministrator() || this.isServiceProviderAdministrator();
    }

    isServiceProviderGroup(): boolean {
        return this.isServiceProviderGlobalAdministrator()
            || this.isServiceProviderOperator()
            || this.isServiceProviderUser()
            || this.isServiceProviderInvoiceAuditor()
            || this.isServiceProviderAdministrator();
    }

    isCompanyGroup(): boolean {
        return (
            this.isLocationUser() ||
            this.isCompanyOwner() ||
            this.isCompanyAdministrator() ||
            this.isSubtenant()
        );
    }

    isReadOnlyRole(): boolean {
        return (
            this.isLocationUser() ||
            this.isServiceProviderUser() ||
            this.isPortalReadonlyOperator()
        );
    }

    isAuditor(): boolean {
        return this.isCompanyInvoiceAuditor() || this.isServiceProviderInvoiceAuditor();
    }

    isPassedRoleContained(roles: PortalUserRoles[], role: PortalUserRoles = this.userRole): boolean {
        return Array.isArray(roles) && roles.length > 0
            ? roles.includes(role)
            : false;
    }

    isAdminPortal() {
        return (
            this.isPortalAdministrator() ||
            this.isSiteAdministrator() ||
            this.isPortalOperator() ||
            this.isPortalReadonlyOperator()
        );
    }

    isResellerPortal() {
        return (
            this.isServiceProviderGlobalAdministrator() ||
            this.isServiceProviderAdministrator() ||
            this.isServiceProviderOperator() ||
            this.isServiceProviderUser() ||
            this.isServiceProviderInvoiceAuditor()
        );
    }

    isClientPortal() {
        return (
            this.isCompanyOwner() ||
            this.isCompanyAdministrator() ||
            this.isLocationAdministrator() ||
            this.isLocationUser() ||
            this.isSubtenant() ||
            this.isCompanyInvoiceAuditor()
        );
    }

}
