/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';

import { PageWizard } from 'components/wizards/PageWizard';
import { useLang } from 'views/providers/LangProvider/hooks';
import { getSummaryStep } from '../SubscriptionPlan/components/Summary';
import { SubscriptionPlanWizardStore } from '../stores';
import { SubscriptionPlanStore, SubscriptionPlanContext } from '../SubscriptionPlan/stores';

import type { SubscriptionPlanModel } from 'core/interfaces';

export interface SummaryComponentProp {
    onClose?: () => void;
    model?: SubscriptionPlanModel;
}

export const SubscriptionSummary = ({ model, onClose }: SummaryComponentProp) => {
    const lang = useLang();
    const wizardStore = useMemo(() => new SubscriptionPlanWizardStore(model), [model]);
    const contextStore = useMemo(() => new SubscriptionPlanStore(), []);

    return (
        <SubscriptionPlanContext.Provider value={contextStore}>
            <PageWizard
                isEdit
                isReadOnlyWizard
                tabsWidth={200}
                onClose={onClose}
                data={wizardStore.getEditModel()}
                deactivate={() => undefined}
                steps={[getSummaryStep(lang.SUMMARY)]}
                title={`${lang.EDIT} ${lang.SUBSCRIPTION_PLAN}`}
            />
        </SubscriptionPlanContext.Provider>
    );
};
