/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ColumnTexts, normalizeOldDateFormat, WizardSummary } from '@veeam-vspc/components';
import { Vb365CopyJobSchedulePolicy } from '@veeam-vspc/models/rest';

import type { Vb365CopyJob } from '@veeam-vspc/models/rest';
import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { StepLayout } from 'components/layouts/StepLayout';
import { getPeriodicallyReadableValues } from '../../../../helpers';
import { useCopyJobWizardStore } from '../../stores';
import { formatStringTime } from 'core/utils/date-helpers';
import { useAppStore } from 'views/providers/AppProvider/hooks';

import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const getSummaryStep = (lang: LangsServiceBase): WizardStep<Vb365CopyJob> => ({
    title: lang.SUMMARY,
    render: data => <SummaryStep {...data} />,
});

const SummaryStep = ({ data }: WizardStepData<Vb365CopyJob>) => {
    const lang = useLang();
    const wizardStore = useCopyJobWizardStore();
    const { formats } = useAppStore();
    const shortTimeFormat = normalizeOldDateFormat(formats.netShortTime);

    const selectedProxy = () => wizardStore.backupProxies.find(x => x.instanceUid === wizardStore.selectedBackupRepository(data).proxyUid)?.hostName;

    const policyScheduling = () => {
        const { schedulePolicy } = data;

        if (!schedulePolicy) {
            return ColumnTexts.DashSymbol;
        }

        if (schedulePolicy.schedulePolicyType === Vb365CopyJobSchedulePolicy.SchedulePolicyTypeEnum.Immediate) {
            return lang.IMMEDIATELY;
        }

        if (
            schedulePolicy.schedulePolicyType === Vb365CopyJobSchedulePolicy.SchedulePolicyTypeEnum.Periodically
            && schedulePolicy.periodicallyEvery
        ) {
            return `${lang.EVERY} ${getPeriodicallyReadableValues(lang)[schedulePolicy.periodicallyEvery]}`;
        }

        if (
            schedulePolicy.schedulePolicyType === Vb365CopyJobSchedulePolicy.SchedulePolicyTypeEnum.DailyAtTime
            && schedulePolicy.dailyType
            && schedulePolicy.dailyTime
        ) {

            return `${schedulePolicy.dailyType} ${lang.AT.toLowerCase()} ${formatStringTime(schedulePolicy.dailyTime, shortTimeFormat, true)}`;
        }

        return ColumnTexts.DashSymbol;
    };

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_AND_COPY_MSG}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: lang.GENERAL,
                        fields: [
                            {
                                label: `${lang.SOURCE_BACKUP_JOB}:`,
                                value: wizardStore.sourceJob.name,
                            },
                        ],
                    },
                    {
                        title: lang.BACKUP_REPOSITORY,
                        fields: [
                            {
                                label: `${lang.BACKUP_PROXY}:`,
                                value: selectedProxy() || ColumnTexts.DashSymbol,
                            },
                            {
                                label: `${lang.BACKUP_REPOSITORY}:`,
                                value: wizardStore.selectedBackupRepository(data).name,
                            },
                        ],
                    },
                    {
                        title: lang.SCHEDULE,
                        fields: [
                            {
                                label: `${lang.JOB_SCHEDULING}:`,
                                value: policyScheduling(),
                            },
                            {
                                label: `${lang.BACKUP_WINDOW}:`,
                                value: data.schedulePolicy && data.schedulePolicy.backupWindowEnabled
                                    ? lang.TERMINATE_JOB_IF_IT_EXCEEDS_ALLOWED_BACKUP_WINDOW
                                    : ColumnTexts.DashSymbol,
                            },
                        ],
                    },
                ]}
            />
        </StepLayout>
    );
};

