/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { colors, constants, Icon, ParsedText, STACK_GAP, StackView, TEXT_WEIGHT } from '@veeam-vspc/components';
import styled from 'styled-components';
import { plural } from '@veeam-vspc/core';

import noLocationSrc from 'images/statuses/no-location.svg';
import { useLang } from 'views/providers/LangProvider/hooks';
import { UnspecifiedCompaniesStatus } from './components/UnspecifiedCompaniesStatus/UnspecifiedCompaniesStatus';

import type { UnspecifiedCompanies } from './interfaces';

const WidgetWrapper = styled.div`
    display: flex;
    padding: ${constants.SPACING_S} ${constants.SPACING_M};
    background: ${colors.Y50};
`;

interface UnspecifiedCompaniesWidgetProps {
    unspecifiedCompanies: UnspecifiedCompanies;
    style: React.CSSProperties;
}

export const UnspecifiedCompaniesWidget: React.FC<UnspecifiedCompaniesWidgetProps> = ({ unspecifiedCompanies, style }) => {
    const lang = useLang();
    const unspecifiedCompaniesText = plural(unspecifiedCompanies.total, lang.COMPANY.toLowerCase(), lang.COMPANIES.toLowerCase());

    return (
        <WidgetWrapper style={style}>
            <StackView gap={STACK_GAP.m}>
                <Icon src={noLocationSrc} />

                <ParsedText
                    textOrConfig={[
                        {
                            text: unspecifiedCompaniesText,
                            props: {
                                weight: TEXT_WEIGHT.bold,
                            },
                        },
                        {
                            text: ` ${lang.WITH_NO_REGION_SET}:`,
                        },
                    ]}
                />

                <StackView gap={STACK_GAP.m}>
                    {unspecifiedCompanies.statuses.map((item, index) => <UnspecifiedCompaniesStatus {...item} key={index} />)}
                </StackView>
            </StackView>
        </WidgetWrapper>
    );
};
