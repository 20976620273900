/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { PageWizard as PageWizardFromComponents, ThemeProvider } from '@veeam-vspc/components';

import type { PageWizardProps } from '@veeam-vspc/components';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';

export const PageWizard = <T extends {}>(
    props: Omit<PageWizardProps<T>, 'closeMessageText' | 'closeMessageCancelButtonText' | 'closeMessageOkButtonText'>
) => {
    const { portalService } = useAppServices();
    const lang = useLang();

    return (
        <ThemeProvider theme={portalService.getProductTheme()}>
            <PageWizardFromComponents
                hasBack
                closeMessageText={lang.ALL_CHANGES_WILL_BE_LOST}
                closeMessageCancelButtonText={lang.CANCEL}
                closeMessageOkButtonText={lang.OK}
                {...props}
                onClose={() => {
                    if (props.onClose) {
                        props.onClose();
                    } else {
                        // For use in ExtJS only
                        document.dispatchEvent(new CustomEvent('RCOP/HIDE_PAGE_WIZARD'));
                    }
                }}
            />
        </ThemeProvider>
    );
};
