/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import {
    Form,
    FormValidator,
    TextInput,
    Textarea,
    CONTROL_SIZE,
    FormLayout,
    isValid,
    useExternalFormApi,
} from '@veeam-vspc/components';
import { capitalize, formatStr } from '@veeam-vspc/core';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { WizardStep, WizardStepData, ExternalFormApi } from '@veeam-vspc/components';

import { core } from 'core/core-module';
import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { extjsRegExpValidate } from 'core/utils/validators';
import { REG_EXP } from 'core/const';
import { VacInternalItemType } from 'core/enums/vac-internal-item-type';

import type { SubscriptionPlanModel } from 'core/interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const stepValidate = (data: SubscriptionPlanModel, lang = {} as LangsServiceBase) => {
    const validator = new FormValidator(data);

    const nameValidator = (value: string): string => {
        const errorText = lang?.CANNOT_USE_SPECIAL_CHARACTERS ?? '-';

        if (value === '.') return errorText;

        return (
            !extjsRegExpValidate(value, REG_EXP.defaultNameLight, errorText) ?
                extjsRegExpValidate(value, REG_EXP.includesNumberOrLetter, errorText) :
                errorText
        );
    };

    validator
        .validate('name')
        .string()
        .required()
        .check(nameValidator)
        .maxLength(128);
    validator
        .validate('description')
        .string()
        .maxLength(2048);

    return validator.result();
};

export const getPlanInfoStep = (title: string, lang: any): WizardStep<SubscriptionPlanModel> => ({
    title,
    validate: ({ data, stepData, isEdit }) => {
        const validationResult = isValid(stepValidate, data);
        const nameWasChanged = stepData.nameOnStart !== undefined && data.name !== stepData.nameOnStart;
        if (validationResult && stepData.formApi && nameWasChanged) {
            return new Promise((resolve) => {
                const checkData = {
                    id: isEdit ? data.id : null,
                    name: data.name,
                    type: VacInternalItemType.SubscriptionPlan,
                };
                core.transportService.request<{}, boolean>('Common/CheckItemExist', checkData)
                    .then((result: BaseSuccessRequestResponse<boolean>) => {
                        const { data } = result;
                        if (data) {
                            (stepData.formApi as ExternalFormApi<SubscriptionPlanModel>).setExternalErrors({
                                name: formatStr(lang.WITH_THE_SAME_NAME_ALREADY_EXISTS, lang.PLAN),
                            });
                        }
                        resolve(!data);
                    });
            });
        }

        return validationResult;
    },
    render: data => <PlanInfoStep {...data} />,
});

const PlanInfoStep = ({ data, validationState, onDataChange, stepData }: WizardStepData<SubscriptionPlanModel>) => {
    const lang = useLang();
    const formApi = useExternalFormApi();

    useEffect(() => {
        stepData.formApi = formApi;
        stepData.nameOnStart = data.name;

        return () => {
            delete stepData.formApi;
        };
    }, []);

    return (
        <StepLayout
            title={capitalize(lang.PLAN_INFO)}
            description={lang.TYPE_IN_A_NAME_AND_DESCRIPTION}
        >
            <Form
                value={data}
                validate={formData => stepValidate(formData as SubscriptionPlanModel, lang)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <TextInput
                        name='name'
                        label={lang.NAME}
                        size={CONTROL_SIZE.l}
                    />

                    <Textarea
                        name='description'
                        label={lang.DESCRIPTION}
                        size={CONTROL_SIZE.l}
                        rows={7}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
};
