/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridSortDirections,
    TextColumn,
} from '@veeam-vspc/components';

import type { CellRendererProps } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { MAX_INTEGER_32 } from 'core/const';

import type { PublicCloudRdsBackupModel, CloudDatabasesDialogRequestParams, CloudDatabasesDialogExtraParams } from '../../interfaces';

export interface CloudDatabasesDialogProps {
    stateId: string;
    name: string;
    extraParams: CloudDatabasesDialogExtraParams;
    deactivate: () => void;
}

export const CloudDatabasesDialog: React.FC<CloudDatabasesDialogProps> = ({ stateId, name, extraParams, deactivate }) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const columns = [
        {
            field: 'created',
            title: lang.DATE,
            cell: ({ cellData }: CellRendererProps<PublicCloudRdsBackupModel>) => <GridDateColumn value={cellData} />,
        },
        {
            field: 'type',
            title: lang.TYPE,
            cell: ({ cellData }: CellRendererProps<PublicCloudRdsBackupModel>) => <TextColumn>{cellData}</TextColumn>,
        },
        {
            field: 'region',
            title: lang.RESTORE_POINT_REGION,
            cell: ({ cellData }: CellRendererProps<PublicCloudRdsBackupModel>) => <TextColumn>{cellData}</TextColumn>,
        },
    ];

    return (
        <Dialog
            header={name}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <AdvancedGrid
                stateId={stateId}
                defaultColumnWidth={100}
                initialState={{
                    sort: {
                        direction: GridSortDirections.Asc,
                        key: columns?.[0]?.field,
                    },
                }}
                columns={columns}
                data={(requestParams: CloudDatabasesDialogRequestParams<{}>) => transportService
                    .request<typeof requestParams, PublicCloudRdsBackupModel[]>(
                        '/PublicCloudRds/GetProtectedRdsBackupRestorePoints',
                        {
                            ...requestParams,
                            ...extraParams,
                        },
                    )}
                toggleable={false}
                selection={{
                    field: 'instanceUid',
                    checkbox: false,
                    multiple: false,
                }}
                paginationLimit={MAX_INTEGER_32}
                toolbars={[
                    () => (
                        <ToolbarWithExport
                            idProperty={'uid'}
                            method={'GetPublicCloudRdsRestorePoints'}
                            extraParams={extraParams}
                        />
                    ),
                ]}
            />
        </Dialog>
    );
};
