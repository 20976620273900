/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';
import { CompanyTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ManagedCompaniesService } from './services/managed-companies.service';
import { useGlobalFiltersChangeHandler } from 'views/providers/GlobalFiltersProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { NotApplicablePlate } from '../../../HighchartsWrapper/components/NotApplicablePlate';

export const ManagedCompanies: React.FC = () => {
    const lang = useLang();
    const [isNotApplicable, setIsNotApplicable] = useState(false);

    const applicableToTypes = [
        CompanyTypeRepresentation.Provider,
        CompanyTypeRepresentation.Reseller,
        CompanyTypeRepresentation.ResellerContainer,
    ];

    // todo: think different when we will switch to React in Global Filters - needs to add full model information to Global Filter Service
    const getGlobalCompanyFilterString = (v) => {
        const type = Ext.getCmp('ResellerFilter')
            .store.getData()
            .items.find(f => f.id === parseInt(v, 10))?.getData().companyType;

        return RCOP.utils.Services.enumMapperService.getEnumStringValue('CompanyTypeRepresentation', type);
    };

    const params = {
        colors: [palette.B400],
        yTitle: lang.COMPANIES,
    };

    useEffect(() => {
        const companyFilter = Ext.getCmp('ResellerFilter').getValue();
        const type = getGlobalCompanyFilterString(companyFilter);

        setIsNotApplicable(type && !applicableToTypes.includes(type));
    }, []);

    useGlobalFiltersChangeHandler(({ companyFilter }) => {
        const type = getGlobalCompanyFilterString(companyFilter);

        setIsNotApplicable(type && !applicableToTypes.includes(type));
    });

    return (
        <>
            {isNotApplicable && <NotApplicablePlate title={{ text: capitalize(lang.MANAGED_COMPANIES) }} />}

            {!isNotApplicable && (
                <BaseTrendChart
                    params={params}
                    service={ManagedCompaniesService}
                    title={{ text: capitalize(lang.MANAGED_COMPANIES) }}
                />
            )}
        </>
    );
};
