/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FormValidator } from '@veeam-vspc/components';

import { REG_EXP } from 'core/const';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { VbrDeploySettingsWizardModel } from '../../entries';

export const formValidate = (lang: LangsServiceBase) => (data: VbrDeploySettingsWizardModel) => {
    const validator = new FormValidator(data);

    const fileTypeValidator = (inputFile: FileList): string => {
        const fileName = inputFile[0].name;
        const regExp = REG_EXP.xmlFileType;

        return regExp.test(fileName) ? '' : lang.THE_SPECIFIED_FILE_IS_INVALID;
    };

    validator.validate('installationParametersFile')
        .object()
        .required()
        .check(fileTypeValidator);

    return validator.result();
};
