/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';
import {
    Icon,
    LinkButton,
    SPACING_XL,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TextColumn,
} from '@veeam-vspc/components';
import { ERestoreStatus } from '@veeam-vspc/models/web-controllers';

import { getStatusIconSrc } from 'core/utils';
import { StatusTypes } from 'core/utils/status-helpers/enums';
import { useLang } from 'views/providers/LangProvider/hooks';
import { transformTimeToAgoString } from 'views/components/columns/TimeAgoColumn';
import { ComputersService } from '../../services';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { PortalUserRoles } from 'core/entries/portal-user/enums';

import type { SystemTypeRepresentations } from 'core/enums';
import type { ProtectedComputerFlrStatusInfo } from '../../interfaces';

const StyledStackView = styled(StackView)`
    min-height: ${SPACING_XL};
`;

export interface FlrStatusColumnProps {
    agentId: string;
    name: string;
    systemType: SystemTypeRepresentations;
    flrStatusInfo: ProtectedComputerFlrStatusInfo;
    tabUrl?: string;
}

export const FlrStatusColumn: React.FC<FlrStatusColumnProps> = (props) => {
    const {
        agentId,
        flrStatusInfo,
        tabUrl = '#/restore-items-audit',
    } = props;
    const { status, lastRestore, restoredCount, totalCount } = flrStatusInfo || {};
    const lang = useLang();
    let isLink = false;
    let text: string | null = null;
    let iconSrc = '';
    const { portalUser, version } = useAppStore();
    const disallowLinkFor = [
        PortalUserRoles.PortalReadonlyOperator,
        PortalUserRoles.ServiceProviderUser,
        PortalUserRoles.LocationUser,
    ];

    if (status === ERestoreStatus.Restoring) {
        const count = `${restoredCount} ${lang.OF} ${totalCount} ${lang.ITEMS.toLowerCase()}`;

        text = `${lang.RESTORING} (${count})`;
        iconSrc = getStatusIconSrc(StatusTypes.Pending);
    } else if (status === ERestoreStatus.Error) {
        text = `${lang.FAILED} (${transformTimeToAgoString(lastRestore, lang)})`;
        iconSrc = getStatusIconSrc(StatusTypes.Error);
        isLink = true;
    } else if (status === ERestoreStatus.Success) {
        text = `${lang.SUCCESS} (${transformTimeToAgoString(lastRestore, lang)})`;
        iconSrc = getStatusIconSrc(StatusTypes.Success);
    } else if (status === ERestoreStatus.Warning) {
        text = `${lang.WARNING} (${transformTimeToAgoString(lastRestore, lang)})`;
        iconSrc = getStatusIconSrc(StatusTypes.Warning);
        isLink = true;
    }

    if (isLink && !disallowLinkFor.includes(portalUser.userRole)) {
        return (
            <TextColumn>
                <LinkButton
                    onClick={() => ComputersService.openFlr({
                        agentId,
                        tabUrl,
                        userId: portalUser.instanceUid,
                        vspcVersion: version,
                    })}
                    iconBefore={iconSrc}
                >
                    {text}
                </LinkButton>
            </TextColumn>
        );
    }

    return (
        <TextColumn>
            {
                text && (
                    <StyledStackView
                        align={STACK_ALIGN.center}
                        direction={STACK_DIRECTION.row}
                        gap={STACK_GAP.s}
                    >
                        {iconSrc && <Icon src={iconSrc} />}
                        <Text truncate style={{ color: 'inherit' }}>{text}</Text>
                    </StyledStackView>
                )
            }
        </TextColumn>
    );
};
