/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE, ToolbarKit } from '@veeam-vspc/components';

import { PeriodFilter } from './components/PeriodFilter';
import { JobTypesFilter } from './components/JobTypesFilter';
import { WorkloadTypeFilter } from './components/WorkloadTypeFilter';
import { PlatformFilter } from './components/PlatformFilter';
import { InfrastructureFilter } from './components/InfrastructureFilter';

export const FilterToolbar = () => (
    <ToolbarKit
        style={{
            height: 70,
        }}
        view={TOOLBAR_VIEW_TYPE.spotted}
        items={[
            {
                type: TOOLBAR_ITEM_TYPE.customControl,
                render: () => <PeriodFilter />,
            },
            {
                type: TOOLBAR_ITEM_TYPE.customControl,
                render: () => <WorkloadTypeFilter />,
            },
            {
                type: TOOLBAR_ITEM_TYPE.customControl,
                render: () => <InfrastructureFilter />,
            },
            {
                type: TOOLBAR_ITEM_TYPE.customControl,
                render: () => <PlatformFilter />,
            },
            {
                type: TOOLBAR_ITEM_TYPE.customControl,
                render: () => <JobTypesFilter />,
            },
        ]}
    />
);
