/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { RequestParams } from '@veeam-vspc/components';
import type { Vb365ProtectedObject } from '@veeam-vspc/models/rest';

import { core } from 'core/core-module';
import { alternateTransformRequestParams } from 'core/utils';

import type { ProtectedVBObjectsFilters } from '../interfaces';

export class VBObjectsService {
    static async getProtectedVBObjects(params: RequestParams<ProtectedVBObjectsFilters>) {
        return core.transportService
            .get<{}, Vb365ProtectedObject[]>(
                '/protectedWorkloads/vb365ProtectedObjects',
                alternateTransformRequestParams(params)
            );
    }
}
