/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/components';

import type { TableWidgetProps } from '../../interfaces';

export const TableWidgetStyled = styled.div<Partial<TableWidgetProps<unknown>>>`
    background-color: ${({ data, isNotApplicable }) => (data.length === 0 || isNotApplicable) ? colors.G100 : colors.G000};
    display: flex;
    flex-direction: column;
    height: ${({ height }) => height ? `${height}px` : 'auto'};
    padding: ${constants.SPACING_M};
    position: relative;
    transition: 0.3s;
    --first-row-height: ${({ title }) => title ? constants.SIZE_XXXL : constants.SIZE_XL};

    &:hover {
        background-color: ${({ data, theme }) => data.length === 0 ? colors.G100 : theme.colors.backgrounds.hover};
        cursor: ${({ data, onClick }) => data.length === 0 || !onClick ? 'auto' : 'pointer'};
    }

    .table-widget__table {
        display: grid;
        grid-auto-rows: ${constants.SIZE_XXXL};
        grid-template-columns: ${({ shiftColumns }) => shiftColumns ? '2fr 1fr 1fr' : '2fr auto auto'};
        grid-template-rows: repeat(1, var(--first-row-height));

        h2 {
            &:first-child {
                padding-left: 0;
            }

            padding-top: 0;
        }
    }
`;
