/**
 * Copyright © Veeam Software Group GmbH.
 */

import { formatStr } from '@veeam-vspc/core';

import { CloudGatewaySelectionType } from 'core/enums';

import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';
import type { CompanyData } from '../interfaces/index';

export const getGatewayNames = (data: CompanyData, lang: LangsServiceBase) => {
    let retVal;
    if (data.gatewaySelectionType === CloudGatewaySelectionType.StandaloneGateways) {
        retVal = lang.AUTOMATIC_SELECTION;
    } else if (data.gatewayPool?.length > 0) {
        if (data.gatewayPool.length === 1) {
            retVal = lang.ONE_GATEWAY_POOL_SELECTED;
        } else {
            retVal = formatStr(lang.GATEWAY_POOLS_SELECTED, data.gatewayPool.length);
        }
    } else {
        retVal = lang.NO_GATEWAY_POOLS_SELECTED;
    }
    return retVal;
};
