/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { BaseRequestResponse, BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { core } from 'core/core-module';
import { getData } from '../transport/utils';

import type { IsExistsNameOrAlias, IsExistsNameOrAliasParams } from 'core/interfaces';

export class CompanyService {
    static isExistsNameOrAlias(reqData: IsExistsNameOrAliasParams): Promise<IsExistsNameOrAlias> {
        return getData<IsExistsNameOrAliasParams, IsExistsNameOrAlias>('Company/IsExistsNameOrAlias', reqData);
    }

    static checkVboResourceCanBeDeleted(params: { companyUid?: string; vboServerUid: string; }): Promise<void> {
        return core.transportService.request<typeof params, BaseRequestResponse<void>>('Company/CheckVboResourceCanBeDeleted', params)
            .then();
    }

    static getCompanyVb365RepositoryUsage(companyUid: string, serverInstanceUid: string, resourceInstanceUid: string) {
        return core.transportService
            .get<{}, string[]>(
                // eslint-disable-next-line max-len
                `/organizations/companies/${companyUid}/vb365Resources/${serverInstanceUid}/backupResources/${resourceInstanceUid}/usageInOrganizations`
            )
            .then((resp: BaseSuccessRequestResponse<string[]>) => resp.data);
    }

    static getCompanyVb365ArchiveRepositoryUsage(companyUid: string, serverInstanceUid: string, resourceInstanceUid: string) {
        return core.transportService
            .get<{}, string[]>(
                // eslint-disable-next-line max-len
                `/organizations/companies/${companyUid}/vb365Resources/${serverInstanceUid}/backupResources/${resourceInstanceUid}/usageInOrganizations?checkOnlyArchiveRepository=true`
            )
            .then((resp: BaseSuccessRequestResponse<string[]>) => resp.data);
    }
}
