/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { ComboboxKit, FieldLayout, constants } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { reaction } from 'mobx';
import { PlatformTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import { useJobsOverviewStore } from '../../../../store';
import { useLang } from 'views/providers/LangProvider/hooks';
import { getPlatformOptions } from './get-platform-options';

export const PlatformFilter: React.FC = observer(() => {
    const jobsOverviewStore = useJobsOverviewStore();
    const lang = useLang();
    const data = useMemo(
        () => getPlatformOptions(jobsOverviewStore.infrastructureType, lang),
        [jobsOverviewStore.infrastructureType]
    );

    useEffect(() => {
        const disposer = reaction(
            () => jobsOverviewStore.infrastructureType,
            () => jobsOverviewStore.changePlatformType(PlatformTypeRepresentation.Unknown)
        );

        return () => disposer();
    }, []);

    return (
        <FieldLayout
            label={`${lang.PLATFORM}:`}
        >
            <ComboboxKit
                size={constants.CONTROL_SIZE_XXM}
                value={jobsOverviewStore.platformType}
                data={data}
                valueGetter={item => item.id}
                textGetter={item => item.name}
                onChange={jobsOverviewStore.changePlatformType}
            />
        </FieldLayout>
    );
});
