/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts } from '@veeam-vspc/components';

import { BackupJobsDialog } from './components/BackupJobsDialog/BackupJobsDialog';

import type { BackupJobsDialogProps } from './components/BackupJobsDialog/BackupJobsDialog';

export interface BackupJobsColumnProps extends Omit<BackupJobsDialogProps, 'deactivate'> {
    value: number | null;
}

export const BackupJobsColumn: React.FC<BackupJobsColumnProps> = ({ value, onClose, ...restProps }) => (
    <ActionLinkColumn
        content={value || ColumnTexts.DashSymbol}
        isLink={Boolean(value)}
        isExtMode={true}
        alignRight={true}
        renderPageDialog={({ onClose: onClosePageDialog }) => (
            <BackupJobsDialog
                {...restProps}
                onClose={(...args) => {
                    onClosePageDialog(...args);
                    onClose?.(...args);
                }}
            />
        )}
    />
);
