/**
 * Copyright © Veeam Software Group GmbH.
 */

import { GridSortDirections } from '@veeam-vspc/components';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core/services/transport/interfaces/base-success-request-response';
import type { CloudConnectParam, CloudConnectServerWithDeployInfo } from '@veeam-vspc/models/web-controllers';

import { core } from 'core/core-module/core-module';
import { MAX_INTEGER_32 } from '../../const';

export class CloudConnectService {
    static getAgents(resellerId?: number): Promise<CloudConnectServerWithDeployInfo[]> {
        return core.transportService.request<CloudConnectParam, CloudConnectServerWithDeployInfo[]>('CloudConnectServers/Get', {
            resellerId,
            withDeployInfo: false,
            start: 0,
            limit: MAX_INTEGER_32,
            sort: [{ property: 'siteName', direction: GridSortDirections.Asc }],
        })
            .then((resp: BaseSuccessRequestResponse<CloudConnectServerWithDeployInfo[]>) => resp.data);
    }
}
