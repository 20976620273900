/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    createDefaultDatetimeInputControl,
    Datetime,
    formatDate,
    normalizeOldDateFormat,
} from '@veeam-vspc/components';
import React from 'react';

import type { DefaultDatetimeInputControlValueFormatterPayload } from '@veeam-vspc/components';
import type { DatetimeProps } from '@veeam-vspc/components/src/form/components/Datetime/Datetime';

import { useAppStore } from '../../../views/providers/AppProvider/hooks';

export const DatetimeFormatted: React.FC<DatetimeProps> = (props) => {
    const { formats } = useAppStore();

    return (
        <Datetime
            controlRenderer={createDefaultDatetimeInputControl({
                valueFormatter: ({ value }: DefaultDatetimeInputControlValueFormatterPayload): string =>
                    value ? formatDate(value, normalizeOldDateFormat(formats.netShortDate)) : '',
            })}
            {...props}
        />
    );
};
