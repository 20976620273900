/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SmtpSettings } from '@veeam-vspc/models/rest';

import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const getTlsModeTypes = (lang: LangsServiceBase) => [
    { id: SmtpSettings.TlsModeEnum.Auto, name: lang.AUTO },
    { id: SmtpSettings.TlsModeEnum.None, name: lang.NONE_NOT_SECURE },
    { id: SmtpSettings.TlsModeEnum.SslOnConnect, name: lang.SSL_ON_CONNECT },
    { id: SmtpSettings.TlsModeEnum.StartTls, name: lang.START_TLS },
    { id: SmtpSettings.TlsModeEnum.StartTlsWhenAvailable, name: lang.START_TLS_WHEN_AVAILABLE },
];
