/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    ComboboxKit,
    FieldLayout,
    LabelGroup,
    NumberInputKit,
    STACK_DIRECTION,
    useFormApi,
    CONTROL_SIZE,
    STACK_GAP,
    StackView,
    ValidationStatus,
    immediatelyValidateFlag,
} from '@veeam-vspc/components';
import { TimeUnitsInMinutes } from '@veeam-vspc/core';
import { CONTROL_SIZE_XXS_PLUS } from '@veeam-vspc/components/src/constants';

import { useLang } from 'views/providers/LangProvider/hooks';

interface Props {
    as?: any;
    label?: string;
    name: string;
    magnitudeName?: string;
    suffix?: string;
    suffix2?: string;
    unitSize?: CONTROL_SIZE;
    allowDecimal?: boolean;
    onChange?: () => void;
}

export const DurationInMinutes = ({ as, label, name, magnitudeName, suffix, suffix2, unitSize, allowDecimal, onChange }: Props) => {
    const formsState = useFormApi();
    const lang = useLang();
    const minValue = 0;

    const unitList = [
        {
            name: lang.MINUTES.toLowerCase(),
            multiplier: 1,
            type: 'm',
        }, {
            name: lang.HOURS,
            multiplier: TimeUnitsInMinutes.Hour,
            type: 'h',
        }, {
            name: lang.DAYS.toLowerCase(),
            multiplier: TimeUnitsInMinutes.Day,
            type: 'd',
        }, {
            name: lang.WEEKS.toLowerCase(),
            multiplier: TimeUnitsInMinutes.Week,
            type: 'w',
        }, {
            name: lang.MONTHS.toLowerCase(),
            multiplier: TimeUnitsInMinutes.Month,
            type: 'mn',
        },
    ];

    const [state, setState] = useState(() => {
        let count = 0;
        let unit;
        const value = formsState.getValue(name);


        if (value) {
            for (let i = unitList.length - 1; i >= 0; i--) {
                const item = unitList[i];
                const { multiplier, type } = item;

                if (value % multiplier === 0) {
                    count = value / multiplier;
                    unit = type;
                    break;
                }
            }
        } else {
            count = value;
            unit = 'm';
        }


        return {
            count,
            unit,
            value,
        };
    });

    const updateValue = (count = minValue, unit: string) => {
        Object.assign(state, { count, unit });

        const { multiplier } = unitList.find(rec => rec.type === unit);
        const newValue = count * multiplier;

        formsState.setValue(name, newValue);
        state.value = newValue;

        if (magnitudeName) {
            formsState.setValue(magnitudeName, count);
        }

        setState(Object.assign({}, state));
        onChange?.();
    };

    const [touched, setTouched] = useState(false);

    const calcErrorText = () => {
        const validationStatus = formsState.validationState.getStatus();

        let error = formsState.getError(magnitudeName || name);
        const isImmediately = error ? error.indexOf(immediatelyValidateFlag) !== -1 : false;
        const showError = (touched || validationStatus !== ValidationStatus.Common || isImmediately) && error;

        error = isImmediately ? error.replace(immediatelyValidateFlag, '') : error;

        return showError ? error : '';
    };
    const [errorText, setErrorText] = useState(() => calcErrorText());

    useEffect(() => {
        const updateValueFunction = () => {
            setErrorText(calcErrorText());
        };

        setErrorText(calcErrorText());

        formsState.onChange(updateValueFunction);
        return () => formsState.unsubscribeOnChange(updateValueFunction);
    }, [touched]);

    useEffect(() => {
        if (magnitudeName) {
            formsState.setValue(magnitudeName, state.count);
        }
    }, []);

    return (
        <LabelGroup asTag={as} label={label}>
            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                <FieldLayout
                    suffix={suffix}
                    error={errorText}
                >
                    <NumberInputKit
                        allowDecimal={allowDecimal !== false}
                        precision={2}
                        minValue={minValue}
                        error={errorText}
                        onChange={(v) => {
                            updateValue(v, state.unit);
                        }}
                        onBlur={() => setTouched(true)}
                        value={state.count}
                        size={CONTROL_SIZE_XXS_PLUS}
                    />
                </FieldLayout>

                <FieldLayout
                    suffix={suffix2}
                >
                    <ComboboxKit
                        data={unitList}
                        onChange={(v) => {
                            updateValue(state.count, v);
                        }}
                        textGetter={item => item.name}
                        value={state.unit}
                        valueGetter={item => item.type}
                        size={unitSize || CONTROL_SIZE.xxs}
                    />
                </FieldLayout>
            </StackView>
        </LabelGroup>
    );
};
