/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
} from '@veeam-vspc/components';

import type { ToolbarItems } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { correctDecorators } from 'core/utils/toolbar-decorators';
import { PublicAddressDialog } from '../PublicAddressDialog';
import addIcon from 'images/actions/add.svg';
import removeIcon from 'images/actions/remove.svg';
import editIcon from 'images/actions/edit.svg';
import addIpAddressIcon from 'images/actions/add-ip-address.png';
import addIpRangeIcon from 'images/actions/add-ip-range.png';
import { PublicAddressRangeDialog } from '../PublicAddressRangeDialog';

import type { PublicIp } from '../../../../../../../../interfaces';

export const ActionsToolbar = ({ items }: { items: PublicIp[]; }) => {
    const lang = useLang();
    const gridApi = useGridApi<PublicIp, void, void>();
    const addGroupId = 'addGroupId';

    const [addressDialog, addressDialogActions] = useModal<PublicIp>({
        render: ({ deactivate, data }) => (
            <PublicAddressDialog
                data={data}
                existingItems={items}
                onConfirm={(newData) => {
                    if (data) {
                        items[items.findIndex(item => item.address === data.address)] = newData;
                    } else {
                        items.push(newData);
                    }

                    gridApi.reloadGrid();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const [addressRangeDialog, addressRangeDialogActions] = useModal<PublicIp>({
        render: ({ deactivate, data }) => (
            <PublicAddressRangeDialog
                existingItems={items}
                onConfirm={(newData) => {
                    items.push(...newData);

                    gridApi.reloadGrid();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const allowEditDecorator: any = ({ selectedItems }: { selectedItems: PublicIp[]; }) => {
        let disabled = true;

        if (selectedItems && selectedItems.length === 1) {
            disabled = selectedItems[0].tenantName?.length > 0;
        }

        return { disabled };
    };

    const toolbarItems: ToolbarItems = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: addIpRangeIcon,
            text: lang.ADD_IP_RANGE,
            groupId: addGroupId,
            onClick: () => {
                addressRangeDialogActions.activate();
            },
            decorators: [
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: addIpAddressIcon,
            text: lang.ADD_INDIVIDUAL_IP_ADDRESS,
            groupId: addGroupId,
            onClick: () => {
                addressDialogActions.activate();
            },
            decorators: [
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: editIcon,
            text: lang.EDIT,
            onClick: async() => {
                const selected = gridApi.selected[0];
                addressDialogActions.activate(selected);
            },
            decorators: correctDecorators([
                allowEditDecorator,
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ]),
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: removeIcon,
            text: lang.REMOVE,
            onClick: async() => {
                const selected = gridApi.selected[0];
                items.splice(items.findIndex(item => item.address === selected.address), 1);
                gridApi.reloadGrid();
            },
            decorators: correctDecorators([
                allowEditDecorator,
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ]),
        },
    ];

    return (
        <>
            <Toolbar
                groups={[
                    {
                        id: addGroupId,
                        title: lang.ADD,
                        iconSrc: addIcon,
                    },
                ]}
                items={toolbarItems}
            />

            {addressDialog}
            {addressRangeDialog}
        </>
    );
};
