/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum QueryKeys {
    ProductReturnUrl = 'stateFrom',
    CloudProductType = 'productType',
    From = 'from',
    VbId = 'vbId',
    SessionId = 'sessionId',
    Name = 'name',
    PolicyId = 'policyId',
    HierarchyRef = 'instanceId',
    RepositoryId = 'repositoryId',
}
