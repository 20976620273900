/**
 * Copyright © Veeam Software Group GmbH.
 */

import { getTagValueAttr, getXmlTag } from './xml-helpers';
import { XmlFileParams } from '../enums';

export const checkLicense = (xml: XMLDocument, isVbrUpgrade = false): boolean => {
    const licenseTag = getXmlTag(xml, XmlFileParams.LicenseQuery);
    const licenceValue = getTagValueAttr(licenseTag);
    const isNotDeclineValue = licenceValue !== XmlFileParams.DeclineValue;
    const isNotEmptyValue = Boolean(licenceValue);

    return isVbrUpgrade
        ? isNotDeclineValue
        : (isNotDeclineValue && isNotEmptyValue);
};
