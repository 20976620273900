/**
 * Copyright © Veeam Software Group GmbH.
 */

import { NotificationDialogsAdapter } from '@veeam-vspc/components';
import {
    LoggerService,
    NotificationService,
    TokenManagerService,
    TokenManagerTextKeys,
    TransportService,
    TransportTextKeys,
    NotificationDefaultTitles,
    NotificationDialogsTextKeys,
} from '@veeam-vspc/core';

import type { TransportConfigParams } from '@veeam-vspc/core';

import {
    PortalService,
    GlobalFiltersService,
    LangsService,
    VspcTransportActions,
    EnumMapperService,
    ResponseHandlerService,
    HighchartsService,
    ReturnUrlManagerService,
} from '../services';
import { baseUrl } from '../consts';
import { returnUrlActionFromVb } from '../utils/return-url-actions';
import { QueryKeys, ReturnUrlProducts } from '../enums';

import type { AppServices } from 'views/providers/AppProvider/interfaces';
import type { LangsServiceBase } from '../services/langs/interfaces';
import type { VspcTransportActionsServices } from '../services';


export class CoreModule {

    protected readonly restPrefix = '/api/v3';

    highchartsService: HighchartsService;
    notificationService: NotificationService;
    tokenManagerService: TokenManagerService;
    loggerService: LoggerService;
    transportService: TransportService;
    portalService: PortalService;
    globalFiltersService: GlobalFiltersService;
    enumMapperService: EnumMapperService;
    responseHandlerService: ResponseHandlerService;
    returnUrlManagerService: ReturnUrlManagerService;

    // @TODO move to core service
    langService: LangsService<LangsServiceBase>;

    protected isServicesCreated = false;

    getServices(): AppServices {
        if (!this.isServicesCreated) {
            this.createServiceInstances();
        }

        return {
            notificationService: this.notificationService,
            tokenManagerService: this.tokenManagerService,
            loggerService: this.loggerService,
            transportService: this.transportService,
            portalService: this.portalService,
            globalFiltersService: this.globalFiltersService,
            langService: this.langService,
            enumMapperService: this.enumMapperService,
            responseHandlerService: this.responseHandlerService,
            returnUrlManagerService: this.returnUrlManagerService,
        };
    }

    protected createServiceInstances(): void {
        const { restPrefix } = this;

        this.langService = new LangsService<LangsServiceBase>();
        this.globalFiltersService = new GlobalFiltersService();
        this.notificationService = new NotificationService(
            NotificationDialogsAdapter,
            {
                [NotificationDialogsTextKeys.Ok]: RCOP.Lang.OK,
                [NotificationDialogsTextKeys.Yes]: RCOP.Lang.YES,
                [NotificationDialogsTextKeys.No]: RCOP.Lang.NO,
                [NotificationDialogsTextKeys.Cancel]: RCOP.Lang.CANCEL,
                [NotificationDialogsTextKeys.Close]: RCOP.Lang.CLOSE,
                [NotificationDefaultTitles.Error]: RCOP.Lang.ERROR,
                [NotificationDefaultTitles.Information]: RCOP.Lang.INFORMATION,
                [NotificationDefaultTitles.Success]: RCOP.Lang.SUCCESS,
                [NotificationDefaultTitles.Warning]: RCOP.Lang.WARNING,
            }
        );
        this.tokenManagerService = new TokenManagerService(this.notificationService, {
            productPrefix: RCOP.Lang.VSPC,
            authUrl: `${restPrefix}/token`,
            texts: {
                [TokenManagerTextKeys.WebServerErrorTitle]: RCOP.Lang.CONNECTION_ERROR,
                [TokenManagerTextKeys.WebServerErrorMessage]: RCOP.Lang.WEB_SERVER_IS_NOT_RESPONDING,
                [TokenManagerTextKeys.AuthErrorTitle]: RCOP.Lang.SESSION_EXPIRED,
                [TokenManagerTextKeys.AuthErrorMessage]: RCOP.Lang.PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING,
                [TokenManagerTextKeys.AuthButtonMessage]: RCOP.utils.String.capitalize(RCOP.Lang.LOG_IN),
            },
        });
        this.loggerService = new LoggerService(this.tokenManagerService, `${baseUrl}Error/WriteLog`);

        const services: VspcTransportActionsServices = {
            loggerService: this.loggerService,
            tokenManagerService: this.tokenManagerService,
            notificationService: this.notificationService,
            globalFiltersService: this.globalFiltersService,
        };
        const transportParams: TransportConfigParams = {
            baseUrl,
            restPrefix,
            authorizationCodeProps: {
                url: '/Login/AuthorizationCode',
                queryKey: 'x-authorization-code',
            },
            trustedUrlsWithNoError: [
                'LicenseReport/SaveNotificationSettings',
                'Setting/SetSMTPSettings',
                'CloudConnectServers/CheckAgent',
            ],
            texts: {
                [TransportTextKeys.DownloadErrorMessage]: RCOP.Lang.FAILED_TO_DOWNLOAD_LOG_FILES,
                [TransportTextKeys.ErrorTitle]: RCOP.Lang.ERROR,
            },
        };

        this.transportService = new TransportService({ services, transportParams, TransportActions: VspcTransportActions });
        this.portalService = new PortalService(this.transportService, {
            portalDataUrl: '/Login/IsLogged',
            enabledFeaturesUrl: '/features',
            identityProvidersUrl: '/authentication/identityProviders',
            enumDescriptorsUrl: '/Common/GetEnumDescriptors',
        });

        this.enumMapperService = new EnumMapperService(this.portalService);
        this.responseHandlerService = new ResponseHandlerService(this.notificationService);
        this.returnUrlManagerService = new ReturnUrlManagerService({ transportService: this.transportService }, {
            returnUrlConfigs: [
                {
                    urlValidation: (location, queryParams) => queryParams.get(QueryKeys.ProductReturnUrl) === ReturnUrlProducts.Vb,
                    action: returnUrlActionFromVb,
                    resetQuery: true,
                },
            ],
        });

        this.highchartsService = new HighchartsService();
        this.isServicesCreated = true;
    }

}

export const core = new CoreModule();
