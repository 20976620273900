/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect, useState } from 'react';

import { ConfiguratorService } from 'core/services';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { useAppStore } from '../../AppProvider/hooks';

import type { UseConfiguratorServiceData } from '../interfaces';

export const useConfiguratorService = (): UseConfiguratorServiceData => {
    const { portalUser } = useAppStore();
    const [loading, setLoading] = useState(true);
    const [configurator] = useState(new ConfiguratorService(portalUser?.userRole || PortalUserRoles.Unknown));

    useEffect(() => {
        if (loading) {
            configurator.loadConfig()
                .then(() => setLoading(false))
                .catch((err) => {
                    console.error('Cannot load user config', err);
                });
        }
    }, [loading, configurator]);


    return {
        loading,
        configurator,
    };
};
