/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { LinkButton, Text } from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/use-page-wizard-store';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { StepLayout } from 'components/layouts/StepLayout';
import { RadioGroup } from 'components/controls/RadioGroup';
import { useLang } from 'views/providers/LangProvider/hooks';
import { ManagedCompaniesMode } from '../../enums';
import { ManagedCompaniesPanel } from './components/ManagedCompaniesPanel';

import type { SPUserModel } from '../../interfaces';

const Companies = (wizardStepData: WizardStepData<SPUserModel>) => {
    const lang = useLang();
    const wizardContext = usePageWizardStore<SPUserModel>();
    const { data, onDataChange, validationState } = wizardStepData;

    const showPanel = () => {
        wizardContext.openPanel(hide => <ManagedCompaniesPanel {...wizardStepData} hidePanel={hide} />);
    };

    useEffect(() => {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.companies.length === 0) {
                showPanel();
            }
        }
    });

    return (
        <StepLayout
            title={lang.COMPANIES}
            description={lang.SELECT_COMPANIES_ACCESSIBLE_FOR_THIS_USER}
        >
            <RadioGroup
                value={data.selectAllCompanies}
                onChange={(value: ManagedCompaniesMode) => {
                    data.selectAllCompanies = value;

                    onDataChange(data);
                }}
                buttons={[
                    {
                        label: lang.ALL_MANAGED_COMPANIES,
                        value: ManagedCompaniesMode.AllCompanies,
                    },
                    {
                        render: () => (
                            <>
                                <Text>
                                    {`${lang.SELECTED_COMPANIES_ONLY} ${data.companies.length > 0 ? `(${data.companies.length})` : ''}`}
                                </Text>

                                <LinkButton
                                    onClick={showPanel}
                                    disabled={data.selectAllCompanies === ManagedCompaniesMode.AllCompanies}
                                >
                                    {`(${lang.CONFIGURE})`}
                                </LinkButton>
                            </>
                        ),
                        value: ManagedCompaniesMode.CustomSelection,
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getCompaniesStep = (title: string): WizardStep<SPUserModel> => ({
    title,
    render: data => <Companies {...data} />,
    isHidden: ({ data }) => data.userRole === PortalUserRoles.ServiceProviderAdministrator ||
            data.userRole === PortalUserRoles.ServiceProviderInvoiceAuditor ||
            data.userRole === PortalUserRoles.ServiceProviderGlobalAdministrator,
    validate({ data }) {
        if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.companies.length === 0) {
            return false;
        }

        return true;
    },
    id: 'companies',
});
