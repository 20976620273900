/**
 * Copyright © Veeam Software Group GmbH.
 */


import { Vb365Server } from '@veeam-vspc/models/rest';

import type { Vb365OrganizationBaseFix } from 'core/interfaces';
import type { PortalUser } from 'core/entries';

export const isScheduleHidden = (portalUser: PortalUser, selectedOrganization: Vb365OrganizationBaseFix) => {
    if (!selectedOrganization) {
        return true;
    }

    if (portalUser.isLocationAdminOrCompanyOwner()) {
        return selectedOrganization._embedded.vb365Server.ownership === Vb365Server.OwnershipEnum.Hosted;
    }

    if (portalUser.isServiceProviderGroup() || portalUser.isPortalAdministrator()) {
        return false;
    }
};
