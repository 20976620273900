/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
} from '@veeam-vspc/components';

import type { ToolbarItems } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import editIcon from 'images/actions/edit.svg';
import { NetworkSettingsDialog } from '../../../NetworkSettingsDialog';

import type { CompanyVcdNetworkExtension } from '../../../../../../interfaces';


export const ActionsToolbar = ({ items }: { items: CompanyVcdNetworkExtension[]; }) => {
    const lang = useLang();
    const gridApi = useGridApi<CompanyVcdNetworkExtension, void, void>();

    const [quotaDialog, quotaDialogActions] = useModal<CompanyVcdNetworkExtension>({
        render: ({ deactivate, data }) => (
            <NetworkSettingsDialog
                data={data}
                onConfirm={(newData) => {
                    items[items.findIndex(item => item.dataCenterUid === data.dataCenterUid)] = newData;

                    gridApi.reloadGrid();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const toolbarItems: ToolbarItems = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: editIcon,
            text: lang.EDIT,
            onClick: async() => {
                const selected = gridApi.selected[0];
                quotaDialogActions.activate(selected);
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
    ];

    return (
        <>
            <Toolbar items={toolbarItems} />
            {quotaDialog}
        </>
    );
};
