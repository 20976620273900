/**
 * Copyright © Veeam Software Group GmbH.
 */

import { EPublicCloudPolicyType } from '@veeam-vspc/models/web-controllers';

import type {
    NewPolicySessionJson,
    NewRepositorySessionJson,
    NewRestoreSessionJson,
    UpdateRepositorySessionJson,
} from '@veeam-vspc/models/web-controllers';
import type { BaseRequestResponse } from '@veeam-vspc/core';

import { QueryKeys } from 'core/enums';
import { VbWizardTypes } from './enums';

import type { ReturnUrlManagerContext } from '../../services/return-url-manager/interfaces';

const getPolicyType = (fromWizard: VbWizardTypes): EPublicCloudPolicyType | null => {
    switch (fromWizard) {
        case (VbWizardTypes.PolicyEc2Wizard): // AWS
            return EPublicCloudPolicyType.EC2;
        case (VbWizardTypes.PolicyRdsWizard):
            return EPublicCloudPolicyType.Rds;
        case (VbWizardTypes.PolicyEfsWizard):
            return EPublicCloudPolicyType.Efs;
        case (VbWizardTypes.PolicyVmWizard): // Azure
            return EPublicCloudPolicyType.EC2;
        case (VbWizardTypes.PolicySqlWizard):
            return EPublicCloudPolicyType.Rds;
        case (VbWizardTypes.PolicyAzureFilesWizard):
            return EPublicCloudPolicyType.Efs;
        default:
            return null;
    }
};

export const returnUrlActionFromVb = (
    { transportService }: ReturnUrlManagerContext,
    location: Location,
    queryParams: URLSearchParams,
): Promise<BaseRequestResponse<void>> | Promise<BaseRequestResponse<void>[]> => {
    const fromWizard = queryParams.get(QueryKeys.From);
    const vbItemId = queryParams.get(QueryKeys.VbId);
    const sessionIdStr = queryParams.get(QueryKeys.SessionId);
    const nameStr = queryParams.get(QueryKeys.Name);
    const policyId = queryParams.get(QueryKeys.PolicyId);
    const hierarchyRef = queryParams.get(QueryKeys.HierarchyRef);
    const repositoryIdStr = queryParams.get(QueryKeys.RepositoryId);
    const type = getPolicyType(fromWizard as VbWizardTypes);

    switch (fromWizard) {
        case (VbWizardTypes.RepositoryWizard): {
            const sessionIds: string[] | void = sessionIdStr?.split(';');
            const repositoryIds: string[] | void = repositoryIdStr?.split(';');
            const names: string[] | void = nameStr?.split(';');

            return Promise.all(sessionIds?.map((sessionId, index) => {
                if (repositoryIds) {
                    return transportService.request<UpdateRepositorySessionJson, void>('/VbPlugin/UpdateRepositorySession', {
                        vbItemId,
                        sessionId,
                        repositoryId: repositoryIds[index],
                    });
                }

                return transportService.request<NewRepositorySessionJson, void>('/VbPlugin/NewRepositorySession', {
                    vbItemId,
                    sessionId,
                    name: names?.[index],
                });
            }) ?? []);
        }
        case (VbWizardTypes.PolicyEc2Wizard): // AWS
        case (VbWizardTypes.PolicyRdsWizard):
        case (VbWizardTypes.PolicyEfsWizard):
        case (VbWizardTypes.PolicyVmWizard): // Azure
        case (VbWizardTypes.PolicySqlWizard):
        case (VbWizardTypes.PolicyAzureFilesWizard): {
            return transportService.request<NewPolicySessionJson, void>('/VbPlugin/NewPolicySession', {
                vbItemId,
                policyId,
                name: nameStr,
                type,
            });
        }
        case (VbWizardTypes.FlrEc2Wizard): // AWS
        case (VbWizardTypes.FlrEfsWizard):
        case (VbWizardTypes.FlrVmWizard): // Azure
        case (VbWizardTypes.FlrAzureFilesWizard): {
            return transportService.request<NewRestoreSessionJson, void>('/VbPlugin/NewRestoreSession', {
                vbItemId,
                sessionId: sessionIdStr,
                hierarchyRef,
            });
        }
        default: {
            console.error('Unsupported productType: ', fromWizard);
        }
    }
};
