/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    Checkbox,
    Combobox,
    CONTROL_SIZE,
    FormLayout,
    NoLabelGroup,
    NumberInput,
    PasswordInput,
    TextInput,
} from '@veeam-vspc/components';
import React from 'react';
import { observer } from 'mobx-react-lite';

import { FormFieldNames } from '../../enums';
import { getTlsModeTypes } from '../../helpers';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useEmailServerSettingsStore } from '../../../../hooks';

export const EmailServerBasicSettingsFields = observer(() => {
    const lang = useLang();
    const serverSettingsStore = useEmailServerSettingsStore();
    const smtp = serverSettingsStore.storeFormData.smtp;
    const useAuth = serverSettingsStore.storeFormData.useAuth;
    const passwordInputHasEye = serverSettingsStore.passwordInputHasEye;

    const onPasswordFocus = async() => {
        const formApi = serverSettingsStore.storeFormApi;
        const password = smtp.passwordCredential.password;

        if (password === serverSettingsStore.defaultPasswordValue) {
            await formApi.setValue(FormFieldNames.Password, '');
        }

        if (!serverSettingsStore.passwordInputHasEye) {
            serverSettingsStore.passwordInputHasEye = true;
        }
    };

    return (
        <>
            <TextInput
                size={CONTROL_SIZE.m}
                label={lang.SMTP_SERVER}
                name={FormFieldNames.Host}
                placeholder={lang.ENTER_NAME_OR_ADDRESS}
            />

            <NumberInput
                minValue={1}
                maxValue={65535}
                label={lang.PORT}
                name={FormFieldNames.Port}
            />

            <Combobox
                data={getTlsModeTypes(lang)}
                size={CONTROL_SIZE.m}
                name={FormFieldNames.TlsMode}
                label={lang.ENCRYPTION_PROTOCOL}
                valueGetter={item => item.id}
                textGetter={item => item.name}
            />

            <NoLabelGroup
                content={(
                    <Checkbox
                        name={FormFieldNames.UseAuth}
                        checked={useAuth}
                    >
                        {lang.REQUIRES_AUTHENTICATION}
                    </Checkbox>
                )}
                subContent={(
                    <FormLayout inlineLabel disabled={!useAuth}>
                        <TextInput
                            label={lang.USERNAME}
                            size={CONTROL_SIZE.m}
                            name={FormFieldNames.UserName}
                            placeholder={lang.ENTER_USERNAME}
                        />

                        <PasswordInput
                            label={lang.PASSWORD}
                            size={CONTROL_SIZE.m}
                            hasEye={passwordInputHasEye}
                            name={FormFieldNames.Password}
                            placeholder={lang.ENTER_PASSWORD}
                            onFocus={onPasswordFocus}
                        />
                    </FormLayout>
                )}
            />
        </>
    );
});
