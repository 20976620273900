/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { GoogleCloudObjectType } from '@veeam-vspc/models/web-controllers';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ProtectedGcpWorkloadsContext } from './contexts/ProtectedGcpWorkloadsContext';
import { ProtectedGcpWorkloadsService } from './services/protected-gcp-workloads.service';
import { ProtectedGcpWorkloadsStore } from './stores/ProtectedGcpWorkloadsStore';
import { StatefulCombobox } from '../../../StatefulCombobox';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useProtectedGcpWorkloadsStore } from './hooks/use-protected-gcp-workloads-store';

import type { ComboboxItem } from '../../../../interfaces';

export const ChartLayout = observer(() => {
    const lang = useLang();
    const protectedGcpWorkloadsStore = useProtectedGcpWorkloadsStore();

    const params = {
        colors: [palette.B400],
        yTitle: lang.WORKLOADS,
    };

    const title = {
        text: capitalize(lang.PROTECTED_GCP_WORKLOADS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={protectedGcpWorkloadsStore.setGoogleCloudObjectType}
                stateId={'trends.backup-services.protected-gcp-workloads.google-cloud-object-type'}
                textGetter={(p: ComboboxItem) => p.text}
                value={protectedGcpWorkloadsStore.googleCloudObjectType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: GoogleCloudObjectType.Vm, text: capitalize(lang.VMS) },
                    { id: GoogleCloudObjectType.Database, text: capitalize(lang.CLOUD_SQL_DATABASES) },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={protectedGcpWorkloadsStore.googleCloudObjectType}
            params={params}
            service={ProtectedGcpWorkloadsService}
            title={title}
        />
    );
});

export const ProtectedGcpWorkloads: React.FC = () => {
    const protectedGcpWorkloadsStore = useMemo(() => new ProtectedGcpWorkloadsStore(), []);

    return (
        <ProtectedGcpWorkloadsContext.Provider value={protectedGcpWorkloadsStore}>
            <ChartLayout />
        </ProtectedGcpWorkloadsContext.Provider>
    );
};
