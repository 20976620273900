/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { BackupInfrastructureObjectType } from '@veeam-vspc/models/web-controllers';

import vbrNotConnected from 'images/vbr-not-connected.svg';
import { useIntervalRequest } from 'views/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { TableWidget } from 'views/components/TableWidget';
import backupServer from '../../../../../images/backup-server.svg';
import backupProxy from '../../../../../images/backup-proxy.svg';
import backupRepository from '../../../../../images/backup-repository.svg';
import wanAccelerator from '../../../../../images/wan-accelerator.svg';
import cloudBackupAppliance from '../../../../../images/cloud-backup-appliance.svg';
import { Product, AlarmStatus } from '../../enums';
import { NameColumn } from 'views/components/TableWidget/components/NameColumn';
import { NoInfoColumn } from 'views/components/TableWidget/components/NoInfoColumn';
import { HealthyColumn } from 'views/components/TableWidget/components/HealthyColumn';
import { ErrorsColumn } from 'views/components/TableWidget/components/ErrorsColumn';
import { WarningsColumn } from 'views/components/TableWidget/components/WarningsColumn';
import { EmptyColumnPlaceholder } from 'views/components/TableWidget/components/EmptyColumnPlaceholder';
import { TOGGLE_FILTER_ALL_VALUE } from '../../configs';
import { useAppServices } from '../../../../providers/AppProvider/hooks';

import type { BackupInfrastructureResponse } from '../../interfaces';


const icons = {
    [BackupInfrastructureObjectType.BackupServer]: backupServer,
    [BackupInfrastructureObjectType.BackupProxy]: backupProxy,
    [BackupInfrastructureObjectType.BackupRepository]: backupRepository,
    [BackupInfrastructureObjectType.WanAccelerator]: wanAccelerator,
    [BackupInfrastructureObjectType.BackupAppliance]: cloudBackupAppliance,
};

export const VeeamBackupReplication: React.FC = () => {
    const lang = useLang();
    const { enumMapperService } = useAppServices();
    const request = useIntervalRequest<{}, BackupInfrastructureResponse>('Overview/BackupInfrastructureAlarms');
    const items = (request.data as BackupInfrastructureResponse)?.every(item => item.overallCount === 0)
        ? []
        : (request.data as BackupInfrastructureResponse);

    return (
        <TableWidget
            title={lang.VEEAM_BACKUP_AND_REPLICATION}
            height={388}
            data={items || []}
            shiftColumns
            columns={[
                {
                    cellRenderer: ({ rowData, key }) => (
                        <NameColumn
                            icon={icons[rowData.itemType]}
                            name={enumMapperService.getEnumDescription('BackupInfrastructureObjectType', rowData.itemType)}
                            key={key}
                        />
                    ),
                },
                {
                    cellRenderer: ({ rowData, key }) => {
                        if (rowData.overallCount === 0) {
                            return (
                                <React.Fragment key={key}>
                                    <NoInfoColumn />
                                    <EmptyColumnPlaceholder />
                                </React.Fragment>
                            );
                        }

                        if (rowData.errors.value === 0 && rowData.warnings.value === 0) {
                            return (
                                <React.Fragment key={key}>
                                    <HealthyColumn />
                                    <EmptyColumnPlaceholder />
                                </React.Fragment>
                            );
                        }

                        const columns = [];

                        if (rowData.errors.value > 0) {
                            columns.push(<ErrorsColumn count={rowData.errors.value} key='errors' />);
                        }

                        if (rowData.warnings.value > 0) {
                            columns.push(<WarningsColumn count={rowData.warnings.value} key='warnings' />);
                        }

                        while (columns.length < 2) {
                            columns.push(<EmptyColumnPlaceholder key={columns.length} />);
                        }

                        return columns;
                    },
                },
            ]}
            onClick={() => {
                const filters = `statusesFilter/${AlarmStatus.Warning},${AlarmStatus.Error}/productsFilter/${Product.VBR}/` +
                    `dateFilter/${TOGGLE_FILTER_ALL_VALUE}/scopesFilter/${TOGGLE_FILTER_ALL_VALUE}`;

                window.location.assign(`#home/activeAlarms/tab/alarms/${filters}`);
            }}
            loading={request.loading}
            noDataConfig={{
                linkText: lang.START_MANAGING_VEEAM_BACKUP_AND_REPLICATION,
                icon: vbrNotConnected,
                linkHandler: () => window.location.assign('#home/discovery/tab/discoveredComputersTab/s/discoveredComputers'),
            }}
            className='fb-50'
        />
    );
};
