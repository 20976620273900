/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { PortalSpinner, useGridApi } from '@veeam-vspc/components';

import type { NotificationParams } from '@veeam-vspc/core';

import { useAppServices } from 'views/providers/AppProvider/hooks';

import type { UseAsyncActionData } from './interfaces';

export const useAsyncAction = (params: NotificationParams = {}): UseAsyncActionData => {
    const [loading, setLoading] = useState(false);
    const { transportService, notificationService } = useAppServices();
    const gridApi = useGridApi();
    const asyncActionLoader = loading && <PortalSpinner />;
    const doAsyncAction = (url: string, title: string, data: Record<string, any> = {}): Promise<void> => {
        setLoading(true);

        const callback = (resp) => {
            setLoading(false);

            return notificationService.multi(title, resp, params)
                .then(() => gridApi.reloadGrid());
        };

        return transportService.request(url, data)
            .then(resp => callback(resp))
            .catch(resp => callback(resp));
    };

    return [asyncActionLoader, doAsyncAction];
};
