/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { GridColumnJustify, GridSortDirections, PageDialog, TextColumn } from '@veeam-vspc/components';
import {
    JobMode,
    VbrBackupAgentJobKind,
    TargetTypeRepresentation,
    SystemTypeRepresentation,
    EndpointSourceTypeRepresentation,
} from '@veeam-vspc/models/web-controllers';

import type { CellRendererProps, PageDialogProps } from '@veeam-vspc/components';
import type { ComputerProtectingJobModel } from '@veeam-vspc/models/web-controllers';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { BackupJobsDialogToolbar } from '../BackupJobsDialogToolbar/BackupJobsDialogToolbar';
import { OldRestorePointColumn } from 'views/components/columns/OldRestorePointColumn';

import type { BackupJobsDialogExtraParams, BackupJobsDialogFilterModel, BackupJobsDialogRequestParams } from '../../interfaces';

export interface BackupJobsDialogProps extends Omit<PageDialogProps, 'title' | 'content'> {
    stateId: string;
    title: string;
    instanceUid: string;
    systemType: SystemTypeRepresentation;
    jobKind: VbrBackupAgentJobKind;
}

export const BackupJobsDialog: React.FC<BackupJobsDialogProps> = (props) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const idProperty = 'instanceUid';
    const {
        description,
        disabledBack,
        hasBack,
        footer,
        onClose,
        stateId,
        title,
        instanceUid,
        systemType,
        jobKind,
    } = props;
    const extraParams: BackupJobsDialogExtraParams = {
        computersUids: [instanceUid],
    };
    const defaultColumnRender = ({ cellData }: CellRendererProps<ComputerProtectingJobModel>) => (
        <TextColumn defaultValue={lang.NO_INFO}>{cellData}</TextColumn>
    );
    const columns = [
        {
            field: 'name',
            title: capitalize(lang.JOB_NAME),
            cell: defaultColumnRender,
        },
        jobKind === VbrBackupAgentJobKind.BackupJob && {
            field: 'operationModeRendered',
            title: lang.OPERATION_MODE,
            cell: defaultColumnRender,
        },
        jobKind === VbrBackupAgentJobKind.BackupJob && {
            field: 'backupModeRendered',
            title: capitalize(lang.BACKUP_TYPE),
            cell: defaultColumnRender,
        },
        {
            field: 'restorePoints',
            title: lang.RESTORE_POINTS,
            cell: ({ cellData, rowData }: CellRendererProps<ComputerProtectingJobModel>) => {
                const isVspcJob = rowData.sourceType === EndpointSourceTypeRepresentation.Vac;
                const isMbaJob = rowData.jobManagementMode === JobMode.ManagedByAgent;
                const isLocalTarget = [
                    TargetTypeRepresentation.SharedFolder,
                    TargetTypeRepresentation.ComputerFolder,
                ].includes(rowData.destinationType);
                const isLink = (!isVspcJob && isMbaJob) ? !isLocalTarget : true;

                return (
                    <OldRestorePointColumn
                        stateId={`${stateId}.Dialog.RestorePoints`}
                        value={cellData as any}
                        name={rowData.name}
                        isLink={isLink}
                        extraParams={{
                            agentId: rowData.agentId,
                            configId: rowData.configUid,
                            endpointId: rowData.endpointUid,
                        }}
                    />
                );
            },
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'lastRestorePoint',
            title: lang.LATEST_RESTORE_POINT,
            cell: ({ cellData }: CellRendererProps<ComputerProtectingJobModel>) => (
                <GridDateColumn value={cellData} />
            ),
        },
        {
            field: 'backupSize',
            title: lang.BACKUP_SIZE,
            cell: ({ cellData }: CellRendererProps<ComputerProtectingJobModel>) => (
                <RestorePointSizeColumn value={cellData as any} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'destination',
            title: lang.DESTINATION,
            cell: defaultColumnRender,
        },
        systemType === SystemTypeRepresentation.Windows && {
            field: 'nextRun',
            title: lang.NEXT_RUN,
            cell: ({ cellData }: CellRendererProps<ComputerProtectingJobModel>) => (
                <GridDateColumn value={cellData} />
            ),
            hidden: true,
        },
    ];

    return (
        <PageDialog
            title={title}
            description={description}
            disabledBack={disabledBack}
            hasBack={hasBack}
            footer={footer}
            onClose={onClose}
            content={(
                <AdvancedGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: columns?.[0]?.field,
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={columns.filter(item => item)}
                    data={(requestParams: BackupJobsDialogRequestParams<BackupJobsDialogFilterModel>) => transportService
                        .request<typeof requestParams, ComputerProtectingJobModel[]>(
                            jobKind === VbrBackupAgentJobKind.BackupJob
                                ? '/ProtectedWorkloads/GetComputersProtectingJobs'
                                : '/ProtectedWorkloads/GetComputersProtectingCopyJobs',
                            {
                                ...requestParams,
                                ...extraParams,
                            },
                        )}
                    toggleable={true}
                    selection={{
                        field: idProperty,
                        checkbox: true,
                        multiple: true,
                    }}
                    toolbars={[
                        () => (
                            <BackupJobsDialogToolbar
                                jobKind={jobKind}
                                idProperty={idProperty}
                                extraParams={extraParams}
                                method={jobKind === VbrBackupAgentJobKind.BackupJob ? 'GetComputersProtectingJobs' : 'GetComputersProtectingCopyJobs'}
                                selectedOnly={true}
                            />
                        ),
                    ]}
                />
            )}
        />
    );
};
