/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, StackView, STACK_GAP, FieldLayout, SIZE_XL, Text, FIELD_XL } from '@veeam-vspc/components';
import styled, { css } from 'styled-components';

import type { IconType } from '@veeam-vspc/components';

export interface DisplayFieldProps {
    label?: string;
    value: string;
    disabled?: boolean;
    icon?: IconType;
    isTruncate?: boolean;
}

interface TruncateTextProps {
    isTruncate: boolean;
}

const truncateCSS = css`
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${FIELD_XL};
    overflow: hidden;
`;

const TruncateText = styled.div`
    ${(props: TruncateTextProps) => props.isTruncate && truncateCSS}
`;

export const DisplayField: React.FC<DisplayFieldProps> = ({ label, value, icon, isTruncate }: DisplayFieldProps) => (
    <FieldLayout label={`${label}:`}>
        <StackView gap={STACK_GAP.s}>
            {icon && <Icon src={icon} />}

            <Text
                style={{ display: 'flex', height: SIZE_XL }}
                // view={TEXT_VIEW.light}
            >
                <TruncateText isTruncate={isTruncate}>
                    {value}
                </TruncateText>
            </Text>
        </StackView>
    </FieldLayout>
);
