/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Vb365CopyJobSchedulePolicy } from '@veeam-vspc/models/rest';

import type { Vb365CopyJob } from '@veeam-vspc/models/rest';

export const copyJobDefaults: Vb365CopyJob = {
    sourceBackupJobUid: '',
    repositoryUid: '',
    schedulePolicy: {
        schedulePolicyType: Vb365CopyJobSchedulePolicy.SchedulePolicyTypeEnum.Immediate,
        periodicallyEvery: Vb365CopyJobSchedulePolicy.PeriodicallyEveryEnum.Hours1,
        dailyType: Vb365CopyJobSchedulePolicy.DailyTypeEnum.Everyday,
        backupWindowEnabled: false,
        backupWindowSettings: {
            backupWindow: Array.from({ length: 168 }, () => true),
            minuteOffset: 0,
        },
        dailyTime: '07:00',
    },
};
