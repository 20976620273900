/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    emailValidator,
} from '@veeam-vspc/core';
import { ResetPasswordForm } from '@veeam-vspc/components';

import type {
    BaseFailedRequestResponse,
    BaseSuccessRequestResponse,
} from '@veeam-vspc/core';
import type { ResetPasswordFormState } from '@veeam-vspc/components';


import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor';

import type { ResetPasswordData } from '../../interfaces';

export const ResetPassword = observer(() => {
    const { transportService } = useAppServices();
    const lang = useLang();
    const loginStore = useLoginStore();

    return (
        <BaseFactor width={'442px'}>
            <ResetPasswordForm
                title={lang.RESET_PASSWORD}
                onReturnButtonClick={() => loginStore.onFinishFactorHandler({ goToLoginStart: true })}
                error={loginStore.error}
                loading={loginStore.isLoading}
                onChange={() => loginStore.resetMessages()}
                logIn={(formData: ResetPasswordFormState) => {
                    loginStore.isLoading = true;

                    const data = {
                        ...formData,
                        returnUrl: '/',
                    };

                    transportService.request<ResetPasswordData, {}>('/User/CreateResetPasswordRequest', data, null, {
                        noAuth: true,
                        notShowDefaultError: true,
                    })
                        .then(() => loginStore.onFinishFactorHandler({
                            goToLoginStart: true,
                            info: lang.A_PASSWORD_RESET_WAS_INITIATED_FOR_YOUR_ACCOUNT,
                        }))
                        .catch((resp) => {
                            const failedResp = resp as BaseFailedRequestResponse;
                            const successResp = resp as BaseSuccessRequestResponse;

                            loginStore.isLoading = false;
                            loginStore.error = failedResp['errors'][0]['message'] || successResp.data && successResp.data['message'];
                        });
                }}
                texts={{
                    labelUsername: lang.USERNAME,
                    labelEmail: lang.EMAIL,
                    actionLogIn: lang.RESET,
                    errorRequired: lang.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: lang.THE_MAXIMUM_LENGTH_IS,
                    warningAboutReset: lang.CLICK_RESET_TO_RECEIVE,
                    returnButtonText: lang.BACK,
                }}
                customEmailValidation={(value: string) => emailValidator(value, {
                    multipleEmailsError: lang.ONLY_ONE_EMAIL_ADDRESS_IS_SUPPORTED,
                    wrongEmailFormatError: lang.THIS_FIELD_SHOULD_BE_AN_EMAIL_ADDRESS,
                })}
            />
        </BaseFactor>
    );
});
