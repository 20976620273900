/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum DayType {
    DayWithJobs,
    DayWithoutJobs,
    OutOfPeriod,
    NoData,
}
