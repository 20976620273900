/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW, Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import { useLang } from 'views/providers/LangProvider/hooks';
import { useCompanyWizardStore } from '../../../../../../hooks';
import { WanAcceleration } from 'views/components/WanAcceleration';

import type { VcdReplicaResource } from '../../../../../../interfaces';

interface Props {
    deactivate: () => void;
    onConfirm: (data: VcdReplicaResource) => void;

    data?: VcdReplicaResource;
    existingItems: VcdReplicaResource[];
    cloudConnectAgentUid: string;
    organizationUid: string;
}

const formValidate = (data: VcdReplicaResource, lang: any, isEdit: boolean, existingItems: VcdReplicaResource[]) => {
    const validator = new FormValidator(data);

    validator.validate('dataCenterUid')
        .string()
        .required();

    if (data.isWanAccelerationEnabled) {
        validator.validate('wanAcceleratorUid')
            .string()
            .required();
    }

    return validator.result();
};

export const VcdResourceDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;
    const wizardStore = useCompanyWizardStore();

    const defaultData: VcdReplicaResource = {
        dataCenterName: '',
        dataCenterUid: null,
        isWanAccelerationEnabled: false,
    };

    const isEdit = !!props.data;
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.dataCenterUid === props.data.dataCenterUid), 1);
        }
        return result;
    });
    const [filteredAvailableDataCenterResources, setFilteredAvailableDataCenterResources] = useState([]);
    const [data, setData] = useState(props.data ?? defaultData);

    const formApi = useExternalFormApi<VcdReplicaResource>();
    const [loading, setLoading] = useState(false);
    const [validationState] = useState(new ValidationState());

    useEffect(() => {
        setLoading(wizardStore.availableDataCenterResourcesCache.loading);
        wizardStore.availableDataCenterResourcesCache.load({
            cloudConnectAgentUid: props.cloudConnectAgentUid,
            organizationUid: props.organizationUid,
        })
            .then((items) => {
                setFilteredAvailableDataCenterResources(items.filter(item => !existingItems
                    .find(existingItem => existingItem.dataCenterUid === item.cloudConnectResourceUid)
                ));
                setLoading(false);
            });

        wizardStore.wanAcceleratorsCache.load({ cloudConnectAgentUid: props.cloudConnectAgentUid });
    }, []);

    return (
        <Dialog
            header={`${isEdit ? lang.EDIT : lang.ADD} ${capitalize(lang.VIRTUAL_DATA_CENTER)}`}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            props.onConfirm && props.onConfirm(data);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={loading}
        >
            <Form <VcdReplicaResource>
                value={data}
                validate={data => formValidate(data, lang, isEdit, existingItems)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'dataCenterUid'}
                        label={lang.SELECT_VIRTUAL_DATA_CENTER}
                        placeholder={lang.SELECT_A_VIRTUAL_DATA_CENTER}
                        data={filteredAvailableDataCenterResources}
                        valueGetter={item => item.cloudConnectResourceUid}
                        textGetter={item => item.cloudConnectResourceName}
                        size={defaultFieldWidth}
                        onChange={(value, itemIndex) => {
                            const item = filteredAvailableDataCenterResources[itemIndex];
                            data.dataCenterName = item.cloudConnectResourceName;
                            formApi.setValue(data);
                        }}
                        disabled={isEdit}
                    />

                    <WanAcceleration
                        value={data}
                        wanAccelerators={wizardStore.wanAcceleratorsCache.value}
                        hidden={!wizardStore.isWanAcceleratorsEnabled}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
});
