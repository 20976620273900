/**
 * Copyright © Veeam Software Group GmbH.
 */

import { plural } from '@veeam-vspc/core/utils/string-helpers/string-helpers';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { capitalize } from '@veeam-vspc/core';
import {
    CheckboxKit,
    NoteBar,
    NOTEBAR_STATUS,
    STACK_GAP,
    ParsedText,
    WizardSummary,
    StackView,
    STACK_DIRECTION,
    formatDate,
    normalizeOldDateFormat,
    dataSizeToString,
} from '@veeam-vspc/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { StepLayout } from 'components/layouts/StepLayout';
import { CloudTenantType } from 'core/enums/cloud-tenant-type';
import { useAppStore } from 'views/providers/AppProvider/hooks/use-app-store';
import { getGatewayNames, syncHwPlansToNetwork, syncVboInfrastructure, syncVcdNetworksExtensions } from '../../utils';
import { CompanyMfaStatuses, CompanyVboRepositoryStorageType } from 'core/enums';
import { useCompanyWizardStore } from '../../hooks';
import { getBandwidthUnits } from 'views/components/wizard/utils';

import type { CompanyWizardStore } from '../../stores';
import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';
import type { VboRepository } from 'core/interfaces';
import type { CompanyData } from '../../interfaces';

export const getSummaryStep = (lang: LangsServiceBase): WizardStep<CompanyData> => ({
    title: lang.SUMMARY,
    render: data => <SummaryStep {...data} />,
});

const getMicrosoft365TotalQuota = (resources: VboRepository[], wizardStore: CompanyWizardStore, lang: LangsServiceBase): string => {
    let result = 0;

    for (let i = 0; i < resources.length; i++) {
        const resource = resources[i];
        const storageType = wizardStore.availableVboBackupRepositoryCache.value
            ?.find(item => item.instanceUid === resource.repositoryUid)?.storageType;

        if (storageType !== CompanyVboRepositoryStorageType.ArchiveObjectStorage) {
            if (resource.isUserQuotaUnlimited) {
                return lang.UNLIMITED;
            }
            result += resource.userQuota;
        }
    }

    return result.toString();
};

const getTotalQuota = <resourceT,>(resources: resourceT[], getValue: (resource: resourceT) => number): number => {
    let result = 0;

    for (let i = 0; i < resources.length; i++) {
        const value = getValue(resources[i]);
        if (value === -1) {
            result = -1;
            break;
        }
        result += value;
    }

    return result;
};

const getTotalQuotaText = <resourceT,>(quotas: resourceT[], getValue: (quotas: resourceT) => number, lang: LangsServiceBase) => {
    const total = getTotalQuota(quotas, getValue);
    if (total === -1) {
        return lang.UNLIMITED;
    }
    return total.toString();
};

const getQuotaDataSizeText = (value: number, lang: LangsServiceBase) => {
    if (value === -1) {
        return lang.UNLIMITED;
    }
    return dataSizeToString(value, 0, 'G');
};

export const SummaryStep = observer(({ data, isEdit, onDataChange }: WizardStepData<CompanyData>) => {
    const lang = useLang();
    const wizardStore = useCompanyWizardStore();
    const { formats } = useAppStore();

    useEffect(() => {
        wizardStore.cloudConnectAgentsCache.load();
        wizardStore.countriesCache.load();
        wizardStore.usaStatesCache.load();
        wizardStore.availableVboBackupRepositoryCache.load({ companyId: data.id }) ;

        wizardStore.subscriptionPlansCache.load({ availableForAssignTo: data.id });
        if (!isEdit) {
            wizardStore.smtpIsConfiguredCache.load()
                .then((isConfigured) => {
                    if (!data.emailAddress || !isConfigured) {
                        wizardStore.isRequiredSendEmail = false;
                    }
                });
        }

        syncVboInfrastructure(data.vboInfrastructure, wizardStore, data.id)
            .then((infrastructure) => {
                data.vboInfrastructure = infrastructure;
                onDataChange(data);
            });

        if (data.tenantType === CloudTenantType.General) {
            data.networks = syncHwPlansToNetwork(data, lang);
            onDataChange(data);
        }

        if (data.tenantType === CloudTenantType.Vcd) {
            syncVcdNetworksExtensions(data, wizardStore)
                .then((items) => {
                    data.vcdNetworksExtensions = items;
                    onDataChange(data);
                });
        }
    }, []);

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_AND_COPY_MSG}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: capitalize(lang.COMPANY_INFO, true),
                        fields: [
                            {
                                label: `${capitalize(lang.COMPANY_NAME, true)}:`,
                                value: data.name,
                            },
                            {
                                label: `${lang.LOGIN_ALIAS}:`,
                                value: data.alias,
                            },
                            {
                                label: `${lang.TAX_ID}:`,
                                value: data.taxId,
                            },
                            {
                                label: `${lang.TITLE}:`,
                                value: data.title
                                    ? wizardStore.userTitles.find(item => item.id === data.title)?.name
                                    : '',
                            },
                            {
                                label: `${lang.FIRST_NAME}:`,
                                value: data.firstName,
                            },

                            {
                                label: `${lang.LAST_NAME}:`,
                                value: data.lastName,
                            },
                            {
                                label: `${lang.EMAIL_ADDRESS}:`,
                                value: data.emailAddress,
                            },
                            {
                                label: `${lang.TELEPHONE}:`,
                                value: data.telephone,
                            },
                            {
                                label: `${lang.COUNTRY}:`,
                                value: data.country && data.country >= 0 && wizardStore.countriesCache.value?.length > 0
                                    ? wizardStore.countriesCache?.value.find(item => item.id === data.country).name
                                    : '',
                            },
                            {
                                label: `${lang.STATE}:`,
                                value: data.usState && data.usState >= 0 && wizardStore.usaStatesCache.value?.length > 0
                                    ? wizardStore.usaStatesCache.value?.find(item => item.id === data.usState).name
                                    : '',
                                hidden: !data.usState,
                            },
                            {
                                label: `${lang.COMPANY_ID}:`,
                                value: data.companyId,
                            },
                            {
                                label: `${lang.VEEAM_TENANT_ID}:`,
                                value: data.veeamTenantId,
                            },
                            {
                                label: `${lang.ZIP_CODE}:`,
                                value: data.zipCode,
                            },
                            {
                                label: `${lang.WEB_SITE}:`,
                                value: data.domain,
                            },
                            {
                                label: `${lang.NOTES}:`,
                                value: data.notes,
                            },
                        ],
                    }, {
                        title: lang.COMPANY_TYPE,
                        fields: [
                            {
                                label: `${lang.TENANT_CONFIGURATION}:`,
                                value: {
                                    [CloudTenantType.General]: lang.NATIVE,
                                    [CloudTenantType.Vcd]: lang.VMWARE_CLOUD_DIRECTOR,
                                }[data.tenantType],
                            },
                        ],
                    }, {
                        title: capitalize(lang.USER_INFO, true),
                        fields: [
                            {
                                label: `${lang.USERNAME}:`,
                                value: data.userName,
                            },
                            {
                                label: `${lang.SITE}:`,
                                value: wizardStore.cloudConnectAgentsCache.value
                                    ?.find(item => item.agentGuid === data.cloudConnectAgentUid)?.siteName,
                            },
                            {
                                label: `${lang.ACCESS_TO_REST_API}:`,
                                value: data.restEnabled ? lang.ENABLED : lang.DISABLED,
                                hidden: !wizardStore.isRestAvailable,
                            },
                            {
                                label: `${lang.ACCOUNT_EXPIRATION_DATE}:`,
                                value: data.expirationEnabled
                                    // TODO: add function to
                                    // RobocopUI.Modules/components/src/utils/date-helpers/date-helpers.ts
                                    // with normalizeOldDateFormat inside
                                    ? formatDate(data.expirationTime, normalizeOldDateFormat(formats.netShortDate))
                                    : capitalize(lang.NOT_DEFINED, true),
                            },
                        ],
                    }, {
                        title: capitalize(lang.MANAGED_SERVICES, true),
                        fields: [
                            {
                                label: `${lang.BACKUP_AGENTS_MANAGEMENT}:`,
                                value: data.backupAgentManagementEnabled ? lang.ON : lang.OFF,
                            },
                            {
                                label: `${lang.BACKUP_SERVERS_MANAGEMENT}:`,
                                value: data.backupServerManagementEnabled ? lang.ON : lang.OFF,
                            },
                        ],
                    }, data.cloudBackupResourceEnabled ? {
                        title: lang.CLOUD_CONNECT_BACKUP_RESOURCES,
                        fields: [
                            {
                                label: `${lang.CLOUD_STORAGE_QUOTA}:`,
                                value: getQuotaDataSizeText(getTotalQuota(data.quotas, (res => res.quota.storageGb)), lang),
                            },
                            {
                                label: `${lang.VMS_QUOTA}:`,
                                value: getTotalQuotaText(data.quotas, (res => res.quota.vms), lang),
                            },
                            {
                                label: `${lang.WORKSTATIONS_QUOTA}:`,
                                value: getTotalQuotaText(data.quotas, (res => res.quota.workstations), lang),
                            },
                            {
                                label: `${lang.SERVERS_QUOTA}:`,
                                value: getTotalQuotaText(data.quotas, (res => res.quota.servers), lang),
                            },
                            {
                                label: `${lang.PROTECT_DELETED_BACKUP_FILES_FOR}:`,
                                value: `${plural(data.backupProtectionPeriod, lang.DAY.toLowerCase())}`,
                                hidden: !data.backupProtectionEnabled,
                            },
                        ],
                    } : null, data.cloudReplicationResourceEnabled ? {
                        title: lang.CLOUD_CONNECT_REPLICATION_RESOURCES,
                        fields: [
                            {
                                label: `${lang.HARDWARE_PLAN}:`,
                                value: data.hwPlans?.map(item => item.cloudConnectResourceName).join(', '),
                                hidden: data.tenantType !== CloudTenantType.General,
                            },
                            {
                                label: `${lang.VIRTUAL_DATA_CENTERS}:`,
                                value: data.vcdReplicationResources?.map(item => item.dataCenterName).join(', '),
                                hidden: data.tenantType !== CloudTenantType.Vcd,
                            },
                            {
                                label: `${lang.NETWORK_EXTENSION_APPLIANCE}:`,
                                value: data.networks?.map(item => item.name).join(', '),
                                hidden: !data.networkFailoverResourcesEnabled || data.tenantType !== CloudTenantType.General,
                            },
                            {
                                label: `${lang.NETWORK_EXTENSION_APPLIANCE}:`,
                                value: data.vcdNetworksExtensions?.map(item => item.name).join(', '),
                                hidden: !data.networkFailoverResourcesEnabled || data.tenantType !== CloudTenantType.Vcd,
                            },
                        ],
                    } : null, data.vB365IntegrationEnabled ? {
                        title: lang.MICROSOFT_365_MANAGED_BACKUP,
                        fields: [
                            {
                                label: `${lang.BACKUP_SERVER}:`,
                                value: data.vboInfrastructure.vboServers?.map(item => wizardStore.availableVboServersCache.value
                                    ?.find(availableServer => availableServer.instanceUid === item.vboServerUid)?.name).join(', '),
                            },
                            {
                                label: `${lang.BACKUP_REPOSITORY}:`,
                                value: data.vboInfrastructure.vboRepositories?.map(item => item.repositoryFriendlyName).join(', '),
                                hidden: data.vboInfrastructure.vboRepositories.length === 0,
                            },
                            {
                                label: `${lang.USERS_QUOTA}:`,
                                value: getMicrosoft365TotalQuota(data.vboInfrastructure.vboRepositories, wizardStore, lang),
                                hidden: data.vboInfrastructure.vboRepositories.length === 0,
                            },
                        ],
                    } : null, {
                        title: capitalize(lang.PUBLIC_CLOUD, true),
                        fields: [
                            {
                                label: `${lang.PUBLIC_CLOUD_MANAGED_BACKUP}:`,
                                value: data.vbPublicCloudManagementEnabled ? lang.ON : lang.OFF,
                            },
                        ],
                    }, {
                        title: lang.BILLING,
                        fields: [
                            {
                                label: `${capitalize(lang.SUBSCRIPTION_PLAN, true)}:`,
                                value: data.subscriptionPlan !== null && wizardStore.subscriptionPlansCache.value?.length > 0
                                    ? wizardStore.subscriptionPlansCache.value.find(item => item.id === data.subscriptionPlan).name
                                    : lang.NOT_SET,
                            },
                        ],
                    }, {
                        title: lang.BANDWIDTH,
                        fields: [
                            {
                                label: `${lang.THROTTLING}:`,
                                value: data.bandwidthThrottlingEnabled
                                    ? `${data.allowedBandwidth} ${getBandwidthUnits(lang)
                                        .find(item => item.value === data.allowedBandwidthUnits).name}`
                                    : lang.NOT_ASSIGNED,
                            },
                            {
                                label: `${lang.MAX_CONCURRENT_TASKS}:`,
                                value: `${data.maxConcurrentTask}`,
                            },
                            {
                                label: `${lang.GATEWAY_POOL}:`,
                                value: getGatewayNames(data, lang),
                            },
                            {
                                label: `${lang.DATA_TRANSFER_OUT_QUOTA}:`,
                                value: wizardStore.dataTransferGbEnabled ? dataSizeToString(data.dataTransferGb, 0, 'G') : lang.UNLIMITED,
                                hidden: !data.cloudBackupResourceEnabled && !data.cloudReplicationResourceEnabled,
                            },
                        ],
                    }, {
                        title: capitalize(lang.PREDEFINED_ALARMS, true),
                        fields: [
                            {
                                label: `${lang.SELF_MANAGED_ALARMS}:`,
                                value: data.alarmDetectEnabled ? lang.ENABLED : lang.DISABLED,
                            },
                        ],
                    }, {
                        title: capitalize(lang.MULTI_FACTOR_AUTHENTICATION.toLowerCase(), true),
                        fields: [
                            {
                                label: `${lang.MFA_STATUS}:`,
                                value: data.mfaPolicyStatus === CompanyMfaStatuses.Enabled ? lang.ENFORCED : lang.NOT_ENFORCED,
                            },
                        ],
                    },
                ].filter(item => item !== null)}
            />

            {!isEdit && (
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                    <CheckboxKit
                        checked={wizardStore.isRequiredSendEmail}
                        onChange={value => wizardStore.isRequiredSendEmail = value}
                        disabled={!data.emailAddress || !wizardStore.smtpIsConfiguredCache.value}
                    >
                        {lang.SEND_WELCOME_EMAIL_NOTIFICATION}
                    </CheckboxKit>

                    {!wizardStore.smtpIsConfiguredCache.value && (
                        <NoteBar status={NOTEBAR_STATUS.info}>
                            <ParsedText textOrConfig={lang.NOTIFICATION_POLICY_AND_EMAIL_SERVER} />
                        </NoteBar>
                    )}
                </StackView>
            )}
        </StepLayout>
    );
});
