/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { ReturnUrlConfig, ReturnUrlManagerContext, ReturnUrlManagerServiceParams } from './interfaces';

export class ReturnUrlManagerService {

    protected returnUrlConfigs: ReturnUrlConfig[] = [];
    protected context: ReturnUrlManagerContext;

    constructor(context: ReturnUrlManagerContext, { returnUrlConfigs }: ReturnUrlManagerServiceParams) {
        this.context = context;
        this.returnUrlConfigs = returnUrlConfigs;
    }

    handleReturnUrls(): Promise<void> {
        if (this.returnUrlConfigs.length > 0) {
            return this.handleAsyncReturnUrls(this.returnUrlConfigs);
        }

        return Promise.resolve();
    }

    protected handleAsyncReturnUrls(returnUrlConfigs: ReturnUrlConfig[], index = 0): Promise<void> {
        const currentReturnUrl = returnUrlConfigs[index];

        return this.handleReturnUrl(currentReturnUrl)
            .then((isResetActivated) => {
                if (!isResetActivated && index < (returnUrlConfigs.length - 1)) {
                    return this.handleAsyncReturnUrls(returnUrlConfigs, index + 1);
                }
            });
    }

    protected handleReturnUrl({ url, urlValidation, action, resetQuery = false }: ReturnUrlConfig): Promise<boolean> {
        const { href, pathname, origin, hash, search } = window.location;
        const queryParams = new URLSearchParams(search.slice(1));

        if (urlValidation?.(window.location, queryParams) || href === url) {
            return Promise.resolve(action(this.context, window.location, queryParams))
                .then(() => {
                    if (resetQuery) {
                        window.history.replaceState(null, '', `${origin}${pathname}${hash}`);
                        return true;
                    }

                    return false;
                })
                .catch((err) => {
                    err && console.error(err);
                    return false;
                });
        }

        return Promise.resolve(false);
    }

}
