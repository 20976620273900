/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { AdvancedFilter, Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE } from '@veeam-vspc/components';

import type { AdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/components';

import { EJobStates, ProtectedVirtualMachineRestoreTypes } from 'core/enums';
import backupJobTypeIconSrc from 'images/filters/job-types/backup.png';
import snapshotJobTypeIconSrc from 'images/filters/job-types/snapshot.png';
import remoteSnapshotJobTypeIconSrc from 'images/filters/job-types/remote-snapshot.png';
import archiveJobTypeIconSrc from 'images/filters/job-types/archive.png';
import { useLang } from 'views/providers/LangProvider/hooks';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { useCloudPlatformTypesAdvancedFilter } from 'views/components/filters/CloudPlatformTypesFilters';
import { AdvancedToolbar } from 'views/components/AdvancedToolbar';

import type { AdvancedToolbarProps } from 'views/components/AdvancedToolbar';

export interface PublicCloudsFilterToolbarProps extends Omit<AdvancedToolbarProps, 'items'> {
}

export const PublicCloudsFilterToolbar: React.FC<PublicCloudsFilterToolbarProps> = (props) => {
    const lang = useLang();
    const statuses = [
        { title: lang.SUCCESS, value: EJobStates.Success },
        { title: lang.WARNING, value: EJobStates.Warning },
        { title: lang.FAILED, value: EJobStates.Failed },
        { title: lang.RUNNING, value: EJobStates.Running },
        {
            title: lang.INFORMATION,
            value: [
                EJobStates.None,
                EJobStates.Idle,
                EJobStates.Starting,
                EJobStates.Stopping,
                EJobStates.Enabling,
                EJobStates.Disabling,
                EJobStates.WaitingTape,
                EJobStates.WaitingRepository,
            ],
        },
    ];
    const cloudPlatformTypesAdvancedFilter = useCloudPlatformTypesAdvancedFilter();
    const advancedFilterOptions: AdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_POLICIES_BY}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.TYPE,
                        items: [
                            {
                                iconSrc: backupJobTypeIconSrc,
                                label: lang.BACKUP,
                                value: {
                                    id: ProtectedVirtualMachineRestoreTypes.Backup,
                                    name: 'jobType',
                                },
                            },
                            {
                                iconSrc: snapshotJobTypeIconSrc,
                                label: lang.SNAPSHOT,
                                value: {
                                    id: ProtectedVirtualMachineRestoreTypes.Snapshot,
                                    name: 'jobType',
                                },
                            },
                            {
                                iconSrc: remoteSnapshotJobTypeIconSrc,
                                label: lang.REPLICA_SNAPSHOT,
                                value: {
                                    id: ProtectedVirtualMachineRestoreTypes.RemoteSnapshot,
                                    name: 'jobType',
                                },
                            },
                            {
                                iconSrc: archiveJobTypeIconSrc,
                                label: lang.ARCHIVE,
                                value: {
                                    id: ProtectedVirtualMachineRestoreTypes.PublicCloudArchive,
                                    name: 'jobType',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    cloudPlatformTypesAdvancedFilter,
                ],
            },
        ],
    };

    return (
        <AdvancedToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='vmName'
                            placeholder={lang.VM}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='policyName'
                            placeholder={lang.POLICY}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            statuses={statuses}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <AdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
