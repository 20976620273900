/**
 * Copyright © Veeam Software Group GmbH.
 */

import { formatDate } from '@veeam-vspc/components/src/utils/date-helpers/date-helpers';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Combobox,
    CONTROL_SIZE,
    Form,
    FormLayout,
    FormValidator,
    isValid,
    LabelGroup,
    normalizeOldDateFormat,
    NoteBar,
    NOTEBAR_STATUS,
    NumberInput,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Toggle,
    useExternalFormApi,
} from '@veeam-vspc/components';

import type { ExternalFormApi, WizardStep } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { ToggleField } from 'components/controls/ToggleField';
import { useAppStore } from 'views/providers/AppProvider/hooks/use-app-store';
import { useResellerWizardStore } from '../../hooks/';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { ResellerData, ResellerWizardStepData } from '../../interfaces';
import type { ResellerWizardStore } from '../../stores';

interface StepData {
    formApi?: ExternalFormApi<ResellerData>;
}

const MIN_PULSE_POINTS_LIMIT = 1;
const MAX_PULSE_POINTS_LIMIT = 99999;

const formValidate = (data: ResellerData, lang: LangsServiceBase, wizardStore: ResellerWizardStore) => {
    const validator = new FormValidator(data);

    if (wizardStore.pulseConfiguration?.licenseManagement) {

        if (data.pulseLicenseCreation) {
            validator.validate('pulseContractId')
                .string()
                .required();
        }

        if (data.pulsePointsLimit) {
            validator.validate('pulsePointsLimitValue')
                .number()
                .required()
                .min(MIN_PULSE_POINTS_LIMIT)
                .max(MAX_PULSE_POINTS_LIMIT);
        }
    }

    return validator.result();
};

const stepValidate = (data: ResellerData, lang: LangsServiceBase, wizardStore: ResellerWizardStore) => {
    if (isValid(value => formValidate(value, lang, wizardStore), data)) {
        return true;
    }

    return false;
};

export const getLicenseStep = (lang: LangsServiceBase, wizardStore: ResellerWizardStore): WizardStep<ResellerData> => ({
    title: lang.LICENSE_MANAGEMENT,
    validate: ({ data, isEdit, stepData }) => stepValidate(data, lang, wizardStore),
    render: stepData => <LicenseStep {...stepData} wizardStore={wizardStore} />,
});

const LicenseStep = observer((wizardStepData: ResellerWizardStepData) => {
    const { data, validationState, onDataChange } = wizardStepData;
    const stepData = wizardStepData.stepData as StepData;
    const lang = useLang();
    const formApi = useExternalFormApi<ResellerData>();
    const wizardStore = useResellerWizardStore();
    const { formats } = useAppStore();

    useEffect(() => {
        stepData.formApi = formApi;

        wizardStore.loadContracts()
            .then((items) => {
                if (!data.pulseContractId && items.length > 0) {
                    formApi.setValue('pulseContractId', items[0].contractId);
                }
            });

        return () => {
            delete stepData.formApi;
        };
    }, []);

    return (
        <StepLayout
            title={lang.LICENSE_MANAGEMENT}
            description={lang.ALLOW_THIS_RESELLER_TO_CREATE}
        >
            <Form
                value={data}
                validate={(data: ResellerData) => formValidate(data, lang, wizardStore)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                    <FormLayout inlineLabel disabled={!wizardStore.pulseConfiguration?.licenseManagement}>
                        <Toggle
                            name={'pulseLicenseCreation'}
                            label={lang.LICENSE_CREATION}
                            showSuffix={true}
                            disabled={!wizardStore.pulseConfiguration?.licenseManagement} // TODO: use disabled from FormLayout
                        />

                        <Combobox
                            name={'pulseContractId'}
                            label={lang.CONTRACT_ID}
                            data={wizardStore.contracts || []}
                            valueGetter={item => item.contractId}
                            textGetter={
                                item => `${item.contractId} (${formatDate(item.expirationDate, normalizeOldDateFormat(formats.netShortDate))})`
                            }
                            disabled={!data.pulseLicenseCreation}
                            size={CONTROL_SIZE.m}
                        />

                        <LabelGroup label={lang.POINTS_QUOTA} disabled={!data.pulseLicenseCreation}>
                            <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                                <ToggleField
                                    name={'pulsePointsLimit'}
                                    showSuffix={true}
                                    disabled={!data.pulseLicenseCreation || !wizardStore.pulseConfiguration?.licenseManagement} // TODO: use disabled from FormLayout
                                />

                                <NumberInput
                                    name={'pulsePointsLimitValue'}
                                    disabled={!data.pulsePointsLimit}
                                    minValue={MIN_PULSE_POINTS_LIMIT}
                                    maxValue={MAX_PULSE_POINTS_LIMIT}
                                />
                            </FormLayout>
                        </LabelGroup>
                    </FormLayout>

                    <NoteBar status={NOTEBAR_STATUS.info}>
                        <StackView style={{ flexShrink: 1 }} direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                            {lang.UPON_ENABLING_THIS_OPTION_THIS_RESELLER}
                        </StackView>
                    </NoteBar>
                </StackView>
            </Form>
        </StepLayout>
    );
});
