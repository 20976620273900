/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import {
    DurationColumn,
    GridColumnJustify,
    isPositiveNumber,
    SizeColumn,
    TextColumn,
} from '@veeam-vspc/components';
import { JobMode } from '@veeam-vspc/models/web-controllers';

import type { VbrAgentBackupJobModel, VbrBackupAgentJobRequestParam } from '@veeam-vspc/models/web-controllers';
import type { CellRendererProps } from '@veeam-vspc/components';

import { JobTypes } from 'core/enums';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { ConfigSectionIds } from 'core/services/configurator/enums';
import { EJobStatuses } from '../../../../enums';
import { ManagedByBackupServerActionToolbar, ManagedByBackupServerFilterToolbar, ObjectsColumn } from './components';
import { BackupStatusColumn } from '../../../BackupStatusColumn';
import { getJobModeNames, getLicenceTypeNames, getOsName } from './utils';

import type { ValScheduleTypeRepresentations } from '../../../../enums';
import type { EJobStates } from 'core/enums';

export interface ManagedByBackupServerProps {
    stateId?: string;
}

export const ManagedByBackupServer: React.FC<ManagedByBackupServerProps> = ({ stateId }) => {
    const { transportService } = useAppServices();
    const lang = useLang();
    const gridStateId = `${stateId}.ManagedByBackupServer`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<VbrAgentBackupJobModel>) => (
        <TextColumn defaultValue={lang.NO_INFO}>{cellData}</TextColumn>
    );
    const rendererIfPolicy = (value: number, jobType: JobTypes): number => jobType === JobTypes.AgentPolicy ? null : value;
    const columns = [
        {
            field: 'status',
            title: lang.BACKUP_STATUS,
            cell: ({ rowData }: CellRendererProps<VbrAgentBackupJobModel>) => (
                <BackupStatusColumn
                    statusName={rowData.statusName as keyof typeof EJobStates}
                    lastSessionStartTime={rowData.lastSessionStartTime}
                    lastSessionEndTime={rowData.lastSessionEndTime}
                    failureMessage={rowData.failureMessage}
                    lastSessionUid={rowData.lastSessionUid}
                />
            ),
        },
        {
            field: 'companyName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'backupServerName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: defaultColumnRender,
        },
        {
            field: 'name',
            title: lang.JOB,
            cell: defaultColumnRender,
        },
        {
            field: 'jobKindName',
            title: lang.JOB_TYPE,
            cell: ({ rowData }: CellRendererProps<VbrAgentBackupJobModel>) => (
                <TextColumn>
                    {rowData.jobKindName}
                </TextColumn>
            ),
        },
        {
            field: 'jobMode',
            title: capitalize(lang.JOB_MODE),
            cell: ({ rowData }: CellRendererProps<VbrAgentBackupJobModel>) => (
                <TextColumn>
                    {getJobModeNames(lang, rowData.jobMode)}
                </TextColumn>
            ),
        },
        {
            field: 'scheduleState',
            title: lang.SCHEDULE,
            cell: ({ cellData }: CellRendererProps<VbrAgentBackupJobModel>) => (
                <TextColumn>
                    {cellData || lang.DISABLED}
                </TextColumn>
            ),
            hidden: true,
        },
        {
            field: 'successfulPercentage',
            title: lang.PROCESSED_OBJECTS,
            width: 200,
            cell: ({ rowData }: CellRendererProps<VbrAgentBackupJobModel>) => (
                <ObjectsColumn
                    stateId={gridStateId}
                    successfulPercentage={rowData.successfulPercentage}
                    successfulJobsCount={rowData.successfullJobsCount}
                    totalJobsCount={rowData.jobsCount}
                    scheduleState={rowData.scheduleState as keyof typeof ValScheduleTypeRepresentations}
                    jobKind={rowData.jobKind}
                    title={`${rowData.name} - ${getJobModeNames(lang, rowData.jobMode)}`}
                    instanceUid={rowData.instanceUid}
                />
            ),
        },
        {
            field: 'transferred',
            title: lang.TRANSFERRED_DATA,
            cell: ({ rowData }: CellRendererProps<VbrAgentBackupJobModel>) => {
                const value = rowData.transferred;

                if (rowData.status === EJobStatuses.Unknown || (rowData.jobMode === JobMode.ManagedByBackupServer && value === -1)) {
                    return <TextColumn>{null}</TextColumn>;
                }

                return (
                    <SizeColumn value={isPositiveNumber(value) ? rendererIfPolicy(value, rowData.jobType) : null} pointFixed={1} />
                );
            },
            justify: GridColumnJustify.Right,
            headerJustify: GridColumnJustify.Right,
            hidden: true,
        },
        {
            field: 'lastSessionDuration',
            title: lang.DURATION,
            cell: ({ rowData }: CellRendererProps<VbrAgentBackupJobModel>) => {
                const value = rowData.lastSessionDuration;

                if (rowData.status === EJobStatuses.Unknown) {
                    return <TextColumn>{null}</TextColumn>;
                }

                return (
                    <DurationColumn value={isPositiveNumber(value) ? rendererIfPolicy(value, rowData.jobType) : null} />
                );
            },
        },
        {
            field: 'lastSessionStart',
            title: lang.LAST_RUN,
            cell: ({ rowData }: CellRendererProps<VbrAgentBackupJobModel>) => (
                <TimeAgoColumn
                    value={rowData.lastSessionStartTime}
                />
            ),
            getSortParam: () => 'lastSessionStartTime',
        },
        {
            field: 'lastSessionStartTime',
            title: lang.LAST_RUN_TIME,
            cell: ({ cellData }: CellRendererProps<VbrAgentBackupJobModel>) => (
                <GridDateColumn
                    value={cellData}
                />
            ),
            hidden: true,
        },
        {
            field: 'osPlatform',
            title: lang.GUEST_OS,
            cell: ({ rowData }: CellRendererProps<VbrAgentBackupJobModel>) => (
                <TextColumn>
                    {getOsName(lang, rowData.osPlatform)}
                </TextColumn>
            ),
            hidden: true,
        },
        {
            field: 'licenseType',
            title: lang.OPERATION_MODE,
            cell: ({ rowData }: CellRendererProps<VbrAgentBackupJobModel>) => (
                <TextColumn>
                    {getLicenceTypeNames(lang, rowData.licenseType)}
                </TextColumn>
            ),
        },
    ];

    return (
        <AdvancedGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.BackupJobsComputersManagedByBackupServer}
            defaultColumnWidth={100}
            columns={columns}
            data={(requestParams: VbrBackupAgentJobRequestParam) => transportService
                .request<VbrBackupAgentJobRequestParam, VbrAgentBackupJobModel[]>(
                    '/BackupServerAgentManagement/GetBackupAgentJobContainers',
                    { ...requestParams },
                )}
            isRowInactive={({ rowData }) => !rowData.scheduleEnabled}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <ManagedByBackupServerFilterToolbar />,
                () => (
                    <ManagedByBackupServerActionToolbar
                        idProperty={'instanceUid'}
                        selectedOnly
                        excludeFromExport={['lastSessionStart']}
                    />
                ),
            ]}
        />
    );
};
