/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    STACK_ALIGN,
    STACK_GAP,
    StackView,
    Text,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    useGridApi,
    ComboboxKit,
} from '@veeam-vspc/components';
import { EPrincipalTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WinPrincipalModel } from '@veeam-vspc/models/web-controllers';

import { useLang } from 'views/providers/LangProvider/hooks';

import type { AccountFilterModel } from '../../../../../interfaces';


export const ToolbarFirst = () => {
    const lang = useLang();
    const gridApi = useGridApi<WinPrincipalModel, any, AccountFilterModel>();

    return (
        <Toolbar
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StackView gap={STACK_GAP.m} align={STACK_ALIGN.center}>
                            <Text>Type:</Text>

                            <ComboboxKit
                                value={gridApi.filters.userType}
                                onChange={userType => gridApi.applyFilters({
                                    userType,
                                })}
                                data={[
                                    { value: EPrincipalTypeRepresentation.WinNTUser, text: lang.USER },
                                    { value: EPrincipalTypeRepresentation.WinNTGroup, text: lang.GROUP },
                                ]}
                                valueGetter={item => item.value}
                                textGetter={item => item.text}
                            />
                        </StackView>
                    ),
                },
            ]}
        />
    );
};
