/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    STACK_ALIGN,
    STACK_GAP,
    StackView,
    Text,
    Toolbar as ToolbarKit,
    TOOLBAR_ITEM_TYPE,
    useGridApi,
    ComboboxKit,
    CONTROL_SIZE,
} from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';

import type {
    VbrLicenseKey,
    VbrLicenseFilterModel,
} from 'views/pages/DiscoveryPage/components/InstallAndUpdateVBRWizard/interfaces';
import type { ToolbarProps } from './toolbar-props';

export const Toolbar: React.FC<ToolbarProps> = ({ data, onDataChange }) => {
    const gridApi = useGridApi<VbrLicenseKey, {}, VbrLicenseFilterModel>();
    const lang = useLang();

    return (
        <ToolbarKit
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StackView gap={STACK_GAP.m} align={STACK_ALIGN.center}>
                            <Text>{`${lang.LICENSE_EDITION}:`}</Text>

                            <ComboboxKit
                                value={data.productUids[0]}
                                onChange={(productUid) => {
                                    const productUids = [productUid];


                                    gridApi.applyFilters({
                                        productUids,
                                    });

                                    data.productUids = productUids;

                                    onDataChange(data);
                                }}
                                data={data.products}
                                valueGetter={item => item.value}
                                textGetter={item => item.text}
                                size={CONTROL_SIZE.l}
                            />
                        </StackView>
                    ),
                },
            ]}
        />
    );
};
