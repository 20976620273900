/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { Search, TOOLBAR_ITEM_TYPE, AdvancedFilter, TOOLBAR_VIEW_TYPE } from '@veeam-vspc/components';

import type { AdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import nasIconSrc from 'images/pages/protected-data/filters/backup-types/nas-backup.png';
import nasBackupCopyIconSrc from 'images/pages/protected-data/filters/backup-types/nas-backup-copy.png';
import unknownIconSrc from 'images/pages/protected-data/filters/backup-types/imported-file-share-backup.png';
import { JobTypes } from 'core/enums';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';

export interface FileSharesLocalToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items'> {
    method?: string;
}

export const FileSharesLocalToolbar = (props: FileSharesLocalToolbarProps) => {
    const { method = 'GetFileShares', ...restProps } = props;
    const lang = useLang();
    const advancedFilterOptions: AdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.BACKUP_TYPE,
                        items: [
                            {
                                label: capitalize(lang.FILE_SHARE_BACKUP, true),
                                value: {
                                    id: JobTypes.Nas,
                                    name: 'types',
                                },
                                iconSrc: nasIconSrc,
                            },
                            {
                                label: lang.FILE_SHARE_BACKUP_COPY,
                                value: {
                                    id: JobTypes.NasBackupCopy,
                                    name: 'types',
                                },
                                iconSrc: nasBackupCopyIconSrc,
                            },
                            {
                                label: lang.IMPORTED_FILE_BACKUP,
                                value: {
                                    id: JobTypes.Unknown,
                                    name: 'types',
                                },
                                iconSrc: unknownIconSrc,
                            },

                        ],
                    },
                ],
            },
        ],
    };

    return (
        <ToolbarWithExport
            {...restProps}
            method={method}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='jobName'
                            placeholder={lang.JOB}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='name'
                            placeholder={lang.SERVER}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <AdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
