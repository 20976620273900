/**
 * Copyright © Veeam Software Group GmbH.
 */

import { AllowedBandwidthUnits } from '../enums';

import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const getBandwidthUnits = (lang: LangsServiceBase) => [
    { name: lang.MBPS, value: AllowedBandwidthUnits.MbitPerSec },
    { name: lang.KBS, value: AllowedBandwidthUnits.KbytePerSec },
    { name: lang.MBS, value: AllowedBandwidthUnits.MbytePerSec },
];

export const getBandwidthUnitsText = (unit: AllowedBandwidthUnits, lang: LangsServiceBase) =>
    getBandwidthUnits(lang).find(item => item.value === unit).name;
