/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { ComboboxKit, constants, FieldLayout } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';

import { Period } from '../../../../enums';
import { useJobsOverviewStore } from '../../../../store';
import { useLang } from 'views/providers/LangProvider/hooks';

export const PeriodFilter: React.FC = observer(() => {
    const jobsOverviewStore = useJobsOverviewStore();
    const lang = useLang();

    return (
        <FieldLayout
            label={`${lang.PERIOD}:`}
        >
            <ComboboxKit
                size={constants.CONTROL_SIZE_XXM}
                value={jobsOverviewStore.selectedPeriod}
                data={[
                    { id: Period.CurrentMonth, name: lang.CURRENT_MONTH },
                    { id: Period.LastMonth, name: lang.PREVIOUS_MONTH },
                    { id: Period.Last30Days, name: lang.LAST_30_DAYS },
                ]}
                valueGetter={item => item.id}
                textGetter={item => item.name}
                onChange={jobsOverviewStore.changePeriod}
            />
        </FieldLayout>
    );
});
