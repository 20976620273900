/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify, GridSortDirections, TextColumn } from '@veeam-vspc/components';

import type { CellRendererProps, RequestParams } from '@veeam-vspc/components';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { TimeAgoColumn, transformTimeToAgoString } from 'views/components/columns/TimeAgoColumn';
import { JobKinds } from 'views/pages/BackupJobsPage/enums';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { ConfigSectionIds } from 'core/services/configurator/enums';
import { BackupJobsColumn } from '../BackupJobsColumn';
import { ComputersFilterToolbar } from './components';

import type { ProtectedComputerItem, ProtectedComputersFilterModel } from '../../interfaces';

export interface ManagedByBackupServerProps {
    stateId: string;
}

export const ManagedByBackupServer: React.FC<ManagedByBackupServerProps> = ({ stateId }) => {
    const { transportService } = useAppServices();
    const lang = useLang();
    const gridStateId = `${stateId}.ManagedByBackupServer`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<ProtectedComputerItem>) => (
        <TextColumn defaultValue={lang.NO_INFO}>{cellData}</TextColumn>
    );
    const columns = [
        {
            field: 'name',
            title: lang.NAME,
            cell: defaultColumnRender,
        },
        {
            field: 'serverName',
            title: lang.SERVER_NAME,
            cell: defaultColumnRender,
        },
        {
            field: 'totalJobsCount',
            title: lang.BACKUPS,
            cell: ({ rowData }: CellRendererProps<ProtectedComputerItem>) => (
                <BackupJobsColumn
                    stateId={`${gridStateId}.Dialog.BackupJobs`}
                    value={rowData.totalJobsCount}
                    title={`${lang.BACKUPS} - ${rowData.name}`}
                    instanceUid={rowData.instanceUid}
                    systemType={rowData.systemType}
                    jobKind={JobKinds.BackupJob}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'totalCopyJobsCount',
            title: lang.BACKUP_COPIES,
            cell: ({ rowData }: CellRendererProps<ProtectedComputerItem>) => (
                <BackupJobsColumn
                    stateId={`${gridStateId}.Dialog.BackupCopyJobs`}
                    value={rowData.totalCopyJobsCount}
                    title={`${lang.BACKUP_COPIES} - ${rowData.name}`}
                    instanceUid={rowData.instanceUid}
                    systemType={rowData.systemType}
                    jobKind={JobKinds.CopyJob}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'guestOs',
            title: lang.GUEST_OS,
            cell: defaultColumnRender,
        },
        {
            field: 'lastRestorePoint',
            title: lang.LATEST_RESTORE_POINT,
            cell: ({ cellData }: CellRendererProps<ProtectedComputerItem>) => (
                <TimeAgoColumn
                    defaultValue={lang.NO_INFO}
                    value={cellData}
                />
            ),
        },
        {
            field: 'lastCloudRestorePoint',
            title: lang.CLOUD_COPY,
            cell: ({ cellData }: CellRendererProps<ProtectedComputerItem>) => {
                if (!cellData) {
                    return <TextColumn defaultValue={lang.NO_INFO}>{lang.NO}</TextColumn>;
                }

                return (
                    <TextColumn>
                        {`${lang.YES} (${transformTimeToAgoString(cellData, lang)})`}
                    </TextColumn>
                );
            },
        },
        {
            field: 'companyName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'operationModeRendered',
            title: lang.OPERATION_MODE,
            cell: defaultColumnRender,
            hidden: true,
        },
    ];

    return (
        <AdvancedGrid
            sectionId={ConfigSectionIds.ProtectedDataComputers}
            stateId={gridStateId}
            defaultColumnWidth={80}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.field,
                },
            }}
            columns={columns.filter(column => column)}
            data={(requestParams: RequestParams<ProtectedComputersFilterModel>) => transportService
                .request<typeof requestParams, ProtectedComputerItem[]>(
                    '/ProtectedWorkloads/GetComputersManagedByVBR',
                    { ...requestParams }
                )}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <ComputersFilterToolbar />,
            ]}
        />
    );
};
