/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ColumnTexts } from '@veeam-vspc/components';

import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { EAgentCommonStatuses, EConnectionStatuses, EpRmmProviderModes, SoftwareStatusRepresentations } from 'core/enums';

import type { AssignTagAllowed, AssignTagItemParams } from '../interfaces';

const isAssignTagAllowed = (value: string, userRole: PortalUserRoles, itemParams: AssignTagItemParams): AssignTagAllowed => {
    const { isVacInstalled, vacAgentInstalled, agentStatus, connectionStatus, managementMode } = itemParams;
    const canEdit = !(
        userRole === PortalUserRoles.LocationUser
        || userRole === PortalUserRoles.PortalReadonlyOperator
        || userRole === PortalUserRoles.ServiceProviderUser
        || userRole === PortalUserRoles.Subtenant
    );

    const forceAllowSetTagForAgentStatus = agentStatus !== undefined && [
        EAgentCommonStatuses.NotSet,
    ].includes(agentStatus);
    const forceAllowSetTagForConnectionStatus = connectionStatus !== undefined && [
        EConnectionStatuses.Unverified,
    ].includes(connectionStatus);
    const forceAllowSetTag = forceAllowSetTagForAgentStatus || forceAllowSetTagForConnectionStatus;

    if (
        (managementMode !== undefined && managementMode === EpRmmProviderModes.ManagedByVBR)
        || (isVacInstalled !== undefined && isVacInstalled !== SoftwareStatusRepresentations.Installed && !forceAllowSetTag)
        || (vacAgentInstalled !== undefined && !vacAgentInstalled)
    ) {
        return {
            canEdit: false,
            value: ColumnTexts.DashSymbol,
        };
    }

    return {
        canEdit,
        value,
    };
};

export {
    isAssignTagAllowed,
};
