/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import {
    Icon,
    TEXT_SIZE,
    LinkButton,
    Text,
    constants,
    StackView,
    STACK_GAP,
    STACK_DIRECTION,
    STACK_ALIGN,
    TEXT_VIEW,
} from '@veeam-vspc/components';

import type { TableCompactItemProps } from './interfaces';

export const TableCompactItem: React.FC<TableCompactItemProps> = props => (
    <StackView
        direction={STACK_DIRECTION.column}
    >
        {props.linkHandler ? (
            <LinkButton
                onClick={props.linkHandler}
                iconBefore={<Icon src={props.icon} />}
                style={{
                    fontSize: constants.FONT_SIZE_L,
                    lineHeight: constants.FONT_LINE_HEIGHT_XL,
                    height: constants.FONT_LINE_HEIGHT_L,
                    width: 'min-content',
                }}
                compact
            >
                {props.name}
            </LinkButton>
        ) : (
            <StackView
                align={STACK_ALIGN.center}
                gap={STACK_GAP.s}
            >
                <Icon src={props.icon} />
                <Text size={TEXT_SIZE.l}>{props.name}</Text>
            </StackView>
        )}

        <Text
            view={TEXT_VIEW.secondary}
            style={{
                marginLeft: constants.SPACING_L,
            }}
        >
            {props.status}
        </Text>
    </StackView>
);
