/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TextColumn } from '@veeam-vspc/components';
import { Vb365Job } from '@veeam-vspc/models/rest';

import type { CellRendererProps } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { useLang } from 'views/providers/LangProvider/hooks';
import { JobStatusColumn, JobTypeColumn, VBObjectsActionToolbar, VBObjectsFilterToolbar } from './components';
import { VBObjectsService } from './services';

export const VBObjects: React.FC = () => {
    const lang = useLang();

    const defaultColumnRender = ({ cellData }: CellRendererProps<Vb365Job>) => <TextColumn>{cellData}</TextColumn>;

    const columns = [
        {
            field: 'name',
            title: lang.JOB,
            cell: defaultColumnRender,
        },
        {
            field: 'vspcOrganizationName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
        },
        {
            field: 'vb365ServerName',
            title: lang.SERVER,
            cell: defaultColumnRender,
        },
        {
            field: 'jobType',
            title: lang.JOB_TYPE,
            cell: JobTypeColumn,
        },
        {
            field: 'lastRun',
            title: lang.LAST_RUN,
            cell: ({ rowData }: CellRendererProps<Vb365Job>) => (
                <TimeAgoColumn
                    value={rowData.lastRun}
                />
            ),
        },
        {
            field: 'lastStatus',
            title: lang.JOB_STATUS,
            cell: JobStatusColumn,
        },
        {
            field: 'repositoryName',
            title: lang.REPOSITORY,
            cell: defaultColumnRender,
        },
        {
            field: 'nextRun',
            title: lang.NEXT_RUN,
            cell: ({ rowData }: CellRendererProps<Vb365Job>) => (
                <GridDateColumn
                    value={rowData.nextRun}
                />
            ),
        },
    ];

    return (
        <AdvancedGrid
            columns={columns}
            stateId='BackupJobsPage.VBObjects'
            data={VBObjectsService.getJobs}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <VBObjectsFilterToolbar />,
                () => <VBObjectsActionToolbar />,
            ]}
            defaultColumnWidth={150}
            isRowInactive={({ rowData }) => !rowData.isEnabled || rowData.lastStatus === Vb365Job.LastStatusEnum.Deleting}
        />
    );
};
