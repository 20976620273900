/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { LoginBaseLayout } from '@veeam-vspc/components';

import { useAppStore } from 'views/providers/AppProvider/hooks';
import { useImageLoader } from 'views/hooks';
import { useLoginStore } from '../../hooks';

export interface Props {
    children: React.ReactElement;
}

export const LoginBackground = observer(({ children }: Props) => {
    const appStore = useAppStore();
    const loginStore = useLoginStore();
    const { image: brandImage } = useImageLoader(loginStore.productImageSrc);
    const { image: brandLogo } = useImageLoader(loginStore.productLogoImageSrc);

    return (
        <LoginBaseLayout
            minWidth={appStore.appMinWidth}
            minHeight={appStore.appMinHeight}
            productBrandImage={brandImage}
            productIconLogo={brandLogo}
            title={appStore.portalName}
        >
            {children}
        </LoginBaseLayout>
    );
});
