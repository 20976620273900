/**
 * Copyright © Veeam Software Group GmbH.
 */

import { CountTypes } from '@veeam-vspc/models/web-controllers';

import { core } from 'core/core-module';
import { transformBaseChartData } from 'views/pages/TrendsPage/helpers';

import type { BaseDataResponse } from 'core/services/transport/interfaces/base-data-response';
import type { LineChartData, TrendData } from '../../../../../interfaces';

const names = [
    { label: 'Workloads', type: CountTypes.SimpleNumber },
];

export class ProtectedAwsWorkloadsService {
    static fetchData({ backupServicesType, objectsType, reportingPeriodInfo }): Promise<LineChartData> {
        return core.transportService
            .request('Trends/ProtectedAmazonObjects', {
                ...reportingPeriodInfo,
                backupServicesType,
                objectsType,
            })
            .then((resp: BaseDataResponse<TrendData>) => transformBaseChartData(resp.data.trends, reportingPeriodInfo, names));
    }
}
