/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { PortalSpinner } from '@veeam-vspc/components';
import { TokenManagerTextKeys, TransportTextKeys, NotificationDialogsTextKeys } from '@veeam-vspc/core';

import { useAppServices, useAppStore } from '../AppProvider/hooks';
import { LangContext } from './stores';
import { useLangService } from './hooks';

export interface LangProviderProps {
    children: React.ReactElement;
}

/**
 * @description Should be under ThemeProvider because of PortalSpinner
 */
export const LangProvider = observer(({ children }: LangProviderProps) => {
    const appStore = useAppStore();
    const { langService, notificationService, tokenManagerService, transportService } = useAppServices();
    const { lang, locale, hasLang } = useLangService(langService, appStore.locale);
    const isLoading = locale !== appStore.locale;

    if (hasLang && !isLoading) {
        notificationService.setTexts({
            [NotificationDialogsTextKeys.Ok]: lang.OK,
            [NotificationDialogsTextKeys.Yes]: lang.YES,
            [NotificationDialogsTextKeys.No]: lang.NO,
            [NotificationDialogsTextKeys.Cancel]: lang.CANCEL,
            [NotificationDialogsTextKeys.Close]: lang.CLOSE,
        });
        tokenManagerService.setTexts({
            [TokenManagerTextKeys.WebServerErrorTitle]: lang.CONNECTION_ERROR,
            [TokenManagerTextKeys.WebServerErrorMessage]: lang.WEB_SERVER_IS_NOT_RESPONDING,
            [TokenManagerTextKeys.AuthErrorTitle]: lang.SESSION_EXPIRED,
            [TokenManagerTextKeys.AuthErrorMessage]: lang.PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING,
            [TokenManagerTextKeys.AuthButtonMessage]: lang.LOG_IN,
        });
        transportService.config.setTexts({
            [TransportTextKeys.DownloadErrorMessage]: lang.FAILED_TO_DOWNLOAD_LOG_FILES,
            [TransportTextKeys.ErrorTitle]: lang.ERROR,
        });
    }

    return (
        <LangContext.Provider value={lang}>
            {hasLang && children}
            {isLoading && <PortalSpinner />}
        </LangContext.Provider>
    );
});
