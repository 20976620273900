/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts, isPositiveNumber } from '@veeam-vspc/components';

import { RestorePointsDialog } from './components/RestorePointsDialog/RestorePointsDialog';

import type { RestorePointsColumnProps } from './interfaces/RestorePointsColumnProps';

export const RestorePointsColumn: React.FC<RestorePointsColumnProps> = ({ value, ...restProps }) => (
    <ActionLinkColumn
        content={isPositiveNumber(value) ? value : ColumnTexts.DashSymbol}
        isLink={isPositiveNumber(value)}
        isExtMode={true}
        renderModal={({ deactivate }) => (
            <RestorePointsDialog
                deactivate={deactivate}
                {...restProps}
            />
        )}
    />
);


