/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    CheckboxKit,
    Combobox,
    CONTROL_SIZE, dataSizeToString,
    FieldLayout,
    Form,
    FormLayout,
    FormValidator,
    isValid,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TextInput,
} from '@veeam-vspc/components';
import { formatStr } from '@veeam-vspc/core';

import type {
    FormErrors,
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { useCompanyWizardStore } from '../../hooks';
import { DataSizeInBytes } from 'components/controls/DataSizeInBytes';

import type { CompanyUserStore } from '../../stores';
import type { CompanyUserModel } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/company-user-model';

const MIN_QUOTA = 1;
const MAX_QUOTA = 999 * Math.pow(1024, 2); // 999 PB

export const formValidate = (data: CompanyUserModel, lang): FormErrors<CompanyUserModel> => {
    const validator = new FormValidator(data);

    validator.validate('repositoryFriendlyName').string()
        .required();

    validator.validate('tenantRepositoryId').string()
        .required();

    if (!data.isUnlimited) {
        validator.validate('repositoryQuota').number()
            .required()
            .min(MIN_QUOTA)
            .max(MAX_QUOTA, `${lang.THE_MAXIMUM_VALUE_FOR_THIS_FIELD_IS} ${dataSizeToString(MAX_QUOTA, 0, 'G')}`);
    }

    return validator.result();
};

export const Quota: React.FC<WizardStepData<CompanyUserModel>> = ({ data, onDataChange, validationState }) => {
    const lang = useLang();
    const additionalWizardStore = useCompanyWizardStore();
    const normalizeCapacity = (repositoryCapacity: number) => {
        const unitList = [
            {
                name: RCOP.Lang.GB,
                multiplier: Math.pow(1024, 1),
            }, {
                name: RCOP.Lang.TB,
                multiplier: Math.pow(1024, 2),
            }, {
                name: RCOP.Lang.PB,
                multiplier: Math.pow(1024, 3),
            },
        ];

        let count = 0;
        let unit;

        if (repositoryCapacity === null) {
            return '';
        }

        for (let i = unitList.length - 1; i >= 0; i--) {
            const item = unitList[i];
            const { multiplier, name } = item;

            if (repositoryCapacity % multiplier === 0) {
                count = repositoryCapacity / multiplier;
                unit = name;
                break;
            }
        }

        if (!unit) {
            count = repositoryCapacity / 1024;
            unit = RCOP.Lang.GB;
        }

        return `${count} ${unit}`;
    };
    const tenantRepository = data.repositories.find(repository => repository.id === data.tenantRepositoryId);
    const repoCapacityStr = normalizeCapacity(tenantRepository?.repositoryQuota);

    return (
        <Form
            value={data}
            validate={value => formValidate(value, lang)}
            validationState={validationState}
            onChange={(newData) => {
                additionalWizardStore.dialogMsgHasBeenShown = false;
                onDataChange(newData);
            }}
        >
            <StepLayout
                title={lang.QUOTA}
                description={lang.SET_CLOUD_REPOSITORY_QUOTA_FOR_THE_USER}
            >
                <FormLayout inlineLabel>
                    <FieldLayout label={`${lang.CLOUD_REPOSITORY_NAME}:`}>
                        <TextInput name='repositoryFriendlyName' />
                    </FieldLayout>

                    <FieldLayout label={`${lang.BACKUP_REPOSITORY}:`}>
                        <Combobox
                            name='tenantRepositoryId'
                            data={data.repositories}
                            valueGetter={item => item.id}
                            textGetter={item => item.repositoryFriendlyName}
                            size={CONTROL_SIZE.m}
                        />
                    </FieldLayout>

                    {data.tenantRepositoryId && (
                        <FieldLayout label={formatStr(lang.REPOSITORY_CAPACITY_SOME, repoCapacityStr)}>
                            <Text />
                        </FieldLayout>
                    )}

                    <FieldLayout label={`${lang.USER_QUOTA}:`}>
                        <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.m}>
                            <DataSizeInBytes
                                disabled={data.isUnlimited}
                                unitType={'G'}
                                name={'repositoryQuota'}
                                maxUnit={'P'}
                                minValue={MIN_QUOTA}
                                maxValue={MAX_QUOTA}
                            />

                            <CheckboxKit
                                checked={data.isUnlimited}
                                onChange={(isChecked) => {
                                    data.isUnlimited = isChecked;

                                    onDataChange(data);
                                }}
                            >
                                {lang.UNLIMITED}
                            </CheckboxKit>
                        </StackView>
                    </FieldLayout>
                </FormLayout>
            </StepLayout>
        </Form>
    );
};

const stepValidate = async(data: CompanyUserModel, additionalStore: CompanyUserStore, lang) => {
    const valid = isValid(value => formValidate(value, lang), data);

    if (!additionalStore.dialogMsgHasBeenShown && valid) {
        const repository = data.repositories.find(repository => repository.id === data.tenantRepositoryId);
        const repositoryQuotaMb = repository.repositoryQuota;
        const repositoryQuotaGb = Math.round(repositoryQuotaMb / 1024);
        const selectedQuotaMb = !data.isUnlimited && data.repositoryQuota * 1024;
        const text = formatStr(
            RCOP.Lang.QUOTA_FOR_THIS,
            repositoryQuotaGb,
        );

        if (selectedQuotaMb > repositoryQuotaMb) {
            core.notificationService.info(RCOP.Lang.QUOTA, text);
            additionalStore.dialogMsgHasBeenShown = true;
        }
    }

    return valid;
};

export const getQuotaStep = (title: string, additionalStore: CompanyUserStore, lang): WizardStep<CompanyUserModel> => ({
    title,
    isHidden: ({ data }) => data.userRole !== PortalUserRoles.Subtenant,
    validate: ({ data }) => stepValidate(data, additionalStore, lang),
    render: data => <Quota {...data} />,
});
