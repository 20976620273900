/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { AdvancedFilter, Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE } from '@veeam-vspc/components';

import type { AdvancedFilterOptions, ToolbarProps, AdvancedFilterValue } from '@veeam-vspc/components';

import backupJobSTypeIconSrc from 'images/filters/job-stypes/backup-servers.svg';
import cloudConnectJobSTypeIconSrc from 'images/filters/job-stypes/cloud-connect.svg';
import vSpherePlatformTypeIconSrc from 'images/filters/virtual-platform-types/vsphere.png';
import hyperVPlatformTypeIconSrc from 'images/filters/os/windows.svg';
import nutanixPlatformTypeIconSrc from 'images/filters/virtual-platform-types/nutanix.png';
import localTargetTypeIconSrc from 'images/filters/fplan-types/local.png';
import cloudTargetTypeIconSrc from 'images/filters/fplan-types/cloud.png';
import { EJobStates, JobTargetKinds, VirtualPlatformTypeRepresentations } from 'core/enums';
import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedToolbar } from 'views/components/AdvancedToolbar';
import { StatusToggleFilter } from 'views/components/filters/StatusToggleFilter';
import { useJobTypesAdvancedFilter } from 'views/components/filters/JobTypesFilters';
import { useAppStore } from 'views/providers/AppProvider/hooks';

export interface VirtualInfrastructureFilterToolbarProps extends Omit<ToolbarProps, 'items'> {
}

export const VirtualInfrastructureFilterToolbar: React.FC<VirtualInfrastructureFilterToolbarProps> = (props) => {
    const lang = useLang();
    const { portalUser } = useAppStore();
    const statuses = [
        { title: lang.SUCCESS, value: EJobStates.Success },
        { title: lang.WARNING, value: EJobStates.Warning },
        { title: lang.FAILED, value: EJobStates.Failed },
        { title: lang.RUNNING, value: EJobStates.Running },
        {
            title: lang.INFORMATION,
            value: [
                EJobStates.None,
                EJobStates.Idle,
                EJobStates.Starting,
                EJobStates.Stopping,
                EJobStates.Enabling,
                EJobStates.Disabling,
                EJobStates.WaitingTape,
                EJobStates.WaitingRepository,
            ],
        },
    ];
    const jobTypesAdvancedFilter = useJobTypesAdvancedFilter();
    const isBackupServerTypeFilterHidden = portalUser.isPortalOperator()
        || portalUser.isPortalReadonlyOperator()
        || portalUser.isServiceProviderGlobalOrUsualAdmin()
        || portalUser.isServiceProviderOperator()
        || portalUser.isServiceProviderUser()
        || portalUser.isCompanyOwner()
        || portalUser.isLocationAdministrator()
        || portalUser.isCompanyAdministrator()
        || portalUser.isLocationUser();
    const advancedFilterOptions: AdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_JOBS_BY}:`,
        cols: [
            {
                groups: [
                    jobTypesAdvancedFilter,
                ],
            },
            {
                groups: [
                    !isBackupServerTypeFilterHidden && {
                        title: lang.BACKUP_SERVER_TYPE,
                        items: [
                            {
                                iconSrc: backupJobSTypeIconSrc,
                                label: lang.VEEAM_BACKUP_AND_REPLICATION,
                                value: {
                                    id: 1,
                                    name: 'serverTypes',
                                },
                            },
                            {
                                iconSrc: cloudConnectJobSTypeIconSrc,
                                label: lang.VEEAM_CLOUD_CONNECT,
                                value: {
                                    id: 2,
                                    name: 'serverTypes',
                                },
                            },
                        ],
                    },
                    !isBackupServerTypeFilterHidden && {
                        title: lang.PLATFORM_TYPE,
                        items: [
                            {
                                iconSrc: vSpherePlatformTypeIconSrc,
                                label: lang.VSPHERE,
                                value: {
                                    id: VirtualPlatformTypeRepresentations.VSphere,
                                    name: 'virtualPlatformTypes',
                                },
                            },
                            {
                                iconSrc: hyperVPlatformTypeIconSrc,
                                label: lang.HYPER_V,
                                value: {
                                    id: VirtualPlatformTypeRepresentations.HyperV,
                                    name: 'virtualPlatformTypes',
                                },
                            },
                            {
                                iconSrc: nutanixPlatformTypeIconSrc,
                                label: lang.AHV,
                                value: {
                                    id: VirtualPlatformTypeRepresentations.NutanixAHV,
                                    name: 'virtualPlatformTypes',
                                },
                            },
                        ],
                    },
                    {
                        title: lang.BACKUP_TARGET,
                        items: [
                            {
                                iconSrc: localTargetTypeIconSrc,
                                label: lang.LOCAL,
                                value: {
                                    id: JobTargetKinds.Local,
                                    name: 'targetKind',
                                },
                            },
                            {
                                iconSrc: cloudTargetTypeIconSrc,
                                label: lang.OFFSITE,
                                value: {
                                    id: JobTargetKinds.Cloud,
                                    name: 'targetKind',
                                },
                            },
                        ],
                    },
                ].filter(group => group),
            },
        ],
    };

    return (
        <AdvancedToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='jobName'
                            placeholder={lang.JOB}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='serverName'
                            placeholder={lang.SERVER}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StatusToggleFilter
                            statuses={statuses}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.separator,
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <AdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
