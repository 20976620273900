/**
 * Copyright © Veeam Software Group GmbH.
 */
import { capitalize } from '@veeam-vspc/core';

import { CompanyVboRepositoryStorageType } from '../../enums';

import type { LangsServiceBase } from '../../services/langs/interfaces';

export const getVb365RepositoryStorageTypes = (lang: LangsServiceBase): { id: CompanyVboRepositoryStorageType; name: string; }[] => ([
    {
        id: CompanyVboRepositoryStorageType.ObjectStorage,
        name: capitalize(lang.OBJECT_STORAGE, true),
    },
    {
        id: CompanyVboRepositoryStorageType.ArchiveObjectStorage,
        name: lang.ARCHIVE_OBJECT_STORAGE,
    },
    {
        id: CompanyVboRepositoryStorageType.JetDatabase,
        name: lang.JET_DATABASE,
    },
]);
