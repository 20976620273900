/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';

import { JobsOverview } from './components/JobsOverview';

import type { JobsOverviewProps } from './components/JobsOverview/interfaces';

export const JOBS_SUMMARY = 'JobsSummary';

export const JobsSummaryPage: React.FC<JobsOverviewProps> = props => (
    <JobsOverview {...props} />
);
