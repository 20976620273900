/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify, GridSortDirections, PageDialog, TextColumn } from '@veeam-vspc/components';
import { PublicCloudVmRestoreType } from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/core';

import type { CellRendererProps, PageDialogProps } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { PublicCloudsDialogToolbar } from '../PublicCloudsDialogToolbar';
import {
    getBackupSizeColumnHeader,
    getDestinationColumnHeader,
    getDialogTitle,
    getJobNameColumnHeader,
    getRestorePointColumnHeader,
    getSourceSizeColumnHeader,
} from '../../utils';
import { PublicCloudsRestorePointColumn } from '../../../PublicCloudsRestorePointColumn';

import type {
    PublicCloudsDialogModel,
    PublicCloudsDialogExtraParams,
    PublicCloudsDialogFilterModel,
    PublicCloudsDialogRequestParams,
} from '../../interfaces';

export interface PublicCloudsDialogProps extends Omit<PageDialogProps, 'title' | 'content'> {
    stateId: string;
    name: string;
    extraParams: PublicCloudsDialogExtraParams;
}

const { Snapshot, RemoteSnapshot } = PublicCloudVmRestoreType;

export const PublicCloudsDialog: React.FC<PublicCloudsDialogProps> = (props) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const idProperty = 'backupUid';
    const {
        description,
        disabledBack,
        hasBack,
        footer,
        onClose,
        stateId,
        name,
        extraParams,
    } = props;
    const { backupType, platformType, backupServerUid } = extraParams;
    const isSnapshot = [Snapshot, RemoteSnapshot].includes(backupType);
    const columns = [
        {
            field: 'jobName',
            title: getJobNameColumnHeader(backupType, lang),
            cell: ({ cellData }: CellRendererProps<PublicCloudsDialogModel>) => <TextColumn>{cellData}</TextColumn>,
        },
        {
            field: 'lastRun',
            title: lang.LAST_JOB_RUN,
            cell: ({ cellData }: CellRendererProps<PublicCloudsDialogModel>) => (
                <TimeAgoColumn value={cellData} />
            ),
        },
        {
            field: 'sourceSize',
            title: getSourceSizeColumnHeader(backupType, lang),
            cell: ({ cellData }: CellRendererProps<PublicCloudsDialogModel>) => <RestorePointSizeColumn value={cellData as any} />,
        },
        {
            field: 'restorePoints',
            title: getRestorePointColumnHeader(backupType, lang),
            cell: ({ cellData, rowData }: CellRendererProps<PublicCloudsDialogModel>) => (
                <PublicCloudsRestorePointColumn
                    value={cellData}
                    stateId={`${stateId}.${PublicCloudVmRestoreType[backupType]}`}
                    name={rowData.jobName}
                    extraParams={{
                        backupServerUid,
                        vmUid: rowData.vmInstanceUid,
                        backupUid: rowData.backupUid,
                        platformType,
                        backupType,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        !isSnapshot && {
            field: 'backupSize',
            title: getBackupSizeColumnHeader(backupType, lang),
            cell: ({ cellData }: CellRendererProps<PublicCloudsDialogModel>) => <RestorePointSizeColumn value={cellData as any} />,
        },
        {
            field: 'destination',
            title: getDestinationColumnHeader(backupType, lang),
        },
        {
            field: 'backupServer',
            title: capitalize(lang.BACKUP_SERVER),
        },
    ];

    return (
        <PageDialog
            title={`${getDialogTitle(backupType, lang)} - ${name}`}
            description={description}
            disabledBack={disabledBack}
            hasBack={hasBack}
            footer={footer}
            onClose={onClose}
            content={(
                <AdvancedGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: columns?.[0]?.field,
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={columns.filter(item => item)}
                    data={(requestParams: PublicCloudsDialogRequestParams<PublicCloudsDialogFilterModel>) => transportService
                        .request<typeof requestParams, PublicCloudsDialogModel[]>(
                            '/PublicCloudVm/GetProtectedVmBackups',
                            {
                                ...requestParams,
                                ...extraParams,
                            },
                        )}
                    toggleable={false}
                    selection={{
                        field: idProperty,
                        checkbox: false,
                        multiple: false,
                    }}
                    toolbars={[
                        () => (
                            <PublicCloudsDialogToolbar
                                idProperty={idProperty}
                                extraParams={extraParams}
                                backupType={backupType}
                            />
                        ),
                    ]}
                />
            )}
        />
    );
};
