/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable } from 'mobx';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { DataCache } from 'core/utils/data-cache';
import { core } from 'core/core-module';

import type { CurrencyItem } from 'core/interfaces';

export class SubscriptionPlanStore {
    @observable currencies: CurrencyItem[] = [];

    @action.bound
    setCurrency(currencies: CurrencyItem[]) {
        this.currencies = currencies;
    }

    readonly currenciesListCache = new DataCache< CurrencyItem[], void>(
        () => core.transportService.request('SubscriptionPlan/GetCurrency')
            .then((resp: BaseSuccessRequestResponse<CurrencyItem[]>) => resp.data)
    );
}
