/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { WizardStep } from '@veeam-vspc/components';

import { PageWizard } from 'components/wizards/PageWizard';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { ManagedCompaniesMode } from 'views/pages/RolesAndUsersPage/components/SPUser/enums/managed-companies-mode';
import { saveUser } from 'views/pages/RolesAndUsersPage/components/Base/helpers/saveUser';
import { useLang } from 'views/providers/LangProvider/hooks';
import { getLoginInfoStep } from '../Base/components/LoginInfo';
import { getUserInfoStep } from '../Base/components/UserInfo';
import { getMultiFactorAuthStep } from '../Base/components/MultiFactorAuthentication';
import { getCompaniesStep } from './components/Companies';
import { getRoleStep } from './components/Role';
import { getSummaryStep } from './components/Summary';

import type { SPUserModel, SPUserProps } from './interfaces';

export const SPUser = (props: SPUserProps) => {
    const lang = useLang();
    const data: SPUserModel = {
        description: '',
        firstName: '',
        lastName: '',
        email: '',
        login: '',
        userRole: PortalUserRoles.ServiceProviderAdministrator,
        sendInvoice: false,
        companies: [],
        selectAllCompanies: props.model?.userCompanyIds?.length > 0 ? ManagedCompaniesMode.CustomSelection : ManagedCompaniesMode.AllCompanies,
        ...props.model,
        password: '',
        confirmPassword: '',
        oldPassword: props.selfEdit ? '' : undefined,
        selfEdit: props.selfEdit,
    };
    const title = props.isEdit ? lang.EDIT_LOCAL_USER : lang.NEW_LOCAL_USER;

    return (
        <PageWizard
            title={title}
            deactivate={() => undefined}
            isEdit={props.isEdit}
            data={data}
            steps={[
                getUserInfoStep(lang.USER_INFO),
                getLoginInfoStep(lang.LOGIN_INFO),
                getRoleStep(lang.ROLE),
                getCompaniesStep(lang.COMPANIES),
                getMultiFactorAuthStep(lang.MULTI_FACTOR_AUTHENTICATION),
                getSummaryStep(lang.SUMMARY),
            ] as WizardStep<SPUserModel>[]}
            onFinish={({ data }) => {
                const requestParams: SPUserModel = {
                    ...data,
                    userCompanyIds: data.selectAllCompanies === ManagedCompaniesMode.CustomSelection
                        ? data.companies.map(company => company.id)
                        : undefined,
                };

                if (data.userRole === PortalUserRoles.ServiceProviderOperator || data.userRole === PortalUserRoles.ServiceProviderUser) {
                    requestParams.assignAllResellerCompanies = data.selectAllCompanies === ManagedCompaniesMode.AllCompanies;
                    requestParams.userCompanyIds = data.selectAllCompanies === ManagedCompaniesMode.CustomSelection
                        ? data.companies.map(company => company.id)
                        : undefined;
                }

                return saveUser({ props, requestParams });
            }}
        />
    );
};
