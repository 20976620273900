/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    NumberInput,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';
import { capitalize, deepCopy } from '@veeam-vspc/core';

import { useLang } from 'views/providers/LangProvider/hooks';
import { MAX_INTEGER_32 } from 'core/const';
import { WanAcceleration } from 'views/components/WanAcceleration';
import { useResellerWizardStore } from '../../../../../../hooks';

import type { ResellerReplicaResource } from '../../../../../../interfaces';

interface Props {
    deactivate: () => void;
    onConfirm: (data: ResellerReplicaResource) => void;

    data?: ResellerReplicaResource;
    existingItems: ResellerReplicaResource[];
    cloudConnectAgentUids: string[];
    wanAccelerationIsEnabled: boolean;
}

const MIN_TENANTS_PER_PLAN = 1;
const MAX_TENANTS_PER_PLAN = MAX_INTEGER_32;

const formValidate = (data: ResellerReplicaResource, lang: any, isEdit: boolean, existingItems: ResellerReplicaResource[]) => {
    const validator = new FormValidator(data);

    validator.validate('cloudConnectResourceUid')
        .string()
        .required();

    validator.validate('tenantsPerPlan')
        .number()
        .required()
        .min(MIN_TENANTS_PER_PLAN)
        .max(MAX_TENANTS_PER_PLAN);

    if (data.isWanAccelerationEnabled) {
        validator.validate('wanAcceleratorUid')
            .string()
            .required();
    }

    return validator.result();
};

const getDefaultData = (): ResellerReplicaResource => ({
    cloudConnectResourceName: '',
    isWanAccelerationEnabled: false,
    cloudConnectResourceUid: null,
    storageGb: 0,
    cpuUsageLimitMhz: 0,
    cloudConnectAgentUid: null,
    ramUsageLimitMb: 0,
    tenantsPerPlan: 1,
});

export const ResourceDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;

    const isEdit = !!props.data;
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.cloudConnectResourceUid === props.data.cloudConnectResourceUid), 1);
        }
        return result;
    });
    const [filteredAvailableReplicaResources, setFilteredAvailableReplicaResources] = useState([]);
    const [data, setData] = useState(isEdit ? deepCopy(props.data) : getDefaultData());
    const [filteredWanAccelerators, setFilteredWanAccelerators] = useState([]);
    const [cloudConnectAgentUid, setCloudConnectAgentUid] = useState<string>(data.cloudConnectAgentUid);

    const formApi = useExternalFormApi<ResellerReplicaResource>();
    const [validationState] = useState(new ValidationState());

    const wizardStore = useResellerWizardStore();

    useEffect(() => {
        wizardStore.availableReplicaResourcesCache.load({ cloudConnectAgentUids: props.cloudConnectAgentUids })
            .then((items) => {
                setFilteredAvailableReplicaResources(items.filter(item => !existingItems
                    .find(existingItem => existingItem.cloudConnectResourceUid === item.cloudConnectResourceUid)
                ));
            });
    }, []);

    useEffect(() => {
        if (props.wanAccelerationIsEnabled) {
            wizardStore.wanAcceleratorsCache.load({ cloudConnectAgentUids: props.cloudConnectAgentUids });
        }
    }, []);

    useEffect(() => {
        if (wizardStore.wanAcceleratorsCache.value && cloudConnectAgentUid) {
            setFilteredWanAccelerators(wizardStore.wanAcceleratorsCache.value
                ?.filter(item => item.cloudConnectAgentUid === cloudConnectAgentUid));
        }
    }, [wizardStore.wanAcceleratorsCache.value, cloudConnectAgentUid]);

    return (
        <Dialog
            header={lang.CLOUD_REPLICATION_RESOURCE}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            props.onConfirm && props.onConfirm(data);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={wizardStore.availableReplicaResourcesCache.loading}
        >
            <Form <ResellerReplicaResource>
                value={data}
                validate={data => formValidate(data, lang, isEdit, existingItems)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'cloudConnectResourceUid'}
                        label={capitalize(RCOP.Lang.HARDWARE_PLAN, true)}
                        placeholder={lang.SELECT_A_HARDWARE_PLAN}
                        data={filteredAvailableReplicaResources}
                        valueGetter={item => item.cloudConnectResourceUid}
                        textGetter={item => item.cloudConnectResourceName}
                        size={defaultFieldWidth}
                        disabled={data.companyMap?.length > 0}
                        onChange={(value, itemIndex) => {
                            const item = filteredAvailableReplicaResources[itemIndex];
                            formApi.setValue('cloudConnectResourceName', item.cloudConnectResourceName);
                            formApi.setValue('cpuUsageLimitMhz', item.cpuUsageLimitMhz);
                            formApi.setValue('ramUsageLimitMb', item.ramUsageLimitMb);
                            formApi.setValue('storageGb', item.storageGb);
                            formApi.setValue('cloudConnectAgentUid', item.cloudConnectAgentUid);
                            setCloudConnectAgentUid(item.cloudConnectAgentUid);
                        }}
                    />

                    <FormLayout inlineLabel>
                        <NumberInput
                            name={'tenantsPerPlan'}
                            label={lang.MAX_TENANTS_PER_PLAN}
                            minValue={MIN_TENANTS_PER_PLAN}
                            maxValue={MAX_TENANTS_PER_PLAN}
                        />
                    </FormLayout>

                    <WanAcceleration
                        value={data}
                        wanAccelerators={filteredWanAccelerators}
                        hidden={!props.wanAccelerationIsEnabled}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
});
