/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { DayType } from '../../enums';
import { useJobsOverviewStore } from '../../store';
import { NoData } from './components/NoData';
import { DayWithJobs } from './components/DayWithJobs';
import { DayWithoutJobs } from './components/DayWithoutJobs';
import { OutOfPeriod } from './components/OutOfPeriod';

import type { DayProps } from '../../interfaces/day-props';

export const Day: React.FC<DayProps> = ({ id }) => {
    const store = useJobsOverviewStore();
    const record = store.findById(id);

    switch (record.dayType) {
        case DayType.NoData:
            return <NoData id={id} />;
        case DayType.DayWithJobs:
            return <DayWithJobs id={id} />;
        case DayType.DayWithoutJobs:
            return <DayWithoutJobs id={id} />;
        case DayType.OutOfPeriod:
            return <OutOfPeriod id={id} />;
    }
};
