/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Combobox,
    Form,
    FormValidator,
    isValid,
} from '@veeam-vspc/components';

import type {
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { IdentityProviderModel } from './identity-provider-model';


export const stepValidate = (data: IdentityProviderModel) => {
    const validator = new FormValidator(data);

    validator.validate('identityProviderName').string()
        .required();

    return validator.result();
};

export const IdentityProvider = ({ data, validationState, onDataChange, isEdit }: WizardStepData<IdentityProviderModel>) => {
    const lang = useLang();

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={lang.IDENTITY_PROVIDER}
                description={lang.SELECT_THE_IDENTITY_PROVIDER_TO_USE}
            >
                <Combobox
                    name='identityProviderName'
                    data={data.providers}
                    valueGetter={item => item.value}
                    textGetter={item => item.text}
                    disabled={isEdit}
                    label={lang.PROVIDER}
                    inlineLabel
                />
            </StepLayout>
        </Form>
    );
};

export const getIdentityProviderStep = (title: string): WizardStep<IdentityProviderModel> => ({
    title,
    validate: ({ data }) => isValid(stepValidate, data),
    isHidden: ({ data, isEdit }) => !isEdit && data.providers.length === 1,
    render: data => <IdentityProvider {...data} />,
    id: 'identityProvider',
});
