/**
 * Copyright © Veeam Software Group GmbH.
 */

export const OVERVIEW_PAGE = 'OverviewPage';

export const DASHBOARD_MAP_HEIGHT = 456;

export const WORLD_MAP_ID = 'all';

export const TOGGLE_FILTER_ALL_VALUE = 'all';
