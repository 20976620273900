/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { CONTROL_SIZE_XXS_PLUS } from '@veeam-vspc/components/src/constants';
import {
    ComboboxKit,
    FieldLayout,
    NumberInputKit,
    STACK_DIRECTION,
    useFormApi,
    CONTROL_SIZE,
    STACK_GAP,
    StackView,
    ValidationStatus,
    immediatelyValidateFlag,
} from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';

export type DataSizeUnit = 'B' | 'K' | 'M' | 'G' | 'T' | 'P' | 'E' | 'Z' | 'Y';

interface Props {
    as?: any;
    label?: string;
    name: string;
    suffix?: string;
    suffix2?: string;
    unitSize?: CONTROL_SIZE;
    allowDecimal?: boolean;
    onChange?: () => void;
    disabled?: boolean;
    unitType?: DataSizeUnit;
    minValue?: number;
    maxValue?: number;
    minUnit?: DataSizeUnit;
    maxUnit?: DataSizeUnit;
}

export const DataSizeInBytes = ({
    as,
    label,
    name,
    suffix,
    suffix2,
    unitSize,
    allowDecimal,
    onChange,
    disabled,
    unitType = 'B',
    minValue = 0,
    maxValue,
    minUnit = 'G',
    maxUnit = 'T',
}: Props) => {
    const formsState = useFormApi();
    const lang = useLang();
    const labelDelimiter = ':';

    const fullUnitList: { name: string; multiplier: number; type: DataSizeUnit; }[] = [
        {
            name: lang.B || 'B',
            multiplier: Math.pow(1024, 0),
            type: 'B',
        }, {
            name: lang.KB || 'KB',
            multiplier: Math.pow(1024, 1),
            type: 'K',
        }, {
            name: lang.MB || 'MB',
            multiplier: Math.pow(1024, 2),
            type: 'M',
        }, {
            name: lang.GB || 'GB',
            multiplier: Math.pow(1024, 3),
            type: 'G',
        }, {
            name: lang.TB || 'TB',
            multiplier: Math.pow(1024, 4),
            type: 'T',
        }, {
            name: lang.PB || 'PB',
            multiplier: Math.pow(1024, 5),
            type: 'P',
        },
    ];

    const getUnitIndexByType = (type: DataSizeUnit) => fullUnitList.findIndex(item => item.type === type);
    const getUnitItemByType = (type: DataSizeUnit) => fullUnitList.find(item => item.type === type);

    const minUnitIndex = getUnitIndexByType(minUnit);
    const maxUnitIndex = getUnitIndexByType(maxUnit);
    const unitList = fullUnitList.filter((item, index) => index >= minUnitIndex && index <= maxUnitIndex);

    const separateValue = (value) => {
        let count = 0;
        let unit: DataSizeUnit;

        if (value) {
            if (unitType && value !== 0) {
                value = value * getUnitItemByType(unitType).multiplier;
            }

            if (Math.abs(value) < getUnitItemByType(minUnit).multiplier) {
                count = 0;
                unit = minUnit;
            } else {
                for (let i = unitList.length - 1; i >= 0; i--) {
                    const item = unitList[i];
                    const { multiplier, type } = item;

                    if (value % multiplier === 0) {
                        count = value / multiplier;
                        unit = type;
                        break;
                    }
                }

                if (!unit) {
                    count = Math.floor(value / getUnitItemByType(minUnit).multiplier);
                    unit = minUnit;
                }
            }
        } else {
            count = value;
            unit = minUnit;
        }

        return {
            count,
            unit,
            value,
        };
    };

    const [state, setState] = useState(() => {
        const value = formsState.getValue(name);
        return separateValue(value);
    });

    const calculateNumberValue = (v) => {
        if (v === undefined) {
            return undefined;
        }

        let multiplier = unitList.find(unit => unit.type === state.unit).multiplier;

        if (unitType) {
            multiplier = multiplier / getUnitItemByType(unitType).multiplier;
        }

        return v / multiplier;
    };

    const [maxNumberValue, setMaxNumberValue] = useState(() => Math.floor(calculateNumberValue(maxValue)));
    const [minNumberValue, setMinNumberValue] = useState(() => Math.ceil(calculateNumberValue(minValue)));

    useEffect(() => {
        setMaxNumberValue(Math.floor(calculateNumberValue(maxValue)));
    }, [maxValue]);

    useEffect(() => {
        setMinNumberValue(Math.ceil(calculateNumberValue(minValue)));
    }, [minValue]);

    const [staticState] = useState({
        count: state.count,
        unit: state.unit,
    });

    const updateValue = (count: number, unit: DataSizeUnit, inputUnit: string | null) => {
        if (count === staticState.count && unit === staticState.unit) {
            return;
        }

        staticState.count = count;
        staticState.unit = unit;

        Object.assign(state, { count, unit });

        const { multiplier } = fullUnitList.find(rec => rec.type === unit);
        const newValue = count * multiplier;

        let outputValue = newValue;
        if (inputUnit) {
            const inputUnitData = fullUnitList.find(unitData => unitData.type === inputUnit);
            outputValue = Math.floor(outputValue / inputUnitData.multiplier);
        }

        formsState.setValue(name, outputValue);
        state.value = newValue;

        setState(Object.assign({}, state));

        onChange?.();
    };

    const [touched, setTouched] = useState(false);

    const calcErrorText = () => {
        const validationStatus = formsState.validationState.getStatus();

        let error = formsState.getError(name);
        const isImmediately = error ? error.indexOf(immediatelyValidateFlag) !== -1 : false;
        const showError = (touched || validationStatus !== ValidationStatus.Common || isImmediately) && error;

        error = isImmediately ? error.replace(immediatelyValidateFlag, '') : error;

        return showError ? error : '';
    };
    const [errorText, setErrorText] = useState(() => calcErrorText());

    useEffect(() => {
        const updateValueFunction = () => {
            const val = separateValue(formsState.getValue(name));
            const unit = val.count === 0 ? state.unit : val.unit;
            updateValue(val.count, unit, unitType);

            setErrorText(calcErrorText());
        };

        formsState.onChange(updateValueFunction);
        return () => formsState.unsubscribeOnChange(updateValueFunction);
    }, [state]);

    useEffect(() => {
        setErrorText(calcErrorText());
    }, [touched]);

    return (
        <FieldLayout
            asTag={as}
            label={label && label[label.length - 1] !== labelDelimiter ? `${label}${labelDelimiter}` : label}
            disabled={disabled}
        >
            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                <FieldLayout
                    suffix={suffix}
                    error={errorText}
                    disabled={disabled}
                >
                    <NumberInputKit
                        allowDecimal={allowDecimal !== false}
                        precision={4}
                        minValue={minNumberValue}
                        maxValue={maxNumberValue}
                        error={errorText}
                        onChange={(v) => {
                            const count = v === undefined ? null : v;
                            updateValue(count, state.unit, unitType);
                        }}
                        onBlur={() => setTouched(true)}
                        value={state.count}
                        size={CONTROL_SIZE_XXS_PLUS}
                    />
                </FieldLayout>

                <FieldLayout
                    suffix={suffix2}
                    disabled={disabled}
                >
                    <ComboboxKit
                        data={unitList}
                        onChange={(v) => {
                            updateValue(state.count, v, unitType);
                        }}
                        textGetter={item => item.name}
                        value={state.unit}
                        valueGetter={item => item.type}
                        size={unitSize || CONTROL_SIZE.xxs}
                    />
                </FieldLayout>
            </StackView>
        </FieldLayout>
    );
};
