/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { WeekTitleWrapper } from './WeekTitle.styled';

export const WeekTitle: React.FC = () => (
    <WeekTitleWrapper>
        <div>Sunday</div>
        <div>Monday</div>
        <div>Tuesday</div>
        <div>Wednesday</div>
        <div>Thursday</div>
        <div>Friday</div>
        <div>Saturday</div>
    </WeekTitleWrapper>
);
