/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
} from '@veeam-vspc/components';
import { deepCopy } from '@veeam-vspc/core';

import type { ToolbarItems } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { Microsoft365ServerDialog } from '../Microsoft365ServerDialog';
import addIcon from 'images/actions/add.svg';
import removeIcon from 'images/actions/remove.svg';
import editIcon from 'images/actions/edit.svg';
import { canRemoveVboResource } from '../../../../utils';

import type { ResellerVboResource } from '../../../../../../interfaces';

interface Props {
    items: ResellerVboResource[];
    setItems: (items: ResellerVboResource[]) => void;
}

export const ActionsToolbar = ({ items, setItems }: Props) => {
    const lang = useLang();
    const gridApi = useGridApi<ResellerVboResource, void, void>();

    const [quotaDialog, quotaDialogActions] = useModal<ResellerVboResource>({
        render: ({ deactivate, data }) => (
            <Microsoft365ServerDialog
                data={data}
                existingItems={items}
                onConfirm={(newData) => {
                    const newItems = deepCopy(items);
                    if (data) {
                        newItems[newItems.findIndex(item => item.vboInstanceUid === data.vboInstanceUid)] = newData;
                    } else {
                        newItems.push(newData);
                    }
                    setItems(newItems);
                }}
                deactivate={deactivate}
            />
        ),
    });

    const toolbarItems: ToolbarItems = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: addIcon,
            text: lang.ADD,
            onClick: () => {
                quotaDialogActions.activate();
            },
            decorators: [
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: editIcon,
            text: lang.EDIT,
            onClick: async() => {
                const selected = gridApi.selected[0];
                quotaDialogActions.activate(selected);
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: removeIcon,
            text: lang.REMOVE,
            onClick: async() => {
                const selected = gridApi.selected[0];

                if (
                    await canRemoveVboResource(
                        selected,
                        lang.CANNOT_REMOVE_THE_MICROSOFT_365_SERVER,
                        lang.THIS_ACTION_WILL_REMOVE_MICROSOFT_365_SERVERS,
                        lang
                    )
                ) {
                    const newItems = deepCopy(items);
                    newItems.splice(newItems.findIndex(item => item.vboInstanceUid === selected.vboInstanceUid), 1);
                    setItems(newItems);
                }
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
    ];

    return (
        <>
            <Toolbar items={toolbarItems} />
            {quotaDialog}
        </>
    );
};
