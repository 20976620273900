/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import type { ProtectedVmBackupRestorePointExportParam } from '@veeam-vspc/models/web-controllers';

import { ToolbarWithExport } from 'views/components/ToolbarWithExport';

import type { GridExportProps } from 'views/hooks';

export interface RestorePointDialogToolbarProps extends GridExportProps {
    method?: string;
    extraParams: ProtectedVmBackupRestorePointExportParam;
}

export const RestorePointDialogToolbar: React.FC<RestorePointDialogToolbarProps> = ({
    method = 'getprotectedvmbackuprestorepoints',
    ...restProps
}) => (
    <ToolbarWithExport
        {...restProps}
        method={method}
    />
);
