/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SidePanel, SidePanelBody } from '@veeam-vspc/components';
import React from 'react';

import type { WizardPanelProps, DialogAction } from '@veeam-vspc/components';

interface Props extends WizardPanelProps {
    actions?: DialogAction[];
}

export const SidePanelForm = (props: Props) => (
    <SidePanel {...props}>
        <SidePanelBody
            growContent
            actions={props.actions}
            content={props.children as (React.ReactElement | null | false)}
        />
    </SidePanel>
);
