/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/use-page-wizard-store';
import {
    LinkButton,
    FullPageButton,
} from '@veeam-vspc/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';
import type { SubscriptionPlanForAssignParam } from '@veeam-vspc/models/web-controllers';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { RadioGroup } from 'components/controls/RadioGroup';
import { SubscriptionPlan } from 'views/pages/SubscriptionPlanPage/components/wizards/SubscriptionPlan';
import { useAppStore } from 'views/providers/AppProvider/hooks/use-app-store';
import { useCompanyWizardStore } from '../../hooks';
import { PlanSummary } from './components/PlanSummary';
import addIcon from 'images/actions/add.svg';

import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';
import type { CompanyData } from '../../interfaces';
import type { RadioGroupButtons } from 'components/controls/RadioGroup';

export const getBillingStep = (lang: LangsServiceBase): WizardStep<CompanyData> => ({
    title: lang.BILLING,
    render: stepData => <BillingStep {...stepData} />,
});

const BillingStep = observer((wizardStepData: WizardStepData<CompanyData>) => {
    const lang = useLang();
    const { data, onDataChange } = wizardStepData;
    const wizardContext = usePageWizardStore<CompanyData>();
    const wizardStore = useCompanyWizardStore();
    const { portalUser } = useAppStore();
    const subPlanCashLoadParams: SubscriptionPlanForAssignParam = { availableForAssignTo: data.id };

    const showPlanSummary = () => wizardContext.openPanel(hide => (
        <PlanSummary
            {...wizardStepData}
            hidePanel={hide}
            planData={wizardStore.subscriptionPlansCache.value.find(item => item.id === data.subscriptionPlan)}
        />
    ));

    useEffect(() => {
        wizardStore.subscriptionPlansCache.load(subPlanCashLoadParams);
    }, []);

    const planRadioButtons: RadioGroupButtons<number | null>[] = wizardStore.subscriptionPlansCache.value?.map(plan => ({
        value: plan.id,
        label: plan.name,
        helpText: `${lang.CREATED_BY}: ${plan.companyName}`,
        link: props => <LinkButton compact onClick={showPlanSummary} {...props}>{lang.VIEW_DETAILS}</LinkButton>,
    }));
    planRadioButtons?.push({
        label: lang.NO_BILLING,
        value: null,
    });

    wizardContext.setLoading(wizardStore.subscriptionPlansCache.loading);

    return (
        <StepLayout
            title={lang.BILLING}
            description={lang.SELECT_THE_DESIRED_SUBSCRIPTION}
        >
            {(portalUser.isPortalAdministrator()
                || portalUser.isServiceProviderGlobalAdministrator()
                || portalUser.isServiceProviderAdministrator()
                || portalUser.isCompanyAdministrator()
            ) && (
                <FullPageButton
                    iconBefore={addIcon}
                    isExtMode={true}
                    renderFullPageWindow={({ onClose }) => (
                        <SubscriptionPlan
                            isEdit={false}
                            onFinish={(planId) => {
                                data.subscriptionPlan = planId;
                                onDataChange(data);
                                wizardStore.subscriptionPlansCache.load(subPlanCashLoadParams, true);
                            }}
                            onClose={onClose}
                        />
                    )}
                >
                    {`${lang.CREATE_NEW}...`}
                </FullPageButton>
            )}

            {wizardStore.subscriptionPlansCache.value !== null && (
                <RadioGroup
                    value={data.subscriptionPlan}
                    onChange={(value) => {
                        data.subscriptionPlan = value;
                        onDataChange(data);
                    }}
                    buttons={planRadioButtons}
                />
            )}
        </StepLayout>
    );
});
