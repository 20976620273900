/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable } from 'mobx';
import { parseMfaToken } from '@veeam-vspc/core';

import type { IdentityProviderSettings } from '@veeam-vspc/models/rest';
import type { NextFactor, SuccessAuth } from '@veeam-vspc/core';

import { CurrentLoginFactor, GoToLoginStartResponse } from '../interfaces';
import { LoginFactors } from '../enums';
import { URL_PARAMS } from 'core/const';

import type { CloudConnectServerItem, FinishFactorResponse, GoToResetPasswordResponse, GoToSsoResponse, LoginParams } from '../interfaces';

export class LoginStore {

    @observable error: string | null;
    @observable warning: string | null;
    @observable info: string | null;
    @observable isLoading = false;
    @observable currentFactor: CurrentLoginFactor;

    productImageSrc: string;
    productLogoImageSrc: string;

    identityProviders: IdentityProviderSettings[] | null;

    loadApp: () => void;
    loadLogin: (homeUrl?: string) => void;

    constructor({ identityProviders, error = null, warning = null, info = null, loadApp, loadLogin }: LoginParams) {
        this.productImageSrc = '/Branding/GetPortalLoginImageBrandImage';
        this.productLogoImageSrc = '/Branding/GetPortalLogoBrandImage';
        this.error = error;
        this.warning = warning;
        this.info = info;
        this.loadApp = loadApp;
        this.loadLogin = loadLogin;
        this.identityProviders = identityProviders; //d

        this.initDefaultFactor({ error, warning, info, goToLoginStart: true });
    }

    @action.bound
    onFinishFactorHandler(resp: FinishFactorResponse & { login?: string; }): void {
        this.isLoading = false;
        this.resetMessages();

        if ((resp as SuccessAuth).successAuth) {
            return this.loadApp();
        } else if ((resp as GoToSsoResponse).goToSso) {
            return this.goToSsoFactor();
        } else if ((resp as GoToResetPasswordResponse).goToResetPassword) {
            return this.goToResetPasswordFactor();
        } else if ((resp as NextFactor).nextFactor) {
            return this.goToNextFactor((resp as NextFactor).mfaToken, resp.login);
        } else if ((resp as GoToLoginStartResponse).goToLoginStart) {
            return this.goToLoginStart(resp as GoToLoginStartResponse);
        }

        console.error('Wrong login factor. Change to LoginStart');
    }

    @action.bound
    onSsoClick(): void {
        this.isLoading = true;

        const form = document.createElement('form');

        form.method = 'POST';
        form.action = `/Saml2/${this.identityProviders[0].name}/SignIn`;

        document.body.appendChild(form);

        form.submit();
    }

    @action.bound
    resetMessages(): void {
        if (this.error) {
            this.error = null;
        }

        if (this.warning) {
            this.warning = null;
        }

        if (this.info) {
            this.info = null;
        }
    }

    protected initDefaultFactor(factorData: GoToLoginStartResponse): void {
        const code = new URLSearchParams(window.location.search).get(URL_PARAMS.ResetPasswordFlag);

        if (code) {
            this.goToChangePasswordFactor(code);
        } else {
            this.goToLoginStart(factorData);
        }
    }

    @action.bound
    protected goToNextFactor(mfaToken: string, login: string): void {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { select_server, totp } = parseMfaToken(mfaToken);

        if (select_server) {
            this.currentFactor = {
                type: LoginFactors.SelectServer,
                mfaToken,
                cloudConnectServerItems: select_server.split(';').map((name: string): CloudConnectServerItem => ({ name })),
                login,
            };
        } else if (totp) {
            this.currentFactor = {
                type: LoginFactors.VerifyCode,
                mfaToken,
                login,
                attempts: 0,
            };
        } else {
            console.error('Cannot define next factor type. Change to LoginStart', mfaToken);
            return this.goToLoginStart();
        }
    }

    @action.bound
    protected goToLoginStart({ error = null, warning = null, info = null }: GoToLoginStartResponse = { goToLoginStart: true }): void {
        this.currentFactor = {
            type: LoginFactors.LoginStart,
            identityProviders: this.identityProviders,
        };
        this.error = error;
        this.warning = warning;
        this.info = info;
    }

    @action.bound
    protected goToSsoFactor(): void {
        this.currentFactor = { type: LoginFactors.Sso };
    }

    @action.bound
    protected goToChangePasswordFactor(code: string): void {
        this.currentFactor = {
            type: LoginFactors.ChangePassword,
            code,
        };
    }

    @action.bound
    protected goToResetPasswordFactor(): void {
        this.currentFactor = { type: LoginFactors.ResetPassword };
    }

}
