/**
 * Copyright © Veeam Software Group GmbH.
 */

import { XmlFileParams } from '../../../enums';
import { getTagValueAttr, getXmlTags } from '../../../helpers';

export const checkPolitics = (xml: XMLDocument): boolean => {
    const tagsQueries = [
        XmlFileParams.EulaQuery,
        XmlFileParams.LicensingPolicyQuery,
        XmlFileParams.RequiredSoftwareQuery,
        XmlFileParams.ThirdPartyLicensesQuery,
    ];

    return getXmlTags(xml, tagsQueries).every(tag => getTagValueAttr(tag) === XmlFileParams.AcceptValue);
};
