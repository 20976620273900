/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { constants } from '@veeam-vspc/components';

import { NARROW_HEAT_MAP_HEIGHT_BREAKPOINT } from '../../const';

export const WeekTitleWrapper = styled.div`
  height: ${constants.SIZE_XXL};
  font-weight: ${constants.BASE_FONT_BOLD_WEIGHT};
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  @media only screen and (max-height: ${NARROW_HEAT_MAP_HEIGHT_BREAKPOINT}) {
    height: ${constants.SIZE_L};
  }
  
  & > div {
    display: flex;
    align-items: center;
  }
`;
