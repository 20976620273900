/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { DataCache } from '../data-cache';
import { core } from '../../core-module';

import type { CurrencyItem } from '../../interfaces';

export const currenciesCache = new DataCache<CurrencyItem[], void>(
    () => core.transportService.request('SubscriptionPlan/GetCurrency')
        .then((resp: BaseSuccessRequestResponse<CurrencyItem[]>) => resp.data)
);
