/**
 * Copyright © Veeam Software Group GmbH.
 */

import { NotificationMethods } from '@veeam-vspc/core';

import type { NotificationService } from '@veeam-vspc/core';

import type { NotificationContextData, UseNotificationServiceData } from '../interfaces';

export const useNotificationService = (notificationService: NotificationService): UseNotificationServiceData => {
    const notifications: NotificationContextData = {} as NotificationContextData;
    const notificationMethods: Array<keyof typeof NotificationMethods> = Object.keys(NotificationMethods) as Array<keyof typeof NotificationMethods>;

    notificationMethods.forEach((method: keyof typeof NotificationMethods) => {
        const methodName: keyof NotificationService = method.toLowerCase() as keyof NotificationService;

        notifications[methodName] = (
            ...args: ArgumentTypes<typeof notificationService[typeof methodName]>
            // eslint-disable-next-line prefer-spread
        ): ReturnType<typeof notificationService[typeof methodName]> => notificationService[methodName].apply(notificationService, args);
    });

    return { notifications };
};
