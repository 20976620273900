/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { BackupServices } from './components/BackupServices';
import { CloudConnect } from './components/CloudConnect';
import { Infrastructure } from './components/Infrastructure';
import { HighchartsStyles } from './components/HighchartsWrapper';

export interface TrendsPageProps {
    viewName?: string;
}

export const TRENDS_PAGE = 'TrendsPage';

export const TrendsPage = ({ viewName, ...restProps }: TrendsPageProps) => (
    <>
        <HighchartsStyles />

        <div style={{ flex: 1 }}>
            {viewName === 'BackupServices' && <BackupServices {...restProps} />}
            {viewName === 'CloudConnect' && <CloudConnect {...restProps} />}
            {viewName === 'Infrastructure' && <Infrastructure {...restProps} />}
        </div>
    </>
);
