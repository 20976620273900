/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { ProtectedDataSummaryStoreContext } from './protected-data-summary-store-context';

import type { ProtectedDataSummaryStore } from './protected-data-summary-store';

export const useProtectedDataSummaryStore = (): ProtectedDataSummaryStore => useContext(ProtectedDataSummaryStoreContext);
