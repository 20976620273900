/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { EmailServerSettingsDialogParamsContext } from '../interfaces';

import type { EmailServerSettingsDialogParams } from '../interfaces';

export const useEmailServerSettingsDialogParams = (): EmailServerSettingsDialogParams => useContext(EmailServerSettingsDialogParamsContext);
