/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts, isPositiveNumber } from '@veeam-vspc/components';

import { RestorePointDialog } from './components/RestorePointDialog';

import type { RestorePointDialogProps } from './components/RestorePointDialog';

export interface RestorePointColumnProps extends Omit<RestorePointDialogProps, 'deactivate'> {
    value: string | number | null;
}

export const RestorePointColumn: React.FC<RestorePointColumnProps> = ({ value, ...restProps }) => (
    <ActionLinkColumn
        content={isPositiveNumber(value) ? value : ColumnTexts.DashSymbol}
        isLink={isPositiveNumber(value)}
        isExtMode={true}
        alignRight={true}
        renderModal={({ deactivate }) => (
            <RestorePointDialog
                {...restProps}
                deactivate={deactivate}
            />
        )}
    />
);

