/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, autorun, computed, observable } from 'mobx';
import { deepCopy, generateGuid } from '@veeam-vspc/core';
import { generate, observe } from 'fast-json-patch';
import {
    Vb365BackupJob,
    Vb365BackupJobSchedulePolicy,
    Vb365JobItemComposed,
    Vb365JobItemGroup,
    Vb365JobItemUser,
    Vb365OrganizationBase,
} from '@veeam-vspc/models/rest';

import type { BaseRequestResponse } from '@veeam-vspc/core';
import type { Operation } from 'fast-json-patch';
import type {
    Vb365BackupProxy,
    Vb365BackupRepository,
    Vb365JobItemSite,
    Vb365JobItemTeam,
} from '@veeam-vspc/models/rest';


import { core } from 'core/core-module';
import { BackupOptionsTarget } from '../enums';
import {
    getGroupsProcessingOptions,
    getOffice365GroupProcessingOptions,
    getOrganizationProcessingOptions,
    getPublicMailboxUsersProcessingOptions,
    getUsersProcessingOptions,
    personalSitesProcessingOptions,
    sitesProcessingOptions,
    teamsProcessingOptions,
} from '../components/BackupMode/helpers';
import { VBObjectsService } from '../../../services';
import { isScheduleHidden } from '../../../helpers';

import type { Vb365OrganizationBaseFix } from 'core/interfaces';
import type { PortalUser } from 'core/entries';
import type { ConfigurableOption } from '../components/BackupMode/interfaces';

const TEMP_ID_PREFIX = 'temp__';

export class JobWizardStore {
    static defaultSchedulePolicy: Vb365BackupJobSchedulePolicy = {
        schedulePolicyType: Vb365BackupJobSchedulePolicy.SchedulePolicyTypeEnum.Daily,
        periodicallyEvery: Vb365BackupJobSchedulePolicy.PeriodicallyEveryEnum.Hours1,
        dailyType: Vb365BackupJobSchedulePolicy.DailyTypeEnum.Everyday,
        scheduleEnabled: true,
        backupWindowEnabled: false,
        backupWindowSettings: { backupWindow: Array.from({ length: 168 }, () => true) },
        periodicallyWindowSettings: { backupWindow: Array.from({ length: 168 }, () => true), minuteOffset: 0 },
        periodicallyOffsetMinutes: null,
        periodicallyWindowEnabled: false,
        dailyTime: '07:00',
        retryEnabled: true,
        retryNumber: 3,
        retryWaitInterval: 10,
    };

    private initialData: Vb365BackupJob;

    @observable data!: Vb365BackupJob;
    @observable vbOrganizationUid: Vb365BackupJob['vb365OrganizationUid'];

    @observable organizationsLoadingState: 'idle' | 'pending' | 'done' | 'fail' = 'idle';
    @observable organizations: Vb365OrganizationBase[] = [];
    @observable backupRepositories: Vb365BackupRepository[] = [];
    @observable backupProxies: Vb365BackupProxy[] = [];

    @observable selectedItems: Vb365JobItemComposed[] = [];
    @observable excludedItems: Vb365JobItemComposed[] = [];

    @observable exclusionsEnabled = false;
    @observable startJobImmediately = false;

    @observable needToShowBackupRepositoryChangeWarning = false;

    constructor(
        data: Vb365BackupJob,
        private portalUser: PortalUser,
        organizations: Vb365OrganizationBase[] = [],
        backupRepositories: Vb365BackupRepository[] = [],
        backupProxies: Vb365BackupProxy[] = [],
        isEdit: boolean,
    ) {
        this.initialData = data;
        this.updateJobData(data);

        this.organizations = organizations;
        this.backupRepositories = backupRepositories;
        this.backupProxies = backupProxies;

        if (organizations.length === 1) {
            this.selectOrganization(organizations[0]);
        } else {
            this.selectOrganization({ instanceUid: data.vb365OrganizationUid } as Vb365OrganizationBase);
        }

        this.setExclusionsState(Boolean(data.excludedItems && data.excludedItems.length > 0));

        this.needToShowBackupRepositoryChangeWarning = isEdit;

        autorun(() => {
            if (this.selectedOrganization && this.selectedOrganization.vb365ServerUid) {
                this.loadBackupRepositories(this.selectedOrganization.vb365ServerUid);
                this.loadProxies(this.selectedOrganization.vb365ServerUid);
            }
        });
    }

    @computed
    get isScheduleHidden() {
        return isScheduleHidden(this.portalUser, this.selectedOrganization);
    }

    @action.bound
    handleBackupRepositoryChangeWarningShown() {
        this.needToShowBackupRepositoryChangeWarning = false;
    }

    @action
    loadBackupRepositories(vb365ServerUid: string): void {
        VBObjectsService.loadBackupRepositories(vb365ServerUid)
            .then((dirtyBackupRepositories) => {
                const backupRepositories = dirtyBackupRepositories.filter(repository => repository.isAvailableForBackupJob);
                this.backupRepositories = backupRepositories;

                if (backupRepositories.length === 1) {
                    this.selectBackupRepository(this.backupRepositories[0].instanceUid!);
                } else {
                    this.selectBackupRepository(this.data.repositoryUid);
                }
            });
    }

    @action
    loadProxies(vb365ServerUid: string): void {
        VBObjectsService.loadProxies(vb365ServerUid)
            .then((response) => {
                this.backupProxies = response;
            });
    }

    @computed
    get selectedOrganization(): Vb365OrganizationBaseFix | undefined {
        return this.organizations.find(x => x.instanceUid === this.vbOrganizationUid);
    }

    @action.bound
    selectOrganization(organization: Vb365OrganizationBase): void {
        this.vbOrganizationUid = organization.instanceUid;

        this.updateJobData(this.data);
    }

    @action.bound
    resetJobSettings(): void {
        this.updateJobData({
            ...this.data,
            selectedItems: [],
            excludedItems: [],
            backupType: Vb365BackupJob.BackupTypeEnum.EntireOrganization,
            repositoryUid: undefined,
        });

        this.setExclusionsState(false);
    }

    @computed
    get selectedBackupRepository(): Vb365BackupRepository | undefined {
        return this.backupRepositories.find(x => x.instanceUid === this.data.repositoryUid);
    }

    @action.bound
    selectBackupRepository(uid: string): void {
        this.data.repositoryUid = uid;
    }

    @action.bound
    setExclusionsState(state: boolean): void {
        this.exclusionsEnabled = state;

        if (!state) {
            this.excludedItems = [];
            this.data.excludedItems = [];
        }
    }

    @action.bound
    addUsersToResources(resources: Vb365JobItemUser[], target: BackupOptionsTarget): void {
        resources.forEach((user) => {
            if (this[target].findIndex(x => x.user?.id === user.id) !== -1) {
                return;
            }

            const resource: Vb365JobItemComposed = {
                id: `${TEMP_ID_PREFIX}${generateGuid()}`,
                itemType: Vb365JobItemComposed.ItemTypeEnum.User,
                user,
            };

            const options = this.getProcessingOptions(resource);

            options.forEach(option => resource[option] = true);

            this[target].push(resource);
        });

    }

    @action.bound
    addGroupsToResources(resources: Vb365JobItemGroup[], target: BackupOptionsTarget): void {
        resources.forEach((group) => {
            if (this[target].findIndex(x => x.group?.id === group.id) !== -1) {
                return;
            }

            const resource: Vb365JobItemComposed = {
                id: `${TEMP_ID_PREFIX}${generateGuid()}`,
                itemType: Vb365JobItemComposed.ItemTypeEnum.Group,
                group,
            };

            const options = this.getProcessingOptions(resource);

            options.forEach(option => resource[option] = true);

            this[target].push(resource);
        });
    }

    @action.bound
    addSitesToResources(resources: Vb365JobItemSite[], target: BackupOptionsTarget): void {
        resources.forEach((site) => {
            if (this[target].findIndex(x => x.site?.id === site.id) !== -1) {
                return;
            }

            const resource: Vb365JobItemComposed = {
                id: `${TEMP_ID_PREFIX}${generateGuid()}`,
                itemType: Vb365JobItemComposed.ItemTypeEnum.Site,
                site,
            };

            this[target].push(resource);
        });
    }

    @action.bound
    addTeamsToResources(resources: Vb365JobItemTeam[], target: BackupOptionsTarget): void {
        resources.forEach((team) => {
            if (this[target].findIndex(x => x.team?.id === team.id) !== -1) {
                return;
            }

            const resource: Vb365JobItemComposed = {
                id: `${TEMP_ID_PREFIX}${generateGuid()}`,
                itemType: Vb365JobItemComposed.ItemTypeEnum.Team,
                team,
                backupTeamsChats: false,
            };

            if (target === BackupOptionsTarget.ExcludedItems) {
                if (!this.selectedOrganization.protectedServices.includes(Vb365OrganizationBase.ProtectedServicesEnum.MicrosoftTeamsChats)) {
                    resource.backupTeamsChats = false;
                } else {
                    resource.backupTeamsChats = true;
                }
            }


            this[target].push(resource);
        });
    }

    @action.bound
    addCurrentOrganizationToResources(target: BackupOptionsTarget): void {
        if (this[target].findIndex(x => x.itemType === Vb365JobItemComposed.ItemTypeEnum.PartialOrganization) !== -1) {
            return;
        }

        const item: Vb365JobItemComposed = {
            id: `${TEMP_ID_PREFIX}${generateGuid()}`,
            itemType: Vb365JobItemComposed.ItemTypeEnum.PartialOrganization,
        };

        this.getProcessingOptions(item).forEach((k) => {
            if (k === 'backupTeamsChats') {
                item[k] = false;
            } else {
                item[k] = true;
            }
        });

        this[target].push(item);
    }

    @action.bound
    addPersonalSiteToResources(target: BackupOptionsTarget): void {
        if (this[target].findIndex(x => x.itemType === Vb365JobItemComposed.ItemTypeEnum.PersonalSites) !== -1) {
            return;
        }

        this[target].push({
            id: `${TEMP_ID_PREFIX}${generateGuid()}`,
            itemType: Vb365JobItemComposed.ItemTypeEnum.PersonalSites,
        });
    }

    @action.bound
    removeResourcesFromBackupResources(resources: Vb365JobItemComposed[]): void {
        const ids = resources.map(x => x.id);

        this.selectedItems = this.selectedItems.filter(x => !ids.includes(x.id));
    }

    @action.bound
    removeResourcesFromExcludedResources(resources: Vb365JobItemComposed[]): void {
        const ids = resources.map(x => x.id);

        this.excludedItems = this.excludedItems.filter(x => !ids.includes(x.id));
    }

    @action.bound
    updateResources(resources: Vb365JobItemComposed[], target: BackupOptionsTarget): void {
        resources.forEach((resource) => {
            const index = this[target].findIndex(x => x.id === resource.id);
            this[target][index] = resource;
        });
    }

    @action.bound
    updateJobData(data: Vb365BackupJob): void {
        this.data = data;

        if (data.selectedItems) {
            this.selectedItems = [...data.selectedItems];
        }

        if (data.excludedItems) {
            this.excludedItems = [...data.excludedItems];
        }

        if (!this.selectedOrganization) {
            return;
        }

        if (!this.isScheduleHidden) {
            this.data.schedulePolicy = { ...JobWizardStore.defaultSchedulePolicy, ...data.schedulePolicy };

            this.fillEmptyScheduleFields();
        }
    }

    @action.bound
    fillEmptyScheduleFields() {
        const { defaultSchedulePolicy } = JobWizardStore;
        const availablePolicyTypes = [
            Vb365BackupJobSchedulePolicy.SchedulePolicyTypeEnum.Daily,
            Vb365BackupJobSchedulePolicy.SchedulePolicyTypeEnum.Periodically,
        ];

        let schedulePolicyType = defaultSchedulePolicy.schedulePolicyType;

        if (this.data.schedulePolicy?.schedulePolicyType && availablePolicyTypes.includes(this.data.schedulePolicy.schedulePolicyType)) {
            schedulePolicyType = this.data.schedulePolicy?.schedulePolicyType;
        }

        this.data.schedulePolicy = {
            ...(this.data.schedulePolicy || {}),
            schedulePolicyType,
            dailyType: this.data.schedulePolicy?.dailyType ?? defaultSchedulePolicy.dailyType,
            dailyTime: this.data.schedulePolicy?.dailyTime ?? defaultSchedulePolicy.dailyTime,
            periodicallyEvery: this.data.schedulePolicy?.periodicallyEvery ?? defaultSchedulePolicy.periodicallyEvery,
            retryNumber: this.data.schedulePolicy?.retryNumber ?? defaultSchedulePolicy.retryNumber,
            retryWaitInterval: this.data.schedulePolicy?.retryWaitInterval ?? defaultSchedulePolicy.retryWaitInterval,
            periodicallyWindowEnabled: this.data.schedulePolicy?.periodicallyWindowEnabled ?? defaultSchedulePolicy.periodicallyWindowEnabled,
        };
    }


    @action.bound
    updateSchedule(policy: Vb365BackupJobSchedulePolicy): void {
        this.data.schedulePolicy = { ...policy };
    }

    @action.bound
    updateSelectedItems(): void {
        this.data.selectedItems = this.selectedItems
            .map(item => item.id!.startsWith(TEMP_ID_PREFIX) ? { ...item, id: undefined } : item);
    }

    @action.bound
    cancelSelectedItems(): void {
        this.selectedItems = this.data.selectedItems
            ? this.data.selectedItems.map(x => ({ ...x, id: x.id || `${TEMP_ID_PREFIX}${generateGuid()}` }))
            : [];
    }

    @action.bound
    updateExcludedItems(): void {
        this.data.excludedItems = this.excludedItems
            .map(item => item.id!.startsWith(TEMP_ID_PREFIX) ? { ...item, id: undefined } : item);
    }

    @action.bound
    cancelExcludedItems(): void {
        this.excludedItems = this.data.excludedItems
            ? this.data.excludedItems.map(x => ({ ...x, id: x.id || `${TEMP_ID_PREFIX}${generateGuid()}` }))
            : [];
    }

    getProcessingOptions(item: Vb365JobItemComposed): ConfigurableOption[] {
        if (!this.selectedOrganization || !this.selectedOrganization.protectedServices) {
            return [];
        }

        switch (item.itemType) {
            case Vb365JobItemComposed.ItemTypeEnum.User: {
                if (item.user?.userType === Vb365JobItemUser.UserTypeEnum.Public) {
                    return getPublicMailboxUsersProcessingOptions(this.selectedOrganization.protectedServices);
                }

                return getUsersProcessingOptions(this.selectedOrganization.protectedServices);
            }
            case Vb365JobItemComposed.ItemTypeEnum.Group: {
                if (item.group?.groupType === Vb365JobItemGroup.GroupTypeEnum.Office365) {
                    return getOffice365GroupProcessingOptions(this.selectedOrganization.protectedServices);
                }

                return getGroupsProcessingOptions(this.selectedOrganization.protectedServices);
            }
            case Vb365JobItemComposed.ItemTypeEnum.Site: {
                return sitesProcessingOptions;
            }
            case Vb365JobItemComposed.ItemTypeEnum.Team: {
                return teamsProcessingOptions;
            }
            case Vb365JobItemComposed.ItemTypeEnum.PartialOrganization: {
                return getOrganizationProcessingOptions(this.selectedOrganization.protectedServices);
            }
            case Vb365JobItemComposed.ItemTypeEnum.PersonalSites: {
                return personalSitesProcessingOptions;
            }
        }

        return [];
    }

    createJob(): Promise<BaseRequestResponse<Vb365BackupJob>> {
        if (!this.selectedOrganization) {
            return Promise.reject();
        }

        return core.transportService.post<Vb365BackupJob, Vb365BackupJob>(
            // eslint-disable-next-line max-len
            `/infrastructure/vb365Servers/${this.selectedOrganization.vb365ServerUid}/organizations/${this.selectedOrganization.instanceUid}/jobs/backup/sync?startJobAfterCreation=${this.startJobImmediately}`,
            this.data
        );
    }

    getExtendedDiff() {
        const initialDataLocal: Vb365BackupJob = deepCopy(this.initialData);
        const deletionsObserver = observe<Vb365BackupJob>(initialDataLocal);

        initialDataLocal.selectedItems = [];
        initialDataLocal.excludedItems = [];

        const deletions = generate(deletionsObserver);
        deletionsObserver.unobserve();

        const updatesObserver = observe<Vb365BackupJob>(initialDataLocal);

        for (const k in this.data) {
            const key = k as keyof Vb365BackupJob;

            // @ts-expect-error: readonly
            initialDataLocal[key] = this.data[key];
        }

        const updates = generate(updatesObserver);
        updatesObserver.unobserve();

        return [...deletions, ...updates];
    }

    patchJob(): Promise<BaseRequestResponse<Vb365BackupJob>> {
        if (!this.selectedOrganization) {
            return Promise.reject();
        }

        if (this.data.backupType === Vb365BackupJob.BackupTypeEnum.EntireOrganization && this.data.selectedItems?.length) {
            this.data.selectedItems = [];
        }

        const data = this.getExtendedDiff();

        return core.transportService.patch<Operation[], Vb365BackupJob>(
            `/infrastructure/vb365Servers/${this.selectedOrganization.vb365ServerUid}/organizations/jobs/backup/${this.data.instanceUid}/sync`,
            data,
            undefined,
            { notUseJsonPatch: true }
        );
    }

    isItemSelected(id: string, type: Vb365JobItemComposed.ItemTypeEnum): boolean {
        const propsMap: Record<any, keyof Pick<Vb365JobItemComposed, 'user' | 'group' | 'site' | 'team'>> = {
            [Vb365JobItemComposed.ItemTypeEnum.User]: 'user',
            [Vb365JobItemComposed.ItemTypeEnum.Group]: 'group',
            [Vb365JobItemComposed.ItemTypeEnum.Site]: 'site',
            [Vb365JobItemComposed.ItemTypeEnum.Team]: 'team',
        };

        const prop = propsMap[type];

        const foundInSelected = this.selectedItems.find(item => item[prop]?.id === id) !== undefined;
        const foundInExcluded = this.excludedItems.find(item => item[prop]?.id === id) !== undefined;

        return foundInExcluded || foundInSelected;
    }
}
