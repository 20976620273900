/**
 * Copyright © Veeam Software Group GmbH.
 */

import { isValid } from '@veeam-vspc/components';

import type { ExternalFormApi } from '@veeam-vspc/components';

import { core } from 'core/core-module';
import { ValidationType } from './enums';
import { VbrDeployType } from '../../enums';
import { formValidate } from './formValidate';
import { getXmlFromFile } from '../../helpers';
import { getValidationResult } from './helpers';

import type { VbrDeploySettingsWizardModel } from '../../entries';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const stepValidate = async(
    lang: LangsServiceBase,
    data: VbrDeploySettingsWizardModel,
    formApi?: ExternalFormApi<VbrDeploySettingsWizardModel>
) => {
    const isXmlFile = isValid(formValidate(lang), data);

    if (isXmlFile) {
        return getXmlFromFile(data.installationParametersFile[0])
            .then((xml) => {
                const isVbrUpgrade = data.vbrDeployType === VbrDeployType.Upgrade;
                const validationResult = getValidationResult(xml, isVbrUpgrade);

                if (!validationResult.isValid) {
                    const errTitle = lang.INVALID_FILE;
                    let errMsg = lang.THE_PROVIDED_CONFIGURATION_FILE_IS_INVALID;

                    switch (validationResult.type) {
                        case ValidationType.MainTag:
                            errMsg = lang.THE_PROVIDED_CONFIGURATION_FILE_IS_INVALID; break;

                        case ValidationType.Politics:
                            errMsg = lang.CANNOT_PROCEED_WITH_THE_INSTALLATION; break;

                        case ValidationType.RebootTag:
                            errMsg = lang.CANNOT_PROCEED_WITH_THE_REMOTE; break;

                        case ValidationType.LicenseTag:
                            errMsg = lang.THE_CONFIGURATION_FILE_INCLUDES; break;

                        default: break;
                    }

                    core.notificationService.warning(errTitle, errMsg);
                    formApi.setExternalErrors({ installationParametersFile: lang.INVALID_FILE });
                }

                return validationResult.isValid;
            })
            .catch(() => {
                if (formApi) {
                    formApi.setValue('installationParametersFile', undefined);
                }

                data.installationParametersFile = undefined;
                core.notificationService.error(lang.CONFIGURATION_FILE, lang.CANNOT_DETECT_THE_CONFIGURATION_FILE);

                return false;
            });
    }

    return isXmlFile;
};
