/**
 * Copyright © Veeam Software Group GmbH.
 */

import { PortalUserRoles } from 'core/entries/portal-user/enums';

import type { BaseUserConfig } from '../interfaces';

export const baseConfig: BaseUserConfig = {
    baseConfig: null,
    roleInt: PortalUserRoles.Unknown,
    roleName: PortalUserRoles[PortalUserRoles.Unknown] as keyof typeof PortalUserRoles,
    routes: [],
    sections: [],
};
