/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, StackView, Text, ICON_SIZES, STACK_ALIGN, STACK_GAP, TEXT_SIZE } from '@veeam-vspc/components';

import type { TextProps } from '@veeam-vspc/components';

import infoIcon from 'images/info-light-gray.svg';
import { useLang } from 'views/providers/LangProvider/hooks';

interface NoInfoColumnProps extends Pick<TextProps, 'size'> {}

export const NoInfoColumn: React.FC<NoInfoColumnProps> = ({ size }) => {
    const lang = useLang();

    return (
        <StackView gap={STACK_GAP.s} align={STACK_ALIGN.center}>
            <Icon src={infoIcon} size={ICON_SIZES.xl} />
            <Text size={size || TEXT_SIZE.l}>{lang.NO_INFO}</Text>
        </StackView>
    );
};
