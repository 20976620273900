/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    Form,
    FormLayout,
    FormValidator,
    isValid,
    FormDescription,
    FormTitle, CONTROL_SIZE,
} from '@veeam-vspc/components';
import { capitalize, FileSizeUnitsInBytes, formatStr } from '@veeam-vspc/core';

import type { WizardStepData } from '@veeam-vspc/components';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { DataSizeInBytes } from 'components/controls/DataSizeInBytes';
import { PerBytesField } from 'components/controls/PerBytesField';
import { PerDateField } from 'components/controls/PerDateField';
import { DurationInMinutes } from 'components/controls/DurationInMinutes';

import type { SubscriptionPlanModel } from 'core/interfaces';

export const panelValidate = (data: SubscriptionPlanModel, maxErrorTextTemplate = '-') => {
    const validator = new FormValidator(data);
    const gbError = `${formatStr(maxErrorTextTemplate, 1024 * 1024)}!immediately`;
    const tbError = `${formatStr(maxErrorTextTemplate, 1024)}!immediately`;
    const getMaxErrorText = (propName: string) => {
        const val = data[propName] as number;
        if (val && val % FileSizeUnitsInBytes.TB === 0) {
            return tbError;
        }

        return gbError;
    };

    validator.validate('crVmStoragePrice')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('crVmComputeTimePrice')
        .number()
        .min(0)
        .max(1000000);

    validator
        .validate('crVmTrafficPrice')
        .number()
        .min(0)
        .max(1000000);

    validator
        .validate('crVmStorageFree')
        .number()
        .min(0)
        .max(FileSizeUnitsInBytes.TB * 1024, getMaxErrorText('crVmStorageFree'));
    validator
        .validate('cRVmComputeTimeFreeMagnitude')
        .number()
        .min(0)
        .max(9999);

    return validator.result();
};

interface Props extends WizardStepData<SubscriptionPlanModel> {
    hide: () => void;
}

export const CloudConnectReplicationAdvanced = ({ hide, onDataChange, validationState, data }: Props) => {
    const lang = useLang();
    const [localData] = useState<SubscriptionPlanModel>(() => Object.assign({}, data));
    const currencyPer = formatStr(lang.CURRENCY_PER, data.currency);

    return (
        <SidePanelForm
            onRequestClose={hide}
            title={lang.VEEAM_CLOUD_CONNECT_REPLICATION_ADVANCED_SETTINGS}
            description={lang.SET_PRICE_FOR_THE_CLOUD_STORAGE}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        if (isValid(panelValidate, localData)) {
                            Object.assign(data, localData);
                            onDataChange(data);
                            hide();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hide,
                },
            ]}
        >
            <Form
                value={localData}
                validate={data => panelValidate(data, lang.MAX_VALUE_IS)}
                validationState={validationState}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.CLOUD_HOST}</FormTitle>
                    <FormDescription>{lang.DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_VMS_REPLICATED}</FormDescription>

                    <PerBytesField
                        label={`${lang.CLOUD_STORAGE}:`}
                        valueName={'crVmStoragePrice'}
                        unitsName={'crVmStorageMultiplier'}
                        suffix={currencyPer}
                        unitSize={CONTROL_SIZE.xs}
                    />

                    <PerDateField
                        label={`${lang.COMPUTE_RESOURCES}:`}
                        valueName={'crVmComputeTimePrice'}
                        unitsName={'crVmComputeTimeMultiplier'}
                        suffix={currencyPer}
                        suffix2={lang.PER_VM}
                        unitSize={CONTROL_SIZE.xs}
                    />

                    <FormTitle>{capitalize(lang.DATA_TRANSFER_OUT, true)}</FormTitle>

                    <PerBytesField
                        label={`${capitalize(RCOP.Lang.DATA_TRANSFER_OUT, true)}:`}
                        valueName={'crVmTrafficPrice'}
                        unitsName={'crVmTrafficMultiplier'}
                        suffix={currencyPer}
                    />

                    <FormTitle>{lang.FREE_OF_CHARGE}</FormTitle>

                    <DataSizeInBytes
                        name={'crVmStorageFree'}
                        unitSize={CONTROL_SIZE.xs}
                        label={lang.CLOUD_STORAGE}
                        allowDecimal={false}
                    />

                    <DurationInMinutes
                        label={`${lang.COMPUTE_RESOURCES}:`}
                        unitSize={CONTROL_SIZE.xs}
                        name={'crVmComputeTimeFree'}
                        magnitudeName={'cRVmComputeTimeFreeMagnitude'}
                        allowDecimal={false}
                    />
                </FormLayout>
            </Form>
        </SidePanelForm>
    );
};
