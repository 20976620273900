/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { ICON_SIZES } from '@veeam/components';

import { useIntervalRequest } from 'views/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { TableWidget } from 'views/components/TableWidget';
import { Product, AlarmStatus } from '../../enums';
import agent from '../../../../../images/agent.svg';
import agentNotInstalled from '../../../../../images/agent-not-installed.svg';
import { NameColumn } from 'views/components/TableWidget/components/NameColumn';
import { NoInfoColumn } from 'views/components/TableWidget/components/NoInfoColumn';
import { HealthyColumn } from 'views/components/TableWidget/components/HealthyColumn';
import { ErrorsColumn } from 'views/components/TableWidget/components/ErrorsColumn';
import { WarningsColumn } from 'views/components/TableWidget/components/WarningsColumn';
import { EmptyColumnPlaceholder } from 'views/components/TableWidget/components/EmptyColumnPlaceholder';
import { TOGGLE_FILTER_ALL_VALUE } from '../../configs';
import { useAppServices } from '../../../../providers/AppProvider/hooks';

import type { BackupAgentInfrastructureResponse } from '../../interfaces';

export const VeeamAgents: React.FC = () => {
    const lang = useLang();
    const { enumMapperService } = useAppServices();
    const request = useIntervalRequest<{}, BackupAgentInfrastructureResponse>('Overview/BackupAgentInfrastructureAlarms');
    const items = (request.data as BackupAgentInfrastructureResponse)?.every(item => item.overallCount === 0)
        ? []
        : (request.data as BackupAgentInfrastructureResponse);

    return (
        <TableWidget
            title={lang.VEEAM_AGENT}
            height={132}
            data={items}
            shiftColumns
            columns={[
                {
                    cellRenderer: ({ rowData, key }) => (
                        <NameColumn
                            icon={agent}
                            name={enumMapperService.getEnumDescription('BackupAgentInfrastructureObjectType', rowData.itemType)}
                            key={key}
                        />
                    ),
                },
                {
                    cellRenderer: ({ rowData, key }) => {
                        if (rowData.overallCount === 0) {
                            return (
                                <React.Fragment key={key}>
                                    <NoInfoColumn />
                                    <EmptyColumnPlaceholder />
                                </React.Fragment>
                            );
                        }

                        if (rowData.errors.value === 0 && rowData.warnings.value === 0) {
                            return (
                                <React.Fragment key={key}>
                                    <HealthyColumn />
                                    <EmptyColumnPlaceholder />
                                </React.Fragment>
                            );
                        }

                        const columns = [];

                        if (rowData.errors.value > 0) {
                            columns.push(<ErrorsColumn count={rowData.errors.value} key='errors' />);
                        }

                        if (rowData.warnings.value > 0) {
                            columns.push(<WarningsColumn count={rowData.warnings.value} key='warnings' />);
                        }

                        while (columns.length < 2) {
                            columns.push(<EmptyColumnPlaceholder key={columns.length} />);
                        }

                        return columns;
                    },
                },
            ]}
            onClick={() => {
                const filters = `statusesFilter/${AlarmStatus.Warning},${AlarmStatus.Error}/productsFilter/${Product.VBA}/` +
                    `dateFilter/${TOGGLE_FILTER_ALL_VALUE}/scopesFilter/${TOGGLE_FILTER_ALL_VALUE}`;

                window.location.assign(`#home/activeAlarms/tab/alarms/${filters}`);
            }}
            loading={request.loading}
            noDataConfig={{
                linkText: lang.START_MANAGING_VEEAM_AGENT,
                icon: agentNotInstalled,
                iconSize: ICON_SIZES.xl,
                linkHandler: () => window.location.assign('#home/discovery/tab/discoveredComputersTab/s/discoveredComputers'),
            }}
        />
    );
};
