/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { ColumnTexts, LinkButton, PortalSpinner, TextColumn } from '@veeam-vspc/components';
import { EVbFlrRestoreStatus } from '@veeam-vspc/models/web-controllers';
import { TransportMethods } from '@veeam-vspc/core';

import type {
    GetVbRestoreSessionWorkerLinkParams,
    VbFlrRestoreStatusModel,
    VbPluginGetFlrSessionWorkerLinkResponse,
    EPublicCloudRestoreType } from '@veeam-vspc/models/web-controllers';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { StatusColumn } from 'views/components/columns/StatusColumn';

export interface FlrPortalColumnProps {
    instanceUid: string;
    vbServerId: string;
    restoreType: EPublicCloudRestoreType;
    restoreStatusModel: VbFlrRestoreStatusModel | null;
}

export const FlrPortalColumn: React.FC<FlrPortalColumnProps> = ({ instanceUid, restoreType, restoreStatusModel, vbServerId }) => {
    const lang = useLang();
    const { transportService, notificationService } = useAppServices();
    const [loading, setLoading] = useState(false);

    if (!restoreStatusModel) {
        return (
            <TextColumn>{ColumnTexts.DashSymbol}</TextColumn>
        );
    }

    const { sessionId, status } = restoreStatusModel;

    if (status === EVbFlrRestoreStatus.Pending) {
        return (
            <StatusColumn status={status} />
        );
    }

    return (
        <>
            <TextColumn>
                <LinkButton
                    onClick={() => {
                        setLoading(true);

                        transportService.request<GetVbRestoreSessionWorkerLinkParams, VbPluginGetFlrSessionWorkerLinkResponse>(
                            '/VbPlugin/GetFlrSessionWorkerLink',
                            {
                                itemUid: instanceUid,
                                vbServerId,
                                restoreSessionUid: sessionId,
                                restoreType,
                                backUrl: window.location.href,
                            },
                        )
                            .then((resp) => {
                                const linkUrl = (resp as BaseSuccessRequestResponse<string>).data as string;

                                return fetch(linkUrl, { method: TransportMethods.Get })
                                    .then(resp => resp.ok ? linkUrl : Promise.reject('unable'))
                                    .catch(() => Promise.reject('unable'));
                            })
                            .then((linkUrl) => {
                                setLoading(false);
                                window.open(linkUrl, '_blank', 'noopener,noreferrer');
                            })
                            .catch((err) => {
                                setLoading(false);

                                if (err === 'unable') {
                                    notificationService.error(
                                        lang.FILE_LEVEL_RESTORE_PORTAL,
                                        lang.UNABLE_TO_PERFORM_THE_OPERATION_NETWORK_SETTINGS_FOR,
                                    );
                                } else {
                                    err && console.error(err);
                                }
                            });
                    }}
                >
                    {lang.RESTORE_PORTAL}
                </LinkButton>
            </TextColumn>

            {loading && <PortalSpinner />}
        </>
    );
};
