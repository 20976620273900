/**
 * Copyright © Veeam Software Group GmbH.
 */

import { observable } from 'mobx';
import { ExternalFormApi, ValidationState } from '@veeam-vspc/components';
import { OAuth2ClientSettings, SmtpSettings, NotificationSettings } from '@veeam-vspc/models/rest';

import { DEFAULT_PASSWORD } from 'core/const';
import { EmailServerSettingsFormData } from '../components/EmailServerSettingsDialog/interfaces';
import { FormFieldNames, AuthTypes, UrlProtocols } from '../components/EmailServerSettingsDialog/enums';

import type { HostAndPort } from '../components/EmailServerSettingsDialog/interfaces';

export class EmailServerSettingsStore {
    readonly redirectUrl = window.location.origin;
    readonly defaultPasswordValue = DEFAULT_PASSWORD;

    @observable isOAuth2Authorized: boolean;
    @observable passwordInputHasEye = false;
    @observable emailServerTestSmtpSettings: SmtpSettings;
    @observable storeFormValidationState: ValidationState;
    @observable storeFormData: EmailServerSettingsFormData;
    @observable storeFormApi: ExternalFormApi<EmailServerSettingsFormData>;
    @observable originalEmailServerSettings: NotificationSettings;

    constructor(emailServerSettings: NotificationSettings, formApi: ExternalFormApi<EmailServerSettingsFormData>) {
        this.storeFormApi = formApi;
        this.storeFormValidationState = new ValidationState();
        this.originalEmailServerSettings = emailServerSettings;

        const { smtp } = emailServerSettings;
        const { host, port } = this.getHostAndPortFromURL(smtp?.serverAddress);
        this.isOAuth2Authorized = Boolean(smtp?.oAuth2Credential?.accessToken);

        this.storeFormData = {
            [FormFieldNames.Host]: host,
            [FormFieldNames.Port]: port,
            [FormFieldNames.UseAuth]: Boolean(smtp?.passwordCredential),
            [FormFieldNames.AuthType]: Boolean(smtp?.oAuth2Credential) ? AuthTypes.Modern : AuthTypes.Basic,
            smtp: {
                tlsMode: smtp?.tlsMode || SmtpSettings.TlsModeEnum.Auto,
                timeout: smtp?.timeout || '00:00:30',
                serverAddress: smtp?.serverAddress || '',
                passwordCredential: {
                    userName: smtp?.passwordCredential?.userName || '',
                    password: smtp?.passwordCredential?.userName ? this.defaultPasswordValue : '',
                },
                oAuth2Credential: {
                    clientSettings: {
                        clientId: smtp?.oAuth2Credential?.clientSettings?.clientId || '',
                        tenantId: smtp?.oAuth2Credential?.clientSettings?.tenantId || '',
                        clientSecret: smtp?.oAuth2Credential?.clientSettings?.clientSecret || '',
                        kind: smtp?.oAuth2Credential?.clientSettings?.kind || OAuth2ClientSettings.KindEnum.Google,
                    },
                },
            },
        };
    }

    getHostAndPortFromURL = (urlString = ''): HostAndPort => {
        if (!urlString) {
            return {
                host: '',
                port: 25,
            };
        }

        const url = new URL(urlString.replace(UrlProtocols.Smtp, UrlProtocols.Https));
        const { hostname: host } = url;
        const port = +url.port || 25;

        return { host, port };
    };
}
