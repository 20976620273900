/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/core';
import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Checkbox,
    Form,
    SPACE_FILL,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    useExternalFormApi,
    View,
} from '@veeam-vspc/components';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { GridStore, RequestParams, WizardStepData } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { RadioGroup } from 'components/controls/RadioGroup';
import { core } from 'core/core-module';
import { CloudGatewaySelectionType } from 'core/enums';

import type { GatewayPoolModel, CompanyData } from '../../../../interfaces';

interface Props extends WizardStepData<CompanyData> {
    hidePanel: () => void;
}

export const GatewayPools = observer((props: Props) => {
    const lang = useLang();
    const { hidePanel } = props;
    const formApi = useExternalFormApi<CompanyData>();
    const gridApi = useRef<GridStore<GatewayPoolModel, {}, {}>>();

    const [isPoolsEmpty, setIsPoolEmpty] = useState(true);
    const [data, setData] = useState(deepCopy(props.data));

    return (
        <SidePanelForm
            title={lang.GATEWAY_POOLS}
            description={lang.CHOOSE_GATEWAY_POOL_FOR_THIS_COMPANY}
            onRequestClose={hidePanel}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        if (data.gatewaySelectionType === CloudGatewaySelectionType.GatewayPool && gridApi.current.selected.length === 0) {
                            core.notificationService.warning(lang.WARNING, lang.SELECT_AT_LEAST_ONE_GATEWAY_POOL);
                        } else {
                            data.gatewayPool = gridApi.current.selected;
                            Object.assign(props.data, data);
                            props.onDataChange(props.data);

                            hidePanel();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} spaceFill={SPACE_FILL.all} gap={STACK_GAP.s}>
                <Form <CompanyData>
                    value={data}
                    externalFormApi={formApi}
                    onChange={(v) => {
                        setData({ ...v });
                    }}
                >
                    <RadioGroup
                        value={data.gatewaySelectionType}
                        onChange={(value: CloudGatewaySelectionType) => {
                            formApi.setValue('gatewaySelectionType', value);
                            if (value === CloudGatewaySelectionType.StandaloneGateways) {
                                gridApi.current.disable();
                            } else {
                                gridApi.current.enable();
                            }
                        }}
                        buttons={[
                            {
                                label: lang.AUTOMATIC_SELECTION,
                                value: CloudGatewaySelectionType.StandaloneGateways,
                                helpText: lang.THE_LEAST_LOADED_CLOUD_GATEWAY_NOT,
                            },
                            {
                                label: lang.SELECTED_GATEWAY_POOL,
                                value: CloudGatewaySelectionType.GatewayPool,
                                helpText: lang.THE_LEAST_LOADED_CLOUD_GATEWAY_FROM,
                                disabled: isPoolsEmpty,
                            },
                        ]}
                    />
                </Form>

                <AdvancedGrid
                    initialState={{
                        disabled: data.gatewaySelectionType === CloudGatewaySelectionType.StandaloneGateways,
                        selected: data.gatewayPool,
                    }}
                    defaultColumnWidth={100}
                    columns={[
                        { field: 'name', title: lang.GATEWAY_POOL_NAME },
                        {
                            field: 'numberOfGateways',
                            title: lang.N_OF_GATEWAY_SERVERS,
                        },
                    ]}
                    data={(requestParams: RequestParams<{}>) => core.transportService.request(
                        'CloudConnectServers/GetCloudGatewayPools',
                        { cloudAgentId: { id: data.cloudConnectAgentUid }, ...requestParams }
                    )
                        .then((resp: BaseSuccessRequestResponse<GatewayPoolModel[]>) => {
                            setIsPoolEmpty(resp['data'].length === 0);
                            return resp;
                        })}
                    toggleable={false}
                    disablePagination={true}
                    disableAutoUpdate={true}
                    selection={{
                        field: 'instanceUid',
                    }}
                    api={gridApi}
                />

                <View>
                    <Form <CompanyData>
                        value={data}
                        onChange={(v) => {
                            setData({ ...v });
                        }}
                    >
                        <Checkbox
                            name={'gatewayFailoverEnabled'}
                            disabled={data.gatewaySelectionType === CloudGatewaySelectionType.StandaloneGateways}
                        >
                            {lang.FAILOVER_TO_OTHER_CLOUD_GATEWAYS}
                        </Checkbox>
                    </Form>
                </View>
            </StackView>
        </SidePanelForm>
    );
});
