/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum StatusTypes {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',

    Running = 'running',

    Syncing = 'syncing',

    Stopped = 'stopped',
    Stopping = 'stopping',
    Pending = 'pending',
    Starting = 'starting',
    Updating = 'updating',
    Created = 'created',
    Enabled = 'enabled',

    Acknowledged = 'acknowledge',
    Installed = 'backup-agent-type-installed',
    NotInstalled = 'backup-agent-type-not-installed',
    Unlicensed = 'unlicensed-backup-server',
}
