/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable } from 'mobx';
import { GoogleCloudObjectType } from '@veeam-vspc/models/web-controllers';

export class ProtectedGcpWorkloadsStore {
    @observable googleCloudObjectType = GoogleCloudObjectType.Vm;

    @action.bound
    setGoogleCloudObjectType(v: GoogleCloudObjectType): void {
        this.googleCloudObjectType = v;
    }
}
