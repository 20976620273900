/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { createLinkControl } from '@veeam/components';
import { BackupServicesType, TimeUnit } from '@veeam-vspc/models/web-controllers';
import { FieldLayout, STACK_DIRECTION, STACK_GAP, StackView } from '@veeam-vspc/components';

import { FilterContext } from '../../contexts';
import { FilterStore } from '../../stores';
import { ManagedBackupAgents } from './components/ManagedBackupAgents';
import { ManagedVms } from './components/ManagedVms';
import { MonitoredObjects } from './components/MonitoredObjects';
import { ProtectedAwsWorkloads } from './components/ProtectedAwsWorkloads';
import { ProtectedAzureWorkloads } from './components/ProtectedAzureWorkloads';
import { ProtectedFileShares } from './components/ProtectedFileShares';
import { ProtectedGcpWorkloads } from './components/ProtectedGcpWorkloads';
import { ProtectedMicrosoft365Objects } from './components/ProtectedMS365Objects';
import { StatefulCombobox } from '../StatefulCombobox';
import { WidgetColumnsStyled, WidgetViewStyled, WidgetRowsStyled } from '../../../../../components/layouts/WidgetView';
import { useFilterStore } from '../../hooks';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { ComboboxItem } from '../../interfaces';

const BackupServicesLayout = observer(() => {
    const filterStore = useFilterStore();
    const lang = useLang();

    return (
        <WidgetViewStyled>
            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.xl}>
                <StatefulCombobox
                    controlRenderer={createLinkControl()}
                    label={`${lang.REPORTING_PERIOD}:`}
                    onChange={filterStore.setReportingPeriod}
                    stateId={'trends.backup-servers.reporting-period'}
                    textGetter={(p: ComboboxItem) => p.text}
                    value={filterStore.reportingPeriod}
                    valueGetter={(p: ComboboxItem) => p.id}
                    data={[
                        { id: TimeUnit.Weeks, text: lang.WEEKLY },
                        { id: TimeUnit.Months, text: lang.MONTHLY },
                    ]}
                />

                <FieldLayout inlineLabel label={`${lang.TYPE}:`}>
                    <StatefulCombobox
                        controlRenderer={createLinkControl()}
                        onChange={filterStore.setBackupServicesType}
                        stateId={'trends.backup-servers.backup-service-type'}
                        textGetter={(p: ComboboxItem) => p.text}
                        value={filterStore.backupServicesType}
                        valueGetter={(p: ComboboxItem) => p.id}
                        data={[
                            { id: BackupServicesType.All, text: lang.ALL },
                            { id: BackupServicesType.Managed, text: lang.CLIENT },
                            { id: BackupServicesType.Hosted, text: lang.HOSTED },
                        ]}
                    />
                </FieldLayout>
            </StackView>

            <WidgetColumnsStyled>
                <WidgetRowsStyled>
                    <ManagedBackupAgents />
                    <ProtectedFileShares />
                    <ProtectedMicrosoft365Objects />
                    <ProtectedAzureWorkloads />
                </WidgetRowsStyled>

                <WidgetRowsStyled>
                    <ManagedVms />
                    <MonitoredObjects />
                    <ProtectedAwsWorkloads />
                    <ProtectedGcpWorkloads />
                </WidgetRowsStyled>
            </WidgetColumnsStyled>
        </WidgetViewStyled>
    );
});

export const BackupServices: React.FC = () => {
    const filterStore = useMemo(() => new FilterStore(), []);

    return (
        <FilterContext.Provider value={filterStore}>
            <BackupServicesLayout />
        </FilterContext.Provider>
    );
};
