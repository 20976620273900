/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { toolbarItemDecorators, TOOLBAR_ITEM_TYPE, useGridApi } from '@veeam-vspc/components';

import type { Vb365ProtectedObject } from '@veeam-vspc/models/rest';

import { core } from 'core/core-module';
import flrActionIconSrc from 'images/actions/flr.png';
import { useLang } from 'views/providers/LangProvider/hooks';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { alternateTransformRequestParams } from 'core/utils';


export const VBObjectsActionToolbar: React.FC = () => {
    const lang = useLang();
    const gridApi = useGridApi<Vb365ProtectedObject, any, any>();

    return (
        <ToolbarWithExport
            RESTMethod='protectedWorkloads/vb365ProtectedObjects/'
            extraParams={alternateTransformRequestParams(gridApi.requestParams)}
            selectedOnly
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.button,
                    iconSrc: flrActionIconSrc,
                    text: `${lang.RESTORE_PORTAL}...`,
                    onClick: () => {
                        const { fileRestorePortalUrl, isFileRestorePortalEnabled } = gridApi.selected[0];

                        if (!isFileRestorePortalEnabled || !fileRestorePortalUrl) {
                            return core.notificationService.warning(lang.WARNING, `${lang.THE_RESTORE_PORTAL_IS_NOT_CONFIGURED}.`);
                        }

                        window.open(fileRestorePortalUrl, '_blank', 'noopener,noreferrer');
                    },
                    decorators: [
                        toolbarItemDecorators.disallowWithoutSingleSelection(),
                    ],
                },
            ]}
        />
    );
};
