/**
 * Copyright © Veeam Software Group GmbH.
 */

import { isValid } from '@veeam-vspc/components';
import { VbrDeploymentLicenseSourceType } from '@veeam-vspc/models/web-controllers';

import { core } from 'core/core-module';
import { formValidate } from './formValidate';
import { getXmlFromFile } from '../../helpers';
import { checkLicense } from '../../helpers/check-license';

import type { VbrDeploySettingsWizardModel } from '../../entries';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const stepValidate = async(lang: LangsServiceBase, data: VbrDeploySettingsWizardModel) => {
    if (data.licenseSource === VbrDeploymentLicenseSourceType.AnswerFile && data.installationParametersFile) {
        return getXmlFromFile(data.installationParametersFile[0])
            .then((xml) => {
                const licenseIsValid = checkLicense(xml);

                if (!licenseIsValid) {
                    core.notificationService.warning(lang.CONFIGURATION_FILE, lang.THE_CONFIGURATION_FILE_INCLUDES);
                }

                return licenseIsValid;
            })
            .catch(() => {
                data.installationParametersFile = undefined;
                core.notificationService.error(lang.CONFIGURATION_FILE, lang.CANNOT_DETECT_THE_CONFIGURATION_FILE);

                return false;
            });
    }

    return isValid(formValidate(lang), data);
};
