/**
 * Copyright © Veeam Software Group GmbH.
 */

import { SobrUsageType } from '@veeam-vspc/models/web-controllers';

import { core } from 'core/core-module';
import { transformBaseChartData } from 'views/pages/TrendsPage/helpers';

import type { BaseDataResponse } from 'core/services/transport/interfaces/base-data-response';
import type { LineChartData, TrendData } from '../../../../../interfaces';

const names = [
    { label: 'Performance Tier', type: SobrUsageType.PerfTier },
    { label: 'Capacity Tier', type: SobrUsageType.CapacityTier },
    { label: 'Archive Tier', type: SobrUsageType.ArchiveTier },
    { label: 'Object Storage', type: SobrUsageType.ObjectStorage },
    { label: 'Regular Storage', type: SobrUsageType.RegularStorage },
];

export class SobrUsageService {
    static fetchData({ formatDimension = true, reportingPeriodInfo }): Promise<LineChartData> {
        return core.transportService
            .request('Trends/CloudSobrUsage', { ...reportingPeriodInfo })
            .then((resp: BaseDataResponse<TrendData>) => transformBaseChartData(resp.data.trends, reportingPeriodInfo, names, formatDimension));
    }
}
