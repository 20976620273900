/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy } from '@veeam-vspc/core';

import { PortalUserRoles } from 'core/entries/portal-user/enums';
import {
    companyAdministratorConfig,
    companyInvoiceAuditorConfig,
    companyOwnerConfig,
    localAdministratorConfig,
    locationAdministratorConfig,
    locationUserConfig,
    portalAdministratorConfig,
    portalOperatorConfig,
    portalReadonlyOperatorConfig,
    serviceProviderAdministratorConfig,
    serviceProviderGlobalAdministratorConfig,
    serviceProviderInvoiceAuditorConfig,
    serviceProviderOperatorConfig,
    serviceProviderUserConfig,
    siteAdministratorConfig,
    subtenantConfig,
} from '../configs';
import { RouteConfigItem, SectionConfigItem } from '../entries';

import type { BaseUserConfig, RouteConfigItemData, SectionConfigItemData, UserConfig, BaseConfigItemData } from '../interfaces';

export class ConfigService {

    protected allConfigs: Record<PortalUserRoles, BaseUserConfig> = {
        [PortalUserRoles.PortalAdministrator]: portalAdministratorConfig,
        [PortalUserRoles.LocationAdministrator]: locationAdministratorConfig,
        [PortalUserRoles.LocationUser]: locationUserConfig,
        [PortalUserRoles.CompanyOwner]: companyOwnerConfig,
        [PortalUserRoles.CompanyInvoiceAuditor]: companyInvoiceAuditorConfig,
        [PortalUserRoles.Subtenant]: subtenantConfig,
        [PortalUserRoles.PortalOperator]: portalOperatorConfig,
        [PortalUserRoles.ServiceProviderGlobalAdministrator]: serviceProviderGlobalAdministratorConfig,
        [PortalUserRoles.ServiceProviderOperator]: serviceProviderOperatorConfig,
        [PortalUserRoles.ServiceProviderUser]: serviceProviderUserConfig,
        [PortalUserRoles.ServiceProviderInvoiceAuditor]: serviceProviderInvoiceAuditorConfig,
        [PortalUserRoles.ServiceProviderAdministrator]: serviceProviderAdministratorConfig,
        [PortalUserRoles.SiteAdministrator]: siteAdministratorConfig,
        [PortalUserRoles.CompanyAdministrator]: companyAdministratorConfig,
        [PortalUserRoles.PortalReadonlyOperator]: portalReadonlyOperatorConfig,
        [PortalUserRoles.LocalAdministrator]: localAdministratorConfig,
        [PortalUserRoles.Unknown]: portalReadonlyOperatorConfig,
    };

    getConfigFor(userRole: PortalUserRoles): Promise<UserConfig> {
        return this.prepareUserConfigFor(userRole);
    }

    protected prepareUserConfigFor(userRole: PortalUserRoles): Promise<UserConfig> {
        return this.processUserConfigExtension(userRole)
            .then((baseUserConfig) => {
                const { routes, sections, ...restProps } = baseUserConfig;

                return {
                    ...restProps,
                    routes: routes.map(route => new RouteConfigItem(route)),
                    sections: sections.map(section => new SectionConfigItem(section)),
                };
            });
    }

    protected processUserConfigExtension(userRole: PortalUserRoles): Promise<BaseUserConfig | null> {
        return this.getBaseUserConfig(userRole)
            .then((baseUserConfig) => {
                if (!baseUserConfig) {
                    console.error(`Wrong user role: "${userRole}"`);
                    return null;
                }

                const { baseConfig, routes, sections, ...restProps } = baseUserConfig;

                return {
                    ...restProps,
                    routes: baseConfig ? this.mergeArraysAndRemoveDisabled<RouteConfigItemData>(baseConfig.routes, routes) : routes,
                    sections: baseConfig ? this.mergeArraysAndRemoveDisabled<SectionConfigItemData>(baseConfig.sections, sections) : sections,
                };
            });
    }

    mergeArraysAndRemoveDisabled<T extends BaseConfigItemData<string>>(parent: T[] = [], child: T[] = []): T[] {
        return this.mergeArraysById(parent, child)
            .filter(item => !item.disableRule);
    }

    protected mergeArraysById<T extends BaseConfigItemData<string>>(parent: T[] = [], child: T[] = []): T[] {
        const result = deepCopy(parent);
        let resultCache = {};
        const doCache = () => {
            resultCache = {};
            result.forEach((item, index) => {
                // do cache for speeding up merging
                resultCache[item.id] = index;
            });
        };

        doCache();

        child.forEach((item) => {
            const { id, insertBefore, insertAfter, ...restProps } = item;
            const indexInResult: void | number = resultCache[id];
            const isExist = Number.isInteger(indexInResult);

            if (isExist) {
                result[indexInResult as number] = {
                    ...result[indexInResult as number],
                    ...restProps,
                };
            } else if (
                (insertBefore && Number.isInteger(resultCache[insertBefore]))
                || (insertAfter && Number.isInteger(resultCache[insertAfter]))
            ) {
                result.splice(insertBefore ? resultCache[insertBefore] : (resultCache[insertAfter] + 1), 0, { id, ...restProps } as T);
                doCache();
            } else {
                result.push(item);
                doCache();
            }
        });

        return result;
    }

    protected getBaseUserConfig(userRole: PortalUserRoles): Promise<BaseUserConfig> {
        return Promise.resolve(this.allConfigs[userRole] ?? null);
    }

}
