/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    NoLabelGroup,
    NoteBar,
    NOTEBAR_STATUS,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TEXT_WHITE_SPACE,
} from '@veeam-vspc/components';
import styled from 'styled-components';

import { OAuthNotification } from './components';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useEmailServerSettingsStore } from '../../../../hooks';

const EmptySpaceFiller = styled(StackView)`
    height: 32px;
`;

export const EmailServerSettingsDialogNotes = observer(() => {
    const lang = useLang();
    const serverSettingsStore = useEmailServerSettingsStore();
    const { isOAuth2Authorized } = serverSettingsStore;

    return (
        <NoLabelGroup
            content={(
                <StackView
                    gap={STACK_GAP.s}
                    direction={STACK_DIRECTION.column}
                >
                    <NoteBar status={NOTEBAR_STATUS.info}>
                        <Text whitespace={TEXT_WHITE_SPACE.normal}>
                            {lang.TO_AUTHORIZE_THE_APPLICATION}
                        </Text>
                    </NoteBar>

                    {isOAuth2Authorized
                        ? <OAuthNotification />
                        : <EmptySpaceFiller />}
                </StackView>
            )}
        />
    );
});
