/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    constants,
    FieldLayout,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    Text,
    STACK_ALIGN,
    CONTROL_SIZE,
    Form,
    useExternalFormApi,
    NumberInput,
    FormValidator,
    TOOLBAR_ITEM_TYPE,
    ComboboxKit,
    isValid,
    SPACING_M,
} from '@veeam-vspc/components';
import { Toolbar } from '@veeam/components';
import { debounce } from '@veeam-vspc/core';
import { observer } from 'mobx-react-lite';

import type { FormErrors } from '@veeam-vspc/components';

import { TimePeriod } from '../../enums';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useProtectedDataSummaryStore } from '../../store';

import type { ProtectedDataSummaryFilters } from '../../interfaces';
interface ProtectedDataSummaryToolbarProps {
    onChange: (params: ProtectedDataSummaryFilters) => void;
}

const formValidate = (data: ProtectedDataSummaryFilters): FormErrors<ProtectedDataSummaryFilters> => {
    const validator = new FormValidator(data);

    validator
        .validate('slaPercent')
        .number()
        .min(1)
        .max(100)
        .required();

    validator
        .validate('rpoDays')
        .number()
        .min(1)
        .max(365)
        .required();

    return validator.result();
};

export const ProtectedDataSummaryToolbar: React.FC<ProtectedDataSummaryToolbarProps> = observer(({ onChange }) => {
    const store = useProtectedDataSummaryStore();
    const lang = useLang();
    const formApi = useExternalFormApi();

    const handleChange = debounce((params: ProtectedDataSummaryFilters) => {
        const isFormValid = isValid(formValidate, params);

        if (isFormValid) {
            store.setFilters(params);
            onChange(params);
        }
    }, 500);

    const items = [
        {
            type: TOOLBAR_ITEM_TYPE.customControl,
            render: () => (
                <FieldLayout
                    label={lang.RPO}
                    suffix={lang.DAYS}
                    inlineLabel
                >
                    <NumberInput
                        name='rpoDays'
                        minValue={1}
                        maxValue={365}
                        size={CONTROL_SIZE.xxs}
                    />
                </FieldLayout>
            ),
        },
        {
            type: TOOLBAR_ITEM_TYPE.separator,
        },
        {
            type: TOOLBAR_ITEM_TYPE.customControl,
            render: () => (
                <FieldLayout
                    label={`${lang.SLA} (${lang.MICROSOFT_365_ONLY})`}
                    suffix={'%'}
                    inlineLabel
                >
                    <NumberInput
                        name='slaPercent'
                        minValue={1}
                        maxValue={100}
                        size={CONTROL_SIZE.xxs}
                    />
                </FieldLayout>
            ),
        },
        {
            type: TOOLBAR_ITEM_TYPE.customControl,
            render: () => (
                <FieldLayout
                    label={`${lang.TIME_PERIOD}:`}
                    inlineLabel
                    style={{ marginLeft: `-${SPACING_M}` }}
                >
                    <ComboboxKit
                        value={store.timePeriod}
                        data={[
                            { id: TimePeriod.CurrentMonth, name: lang.CURRENT_MONTH },
                            { id: TimePeriod.PreviousMonth, name: lang.PREVIOUS_MONTH },
                            { id: TimePeriod.Last30Days, name: lang.LAST_30_DAYS },
                        ]}
                        valueGetter={item => item.id}
                        textGetter={item => item.name}
                        onChange={id => formApi.setValue('timePeriod', id)}
                    />
                </FieldLayout>
            ),
        },
    ];

    return (
        <StackView
            direction={STACK_DIRECTION.row}
            align={STACK_ALIGN.center}
            gap={STACK_GAP.m}
            style={{ height: constants.SIZE_XL }}
        >
            <Text>{`${lang.PARAMETERS}: `}</Text>

            <Form
                value={store.filters}
                validate={formValidate}
                externalFormApi={formApi}
                onChange={handleChange}
            >
                <Toolbar
                    items={items}
                />
            </Form>
        </StackView>
    );
});
