/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';

import type { AppStorage } from '@veeam-vspc/core';

import { AppContext } from './stores';
import { useAppInit } from './hooks';
import { AppContent } from './components/AppContent/AppContent';

import type { PortalData } from 'core/entries';
import type { AppContextData, AppServices } from './interfaces';
import type { PageProps } from '../RouterProvider/interfaces';


interface AppProviderProps extends AppServices {
    appStorage: AppStorage;
    pageName?: string;
    portalData?: PortalData;
    pageProps?: PageProps;
}

export const AppProvider: React.FC<AppProviderProps> = ({ appStorage, pageName, portalData, pageProps = {}, ...restProps }) => {
    const { viewType, appStore, appActions } = useAppInit(restProps, { pageName, portalData });
    const appContextData: AppContextData = useMemo(() => ({
        data: appStore,
        services: restProps,
        actions: appActions,
        appStorage, // @TODO Remove when finish switching to full React App
    }), [appStore, restProps, appActions, appStorage]);

    return (
        <AppContext.Provider value={appContextData}>
            <AppContent viewType={viewType} pageName={pageName} pageProps={pageProps} />
        </AppContext.Provider>
    );
};
