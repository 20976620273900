/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { EmailServerSettingsStoreContext } from '../stores';

import type { EmailServerSettingsStore } from '../stores';

export const useEmailServerSettingsStore = (): EmailServerSettingsStore => useContext(EmailServerSettingsStoreContext);
