/**
 * Copyright © Veeam Software Group GmbH.
 */

import { PortalUserRoles } from '../../../entries/portal-user/enums';
import { baseConfig } from './base.config';

import type { BaseUserConfig } from '../interfaces';

export const portalAdministratorConfig: BaseUserConfig = {
    baseConfig: baseConfig,
    roleInt: PortalUserRoles.PortalAdministrator,
    roleName: PortalUserRoles[PortalUserRoles.PortalAdministrator] as keyof typeof PortalUserRoles,
    routes: [],
    sections: [],
};
