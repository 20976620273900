/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { ACTION_VIEW, MessageBox, useModal } from '@veeam-vspc/components';
import { formatStr } from '@veeam-vspc/core/utils/string-helpers/string-helpers';
import { MESSAGE_BOX_ICON_VIEW } from '@veeam/components/lib/MessageBox/MessageBox';

import type { UseModalActions } from '@veeam-vspc/components';
import type { SmtpSettingsServerCertificate } from '@veeam-vspc/models/rest/smtpSettingsServerCertificate';

import { CertificateInfoDialog } from './components';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useEmailServerSettingsStore } from '../../../../hooks';

import type { UntrustedCertificateDialogProps } from './interfaces';

export const UntrustedCertificateDialog: React.FC<UntrustedCertificateDialogProps> = observer((props) => {
    const lang = useLang();
    const { deactivateDialog, onSaveCallback } = props;
    const serverSettingsStore = useEmailServerSettingsStore();
    const serverAddress = serverSettingsStore.emailServerTestSmtpSettings.serverAddress;
    const certInfoData = serverSettingsStore.emailServerTestSmtpSettings.serverCertificate;
    const message = formatStr(lang.CANNOT_VERIFY_THE_SECURITY, serverAddress);

    const [certificateInfoDialog, certificateInfoDialogActions] = useModal<SmtpSettingsServerCertificate>({
        render: ({ deactivate, data }) => (
            <CertificateInfoDialog deactivateDialog={deactivate} {...data} />
        ),
    });

    const showCertInfo = (certInfo: SmtpSettingsServerCertificate) => (certInfo && certificateInfoDialogActions.activate(certInfo));

    const saveChanges = (deactivateModal: UseModalActions['deactivate']) => {
        onSaveCallback();
        deactivateModal();
    };

    return (
        <>
            <MessageBox
                header={lang.UNTRUSTED_SECURITY_CERTIFICATE}
                iconView={MESSAGE_BOX_ICON_VIEW.info}
                onRequestClose={deactivateDialog}
                actions={[
                    { text: `${lang.VIEW}...`, onClick: () => showCertInfo(certInfoData) },
                    { text: lang.CONTINUE, view: ACTION_VIEW.secondary, onClick: () => saveChanges(deactivateDialog) },
                    { text: lang.CANCEL, view: ACTION_VIEW.secondary, onClick: deactivateDialog },
                ]}
            >
                {message}
            </MessageBox>

            {certificateInfoDialog}
        </>
    );
});
