/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/components';

export const DaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr;
  z-index: 2;
  border-color: ${colors.G300};
  border-style: solid;
  border-width: 0 0 ${constants.BASE_BORDER_WIDTH} ${constants.BASE_BORDER_WIDTH};
  flex-grow: 1;
`;
