/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    TextInput,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';
import { deepCopy } from '@veeam-vspc/core/index';

import { useLang } from 'views/providers/LangProvider/hooks';
import { RadioGroup } from 'components/controls/RadioGroup';
import { trimTextFields } from 'core/utils/string-helpers';
import { IpAddressField } from 'components/controls/IpAddressField';
import { getIp4Validator } from 'core/utils/validators';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { NetworkExtensionBase } from '../../../../interfaces';

interface Props<T extends NetworkExtensionBase,> {
    deactivate: () => void;
    onConfirm: (data: T) => void;

    data?: T;
}

const formValidate = <T extends NetworkExtensionBase,>(data: T, lang: LangsServiceBase) => {
    const validator = new FormValidator(data);

    validator.validate('name')
        .string()
        .required()
        .maxLength(512);

    if (!data.dhcpEnabled) {
        validator.validate('ipAddress')
            .string()
            .required()
            .check(getIp4Validator(lang));

        validator.validate('subnetMask')
            .string()
            .required()
            .check(getIp4Validator(lang));

        validator.validate('defaultGateway')
            .string()
            .required()
            .check(getIp4Validator(lang));
    }

    return validator.result();
};

export const NetworkSettingsDialog = <T extends NetworkExtensionBase,>(props: Props<T>) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.s;

    const [data, setData] = useState(deepCopy(props.data));

    const formApi = useExternalFormApi<T>();
    // const [loading, setLoading] = useState(false);
    const [validationState] = useState(new ValidationState());

    return (
        <Dialog
            header={lang.NETWORK_SETTINGS}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        // trim text fields
                        trimTextFields(data, ['name']);
                        formApi.setValue(data);

                        if (formApi.validate()) {
                            props.onConfirm && props.onConfirm(formApi.value);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.auto,
            }}
            // loading={loading}
        >
            <Form <T>
                value={data}
                validate={data => formValidate(data, lang)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(data) => {
                    setData({ ...data });
                }}
            >
                <FormLayout>
                    <TextInput
                        name={'name'}
                        label={lang.NETWORK_EXTENSION_APPLIANCE}
                        size={CONTROL_SIZE.full}
                    />

                    <TextInput
                        name={'networkName'}
                        label={lang.EXTERNAL_NETWORK}
                        size={CONTROL_SIZE.full}
                        disabled={true}
                    />

                    <RadioGroup
                        value={data.dhcpEnabled}
                        onChange={(value: boolean) => {
                            data.dhcpEnabled = value;
                            formApi.setValue(data);
                        }}
                        buttons={[
                            {
                                value: true,
                                label: lang.OBTAIN_AN_IP_ADDRESS_AUTOMATICALLY,
                            },
                            {
                                value: false,
                                label: lang.USE_THE_FOLLOWING_IP_ADDRESS,
                            },
                        ]}
                    />
                </FormLayout>

                <FormLayout inlineLabel disabled={data.dhcpEnabled}>
                    <IpAddressField
                        name={'ipAddress'}
                        label={lang.IP_ADDRESS}
                        size={defaultFieldWidth}
                    />

                    <IpAddressField
                        name={'subnetMask'}
                        label={lang.SUBNET_MASK}
                        size={defaultFieldWidth}
                    />

                    <IpAddressField
                        name={'defaultGateway'}
                        label={lang.DEFAULT_GATEWAY}
                        size={defaultFieldWidth}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
};
