/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { CloudInfrastructureObjectType, CompanyTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import { useEffect, useState } from 'react';

import { useIntervalRequest } from 'views/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { TableWidget } from 'views/components/TableWidget';
import backupServer from '../../../../../images/backup-server.svg';
import cloudGateway from '../../../../../images/cloud-gateway.svg';
import backupRepository from '../../../../../images/backup-repository.svg';
import wanAccelerator from '../../../../../images/wan-accelerator.svg';
import cloudBackupAppliance from '../../../../../images/cloud-backup-appliance.svg';
import cloudConnectedNotConnected from '../../../../../images/cloud-connect-not-connected.svg';
import { Product, AlarmStatus } from '../../enums';
import { EmptyPlate } from '../../../../components/TableWidget/components/EmptyPlate';
import { NameColumn } from 'views/components/TableWidget/components/NameColumn';
import { NoInfoColumn } from 'views/components/TableWidget/components/NoInfoColumn';
import { HealthyColumn } from 'views/components/TableWidget/components/HealthyColumn';
import { ErrorsColumn } from 'views/components/TableWidget/components/ErrorsColumn';
import { WarningsColumn } from 'views/components/TableWidget/components/WarningsColumn';
import { EmptyColumnPlaceholder } from 'views/components/TableWidget/components/EmptyColumnPlaceholder';
import { TOGGLE_FILTER_ALL_VALUE } from '../../configs';
import { useAppServices } from '../../../../providers/AppProvider/hooks';
import { useGlobalFiltersChangeHandler } from '../../../../providers/GlobalFiltersProvider/hooks';

import type { CloudInfrastructureResponse } from '../../interfaces';

const icons = {
    [CloudInfrastructureObjectType.BackupServer]: backupServer,
    [CloudInfrastructureObjectType.CloudGateway]: cloudGateway,
    [CloudInfrastructureObjectType.BackupRepository]: backupRepository,
    [CloudInfrastructureObjectType.WanAccelerator]: wanAccelerator,
    [CloudInfrastructureObjectType.BackupAppliance]: cloudBackupAppliance,
};

export const VeeamCloudConnect: React.FC = () => {
    const lang = useLang();
    const { enumMapperService } = useAppServices();
    const request = useIntervalRequest<{}, CloudInfrastructureResponse>('Overview/CloudInfrastructureAlarms');
    const items = (request.data as CloudInfrastructureResponse)?.every(item => item.overallCount === 0)
        ? []
        : (request.data as CloudInfrastructureResponse);

    const [isNotApplicable, setIsNotApplicable] = useState(false);

    const applicableToTypes = [
        CompanyTypeRepresentation.Provider,
    ];

    // todo: think different when we will switch to React in Global Filters - needs to add full model information to Global Filter Service
    const getGlobalCompanyFilterString = (v) => {
        const type = Ext.getCmp('ResellerFilter')
            .store.getData()
            .items.find(f => f.id === parseInt(v, 10))?.getData().companyType;

        return RCOP.utils.Services.enumMapperService.getEnumStringValue('CompanyTypeRepresentation', type);
    };

    useEffect(() => {
        const companyFilter = Ext.getCmp('ResellerFilter').getValue();
        const type = getGlobalCompanyFilterString(companyFilter);

        setIsNotApplicable(type && !applicableToTypes.includes(type));
    }, []);

    useGlobalFiltersChangeHandler(({ companyFilter }) => {
        const type = getGlobalCompanyFilterString(companyFilter);

        setIsNotApplicable(type && !applicableToTypes.includes(type));
    });

    return (
        <>
            {isNotApplicable && (
                <EmptyPlate
                    emptyText={lang.REGISTER_VEEAM_CLOUD_CONNECT_SERVERS}
                    title={lang.VEEAM_CLOUD_CONNECT}
                />
            )}

            {!isNotApplicable && (
                <TableWidget
                    isNotApplicable={isNotApplicable}
                    title={lang.VEEAM_CLOUD_CONNECT}
                    height={388}
                    data={items}
                    shiftColumns
                    columns={[
                        {
                            cellRenderer: ({ rowData, key }) => (
                                <NameColumn
                                    icon={icons[rowData.itemType]}
                                    name={enumMapperService.getEnumDescription('CloudInfrastructureObjectType', rowData.itemType)}
                                    key={key}
                                />
                            ),
                        },
                        {
                            cellRenderer: ({ rowData, key }) => {
                                if (rowData.overallCount === 0) {
                                    return (
                                        <React.Fragment key={key}>
                                            <NoInfoColumn />
                                            <EmptyColumnPlaceholder />
                                        </React.Fragment>
                                    );
                                }

                                if (rowData.errors.value === 0 && rowData.warnings.value === 0) {
                                    return (
                                        <React.Fragment key={key}>
                                            <HealthyColumn />
                                            <EmptyColumnPlaceholder />
                                        </React.Fragment>
                                    );
                                }

                                const columns = [];

                                if (rowData.errors.value > 0) {
                                    columns.push(<ErrorsColumn count={rowData.errors.value} key='errors' />);
                                }

                                if (rowData.warnings.value > 0) {
                                    columns.push(<WarningsColumn count={rowData.warnings.value} key='warnings' />);
                                }

                                while (columns.length < 2) {
                                    columns.push(<EmptyColumnPlaceholder key={columns.length} />);
                                }

                                return columns;
                            },
                        },
                    ]}
                    onClick={() => {
                        const filters = `statusesFilter/${AlarmStatus.Warning},${AlarmStatus.Error}/productsFilter/${Product.CloudConnect}/` +
                            `dateFilter/${TOGGLE_FILTER_ALL_VALUE}/scopesFilter/${TOGGLE_FILTER_ALL_VALUE}`;

                        window.location.assign(`#home/activeAlarms/tab/alarms/${filters}`);
                    }}
                    loading={request.loading}
                    noDataConfig={{
                        linkText: lang.START_USING_VEEAM_CLOUD_CONNECT,
                        icon: cloudConnectedNotConnected,
                        linkHandler: () => window.location.assign('#config/cloudConnectServers'),
                    }}
                    className='fb-50'
                />
            )}
        </>
    );
};
