/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import {
    Form,
    FormValidator,
    FormLayout,
    isValid,
    useExternalFormApi,
} from '@veeam-vspc/components';
import { capitalize, formatStr } from '@veeam-vspc/core';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { PerMonthField } from 'components/controls/PerMonthField';

import type { SubscriptionPlanModel } from 'core/interfaces';

export const stepValidate = (data: SubscriptionPlanModel) => {
    const validator = new FormValidator(data);

    validator
        .validate('managedServicesPrice')
        .number()
        .min(0)
        .max(1000000);

    validator
        .validate('monitoredServicesPrice')
        .number()
        .min(0)
        .max(1000000);

    return validator.result();
};

export const getServiceFeesStep = (title: string, lang: any): WizardStep<SubscriptionPlanModel> => ({
    title,
    validate: ({ data, stepData, isEdit }) => isValid(stepValidate, data),
    render: data => <ServiceFeesStep {...data} />,
});

const ServiceFeesStep = ({ data, validationState, onDataChange, stepData }: WizardStepData<SubscriptionPlanModel>) => {
    const lang = useLang();
    const formApi = useExternalFormApi();

    useEffect(() => {
        stepData.formApi = formApi;

        return () => {
            delete stepData.formApi;
        };
    }, []);

    const currencyPerMonth = formatStr(lang.CURRENCY_PER_MONTH, data.currency);

    return (
        <StepLayout
            title={lang.GENERAL_SERVICES}
            description={lang.DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_GENERAL_BACKUP}
        >
            <Form
                value={data}
                validate={stepValidate}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <PerMonthField
                        name='managedServicesPrice'
                        label={capitalize(lang.MANAGED_SERVICES, true)}
                        suffix={currencyPerMonth}
                    />

                    <PerMonthField
                        name='monitoredServicesPrice'
                        label={lang.MONITORING_SERVICES}
                        suffix={currencyPerMonth}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
};
