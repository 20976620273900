/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { constants } from '@veeam-vspc/components';

export const TableTitleStyled = styled.div`
  display: contents;

  & > * {
    padding: ${constants.SPACING_S};
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  & > *:empty {
    padding: 0;
  }
`;
