/**
 * Copyright © Veeam Software Group GmbH.
 */

import { RequestGridSortDirections } from '@veeam-vspc/components';

import type { SortParams } from '@veeam-vspc/components';
import type { Vb365JobItemComposed } from '@veeam-vspc/models/rest';

import { resourceValueGetter } from './resource-value-getter';

import type { JobWizardStore } from '../../../stores';

export function sortResources(
    items: Vb365JobItemComposed[],
    sort: SortParams[],
    store: JobWizardStore,
) {
    items.sort((a, b) => {
        const { direction, property } = sort[0];

        if (direction === RequestGridSortDirections.Asc) {
            return resourceValueGetter(a, property, store)
                .localeCompare(
                    resourceValueGetter(b, property, store),
                    'kn',
                    { sensitivity: 'base', numeric: true }
                );
        }

        if (direction === RequestGridSortDirections.Desc) {
            return resourceValueGetter(b, property, store)
                .localeCompare(
                    resourceValueGetter(a, property, store),
                    'kn',
                    { sensitivity: 'base', numeric: true }
                );
        }

        return 0;
    });
}
