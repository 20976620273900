/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, computed, observable } from 'mobx';
import { deepEqual } from '@veeam-vspc/core';

type CacheParams = Object | void;

export class DataCache<TData, TParams extends CacheParams = void> {
    private loadFn: (params?: TParams) => Promise<TData>;
    @observable private loading$ = true;
    @observable private loaded$ = false;
    @observable private value$: TData | null = null;
    private params?: TParams;

    constructor(loadFn: (params: TParams) => Promise<TData>) {
        this.loadFn = loadFn;
    }

    @computed get value(): TData | null {
        return this.value$;
    }

    @computed get loading(): boolean {
        return this.loading$;
    }

    private paramsChanged(newParams: TParams) {
        return !deepEqual(this.params as object, newParams as object);
    }

    @action load(params?: TParams, force = false): Promise<TData> {
        if (!this.loaded$ || force || this.paramsChanged(params)) {
            this.params = params;
            this.loading$ = true;
            return this.loadFn(params)
                .then((data) => {
                    this.value$ = data;
                    this.loading$ = false;
                    this.loaded$ = true;
                    return data;
                });
        }
        return Promise.resolve(this.value$);
    }

    @action clear(): void {
        this.loaded$ = false;
        this.loading$ = true;
        this.params = undefined;
        this.value$ = null;
    }
}
