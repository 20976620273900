/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import styled from 'styled-components';

import type { LangsServiceBase } from 'core/services/langs/interfaces';

const Header = styled.div`
    padding: 8px 8px 0;
`;

const Marker = styled.div<{ color: string; }>`
    border-color: ${props => props.color};
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    display: inline-block;
    height: 8px;
    margin-right: 4px;
    width: 8px;
`;

const Name = styled.div`
    display: inline-block;
`;

const TooltipStyled = styled.div`
    table {
        border-spacing: 8px;

        td {
            padding: 0;
        }
    }
`;

const Total = styled.div`
    font-weight: bold;
`;

interface RowProps {
    color: string;
    name: string;
    value: number;
}

interface TooltipProps {
    children: React.ReactElement | React.ReactElement[];
    lang: LangsServiceBase;
    title: number | string;
    total?: number;
}

export const Row = ({ color, name, value }: RowProps): React.ReactElement => (
    <tr>
        <td>
            <Marker color={color} />

            <Name>
                {`${name}:`}
            </Name>
        </td>

        <td>{value}</td>
    </tr>
);

export const Tooltip = ({ children, lang, title, total }: TooltipProps): React.ReactElement => (
    <TooltipStyled>
        <Header>
            {total !== null && (
                <Total>
                    {`${lang.TOTAL}: ${total.toFixed(2)}`}
                </Total>
            )}

            {title}
        </Header>

        <table>
            {children}
        </table>
    </TooltipStyled>
);
