/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ManagedAgentType } from '@veeam-vspc/models/web-controllers';

import { core } from 'core/core-module';
import { transformBaseChartData } from 'views/pages/TrendsPage/helpers';

import type { BaseDataResponse } from 'core/services/transport/interfaces/base-data-response';
import type { LineChartData, TrendData } from '../../../../../interfaces';

const names = [
    { label: 'Workstations', type: ManagedAgentType.Workstation },
    { label: 'Servers', type: ManagedAgentType.Server },
];

export class AgentBackupsService {
    static fetchData({ reportingPeriodInfo }): Promise<LineChartData> {
        return core.transportService
            .request('Trends/CloudAgentBackups', { ...reportingPeriodInfo })
            .then((resp: BaseDataResponse<TrendData>) => transformBaseChartData(resp.data.trends, reportingPeriodInfo, names));
    }
}
