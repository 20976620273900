/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { renderToString } from 'react-dom/server';
import { BASE_FONT_FAMILY, BASE_FONT_NORMAL_WEIGHT, colors as palette, FONT_LINE_HEIGHT_S, FONT_SIZE_S } from '@veeam-vspc/components';

import type * as Highcharts from 'highcharts';

import { Row, Tooltip } from '../components/HighchartsWrapper/components/Tooltip';

import type { ExtraHighchartsParams, RequiredHighchartsParams } from '../components/HighchartsWrapper/interfaces';

export type Params = RequiredHighchartsParams & ExtraHighchartsParams;

export const getHighchartsOptions = ({ categories, colors, lang, series, title, type = 'line', yMax, yMin, yTitle }: Params): Highcharts.Options => {
    const showLegend = series?.length > 1;

    return {
        colors,
        series,
        title,

        chart: {
            height: 318, // chart plate height is 366px by designers` mockups, but -48px for custom header
            spacing: [type === 'column' ? 24 : 8, 24, showLegend ? 0 : 52, 24], // 52 = 48 (legend height) + 4 (legend margin)
            style: { fontFamily: BASE_FONT_FAMILY },
            type,
        },

        credits: {
            enabled: false,
        },

        legend: {
            enabled: showLegend,
            itemDistance: 32,
            itemMarginBottom: 2,
            itemMarginTop: 2,
            margin: 4,
            padding: 16,
            symbolPadding: 4,
            symbolWidth: 8,

            itemStyle: {
                fontSize: FONT_SIZE_S,
                fontWeight: BASE_FONT_NORMAL_WEIGHT,
                lineHeight: FONT_LINE_HEIGHT_S,
            },

        },

        plotOptions: {
            area: {
                fillOpacity: 0.5,
                stacking: 'normal',
            },

            column: {
                maxPointWidth: 16,
                pointWidth: 8,

                dataLabels: {
                    align: 'center',
                    color: '#444444',
                    crop: false,
                    enabled: true,
                    overflow: 'allow',
                    padding: 8,

                    style: {
                        fontSize: '12px',
                        fontWeight: 'normal',
                    },
                },
            },

            series: {
                events: {
                    legendItemClick: function() {
                        let visibleCount = 0;
                        this.chart.series.forEach(s => visibleCount += s.visible ? 1 : 0);

                        if (this.visible && visibleCount === 1) {
                            return false;
                        }

                        this.chart.legend.update({
                            itemStyle: {
                                cursor: this.visible && visibleCount === 2 ? 'default' : 'pointer',
                            },
                        });
                    },
                },

                marker: {
                    fillColor: palette.G000,
                    lineColor: undefined,
                    lineWidth: 2,
                    radius: 3,
                    symbol: 'circle',

                    states: {
                        hover: {
                            lineWidthPlus: 2,
                            radiusPlus: -1,
                        },
                    },
                },

                point: {
                    events: {
                        mouseOver: function() {
                            if (type !== 'column') {
                                this.series['halo'].attr({
                                    stroke: this.series['color'],
                                });
                            }
                        },
                    },
                },

                states: {
                    hover: {
                        lineWidthPlus: 0,

                        halo: type === 'column'
                            ? undefined
                            : {
                                size: 6,
                                opacity: 1,

                                attributes: {
                                    fill: palette.G000,
                                    'stroke-width': 1,
                                },
                            },
                    },
                },
            },
        },

        tooltip: {
            backgroundColor: palette.G000,
            borderColor: palette.G300,
            borderRadius: 0,
            padding: 0,
            shape: 'square',
            shared: true,
            useHTML: true,

            shadow: {
                color: palette.G300,
                width: 5,
            },

            formatter: function() {
                const total = type === 'area'
                    ? this.points.reduce((t, point) => (t + point.y), 0)
                    : null;

                return renderToString(
                    <Tooltip lang={lang} title={this.x} total={total}>
                        {this.points.map((point, i) => (
                            <Row
                                color={point.color as string}
                                key={i}
                                name={point.series.name}
                                value={point.y}
                            />
                        ))}
                    </Tooltip>
                );
            },
        },

        xAxis: {
            categories,

            crosshair: type === 'column'
                ? undefined
                : {
                    color: palette.G400,
                    width: 1,
                },

            labels: {
                autoRotation: [0],
                style: { fontSize: FONT_SIZE_S },
            },
        },

        yAxis: {
            allowDecimals: false,
            max: yMax,
            min: yMin,
            softMin: 0,
            tickAmount: 5,
            title: { text: yTitle },

            labels: {
                format: '{value}',
                style: { fontSize: FONT_SIZE_S },
            },
        },
    };
};
