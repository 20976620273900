/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { capitalize } from '@veeam-vspc/core';

import { PageWizard } from 'components/wizards/PageWizard';
import { useLang } from 'views/providers/LangProvider/hooks';
import { getData } from 'core/services/transport/utils/get-data';
import { getPlanInfoStep } from './components/PlanInfo';
import { getSummaryStep } from './components/Summary';
import { getCurrencyStep } from './components/Currency';
import { getServiceFeesStep } from './components/ServiceFees';
import { getManagedServicesStep } from './components/ManagedServices';
import { getCloudConnectServicesStep } from './components/CloudConnectServices';
import { getMicrosoft365Step } from './components/Microsoft365';
import { getPublicCloudStep } from './components/PublicCloud';
import { CloudStorageSpaceMode } from 'core/enums';
import { SubscriptionPlanWizardStore } from '../stores';
import { SubscriptionPlanContext, SubscriptionPlanStore } from './stores';

import type { SubscriptionPlanModel } from 'core/interfaces';

export interface SubscriptionPlanProp {
    isEdit: boolean;
    onFinish: (id: number) => void;
    onClose?: () => void;
    model?: SubscriptionPlanModel;
}

export const SubscriptionPlan = ({ isEdit, model, onFinish, onClose }: SubscriptionPlanProp) => {
    const lang = useLang();
    const title = isEdit ? `${lang.EDIT} ${lang.SUBSCRIPTION_PLAN} - ${model.name}` : `${lang.NEW} ${lang.SUBSCRIPTION_PLAN}`;
    const wizardStore = useMemo(() => new SubscriptionPlanWizardStore(model), [model]);

    const currentModel: SubscriptionPlanModel = isEdit ?
        wizardStore.getEditModel() :
        wizardStore.defaultModel;

    const prepareDataForSave = (data: SubscriptionPlanModel) => {
        if (data.cbRepoCountAlgorithm !== CloudStorageSpaceMode.AllocatedSpaceMode) {
            delete data.cbRepoAllocatedSpacePrice;
            delete data.cbRepoAllocatedSpaceMultiplier;
        }
        if (data.cbRepoCountAlgorithm !== CloudStorageSpaceMode.ConsumedSpaceMode) {
            delete data.cbVmRepoUsedSpacePrice;
            delete data.cbVmRepoUsedSpaceMultiplier;
            delete data.cbVmRepoUsedSpaceFree;
        }
        if (data.cbRepoCountAlgorithm !== CloudStorageSpaceMode.GranularSpaceMode) {
            delete data.cbPerformanceTierUsedSpacePrice;
            delete data.cbPerformanceTierUsedSpaceMultiplier;
            delete data.cbCapacityTierUsedSpacePrice;
            delete data.cbCapacityTierUsedSpaceMultiplier;
            delete data.cbArchiveTierUsedSpacePrice;
            delete data.cbArchiveTierUsedSpaceMultiplier;
        }
        return data;
    };

    const contextStore = useMemo(() => new SubscriptionPlanStore(), []);

    return (
        <SubscriptionPlanContext.Provider value={contextStore}>
            <PageWizard
                title={title}
                isEdit={isEdit}
                deactivate={() => undefined}
                steps={[
                    getPlanInfoStep(capitalize(lang.PLAN_INFO), lang),
                    getCurrencyStep(lang.CURRENCY),
                    getServiceFeesStep(lang.GENERAL_SERVICES, lang),
                    getManagedServicesStep(lang.MANAGED_BACKUP),
                    getCloudConnectServicesStep(lang.VEEAM_CLOUD_CONNECT),
                    getMicrosoft365Step(lang.MICROSOFT_365_BACKUP),
                    getPublicCloudStep(lang.PUBLIC_CLOUD_BACKUP),
                    getSummaryStep(lang.SUMMARY),
                ]}
                data={currentModel}
                onFinish={(wizardData) => {
                    const dataForSave = prepareDataForSave(wizardData.data);

                    return getData('SubscriptionPlan/SaveSubscriptionPlan', dataForSave)
                        .then((id: number) => {
                            onFinish(id);
                        });
                }}
                tabsWidth={200}
                onClose={onClose}
            />
        </SubscriptionPlanContext.Provider>
    );
};
