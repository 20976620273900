/**
 * Copyright © Veeam Software Group GmbH.
 */

/* eslint-disable @typescript-eslint/no-duplicate-imports */

import React, { useState } from 'react';
import {
    disableForSelectionWithPropValues,
    PortalSpinner,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
} from '@veeam-vspc/components';
import { EPublicCloudRestoreType, PublicCloudPlatformTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type {
    GetVbRestoreLinkParams,
    PublicCloudEfsModel,
    PublicCloudEfsParam,
} from '@veeam-vspc/models/web-controllers';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { useLang } from 'views/providers/LangProvider/hooks';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import restoreTypeIconSrc from 'images/actions/restore.svg';
import fileLevelRecoveryTypeIconSrc from 'images/actions/file-level-recovery.svg';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { ConfigSectionIds } from 'core/services';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';

export interface FileSharesPublicCloudsActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items'> {
    method?: string;
}

export const FileSharesPublicCloudsActionToolbar: React.FC<FileSharesPublicCloudsActionToolbarProps> = (props) => {
    const { method = 'GetPublicCloudEfs', ...restProps } = props;
    const lang = useLang();
    const { transportService } = useAppServices();
    const [loading, setLoading] = useState(false);
    const gridApi = useGridApi<PublicCloudEfsModel, unknown, PublicCloudEfsParam['filter']>();
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSingleSelection(),
    ];
    const efsRestoreGroupId = 'efsRestoreGroupId';
    const getLink = (restoreType: EPublicCloudRestoreType) => {
        const { selected } = gridApi;

        setLoading(true);

        transportService.request<GetVbRestoreLinkParams, string>('/VbPlugin/GetRestoreLink', {
            uid: selected[0].uid,
            vbServerId: selected[0].vbServerId,
            restoreType,
            backUrl: window.location.href,
        })
            .then((resp: BaseSuccessRequestResponse<string>) => {
                setLoading(false);
                window.open(resp.data as string, '_self');
            })
            .catch((err) => {
                if (err) {
                    console.error(err);
                }
                setLoading(false);
            });
    };

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                sectionId={ConfigSectionIds.ProtectedDataFileSharesPublicClouds}
                method={method}
                groups={[
                    {
                        iconSrc: restoreTypeIconSrc,
                        title: lang.RESTORE,
                        id: efsRestoreGroupId,
                    },
                ]}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: restoreTypeIconSrc,
                        text: lang.ENTIRE_EFS,
                        onClick: () => getLink(EPublicCloudRestoreType.RestoreEfsEntire),
                        groupId: efsRestoreGroupId,
                        decorators: [
                            ...defaultDisabledDecorators,
                            disableForSelectionWithPropValues('platformType', [PublicCloudPlatformTypeRepresentation.AzureCompute]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: fileLevelRecoveryTypeIconSrc,
                        text: lang.FILE_LEVEL_RECOVERY,
                        onClick: () => getLink(EPublicCloudRestoreType.RestoreEfsFileLevel),
                        groupId: efsRestoreGroupId,
                        decorators: defaultDisabledDecorators,
                    },
                ]}
            />

            {loading && <PortalSpinner />}
        </>
    );
};
