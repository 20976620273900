/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { IdentityProviderRoleMappingRule } from '@veeam-vspc/models/rest';
import { Combobox, CONTROL_SIZE, Form, STACK_DIRECTION, STACK_GAP, StackView } from '@veeam-vspc/components';
import { UserRoleRepresentation } from '@veeam-vspc/models/web-controllers/userRoleRepresentation';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { NoteBar } from 'components/layouts/NoteBar';
import { getClientDocUrl } from '../../../Base/helpers/getClientDocUrl';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { PortalUserRoles } from 'core/entries/portal-user/enums';

import type { SSORuleModel } from '../../../Base/interfaces/sso-rule-model';

export const Role: React.FC<WizardStepData<SSORuleModel>> = ({ data, validationState, onDataChange, isEdit }) => {
    const lang = useLang();
    const { portalUser } = useAppStore();
    const rolesEnum = IdentityProviderRoleMappingRule.RoleEnum;
    const isCompanyOwnerRule = data.model.role === rolesEnum.CompanyOwner;

    const roles = useMemo(() => {
        const companyRoles = [
            { value: rolesEnum.CompanyOwner, text: lang.COMPANY_OWNER },
            { value: rolesEnum.CompanyAdministrator, text: lang.COMPANY_ADMINISTRATOR },
            { value: rolesEnum.CompanyLocationAdministrator, text: lang.LOCATION_ADMINISTRATOR },
            { value: rolesEnum.CompanyLocationUser, text: lang.LOCATION_USER },
            { value: rolesEnum.CompanyInvoiceAuditor, text: lang.COMPANY_INVOICE_AUDITOR },
        ];

        const serviceProviderRoles = [
            { value: rolesEnum.ResellerOwner, text: lang.SERVICE_PROVIDER_GLOBAL_ADMINISTRATOR },
            { value: rolesEnum.ResellerAdministrator, text: lang.SERVICE_PROVIDER_ADMINISTRATOR },
            { value: rolesEnum.ResellerOperator, text: lang.SERVICE_PROVIDER_OPERATOR },
            { value: rolesEnum.ResellerUser, text: lang.SERVICE_PROVIDER_USER },
            { value: rolesEnum.ResellerInvoiceAuditor, text: lang.SERVICE_PROVIDER_INVOICE_AUDITOR },
        ];

        switch (portalUser.userRole) {
            case PortalUserRoles.CompanyOwner:
            case PortalUserRoles.ServiceProviderAdministrator:
            case PortalUserRoles.ServiceProviderGlobalAdministrator:
                return companyRoles;

            case PortalUserRoles.CompanyAdministrator:
                if (isEdit && isCompanyOwnerRule) {
                    return companyRoles;
                }

                const companyOwnerIdx = companyRoles.findIndex(role => role.value === rolesEnum.CompanyOwner);
                companyRoles.splice(companyOwnerIdx, 1);
                return companyRoles;

            case PortalUserRoles.PortalAdministrator:
                return [].concat(companyRoles, serviceProviderRoles);

            default: return [];
        }
    }, [lang, rolesEnum, isEdit, portalUser.userRole, isCompanyOwnerRule]);

    return (
        <Form
            value={data}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={lang.ROLE}
                description={lang.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='model.role'
                        data={roles}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        size={CONTROL_SIZE.m}
                        label={lang.ROLE}
                        disabled={portalUser.userRole === UserRoleRepresentation.CompanyAdministrator && isCompanyOwnerRule}
                        inlineLabel
                    />

                    <NoteBar>
                        {data.model.role === rolesEnum.CompanyOwner && <p>{lang.COMPANY_OWNER_HAS_ACCESS_TO_ALL}</p>}
                        {data.model.role === rolesEnum.CompanyAdministrator && <p>{lang.COMPANY_ADMINISTRATOR_HAS_ACCESS_TO_ALL_MONITORING}</p>}
                        {data.model.role === rolesEnum.CompanyLocationAdministrator && <p>{lang.LOCATION_ADMINISTRATOR_HAS_ACCESS_TO_ALL_DATA}</p>}
                        {data.model.role === rolesEnum.CompanyLocationUser && <p>{lang.LOCATION_USER_HAS_ACCESS_TO_THE_COMPANY}</p>}
                        {data.model.role === rolesEnum.CompanyInvoiceAuditor && <p>{lang.COMPANY_INVOICE_AUDITOR_HAS_ACCESS}</p>}
                        {data.model.role === rolesEnum.ResellerOwner && <p>{lang.SERVICE_PROVIDER_GLOBAL_ADMINISTRATOR_OF_A_RESELLER}</p>}
                        {data.model.role === rolesEnum.ResellerAdministrator && <p>{lang.SERVICE_PROVIDER_ADMINISTRATOR_HAS_ACCESS}</p>}
                        {data.model.role === rolesEnum.ResellerOperator && <p>{lang.SERVICE_PROVIDER_OPERATOR_CAN_PERFORM}</p>}
                        {data.model.role === rolesEnum.ResellerUser && <p>{lang.SERVICE_PROVIDER_USER_HAS_ACCESS}</p>}
                        {data.model.role === rolesEnum.ResellerInvoiceAuditor && <p>{lang.SERVICE_PROVIDER_INVOICE_AUDITOR_HAS_ACCESS}</p>}

                        <p>
                            {lang.CLICK}
                            {' '}

                            <a
                                href={getClientDocUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {lang.HERE}
                            </a>

                            {' '}
                            {lang.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: data => <Role {...data} />,
});
