/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { ExternalFormApi } from '@veeam-vspc/components';

import { FormFieldNames, OauthSmtpServersHosts } from '../enums';

import type { EmailServerSettingsFormData } from '../interfaces';

export const resetHostField = (formApi: ExternalFormApi<EmailServerSettingsFormData>) => {
    const host = formApi.value.host;
    const isOAuthSmtpHost = (host === OauthSmtpServersHosts.Google) || (host === OauthSmtpServersHosts.Azure);

    if (isOAuthSmtpHost) {
        formApi.setValue(FormFieldNames.Host, '');
    }
};
