/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { OAuth2ClientSettings } from '@veeam-vspc/models/rest';
import {
    Combobox,
    CONTROL_SIZE,
    CopyInput,
    FIELD_L,
    FieldLayout,
    PasswordInput,
    Text,
    TEXT_VIEW,
    TextInput,
    TEXT_SIZE,
} from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';

import { FormFieldNames } from '../../enums';
import { getMailServicesTypes } from '../../helpers';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useEmailServerSettingsStore } from '../../../../hooks';

export const EmailServerModernSettingsFields = observer(() => {
    const lang = useLang();
    const serverSettingsStore = useEmailServerSettingsStore();
    const { redirectUrl, storeFormData: { smtp } } = serverSettingsStore;
    const oAuth2Kind = smtp.oAuth2Credential.clientSettings.kind;
    const isAzureOAth2Kind = oAuth2Kind === OAuth2ClientSettings.KindEnum.Azure;

    return (
        <>
            <Combobox
                size={CONTROL_SIZE.m}
                data={getMailServicesTypes(lang)}
                label={lang.MAIL_SERVICE}
                valueGetter={item => item.id}
                textGetter={item => item.name}
                name={FormFieldNames.MailServiceKind}
            />

            <TextInput
                size={CONTROL_SIZE.l}
                name={FormFieldNames.ClientId}
                label={lang.APPLICATION_CLIENT_ID}
                placeholder={lang.ENTER_APPLICATION_CLIENT_ID}
                helpText={(
                    <Text
                        size={TEXT_SIZE.s}
                        view={TEXT_VIEW.light}
                        style={{ width: FIELD_L }}
                    >
                        {lang.REGISTER_YOUR_APPLICATION}
                    </Text>
                )}
                helpTextWrap
            />

            <PasswordInput
                size={CONTROL_SIZE.l}
                label={lang.CLIENT_SECRET}
                name={FormFieldNames.ClientSecret}
                placeholder={lang.ENTER_CLIENT_SECRET}
            />

            {isAzureOAth2Kind && (
                <TextInput
                    label={lang.TENANT_ID}
                    size={CONTROL_SIZE.l}
                    name={FormFieldNames.TenantId}
                    placeholder={lang.ENTER_TENANT_ID}
                />
            )}

            <FieldLayout label={`${lang.REDIRECT_URL}:`}>
                <CopyInput
                    value={redirectUrl}
                    size={CONTROL_SIZE.l}
                />
            </FieldLayout>
        </>
    );
});
