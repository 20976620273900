/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import type { CompanyTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import type { WizardStepData } from '@veeam-vspc/components';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { getData } from 'core/services/transport/utils';
import { CenterSpinner } from 'components/controls/CenterSpinner';
import { SanitizedInnerHtml } from '../SanitizedInnerHtml';

import type { UserTitleRepresentation, CloudTenantType } from 'core/enums';
import type { EmailCustomSectionData, WelcomeEmailPreviewGateways } from 'core/interfaces';

interface WizardData {
    alias?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    name?: string;
    tenantType?: CloudTenantType;
    title?: UserTitleRepresentation;
    organizationUid?: string;
}

interface Props extends WizardStepData<WizardData> {
    emailData: EmailCustomSectionData;
    organizationType: CompanyTypeRepresentation;
    gateways: WelcomeEmailPreviewGateways[];
    hidePanel: () => void;
}

export const PreviewEmail = observer((props: Props) => {
    const lang = useLang();
    const { data, emailData, hidePanel, organizationType, gateways } = props;

    const [html, setHtml] = useState<string>(null);

    useEffect(() => {
        getData('Company/PreviewWelcomeEmail', {
            title: data.title,
            firstName: data.firstName,
            lastName: data.lastName,
            userName: data.userName,
            isVisibleSelfServiceSection: emailData.isVisibleGeneralSection,
            organizationAliasOrName: data.alias || data.name,
            tenantType: data.tenantType,
            organizationType,
            customSection: emailData.addCustomText ? emailData.emailContent : null,
            organizationUid: data.organizationUid,
            gateways: gateways,
        })
            .then((data: string) => setHtml(data));
    }, []);

    return (
        <SidePanelForm
            title={lang.PREVIEW_WELCOME_EMAIL}
            onRequestClose={hidePanel}
            actions={[
                {
                    text: lang.CLOSE,
                    onClick: hidePanel,
                },
            ]}
        >
            {html
                ? <SanitizedInnerHtml html={html} />
                : <CenterSpinner size={50} />}
        </SidePanelForm>
    );
});
