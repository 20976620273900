/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    TextInput,
    NumberInput,
    LabelGroup,
    STACK_DIRECTION,
    CONTROL_SIZE,
    FormLayout,
    FormValidator,
    SignPost,
    FormTitle,
    Radiobutton,
    Combobox,
} from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { SlaPeriodRepresentation } from '../../../enums';
import { DatetimeFormatted } from 'components/controls/DatetimeFormatted';
import { useReportStore } from '../../../hooks';

import type { ReportModel, Timezone } from '../../../interfaces';


export const cdpParametersValidate = (data: ReportModel, errorMessage: string) => {
    const validator = new FormValidator(data);
    let tmpDateValidator;

    if (data.parameters.slaSettings.isCustomPeriod) {
        tmpDateValidator = validator
            .validate('parameters.slaSettings.fromAsDate')
            .date();
        if (data.parameters.slaSettings?.toAsDate) {
            tmpDateValidator
                .max(data.parameters.slaSettings?.toAsDate, errorMessage);
        }
        tmpDateValidator.required();

        tmpDateValidator = validator
            .validate('parameters.slaSettings.toAsDate')
            .date();

        if (data.parameters.slaSettings?.fromAsDate) {
            tmpDateValidator
                .min(data.parameters.slaSettings?.fromAsDate, errorMessage);
        }
        tmpDateValidator.required();
    }

    validator
        .validate('parameters.slaSettings.sla')
        .number()
        .required()
        .min(0)
        .max(100);

    validator
        .validate('parameters.excludeMask')
        .string()
        .maxLength(500);

    return validator.result();
};

interface Props {
    data: ReportModel;
    onDataChange: (data: any) => void;
}

export const CdpParameters = ({ data }: Props) => {
    const lang = useLang();
    const allPeriods = [{
        id: SlaPeriodRepresentation.Day,
        name: lang.DAY,
    }, {
        id: SlaPeriodRepresentation.Week,
        name: lang.WEEK,
    }, {
        id: SlaPeriodRepresentation.Month,
        name: lang.MONTH,
    }, {
        id: SlaPeriodRepresentation.Quater,
        name: lang.QUARTER,
    }];
    const reportStore = useReportStore();
    const [timezones, setTimezones] = useState<Timezone[]>([]);

    useEffect(() => {
        reportStore.timezoneListCache.load()
            .then(items => setTimezones(items));
    }, []);

    return (
        <>
            <FormTitle>{lang.TIME_PERIOD}</FormTitle>

            <LabelGroup
                label={(
                    <Radiobutton
                        name={'parameters.slaSettings.isCustomPeriod'}
                        value={false}
                    >
                        {`${lang.LAST}:`}
                    </Radiobutton>
                )}
            >
                <FormLayout
                    direction={STACK_DIRECTION.row}
                    disabled={data.parameters.slaSettings.isCustomPeriod}
                >
                    <Combobox
                        name='parameters.slaSettings.periodType'
                        data={allPeriods}
                        valueGetter={item => item.id}
                        textGetter={item => item.name}
                        size={CONTROL_SIZE.xs}
                    />
                </FormLayout>
            </LabelGroup>

            <LabelGroup
                hasTopOffset
                label={(
                    <Radiobutton
                        name={'parameters.slaSettings.isCustomPeriod'}
                        value={true}
                    >
                        {`${lang.CUSTOM_PERIOD}:`}
                    </Radiobutton>
                )}
            >
                <FormLayout
                    direction={STACK_DIRECTION.row}
                    disabled={!data.parameters.slaSettings.isCustomPeriod}
                >
                    <DatetimeFormatted
                        name='parameters.slaSettings.fromAsDate'
                        label={lang.FROM}
                    />

                    <DatetimeFormatted
                        name='parameters.slaSettings.toAsDate'
                        label={lang.TO}
                    />
                </FormLayout>
            </LabelGroup>

            <Combobox
                name={'parameters.slaSettings.timeZoneId'}
                label={lang.TIME_ZONE}
                data={timezones}
                valueGetter={item => item.id}
                textGetter={item => item.description}
                disabled={!data.parameters.slaSettings.isCustomPeriod}
                size={'616px'}
            />

            <FormTitle>{lang.REPORT_PARAMETERS}</FormTitle>

            <NumberInput
                name='parameters.slaSettings.sla'
                label={lang.SLA}
                minValue={0}
                maxValue={100}
                suffix='%'
                size={CONTROL_SIZE.xxs}
            />

            <TextInput
                name='parameters.excludeMask'
                label={lang.VM_EXCLUSIONS_RULE}
                suffix={(
                    <SignPost
                        text={lang.TYPE_IN_THE_VM_NAME}
                    />
                )}
            />
        </>
    );
};
