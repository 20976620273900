/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ActionLinkColumn } from '@veeam-vspc/components/src/Grid/columns/ActionLinkColumn/ActionLinkColumn';
import { capitalize } from '@veeam-vspc/core';
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { SortingMode } from '@veeam-vspc/components';

import type { CloudConnectServerWithDeployInfo } from '@veeam-vspc/models/web-controllers';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { WizardStep, GridStore } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { EAgentCommonStatuses } from 'core/enums/e-agent-common-statuses';
import { StatusColumn } from 'views/components/columns/StatusColumn';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { ActionsToolbar } from './components/ActionsToolbar';
import successIconSrc from 'images/statuses/success.svg';
import infoIconSrc from 'images/statuses/info.svg';
import warningIconSrc from 'images/statuses/warning.svg';
import errorIconSrc from 'images/statuses/error.svg';
import pendingIconSrc from 'images/statuses/pending.svg';
import { TenantQuotaDialog } from './components/TenantQuotaDialog';
import { getResellerCcAgent } from './utils';

import type { ResellerWizardStore } from '../../stores';
import type { ResellerWizardStepData, ResellerData } from '../../interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { GridItem } from './interfaces';

const stepValidate = (data: ResellerData, lang: LangsServiceBase) => {
    if (data.cloudConnectAgents.length === 0) {
        core.notificationService.warning(lang.SITE_SCOPE, lang.PLEASE_ADD_AT_LEAST_ONE_SITE);
        return false;
    }

    return true;
};

export const getSiteScopeStep = (lang: LangsServiceBase, wizardStore: ResellerWizardStore): WizardStep<ResellerData> => ({
    title: lang.SITE_SCOPE,
    validate: ({ data }) => stepValidate(data, lang),
    render: stepData => <SiteScopeStep {...stepData} wizardStore={wizardStore} />,
});

const SiteScopeStep = observer((wizardStepData: ResellerWizardStepData) => {
    const { data, wizardStore } = wizardStepData;
    const lang = useLang();
    const gridApi = useRef<GridStore<GridItem, void, void>>();

    useEffect(() => {
        wizardStore.cloudConnectAgentsCache.load({ resellerId: data.id })
            .then(() => {
                gridApi.current.reloadGrid();
            });
    }, []);

    return (
        <StepLayout
            title={lang.SITE_SCOPE}
            description={lang.SELECT_SITES_AVAILABLE_FOR}
        >
            <AdvancedGrid <GridItem, void, void>
                defaultColumnWidth={100}
                columns={[
                    { field: 'siteName', title: lang.SITE },
                    {
                        field: 'agentStatus',
                        title: lang.STATUS,
                        cell: ({ rowData }) => {
                            const icons: { [name: string]: string; } = {
                                [EAgentCommonStatuses.Inacessible]: errorIconSrc,
                                [EAgentCommonStatuses.Error]: errorIconSrc,
                                [EAgentCommonStatuses.FailedToUpdate]: errorIconSrc,
                                [EAgentCommonStatuses.Rejected]: 'backup-agent-type-rejected',
                                [EAgentCommonStatuses.Warning]: warningIconSrc,
                                [EAgentCommonStatuses.Healthy]: successIconSrc,
                                [EAgentCommonStatuses.NotInstalled]: infoIconSrc,
                                [EAgentCommonStatuses.NotManaged]: infoIconSrc,
                                [EAgentCommonStatuses.NotSet]: 'backup-agent-type-na',
                                [EAgentCommonStatuses.Updating]: pendingIconSrc,
                                [EAgentCommonStatuses.Restarting]: pendingIconSrc,
                                [EAgentCommonStatuses.Installation]: pendingIconSrc,
                                [EAgentCommonStatuses.Applying]: pendingIconSrc,
                            };

                            return (
                                <StatusColumn
                                    status={rowData.agentStatusName}
                                    message={rowData.statusNote}
                                    statusIconSrc={icons[rowData.agentStatus]}
                                />
                            );
                        },
                    },
                    { field: 'serverVersion', title: lang.CLOUD_CONNECT_SERVER_VERSION, hidden: true },
                    {
                        field: 'lastContactTime',
                        title: lang.LAST_HEARTBEAT,
                        cell: ({ rowData }) => (
                            <TimeAgoColumn value={rowData.lastContactTime} />
                        ),
                        hidden: true,
                    },
                    {
                        field: 'tenantQuota',
                        title: capitalize(lang.MANAGED_COMPANIES_QUOTA),
                        cell: ({ rowData }) => (
                            <ActionLinkColumn
                                content={rowData.tenantQuota > -1 ? rowData.tenantQuota.toString() : lang.UNLIMITED}
                                renderModal={({ deactivate }) => (
                                    <TenantQuotaDialog
                                        items={[getResellerCcAgent(data.cloudConnectAgents, rowData)]}
                                        onConfirm={() => {
                                            gridApi.current.reloadGrid();
                                        }}
                                        deactivate={deactivate}
                                    />
                                )}
                            />
                        ),
                    },
                ]}
                data={() => {
                    const ccAgents: GridItem[] = wizardStore.cloudConnectAgentsCache.value
                        ? data.cloudConnectAgents
                            .map(resellerAgent => ({
                                ...wizardStore.cloudConnectAgentsCache.value.find(agent => agent.agentGuid === resellerAgent.cloudConnectAgentUid),
                                tenantQuota: resellerAgent.tenantQuota,
                                tenantUsage: resellerAgent.tenantUsage,
                            }))
                        : [];

                    return Promise.resolve({
                        data: ccAgents,
                        meta: {
                            pagingInfo: {
                                total: ccAgents?.length,
                            },
                        },
                    } as BaseSuccessRequestResponse<CloudConnectServerWithDeployInfo[]>);
                }}
                sortingMode={SortingMode.Local}
                toggleable={true}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'agentGuid',
                }}
                toolbars={[
                    () => (
                        <ActionsToolbar
                            items={data.cloudConnectAgents}
                        />
                    ),
                ]}
                api={gridApi}
                isLoading={wizardStore.cloudConnectAgentsCache.loading}
                stateId={'ResellerWizard.ResellerGrid.SiteScope'}
            />
        </StepLayout>
    );
});
