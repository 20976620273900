/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum XmlFileParams {
    AcceptValue = '1',
    DeclineValue = '0',
    ParsingType = 'text/xml',

    /* Tags */
    PropertyTag = 'property',

    /* Attributes */
    NameAttr = 'name',
    ValueAttr = 'value',
    HiddenAttr = 'hidden',

    /* Tag queries */
    EulaQuery = 'property[name=ACCEPT_EULA]',
    LicenseQuery = 'property[name=VBR_LICENSE_FILE]',
    RequiredSoftwareQuery = 'property[name=ACCEPT_REQUIRED_SOFTWARE]',
    LicensingPolicyQuery = 'property[name=ACCEPT_LICENSING_POLICY]',
    ThirdPartyLicensesQuery = 'property[name=ACCEPT_THIRDPARTY_LICENSES]',
    RebootIfRequired = 'property[name=REBOOT_IF_REQUIRED]',
    MainConfigInstallQuery = 'unattendedInstallationConfiguration[bundle=VBR][mode=install]',
    MainConfigUpgradeQuery = 'unattendedInstallationConfiguration[bundle=VBR][mode=upgrade]',
}
