/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify, GridSortDirections, TextColumn } from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { CellRendererProps, RequestParams } from '@veeam-vspc/components';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { SourceItemsColumn } from 'views/components/columns/SourceItemsColumn';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { ConfigSectionIds } from 'core/services/configurator/enums';
import { FileSharesLocalToolbar } from './components';

import type { FileSharesLocalFilterModel, FileSharesLocalItem } from './interfaces';

export interface LocalProps {
    stateId: string;
}

export const FileSharesLocal: React.FC<LocalProps> = ({ stateId }) => {
    const { transportService } = useAppServices();
    const lang = useLang();
    const gridStateId = `${stateId}.Local`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<FileSharesLocalItem>) => <TextColumn>{cellData}</TextColumn>;
    const columns = [
        {
            field: 'name',
            title: lang.SERVER,
            cell: ({ cellData, rowData }: CellRendererProps<FileSharesLocalItem>) => (
                <SourceItemsColumn
                    value={cellData}
                    stateId={`${gridStateId}.Dialogs.SourceItems`}
                    name={cellData}
                    sourceItems={rowData.sourceItems}
                />
            ),
        },
        {
            field: 'jobName',
            title: lang.JOB,
            cell: defaultColumnRender,
        },
        {
            field: 'backupServerName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: defaultColumnRender,
        },
        {
            field: 'shortTermRestorePointsSize',
            title: lang.BACKUP_SIZE,
            cell: ({ cellData }: CellRendererProps<FileSharesLocalItem>) => (
                <RestorePointSizeColumn
                    value={cellData as any}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'longTermRestorePointsSize',
            title: lang.ARCHIVE_SIZE,
            cell: ({ cellData }: CellRendererProps<FileSharesLocalItem>) => (
                <RestorePointSizeColumn
                    value={cellData as any}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'totalBackupSize',
            title: lang.TOTAL_BACKUP_SIZE,
            cell: ({ cellData }: CellRendererProps<FileSharesLocalItem>) => (
                <RestorePointSizeColumn
                    value={cellData as any}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'destination',
            title: capitalize(lang.BACKUP_REPOSITORY),
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'shortTermRestorePointsCount',
            title: lang.RESTORE_POINTS,
            cell: defaultColumnRender,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'longTermRestorePointsCount',
            title: lang.ARCHIVED_POINTS,
            cell: defaultColumnRender,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'lastRestorePoint',
            title: lang.LAST_RUN,
            cell: ({ cellData }: CellRendererProps<FileSharesLocalItem>) => (
                <TimeAgoColumn
                    value={cellData}
                />
            ),
        },
        {
            field: 'lastSourceSize',
            title: lang.PROTECTED_FILES,
            cell: ({ cellData }: CellRendererProps<FileSharesLocalItem>) => (
                <RestorePointSizeColumn
                    value={cellData as any}
                />
            ),
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'companyName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'archiveDestination',
            title: lang.ARCHIVE_REPOSITORY,
            cell: defaultColumnRender,
            hidden: true,
        },
    ];

    return (
        <AdvancedGrid
            sectionId={ConfigSectionIds.ProtectedDataFileSharesLocal}
            stateId={gridStateId}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: 'jobName',
                },
            }}
            defaultColumnWidth={100}
            columns={columns.filter(column => column)}
            data={(requestParams: RequestParams<FileSharesLocalFilterModel>) => transportService
                .request<typeof requestParams, FileSharesLocalItem[]>('/ProtectedWorkloads/GetFileShares', { ...requestParams })}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <FileSharesLocalToolbar idProperty={'instanceUid'} selectedOnly />,
            ]}
        />
    );
};
