/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { ButtonTypeItem, CustomTypeItem, ToolbarGroupItem, ToolbarItem } from '@veeam-vspc/components';

import { useConfigurator } from 'views/providers/ConfiguratorProvider/hooks';
import { ConfigStates } from 'core/services/configurator/enums';

type GroupActions = ButtonTypeItem | CustomTypeItem;

export const useConfiguredToolbar = (
    sectionId: string,
    groups: ToolbarGroupItem[],
    items: ToolbarItem[],
): { configuredGroups: ToolbarGroupItem[]; configuredItems: ToolbarItem[]; } => {
    const configurator = useConfigurator();
    const sectionConfig = configurator?.getSectionConfig(sectionId);
    const isActonConfigExist = sectionId && sectionConfig;
    const preparedGroups = groups ? groups.filter(group => group) : [];
    const preparedItems = items ? items.filter(item => item) : [];
    const configuredGroups = isActonConfigExist
        ? preparedGroups.filter(group => sectionConfig.getActionState(group.id as string) === ConfigStates.Visible)
        : preparedGroups;
    const configuredItems = isActonConfigExist
        ? preparedItems.filter(item =>
            item
            && (!(item as GroupActions).groupId || sectionConfig.getActionState((item as GroupActions).groupId) === ConfigStates.Visible)
            && sectionConfig.getActionState(item.id as string) === ConfigStates.Visible
        )
        : preparedItems;
    // filter groups where may be that no items left
    const configuredGroupsWithItems = isActonConfigExist
        ? configuredGroups.filter(({ id: groupId }) => configuredItems.some(item => (item as GroupActions).groupId === groupId))
        : configuredGroups;

    return {
        configuredGroups: configuredGroupsWithItems.filter(group => group),
        configuredItems,
    };
};
