/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ERdsEngineTypes {
    Unknown = 0,
    MsSql = 1,
    AzureSql = 2,
    Postgres = 3,
    MySql = 4,
    MariaDb = 5,
    OracleDb = 6,
}
