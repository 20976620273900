/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn } from '@veeam-vspc/components';

import type { BackupServerJobProcessedVmCounterModel } from '@veeam-vspc/models/web-controllers';

import { JobTypes } from 'core/enums';
import { getIconSrc, getColumnText } from './helpers';
import { useLang } from 'views/providers/LangProvider/hooks';
import { ProcessedVmsCdpJobsDialog, ProcessedVmsJobsDialog } from './components';

export interface ProcessedVmsColumnProps {
    value: BackupServerJobProcessedVmCounterModel;
    jobType: JobTypes;
    jobUid: string;
    jobName: string;
    stateId: string;
}

export const ProcessedVmsColumn: React.FC<ProcessedVmsColumnProps> = ({ value, jobType, jobUid, jobName, stateId }) => {
    const lang = useLang();
    const { total, warning, failed, heaviestStatus } = value;

    const iconSrc = getIconSrc(heaviestStatus);
    const content = jobType !== JobTypes.SureBackup && jobType !== JobTypes.NutanixSnapshotJob
        ? getColumnText ({ total, warning, failed }, lang)
        : null;

    return (
        <ActionLinkColumn
            content={content}
            isLink={total && Boolean(content)}
            isExtMode={true}
            contentIconBefore={content ? iconSrc : null}
            renderPageDialog={({ onClose }) => {
                if (jobType === JobTypes.CdpReplica) {
                    return (
                        <ProcessedVmsCdpJobsDialog
                            stateId={stateId}
                            onClose={onClose}
                            jobUid={jobUid}
                            title={`${lang.PROCESSED_OBJECTS_LAST_12_HOURS} - ${jobName}`}
                        />
                    );
                }

                return (
                    <ProcessedVmsJobsDialog
                        stateId={stateId}
                        onClose={onClose}
                        jobUid={jobUid}
                        jobType={jobType}
                        title={`${lang.PROCESSED_OBJECTS_LAST_12_HOURS_OR_SESSION} - ${jobName}`}
                    />
                );
            }}
        />
    );
};
