/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { Microsoft365ObjectType } from '@veeam-vspc/models/web-controllers';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ProtectedMicrosoft365ObjectsContext } from './contexts/ProtectedMicrosoft365ObjectsContext';
import { ProtectedMicrosoft365ObjectsService } from './services/protected-microsoft-365-objects.service';
import { ProtectedMicrosoft365ObjectsStore } from './stores/ProtectedMicrosoft365ObjectsStore';
import { StatefulCombobox } from '../../../StatefulCombobox';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useProtectedMicrosoft365ObjectsStore } from './hooks/use-protected-microsoft-365-objects-store';

import type { ComboboxItem } from '../../../../interfaces';

export const ChartLayout = observer(() => {
    const lang = useLang();
    const protectedMs365Object = useProtectedMicrosoft365ObjectsStore();

    const params = {
        colors: [palette.B400],
        yTitle: lang.OBJECTS,
    };

    const title = {
        text: capitalize(lang.PROTECTED_MICROSOFT_365_OBJECTS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={protectedMs365Object.setMicrosoft365ObjectType}
                stateId={'trends.backup-services.protected-ms-365-objects.ms-365-object-type'}
                textGetter={(p: ComboboxItem) => p.text}
                value={protectedMs365Object.microsoft365ObjectType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: Microsoft365ObjectType.User, text: capitalize(lang.USERS) },
                    { id: Microsoft365ObjectType.Group, text: capitalize(lang.GROUPS) },
                    { id: Microsoft365ObjectType.SharePoint, text: capitalize(lang.SITES) },
                    { id: Microsoft365ObjectType.Team, text: capitalize(lang.TEAMS) },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={protectedMs365Object.microsoft365ObjectType}
            params={params}
            service={ProtectedMicrosoft365ObjectsService}
            title={title}
        />
    );
});

export const ProtectedMicrosoft365Objects: React.FC = () => {
    const objectsStore = useMemo(() => new ProtectedMicrosoft365ObjectsStore(), []);

    return (
        <ProtectedMicrosoft365ObjectsContext.Provider value={objectsStore}>
            <ChartLayout />
        </ProtectedMicrosoft365ObjectsContext.Provider>
    );
};
