/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable } from 'mobx';
import { AmazonObjectType } from '@veeam-vspc/models/web-controllers';

export class ProtectedAwsWorkloadsStore {
    @observable amazonObjectType = AmazonObjectType.EC2;

    @action.bound
    setAmazonObjectType(v: AmazonObjectType): void {
        this.amazonObjectType = v;
    }
}
