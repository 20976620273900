/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { constants } from '@veeam-vspc/components';

export const ContentWrapper = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr ${constants.SPACING_S} 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${constants.SPACING_S};
`;
