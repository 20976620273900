/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { capitalize, deepCopy } from '@veeam-vspc/core';
import { ACTION_VIEW, SortingMode, TextColumn } from '@veeam-vspc/components';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { GridStore, WizardStepData } from '@veeam-vspc/components';

import { ActionsToolbar } from './components/ActionsToolbar';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useResellerWizardStore } from '../../../../hooks';

import type { ResellerVboResource, ResellerData } from '../../../../interfaces';

export interface Microsoft365ServersProps extends WizardStepData<ResellerData> {
    hidePanel: () => void;
}

export const Microsoft365Servers = observer((props: Microsoft365ServersProps) => {
    const lang = useLang();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<ResellerVboResource, void, void>>();
    const wizardStore = useResellerWizardStore();

    const [items, setItems] = useState(deepCopy(data.vboResources));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.current.reloadGrid();
    }, [items]);

    useEffect(() => {
        wizardStore.availableVboServersCache.load()
            .then(() => setLoading(false));
    }, []);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.VEEAM_BACKUP_FOR_MICROSOFT_365_SERVERS}
            description={lang.SELECT_ONE_OR_MORE_MICROSOFT_365}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.vboResources = items;
                        onDataChange(data);

                        hidePanel();
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid <ResellerVboResource, void, void>
                defaultColumnWidth={100}
                columns={[
                    {
                        field: 'vboInstanceUid',
                        title: lang.SERVER,
                        cell: ({ rowData }) => (
                            <TextColumn>
                                {wizardStore.availableVboServersCache.value?.find(item => item.instanceUid === rowData.vboInstanceUid)?.name}
                            </TextColumn>
                        ),
                    },
                    { field: 'vboFriendlyName', title: capitalize(lang.FRIENDLY_NAME) },
                ]}
                data={() => Promise.resolve({
                    data: items,
                    meta: {
                        pagingInfo: {
                            total: items?.length,
                        },
                    },
                } as BaseSuccessRequestResponse<ResellerVboResource[]>)}
                toggleable={false}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'vboInstanceUid',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => <ActionsToolbar items={items} setItems={setItems} />,
                ]}
                api={api}
                isLoading={loading}
                sortingMode={SortingMode.Local}
            />
        </SidePanelForm>
    );
});
