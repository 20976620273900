/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable } from 'mobx';
import { ManagedVmType } from '@veeam-vspc/models/web-controllers';

export class ManagedVmsStore {
    @observable managedVmType = ManagedVmType.All;

    @action.bound
    setManagedVmType(v: ManagedVmType): void {
        this.managedVmType = v;
    }
}
