/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { useIntervalRequest } from 'views/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { AlarmStatus } from '../../enums';
import { TOGGLE_FILTER_ALL_VALUE } from '../../configs';
import companyItemSrc from 'images/company.svg';
import { TableWidget } from 'views/components/TableWidget';
import { NameColumn } from 'views/components/TableWidget/components/NameColumn';
import { HealthyColumn } from 'views/components/TableWidget/components/HealthyColumn';
import { EmptyColumnPlaceholder } from 'views/components/TableWidget/components/EmptyColumnPlaceholder';
import { ErrorsColumn } from 'views/components/TableWidget/components/ErrorsColumn';
import { WarningsColumn } from 'views/components/TableWidget/components/WarningsColumn';

import type { TableColumnProps } from '../../../../components/TableWidget/interfaces';
import type { Alarms } from '../../interfaces';

export const CompaniesOverview: React.FC = () => {
    const lang = useLang();
    const request = useIntervalRequest<{}, Alarms>('Overview/Companies');
    const data = request.data as Alarms;
    const columns: TableColumnProps<Alarms>[] = [
        {
            cellRenderer: ({ key }) => (
                <NameColumn icon={companyItemSrc} name={lang.MANAGED_COMPANIES} key={key} />
            ),
        },
    ];

    if (data?.errors?.value === 0 && data?.warnings?.value === 0) {
        columns.push({
            cellRenderer: ({ key }) => (
                <React.Fragment key={key}>
                    <HealthyColumn />
                    <EmptyColumnPlaceholder />
                </React.Fragment>
            ),
        });
    } else {
        columns.push({
            cellRenderer: ({ key, rowData }) => (
                rowData.errors.value > 0 ? <ErrorsColumn count={rowData.errors.value} key='errors' /> : <EmptyColumnPlaceholder key={key} />
            ),
        },
        {
            cellRenderer: ({ key, rowData }) => (
                rowData.warnings.value > 0 ? <WarningsColumn count={rowData.warnings.value} key='warnings' /> : <EmptyColumnPlaceholder key={key} />
            ),
        });
    }

    return (
        <TableWidget
            title={lang.OVERVIEW}
            height={132}
            shiftColumns
            columns={columns}
            onClick={() => {
                const filters = `statusesFilter/${AlarmStatus.Warning},${AlarmStatus.Error}/productsFilter/${TOGGLE_FILTER_ALL_VALUE}/` +
                    `dateFilter/${TOGGLE_FILTER_ALL_VALUE}/scopesFilter/${TOGGLE_FILTER_ALL_VALUE}`;

                window.location.assign(`#home/activeAlarms/tab/alarms/${filters}`);
            }}
            data={data ? [data] : []}
            loading={request.loading}
        />
    );
};
