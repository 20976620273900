/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Vb365BackupJobSchedulePolicy } from '@veeam-vspc/models/rest';

const { SchedulePolicyTypeEnum, PeriodicallyEveryEnum } = Vb365BackupJobSchedulePolicy;

export const isPeriodicScheduleApplicable = (schedulePolicy: Vb365BackupJobSchedulePolicy) => [
    PeriodicallyEveryEnum.Hours1,
    PeriodicallyEveryEnum.Hours2,
    PeriodicallyEveryEnum.Hours4,
    PeriodicallyEveryEnum.Hours8,
].includes(schedulePolicy.periodicallyEvery) &&
    schedulePolicy.schedulePolicyType === SchedulePolicyTypeEnum.Periodically &&
    schedulePolicy.scheduleEnabled;