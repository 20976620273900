/**
 * Copyright © Veeam Software Group GmbH.
 */

export const MAX_INTEGER_32 = 2147483647;

export const DEFAULT_PASSWORD = '\u2022'.repeat(6);

export const URL_PARAMS = {
    ResetPasswordFlag: 'resetCode', // the name is connected with the same name in ext.js
    SmtpOAuthAzureRedirectFlag: 'azureSmtpOAuth', // the name is connected with the same name in ext.js
    SmtpOAuthGoogleRedirectFlag: 'googleSmtpOAuth', // the name is connected with the same name in ext.js
};
