/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    DurationColumn,
    DurationFormatTypes,
    GridColumnJustify,
    GridSortDirections,
    PageDialog,
    TextColumn,
} from '@veeam-vspc/components';

import type { CellRendererProps, PageDialogProps } from '@veeam-vspc/components';
import type { JobProcessedVmParam } from '@veeam-vspc/models/web-controllers/jobProcessedVmParam';
import type { BackupJobProcessedVmDataModel } from '@veeam-vspc/models/web-controllers/backupJobProcessedVmDataModel';

import { JobTypes } from 'core/enums';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices, useAppStore } from 'views/providers/AppProvider/hooks';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { StatusColumn } from 'views/components/columns/StatusColumn';
import { RestorePointColumn } from 'views/pages/BackupJobsPage/components/RestorePointColumn';
import { ProcessedVmsDialogToolbar } from '../ProcessedVmsDialogToolbar';
import { getFailureMessage } from 'views/components/FailureMessageDialog';
import {
    RestorePointColumnsFields,
} from 'views/pages/BackupJobsPage/components/RestorePointColumn/components/RestorePointDialog';
import {
    JobSessionTaskStatusRepresentation,
    JobSessionTaskStatusRepresentationText,
} from '../../../../../../../../enums';


export interface ProcessedVmsJobsDialogProps extends Omit<PageDialogProps, 'content'> {
    jobUid: string;
    stateId: string;
    jobType: JobTypes;
}

export const ProcessedVmsJobsDialog: React.FC<ProcessedVmsJobsDialogProps> = (props) => {
    const lang = useLang();
    const { formats } = useAppStore();
    const { netShortDate, netShortTime } = formats;
    const { transportService } = useAppServices();
    const { jobUid, stateId, title, description, disabledBack, hasBack, footer, onClose, jobType } = props;
    const gridStateId = `${stateId}.Dialogs.ProcessedVms`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<BackupJobProcessedVmDataModel>) => (
        <TextColumn>{cellData}</TextColumn>
    );

    return (
        <PageDialog
            title={title}
            description={description}
            disabledBack={disabledBack}
            hasBack={hasBack}
            footer={footer}
            onClose={onClose}
            content={(
                <AdvancedGrid
                    stateId={gridStateId}
                    initialState={{
                        filters: {
                            jobUid,
                        },
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: 'name',
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={[
                        {
                            field: 'name',
                            title: lang.OBJECT,
                            cell: defaultColumnRender,
                        },
                        {
                            field: 'status',
                            title: lang.STATUS,
                            cell: ({ cellData, rowData }: CellRendererProps<BackupJobProcessedVmDataModel>) => {
                                const statusName = rowData.status;
                                const linkStatuses = [
                                    JobSessionTaskStatusRepresentation.Failed,
                                    JobSessionTaskStatusRepresentation.Warning,
                                ];
                                const isLink = !!rowData.failureMessage && linkStatuses.includes(statusName);

                                return (
                                    <StatusColumn
                                        status={
                                            JobSessionTaskStatusRepresentationText[rowData.status]
                                        }
                                        messageBoxTitle={lang.STATUS}
                                        message={isLink &&
                                            getFailureMessage(lang, {
                                                statusName,
                                                shortDateFormat: netShortDate,
                                                shortTimeFormat: netShortTime,
                                                failureMessage: rowData.failureMessage,
                                                lastSessionTime: rowData.lastSessionEndTime,
                                            })}
                                    />
                                );
                            },
                        },
                        {
                            field: 'transferredDataKb',
                            title: lang.TRANSFERRED_DATA,
                            cell: ({ cellData }: CellRendererProps<BackupJobProcessedVmDataModel>) =>
                                <RestorePointSizeColumn value={cellData as any} />,
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'guestOS',
                            title: lang.GUEST_OS,
                            cell: defaultColumnRender,
                        },
                        {
                            field: 'restorePointsCount',
                            title: lang.RESTORE_POINTS,
                            cell: ({ cellData, rowData }: CellRendererProps<BackupJobProcessedVmDataModel>) => {
                                const isReplicaJob = jobType === JobTypes.Replica || jobType === JobTypes.VcdReplica;
                                const hiddenColumns = isReplicaJob ? [
                                    RestorePointColumnsFields.SourceSize,
                                    RestorePointColumnsFields.BackedUpData,
                                    RestorePointColumnsFields.RestorePointSize,
                                ] : [];

                                return (
                                    <RestorePointColumn
                                        value={cellData}
                                        stateId={`${gridStateId}.Dialogs.RestorePoints`}
                                        name={rowData.name}
                                        noteBarIsHidden={rowData.isOneBackupPerVmEnabled || isReplicaJob}
                                        hiddenColumns={hiddenColumns}
                                        extraParams={{
                                            jobUid: jobUid,
                                            vmUid: rowData.vmUid,
                                        }}
                                    />
                                );
                            },
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                        {
                            field: 'lastSuccessSession',
                            title: lang.LAST_RUN,
                            cell: ({ cellData }: CellRendererProps<BackupJobProcessedVmDataModel>) => <GridDateColumn value={cellData} />,
                        },
                        {
                            field: 'lastDurationSessionSec',
                            title: lang.LAST_DURATION,
                            cell: ({ cellData }: CellRendererProps<BackupJobProcessedVmDataModel>) => (
                                <DurationColumn
                                    value={cellData as any}
                                    formatType={DurationFormatTypes.TIMEw8}
                                />
                            ),
                            headerJustify: GridColumnJustify.Right,
                            justify: GridColumnJustify.Right,
                        },
                    ]}
                    data={(requestParams: JobProcessedVmParam) => transportService
                        .request<typeof requestParams, BackupJobProcessedVmDataModel[]>(
                            '/BackupServerJob/GetProcessedVms',
                            { jobUid, ...requestParams },
                        )}
                    toggleable={false}
                    selection={{
                        field: 'vmUid',
                        checkbox: true,
                        multiple: true,
                    }}
                    toolbars={[
                        () => <ProcessedVmsDialogToolbar idProperty={'vmUid'} />,
                    ]}
                />
            )}
        />
    );
};
