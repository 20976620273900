/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { StackView, STACK_GAP, STACK_DIRECTION, ToggleKit } from '@veeam-vspc/components';

import type { WizardStep } from '@veeam-vspc/components';
import type { WizardStepData } from '@veeam-vspc/components/src/index';

import { UserMfaStatuses } from 'views/pages/RolesAndUsersPage/components/Base/components/MultiFactorAuthentication/user-mfa-statuses';
import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { NoteBar } from 'components/layouts/NoteBar';

import type { MultiFactorAuthModel } from './multi-factor-auth-model';

export const MultiFactorAuth: React.FC<WizardStepData<MultiFactorAuthModel>> = ({ data, isEdit, onDataChange }) => {
    const lang = useLang();

    return (
        <StepLayout
            title={lang.MULTI_FACTOR_AUTHENTICATION}
            description={lang.CONFIGURE_MFA_ACCESS}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                <ToggleKit
                    value={data.mfaPolicyStatus === UserMfaStatuses.NotConfigured || data.mfaPolicyStatus === UserMfaStatuses.Enabled}
                    onChange={(isMfaEnabled) => {
                        if (isMfaEnabled) {
                            data.mfaPolicyStatus = UserMfaStatuses.Enabled;
                        } else {
                            data.mfaPolicyStatus = UserMfaStatuses.Disabled;
                        }

                        onDataChange(data);
                    }}
                    showSuffix={true}
                    titles={{ on: lang.ENFORCE_THE_MFA_ACCESS, off: lang.ENFORCE_THE_MFA_ACCESS }}
                    disabled={!data.isAllowedToSwitchMfaPolicyStatus}
                />

                <NoteBar>
                    {isEdit ? (
                        <>
                            <p>{lang.UPON_ENABLING_THIS_OPTION_USER_WILL_BE_ASKED}</p>
                            <p>{lang.IF_THIS_USER_IS_LEVERAGING_BACKUP_PORTAL}</p>
                            <p>{lang.TO_RESOLVE_THAT_THIS_CLIENT_NEEDS_TO_START}</p>
                        </>
                    ) :
                        <p>{lang.UPON_ENABLING_THIS_OPTION_USER_WILL_BE_ASKED}</p>}
                </NoteBar>
            </StackView>
        </StepLayout>
    );
};

export const getMultiFactorAuthStep = (title: string): WizardStep<MultiFactorAuthModel> => ({
    title,
    render: data => <MultiFactorAuth {...data} />,
});
