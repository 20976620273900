/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TitleH2 } from '@veeam-vspc/components';

import { TableTitleStyled } from '../TableTitleStyled';

import type { TableColumnProps } from '../../interfaces';

interface TableTitleProps<T> {
    columns: TableColumnProps<T>[];
}

export const TableTitle = <T,>({ columns }: TableTitleProps<T>): ReturnType<React.FC<TableColumnProps<T>>> => (
    <TableTitleStyled>
        {columns.map(({ name }, index) => <TitleH2 key={index} style={{ marginBottom: 0 }}>{name}</TitleH2>)}
    </TableTitleStyled>
);
