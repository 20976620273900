/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import get from 'lodash/get';

import { TableRowStyled } from '../TableRowStyled';
import { EmptyColumnPlaceholder } from '../EmptyColumnPlaceholder';

import type { TableItemProps } from './interfaces';
import type { TableColumnProps } from '../../interfaces';

export const TableItem = <T,>({
    data,
    columns,
}: TableItemProps<T>): ReturnType<React.FC<TableItemProps<T>>> => {
    const renderColumn = (column: TableColumnProps<T>, index: number) => {
        if (column.cellRenderer) {
            return column.cellRenderer({ rowData: data, key: index });
        }

        if (column.field) {
            return <span key={index}>{get(data, column.field)}</span>;
        }

        return <EmptyColumnPlaceholder key={index} />;
    };

    return (
        <TableRowStyled>
            {columns.map(renderColumn)}
        </TableRowStyled>
    );
};
