/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/components';

export const OverviewPageStyled = styled.div`
  display: flex;
  padding: ${constants.SPACING_M} ${constants.SPACING_L};
  overflow-y: auto;
  flex-wrap: wrap;
  align-self: stretch;
  flex: 1 1 100%;
  align-content: flex-start;
  background-color: ${colors.G200};

  h1 {
    margin-bottom: 0;
  }

  .fb-50 {
    flex-basis: 50%;
  }

  @media only screen and (min-width: 1800px) {
    .summary-section {
      order: 1;
      flex-basis: 60%;
    }

    .product-updates-section {
      order: 2;
      flex-basis: calc(40% - ${constants.SPACING_L});
      margin-bottom: ${constants.SPACING_L};
      margin-left: ${constants.SPACING_L};
    }

    .companies-health-section {
      order: 3;
      flex-basis: 35%;
    }

    .infrastructure-health-section {
      order: 4;
      flex-basis: calc(65% - ${constants.SPACING_L});
      margin-left: ${constants.SPACING_L};
    }
  }
`;
