/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable } from 'mobx';
import { Microsoft365ObjectType } from '@veeam-vspc/models/web-controllers';

export class ProtectedMicrosoft365ObjectsStore {
    @observable microsoft365ObjectType = Microsoft365ObjectType.User;

    @action.bound
    setMicrosoft365ObjectType(v: Microsoft365ObjectType): void {
        this.microsoft365ObjectType = v;
    }
}
