/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable } from 'mobx';

import type { BaseRequestResponse } from '@veeam-vspc/core';
import type { Vb365BackupJob, Vb365BackupProxy, Vb365BackupRepository, Vb365CopyJob } from '@veeam-vspc/models/rest';

import { core } from 'core/core-module';
import { VBObjectsService } from '../../../services';

export class CopyJobWizardStore {
    @observable backupRepositories: Vb365BackupRepository[] = [];
    @observable backupProxies: Vb365BackupProxy[] = [];

    constructor(
        private vb365ServerUid: string,
        private initialData: Vb365CopyJob,
        public sourceJob: Vb365BackupJob
    ) {
        this.loadBackupRepositories(this.vb365ServerUid);
        this.loadProxies(this.vb365ServerUid);
    }

    filterRepositories(repositories: Vb365BackupRepository[]) {
        const sourceRepository = repositories.find(x => x.instanceUid === this.sourceJob.repositoryUid);

        this.backupRepositories = repositories.filter((repository) => {
            if (repository.instanceUid === sourceRepository.instanceUid) {
                return false;
            }

            if (!repository.isAvailableForCopyJob) {
                return false;
            }

            if (repository.retentionType !== sourceRepository.retentionType || repository.proxyUid !== sourceRepository.proxyUid) {
                return false;
            }

            return true;
        });
    }

    @action
    loadBackupRepositories(vb365ServerUid: string): void {
        VBObjectsService.loadBackupRepositories(vb365ServerUid)
            .then(backupRepositories => this.filterRepositories(backupRepositories));
    }

    @action
    loadProxies(vb365ServerUid: string): void {
        VBObjectsService.loadProxies(vb365ServerUid)
            .then((response) => {
                this.backupProxies = response;
            });
    }

    selectedBackupRepository(job: Vb365CopyJob): Vb365BackupRepository {
        return this.backupRepositories.find(x => x.instanceUid === job.repositoryUid);
    }

    createJob(data: Vb365CopyJob): Promise<BaseRequestResponse<Vb365CopyJob>> {
        return core.transportService.post<Vb365CopyJob, Vb365CopyJob>(
            `/infrastructure/vb365Servers/${this.vb365ServerUid}/organizations/jobs/copy/sync`,
            data
        );
    }

    patchJob(data: Vb365CopyJob): Promise<BaseRequestResponse<Vb365CopyJob>> {
        return core.transportService.patch<Vb365CopyJob, Vb365CopyJob>(
            `/infrastructure/vb365Servers/${this.vb365ServerUid}/organizations/jobs/copy/${data.instanceUid}/sync`,
            { original: this.initialData, current: data }
        );
    }
}
