/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum ConnectionStatuses {
    None = 'none',
    Connecting = 'connecting',
    Success = 'success',
    Failed = 'failed',
}
