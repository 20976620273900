/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';

export const SpinnerWrapperStyled = styled.div`
    align-items: center;
    background-color: white;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
`;
