/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect, useState } from 'react';

import type { RequestAdditionalParams } from '@veeam-vspc/core';

import { useTransportRequest } from '../use-transport-requests';
import { useGlobalFiltersChangeHandler } from '../../providers/GlobalFiltersProvider/hooks';

import type { UseRequestsData } from '../use-transport-requests';

export const useIntervalRequest = <T, R>(
    url: string,
    data?: T,
    customOptions?: RequestInit,
    params?: RequestAdditionalParams,
    timeout = 60000,
    useGlobalFilters = true,
): UseRequestsData<R> => {
    const [isAutoUpdate, setAutoUpdate] = useState(false);
    const request = useTransportRequest<T, R>(url, isAutoUpdate ? { ...data, rcopAutoUpdate: true } : data, customOptions, params);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setAutoUpdate(true);

            request.forceRequest();
        }, timeout);

        return () => clearInterval(intervalId);
    }, []);

    useGlobalFiltersChangeHandler(() => {
        if (useGlobalFilters) {
            setAutoUpdate(false);

            request.forceRequest();
        }
    });

    return request;
};
