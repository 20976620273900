/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ColumnTexts, LinkButton, TextColumn } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { Vb365JobItemComposed, Vb365JobItemUser } from '@veeam-vspc/models/rest';

import type { FC } from 'react';

import { useLang } from 'views/providers/LangProvider/hooks';
import { BackupOptionsTarget } from '../../../../enums';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { ConfigurableOption, OptionsCellProps } from '../../interfaces';

const optionsNames = (lang: LangsServiceBase): Record<ConfigurableOption, string> => ({
    backupMailbox: lang.MAIL,
    backupArchiveMailbox: lang.ARCHIVE,
    backupOneDrive: lang.ONE_DRIVE,
    backupPersonalSite: lang.SITE,
    backupMembers: lang.MEMBERS,
    backupSites: lang.SITES,
    backupTeams: lang.TEAMS,
    backupMemberMailbox: lang.MAIL,
    backupMemberArchiveMailbox: lang.ARCHIVE,
    backupMemberOneDrive: lang.ONE_DRIVE,
    backupMemberSite: lang.SITE,
    backupGroupSite: lang.GROUP_SITE,
    backupTeamsChats: lang.TEAMS_CHATS,
});

export const getOptionName = (option: ConfigurableOption, itemType: Vb365JobItemComposed.ItemTypeEnum, lang: LangsServiceBase) => {
    if (itemType === Vb365JobItemComposed.ItemTypeEnum.Group && option === 'backupMailbox') {
        return lang.GROUP_MAIL;
    }

    if (itemType === Vb365JobItemComposed.ItemTypeEnum.Team && option === 'backupTeamsChats') {
        return lang.CHATS;
    }

    return optionsNames(lang)[option];
};

export const isItemEditable = (item: Vb365JobItemComposed, targetCollectionType: BackupOptionsTarget) => {
    const itemTypesThatCouldBeEdited = [
        Vb365JobItemComposed.ItemTypeEnum.User,
        Vb365JobItemComposed.ItemTypeEnum.Group,
        Vb365JobItemComposed.ItemTypeEnum.PartialOrganization,
        Vb365JobItemComposed.ItemTypeEnum.Team,
    ];

    if (!itemTypesThatCouldBeEdited.includes(item.itemType)) {
        return false;
    }

    if (item.user?.userType === Vb365JobItemUser.UserTypeEnum.Public) {
        return false;
    }


    if (
        targetCollectionType === BackupOptionsTarget.ExcludedItems &&
        item.itemType === Vb365JobItemComposed.ItemTypeEnum.Team
    ) {
        return false;
    }

    return true;
};

const getOptions = (
    lang: LangsServiceBase,
    resource: Vb365JobItemComposed,
    options: ConfigurableOption[]
): string => {
    if (options.every(option => resource[option] === true)) {
        return lang.ALL;
    }

    if (options.every(option => resource[option] === false) && resource.itemType !== Vb365JobItemComposed.ItemTypeEnum.Team) {
        return ColumnTexts.DashSymbol;
    }

    const optionsNames = options
        .filter(option => resource[option] === true)
        .map(option => getOptionName(option, resource.itemType, lang));

    // Add fake Teams option
    if (resource.itemType === Vb365JobItemComposed.ItemTypeEnum.Team) {
        optionsNames.push(lang.CHANNELS_TABS_FILES);
    }

    return optionsNames.join('/');
};

export const OptionsCell: FC<OptionsCellProps> = observer(({ rowData, store, collection, onClick }) => {
    const lang = useLang();
    const item = store[collection].find(i => i.id === rowData.id);

    if (!item) {
        return;
    }

    const options = store.getProcessingOptions(item);

    const plaintext = <TextColumn>{getOptions(lang, rowData, options)}</TextColumn>;

    if (options.length === 0) {
        return plaintext;
    }

    if (options.length === 1 && options[0] === 'backupMembers') {
        return plaintext;
    }

    if (!isItemEditable(rowData, collection)) {
        return plaintext;
    }

    return <LinkButton onClick={onClick}>{getOptions(lang, item, options)}</LinkButton>;
});
