/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    Form,
    FormValidator,
    Combobox,
    CONTROL_SIZE,
    FormLayout,
    isValid,
    useExternalFormApi,
    FieldLayout,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    NumberInput,
    createSearchableControl,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { TaxTypes } from 'core/enums';
import { useSubscriptionPlanStore } from '../../hooks';
import { currenciesCache } from 'core/utils/common-caches';

import type { SubscriptionPlanModel, CurrencyItem } from 'core/interfaces';

export const stepValidate = (data: SubscriptionPlanModel) => {
    const validator = new FormValidator(data);

    validator
        .validate('currency')
        .string()
        .required();
    validator
        .validate('vatAlias')
        .number()
        .required();
    validator
        .validate('vat')
        .number()
        .min(0)
        .max(100);
    validator.validate('discount')
        .number()
        .min(0)
        .max(100);

    return validator.result();
};

export const getCurrencyStep = (title: string): WizardStep<SubscriptionPlanModel> => ({
    title,
    validate: ({ data, stepData, isEdit }) => isValid(stepValidate, data),
    render: data => <CurrencyStep {...data} />,
});

const CurrencyStep = ({ data, validationState, onDataChange, stepData }: WizardStepData<SubscriptionPlanModel>) => {
    const lang = useLang();
    const formApi = useExternalFormApi();
    const spStore = useSubscriptionPlanStore();
    const [currencyList, setCurrencyList] = useState<CurrencyItem[]>(spStore.currencies);
    const [taxList] = useState(() => ([
        {
            id: TaxTypes.Vat,
            name: lang.VAT,
        }, {
            id: TaxTypes.Gst,
            name: lang.GST,
        }, {
            id: TaxTypes.SalesTax,
            name: lang.SALES_TAX,
        },
    ]));

    useEffect(() => {
        currenciesCache.load()
            .then(resp => setCurrencyList(resp));
    }, []);


    return (
        <StepLayout
            title={capitalize(lang.CURRENCY)}
            description={lang.DEFINE_CURRENCY}
        >
            <Form
                value={data}
                validate={stepValidate}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <Combobox
                        name='currency'
                        label={lang.CURRENCY}
                        data={currencyList}
                        valueGetter={item => item.code}
                        textGetter={item => `${item.name} (${item.code})`}
                        size={'264px'}
                        controlRenderer={createSearchableControl()}
                    />

                    <FieldLayout label={`${lang.TAX}:`}>
                        <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                            <Combobox
                                name='vatAlias'
                                data={taxList}
                                valueGetter={item => item.id}
                                textGetter={item => item.name}
                                size={CONTROL_SIZE.s}
                            />

                            <NumberInput
                                name='vat'
                                allowDecimal
                                precision={2}
                                minValue={0}
                                maxValue={100}
                                size={CONTROL_SIZE.xxs}
                                suffix={'%'}
                            />
                        </StackView>
                    </FieldLayout>

                    <NumberInput
                        name='discount'
                        allowDecimal
                        precision={2}
                        minValue={0}
                        maxValue={100}
                        label={lang.DISCOUNT}
                        size={CONTROL_SIZE.xxs}
                        suffix={'%'}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
};
