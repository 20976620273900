/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridColumnJustify, GridSortDirections, TextColumn } from '@veeam-vspc/components';

import type { CellRendererProps } from '@veeam-vspc/components';
import type { PublicCloudRdsModel, PublicCloudRdsParam } from '@veeam-vspc/models/web-controllers';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { ERdsBackupTypes } from 'core/enums';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { CloudDatabasesActionToolbar, CloudDatabasesColumn, CloudDatabasesFilterToolbar } from './components';
import { ConfigSectionIds } from 'core/services';

export interface CloudDatabasesProps {
    stateId: string;
}

export const CloudDatabases: React.FC<CloudDatabasesProps> = ({ stateId }) => {
    const { transportService, enumMapperService } = useAppServices();
    const lang = useLang();
    const gridStateId = `${stateId}.CloudDatabases`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<PublicCloudRdsModel>) => <TextColumn>{cellData}</TextColumn>;
    const columns = [
        {
            field: 'vmName',
            title: lang.INSTANCE,
            cell: defaultColumnRender,
        },
        {
            field: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: defaultColumnRender,
            width: 150,
        },
        {
            field: 'companyName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'policyName',
            title: lang.POLICY,
            cell: defaultColumnRender,
        },
        {
            field: 'platformType',
            title: lang.PLATFORM,
            cell: ({ cellData }: CellRendererProps<PublicCloudRdsModel>) => (
                <TextColumn>
                    {enumMapperService.getEnumDescription('PublicCloudPlatformTypeRepresentation', cellData)}
                </TextColumn>
            ),
        },
        {
            field: 'backup.backupCount',
            title: lang.BACKUPS,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsModel>) => (
                <CloudDatabasesColumn
                    value={rowData.backup?.backupCount}
                    name={`${lang.BACKUPS} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: ERdsBackupTypes.Backup,
                    }}
                    stateId={`${gridStateId}.Dialogs.CloudDatabasesRestorePoint.'Backup`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'archive.backupCount',
            title: lang.ARCHIVES,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsModel>) => (
                <CloudDatabasesColumn
                    value={rowData.archive?.backupCount}
                    name={`${lang.ARCHIVES} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: ERdsBackupTypes.Archive,
                    }}
                    stateId={`${gridStateId}.Dialogs.CloudDatabasesRestorePoint.Archive`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'snapshot.backupCount',
            title: lang.SNAPSHOTS,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsModel>) => (
                <CloudDatabasesColumn
                    value={rowData.snapshot?.backupCount}
                    name={`${lang.SNAPSHOTS} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: ERdsBackupTypes.Snapshot,
                    }}
                    stateId={`${gridStateId}.Dialogs.CloudDatabasesRestorePoint.Snapshot`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'remoteSnapshot.backupCount',
            title: lang.REPLICA_SNAPSHOTS,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsModel>) => (
                <CloudDatabasesColumn
                    value={rowData.remoteSnapshot?.backupCount}
                    name={`${lang.REPLICA_SNAPSHOTS} - ${rowData.vmName}`}
                    extraParams={{
                        backupServerUid: rowData.backupServerUid,
                        vmUid: rowData.vmUid,
                        policyUid: rowData.policyUid,
                        platformType: rowData.platformType,
                        backupType: ERdsBackupTypes.RemoteSnapshot,
                    }}
                    stateId={`${gridStateId}.Dialogs.CloudDatabasesRestorePoint.RemoteSnapshot`}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
            width: 150,
        },
        {
            field: 'engine',
            title: lang.ENGINE,
            cell: ({ cellData }: CellRendererProps<PublicCloudRdsModel>) => (
                <TextColumn>
                    {enumMapperService.getEnumDescription('ERdsEngineType', cellData)}
                </TextColumn>
            ),
        },
        {
            field: 'totalSize',
            title: lang.TOTAL_SIZE,
            cell: ({ cellData }: CellRendererProps<PublicCloudRdsModel>) => (
                <RestorePointSizeColumn
                    value={cellData as any}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'region',
            title: lang.REGION,
            cell: defaultColumnRender,
        },
        {
            field: 'backup.lastBackup',
            title: lang.LAST_BACKUP,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsModel>) => (
                <TimeAgoColumn
                    value={rowData.backup?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            field: 'archive.lastBackup',
            title: lang.LAST_ARCHIVE,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsModel>) => (
                <TimeAgoColumn
                    value={rowData.archive?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            field: 'snapshot.lastBackup',
            title: lang.LAST_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsModel>) => (
                <TimeAgoColumn
                    value={rowData.snapshot?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            field: 'remoteSnapshot.lastBackup',
            title: lang.LAST_REPLICA_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudRdsModel>) => (
                <TimeAgoColumn
                    value={rowData.remoteSnapshot?.lastBackup}
                />
            ),
            hidden: true,
        },
        {
            field: 'policyState',
            title: lang.POLICY_STATE,
            cell: ({ cellData }: CellRendererProps<PublicCloudRdsModel>) => (
                <TextColumn>
                    {enumMapperService.getEnumDescription('JobStateRepresentation', cellData)}
                </TextColumn>
            ),
        },
    ];

    return (
        <AdvancedGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.ProtectedDataCloudDatabases}
            defaultColumnWidth={100}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.field,
                },
            }}
            columns={columns}
            data={(requestParams: PublicCloudRdsParam) => transportService
                .request<PublicCloudRdsParam, PublicCloudRdsModel[]>('/PublicCloudRds/GetProtectedRds', { ...requestParams })}
            toggleable={true}
            selection={{
                field: 'uid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <CloudDatabasesFilterToolbar />,
                () => <CloudDatabasesActionToolbar idProperty={'uid'} selectedOnly />,
            ]}
        />
    );
};
