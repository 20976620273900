/**
 * Copyright © Veeam Software Group GmbH.
 */

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { TimePeriod } from '../enums';

dayjs.extend(utc);

export function getBeginDate(selectedPeriod: TimePeriod): Date {
    switch (selectedPeriod) {
        case TimePeriod.CurrentMonth: return dayjs()
            .utc()
            .startOf('month')
            .toDate();
        case TimePeriod.PreviousMonth: return dayjs()
            .utc()
            .subtract(1, 'month')
            .startOf('month')
            .toDate();
        case TimePeriod.Last30Days: return dayjs()
            .utc()
            .startOf('day')
            .subtract(30, 'day')
            .toDate();
    }
}

export function getEndDate(selectedPeriod: TimePeriod): Date {
    switch (selectedPeriod) {
        case TimePeriod.CurrentMonth: return dayjs()
            .utc()
            .toDate();
        case TimePeriod.PreviousMonth: return dayjs()
            .utc()
            .subtract(1, 'month')
            .endOf('month')
            .endOf('day')
            .toDate();
        case TimePeriod.Last30Days: return dayjs()
            .utc()
            .toDate();
    }
}
