/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import {
    Search,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    ACTION_VIEW,
} from '@veeam-vspc/components';

import type { GridStore, RequestParams } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { MAX_INTEGER_32 } from 'core/const';

import type { SelectUserProps } from './interfaces/select-user-props';
import type { VcdUser } from './interfaces/vcd-user';
import type { VcdUsersRequest } from './interfaces/vcd-users-request';


export const SelectUser = ({ hidePanel, data, onDataChange }: SelectUserProps) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const api = useRef<GridStore<VcdUser, any, VcdUsersRequest>>();
    const [isApplyDisabled, setApplyToDisabledState] = useState(!data.vcdUserId);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.SPECIFY_USER}
            description={lang.SELECT_USER_FROM_THE_LIST}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.vcdUserId = api.current.selected[0]?.userId;
                        data.vcdUserName = api.current.selected[0]?.name;

                        onDataChange(data);

                        hidePanel();
                    },
                    disabled: isApplyDisabled,
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid
                initialState={{
                    selected: data.vcdUserId ? [{ userId: data.vcdUserId } as VcdUser] : undefined,
                }}
                defaultColumnWidth={100}
                columns={[
                    { field: 'name', title: lang.NAME },
                ]}
                data={(requestParams: RequestParams<{}>) => transportService
                    .request<VcdUsersRequest, VcdUser[]>('Company/GetVcdOrganizationUsers', {
                        ...requestParams,
                        companyId: data.companyID,
                    })}
                toggleable={false}
                selection={{
                    field: 'userId',
                    checkbox: false,
                    multiple: false,
                }}
                onSelectionChange={(selected) => {
                    if (selected.length === 0) {
                        setApplyToDisabledState(true);
                    } else {
                        setApplyToDisabledState(false);
                    }
                }}
                paginationLimit={MAX_INTEGER_32}
                api={api}
                toolbars={[
                    () => (
                        <Toolbar
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='name'
                                            placeholder={lang.USERNAME}
                                            hasSearchButton={true}
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
            />
        </SidePanelForm>
    );
};
