/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useContext, useEffect, useState } from 'react';
import { FieldLayout, TextInputKit } from '@veeam-vspc/components';
import InputMask from 'react-input-mask';
import { FormsContext } from '@veeam-vspc/components/src/form/components/Form';
import { immediatelyValidateFlag } from '@veeam-vspc/components/src/form/entries';
import { ValidationStatus } from '@veeam-vspc/components/src/form/enums';
import hasIn from 'lodash/has';

import type { CONTROL_SIZE } from '@veeam/components';
import type { TextInputProps } from '@veeam-vspc/components/src/form/components/TextInput/TextInput';

export interface IpAddressFieldProps extends TextInputProps {}

function checkValue(value) {
    const subips = value.split('.');
    if (subips.length > 4) {
        return false;
    }
    const invalidSubips = subips.filter((ip) => {
        ip = parseInt(ip);
        return ip < 0 || ip > 255;
    });
    if (invalidSubips.length !== 0) {
        return false;
    }
    let emptyIpCount = 0;
    subips.forEach((ip) => {
        if (ip === '') {
            emptyIpCount++;
        }
    });
    if (emptyIpCount > 1) {
        return false;
    }
    return true;
}

export const IpAddressField = (props: IpAddressFieldProps) => {
    const {
        inlineLabel = false,
        label,
        suffix,
        name,
        asTag,
        helpText,
        helpTextView,
        helpTextWrap,
        fieldStyles,
        ...restProps
    } = props;

    const formsState = useContext(FormsContext);
    const { disabled } = restProps;

    const labelSuffix = restProps.labelSuffix === undefined ? ':' : restProps.labelSuffix;
    const labelText = label ? label + labelSuffix : label;
    const [value, setValue] = useState(formsState.getValue(name));
    const [touched, setTouched] = useState(false);

    const calcErrorText = () => {
        const validationStatus = formsState.validationState.getStatus();

        let error = formsState.getError(name);
        const isImmediately = error ? error.indexOf(immediatelyValidateFlag) !== -1 : false;
        const showError = (touched || validationStatus !== ValidationStatus.Common || isImmediately) && error;

        error = isImmediately ? error.replace(immediatelyValidateFlag, '') : error;

        return showError ? error : '';
    };

    const [errorText, setErrorText] = useState(() => calcErrorText());

    let correctedValue = value;

    useEffect(() => {
        const updateValueFunction = () => {
            if (hasIn(formsState.value, name)) {
                setValue(formsState.getValue(name));
            }

            setErrorText(calcErrorText());
        };

        setErrorText(calcErrorText());

        formsState.onChange(updateValueFunction);
        return () => formsState.unsubscribeOnChange(updateValueFunction);
    }, [touched]);

    return (
        <InputMask
            value={value}
            onChange={() => {
                formsState.setValue(name, correctedValue);
                restProps.onChange && restProps.onChange(correctedValue);
            }}
            onBlur={() => setTouched(true)}
            formatChars={{
                9: '[0-9.]',
            }}
            mask='999999999999999'
            maskChar={null}
            alwaysShowMask={false}
            beforeMaskedValueChange={(newState, oldState, userInput) => {
                let value = newState.value;
                const oldValue = oldState.value;
                let selection = newState.selection;
                let cursorPosition = selection ? selection.start : null;
                const result = checkValue(value);
                if (!result) {
                    value = value.trim();
                    // try to add . before the last char to see if it is valid ip address
                    const newValue = `${value.substring(0, value.length - 1) }.${ value.substring(value.length - 1)}`;
                    if (checkValue(newValue)) {
                        cursorPosition++;
                        selection = { start: cursorPosition, end: cursorPosition };
                        value = newValue;
                    } else {
                        value = oldValue;
                    }
                }

                correctedValue = value;

                return {
                    value,
                    selection,
                };
            }}
        >
            {() => (
                <FieldLayout
                    label={labelText}
                    suffix={suffix}
                    error={errorText}
                    size={restProps.size as CONTROL_SIZE}
                    disabled={disabled}
                    asTag={asTag}
                    inlineLabel={inlineLabel}
                    helpText={helpText}
                    helpTextView={helpTextView}
                    helpTextWrap={helpTextWrap}
                    style={fieldStyles}
                >
                    <TextInputKit
                        {...restProps}
                        error={errorText}
                    />
                </FieldLayout>
            )}
        </InputMask>
    );
};
