/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import {
    colors,
    constants,
    Icon,
    ICON_SIZES,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TitleH2,
} from '@veeam-vspc/components';

import infoSrc from 'images/info-light-gray.svg';
import { EmptyPlateStyled } from './EmptyPlate.styled';

export const EmptyPlate = ({ emptyText, title }) => (
    <StackView
        direction={STACK_DIRECTION.column}
        style={{ backgroundColor: colors.G100, flex: 1, padding: constants.SPACING_M }}
    >
        <TitleH2>{title}</TitleH2>

        <EmptyPlateStyled>
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <Icon size={ICON_SIZES.xl} src={infoSrc} style={{ width: '100%' }} />
                <div>{emptyText}</div>
            </StackView>
        </EmptyPlateStyled>
    </StackView>
);
