/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { TimeUnitsInMs } from '@veeam-vspc/core';
import { SelectServerForm, Icon } from '@veeam-vspc/components';

import type { TokenManagerErrorResponse } from '@veeam-vspc/core';
import type { SelectServerFormState } from '@veeam-vspc/components';


import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import allSitesSrc from 'images/all-sites.png';
import allSitesDisSrc from 'images/all-sites-dis.png';
import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor';

import type { CloudConnectServerItem, SelectServerFactor } from '../../interfaces';

export const selectServerState = {
    timerId: null,
};

export interface SelectServerProps {
    returnToLoginTime?: number;
}

export const SelectServer = observer(({ returnToLoginTime = 55 * TimeUnitsInMs.Second }: SelectServerProps) => {
    const { tokenManagerService } = useAppServices();
    const lang = useLang();
    const loginStore = useLoginStore();
    const { mfaToken, cloudConnectServerItems, login } = loginStore.currentFactor as SelectServerFactor;
    const returnToLogin = () => {
        clearTimeout(selectServerState.timerId);
        loginStore.onFinishFactorHandler({ goToLoginStart: true });
    };

    if (!selectServerState.timerId) {
        selectServerState.timerId = window.setTimeout(returnToLogin, returnToLoginTime);
    }

    return (
        <BaseFactor>
            <SelectServerForm
                title={lang.PLEASE_CHOOSE_A_SITE_TO_LOG_IN}
                returnButtonText={login}
                onReturnButtonClick={returnToLogin}
                error={loginStore.error}
                loading={loginStore.isLoading}
                data={cloudConnectServerItems}
                logIn={({ index }: SelectServerFormState) => {
                    loginStore.resetMessages();
                    loginStore.isLoading = true;

                    tokenManagerService.mfa(mfaToken, index)
                        .then((resp) => {
                            clearTimeout(selectServerState.timerId);
                            loginStore.onFinishFactorHandler({ ...resp, login });
                        })
                        .catch(({ errorDescription }: TokenManagerErrorResponse) => {
                            loginStore.isLoading = false;
                            loginStore.error = errorDescription || lang.FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT;
                        });
                }}
                iconGetter={(disabled: boolean) => disabled ? <Icon src={allSitesDisSrc} /> : <Icon src={allSitesSrc} />}
                valueGetter={({ name }: CloudConnectServerItem) => name}
                textGetter={({ name }: CloudConnectServerItem) => name}
            />
        </BaseFactor>
    );
});
