/**
 * Copyright © Veeam Software Group GmbH.
 */


import { deepClone, htmlEncode } from '@veeam-vspc/core';

import type {
    BaseRequestResponse,
    RequestAdditionalParams,
    BlobFileData,
    RequestPatchData,
    StreamFileUploaderOptions,
    StreamFileUploadParams,
} from '@veeam-vspc/core/services/transport/interfaces';
import type { BaseSuccessRequestResponse, BaseFailedRequestResponse, TransportService } from '@veeam-vspc/core';
import type { TransportActions, TransportConfig } from '@veeam-vspc/core/services/transport/entries';

import { webControllersEnumsMap } from '../../../swagger/webControllers/webControllersEnumsMap';

import type { EnumMapperService } from '../enum-mapper';
import type { EnumMap } from '../../../swagger/webControllers/interfaces/enum-map';

export class ExtTransportService {
    private transportService: TransportService;
    private enumMapperService: EnumMapperService;

    readonly actions: TransportActions;
    readonly config: TransportConfig;

    constructor(transportService: TransportService, enumMapperService: EnumMapperService) {
        this.transportService = transportService;
        this.enumMapperService = enumMapperService;

        this.actions = transportService.actions;
        this.config = transportService.config;
    }

    private replaceEnumsInRequestParams = (params: Object, enumMap: EnumMap, stepI = 0, url: string) => {
        const enumDescriptors = this.enumMapperService.getEnumDescriptors();
        const isLastStep = stepI === enumMap.path.length - 1;
        const handleEnumValue = (obj: Object, propName: string|number) => {
            const enumValue = obj[propName] as number;
            if (typeof enumValue === 'number') {
                if (!enumDescriptors[enumMap.enumName]) {
                    console.error(`No enum "${enumMap.enumName}" in enum descriptors. Request "${url}"`);
                } else {
                    const enumDescriptor = enumDescriptors[enumMap.enumName]
                        .find(enumDescriptorItem => enumDescriptorItem.intValue === enumValue);

                    if (enumDescriptor) {
                        obj[propName] = enumDescriptor.literalValue;
                    }
                }
            }
        };

        if (!params) {
            return;
        }

        if (isLastStep) {
            const pathValue = enumMap.path[stepI];
            if (pathValue === '$i') {
                (params as Array<string>).forEach((param, i) => handleEnumValue(params, i));
            } else {
                handleEnumValue(params, pathValue);
            }
        } else {
            const isArrayStep = enumMap.path[stepI] === '$i';
            if (isArrayStep) {
                const dataItemArray = params as Array<Object>;
                if (dataItemArray) {
                    dataItemArray.forEach(dataSubItem => this.replaceEnumsInRequestParams(dataSubItem, enumMap, stepI + 1, url));
                }
            } else {
                if (params) {
                    this.replaceEnumsInRequestParams(params[enumMap.path[stepI]], enumMap, stepI + 1, url);
                }
            }
        }
    };

    get<T, R>(url: string, data?: T, customOptions?: RequestInit, params?: RequestAdditionalParams): Promise<BaseRequestResponse<R>> {
        return this.transportService.get(url, data, customOptions, params);
    }

    post<T, R>(url: string, data?: T, customOptions?: RequestInit, params?: RequestAdditionalParams): Promise<BaseRequestResponse<R>> {
        return this.transportService.post(url, data, customOptions, params);
    }

    patch<T, R>(
        url: string, data?: T | RequestPatchData<T>,
        customOptions?: RequestInit,
        params?: RequestAdditionalParams
    ): Promise<BaseRequestResponse<R>> {
        return this.transportService.patch(url, data, customOptions, params);
    }

    put<T, R>(url: string, data?: T, customOptions?: RequestInit, params?: RequestAdditionalParams): Promise<BaseRequestResponse<R>> {
        return this.transportService.put(url, data, customOptions, params);
    }

    delete<T, R>(url: string, data?: T, customOptions?: RequestInit, params?: RequestAdditionalParams): Promise<BaseRequestResponse<R>> {
        return this.transportService.delete(url, data, customOptions, params);
    }

    request<T, R>(url: string, data?: T, customOptions?: RequestInit, params: RequestAdditionalParams = {}): Promise<BaseRequestResponse<R>> {
        return this.transportService.request(url, data, customOptions, params);
    }

    uploadFilesByStream(
        files: StreamFileUploadParams[],
        options: StreamFileUploaderOptions,
        params: RequestAdditionalParams = {},
    ): Promise<Record<string, string>[] | BaseFailedRequestResponse> {
        return this.transportService.uploadFilesByStream(files, options, params);
    }

    downloadFile(fileUrl: string, params: RequestAdditionalParams = {}): Promise<void> {
        return this.transportService.downloadFile(fileUrl, params);
    }

    downloadFileAjax<T>(
        fileUrl: string,
        data?: T,
        customOptions: RequestInit = {},
        params: RequestAdditionalParams = {}
    ): Promise<void | BlobFileData | BaseRequestResponse<Response>> {
        return this.transportService.downloadFileAjax(fileUrl, data, customOptions, params);
    }

    downloadFileAjaxWithReplaceEnums<T>(
        fileUrl: string,
        data?: T,
        customOptions: RequestInit = {},
        params: RequestAdditionalParams = {}
    ): Promise<void | BlobFileData | BaseRequestResponse<Response>> {
        let clearUrl = fileUrl.replace(/\?.*$/, '').toLowerCase();

        const dataClone = deepClone(data);

        if (clearUrl[0] !== '/') {
            clearUrl = `/${clearUrl}`;
        }

        if (webControllersEnumsMap[clearUrl] && webControllersEnumsMap[clearUrl].request) {
            const enumRequestMaps = webControllersEnumsMap[clearUrl].request;
            enumRequestMaps.forEach(property => this.replaceEnumsInRequestParams(dataClone, property, 0, fileUrl));
        }

        return this.transportService.downloadFileAjax(fileUrl, dataClone, customOptions, params);
    }

    uploadFile(form: any, options: any, params: RequestAdditionalParams = {}): Promise<void> {
        return this.transportService.uploadFile(form, options, params);
    }

    requestSync<T, R>(
        url: string,
        callback: (resp: BaseRequestResponse<R>) => void,
        data?: T,
        customOptions?: RequestInit,
        params: RequestAdditionalParams = {},
    ): void {
        this.transportService.requestSync(url, callback, data, customOptions, params);
    }

    requestWithReplaceEnums(
        url: string,
        data: Object,
        customOptions?: RequestInit,
        params: RequestAdditionalParams = {}
    ) {
        // XSS sanitizer
        const sanitize = htmlEncode;

        function sanitizeObject(obj) {
            Object.keys(obj).forEach((key) => {
                const val = obj[key];
                if (val && typeof val === 'object') {
                    sanitizeObject(val);
                } else if (typeof val === 'string') {
                    obj[key] = sanitize(val);
                }
            });
        }

        const enumDescriptors = this.enumMapperService.getEnumDescriptors();
        let clearUrl = url.replace(/\?.*$/, '').toLowerCase();
        if (clearUrl[0] !== '/') {
            clearUrl = `/${clearUrl}`;
        }

        const dataClone = deepClone(data);

        const newRequestCallback = (resp: BaseRequestResponse<any>) => {
            if ('data' in resp && webControllersEnumsMap[clearUrl] && webControllersEnumsMap[clearUrl].response) {
                const enumRespMaps = webControllersEnumsMap[clearUrl].response;
                const response = resp as BaseSuccessRequestResponse;
                const handleDataItem = (dataItem: Object, enumMap: EnumMap, stepI = 0) => {
                    const isLastStep = stepI === enumMap.path.length - 1;
                    if (isLastStep) {
                        const pathValue = enumMap.path[stepI];

                        const isArrayStep = pathValue === '$i';
                        if (isArrayStep) {
                            const array = dataItem ? dataItem as Array<Object> : null;
                            if (array) {
                                array.forEach((item, index, arr) => {
                                    const strItem = item ? item as string : null;
                                    if (strItem) {
                                        const intItem = enumDescriptors[enumMap.enumName]
                                            .find(enumItem => enumItem.literalValue === strItem).intValue;
                                        if (typeof intItem === 'number') {
                                            arr[index] = intItem;
                                        }
                                    }
                                });
                            }
                        } else {
                            const enumValue = dataItem ? dataItem[pathValue] as string : null;
                            if (enumValue) {
                                if (typeof enumValue !== 'string') {
                                    throw Error(`Value of "${pathValue}" must be string from "${enumMap.enumName}" enum, ` +
                                        `but it is ${typeof enumValue}. Request "${url}"`);
                                }

                                const extractEnumFromDescriptor = (enumName) => {
                                    const enumDescriptor = enumDescriptors[enumName]
                                        .find(enumDescriptorItem => enumDescriptorItem.literalValue.toLowerCase() === enumValue.toLowerCase());

                                    if (enumDescriptor) {
                                        dataItem[pathValue] = enumDescriptor.intValue;
                                        dataItem[`${pathValue}_literalValue`] = enumDescriptor.literalValue;
                                        dataItem[`${pathValue}_description`] = enumDescriptor.description;
                                    }
                                };

                                if (enumDescriptors[enumMap.enumName]) {
                                    extractEnumFromDescriptor(enumMap.enumName);
                                } else {
                                    if (enumMap.enumName.indexOf('nullable') === 0) {
                                        const enumName = enumMap.enumName.replace(/^nullable([\d\D])([\d\D]+$)/, (a, b, c) => b.toLowerCase() + c);
                                        extractEnumFromDescriptor(enumName);
                                        console.warn(`(Handled) No enum "${enumMap.enumName}" in enum descriptors. Request "${url}"`);
                                    } else {
                                        console.error(`No enum "${enumMap.enumName}" in enum descriptors. Request "${url}"`);
                                    }
                                }
                            }
                        }
                    } else {
                        // TEST
                        if (enumMap.path[stepI] === 'userFolders') {
                            // debugger;
                        }

                        const isArrayStep = enumMap.path[stepI] === '$i';
                        if (isArrayStep) {
                            const dataItemArray = dataItem as Array<Object>;
                            if (dataItemArray) {
                                dataItemArray.forEach(dataSubItem => handleDataItem(dataSubItem, enumMap, stepI + 1));
                            }
                        } else {
                            if (dataItem) {
                                handleDataItem(dataItem[enumMap.path[stepI]], enumMap, stepI + 1);
                            }
                        }
                    }
                };

                enumRespMaps.forEach((property) => {
                    if (Array.isArray(response.data)) {
                        const dataAsArray = response.data as Array<Object>;
                        dataAsArray.forEach(dataItem => handleDataItem(dataItem, property));
                    } else {
                        handleDataItem(response.data, property);
                    }
                });
            }

            return resp;
        };

        try {
            if (webControllersEnumsMap[clearUrl] && webControllersEnumsMap[clearUrl].request) {
                const enumRequestMaps = webControllersEnumsMap[clearUrl].request;
                enumRequestMaps.forEach(property => this.replaceEnumsInRequestParams(dataClone, property, 0, url));
            }
        } catch (e) {
            console.error(e);
            // debugger;
        }

        const requestParams = dataClone || {};

        return this.transportService
            .request(url, requestParams, customOptions, params)
            .then((resp) => {
                try {
                    sanitizeObject(resp);
                    return newRequestCallback(resp);
                } catch (e) {
                    console.error(e);
                    //debugger;
                }
            })
            .catch((err) => {
                err && console.error(err);

                return Promise.reject(err);
            });
    }

    requestSyncWithReplaceEnums<T>(
        url: string,
        callback: (resp: BaseRequestResponse<T>) => void,
        data: Object,
        customOptions?: RequestInit,
        params: RequestAdditionalParams = {},
    ): void {
        this.requestWithReplaceEnums(url, data, customOptions, params)
            .then(resp => callback(resp))
            .catch(resp => callback(resp));
    }
}
