/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import hasIn from 'lodash/has';
import {
    CONTROL_SIZE,
    ComboboxKit,
    FieldLayout,
    useFormApi,
    ValidationStatus,
    formatDate,
    normalizeOldDateFormat,
} from '@veeam-vspc/components';
import { TimeUnitsInMinutes } from '@veeam-vspc/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import type { ComboboxProps, FormControlProps } from '@veeam-vspc/components';

import { useAppStore } from 'views/providers/AppProvider/hooks';

dayjs.extend(utc);

interface TimeSet {
    value: number;
    text: string;
}

export interface Props extends Partial<ComboboxProps<TimeSet, string>>, FormControlProps {
    autoSelectIfOneItem?: boolean;
    autoSelectFirstItem?: boolean;
    emptyValue?: any;
    shortFormat?: boolean;
    minutesInterval?: number;
    utc?: boolean;
}

export const TimeField = (props: Props) => {
    const {
        name,
        label,
        inlineLabel,
        asTag,
        suffix,
        shortFormat,
        minutesInterval = 15,
        utc,
    } = props;
    const formsState = useFormApi();
    const { formats } = useAppStore();
    const [timeList] = useState<TimeSet[]>(() => {
        const result: TimeSet[] = [];

        const now = new Date();
        const year = now.getUTCFullYear();
        const month = now.getUTCMonth();
        const day = now.getUTCDate();
        const timeFormat = normalizeOldDateFormat(formats.netShortTime);

        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < TimeUnitsInMinutes.Hour; minute += minutesInterval) {
                result.push({
                    value: hour * TimeUnitsInMinutes.Hour + minute,
                    text: formatDate(
                        new Date(year, month, day, hour, minute, 0, 0),
                        timeFormat,
                    ),
                });
            }
        }

        return result;
    });

    const { disabled } = props;

    const labelSuffix = props.labelSuffix === undefined ? ':' : props.labelSuffix;
    const labelText = label ? label + labelSuffix : label;

    const valueToInnerVal = (value: string) => {
        if (value === null || value === undefined) {
            return value as null;
        }

        if (shortFormat) {
            const [hours, minutes] = value.split(':').map(Number);
            let date = dayjs()
                .hour(hours)
                .minute(minutes)
                .second(0);

            if (utc) {
                date = dayjs.utc()
                    .hour(hours)
                    .minute(minutes)
                    .second(0)
                    .local();
            }

            return date.hour() * TimeUnitsInMinutes.Hour + date.minute();
        }

        let date = dayjs(value);

        if (utc) {
            date = dayjs.utc(value).local();
        }

        return date.hour() * TimeUnitsInMinutes.Hour + date.minute();
    };

    const innerValToValue = (innerVal: number) => {
        if (innerVal === null || innerVal === undefined) {
            return innerVal as null;
        }

        const hours = (innerVal - innerVal % TimeUnitsInMinutes.Hour) / TimeUnitsInMinutes.Hour;
        const minutes = innerVal % TimeUnitsInMinutes.Hour;

        let date = dayjs()
            .hour(hours)
            .minute(minutes);

        if (utc) {
            date = date.utc();
        }

        if (shortFormat) {
            return date.format('HH:mm');
        }

        return date.toISOString();
    };

    const [innerVal, setInnerVal] = useState(() => valueToInnerVal(formsState.getValue(name)));
    const [touched, setTouched] = useState(false);

    const setValue = (value: string) => {
        setInnerVal(valueToInnerVal(value));
    };

    const calcErrorText = () => {
        const validationStatus = formsState.validationState.getStatus();

        const error = formsState.getError(name);
        const showError = (touched || validationStatus !== ValidationStatus.Common) && error;

        return showError ? error : '';
    };

    const [errorText, setErrorText] = useState(() => calcErrorText());

    useEffect(() => {
        const updateValueFunction = () => {
            if (hasIn(formsState.value, name)) {
                setValue(formsState.getValue(name));
            }

            setErrorText(calcErrorText());
        };

        formsState.onChange(updateValueFunction);
        return () => formsState.unsubscribeOnChange(updateValueFunction);
    }, []);

    useEffect(() => {
        setErrorText(calcErrorText());
    }, [touched]);

    useEffect(() => {
        if (innerVal === null) {
            const val = innerValToValue(8 * 60);
            formsState.setValue(name, val);
        }
    }, []);

    useEffect(() => {
        if (props.data && props.data.length > 0 && (innerVal === undefined || innerVal === null || innerVal === props.emptyValue)) {
            if (props.autoSelectFirstItem || (props.autoSelectIfOneItem && props.data.length === 1)) {
                const val = props.valueGetter(props.data[0]);
                formsState.setValue(name, val);
                props.onChange && props.onChange(val, undefined);
            }
        }
    });

    return (
        <FieldLayout
            label={labelText}
            suffix={suffix}
            error={errorText}
            size={props.size as CONTROL_SIZE}
            disabled={disabled}
            asTag={asTag}
            inlineLabel={inlineLabel}
        >
            <ComboboxKit
                value={innerVal}
                onChange={(innerVal, index) => {
                    const val = innerValToValue(innerVal);
                    formsState.setValue(name, val);
                    props.onChange && props.onChange(val, index);
                }}
                onBlur={() => setTouched(true)}
                data={timeList}
                valueGetter={item => item.value}
                textGetter={item => item.text}
                size={CONTROL_SIZE.xs}
                error={!!errorText}
            />
        </FieldLayout>
    );
};
