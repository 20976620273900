/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { DateTitle, DayWrapper } from '../DayBase';
import { useJobsOverviewStore } from '../../../../store';

import type { DayProps } from '../../../../interfaces/day-props';

export const NoData: React.FC<DayProps> = ({ id }) => {
    const store = useJobsOverviewStore();
    const record = store.findById(id);

    return (
        <DayWrapper>
            <DateTitle>
                {record.displayDateForCell}
            </DateTitle>
        </DayWrapper>
    );
};
