/**
 * Copyright © Veeam Software Group GmbH.
 */
import { formatDate, normalizeOldDateFormat } from '@veeam-vspc/components';

import { isDate } from './is-date';

import type { PortalFormats } from '../../entries/portal-data/interfaces';

export function normalizeDateWithTime(value: Date, formats: PortalFormats): string | null {
    if (!isDate(value) || value <= new Date(0)) {
        return null;
    }

    return formatDate(value, `${normalizeOldDateFormat(formats.netShortDate)} ${normalizeOldDateFormat(formats.netShortTime)}`);
}
