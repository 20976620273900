/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { WizardSummary } from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import { useLang } from 'views/providers/LangProvider/hooks';
import { TaxTypes, CloudStorageSpaceMode } from 'core/enums';
import { currenciesCache } from 'core/utils/common-caches';

import type { SubscriptionPlanModel } from 'core/interfaces';

export const SubscriptionPlanSummary = ({ data }: { data: SubscriptionPlanModel; }) => {
    const lang = useLang();
    const [currencyLabel, setCurrencyLabel] = useState('');
    const [taxList] = useState(() => ([
        {
            id: TaxTypes.Vat,
            name: lang.VAT,
        }, {
            id: TaxTypes.Gst,
            name: lang.GST,
        }, {
            id: TaxTypes.SalesTax,
            name: lang.SALES_TAX,
        },
    ]));
    const taxType = taxList.find(item => item.id === data.vatAlias).name;

    useEffect(() => {
        currenciesCache.load()
            .then((items) => {
                const item = items.find(it => it.code === data.currency);
                if (item) {
                    setCurrencyLabel(`${item.name} (${item.code})`);
                }
            });
    }, []);

    const perMountTransform = tx => `${tx || 0} ${data.currency} ${lang.PER_MONTH}`;

    const perAgentPerMountTransform = tx => `${tx || 0} ${data.currency} ${lang.PER_AGENT_PER_MONTH}`;

    const costPerMountTransform = (cost, sizeUnits, suffix = lang.PER) => `${cost || 0} ${data.currency} ${suffix} ${sizeUnitsLabels[sizeUnits]}`;

    const costPerPeriodTransform = (cost, timeUnits) =>
        `${cost || 0} ${data.currency} ${lang.PER} ${periodUnitsLabels[timeUnits || 1]} ${lang.PER_VM}`;

    const periodInMinutesTransform = (value) => {
        value = value || 0;
        let unit = ''; // for 0 use '' (not use 'm')
        let resultValue = value;

        const arr = [
            {
                unitPrefix: 'm',
                unitNameSingular: lang.MINUTE,
                unitNamePlural: lang.MINUTES,
                countMin: 1,
            },
            {
                unitPrefix: 'h',
                unitNameSingular: lang.HOUR,
                unitNamePlural: lang.HOURS,
                countMin: 60,
                withoutRemainder: true,
            },
            {
                unitPrefix: 'd',
                unitNameSingular: lang.DAY,
                unitNamePlural: lang.DAYS,
                countMin: 1440,
                withoutRemainder: true,
            },
            {
                unitPrefix: 'w',
                unitNameSingular: lang.WEEK,
                unitNamePlural: lang.WEEKS,
                countMin: 10080,
                withoutRemainder: true,
            },
            {
                unitPrefix: 'mo',
                unitNameSingular: lang.MONTH,
                unitNamePlural: lang.MONTHS,
                countMin: 43200,
                withoutRemainder: true,
            },
        ];

        arr.forEach((item, index) => {
            if ((value >= item.countMin && !item.withoutRemainder) ||
                (value >= item.countMin && (item.withoutRemainder && value % item.countMin === 0))) {
                resultValue = (value / arr[index].countMin);
                unit = RCOP.utils.String.plural(resultValue, arr[index].unitNameSingular, arr[index].unitNamePlural);
            }
        });

        return `${resultValue} ${unit.toLowerCase()}`;
    };

    const perInstances = (value: number) => capitalize(`${value?.toString()} ${lang.INSTANCES}`, true);

    const sizeUnitsLabels = {
        1: lang.GB,
        2: lang.TB,
    };

    const periodUnitsLabels = {
        5: lang.MINUTE,
        1: lang.HOUR,
        2: lang.DAY,
        3: lang.WEEK,
        4: lang.MONTH,
    };

    const dataSizeTransform = (valInByte: number) => {
        let units = '';
        let count = 0;

        if (valInByte < Math.pow(1024, 3)) {
            units = lang.GB;
        } else {
            if (valInByte % Math.pow(1024, 4) === 0) {
                units = lang.TB;
                count = valInByte / Math.pow(1024, 4);
            } else {
                units = lang.GB;
                count = Math.round(valInByte / Math.pow(1024, 3));
            }

            if (!units) {
                units = lang.GB;
            }
        }

        return `${count} ${units}`;
    };

    return (
        <WizardSummary
            fieldsets={[
                {
                    title: lang.PLAN_INFO,
                    fields: [
                        {
                            label: `${lang.NAME}:`,
                            value: data.name,
                        },
                        {
                            label: `${lang.DESCRIPTION}:`,
                            value: data.description || '-',
                        },
                    ],
                }, {
                    title: lang.CURRENCY,
                    fields: [
                        {
                            label: `${lang.CURRENCY}:`,
                            value: currencyLabel,
                        },
                        {
                            label: `${lang.TAX}:`,
                            value: `${taxType} ${data.vat || 0}%`,
                        },
                        {
                            label: `${lang.DISCOUNT}:`,
                            value: `${data.discount || 0}%`,
                        },
                    ],
                }, {
                    title: capitalize(lang.GENERAL_SERVICES, true),
                    fields: [
                        {
                            label: `${capitalize(lang.MANAGED_SERVICES, true)}:`,
                            value: perMountTransform(data.managedServicesPrice),
                        },
                        {
                            label: `${lang.MONITORING_SERVICES}:`,
                            value: perMountTransform(data.monitoredServicesPrice),
                        },
                    ],
                }, {
                    title: capitalize(lang.MANAGED_BACKUP, true),
                    fields: [
                        {
                            label: `${capitalize(lang.WORKSTATION_AGENT, true)}:`,
                            value: perMountTransform(data.managedWorkstationPrice),
                        },
                        {
                            label: `${lang.WORKSTATION_AGENT_FREE_OF_CHARGE}:`,
                            value: data.workstationAgentFree?.toString(),
                            hidden: !data.workstationAgentFree,
                        },
                        {
                            label: `${capitalize(lang.SERVER_AGENT, true)}:`,
                            value: perMountTransform(data.managedServerPrice),
                        },
                        {
                            label: `${lang.SERVER_AGENT_FREE_OF_CHARGE}:`,
                            value: data.serverAgentFree?.toString(),
                            hidden: !data.serverAgentFree,
                        },
                        {
                            label: `${lang.MANAGED_VM}:`,
                            value: perMountTransform(data.managedVmPrice),
                        },
                        {
                            label: `${lang.MANAGED_VM_FREE_OF_CHARGE}:`,
                            value: data.managedVmFree?.toString(),
                            hidden: !data.managedVmFree,
                        },
                        {
                            label: `${lang.VM_WITH_CDP_ENABLED}:`,
                            value: perMountTransform(data.managedCdpVmPrice),
                        },
                        {
                            label: `${lang.VMS_WITH_CDP_ENABLED_FREE_OF_CHARGE}:`,
                            value: data.managedCdpVmFree?.toString(),
                            hidden: !data.managedCdpVmFree,
                        },
                        {
                            label: `${lang.FILE_SHARE_SIZE}:`,
                            value: costPerMountTransform(data.sourceAmountOfDataPrice, data.sourceAmountOfDataMultiplier),
                        },
                        {
                            label: `${lang.FILE_SHARE_SIZE_FREE_OF_CHARGE}:`,
                            value: dataSizeTransform(data.sourceAmountOfDataFree),
                            hidden: !data.sourceAmountOfDataFree,
                        },
                        {
                            label: `${lang.FILE_SHARE_BACKUP_SIZE}:`,
                            value: costPerMountTransform(data.nasShortTermUsedSpacePrice, data.nasShortTermUsedSpaceMultiplier),
                        },
                        {
                            label: `${lang.FILE_SHARE_BACKUP_SIZE_FREE_OF_CHARGE}:`,
                            value: dataSizeTransform(data.nasShortTermUsedSpaceFree),
                            hidden: !data.nasShortTermUsedSpaceFree,
                        },
                        {
                            label: `${RCOP.Lang.FILE_SHARE_ARCHIVE_SIZE}:`,
                            value: costPerMountTransform(data.nasLongTermUsedSpacePrice, data.nasLongTermUsedSpaceMultiplier),
                        },
                        {
                            label: `${lang.FILE_SHARE_ARCHIVE_SIZE_FREE_OF_CHARGE}:`,
                            value: dataSizeTransform(data.nasLongTermUsedSpaceFree),
                            hidden: !data.nasShortTermUsedSpaceFree,
                        },
                        {
                            label: `${lang.WINDOWS_WORKSTATION_OS}:`,
                            value: perAgentPerMountTransform(data.managedAgentDesktopWindowsExtraPrice),
                        },
                        {
                            label: `${lang.WINDOWS_SERVER_OS}:`,
                            value: perAgentPerMountTransform(data.managedAgentServerWindowsExtraPrice),
                        },
                        {
                            label: `${lang.LINUX_OS}:`,
                            value: perAgentPerMountTransform(data.managedAgentLinuxExtraPrice),
                        },
                        {
                            label: `${lang.MAC_OS}:`,
                            value: perAgentPerMountTransform(data.managedAgentMacExtraPrice),
                        },
                    ],
                }, {
                    title: lang.VEEAM_CLOUD_CONNECT_BACKUP_RESOURCES,
                    fields: [
                        {
                            label: `${lang.SERVER_BACKUP}:`,
                            value: perMountTransform(data.cbServerPrice),
                        },
                        {
                            label: `${lang.WORKSTATION_BACKUP}:`,
                            value: perMountTransform(data.cbWorkstationPrice),
                        },
                        {
                            label: `${capitalize(lang.VM_BACKUP, true)}:`,
                            value: perMountTransform(data.cbVmPrice),
                        },
                        {
                            label: `${capitalize(lang.CLOUD_REPOSITORY, true)}:`,
                            value: costPerMountTransform(
                                data.cbRepoAllocatedSpacePrice,
                                data.cbRepoAllocatedSpaceMultiplier,
                                lang.PER_ALLOCATED,
                            ),
                            hidden: !data.cbRepoAllocatedSpacePrice || data.cbRepoCountAlgorithm !== CloudStorageSpaceMode.AllocatedSpaceMode,
                        },
                        {
                            label: `${capitalize(lang.CLOUD_REPOSITORY, true)}:`,
                            value: costPerMountTransform(data.cbVmRepoUsedSpacePrice, data.cbVmRepoUsedSpaceMultiplier, lang.PER_CONSUMED),
                            hidden: !data.cbVmRepoUsedSpacePrice || data.cbRepoCountAlgorithm !== CloudStorageSpaceMode.ConsumedSpaceMode,
                        },
                        {
                            label: `${lang.CLOUD_STORAGE_FREE_OF_CHARGE_BACKUP}:`,
                            value: dataSizeTransform(data.cbVmRepoUsedSpaceFree),
                            hidden: !data.cbVmRepoUsedSpaceFree || data.cbRepoCountAlgorithm !== CloudStorageSpaceMode.ConsumedSpaceMode,
                        },
                        {
                            label: `${lang.PERFORMANCE_TIER}:`,
                            value: costPerMountTransform(
                                data.cbPerformanceTierUsedSpacePrice,
                                data.cbPerformanceTierUsedSpaceMultiplier,
                            ),
                            hidden: !data.cbPerformanceTierUsedSpacePrice
                                || data.cbRepoCountAlgorithm !== CloudStorageSpaceMode.GranularSpaceMode,
                        },
                        {
                            label: `${lang.CAPACITY_TIER}:`,
                            value: costPerMountTransform(
                                data.cbCapacityTierUsedSpacePrice,
                                data.cbCapacityTierUsedSpaceMultiplier,
                            ),
                            hidden: !data.cbCapacityTierUsedSpacePrice
                                || data.cbRepoCountAlgorithm !== CloudStorageSpaceMode.GranularSpaceMode,
                        },
                        {
                            label: `${lang.ARCHIVE_TIER}:`,
                            value: costPerMountTransform(
                                data.cbArchiveTierUsedSpacePrice,
                                data.cbArchiveTierUsedSpaceMultiplier,
                            ),
                            hidden: !data.cbArchiveTierUsedSpacePrice
                                || data.cbRepoCountAlgorithm !== CloudStorageSpaceMode.GranularSpaceMode,
                        },
                        {
                            label: `${capitalize(lang.DELETED_BACKUPS_RECYCLE_BIN, true)}:`,
                            value: costPerMountTransform(
                                data.cbInsiderProtectionUsedSpacePrice,
                                data.cbInsiderProtectionUsedSpaceMultiplier,
                            ),
                            hidden: !data.cbInsiderProtectionUsedSpacePrice,
                        },
                        {
                            label: `${lang.DATA_TRANSFER_OUT_BACKUP}:`,
                            value: costPerMountTransform(data.cbVmTrafficPrice, data.cbVmTrafficMultiplier),
                        },
                    ],
                }, {
                    title: lang.VEEAM_CLOUD_CONNECT_REPLICATION_RESOURCES,
                    fields: [
                        {
                            label: `${lang.REPLICATED_VM}:`,
                            value: perMountTransform(data.crVmPrice),
                        },
                        {
                            label: `${lang.CLOUD_STORAGE}:`,
                            value: costPerMountTransform(data.crVmStoragePrice, data.crVmStorageMultiplier),
                        },
                        {
                            label: `${lang.CLOUD_STORAGE_FREE_OF_CHARGE_REPLICATION}:`,
                            value: dataSizeTransform(data.crVmStorageFree),
                            hidden: !data.crVmStorageFree,
                        },
                        {
                            label: `${lang.COMPUTE_RESOURCES}:`,
                            value: costPerPeriodTransform(data.crVmComputeTimePrice, data.crVmComputeTimeMultiplier),
                        },
                        {
                            label: `${lang.COMPUTE_RESOURCES_FREE_OF_CHARGE}:`,
                            value: periodInMinutesTransform(data.crVmComputeTimeFree),
                            hidden: !data.crVmComputeTimeFree,
                        },
                        {
                            label: `${lang.DATA_TRANSFER_OUT_REPLICATION}:`,
                            value: costPerMountTransform(data.crVmTrafficPrice, data.crVmTrafficMultiplier),
                        },
                    ],
                }, {
                    title: capitalize(lang.MICROSOFT_365_BACKUP, true),
                    fields: [
                        {
                            label: `${lang.PROTECTED_USER}:`,
                            value: perMountTransform(data.vbM365ProtectedUserPrice),
                        },
                        {
                            label: `${lang.PROTECTED_USERS_FREE_OF_CHARGE}:`,
                            value: data.vbM365ProtectedUserFree?.toString(),
                            hidden: !data.vbM365ProtectedUserFree,
                        },
                        {
                            label: `${lang.STANDARD_STORAGE}:`,
                            value: costPerMountTransform(data.vbM365BackupStorageSpacePrice, data.vbM365BackupStorageSpaceMultiplier),
                        },
                        {
                            label: `${lang.STANDARD_STORAGE_FREE_OF_CHARGE}:`,
                            value: dataSizeTransform(data.vbM365BackupStorageSpaceFree),
                            hidden: !data.vbM365BackupStorageSpaceFree,
                        },
                        {
                            label: `${lang.ARCHIVE_STORAGE}:`,
                            value: costPerMountTransform(data.vbM365ArchiveStorageSpacePrice, data.vbM365ArchiveStorageSpaceMultiplier),
                        },
                        {
                            label: `${lang.ARCHIVE_STORAGE_FREE_OF_CHARGE}:`,
                            value: dataSizeTransform(data.vbM365ArchiveStorageSpaceFree),
                            hidden: !data.vbM365ArchiveStorageSpaceFree,
                        },
                    ],
                }, {
                    title: capitalize(lang.PUBLIC_CLOUD_BACKUP, true),
                    fields: [
                        {
                            label: `${lang.CLOUD_VM}:`,
                            value: perMountTransform(data.managedCloudVmPrice),
                        },
                        {
                            label: `${lang.CLOUD_VM_FREE_OF_CHARGE}:`,
                            value: data.managedCloudVmFree?.toString(),
                            hidden: !data.managedCloudVmFree,
                        },
                        {
                            label: `${lang.CLOUD_FILE_SHARE}:`,
                            value: perMountTransform(data.publicCloudFileSharePrice),
                        },
                        {
                            label: `${lang.CLOUD_FILE_SHARES_FREE_OF_CHARGE}:`,
                            value: perInstances(data.publicCloudFileShareFree),
                            hidden: !data.publicCloudFileShareFree,
                        },
                        {
                            label: `${lang.CLOUD_DATABASE}:`,
                            value: perMountTransform(data.publicCloudDatabasePrice),
                        },
                        {
                            label: `${lang.CLOUD_DATABASES_FREE_OF_CHARGE}:`,
                            value: perInstances(data.publicCloudDatabaseFree),
                            hidden: !data.publicCloudDatabaseFree,
                        },
                        {
                            label: `${lang.BACKUP_STORAGE}:`,
                            value: costPerMountTransform(data.publicCloudBackupSpacePrice, data.publicCloudBackupSpaceMultiplier),
                        },
                        {
                            label: `${lang.STANDARD_STORAGE_FREE_OF_CHARGE}:`,
                            value: dataSizeTransform(data.publicCloudBackupSpaceFree),
                            hidden: !data.publicCloudBackupSpaceFree,
                        },
                        {
                            label: `${lang.ARCHIVE_STORAGE}:`,
                            value: costPerMountTransform(data.publicCloudArchiveSpacePrice, data.publicCloudArchiveSpaceMultiplier),
                        },
                        {
                            label: `${lang.ARCHIVE_STORAGE_FREE_OF_CHARGE}:`,
                            value: dataSizeTransform(data.publicCloudArchiveSpaceFree),
                            hidden: !data.publicCloudArchiveSpaceFree,
                        },
                    ],
                },
            ]}
        />
    );
};
