/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { Combobox, CONTROL_SIZE, FormLayout, LabelGroup, NumberInput, STACK_DIRECTION } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { RpoPeriod } from '../../../enums';

interface Props {
    label?: string;
}

export const RpoInterval: React.FC<Props> = ({ label }) => {
    const lang = useLang();

    return (
        <LabelGroup label={`${label || lang.RPO}:`}>
            <FormLayout direction={STACK_DIRECTION.row}>
                <NumberInput
                    name='parameters.rpoInterval.number'
                    size={CONTROL_SIZE.xs}
                />

                <Combobox
                    name='parameters.rpoInterval.period'
                    data={[
                        {
                            id: RpoPeriod.Hour,
                            text: capitalize(lang.HOURS),
                        },
                        {
                            id: RpoPeriod.Day,
                            text: lang.DAYS,
                        },
                        {
                            id: RpoPeriod.Week,
                            text: lang.WEEKS,
                        },
                        {
                            id: RpoPeriod.Month,
                            text: lang.MONTHS,
                        },
                    ]}
                    valueGetter={item => item.id}
                    textGetter={item => item.text}
                    size={CONTROL_SIZE.xs}
                />
            </FormLayout>
        </LabelGroup>
    );
};
