/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { Day } from '../Day';
import { DaysWrapper } from './Days.styled';
import { useJobsOverviewStore } from '../../store';
import { useGlobalFiltersChangeHandler } from 'views/providers/GlobalFiltersProvider/hooks';

export const Days: React.FC = () => {
    const store = useJobsOverviewStore();

    useGlobalFiltersChangeHandler(store.getJobsStatistics);

    return (
        <DaysWrapper>
            {store.days.map(day => <Day key={day.id} id={day.id} />)}
        </DaysWrapper>
    );
};
