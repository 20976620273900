/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { WizardSummary } from '@veeam-vspc/components';

import type { WizardStep } from '@veeam-vspc/components';
import type { WizardStepData } from '@veeam-vspc/components/src/index';

import { ManagedCompaniesMode } from 'views/pages/RolesAndUsersPage/components/AdminUser/enums/managed-companies-mode';
import { UserRoles } from 'core/enums';
import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { SSORuleModel } from '../../../Base/interfaces/sso-rule-model';

export const Summary = ({ data }: WizardStepData<SSORuleModel>) => {
    const lang = useLang();
    const roleMap = {
        [UserRoles.PortalReadonlyOperator]: lang.PORTAL_READONLY_OPERATOR,
        [UserRoles.PortalOperator]: lang.PORTAL_OPERATOR,
        [UserRoles.PortalAdministrator]: lang.PORTAL_ADMINISTRATOR,
    };
    const isSelectAllCompanies = data.selectAllCompanies === ManagedCompaniesMode.AllCompanies;
    const isPortalAdmin = data.model.role === UserRoles.PortalAdministrator;

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_RULE_DETAILS}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: `${lang.RULE_INFO}:`,
                        fields: [
                            {
                                label: `${lang.NAME}:`,
                                value: data.model.name,
                            },
                        ],
                    },
                    {
                        title: `${lang.ROLE}:`,
                        fields: [
                            {
                                label: `${lang.ROLE}:`,
                                value: roleMap[data.model.role],
                            },
                        ],
                    },
                    {
                        title: `${lang.COMPANIES}:`,
                        fields: [
                            {
                                label: `${lang.COMPANIES}:`,
                                value: isSelectAllCompanies || isPortalAdmin ? lang.ALL : data.selectedCompanies.length,
                            },
                        ],
                    },
                    {
                        title: `${lang.CONDITIONS}:`,
                        fields: [
                            {
                                label: `${lang.NAME}:`,
                                value: data.model.organizationMappingSourceClaimType,
                            },
                            {
                                label: `${lang.PARAMETERS}:`,
                                value: data.model.additionalMappings?.length ?? 0,
                            },
                        ],
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getSummaryStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: data => <Summary {...data} />,
    id: 'summary',
});
