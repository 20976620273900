/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { useJobsOverviewStore } from '../../../../store';
import { DateTitle } from '../DayBase';
import { Tooltip } from '../../../Tooltip';
import { DayWithoutJobsWrapper } from './DayWithoutJobs.styled';

import type { DayProps } from '../../../../interfaces/day-props';

export const DayWithoutJobs: React.FC<DayProps> = ({ id }) => {
    const store = useJobsOverviewStore();
    const record = store.findById(id);

    return (
        <>
            <DayWithoutJobsWrapper
                data-for={id}
                data-tip={id}
            >
                <DateTitle>
                    {record.displayDateForCell}
                </DateTitle>
            </DayWithoutJobsWrapper>

            <Tooltip id={id} />
        </>
    );
};
