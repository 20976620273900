/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridColumnJustify,
    GridSortDirections,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
} from '@veeam-vspc/components';
import { PublicCloudVmRestoreType } from '@veeam-vspc/models/web-controllers';

import type { CellRendererProps } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { MAX_INTEGER_32 } from 'core/const';

import type {
    PublicCloudsRestorePointDialogModel,
    PublicCloudsRestorePointDialogRequestParams,
    PublicCloudsRestorePointDialogExtraParams,
} from '../../interfaces';

const { Snapshot, RemoteSnapshot } = PublicCloudVmRestoreType;

export interface PublicCloudsRestorePointDialogProps {
    stateId: string;
    name: string;
    extraParams: PublicCloudsRestorePointDialogExtraParams;
    deactivate: () => void;
}

export const PublicCloudsRestorePointDialog: React.FC<PublicCloudsRestorePointDialogProps> = ({ stateId, name, extraParams, deactivate }) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const { backupType } = extraParams;
    const isSnapshot = [Snapshot, RemoteSnapshot].includes(backupType);
    const columns = [
        {
            field: 'creationDate',
            title: lang.DATE,
            cell: ({ cellData }: CellRendererProps<PublicCloudsRestorePointDialogModel>) => <GridDateColumn value={cellData} />,
        },
        !isSnapshot && {
            field: 'usedSourceSize',
            title: lang.SOURCE_SIZE,
            cell: ({ cellData }: CellRendererProps<PublicCloudsRestorePointDialogModel>) => <RestorePointSizeColumn value={cellData as any} />,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        isSnapshot && {
            field: 'usedSourceSize',
            title: lang.VM_SIZE,
            cell: ({ cellData }: CellRendererProps<PublicCloudsRestorePointDialogModel>) => <RestorePointSizeColumn value={cellData as any} />,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
    ];

    return (
        <Dialog
            header={`${lang.RESTORE_POINTS} - ${name}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <AdvancedGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Desc,
                            key: columns?.[0]?.field,
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={columns.filter(item => item)}
                    data={(requestParams: PublicCloudsRestorePointDialogRequestParams<{}>) => transportService
                        .request<typeof requestParams, PublicCloudsRestorePointDialogModel[]>(
                            '/PublicCloudVm/GetProtectedVmBackupRestorePoints',
                            {
                                ...requestParams,
                                ...extraParams,
                            }
                        )}
                    toggleable={false}
                    selection={{
                        field: 'instanceUid',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => (
                            <ToolbarWithExport
                                idProperty={'instanceUid'}
                                method={'GetPublicCloudVmRestorePoint'} // backend mistake that "point" from small letter
                                extraParams={extraParams}
                            />
                        ),
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
