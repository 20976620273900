/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';

import { BackupTasksRatioService } from './services/backup-tasks-ratio.service';
import { BaseTrendChart } from '../../../BaseTrendChart';
import { useLang } from 'views/providers/LangProvider/hooks';

export const BackupTasksRatio: React.FC = () => {
    const lang = useLang();

    const params = {
        colors: [palette.B400],
        type: 'column',
        yMax: 100,
        yMin: 0,
        yTitle: `${lang.RATIO}, %`,
    };

    return (
        <BaseTrendChart
            params={params}
            service={BackupTasksRatioService}
            title={{ text: `${capitalize(lang.SUCCESSFUL_BACKUP_TASKS_RATIO)} (%)` }}
        />
    );
};
