/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import {
    Combobox,
    CONTROL_SIZE,
    FieldLayout,
    FormValidator,
    MultipleChoice,
    TextInput,
    useFormApi,
} from '@veeam-vspc/components';
import { formatStr, capitalize } from '@veeam-vspc/core';

import { useLang } from 'views/providers/LangProvider/hooks';
import { VmsPlatform } from '../../../enums';
import { useReportStore } from '../../../hooks';
import { RpoInterval } from './RpoInterval';

import type { ReportModel } from '../../../interfaces';

export const vmParametersValidate = (data: ReportModel) => {
    const validator = new FormValidator(data);

    validator
        .validate('parameters.rpoInterval.number')
        .number()
        .required()
        .min(1)
        .max(365);

    validator
        .validate('parameters.jobTypeFilter')
        .string()
        .check((val) => {
            // val is array here
            if (val.length === 0) {
                return 'error';
            }
        });

    if (data.parameters.platformFilter[0] === VmsPlatform.VirtualInfrastructure) {
        validator
            .validate('parameters.virtualPlatformFilter')
            .string()
            .check((val) => {
                // val is array here
                if (val.length === 0) {
                    return 'error';
                }
            });
    }

    validator
        .validate('parameters.excludeMask')
        .string()
        .maxLength(500);

    return validator.result();
};

interface Props {
    data: ReportModel;
    onDataChange: (data: any) => void;
}

export const VmParameters = ({ data, onDataChange }: Props) => {
    const lang = useLang();
    const formApi = useFormApi();
    const reportStore = useReportStore();
    const virtualPlatformTypes = reportStore.virtualReportPlatformTypeSet.map(item => ({
        id: item.id.toString(),
        name: item.name,
    }));

    const filterJobTypes = () => {
        const allJobTypes = reportStore.protectedVmReportJobTypeSet.map(
            item => ({
                id: item.id.toString(),
                name: item.name,
                platform: item.platform,
            }),
        );

        if (data.parameters.platformFilter[0] === VmsPlatform.All) {
            return allJobTypes;
        }

        return allJobTypes.filter(jobType => !jobType.platform || jobType.platform.includes(data.parameters.platformFilter[0] as VmsPlatform));
    };
    const [jobTypes, setJobTypes] = useState(filterJobTypes);
    const [platformFilterHandled] = useState({
        count: 0,
    });

    useEffect(() => {
        const types = filterJobTypes();
        setJobTypes(types);

        if (platformFilterHandled.count > 0) {
            formApi.setValue('parameters.jobTypeFilter', types.map(jobType => parseInt(jobType.id)));
            onDataChange(data);
        }

        platformFilterHandled.count++;
    }, [data.parameters.platformFilter[0]]);

    return (
        <>
            <RpoInterval />

            <Combobox
                name='parameters.platformFilter[0]'
                label={lang.PLATFORM}
                data={[
                    {
                        id: VmsPlatform.All,
                        text: lang.ALL,
                    },
                    {
                        id: VmsPlatform.VirtualInfrastructure,
                        text: lang.VIRTUAL_INFRASTRUCTURE,
                    },
                    {
                        id: VmsPlatform.PublicCloud,
                        text: lang.PUBLIC_CLOUD,
                    },
                ]}
                valueGetter={item => item.id}
                textGetter={item => item.text}
                size={CONTROL_SIZE.m}
            />

            {data.parameters.platformFilter[0] === VmsPlatform.VirtualInfrastructure && (
                <FieldLayout
                    label={`${lang.PLATFORM_TYPE}:`}
                >
                    <MultipleChoice
                        error={data.parameters.virtualPlatformFilter.length === 0}
                        titleFormatter={(values) => {
                            if (values.length === virtualPlatformTypes.length) {
                                return `${lang.ALL} ${lang.PLATFORMS.toLowerCase()}`;
                            }
                            if (values.length > 0) {
                                return `${formatStr(lang.N_OF_COUNT_PLATFORMS, values.length, virtualPlatformTypes.length)}`;
                            }
                            return `${lang.NOT_SELECTED}...`;
                        }}
                        data={virtualPlatformTypes}
                        value={data.parameters.virtualPlatformFilter.map(item => item.toString())}
                        onChange={(value) => {
                            formApi.setValue('parameters.virtualPlatformFilter', value.map(jobTypeId => parseInt(jobTypeId)));
                            onDataChange(data);
                        }}
                    />
                </FieldLayout>
            )}

            <FieldLayout
                label={`${capitalize(lang.JOB_TYPE, true)}:`}
            >
                <MultipleChoice
                    error={data.parameters.jobTypeFilter.length === 0}
                    titleFormatter={(values) => {
                        if (values.length === jobTypes.length) {
                            return `${lang.ALL} ${lang.JOB_TYPES.toLowerCase()}`;
                        }
                        if (values.length > 0) {
                            return `${formatStr(lang.N_OF_COUNT_TYPES, values.length, jobTypes.length)}`;
                        }
                        return `${lang.NOT_SELECTED}...`;
                    }}
                    data={jobTypes}
                    value={data.parameters.jobTypeFilter.map(item => item.toString())}
                    onChange={(value) => {
                        formApi.setValue('parameters.jobTypeFilter', value.map(jobTypeId => parseInt(jobTypeId)));
                        onDataChange(data);
                    }}
                />
            </FieldLayout>

            <TextInput
                name='parameters.excludeMask'
                label={lang.EXCLUSION_MASK_NAME}
            />
        </>
    );
};
