/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridColumnJustify,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextColumn,
} from '@veeam-vspc/components';
import { TargetTypeRepresentation } from '@veeam-vspc/models/web-controllers';
import { capitalize } from '@veeam-vspc/core';

import type { CellRendererProps } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { OldRestorePointColumn } from 'views/components/columns/OldRestorePointColumn';
import { JobObjectsFilterToolbar } from '../JobObjectsFilterToolbar/JobObjectsFilterToolbar';
import { BackupStatusColumn } from '../../../../../../../BackupStatusColumn';
import { getOsName } from '../../../../utils';
import { MAX_INTEGER_32 } from 'core/const';

import type { JobObjectsDialogExtraParams, JobObjectsDialogFilterModel, JobObjectsDialogItem, JobObjectsDialogRequestParams } from '../../interfaces';
import type { EJobStates } from 'core/enums';

export interface JobObjectsDialogProps {
    deactivate: () => void;
    title: string;
    url: string;
    exportMethod: string;
    stateId?: string;
    extraParams: JobObjectsDialogExtraParams;
    isExtendedColumns?: boolean;
}

export const JobObjectsDialog: React.FC<JobObjectsDialogProps> = ({
    deactivate,
    title,
    url,
    stateId,
    extraParams,
    isExtendedColumns,
    exportMethod,
}) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const defaultColumnRender = ({ cellData }: CellRendererProps<JobObjectsDialogItem>) => (
        <TextColumn defaultValue={lang.NO_INFO}>{cellData}</TextColumn>
    );
    const columns = [
        {
            field: 'status',
            title: lang.BACKUP_STATUS,
            cell: ({ rowData }: CellRendererProps<JobObjectsDialogItem>) => (
                <BackupStatusColumn
                    statusName={rowData.statusName as keyof typeof EJobStates}
                    lastSessionStartTime={rowData.lastSessionStartTime}
                    lastSessionEndTime={rowData.lastSessionEndTime}
                    failureMessage={rowData.failureMessage}
                    lastSessionUid={rowData.lastSessionUid}
                />
            ),
        },
        {
            field: 'source',
            title: lang.BACKUP_SOURCE,
            cell: defaultColumnRender,
            isSortable: false,
        },
        {
            field: 'backupServerName',
            title: capitalize(lang.BACKUP_SERVER),
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'computerName',
            title: lang.COMPUTER,
            cell: defaultColumnRender,
        },
        {
            field: 'osPlatform',
            title: lang.GUEST_OS,
            width: 100,
            cell: ({ rowData }: CellRendererProps<JobObjectsDialogItem>) => (
                <TextColumn>
                    {getOsName(lang, rowData.osPlatform)}
                </TextColumn>
            ),
        },
        {
            field: 'destination',
            title: lang.DESTINATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'restorePointsCount',
            title: lang.RESTORE_POINTS,
            cell: ({ cellData, rowData }: CellRendererProps<JobObjectsDialogItem>) => (
                <OldRestorePointColumn
                    {...(stateId ? { stateId: `${stateId}.Dialog.RestorePoints` } : {})}
                    value={cellData as any}
                    name={rowData.name}
                    isLink={![
                        TargetTypeRepresentation.ComputerFolder,
                        TargetTypeRepresentation.SharedFolder,
                    ].includes(rowData.destinationType)}
                    extraParams={{
                        agentId: rowData.agentId,
                        configId: rowData.configUid,
                        endpointId: rowData.endpointId,
                    }}
                    isExtendedColumns={isExtendedColumns}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'lastSessionStart',
            title: lang.LAST_RUN,
            cell: ({ rowData }: CellRendererProps<JobObjectsDialogItem>) => (
                <TimeAgoColumn
                    value={rowData.lastSessionStartTime}
                />
            ),
            getSortParam: () => 'lastSessionStartTime',
        },
        {
            field: 'lastSessionStartTime',
            title: lang.LAST_RUN_TIME,
            cell: ({ cellData }: CellRendererProps<JobObjectsDialogItem>) => (
                <GridDateColumn
                    value={cellData}
                />
            ),
            hidden: true,
        },
    ];

    return (
        <Dialog
            header={title}
            size={{
                width: DIALOG_SIZE.xxl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.OK, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <AdvancedGrid
                    defaultColumnWidth={145}
                    columns={columns}
                    data={(requestParams: JobObjectsDialogRequestParams<JobObjectsDialogFilterModel>) => transportService
                        .request<typeof requestParams, JobObjectsDialogItem[]>(url, {
                            ...requestParams,
                            ...extraParams,
                        })}
                    toggleable={true}
                    selection={{
                        field: 'instanceUid',
                        checkbox: true,
                        multiple: true,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => (
                            <JobObjectsFilterToolbar
                                method={exportMethod}
                                idProperty={'instanceUid'}
                                extraParams={extraParams}
                                selectedOnly
                                excludeFromExport={['lastSessionStart']}
                            />
                        ),
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
