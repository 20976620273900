/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { VboInfrastructure } from '../interfaces/vbo-infrastructure';
import type { CompanyWizardStore } from '../stores';

export const syncVboInfrastructure = async(
    { vboServers, vboRepositories }: VboInfrastructure,
    wizardStore: CompanyWizardStore,
    companyId: number
): Promise<VboInfrastructure> => {
    const [availableServers, availableBackupRepositories] = await Promise.all([
        wizardStore.availableVboServersCache.load({ companyId }),
        wizardStore.availableVboBackupRepositoryCache.load({ companyId }),
    ]);

    const resultServers = vboServers.filter(item => availableServers.find(availableServer => availableServer.instanceUid === item.vboServerUid));
    const resultRepositories = vboRepositories.filter(rep =>
        resultServers.findIndex(server => server.vboServerUid === rep.vboServerUid) >= 0
        && availableBackupRepositories.findIndex(availableBackupRep => availableBackupRep.instanceUid === rep.repositoryUid) >= 0
    );

    return {
        vboServers: resultServers,
        vboRepositories: resultRepositories,
    };
};
