/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormLayout,
    INDENT,
    isValid,
    NoLabelGroup,
    NoteBar,
    NOTEBAR_STATUS,
    PasswordInput,
    StackView,
    Text,
    TextInput,
    useExternalFormApi,
} from '@veeam-vspc/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { AutoLkRadioGroup, NoAutoLkRadioGroup } from './components';
import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { DistributionType, VbrDeployType } from '../../enums';
import { stepValidate } from './stepValidate';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { VbrDeploySettingsWizardModel } from '../../entries';

const Distribution = (wizardStepData: WizardStepData<VbrDeploySettingsWizardModel>) => {
    const { data, onDataChange, validationState } = wizardStepData;
    const distributiveSize = RCOP.utils.format.DataSize.renderer(data.distributiveSize);
    const formApi = useExternalFormApi<VbrDeploySettingsWizardModel>();
    const lang = useLang();

    return (
        <StepLayout
            title={lang.SOURCE}
            description={`${lang.SPECIFY_THE_BACKUP_SERVER_SETUP_FILE_LOCATION}.`}
        >
            <Form
                value={data}
                validate={stepValidate(lang)}
                validationState={validationState}
                onChange={onDataChange}
                externalFormApi={formApi}
            >
                <NoLabelGroup
                    content={(
                        data.autoLkConfigured
                            ? (
                                <AutoLkRadioGroup
                                    distributiveSize={distributiveSize}
                                    distributionType={data.distributionType}
                                    formApi={formApi}
                                />
                            )
                            : (
                                <NoAutoLkRadioGroup
                                    distributionType={data.distributionType}
                                    formApi={formApi}
                                />
                            )
                    )}
                    subContent={(
                        <FormLayout inlineLabel disabled={data.distributionType !== DistributionType.DownloadFromFileShare}>
                            <TextInput
                                name='distribution.location'
                                label={lang.PATH}
                                placeholder={lang.FOLDER}
                            />

                            <TextInput
                                name='distribution.userName'
                                label={lang.USERNAME}
                            />

                            <PasswordInput
                                name='distribution.password'
                                label={lang.PASSWORD}
                            />
                        </FormLayout>
                    )}
                />

                {data.vbrDeployType === VbrDeployType.Upgrade && (
                    <StackView indentTop={INDENT.m}>
                        <NoteBar status={NOTEBAR_STATUS.info}>
                            <Text>
                                {`${lang.BEFORE_PROCEEDING}:`}
                                <br />
                                {' '}
                                <br />
                                {` - ${lang.IF_YOUR_BACKUP_SERVER_IS_MANAGED_BY}`}
                                <br />
                                {` - ${lang.NO_BACKUP_JOBS_AND_RESTORE_OPERATIONS}`}
                                <br />
                                {` - ${lang.YOU_HAVE_THE_UP_TO_DATE_CONFIGURATION}`}
                            </Text>
                        </NoteBar>
                    </StackView>
                )}
            </Form>
        </StepLayout>
    );
};

export const getDistributionStep = (lang: LangsServiceBase): WizardStep<VbrDeploySettingsWizardModel> => ({
    title: lang.SOURCE,
    validate: ({ data }) => isValid(stepValidate(lang), data),
    render: data => <Distribution {...data} />,
});
