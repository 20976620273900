/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TextColumn } from '@veeam-vspc/components';

import type { CellRendererProps, GridColumnProps } from '@veeam-vspc/components';
import type { Vb365ProtectedObject } from '@veeam-vspc/models/rest';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { VBObjectsFilterToolbar, VBObjectsActionToolbar, ProtectedDataTypeColumn, RestorePointsColumn } from './components';
import { VBObjectsService } from './services';


export const VBObjects: React.FC = () => {
    const lang = useLang();

    const defaultColumnRender = ({ cellData }: CellRendererProps<Vb365ProtectedObject>) => <TextColumn>{cellData}</TextColumn>;

    const columns: GridColumnProps<Vb365ProtectedObject>[] = [
        {
            field: 'name',
            title: lang.NAME,
            cell: defaultColumnRender,
        },
        {
            field: 'protectedDataType',
            title: lang.TYPE,
            cell: ProtectedDataTypeColumn,
            width: 100,
        },
        {
            field: 'vb365OrganizationName',
            title: lang.ORGANIZATION,
            cell: defaultColumnRender,
            isSortable: false,
        },
        {
            field: 'latestRestorePointDate',
            title: lang.LATEST_RESTORE_POINT,
            cell: ({ rowData }: CellRendererProps<Vb365ProtectedObject>) => (
                <TimeAgoColumn
                    value={rowData.latestRestorePointDate}
                />
            ),
        },
        {
            field: 'archiveRestorePointsCount',
            title: lang.BACKUP_COPIES,
            width: 130,

            cell: ({ rowData }: CellRendererProps<Vb365ProtectedObject>) => (
                <RestorePointsColumn
                    name={`${lang.BACKUP_COPIES} - ${rowData.name}`}
                    stateId={'VBObjects.Dialogs.archiveRestorePointsCount'}
                    value={rowData.archiveRestorePointsCount}
                    vb365BackupRepositoryUid={rowData.archiveRepositoryUid}
                    vb365ProtectedObjectId={rowData.id}
                    vb365ServerUid={rowData.vb365ServerUid}
                />
            ),
        },
        {
            field: 'restorePointsCount',
            title: lang.RESTORE_POINTS,
            width: 140,

            cell: ({ rowData }: CellRendererProps<Vb365ProtectedObject>) => (
                <RestorePointsColumn
                    name={`${lang.RESTORE_POINTS} - ${rowData.name}`}
                    stateId={'VBObjects.Dialogs.restorePointsCount'}
                    value={rowData.restorePointsCount}
                    vb365BackupRepositoryUid={rowData.repositoryUid}
                    vb365ProtectedObjectId={rowData.id}
                    vb365ServerUid={rowData.vb365ServerUid}
                />
            ),
        },
        {
            field: 'organizationName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
            isSortable: false,
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            isSortable: false,
            hidden: true,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            isSortable: false,
            hidden: true,
        },
        {
            field: 'vb365ServerName',
            title: lang.SERVER,
            cell: defaultColumnRender,
            isSortable: false,
            width: 100,
        },
        {
            field: 'consumesLicense',
            title: lang.LICENSED,
            cell: ({ rowData }: CellRendererProps<Vb365ProtectedObject>) => {
                if (rowData.consumesLicense === null) {
                    return <TextColumn>{null}</TextColumn>;
                }

                return <TextColumn>{rowData.consumesLicense ? lang.YES : lang.NO}</TextColumn>;
            },
            width: 100,
        },
    ];

    return (
        <AdvancedGrid
            stateId='ProtectedDataPage.VBObjects'
            columns={columns}
            data={VBObjectsService.getProtectedVBObjects}
            selection={{
                field: 'id',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <VBObjectsFilterToolbar />,
                () => <VBObjectsActionToolbar />,
            ]}
        />
    );
};
