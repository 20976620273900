/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { ConfigRouteIds } from '../enums';
import type { RouteConfigItemData } from '../interfaces';

export class RouteConfigItem {

    id: ConfigRouteIds;

    constructor({ id }: RouteConfigItemData) {
        if (!id) {
            throw Error('"id" property is required');
        }

        this.id = id;
    }

}
