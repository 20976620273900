/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { isPositiveNumber, SizeColumn } from '@veeam-vspc/components';

import type { SizeColumnProps } from '@veeam-vspc/components';

export interface RestorePointSizeProps extends SizeColumnProps {
}

export const RestorePointSizeColumn: React.FC<RestorePointSizeProps> = ({ value, ...restProps }) => (
    <SizeColumn
        {...restProps}
        value={isPositiveNumber(value) ? value : null}
        pointFixed={1}
    />
);
