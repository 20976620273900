/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';

import { useAppServices } from '../AppProvider/hooks';
import { NotificationContext } from './stores';
import { useNotificationService } from './hooks';

export interface NotificationProviderProps {
    children: React.ReactElement;
}

export const NotificationProvider = (props: NotificationProviderProps) => {
    const { notificationService } = useAppServices();
    const { notifications } = useNotificationService(notificationService);
    const notificationMethods = useMemo(() => notifications, [notifications]);

    return (
        <NotificationContext.Provider value={notificationMethods}>
            {props.children}
        </NotificationContext.Provider>
    );
};
