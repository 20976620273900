/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Form,
    FormLayout,
    NoteBar,
    NOTEBAR_STATUS,
    Toggle,
    NoLabelGroup,
    Text,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    FormTitle,
    Textarea,
    Checkbox,
    LinkButton,
    SignPost,
    FieldLayout,
    FormValidator,
    CONTROL_SIZE,
    Separator,
} from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/use-page-wizard-store';
import { capitalize, formatStr } from '@veeam-vspc/core/utils/string-helpers';
import { CompanyTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { PreviewEmail } from 'views/components/PreviewEmail';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { ToggleField } from 'components/controls/ToggleField';
import { useCompanyWizardStore } from '../../hooks';
import previewEmailIcon from 'images/actions/preview-email.png';

import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';
import type { CompanyData } from '../../interfaces';
import type { EmailCustomSectionData } from 'core/interfaces';

export const getNotificationsStep = (lang: LangsServiceBase): WizardStep<CompanyData> => ({
    title: lang.NOTIFICATIONS,
    render: stepData => <NotificationsStep {...stepData} />,
});

const formValidate = (data: EmailCustomSectionData) => {
    const validator = new FormValidator(data);

    if (data.addCustomText) {
        validator.validate('welcomeEmailCustomSection')
            .string()
            .maxLength(1000);
    }

    return validator.result();
};

const NotificationsStep = observer((wizardStepData: WizardStepData<CompanyData>) => {
    const lang = useLang();
    const wizardStore = useCompanyWizardStore();
    const wizardContext = usePageWizardStore<CompanyData>();
    const { portalUser } = useAppStore();
    const { data } = wizardStepData;
    const showPreview = () => wizardContext.openPanel(hide => (
        <PreviewEmail
            {...wizardStepData}
            emailData={wizardStore.emailData}
            organizationType={CompanyTypeRepresentation.Tenant}
            gateways={[
                {
                    cloudAgentUid: data.cloudConnectAgentUid,
                    selectionType: data.gatewaySelectionType,
                    poolUids: data.gatewayPool.map(pool => pool.instanceUid),
                },
            ]}
            hidePanel={hide}
        />
    ));

    const emailData = wizardStore.emailData;

    useEffect(() => {
        wizardStore.loadEmailData(data.organizationUid);
    }, []);

    wizardContext.setLoading(emailData === null);

    return (
        <StepLayout
            title={lang.NOTIFICATIONS}
            description={formatStr(lang.CONFIGURE_NOTIFICATION_SETTINGS, lang.COMPANY.toLowerCase())}
        >
            {emailData !== null && (
                <Form<EmailCustomSectionData>
                    value={emailData}
                    validate={value => formValidate(value)}
                >
                    <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                        <FormLayout inlineLabel>
                            <FormTitle>{lang.WELCOME_EMAIL}</FormTitle>

                            <Toggle
                                name={'addCustomText'}
                                label={lang.INCLUDE_CUSTOM_TEXT}
                                showSuffix={true}
                            />

                            <NoLabelGroup
                                disabled={!emailData.addCustomText}
                                content={(
                                    <FormLayout>
                                        <FieldLayout
                                            size={CONTROL_SIZE.full}
                                            label={(
                                                <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                                                    <Text>{`${lang.SPECIFY_THE_TEXT_TO_ADD_TO_WELCOME_EMAIL}:`}</Text>
                                                    <SignPost text={lang.SUPPORTED_FORMATS} />
                                                </StackView>
                                            )}
                                        >
                                            <Textarea
                                                name={'emailContent'}
                                                rows={10}
                                                size={CONTROL_SIZE.full}
                                            />
                                        </FieldLayout>

                                        <Checkbox name={'isVisibleGeneralSection'} disabled={!emailData.addCustomText}>
                                            {lang.INCLUDE_SELF_SERVICE}
                                        </Checkbox>

                                        <LinkButton
                                            iconBefore={previewEmailIcon}
                                            onClick={showPreview}
                                        >
                                            {`${capitalize(lang.PREVIEW_WELCOME_EMAIL, true)}...`}
                                        </LinkButton>

                                        {portalUser.userRole !== PortalUserRoles.PortalOperator
                                            && portalUser.userRole !== PortalUserRoles.ServiceProviderOperator && (
                                            <>
                                                <Separator />

                                                <Checkbox name={'isDefault'} disabled={!emailData.addCustomText}>
                                                    {lang.USE_THIS_TEMPLATE_AS_DEFAULT}
                                                </Checkbox>
                                            </>
                                        )}
                                    </FormLayout>
                                )}
                            />
                        </FormLayout>

                        <FormLayout inlineLabel>
                            <FormTitle>{lang.PREDEFINED_ALARMS}</FormTitle>

                            <ToggleField
                                name={''}
                                value={data.alarmDetectEnabled}
                                onChange={(value) => {
                                    data.alarmDetectEnabled = value;
                                    wizardStepData.onDataChange(data);
                                }}
                                label={formatStr(lang.ENABLE_ALARMS, lang.COMPANY.toLowerCase())}
                                showSuffix={true}
                            />
                        </FormLayout>

                        <NoteBar status={NOTEBAR_STATUS.info}>
                            <Text>{lang.UPON_ENABLING_THIS_OPTION}</Text>
                        </NoteBar>
                    </StackView>
                </Form>
            )}
        </StepLayout>
    );
});
