/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { NotificationsPageDialogTypes } from './enums';
import { EmailServerSettings } from './components/EmailServerSettings';

import type { NotificationsPageDialogsProps } from './interfaces';

export const NOTIFICATIONS_PAGE = 'NotificationsPage';

export interface NotificationsPageProps extends NotificationsPageDialogsProps {}

export const NotificationsPage: React.FC<NotificationsPageProps> = props => (
    props.dialogName === NotificationsPageDialogTypes.EmailServerSettings && <EmailServerSettings {...props.dialogData} />
);
