/**
 * Copyright © Veeam Software Group GmbH.
 */

import { FileSizeUnitsInBytes } from '@veeam-vspc/core';

import type { ResellerCloudBackupResourceMapModel } from '@veeam-vspc/models/web-controllers';

export const getSortResult = (val1: number, val2: number): number => {
    const unlimited = -1;

    if (val2 === unlimited) {
        return -1;
    }
    if (val1 === unlimited) {
        return 1;
    }

    return val1 - val2;
};

export const sortQuotaFn = (fieldName: string) =>
    (rowData1: ResellerCloudBackupResourceMapModel, rowData2: ResellerCloudBackupResourceMapModel): number => {
        const quota1: number = rowData1.quota[fieldName];
        const quota2: number = rowData2.quota[fieldName];

        return getSortResult(quota1, quota2);
    };

export const sortFreeSpaceFn = (rowData1: ResellerCloudBackupResourceMapModel, rowData2: ResellerCloudBackupResourceMapModel): number => {
    const freeSpace1 = rowData1.quota.storageGb * FileSizeUnitsInBytes.GB - rowData1.usedSpace;
    const freeSpace2 = rowData2.quota.storageGb * FileSizeUnitsInBytes.GB - rowData2.usedSpace;

    return getSortResult(freeSpace1, freeSpace2);
};
