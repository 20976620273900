/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, StackView, Text, ICON_SIZES, STACK_ALIGN, STACK_GAP, TEXT_SIZE } from '@veeam-vspc/components';

import errorIcon from 'images/error.svg';

interface ErrorsColumnProps {
    count: number;
}

export const ErrorsColumn: React.FC<ErrorsColumnProps> = ({ count }) => (
    <StackView gap={STACK_GAP.s} align={STACK_ALIGN.center}>
        <Icon src={errorIcon} size={ICON_SIZES.xl} />
        <Text size={TEXT_SIZE.xl}>{count}</Text>
    </StackView>
);
