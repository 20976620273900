/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, observable } from 'mobx';
import { AzureObjectType } from '@veeam-vspc/models/web-controllers';

export class ProtectedAzureWorkloadsStore {
    @observable azureObjectType = AzureObjectType.Vm;

    @action.bound
    setAzureObjectType(v: AzureObjectType): void {
        this.azureObjectType = v;
    }
}
