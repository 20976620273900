/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator, STACK_DIRECTION,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';


import { useLang } from 'views/providers/LangProvider/hooks';
import { IpAddressField } from 'components/controls/IpAddressField';
import { getIp4Validator } from 'core/utils/validators';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { PublicIp } from '../../../../../../../../interfaces';

interface Props {
    deactivate: () => void;
    onConfirm: (data: PublicIp[]) => void;
    existingItems: PublicIp[];
}

interface FormData {
    from: string;
    to: string;
}

const getAddresses = (range: FormData): string[] => {
    const lastOctet = 3;
    const fromOctets = range.from.split('.');
    const toOctets = range.to.split('.');
    const fromLastOctet = parseInt(fromOctets[lastOctet]);
    const toLastOctet = parseInt(toOctets[lastOctet]);
    const result: string[] = [];

    for (let i = fromLastOctet; i <= toLastOctet; i++) {
        const newAddress = `${fromOctets[0]}.${fromOctets[1]}.${fromOctets[2]}.${i}`;
        result.push(newAddress);
    }

    return result;
};

const areSameOctets = (range: FormData): boolean => {
    const testOctets = [0, 1, 2];
    const fromOctets = range.from.split('.');
    const toOctets = range.to.split('.');

    return testOctets.every(octet => fromOctets[octet] === toOctets[octet]);
};

const formValidate = (data: FormData, lang: LangsServiceBase, existingItems: PublicIp[]) => {
    const validator = new FormValidator(data);

    const sameOctetsResult = !areSameOctets(data) ? lang.SPECIFIED_VALUE_IS_NOT_SUPPORTED : '';

    const newAddresses = getAddresses(data);

    const alreadyAssignedResult = newAddresses.some(newAddress => existingItems.find(item => item.address === newAddress))
        ? lang.SPECIFIED_IP_RANGE_IS_ALREADY_ASSIGNED
        : '';

    validator.validate('from')
        .string()
        .required()
        .check(getIp4Validator(lang))
        .check(() => sameOctetsResult)
        .check(() => alreadyAssignedResult);

    validator.validate('to')
        .string()
        .required()
        .check(getIp4Validator(lang))
        .check(() => sameOctetsResult)
        .check(() => alreadyAssignedResult);

    return validator.result();
};

export const PublicAddressRangeDialog: React.FC<Props> = (props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;

    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        return result;
    });
    const [data, setData] = useState<FormData>({ from: '', to: '' });

    const formApi = useExternalFormApi<FormData>();
    const [validationState] = useState(new ValidationState());

    return (
        <Dialog
            header={lang.PUBLIC_IP_ADDRESS_RANGE}
            description={lang.SPECIFY_PUBLIC_IP_ADDRESS_RANGE}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            const result = getAddresses(formApi.value).map<PublicIp>(address => ({
                                address,
                                tenantUid: null,
                            }));
                            props.onConfirm && props.onConfirm(result);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.auto,
            }}
        >
            <Form <FormData>
                value={data}
                validate={data => formValidate(data, lang, existingItems)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                    <IpAddressField
                        label={lang.FROM}
                        name={'from'}
                        size={defaultFieldWidth}
                    />

                    <IpAddressField
                        label={lang.TO}
                        name={'to'}
                        size={defaultFieldWidth}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
};
