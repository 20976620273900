/**
 * Copyright © Veeam Software Group GmbH.
 */

import { TextDividerSymbols } from '@veeam-vspc/core';
import { renderShortDateAndTimeForColumn } from '@veeam-vspc/components';

import type { ParsedTextConfig } from '@veeam-vspc/core';

import { getMarkedList } from 'core/utils';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { FailureMessageParams } from '../interfaces';

export const getFailureMessage = (lang: LangsServiceBase, params: FailureMessageParams): ParsedTextConfig[] => {
    const {
        excludeTimeShiftCorrection = false,
        shortDateFormat,
        shortTimeFormat,
        failureMessage,
        lastSessionTime,
        statusName,
    } = params;
    const listItems = getMarkedList(failureMessage);

    const statusText = statusName.toLowerCase() === lang.WARNING.toLowerCase()
        ? lang.WARNING.toLowerCase()
        : lang.ERROR.toLowerCase();

    let message = `${lang.JOB_FINISHED_WITH} ${statusText}`;

    if (lastSessionTime) {
        message += ` ${lang.AT.toLowerCase()} ${renderShortDateAndTimeForColumn(
            lastSessionTime,
            shortDateFormat,
            shortTimeFormat,
            excludeTimeShiftCorrection,
        )}`;
    }

    return [
        { text: message },
        { type: TextDividerSymbols.Paragraph },
        ...listItems,
    ];
};
