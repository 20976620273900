/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { CloudDatabases, Computers, FileShares, VirtualMachines, VBObjects } from './components';

export interface ProtectedDataPageProps {
    viewName: string;
}

export const PROTECTED_DATA_PAGE = 'ProtectedDataPage';

export const ProtectedDataPage: React.FC<ProtectedDataPageProps> = ({ viewName, ...restProps }) => (
    <>
        {viewName === 'ComputersManagedByConsole' && <Computers {...restProps} gridName='ManagedByConsole' />}
        {viewName === 'ComputersManagedByBackupServer' && <Computers {...restProps} gridName='ManagedByBackupServer' />}
        {viewName === 'VirtualMachinesVirtualInfrastructure' && <VirtualMachines {...restProps} gridName='VirtualInfrastructure' />}
        {viewName === 'VirtualMachinesPublicClouds' && <VirtualMachines {...restProps} gridName='PublicClouds' />}
        {viewName === 'FileSharesLocal' && <FileShares {...restProps} gridName='FileSharesLocal' />}
        {viewName === 'FileSharesPublicClouds' && <FileShares {...restProps} gridName='FileSharesPublicClouds' />}
        {viewName === 'CloudDatabases' && <CloudDatabases stateId={'ProtectedDataPage'} {...restProps} />}
        {viewName === 'VBObjects' && <VBObjects {...restProps} />}
    </>
);
