/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { AppContext } from '../stores';

import type { AppContextData } from '../interfaces';

export const useAppContext = (): AppContextData => useContext<AppContextData>(AppContext);
