/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Checkbox,
    Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator, LabelGroup,
    NoLabelGroup,
    NumberInput, STACK_DIRECTION, STACK_GAP, StackView,
    TextInput,
    useExternalFormApi,
    ValidationState,
    dataSizeToString,
    SIZE_XL,
    STACK_ALIGN,
    STACK_DISTRIBUTION,
    Text,
    TEXT_WEIGHT,
} from '@veeam-vspc/components';
import { deepCopy, formatStr } from '@veeam-vspc/core';

import type { ContentRendererProps } from '@veeam-vspc/components';
import type { ResellerCloudBackupResourceMapModel } from '@veeam-vspc/models/web-controllers';

import { DataSizeInBytes } from 'components/controls/DataSizeInBytes';
import { useLang } from 'views/providers/LangProvider/hooks';
import { MAX_INTEGER_32 } from 'core/const';
import { useResellerWizardStore } from '../../../../../../hooks';

import type { AvailableBackupResource } from 'core/interfaces/available-backup-resource';
import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';

interface Props {
    deactivate: () => void;
    onConfirm: (data: ResellerCloudBackupResourceMapModel) => void;

    data?: ResellerCloudBackupResourceMapModel;
    existingItems: ResellerCloudBackupResourceMapModel[];
    cloudConnectAgentUids: string[];
}

const MIN_QUOTA = 0;
const MAX_QUOTA = MAX_INTEGER_32;

const MIN_STORAGE_QUOTA = 1;

const getAvailableQuotaSizeGb = (uniqueUid: string, ccServers: AvailableBackupResource[]) => {
    const selection = ccServers?.find(item => item.uniqueUid === uniqueUid);
    if (!selection) {
        return 0;
    }

    let availableQuotaSize = selection.availableQuotaSize;

    if (availableQuotaSize === -1) {
        const NEW_DEFAULT_AVAILABLE_QUOTA_SIZE_GB = 999 * Math.pow(1024, 2); // 999 PB
        return NEW_DEFAULT_AVAILABLE_QUOTA_SIZE_GB;
    }

    const gb = Math.pow(1024, 3);
    availableQuotaSize = availableQuotaSize / gb;
    return availableQuotaSize;
};

const formValidate = (
    data: ResellerCloudBackupResourceMapModel,
    lang: LangsServiceBase,
    existingItems: ResellerCloudBackupResourceMapModel[],
    ccServers: AvailableBackupResource[]) => {
    const validator = new FormValidator(data);

    validator.validate('uniqueUid')
        .string()
        .required();

    validator.validate('cloudRepositoryFriendlyName')
        .string()
        .required()
        .maxLength(256)
        .check((value => existingItems.find(item => item.cloudRepositoryFriendlyName === value) ? lang.CLOUD_REPOSITORY_WITH_THE_SAME_NAME : ''));

    const maxQuota = getAvailableQuotaSizeGb(data.uniqueUid, ccServers);
    if (data.uniqueUid) {
        if (!data.quota.isStorageQuotaUnlimited) {
            validator.validate('quota.storageGb')
                .number()
                .required()
                .min(MIN_STORAGE_QUOTA)
                .max(maxQuota, formatStr(lang.QUOTA_LIMIT_FOR_THIS, dataSizeToString(maxQuota, 0, 'G')));
        }

        if (!data.quota.quotasIsUnlimited) {
            validator.validate('quota.vms')
                .number()
                .required()
                .min(MIN_QUOTA)
                .max(MAX_QUOTA);

            validator.validate('quota.workstations')
                .number()
                .required()
                .min(MIN_QUOTA)
                .max(MAX_QUOTA);

            validator.validate('quota.servers')
                .number()
                .required()
                .min(MIN_QUOTA)
                .max(MAX_QUOTA);
        }
    }

    return validator.result();
};

const getDefaultData = (): ResellerCloudBackupResourceMapModel => ({
    id: -1,
    cloudRepositoryFriendlyName: '',
    cloudConnectResourceName: '',
    cloudConnectResourceUid: null,
    cloudConnectAgentUid: null,
    freeSpace: 0,
    usedSpace: 0,
    quota: {
        quotasIsUnlimited: true,
        storageGb: 100,
        servers: 0,
        vms: 0,
        workstations: 0,
        isStorageQuotaUnlimited: false,
    },
});

export const QuotaDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;

    const isEdit = !!props.data;
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.uniqueUid === props.data.uniqueUid), 1);
        }
        return result;
    });

    const [filteredAvailableBackupResources, setFilteredAvailableBackupResources] = useState([]);

    const [data, setData] = useState(() => {
        const dataToEdit = props.data;
        const defaultData = getDefaultData();

        if (dataToEdit && dataToEdit.quota.quotasIsUnlimited) {
            // unlimited quotas
            dataToEdit.quota.vms = defaultData.quota.vms;
            dataToEdit.quota.servers = defaultData.quota.servers;
            dataToEdit.quota.workstations = defaultData.quota.workstations;
        }

        return dataToEdit ? deepCopy(dataToEdit) : defaultData;
    });

    const formApi = useExternalFormApi<ResellerCloudBackupResourceMapModel>();
    const [loading, setLoading] = useState(false);
    const [validationState] = useState(new ValidationState());

    const wizardStore = useResellerWizardStore();

    useEffect(() => {
        setLoading(wizardStore.availableBackupResourcesCache.loading);
        wizardStore.availableBackupResourcesCache.load({ cloudConnectAgentUids: props.cloudConnectAgentUids })
            .then((items) => {
                setFilteredAvailableBackupResources(items.filter(item => !existingItems
                    .find(existingItem => existingItem.uniqueUid === item.uniqueUid)
                ));
                setLoading(false);
            });
    }, []);

    return (
        <Dialog
            header={lang.SET_CLOUD_STORAGE_QUOTA}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            props.onConfirm && props.onConfirm(formApi.value);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={loading}
        >
            <Form <ResellerCloudBackupResourceMapModel>
                value={data}
                validate={data => formValidate(data, lang, existingItems, wizardStore.availableBackupResourcesCache.value)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'uniqueUid'}
                        label={RCOP.Lang.BACKUP_REPOSITORY}
                        placeholder={lang.SELECT_AN_EXISTING_BACKUP_REPOSITORY}
                        data={filteredAvailableBackupResources}
                        valueGetter={item => item.uniqueUid}
                        textGetter={item => item.cloudConnectResourceName}
                        disabled={data.companyMap?.length > 0}
                        size={defaultFieldWidth}
                        onChange={(value, itemIndex) => {
                            const item = filteredAvailableBackupResources[itemIndex];
                            formApi.setValue('cloudConnectResourceName', item.cloudConnectResourceName);
                            formApi.setValue('cloudConnectResourceUid', item.cloudConnectResourceUid);
                            formApi.setValue('cloudConnectAgentUid', item.cloudConnectAgentUid);
                        }}
                        optionRenderer={({ text, option }: ContentRendererProps<AvailableBackupResource>) => (
                            <StackView
                                size={{ height: SIZE_XL }}
                                direction={STACK_DIRECTION.row}
                                align={STACK_ALIGN.center}
                                distribution={STACK_DISTRIBUTION.firstFill}
                            >
                                <Text truncate>{`${text}`}</Text>

                                <StackView direction={STACK_DIRECTION.row} align={STACK_ALIGN.center} gap={STACK_GAP.xxs}>
                                    <Text>{`${lang.AVAILABLE_SPACE}:`}</Text>
                                    <Text weight={TEXT_WEIGHT.bold}>{`${dataSizeToString(option.availableSpace, 1)}`}</Text>
                                </StackView>
                            </StackView>
                        )}
                    />

                    <TextInput
                        name={'cloudRepositoryFriendlyName'}
                        label={lang.CLOUD_REPOSITORY_NAME}
                        placeholder={lang.SPECIFY_A_FRIENDLY_REPOSITORY}
                        size={defaultFieldWidth}
                    />

                    <LabelGroup label={`${lang.REPOSITORY_QUOTA}:`} disabled={!data.uniqueUid}>
                        <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                            <DataSizeInBytes
                                name={'quota.storageGb'}
                                disabled={data.quota.isStorageQuotaUnlimited}
                                unitType={'G'}
                                maxUnit={'P'}
                                minValue={MIN_STORAGE_QUOTA}
                                maxValue={getAvailableQuotaSizeGb(data.uniqueUid, wizardStore.availableBackupResourcesCache.value)}
                            />

                            <Checkbox
                                name={'quota.isStorageQuotaUnlimited'}
                                disabled={!data.uniqueUid} // TODO: use disabled from LabelGroup
                            >
                                {lang.UNLIMITED}
                            </Checkbox>
                        </StackView>
                    </LabelGroup>

                    <NoLabelGroup
                        content={(
                            <Checkbox
                                name={'quota.quotasIsUnlimited'}
                            >
                                {lang.SET_UNLIMITED_QUOTA}
                            </Checkbox>
                        )}
                        subContent={(
                            <FormLayout inlineLabel disabled={data.quota.quotasIsUnlimited}>
                                <NumberInput name={'quota.vms'} label={lang.VMS_QUOTA} minValue={MIN_QUOTA} maxValue={MAX_QUOTA} />
                                <NumberInput name={'quota.workstations'} label={lang.WORKSTATIONS_QUOTA} minValue={MIN_QUOTA} maxValue={MAX_QUOTA} />
                                <NumberInput name={'quota.servers'} label={lang.SERVERS_QUOTA} minValue={MIN_QUOTA} maxValue={MAX_QUOTA} />
                            </FormLayout>
                        )}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
});
