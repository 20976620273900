/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    constants,
    StackView,
    STACK_GAP,
    TitleH2,
    STACK_DISTRIBUTION,
    ICON_SIZES,
    Icon,
    Text,
    TEXT_SIZE,
    STACK_DIRECTION,
    STACK_ALIGN,
    SPACE_FILL,
} from '@veeam-vspc/components';
import * as React from 'react';

import success from 'images/success-light.svg';
import { PlateItem } from './components/PlateItem';
import { PlateWidgetStyled } from './components/PlateWidgetStyled';
import { Spinner } from '../../../../../components/Spinner';

import type { PlateWidgetProps } from './interfaces';

export const PlateWidget: React.FC<PlateWidgetProps> = (props) => {
    let widgetContent: JSX.Element | JSX.Element[];

    if (props.isHealthy) {
        widgetContent = (
            <StackView
                gap={STACK_GAP.s}
                direction={STACK_DIRECTION.column}
                align={STACK_ALIGN.center}
            >
                <Icon src={success} size={ICON_SIZES.xxl} />
                <Text size={TEXT_SIZE.l}>{props.healthyText}</Text>
            </StackView>
        );
    } else {
        widgetContent = props.items
            .filter(Boolean)
            .map((itemProps, index) => <PlateItem {...itemProps} key={index} />);
    }

    return (
        <PlateWidgetStyled
            onClick={props.onClick}
            className={props.className}
        >
            <TitleH2 style={{ marginBottom: constants.SPACING_M }}>
                {props.title}
            </TitleH2>

            <StackView
                distribution={STACK_DISTRIBUTION.center}
                gap={STACK_GAP.xxxl}
                align={STACK_ALIGN.center}
                spaceFill={SPACE_FILL.all}
                style={{ position: 'relative' }}
            >
                {props.loading && <Spinner delayMs={300} size={50} />}
                {widgetContent}
            </StackView>
        </PlateWidgetStyled>
    );
};
