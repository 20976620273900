/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { createLinkControl } from '@veeam/components';
import { TimeUnit } from '@veeam-vspc/models/web-controllers';
import { useMemo } from 'react';

import { BackupTasksRatio } from './components/BackupTasksRatio';
import { StatefulCombobox } from '../StatefulCombobox';
import { ConsumedPoints } from './components/ConsumedPoints';
import { FilterContext } from '../../contexts';
import { FilterStore } from '../../stores';
import { ManagedCompanies } from './components/ManagedCompanies';
import { TriggeredAlarms } from './components/TriggeredAlarms';
import { WidgetColumnsStyled, WidgetViewStyled, WidgetRowsStyled } from '../../../../../components/layouts/WidgetView';
import { useFilterStore } from '../../hooks';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { ComboboxItem } from '../../interfaces';

const InfrastructureLayout = observer(() => {
    const filterStore = useFilterStore();
    const lang = useLang();

    return (
        <WidgetViewStyled>
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                label={`${lang.REPORTING_PERIOD}:`}
                onChange={filterStore.setReportingPeriod}
                stateId={'trends.infrastructure.reporting-period'}
                textGetter={(p: ComboboxItem) => p.text}
                value={filterStore.reportingPeriod}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: TimeUnit.Weeks, text: lang.WEEKLY },
                    { id: TimeUnit.Months, text: lang.MONTHLY },
                ]}
            />

            <WidgetColumnsStyled>
                <WidgetRowsStyled>
                    <TriggeredAlarms />
                    <ManagedCompanies />
                </WidgetRowsStyled>

                <WidgetRowsStyled>
                    <BackupTasksRatio />
                    <ConsumedPoints />
                </WidgetRowsStyled>
            </WidgetColumnsStyled>
        </WidgetViewStyled>
    );
});

export const Infrastructure: React.FC = () => {
    const filterStore = useMemo(() => new FilterStore(), []);

    return (
        <FilterContext.Provider value={filterStore}>
            <InfrastructureLayout />
        </FilterContext.Provider>
    );
};
