/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Icon, STACK_DIRECTION, STACK_GAP, StackView, Text, TEXT_WEIGHT, ThemeProvider } from '@veeam-vspc/components';
import { plural } from '@veeam-vspc/core';
import React from 'react';

import successIcon from 'images/success-status.svg';
import warningIcon from 'images/warning-status.svg';
import errorIcon from 'images/error-status.svg';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { Point } from '../../../../interfaces';

export const MapTooltip: React.FC<Point & { lang: LangsServiceBase; }> = ({ name, success, warning, error, lang }) => {
    const companyLang = lang.COMPANY.toLowerCase();
    const companiesLang = lang.COMPANIES.toLowerCase();

    return (
        <ThemeProvider>
            <StackView
                direction={STACK_DIRECTION.column}
                gap={STACK_GAP.s}
            >
                <Text weight={TEXT_WEIGHT.bold}>{name}</Text>

                <StackView
                    direction={STACK_DIRECTION.column}
                    gap={STACK_GAP.s}
                >
                    {success > 0 && (
                        <StackView gap={STACK_GAP.s}>
                            <Icon src={successIcon} />
                            <Text>{`${lang.HEALTHY}: ${plural(success, companyLang, companiesLang)}`}</Text>
                        </StackView>
                    )}

                    {warning > 0 && (
                        <StackView gap={STACK_GAP.s}>
                            <Icon src={warningIcon} />
                            <Text>{`${lang.WARNING}: ${plural(warning, companyLang, companiesLang)}`}</Text>
                        </StackView>
                    )}

                    {error > 0 && (
                        <StackView gap={STACK_GAP.s}>
                            <Icon src={errorIcon} />
                            <Text>{`${lang.ERROR}: ${plural(error, companyLang, companiesLang)}`}</Text>
                        </StackView>
                    )}
                </StackView>
            </StackView>
        </ThemeProvider>
    );
};
