/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import {
    Search,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    ACTION_VIEW,
} from '@veeam-vspc/components';

import type { GridStore, RequestParams } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { MAX_INTEGER_32 } from 'core/const';

import type { VspcTransportBaseData } from 'core/services';
import type { CompanyRow } from 'views/pages/RolesAndUsersPage/components/SPSSORule/interfaces/company-row';
import type { CompanyFilterModel } from 'views/pages/RolesAndUsersPage/components/SPSSORule/interfaces/company-filter-model';
import type { ManagedCompaniesProps } from 'views/pages/RolesAndUsersPage/components/SPSSORule/interfaces/managed-companies-props';

export const ManagedCompaniesPanel = (props: ManagedCompaniesProps) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<CompanyRow, any, CompanyFilterModel>>();
    const [isApplyDisabled, setApplyToDisabledState] = useState(data.model.managedCompaniesUids?.length === 0);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.COMPANIES}
            description={lang.SELECT_COMPANIES_ACCESSIBLE_TO_THIS_USER}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.model.managedCompaniesUids = api.current.selected.map(({ companyInstanceUid }) => companyInstanceUid);

                        onDataChange(data);

                        hidePanel();
                    },
                    disabled: isApplyDisabled,
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid
                initialState={{
                    selected: data.model.managedCompaniesUids?.map(companyInstanceUid => ({ companyInstanceUid })) as CompanyRow[],
                }}
                defaultColumnWidth={100}
                columns={[
                    { field: 'name', title: lang.COMPANY },
                    { field: 'siteName', title: lang.SITE },
                ]}
                data={(requestParams: RequestParams<CompanyFilterModel>) => transportService
                    .request<typeof requestParams & VspcTransportBaseData, CompanyRow[]>(
                        'Company/GetCompanies',
                        { disableGlobalFilters: true, ...requestParams }
                    )}
                toggleable={false}
                selection={{
                    field: 'companyInstanceUid',
                    checkbox: true,
                    multiple: true,
                }}
                onSelectionChange={(selected) => {
                    if (selected.length === 0) {
                        setApplyToDisabledState(true);
                    } else {
                        setApplyToDisabledState(false);
                    }
                }}
                paginationLimit={MAX_INTEGER_32}
                toolbars={[
                    () => (
                        <Toolbar
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='name'
                                            placeholder={lang.COMPANY_NAME}
                                            hasSearchButton={false}
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
                api={api}
            />
        </SidePanelForm>
    );
};
