/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const getIp4Validator = (lang: LangsServiceBase) => (value: string): string | void => {
    // eslint-disable-next-line max-len
    const ip4regExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (value && !ip4regExp.test(value)) {
        return lang.INVALID_IP_ADDRESS;
    }

    return;
};
