/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { AmazonObjectType } from '@veeam-vspc/models/web-controllers';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ProtectedAwsWorkloadsContext } from './contexts/ProtectedAwsWorkloadsContext';
import { ProtectedAwsWorkloadsStore } from './stores/ProtectedAwsWorkloadsStore';
import { ProtectedAwsWorkloadsService } from './services/protected-aws-workloads.service';
import { StatefulCombobox } from '../../../StatefulCombobox';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useProtectedAwsWorkloadsStore } from './hooks/use-protected-aws-workloads-store';

import type { ComboboxItem } from '../../../../interfaces';

export const ChartLayout = observer(() => {
    const lang = useLang();
    const protectedAwsWorkloadsStore = useProtectedAwsWorkloadsStore();

    const params = {
        colors: [palette.B400],
        yTitle: lang.WORKLOADS,
    };

    const title = {
        text: capitalize(lang.PROTECTED_AWS_WORKLOADS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={protectedAwsWorkloadsStore.setAmazonObjectType}
                stateId={'trends.backup-services.protected-aws-workloads.amazon-object-type'}
                textGetter={(p: ComboboxItem) => p.text}
                value={protectedAwsWorkloadsStore.amazonObjectType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: AmazonObjectType.EC2, text: lang.EC2 },
                    { id: AmazonObjectType.Rds, text: lang.RDS },
                    { id: AmazonObjectType.Efs, text: lang.EFS },
                ]}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={protectedAwsWorkloadsStore.amazonObjectType}
            params={params}
            service={ProtectedAwsWorkloadsService}
            title={title}
        />
    );
});

export const ProtectedAwsWorkloads: React.FC = () => {
    const protectedAwsWorkloadsStore = useMemo(() => new ProtectedAwsWorkloadsStore(), []);

    return (
        <ProtectedAwsWorkloadsContext.Provider value={protectedAwsWorkloadsStore}>
            <ChartLayout />
        </ProtectedAwsWorkloadsContext.Provider>
    );
};
