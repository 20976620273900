/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import { ACTION_VIEW } from '@veeam-vspc/components';

import type { GridStore } from '@veeam-vspc/components';
import type { RequestParams } from '@veeam-vspc/components/src/Grid/interfaces/request-params';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { MAX_INTEGER_32 } from 'core/const';

import type { LocationRequest } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/location-request';
import type { LocationsPanelProps } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/locations-panel-props';
import type { Location } from '../../../../interfaces';

export const LocationsPanel = ({ hidePanel, data, onDataChange }: LocationsPanelProps) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const api = useRef<GridStore<Location, any, {}>>();
    const [isApplyDisabled, setApplyToDisabledState] = useState(data.locations?.length === 0);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={lang.LOCATIONS}
            description={lang.SELECT_LOCATIONS_ACCESSIBLE_FOR_THIS_USER}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.locations = api.current.selected;

                        onDataChange(data);

                        hidePanel();
                    },
                    disabled: isApplyDisabled,
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid
                initialState={{
                    selected: data.locations,
                }}
                defaultColumnWidth={100}
                columns={[
                    { field: 'name', title: lang.LOCATION },
                    { field: 'admins', title: lang.ADMINS, cell: ({ cellData = lang.DASH_SYMBOL }) => cellData as any },
                    { field: 'users', title: lang.USERS, cell: ({ cellData = lang.DASH_SYMBOL }) => cellData as any },
                ]}
                data={(requestParams: RequestParams<{}>) => transportService
                    .request<LocationRequest, Location[]>('Location/GetLocationData', {
                        ...requestParams,
                        companyId: data.companyID,
                    })}
                toggleable={false}
                selection={{
                    field: 'id',
                    checkbox: true,
                    multiple: true,
                }}
                onSelectionChange={(selected) => {
                    if (selected.length === 0) {
                        setApplyToDisabledState(true);
                    } else {
                        setApplyToDisabledState(false);
                    }
                }}
                paginationLimit={MAX_INTEGER_32}
                api={api}
            />
        </SidePanelForm>
    );
};
