/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/components';

export const TableRowStyled = styled.div`
  display: contents;

  & > * {
    padding: ${constants.SPACING_S};
    align-self: stretch;
    display: flex;
    align-items: center;
  }

  & > *:empty {
    padding: 0;
  }

  & + & > * {
    border-top: ${constants.BASE_BORDER_WIDTH} solid ${colors.G300};
  }
`;
