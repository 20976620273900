/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { TOOLBAR_ITEM_TYPE } from '@veeam-vspc/components';
import { TransportMethods } from '@veeam-vspc/core';

import exportToIconSrc from 'images/actions/export-to.png';
import exportCsvIconSrc from 'images/actions/export-csv.png';
import exportXmlIconSrc from 'images/actions/export-xml.png';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useGridExportParams, useGridRestExportParams } from 'views/hooks';
import { AdvancedToolbar } from '../AdvancedToolbar';
import { ExportActionIds } from './enums';

import type { AdvancedToolbarProps } from '../AdvancedToolbar';
import type { GridExportProps } from 'views/hooks';

type WithMethod = {
    method?: string;
    RESTMethod?: string;
};

type ExportProps = {
    hideExport?: boolean;
};

export type ToolbarWithExportProps = Partial<AdvancedToolbarProps> & GridExportProps & WithMethod & ExportProps;

export const ToolbarWithExport: React.FC<ToolbarWithExportProps> = (props) => {
    const {
        groups = [],
        items = [],
        method,
        RESTMethod,
        extraParams,
        idProperty,
        selectedOnly,
        excludeFromExport = [],
        forceSelectedId,
        hideExport,
        ...restProps
    } = props;
    const lang = useLang();
    const { transportService } = useAppServices();
    const exportParams = useGridExportParams({ extraParams, idProperty, selectedOnly, excludeFromExport, forceSelectedId });
    const exportRestParams = useGridRestExportParams({ extraParams, idProperty, selectedOnly, excludeFromExport, forceSelectedId });

    const exportItems = [
        {
            id: ExportActionIds.ExportFillSpace,
            type: TOOLBAR_ITEM_TYPE.fillSpace,
        },
        items.length > 0
            ? {
                id: ExportActionIds.ExportSeparator,
                type: TOOLBAR_ITEM_TYPE.separator,
                removeFromContextMenu: true,
            }
            : null,
        {
            id: ExportActionIds.ExportCSV,
            type: TOOLBAR_ITEM_TYPE.button,
            groupId: ExportActionIds.ExportToGroup,
            text: lang.CSV,
            iconSrc: exportCsvIconSrc,
            onClick: () => {
                if (method) {
                    transportService.downloadFileAjax(`/CSV/${method}Csv`, exportParams);
                } else if (RESTMethod) {
                    transportService.downloadFileAjax(
                        `/${RESTMethod}csv`,
                        exportRestParams,
                        { method: TransportMethods.Get },
                        { isRest: true }
                    );
                }
            },
            removeFromContextMenu: true,
        },
        {
            id: ExportActionIds.ExportXML,
            type: TOOLBAR_ITEM_TYPE.button,
            groupId: ExportActionIds.ExportToGroup,
            text: lang.XML,
            iconSrc: exportXmlIconSrc,
            onClick: () => {
                if (method) {
                    transportService.downloadFileAjax(`/XmlExport/${method}Xml`, exportParams);
                } else if (RESTMethod) {
                    transportService.downloadFileAjax(
                        `/${RESTMethod}xml`,
                        exportRestParams,
                        { method: TransportMethods.Get },
                        { isRest: true }
                    );
                }
            },
            removeFromContextMenu: true,
        },
    ]
        .filter(item => item);

    return (
        <AdvancedToolbar
            {...restProps}
            groups={hideExport ? groups : groups.concat([
                {
                    id: ExportActionIds.ExportToGroup,
                    title: lang.EXPORT_TO,
                    iconSrc: exportToIconSrc,
                },
            ])}
            items={hideExport ? items : items.concat(exportItems)}
        />
    );
};
