/**
 * Copyright © Veeam Software Group GmbH.
 */


import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { core } from 'core/core-module';

import type { IsItemExistsParams } from 'core/interfaces';

export class CommonService {
    static isItemExist(reqData: IsItemExistsParams): Promise<boolean> {
        return core.transportService.request<IsItemExistsParams, boolean>('Common/CheckItemExist', reqData)
            .then(({ data }: BaseSuccessRequestResponse<boolean>) => data);
    }
}
