/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';

import { Bell } from './components';
import { AppBellContext, AppBellStore } from './stores';
import { useAppServices, useAppStore } from '../../providers/AppProvider/hooks';
import { useLang } from '../../providers/LangProvider/hooks';

export const AppBell: React.FC = () => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const { formats } = useAppStore();
    const store = useMemo(() => new AppBellStore(transportService, formats, lang), []);

    return (
        <AppBellContext.Provider value={store}>
            <Bell />
        </AppBellContext.Provider>
    );
};
