/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { LoginUserIcon, LoginSsoForm, AlternativeButton } from '@veeam-vspc/components';

import type { TokenManagerErrorResponse, TokenManagerResponse } from '@veeam-vspc/core';
import type { LoginSsoFormState } from '@veeam-vspc/components';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor';

export const LoginSso = observer(() => {
    const { tokenManagerService } = useAppServices();
    const lang = useLang();
    const loginStore = useLoginStore();
    const goToLoginStartButton = (
        <AlternativeButton
            iconBefore={<LoginUserIcon />}
            disabled={loginStore.isLoading}
            onClick={() => loginStore.onFinishFactorHandler({ goToLoginStart: true })}
        >
            {lang.USE_LOCAL_USERS}
        </AlternativeButton>
    );

    return (
        <BaseFactor alternativeButton={goToLoginStartButton}>
            <LoginSsoForm
                title={lang.PLEASE_LOG_IN}
                error={loginStore.error}
                loading={loginStore.isLoading}
                onChange={() => loginStore.resetMessages()}
                logIn={({ login }: LoginSsoFormState) => {
                    const trimmedLogin = login.trim();

                    loginStore.isLoading = true;

                    tokenManagerService.authorizationCode(trimmedLogin)
                        .then((resp: TokenManagerResponse) => loginStore.onFinishFactorHandler(resp))
                        .catch(({ errorDescription }: TokenManagerErrorResponse) => {
                            loginStore.isLoading = false;
                            loginStore.error = errorDescription || lang.FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT;
                        });
                }}
                texts={{
                    placeholderLogin: lang.EMAIL,
                    actionLogIn: lang.LOG_IN,
                    errorRequired: lang.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: lang.THE_MAXIMUM_LENGTH_IS,
                }}
            />
        </BaseFactor>
    );
});
