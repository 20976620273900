/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import {
    Search,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    SortingMode,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type {
    WizardStep,
    WizardStepData,
    GridStore,
    GridColumnProps,
} from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { ReportAggregationModeRepresentation } from '../../enums';
import { useReportStore } from '../../hooks';
import { MAX_INTEGER_32 } from 'core/const';

import type { LocationModel, ReportModel } from '../../interfaces';


export const getLocationsStep = (title: string): WizardStep<ReportModel> => ({
    title,
    validate: ({ data, stepData }) => data.parameters.locations.length > 0 && stepData.isAllCompaniesUsed,
    isHidden: ({ data }) => data.parameters.aggregationMode !== ReportAggregationModeRepresentation.SingleCompany,
    render: data => <LocationsStep {...data} />,
});

const LocationsStep = ({ data, onDataChange, validationState, isEdit, stepData }: WizardStepData<ReportModel>) => {
    const lang = useLang();
    const [staticObject] = useState({
        updateIsAllCompaniesUsed: () => {},
    });
    const [locationsList, setLocationsList] = useState<LocationModel[]>([]);
    const api = useRef<GridStore<LocationModel, any, any>>();
    const { portalUser } = useAppStore();
    const reportStore = useReportStore();
    const companies = reportStore.companies;
    const buildCompaniesAsResponse = (): Promise<BaseSuccessRequestResponse<any>> => {
        let locations;

        if (api.current && api.current.filters.name) {
            const filterName = api.current.filters.name.toLowerCase();
            locations = locationsList.filter(company => company.name.toLowerCase().indexOf(filterName) > -1);
        } else {
            locations = locationsList;
        }

        return Promise.resolve({
            data: locations,
            meta: {
                pagingInfo: {
                    total: locations.length,
                },
            },
        } as BaseSuccessRequestResponse<LocationModel[]>);
    };

    staticObject.updateIsAllCompaniesUsed = () => {
        const selectedLocations = locationsList.filter(location => data.parameters.locations.includes(location.id));

        let isAllCompaniesUsed = true;
        companies.forEach((companyId) => {
            isAllCompaniesUsed = isAllCompaniesUsed && selectedLocations.some(location => location.company === companyId);
        });

        stepData.isAllCompaniesUsed = isAllCompaniesUsed;
    };

    useEffect(() => {
        core.transportService.request('Location/GetLocations', {
            companyIds: companies,
            limit: MAX_INTEGER_32,
            page: 1,
            start: 0,
        })
            .then((resp: BaseSuccessRequestResponse<LocationModel[]>) => {
                setLocationsList(resp.data);
            });
    }, []);

    useEffect(() => {
        if (locationsList.length > 0) {
            api.current.reloadGrid();
            if (data.parameters.locations) {
                api.current.selected = data.parameters.locations.map(id => ({ id: id })) as LocationModel[];
            }

            staticObject.updateIsAllCompaniesUsed();
        }
    }, [locationsList]);

    useEffect(() => {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            const title = `${isEdit ? lang.EDIT : lang.NEW} ${lang.REPORT}`;

            if (data.parameters.locations.length === 0) {
                core.notificationService.warning(title, lang.PLEASE_SELECT_AT_LEAST_ONE_LOCATION_REPORT);
            } else if (!stepData.isAllCompaniesUsed) {
                core.notificationService.warning(title, lang.SELECT_AT_LEAST_ONE_LOCATION);
            }
        }
    });

    useEffect(() => {
        if (api.current && reportStore.locationFilter) {
            api.current.filters.name = reportStore.locationFilter;
            reportStore.locationFilter = '';
        }
    });
    useEffect(() => () => reportStore.locationFilter = api.current.filters.name, []);

    const columns: GridColumnProps<LocationModel>[] = [];

    if (!portalUser.isCompanyOwner()) {
        columns.push({ field: 'companyName', title: lang.COMPANY });
    }

    columns.push({ field: 'name', title: lang.LOCATION });

    return (
        <StepLayout
            title={capitalize(lang.LOCATIONS)}
            description={lang.SELECT_LOCATIONS_TO_CREATE}
        >
            <AdvancedGrid
                columns={columns}
                data={buildCompaniesAsResponse}
                disableAutoUpdate={true}
                paginationLimit={MAX_INTEGER_32}
                selection={{
                    field: 'id',
                    multiple: true,
                    checkbox: true,
                }}
                toolbars={[
                    () => (
                        <Toolbar
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () => (
                                        <Search
                                            name='name'
                                            placeholder={lang.LOCATION}
                                            hasSearchButton={false}
                                        />
                                    ),
                                },
                            ]}
                        />
                    ),
                ]}
                onSelectionChange={(selected) => {
                    data.parameters.locations = selected.map(selectedLocations => selectedLocations.id);
                    staticObject.updateIsAllCompaniesUsed();
                    onDataChange(data);
                }}
                api={api}
                sortingMode={SortingMode.Local}
            />
        </StepLayout>
    );
};
