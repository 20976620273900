/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette } from '@veeam-vspc/components';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { SobrUsageService } from './services/sobr-usage.service';
import { useLang } from 'views/providers/LangProvider/hooks';

export const SobrUsage: React.FC = () => {
    const lang = useLang();

    const params = {
        colors: [palette.B400, palette.Grn200, palette.B250, palette.T500, '#834799'],
        yTitle: lang.USAGE,
    };
    return (
        <BaseTrendChart
            params={params}
            service={SobrUsageService}
            title={{ text: capitalize(lang.STORAGE_USAGE) }}
        />
    );
};
