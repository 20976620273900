/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormValidator,
    FormLayout,
    isValid,
    useExternalFormApi,
    NoLabelGroup,
    LinkButton,
    Separator,
} from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/use-page-wizard-store';
import { capitalize, formatStr } from '@veeam-vspc/core';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import settingsIcon from 'images/actions/settings.svg';
import { PerMonthField } from 'components/controls/PerMonthField';
import { PerBytesField } from 'components/controls/PerBytesField';
import { FormNote } from 'components/controls/FormNote';
import { PublicCloudAdvanced } from './components/PublicCloudAdvanced';

import type { SubscriptionPlanModel } from 'core/interfaces';

export const stepValidate = (data: SubscriptionPlanModel) => {
    const validator = new FormValidator(data);

    validator
        .validate('managedCloudVmPrice')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('publicCloudFileSharePrice')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('publicCloudDatabasePrice')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('publicCloudBackupSpacePrice')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('publicCloudArchiveSpacePrice')
        .number()
        .min(0)
        .max(1000000);

    return validator.result();
};

export const getPublicCloudStep = (title: string): WizardStep<SubscriptionPlanModel> => ({
    title,
    validate: ({ data, stepData, isEdit }) => isValid(stepValidate, data),
    render: data => <PublicCloudStep {...data} />,
});

const PublicCloudStep = (props: WizardStepData<SubscriptionPlanModel>) => {
    const lang = useLang();
    const { data, validationState, onDataChange } = props;
    const formApi = useExternalFormApi();
    const wizardContext = usePageWizardStore();
    const showPanel = () => wizardContext.openPanel(hide => <PublicCloudAdvanced {...props} hide={hide} />);
    const currencyPerInstance = formatStr(lang.CURRENCY_PER_INSTANCE_PER_MONTH, data.currency);
    const currencyPer = formatStr(lang.CURRENCY_PER, data.currency);
    const currencyPerVm = formatStr(lang.CURRENCY_PER_VM_PER_MONTH, data.currency);

    return (
        <StepLayout
            title={capitalize(lang.PUBLIC_CLOUD_BACKUP)}
            description={lang.DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_PUBLIC}
        >
            <Form
                value={data}
                validate={stepValidate}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <PerMonthField
                        name='managedCloudVmPrice'
                        label={lang.CLOUD_VM}
                        suffix={currencyPerVm}
                    />

                    <PerMonthField
                        name='publicCloudFileSharePrice'
                        label={lang.CLOUD_FILE_SHARE}
                        suffix={currencyPerInstance}
                    />

                    <PerMonthField
                        name='publicCloudDatabasePrice'
                        label={lang.CLOUD_DATABASE}
                        suffix={currencyPerInstance}
                    />

                    <PerBytesField
                        label={`${lang.BACKUP_STORAGE}:`}
                        valueName={'publicCloudBackupSpacePrice'}
                        unitsName={'publicCloudBackupSpaceMultiplier'}
                        suffix={currencyPer}
                    />

                    <PerBytesField
                        label={`${lang.ARCHIVE_STORAGE}:`}
                        valueName={'publicCloudArchiveSpacePrice'}
                        unitsName={'publicCloudArchiveSpaceMultiplier'}
                        suffix={currencyPer}
                    />

                    <Separator />

                    <NoLabelGroup
                        content={(
                            <div>
                                <LinkButton
                                    onClick={showPanel}
                                    iconBefore={settingsIcon}
                                >
                                    {`${lang.ADVANCED_SETTINGS}...`}
                                </LinkButton>

                                <FormNote>{lang.IN_THE_ADVANCED_SETTINGS_YOU_CAN_CONFIGURE_THE_NUMBER_OF_WORKLOADS}</FormNote>
                            </div>
                        )}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
};
