/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { ChangePasswordForm } from '@veeam-vspc/components';

import type { BaseFailedRequestResponse, BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { ChangePasswordFormState } from '@veeam-vspc/components';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor';

import type { ChangePasswordData, ChangePasswordFactor } from '../../interfaces';

export const ChangePassword = observer(() => {
    const { transportService } = useAppServices();
    const lang = useLang();
    const loginStore = useLoginStore();
    const { code } = loginStore.currentFactor as ChangePasswordFactor;

    return (
        <BaseFactor width={'491px'}>
            <ChangePasswordForm
                title={lang.PLEASE_ENTER_A_NEW_PASSWORD}
                error={loginStore.error}
                loading={loginStore.isLoading}
                logIn={({ newPassword }: ChangePasswordFormState) => {
                    loginStore.isLoading = true;

                    transportService.request<ChangePasswordData, {}>(
                        '/User/CompleteResetPasswordRequest',
                        { newPassword, code },
                        null,
                        { noAuth: true, notShowDefaultError: true }
                    )
                        .then(() => loginStore.onFinishFactorHandler({
                            goToLoginStart: true,
                            info: lang.THE_NEW_PASSWORD_HAS_BEEN_SET,
                        }))
                        .catch((resp) => {
                            const failedResp = resp as BaseFailedRequestResponse;
                            const successResp = resp as BaseSuccessRequestResponse;

                            loginStore.isLoading = false;
                            loginStore.error = failedResp['errors'][0]['message'] || successResp.data && successResp.data['message'];
                        });
                }}
                onChange={() => loginStore.resetMessages()}
                texts={{
                    labelNewPassword: lang.NEW_PASSWORD,
                    labelRepeatPassword: lang.CONFIRM_PASSWORD,
                    actionLogIn: lang.SAVE,
                    errorRequired: lang.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: lang.THE_MAXIMUM_LENGTH_IS,
                    errorMatchingPasswords: lang.PASSWORDS_DO_NOT_MATCH,
                }}
            />
        </BaseFactor>
    );
});
