/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import { useLang } from 'views/providers/LangProvider/hooks';
import { useCompanyWizardStore } from '../../../../../../hooks';
import { WanAcceleration } from 'views/components/WanAcceleration';

import type { ReplicaResource } from 'core/interfaces';

interface Props {
    deactivate: () => void;
    onConfirm: (data: ReplicaResource) => void;

    data?: ReplicaResource;
    existingItems: ReplicaResource[];
    cloudConnectAgentUid: string;
}

const formValidate = (data: ReplicaResource, lang: any, isEdit: boolean, existingItems: ReplicaResource[]) => {
    const validator = new FormValidator(data);

    validator.validate('cloudConnectResourceUid')
        .string()
        .required()
        .check((value => existingItems.find(item => item.cloudConnectResourceUid === value)
            ? lang.CANNOT_ADD_THE_SELECTED_HARDWARE_PLAN
            : ''))
        .check((value => existingItems.length > 0 && !existingItems.find(item => item.platform === data.platform)
            ? lang.YOU_CANNOT_ADD_DIFFERENT_TYPES
            : '')
        );

    if (data.isWanAccelerationEnabled) {
        validator.validate('wanAcceleratorUid')
            .string()
            .required();
    }

    return validator.result();
};

export const ResourceDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;

    const defaultData: ReplicaResource = {
        cloudConnectResourceName: '',
        isWanAccelerationEnabled: false,
        cloudConnectResourceUid: null,
        hostname: null,
        rootHostname: null,
        cpuUsageLimitMhz: 0,
        ramUsageLimitMb: 0,
        storageGb: 0,
    };

    const isEdit = !!props.data;
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.cloudConnectResourceUid === props.data.cloudConnectResourceUid), 1);
        }
        return result;
    });
    const [filteredAvailableReplicaResources, setFilteredAvailableReplicaResources] = useState<ReplicaResource[]>([]);
    const [data, setData] = useState(props.data ?? defaultData);

    const formApi = useExternalFormApi<ReplicaResource>();
    const [loading, setLoading] = useState(false);
    const [validationState] = useState(new ValidationState());

    const wizardStore = useCompanyWizardStore();

    useEffect(() => {
        setLoading(wizardStore.availableReplicaResourcesCache.loading);
        wizardStore.availableReplicaResourcesCache.load({ cloudConnectAgentUid: props.cloudConnectAgentUid })
            .then((items) => {
                setFilteredAvailableReplicaResources(items.filter(item => !existingItems
                    .find(existingItem => existingItem.cloudConnectResourceUid === item.cloudConnectResourceUid)
                ));
                setLoading(false);
            });

        wizardStore.wanAcceleratorsCache.load({ cloudConnectAgentUid: props.cloudConnectAgentUid });
    }, []);

    return (
        <Dialog
            header={`${isEdit ? lang.EDIT : lang.ADD} ${lang.REPLICATION_RESOURCE}`}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            props.onConfirm && props.onConfirm(formApi.value);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.s,
                height: DIALOG_SIZE.auto,
            }}
            loading={loading}
        >
            <Form <ReplicaResource>
                value={data}
                validate={data => formValidate(data, lang, isEdit, existingItems)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'cloudConnectResourceUid'}
                        label={capitalize(RCOP.Lang.HARDWARE_PLAN, true)}
                        placeholder={lang.SELECT_A_HARDWARE_PLAN}
                        data={filteredAvailableReplicaResources}
                        valueGetter={item => item.cloudConnectResourceUid}
                        textGetter={item => item.cloudConnectResourceName}
                        size={defaultFieldWidth}
                        onChange={(value, itemIndex) => {
                            const item = filteredAvailableReplicaResources[itemIndex];
                            formApi.setValue('cloudConnectResourceName', item.cloudConnectResourceName);
                            formApi.setValue('platform', item.platform);
                            formApi.setValue('hostname', item.hostname);
                        }}
                    />

                    <WanAcceleration
                        value={data}
                        wanAccelerators={wizardStore.wanAcceleratorsCache.value}
                        hidden={!wizardStore.isWanAcceleratorsEnabled}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
});
