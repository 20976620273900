/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Icon, StackView, Text, ICON_SIZES, STACK_ALIGN, STACK_GAP, TEXT_SIZE } from '@veeam-vspc/components';

import warningIcon from 'images/warning.svg';

interface WarningsColumnProps {
    count: number;
}

export const WarningsColumn: React.FC<WarningsColumnProps> = ({ count }) => (
    <StackView gap={STACK_GAP.s} align={STACK_ALIGN.center}>
        <Icon src={warningIcon} size={ICON_SIZES.xl} />
        <Text size={TEXT_SIZE.xl}>{count}</Text>
    </StackView>
);
