/**
 * Copyright © Veeam Software Group GmbH.
 */

import { addParamsToQuery } from '@veeam-vspc/core/utils/index';

import { EventEmitter } from '../../utils';
import { GlobalFilterEvents } from './enums';

import type { GlobalFiltersData, RestGlobalFiltersData } from './interfaces';
import type { VspcTransportBaseData } from '../transport';
import type { PortalUser } from '../../entries';

export class GlobalFiltersService {

    protected portalUser: PortalUser | null = null;
    protected globalFilters: GlobalFiltersData = {};

    protected eventEmitter = new EventEmitter();

    setPortalUser(portalUser: PortalUser): void {
        this.portalUser = portalUser;
    }

    updateFilters(filters: GlobalFiltersData): void {
        if (filters === undefined) {
            return;
        }

        const previousGlobalFilter = JSON.stringify(this.globalFilters);

        this.globalFilters = filters;

        if (previousGlobalFilter !== JSON.stringify(filters)) {
            this.eventEmitter.dispatch(GlobalFilterEvents.Change, filters);
        }
    }

    addGlobalFiltersToUrl<T extends VspcTransportBaseData>(url: string, data: T, rest?: boolean) {
        const isAdmin: boolean = this.portalUser?.isPortalOrSiteAdmin() ?? false;
        let globalFilters;

        if (data && data.disableGlobalFilters) {
            globalFilters = {};
            delete data.disableGlobalFilters;
        } else {
            // need for working on non-React grids
            globalFilters = rest
                ? RCOP.globalFilters.getRestSubmitValues()
                : RCOP.globalFilters.getSubmitValues(); // @TODO Remove when all grids became react grids
        }

        if (!isAdmin && globalFilters?.ccsFilter) {
            // additionally delete a ccsFilter parameter if we aren't an admin
            delete globalFilters?.ccsFilter;
        }

        return addParamsToQuery(url, rest ? this.prepareToQuery(globalFilters) : globalFilters);
    }

    prepareToQuery(filters: GlobalFiltersData): RestGlobalFiltersData {
        const result: RestGlobalFiltersData = {};

        if (filters.ccsFilter !== undefined) {
            result.siteFilter = filters.ccsFilter.join();
        }

        if (filters.companyFilter !== undefined) {
            result.organizationFilter = filters.companyFilter;
        }

        if (filters.locationFilter !== undefined) {
            result.locationFilter = filters.locationFilter;
        }

        return result;
    }

    onFiltersChange(cb: (filters: GlobalFiltersData) => void): () => void {
        return this.eventEmitter.subscribe(GlobalFilterEvents.Change, cb, cb);
    }
}


