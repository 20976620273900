/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import {
    colors,
    constants,
} from '@veeam-vspc/components';

export const PlateWidgetStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.G000};
  height: 178px;
  padding: ${constants.SPACING_M};
  transition: 0.3s;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.backgrounds.hover};
    cursor: pointer;
  }
`;
