/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { ColumnTexts, DurationColumn, GridColumnJustify, GridSortDirections, TextColumn } from '@veeam-vspc/components';

import type { CellRendererProps, RequestParams } from '@veeam-vspc/components';
import type { BackupServerJobFilter, BackupServerJobModel } from '@veeam-vspc/models/web-controllers';
import type { BaseRequestResponse, BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { EJobStates, JobTypes } from 'core/enums';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { ConfigSectionIds } from 'core/services/configurator/enums';
import { BackupStatusColumn } from '../../../BackupStatusColumn';
import {
    JobStatisticsColumn,
    ProcessedVmsColumn,
    VirtualInfrastructureActionToolbar,
    VirtualInfrastructureFilterToolbar,
} from './components';
import { CdpErrorsColumn } from './components/CdpErrorsColumn';

import type { VspcTransportBaseData } from 'core/services';

export interface VirtualInfrastructureProps {
    stateId?: string;
}

export const VirtualInfrastructure: React.FC<VirtualInfrastructureProps> = ({ stateId }) => {
    const lang = useLang();
    const { transportService, enumMapperService } = useAppServices();
    const [existsVbrShell, setExistsVbrShell] = useState(false);
    const gridStateId = `${stateId}.VirtualInfrastructure`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<BackupServerJobModel>) => (
        <TextColumn defaultValue={lang.NO_INFO}>{cellData}</TextColumn>
    );
    const columns = [
        {
            field: 'jobName',
            title: lang.JOB,
            cell: defaultColumnRender,
        },
        {
            field: 'companyName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'serverName',
            title: lang.SERVER,
            cell: defaultColumnRender,
        },
        {
            field: 'lastSessionState',
            title: lang.JOB_STATUS,
            cell: ({ rowData }: CellRendererProps<BackupServerJobModel>) => (
                <BackupStatusColumn
                    statusName={rowData.lastSessionState as keyof typeof EJobStates}
                    lastSessionStartTime={rowData.lastSessionStartTime}
                    lastSessionEndTime={rowData.lastSessionEndTime}
                    failureMessage={rowData.failureMessage}
                    lastSessionUid={rowData.lastSessionUid}
                />
            ),
        },
        {
            field: 'jobTypeName',
            title: lang.TYPE,
            cell: defaultColumnRender,
        },
        {
            field: 'virtualPlatformType',
            title: lang.PLATFORM,
            cell: ({ rowData }: CellRendererProps<BackupServerJobModel>) =>
                <TextColumn>{enumMapperService.getEnumDescription('virtualPlatformTypeRepresentation', rowData.virtualPlatformType)}</TextColumn>,
            hidden: true,
        },
        {
            field: 'lastSessionStartTime',
            title: lang.LAST_RUN,
            cell: ({ cellData }: CellRendererProps<BackupServerJobModel>) => (
                <TimeAgoColumn
                    value={cellData}
                />
            ),
        },
        {
            field: 'lastSessionEndTime',
            title: lang.END_TIME,
            cell: ({ cellData }: CellRendererProps<BackupServerJobModel>) => (
                <GridDateColumn
                    value={cellData}
                />
            ),
            hidden: true,
        },
        {
            field: 'lastSessionDuration',
            title: lang.DURATION,
            cell: ({ cellData }: CellRendererProps<BackupServerJobModel>) => (
                <DurationColumn
                    value={cellData as any}
                />
            ),
        },
        {
            field: 'avgDuration',
            title: lang.AVG_DURATION,
            cell: ({ cellData }: CellRendererProps<BackupServerJobModel>) => (
                <DurationColumn
                    value={cellData as any}
                />
            ),
            hidden: true,
        },
        {
            field: 'destination',
            title: lang.TARGET,
            cell: ({ cellData, rowData }: CellRendererProps<BackupServerJobModel>) => (
                <TextColumn>
                    {rowData.jobType === JobTypes.SureBackup ? ColumnTexts.DashSymbol : cellData}
                </TextColumn>
            ),
            hidden: true,
        },
        {
            field: 'jobStatistics',
            title: lang.JOB_STATISTICS,
            cell: ({ rowData }: CellRendererProps<BackupServerJobModel>) => {
                if (rowData.jobType === JobTypes.SureBackup) {
                    return (
                        <TextColumn>
                            { ColumnTexts.DashSymbol }
                        </TextColumn>
                    );
                }

                return (
                    <JobStatisticsColumn
                        jobType={rowData.jobType}
                        jobUid={rowData.jobInstanceUid}
                        jobName={rowData.jobName}
                    />
                );
            },
            isSortable: false,
        },
        {
            field: 'cdpSessionErrorsCounter',
            title: lang.CDP_SESSION_ERRORS,
            cell: ({ rowData }) => (
                <CdpErrorsColumn
                    errorsCount={rowData.cdpSessionErrorsCounter}
                    stateId={gridStateId}
                    jobName={rowData.jobName}
                    jobUid={rowData.jobInstanceUid}
                />
            ),
            justify: GridColumnJustify.Right,
        },
        {
            field: 'processedVmCounter.total',
            title: lang.PROCESSED_OBJECTS,
            width: 200,
            cell: ({ rowData }: CellRendererProps<BackupServerJobModel>) => (
                <ProcessedVmsColumn
                    value={rowData.processedVmCounter}
                    stateId={gridStateId}
                    jobType={rowData.jobType}
                    jobUid={rowData.jobInstanceUid}
                    jobName={rowData.jobName}
                />
            ),
        },
        {
            field: 'processingRate',
            title: lang.PROCESSING_RATE,
            cell: ({ cellData, rowData }: CellRendererProps<BackupServerJobModel>) => (
                <RestorePointSizeColumn
                    value={rowData.jobType === JobTypes.SureBackup ? null : cellData as any}
                    unitName={'B/s'}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
            hidden: true,
        },
        {
            field: 'transferred',
            title: lang.TRANSFERRED_DATA,
            cell: ({ cellData, rowData }: CellRendererProps<BackupServerJobModel>) => (
                <RestorePointSizeColumn
                    value={rowData.jobType === JobTypes.SureBackup ? null : cellData as any}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'bottleneck',
            title: lang.BOTTLENECK,
            cell: ({ cellData, rowData }: CellRendererProps<BackupServerJobModel>) => (
                <TextColumn>{rowData.jobType === JobTypes.SureBackup ? null : cellData}</TextColumn>
            ),
            hidden: true,
        },
        {
            field: 'jobScheduleState',
            title: lang.SCHEDULE,
            cell: ({ cellData, rowData }: CellRendererProps<BackupServerJobModel>) => (
                <TextColumn defaultValue={lang.NO_INFO}>
                    {rowData.lastSessionStateId === EJobStates.Idle ? null : cellData}
                </TextColumn>
            ),
            hidden: true,
        },
    ];

    return (
        <AdvancedGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructure}
            defaultColumnWidth={100}
            initialState={{
                sort: {
                    key: 'lastSessionStateId',
                    direction: GridSortDirections.Asc,
                },
            }}
            columns={columns}
            data={(requestParams: RequestParams<BackupServerJobFilter>) => (
                Promise.all([
                    transportService.request<typeof requestParams, BackupServerJobModel[]>('/BackupServerJob/GetJobs', {
                        ...requestParams,
                    }),
                    transportService.request<VspcTransportBaseData, BaseRequestResponse<boolean>>('/BackupServer/ExistsVbrShell', {
                        rcopAutoUpdate: true,
                    }),
                ])
                    .then(([resp, existsVbrShellResp]) => {
                        setExistsVbrShell((existsVbrShellResp as BaseSuccessRequestResponse).data as boolean);

                        return resp;
                    })
            )}
            isRowInactive={({ rowData }) => !rowData.scheduleEnabled}
            toggleable={true}
            selection={{
                field: 'jobInstanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <VirtualInfrastructureFilterToolbar />,
                () => (
                    <VirtualInfrastructureActionToolbar
                        idProperty={'jobInstanceUid'}
                        existsVbrShell={existsVbrShell}
                        selectedOnly
                        excludeFromExport={[
                            'jobStatistics',
                        ]}
                    />
                ),
            ]}
        />
    );
};
