/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { constants, NoteBar } from '@veeam-vspc/components';

export const NoteBarStyled = styled(NoteBar)`
    p {
      margin: 0;
    }

    p + p {
      margin-top: ${constants.SPACING_M};
    }
`;
