/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import {
    Form,
    FormLayout,
    useExternalFormApi,
} from '@veeam-vspc/components';

import type { WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { stepValidate } from '../../helpers/stepValidate';
import { PortalUserRoles } from 'core/entries/portal-user/enums';

import type { CompanyUserModel } from '../../../../../CompanyUser/interfaces';


interface LoginInfoFormProps extends WizardStepData<CompanyUserModel> {
    children: React.ReactElement[];
}

export const LoginInfoForm = ({ data, validationState, onDataChange, isEdit, stepData, children }: LoginInfoFormProps) => {
    const lang = useLang();
    const formApi = useExternalFormApi<CompanyUserModel>();
    const isVcdSubtenant = data.isVcdCompany && data.userRole === PortalUserRoles.Subtenant;
    const loginInfoDescription = isVcdSubtenant ? `${lang.SPECIFY_LOGIN_INFORMATION}.` : lang.SPECIFY_LOGIN_AND_PASSWORD;

    useEffect(() => {
        if (stepData.isLoginExist) {
            formApi.setExternalErrors({
                login: RCOP.Lang.THIS_LOGIN_IS_ALREADY_USED,
            });

            delete stepData.isLoginExist;
        }
    });

    return (
        <Form
            value={data}
            validate={data => stepValidate(data, isEdit)}
            validationState={validationState}
            onChange={onDataChange}
            key='loginInfo'
            externalFormApi={formApi}
        >
            <StepLayout
                title={lang.LOGIN_INFO}
                description={loginInfoDescription}
            >
                <FormLayout inlineLabel>
                    {children}
                </FormLayout>
            </StepLayout>
        </Form>
    );
};
