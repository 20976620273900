/**
 * Copyright © Veeam Software Group GmbH.
 */

import { deepCopy, formatStr } from '@veeam-vspc/core';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { renderShortDateAndTimeForColumn } from '@veeam-vspc/components';

import { PageWizard } from 'components/wizards/PageWizard';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { getData } from 'core/services/transport/utils';
import { CloudTenantType } from 'core/enums/cloud-tenant-type';
import { getBandwidthStep } from './components/Bandwidth';
import { getBillingStep } from './components/Billing';
import { getCompanyInfoStep } from './components/CompanyInfo';
import { getCompanyTypeStep } from './components/CompanyType';
import { getMultiFactorStep } from './components/MultiFactor';
import { getNotificationsStep } from './components/Notifications';
import { getServicesStep } from './components/Services';
import { getSummaryStep } from './components/Summary';
import { getUserInfoStep } from './components/UserInfo';
import { getVcdUserInfoStep } from './components/VcdUserInfo';
import { DEFAULT_DATA_TRANSFER_GB } from 'core/const';
import { CompanyStatus } from './enums';
import { CompanyMfaStatuses, CloudGatewaySelectionType, UserTitleRepresentation } from 'core/enums';
import { CompanyWizardStore, CompanyWizardStoreContext } from './stores';
import { syncHwPlansToNetwork, syncVboInfrastructure, syncVcdNetworksExtensions } from './utils';
import { AllowedBandwidthUnits } from 'views/components/wizard/enums';
import { DEFAULT_BV365_USER_QOUTA } from './consts';

import type { PortalUser } from 'core/entries';
import type { CompanyData } from './interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { PortalFormats } from 'core/entries/portal-data/interfaces';

export interface CompanyWizardProps {
    onFinish: () => void;
    data?: CompanyData;
    isMfaPolicyEnforced?: boolean;
}

const DEFAULT_NUMBER_OF_PUBLIC_IP = 1;
const DEFAULT_MAX_CONCURRENT_TASK = 1;

const getDefaultData = (isMfaPolicyEnforced: boolean, lang: LangsServiceBase, formats: PortalFormats, user: PortalUser): CompanyData => ({
    allowedBandwidth: 1,
    allowedBandwidthUnits: AllowedBandwidthUnits.MbytePerSec,
    cloudConnectAgentUid: null,
    companyStatus: CompanyStatus.Unknown,
    country: 0,
    dataTransferGb: DEFAULT_DATA_TRANSFER_GB,
    description: formatStr(
        lang.CREATED_BY_COMPANY_NAME,
        user.companyName,
        renderShortDateAndTimeForColumn(new Date(), formats.netShortDate, formats.netShortTime)
    ),
    expirationEnabled: false,
    gatewayPool: [],
    gatewaySelectionType: CloudGatewaySelectionType.StandaloneGateways,
    isAllowedToSwitchMfaPolicyStatus: !isMfaPolicyEnforced,
    maxConcurrentTask: DEFAULT_MAX_CONCURRENT_TASK,
    mfaPolicyStatus: isMfaPolicyEnforced ? CompanyMfaStatuses.Enabled : CompanyMfaStatuses.Disabled,
    name: '',
    password: '',
    quotas: [],
    subscriptionPlan: null,
    tenantType: CloudTenantType.General,
    usState: 0,
    vcdReplicationResources: [],
    networkFailoverResourcesEnabled: false,
    vcdNetworksExtensions: [],
    publicIpEnabled: false,
    numberOfPublicIp: DEFAULT_NUMBER_OF_PUBLIC_IP,
    alias: '',
    alarmDetectEnabled: false,
    title: UserTitleRepresentation.Unknown,
    bandwidthThrottlingEnabled: false,
    companyId: '',
    domain: '',
    emailAddress: '',
    notes: '',
    firstName: '',
    lastName: '',
    taxId: '',
    telephone: '',
    userName: '',
    zipCode: '',
    vboInfrastructure: {
        vboServers: [],
        vboRepositories: [],
    },
    backupProtectionEnabled: false,
    backupProtectionPeriod: 1,
    restEnabled: false,
    isMappedToPulseTenant: false,

    cloudBackupResourceEnabled: false,
    cloudReplicationResourceEnabled: false,
    backupAgentManagementEnabled: false,
    backupServerManagementEnabled: false,
    vB365IntegrationEnabled: false,
    vOneIntegrationEnabled: false,
    vbPublicCloudManagementEnabled: false,
    allowToSwitchBackupAgentManagement: true,
    allowToSwitchBackupServerManagement: true,
    allowToSwitchVbPublicCloudManagement: true,
});

const transformInputData = (inputData: CompanyData) => {
    const result = inputData;

    if (!inputData.quotas) {
        result.quotas = [];
    }

    if (!inputData.publicIpEnabled) {
        result.numberOfPublicIp = DEFAULT_NUMBER_OF_PUBLIC_IP;
    }

    if (!inputData.password) {
        result.password = '';
    }

    result.vboInfrastructure.vboRepositories.forEach((repository) => {
        if (repository.isUserQuotaUnlimited) {
            repository.userQuota = DEFAULT_BV365_USER_QOUTA;
        }
    });

    if (!inputData.maxConcurrentTask || inputData.maxConcurrentTask < DEFAULT_MAX_CONCURRENT_TASK) {
        result.maxConcurrentTask = DEFAULT_MAX_CONCURRENT_TASK;
    }

    return result;
};

const transformDataToSave = async(data: CompanyData, store: CompanyWizardStore, lang: LangsServiceBase, isEdit: boolean) => {
    const result = deepCopy(data) as CompanyData;

    if (!isEdit) {
        result.id = -1;
    }

    if (!data.cloudBackupResourceEnabled) {
        result.quotas = [];
        result.backupProtectionEnabled = false;
    }

    if (result.tenantType === CloudTenantType.General) {
        if (!data.cloudReplicationResourceEnabled) {
            result.hwPlans = [];
        }
        result.vcdReplicationResources = [];
    } else {
        if (!data.cloudReplicationResourceEnabled) {
            result.vcdReplicationResources = [];
        }
        result.hwPlans = [];
    }

    if (!data.vB365IntegrationEnabled) {
        result.vboInfrastructure.vboServers = [];
        result.vboInfrastructure.vboRepositories = [];
    }

    if (!store.dataTransferGbEnabled) {
        result.dataTransferGb = 0;
    }

    if (result.tenantType === CloudTenantType.General) {
        result.networks = syncHwPlansToNetwork(data, lang);
    } else {
        result.vcdNetworksExtensions = await syncVcdNetworksExtensions(data, store);
    }

    if (data.vB365IntegrationEnabled) {
        result.vboInfrastructure = await syncVboInfrastructure(data.vboInfrastructure, store, data.id);
    }

    // TODO: remove after quota.isStorageQuotaUnlimited support on server
    if (result.quotas) {
        result.quotas.forEach((item) => {
            if (item.quota.quotasIsUnlimited) {
                item.quota.vms = -1;
                item.quota.servers = -1;
                item.quota.workstations = -1;
            }
        });
    }

    if (!data.title) {
        result.title = UserTitleRepresentation.Unknown;
    }

    return result;
};

export const CompanyWizard = observer(({ onFinish, data, isMfaPolicyEnforced }: CompanyWizardProps) => {
    const lang = useLang();

    const isEdit = !!data;
    let title = `${isEdit ? lang.EDIT : lang.NEW} ${lang.COMPANY}`;
    if (isEdit) {
        title += ` - ${data.name}`;
    }
    const { formats, portalUser } = useAppStore();
    const currentData: CompanyData = isEdit ? transformInputData(data) : getDefaultData(isMfaPolicyEnforced, lang, formats, portalUser);
    const store = useMemo(() => new CompanyWizardStore(currentData, isEdit), []);

    // prepare init data
    if (!currentData.expirationTime) {
        currentData.expirationTime = new Date();
    }

    store.dataTransferGbEnabled = isEdit && data.dataTransferGb > 0;

    return (
        <CompanyWizardStoreContext.Provider value={store}>
            <PageWizard
                title={title}
                isEdit={isEdit}
                tabsWidth={220}
                deactivate={() => undefined}
                steps={[
                    // TODO: in developing
                    // getServicesStep(lang, store),

                    getCompanyInfoStep(lang, store),
                    getCompanyTypeStep(lang),
                    getUserInfoStep(lang, store),
                    getVcdUserInfoStep(lang, store),
                    getServicesStep(lang, store),
                    getBillingStep(lang),
                    getBandwidthStep(lang, store),
                    getMultiFactorStep(lang),
                    getNotificationsStep(lang),
                    getSummaryStep(lang),
                ]}
                data={currentData}
                onFinish={async({ data }) => {
                    // prepare data to save
                    const dataToSave = await transformDataToSave(data, store, lang, isEdit);

                    await getData('Company/SaveCompany', {
                        data: dataToSave,
                        isRequiredSendEmail: store.isRequiredSendEmail,
                        emailCustomSection: !isEdit || store.isEmailDataChanged()
                            ? {
                                customSection: store.emailData.addCustomText ? store.emailData.emailContent : null,
                                isDefault: store.emailData.isDefault,
                                isVisibleGeneralSection: store.emailData.isVisibleGeneralSection,
                            } : null,
                    });

                    onFinish();
                }}
            />
        </CompanyWizardStoreContext.Provider>
    );
});
