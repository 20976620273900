/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { AdvancedFilter, Search, TOOLBAR_ITEM_TYPE, TOOLBAR_VIEW_TYPE } from '@veeam-vspc/components';

import type { AdvancedFilterOptions, AdvancedFilterValue } from '@veeam-vspc/components';

import { ERdsBackupTypes } from 'core/enums';
import backupJobTypeIconSrc from 'images/filters/job-types/backup.png';
import snapshotJobTypeIconSrc from 'images/filters/job-types/snapshot.png';
import remoteSnapshotJobTypeIconSrc from 'images/filters/job-types/remote-snapshot.png';
import archiveJobTypeIconSrc from 'images/filters/job-types/archive.png';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useCloudPlatformTypesAdvancedFilter } from 'views/components/filters/CloudPlatformTypesFilters';
import { AdvancedToolbar } from 'views/components/AdvancedToolbar';

import type { AdvancedToolbarProps } from 'views/components/AdvancedToolbar';

export interface CloudDatabasesFilterToolbarProps extends Omit<AdvancedToolbarProps, 'items'> {
}

export const CloudDatabasesFilterToolbar: React.FC<CloudDatabasesFilterToolbarProps> = (props) => {
    const lang = useLang();
    const cloudPlatformTypesAdvancedFilter = useCloudPlatformTypesAdvancedFilter();
    const advancedFilterOptions: AdvancedFilterOptions<AdvancedFilterValue> = {
        header: `${lang.FILTER_PROTECTED_WORKLOADS_BY}:`,
        cols: [
            {
                groups: [
                    {
                        title: lang.TYPE,
                        items: [
                            {
                                iconSrc: backupJobTypeIconSrc,
                                label: lang.BACKUP,
                                value: {
                                    id: ERdsBackupTypes.Backup,
                                    name: 'backupType',
                                },
                            },
                            {
                                iconSrc: snapshotJobTypeIconSrc,
                                label: lang.SNAPSHOT,
                                value: {
                                    id: ERdsBackupTypes.Snapshot,
                                    name: 'backupType',
                                },
                            },
                            {
                                iconSrc: remoteSnapshotJobTypeIconSrc,
                                label: lang.REPLICA_SNAPSHOT,
                                value: {
                                    id: ERdsBackupTypes.RemoteSnapshot,
                                    name: 'backupType',
                                },
                            },
                            {
                                iconSrc: archiveJobTypeIconSrc,
                                label: lang.ARCHIVE,
                                value: {
                                    id: ERdsBackupTypes.Archive,
                                    name: 'backupType',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    cloudPlatformTypesAdvancedFilter,
                ],
            },
        ],
    };

    return (
        <AdvancedToolbar
            {...props}
            view={TOOLBAR_VIEW_TYPE.spotted}
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <Search
                            name='vmName'
                            placeholder={lang.INSTANCE}
                            hasSearchButton={true}
                        />
                    ),
                },
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <AdvancedFilter
                            label={lang.FILTER}
                            options={advancedFilterOptions}
                        />
                    ),
                },
            ]}
        />
    );
};
