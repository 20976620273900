/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { Form, FormLayout, isValid, PasswordInput, TextInput, useExternalFormApi } from '@veeam-vspc/components';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { stepValidate } from './helpers';
import { useLang } from 'views/providers/LangProvider/hooks';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { VbrDeploySettingsWizardModel } from '../../entries';

const Account = (wizardStepData: WizardStepData<VbrDeploySettingsWizardModel>) => {
    const lang = useLang();
    const { data, onDataChange, validationState } = wizardStepData;
    const formApi = useExternalFormApi<VbrDeploySettingsWizardModel>();

    return (
        <StepLayout
            title={lang.SERVICE_ACCOUNT}
            description={lang.SELECT_AN_ACCOUNT}
        >
            <Form
                value={data}
                validate={stepValidate(lang)}
                validationState={validationState}
                onChange={onDataChange}
                externalFormApi={formApi}
            >
                <FormLayout inlineLabel>
                    <TextInput
                        name='adminUserName'
                        label={lang.USERNAME}
                    />

                    <PasswordInput
                        name='adminPassword'
                        label={lang.PASSWORD}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
};

export const getAccountStep = (lang: LangsServiceBase): WizardStep<VbrDeploySettingsWizardModel> => ({
    title: lang.ACCOUNT,
    validate: ({ data }) => isValid(stepValidate(lang), data),
    render: data => <Account {...data} />,
});
