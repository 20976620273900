/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { formatStr } from '@veeam-vspc/core';
import {
    Dialog,
    DIALOG_SIZE,
    GridColumnJustify,
    GridSortDirections,
    NoteBar,
    NOTEBAR_STATUS,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    ParsedText,
} from '@veeam-vspc/components';

import type { CellRendererProps } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { ProtectedVirtualMachineRestoreTypes } from 'core/enums';
import { getRestorePointsHelpResource } from 'core/utils';
import { useAppServices, useAppStore } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { MAX_INTEGER_32 } from 'core/const';

import type {
    VirtualInfrastructureRestorePointModel,
    VirtualInfrastructureRestorePointRequestParams,
    VirtualInfrastructureRestorePointsExtraParams,
} from './interfaces';

const {
    Backup,
    Copy,
    Tape,
} = ProtectedVirtualMachineRestoreTypes;

export interface VirtualInfrastructureRestorePointProps {
    stateId: string;
    name: string;
    protectionType: ProtectedVirtualMachineRestoreTypes;
    extraParams: VirtualInfrastructureRestorePointsExtraParams;
    deactivate: () => void;
    isOneBackupPerVmEnabled: boolean;
}

export const VirtualInfrastructureRestorePointDialog = (props: VirtualInfrastructureRestorePointProps) => {
    const { stateId, name, protectionType, extraParams, deactivate, isOneBackupPerVmEnabled } = props;
    const lang = useLang();
    const { portalUser } = useAppStore();
    const { transportService } = useAppServices();
    const isNoteBarShown = !isOneBackupPerVmEnabled && [Backup, Copy, Tape].includes(protectionType);

    const columns = [
        {
            field: 'creationDate',
            title: lang.DATE,
            cell: ({ cellData }: CellRendererProps<VirtualInfrastructureRestorePointModel>) => <GridDateColumn value={cellData} />,
        },
        {
            field: 'usedSourceSize',
            title: lang.SOURCE_SIZE,
            cell: ({ cellData }: CellRendererProps<VirtualInfrastructureRestorePointModel>) => (
                <RestorePointSizeColumn value={cellData as any} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'incrementRawDataSize',
            title: lang.BACKED_UP_DATA,
            cell: ({ cellData }: CellRendererProps<VirtualInfrastructureRestorePointModel>) => (
                <RestorePointSizeColumn value={cellData as any} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'size',
            title: lang.RESTORE_POINT_SIZE,
            cell: ({ cellData }: CellRendererProps<VirtualInfrastructureRestorePointModel>) => (
                <RestorePointSizeColumn value={cellData as any} />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
    ];

    return (
        <Dialog
            header={`${lang.RESTORE_POINTS} - ${name}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                {isNoteBarShown && (
                    <NoteBar status={NOTEBAR_STATUS.info} style={{ backgroundColor: RCOP.utils.Colors.Y50 }}>
                        <ParsedText textOrConfig={formatStr(lang.TO_SEE_BACKED_UP_DATA, getRestorePointsHelpResource(portalUser.userRole))} />
                    </NoteBar>
                )}

                <AdvancedGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Desc,
                            key: columns?.[0]?.field,
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={columns.filter(item => item)}
                    data={(requestParams: VirtualInfrastructureRestorePointRequestParams<{}>) => transportService
                        .request<typeof requestParams, VirtualInfrastructureRestorePointModel[]>(
                            '/ProtectedWorkloads/GetVmsBackupRestorePoints',
                            {
                                ...requestParams,
                                ...extraParams,
                            }
                        )}
                    toggleable={false}
                    selection={{
                        field: 'instanceUid',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                    toolbars={[
                        () => (
                            <ToolbarWithExport
                                idProperty={'instanceUid'}
                                method={'GetProtectedVmBackupRestorePoints'}
                                extraParams={extraParams}
                            />
                        ),
                    ]}
                />
            </StackView>
        </Dialog>
    );
};
