/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    EPrincipalAccessTypeRepresentation,
    EPrincipalObjectTypeRepresentation,
    EPrincipalRoleTypeRepresentation,
    EPrincipalTypeRepresentation,
} from '@veeam-vspc/models/web-controllers';

import type { PrincipalObject, WinPrincipalModel, WinPrincipalParam } from '@veeam-vspc/models/web-controllers';
import type { WizardStep } from '@veeam-vspc/components';


import { PageWizard } from 'components/wizards/PageWizard';
import { saveUser } from 'views/pages/RolesAndUsersPage/components/Base/helpers/saveUser';
import { useLang } from 'views/providers/LangProvider/hooks';
import { getAccountStep } from './components/Account';
import { getDomainStep } from './components/Domain';
import { getRoleStep } from './components/Role';
import { getSummaryStep } from './components/Summary';
import { ManagedCompaniesMode } from './enums';
import { getCompaniesStep } from './components/Companies';

import type { AdminUserProps, AdminUserModel } from './interfaces';

export const AdminUser = (props: AdminUserProps) => {
    const lang = useLang();
    const defaultModel: WinPrincipalModel = {
        roleType: EPrincipalRoleTypeRepresentation.Administrator,
        type: EPrincipalTypeRepresentation.WinNTUser,
    };
    const title = props.isEdit ? lang.EDIT_WINDOWS_USER : lang.NEW_WINDOWS_USER;

    return (
        <PageWizard
            title={title}
            deactivate={() => {}}
            isEdit={props.isEdit}
            data={{
                model: props.isEdit ? props.model : defaultModel,
                userName: props.userName,
                password: props.password,
                selectedCompanies: props.selectedCompanies || [],
                selectAllCompanies: props.selectAllCompanies ? ManagedCompaniesMode.AllCompanies : ManagedCompaniesMode.CustomSelection,
            } as AdminUserModel}
            steps={[
                getDomainStep(lang.DOMAIN),
                getAccountStep(lang.ACCOUNT),
                getRoleStep(lang.ROLE),
                getCompaniesStep(lang.COMPANIES),
                getSummaryStep(lang.SUMMARY),
            ] as WizardStep<AdminUserModel>[]}
            onFinish={({ data }) => {
                const principalObject: PrincipalObject[] = [];

                if (data.selectAllCompanies === ManagedCompaniesMode.AllCompanies) {
                    principalObject.push({
                        accessType: EPrincipalAccessTypeRepresentation.FullAccess,
                        id: -1,
                        type: EPrincipalObjectTypeRepresentation.All,
                    });
                } else {
                    data.selectedCompanies.forEach(company => principalObject.push({
                        accessType: EPrincipalAccessTypeRepresentation.FullAccess,
                        id: company.id,
                        type: EPrincipalObjectTypeRepresentation.Company,
                    }));
                }

                const requestParams: WinPrincipalParam = {
                    principal: {
                        ...data.model,
                        enabled: props.isEdit ? data.model.enabled : true,
                        principalObject,
                    },
                };

                return saveUser({ props, requestParams, areAdminRoles: true });
            }}
        />
    );
};
