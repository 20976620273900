/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, MessageBox, ParsedText, TextColumn } from '@veeam-vspc/components';

import type { ActionLinkColumnProps, DialogAction, ParsedTextProps } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { getStatusIconSrc, getStatusTitle } from 'core/utils';

export interface StatusColumnProps extends ActionLinkColumnProps {
    status: string;
    statusIconSrc?: string;
    isTextHidden?: boolean;
    message?: ParsedTextProps['textOrConfig'];
    actions?: DialogAction[];
    isCorrectStatus?: boolean;
    messageBoxTitle?: string;
}

export const StatusColumn: React.FC<StatusColumnProps> = (props) => {
    const lang = useLang();
    const { status, statusIconSrc, isTextHidden, message, actions, isCorrectStatus = false, messageBoxTitle, ...restProps } = props;
    const iconSrc = statusIconSrc ?? getStatusIconSrc(status);
    const title = isCorrectStatus ? status : getStatusTitle(lang, status);

    return (
        <ActionLinkColumn
            {...restProps}
            contentIconBefore={iconSrc}
            renderContent={() => (isTextHidden ? '' : <TextColumn style={{ color: 'inherit' }}>{title}</TextColumn>)}
            isLink={message?.length > 0}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <MessageBox
                    header={messageBoxTitle || title}
                    onRequestClose={deactivate}
                    actions={actions ?? [
                        {
                            text: lang.OK, onClick: deactivate,
                        },
                    ]}
                    iconSrc={iconSrc}
                >
                    <ParsedText textOrConfig={message} />
                </MessageBox>
            )}
        />
    );
};
