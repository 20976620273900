/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { RadioGroup } from 'components/controls/RadioGroup';
import { CloudTenantType } from 'core/enums/cloud-tenant-type';
import { allowEditVcd } from '../../utils';

import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';
import type { CompanyData } from '../../interfaces';

export const getCompanyTypeStep = (lang: LangsServiceBase): WizardStep<CompanyData> => ({
    title: capitalize(lang.COMPANY_TYPE),
    render: stepData => <CompanyTypeStep {...stepData} />,
    isHidden: stepModel => stepModel.isEdit && !allowEditVcd(stepModel.data.companyStatus),
});

const CompanyTypeStep = ({ data, validationState, onDataChange }: WizardStepData<CompanyData>) => {
    const lang = useLang();

    return (
        <StepLayout
            title={capitalize(lang.COMPANY_TYPE)}
            description={`${lang.SELECT_TYPE_FOR_THE_NEW_COMPANY}:`}
        >
            <RadioGroup
                value={data.tenantType}
                onChange={(value: CloudTenantType) => {
                    data.tenantType = value;
                    onDataChange(data);
                }}
                buttons={[
                    {
                        label: lang.NATIVE,
                        value: CloudTenantType.General,
                        helpText: lang.THIS_OPTION_IS_RECOMMENDED_IF_YOU,
                    },
                    {
                        label: lang.VMWARE_CLOUD_DIRECTOR,
                        value: CloudTenantType.Vcd,
                        helpText: lang.THIS_OPTION_REQUIRES_INTEGRATION,
                    },
                ]}
            />
        </StepLayout>
    );
};
