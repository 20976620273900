/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { Grid } from '@veeam-vspc/components';
import { ServerEventType } from '@veeam-vspc/models/rest';

import type { RefObject } from 'react';
import type { GridProps, GridStore } from '@veeam-vspc/components';

import { useConfiguredGrid, useGlobalFiltersReload } from './hooks';
import { useAppServices } from '../../providers/AppProvider/hooks';
import { WebSocketConnections } from 'core/enums';

import type { ConfigSectionIds } from 'core/services/configurator/enums';

export interface AdvancedGridProps<RowDataT, TotalRowDataT, FilterT> extends GridProps<RowDataT, TotalRowDataT, FilterT> {
    sectionId?: ConfigSectionIds;
    eventMapName?: string;
    eventAsyncActionMapName?: string;
    isRowExcludedFromAllSelection?: (rowData: RowDataT) => boolean;
}

export const AdvancedGrid = <RowDataT, TotalRowDataT, FilterT>(props: AdvancedGridProps<RowDataT, TotalRowDataT, FilterT>): React.ReactElement => {
    const {
        sectionId,
        eventMapName = ServerEventType.AsyncActionRemovedEvent,
        eventAsyncActionMapName,
        columns,
        api,
        data,
        isRowExcludedFromAllSelection,
        ...restProps
    } = props;
    const { transportService } = useAppServices();
    const configuredColumns = useConfiguredGrid<RowDataT, TotalRowDataT, FilterT>(sectionId, columns);
    const gridApi = useGlobalFiltersReload<RowDataT, TotalRowDataT, FilterT>(api) as RefObject<GridStore<RowDataT, TotalRowDataT, FilterT>>;

    useEffect(() => {
        const headerSelectionChangeHandler = (handler: Function) => (checked: boolean) => {
            const initialSelectionState = gridApi.current.selected.length;
            handler(checked);
            const actualSelectionState = gridApi.current.selected.length;

            handleSelectionChange(checked, initialSelectionState, actualSelectionState);
        };

        gridApi.current.onHeaderSelectionChange = headerSelectionChangeHandler(gridApi.current.onHeaderSelectionChange);
    }, [gridApi.current]);

    // @TODO: move to grid store
    const handleSelectionChange = (checked: boolean, initialSelectionState: number, actualSelectionState: number) => {
        if (!isRowExcludedFromAllSelection) {
            return;
        }

        if (!checked) {
            return;
        }

        if (initialSelectionState === actualSelectionState) {
            gridApi.current.deselectAll();

            return;
        }

        const actualSelection = gridApi.current.selected.filter(x => !isRowExcludedFromAllSelection(x));
        gridApi.current.selected = actualSelection;
        gridApi.current.selection.isIndeterminate = actualSelection.length > 0 && actualSelection.length < gridApi.current.pageData.length;
        gridApi.current.selection.isSelectedAll = actualSelection.length === gridApi.current.pageData.length;
    };

    if (eventMapName && eventAsyncActionMapName) {
        transportService.subscribeToWs(WebSocketConnections.NotificationBell, (message: string) => {
            try {
                const { type, content } = JSON.parse(message);

                if (type === eventMapName && content?.actionName === eventAsyncActionMapName) {
                    gridApi.current?.reloadGrid();
                }
            } catch (e) {
                console.error('Failed to parse web socket message', e, message);
            }
        });
    }

    return (
        <Grid
            {...restProps}
            data={requestParams => data(requestParams)}
            columns={configuredColumns}
            api={gridApi}
        />
    );
};
