/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef } from 'react';
import { capitalize, deepClone } from '@veeam-vspc/core';
import { GridSortDirections, TextColumn } from '@veeam-vspc/components';

import type { BackupAgentJobContainerFilter, BackupAgentJobContainerModel, BackupAgentParam } from '@veeam-vspc/models/web-controllers';
import type { CellRendererProps, GridColumnProps, GridStore } from '@veeam-vspc/components';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { CustomNoteColumn } from 'views/components/columns/CustomNoteColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { UpdatesColumn } from 'views/components/columns/UpdatesColumn';
import { ConfigSectionIds } from 'core/services/configurator/enums';
import {
    BackupPolicyColumn,
    ManagedByConsoleActionToolbar,
    ManagedByConsoleFilterToolbar,
    RunningJobsColumn,
    SuccessfulJobsColumn,
} from './components';


export interface ManagedByConsoleProps {
    stateId?: string;
    extController: any;
}

export const ManagedByConsole: React.FC<ManagedByConsoleProps> = ({ stateId, extController }) => {
    const { transportService, enumMapperService } = useAppServices();
    const lang = useLang();
    const api = useRef<GridStore<BackupAgentJobContainerModel, unknown, BackupAgentJobContainerFilter>>();
    const gridStateId = `${stateId}.ManagedByConsole`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<BackupAgentJobContainerModel>) => (
        <TextColumn defaultValue={lang.NO_INFO}>{cellData}</TextColumn>
    );
    const columns: GridColumnProps<BackupAgentJobContainerModel>[] = [
        {
            field: 'company',
            title: lang.COMPANY,
            cell: defaultColumnRender,
            getAdditionalSorters: currentSort => [{ ...currentSort, property: 'name' }],
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'location',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'name',
            title: lang.COMPUTER,
            cell: defaultColumnRender,
        },
        {
            field: 'agentCustomNote',
            title: lang.TAG,
            cell: ({ cellData, rowData }: CellRendererProps<BackupAgentJobContainerModel>) => (
                <CustomNoteColumn
                    value={cellData}
                    agentUid={rowData.agentId}
                />
            ),
        },
        {
            field: 'operationModeRendered',
            title: lang.OPERATION_MODE,
            cell: defaultColumnRender,
        },
        {
            field: 'guestOs',
            title: lang.GUEST_OS,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'jobsStatus',
            title: lang.SUCCESSFUL_JOBS,
            cell: ({ rowData }: CellRendererProps<BackupAgentJobContainerModel>) => (
                <SuccessfulJobsColumn
                    successJobsCount={rowData.successJobsCount}
                    totalJobsCount={rowData.totalJobsCount}
                    jobsStatus={rowData.jobsStatus}
                    onClick={() => {
                        // @TODO move to React
                        // call of 'blur' method is added in order to save a common behavior of the column links in the project (losing focus after closing dialogs).
                        // maybe it'll be necessary to change this behavior in the next versions (v.8.0, v.9.0, etc.)

                        const rowDataForExt = deepClone(rowData);
                        enumMapperService.replaceEnumsInData('BackupAgentJobContainerModel', rowDataForExt);

                        const dialog = Ext.create('RCOP.view.home.backupJobs.oldComputers.managedByConsole.dialogs.agentJobs.AgentJobs', {
                            record: {
                                ...rowDataForExt,
                                get: prop => rowDataForExt[prop],
                            },
                        });

                        dialog.open(() => {}, () => {
                            api?.current.reloadGrid();
                            (document.activeElement as HTMLElement)?.blur();
                        });
                    }}
                />
            ),
        },
        {
            field: 'runningJobsCount',
            title: lang.RUNNING_JOBS,
            cell: ({ rowData }: CellRendererProps<BackupAgentJobContainerModel>) => (
                <RunningJobsColumn
                    runningJobsCount={rowData.runningJobsCount}
                    totalJobsCount={rowData.totalJobsCount}
                    onClick={() => {
                        // @TODO move to React
                        // call of 'blur' method is added in order to save a common behavior of the column links in the project (losing focus after closing dialogs).
                        // maybe it'll be necessary to change this behavior in the next versions (v.8.0, v.9.0, etc.)

                        const rowDataForExt = deepClone(rowData);
                        enumMapperService.replaceEnumsInData('BackupAgentJobContainerModel', rowDataForExt);

                        const dialog = Ext.create('RCOP.view.home.backupJobs.oldComputers.managedByConsole.dialogs.agentJobs.AgentJobs', {
                            record: {
                                ...rowDataForExt,
                                get: prop => rowDataForExt[prop],
                            },
                        });

                        dialog.open(() => {}, () => {
                            api?.current.reloadGrid();
                            (document.activeElement as HTMLElement)?.blur();
                        });
                    }}
                />
            ),
        },
        {
            field: 'backupPolicyStr',
            title: lang.BACKUP_POLICY,
            cell: ({ cellData, rowData }: CellRendererProps<BackupAgentJobContainerModel>) => (
                <BackupPolicyColumn
                    value={cellData}
                    commonVawTemplateStatus={rowData.commonVawTemplateStatus}
                    multipleJobs={rowData.multipleJobs}
                    defaultValue={lang.NO_INFO}
                    onClick={() => {
                        // @TODO move to React
                        // call of 'blur' method is added in order to save a common behavior of the column links in the project (losing focus after closing dialogs).
                        // maybe it'll be necessary to change this behavior in the next versions (v.8.0, v.9.0, etc.)

                        const rowDataForExt = deepClone(rowData);
                        enumMapperService.replaceEnumsInData('BackupAgentJobContainerModel', rowDataForExt);

                        const dialog = Ext.create('RCOP.view.home.backupJobs.oldComputers.managedByConsole.dialogs.agentJobs.AgentJobs', {
                            record: {
                                ...rowDataForExt,
                                get: prop => rowDataForExt[prop],
                            },
                        });

                        dialog.open(() => {}, () => {
                            api?.current.reloadGrid();
                            (document.activeElement as HTMLElement)?.blur();
                        });
                    }}
                />
            ),
        },
        {
            field: 'isReadOnlyMode',
            title: capitalize(lang.UI_MODE),
            cell: ({ cellData }: CellRendererProps<BackupAgentJobContainerModel>) => (
                <TextColumn>
                    {capitalize(cellData ? lang.READ_ONLY : lang.FULL_ACCESS)}
                </TextColumn>
            ),
        },
        {
            field: 'vawPlatformRendered',
            title: lang.PLATFORM,
            cell: ({ cellData }: CellRendererProps<BackupAgentJobContainerModel>) => <TextColumn>{cellData || lang.N_A}</TextColumn>,
            hidden: true,
        },
        {
            field: 'disabled',
            title: lang.SCHEDULE,
            cell: ({ cellData }: CellRendererProps<BackupAgentJobContainerModel>) => (
                <TextColumn>
                    {cellData ? lang.DISABLED : lang.ENABLED}
                </TextColumn>
            ),
            hidden: true,
        },
        {
            field: 'lastModified',
            title: lang.LAST_MODIFIED,
            cell: ({ cellData }: CellRendererProps<BackupAgentJobContainerModel>) => (
                <GridDateColumn value={cellData} />
            ),
            hidden: true,
        },
        {
            field: 'lastActivity',
            title: lang.LAST_ACTIVITY,
            cell: ({ cellData }: CellRendererProps<BackupAgentJobContainerModel>) => (
                <GridDateColumn value={cellData} />
            ),
        },
        {
            field: 'displayVersion',
            title: lang.BACKUP_AGENT_VERSION,
            cell: defaultColumnRender,
            hidden: true,
        }, {
            field: 'vawUpgradeStatus',
            title: lang.AVAILABLE_UPDATES,
            hidden: true,
            cell: ({ rowData }: CellRendererProps<BackupAgentJobContainerModel>) => (
                <UpdatesColumn
                    upgradeStatus={rowData.vawUpgradeStatus}
                    upgradeVersion={rowData.vawUpgradeVersion}
                />
            ),
        },
    ];

    return (
        <AdvancedGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.BackupJobsComputersManagedByConsole}
            defaultColumnWidth={100}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.field,
                },
            }}
            api={api}
            columns={columns}
            data={(requestParams: BackupAgentParam) => transportService
                .request<BackupAgentParam, BackupAgentJobContainerModel[]>('/BackupAgent/GetBackupAgents', { ...requestParams })}
            isRowInactive={({ rowData }) => rowData.disabled}
            toggleable={true}
            selection={{
                field: 'agentId',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <ManagedByConsoleFilterToolbar />,
                () => <ManagedByConsoleActionToolbar idProperty={'agentId'} extController={extController} selectedOnly />,
            ]}
        />
    );
};
