/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts, isPositiveNumber } from '@veeam-vspc/components';

import {
    VirtualInfrastructureRestorePointDialog,
} from '../VirtualInfrastructureRestorePointDialog';

import type {
    VirtualInfrastructureRestorePointProps,
} from '../VirtualInfrastructureRestorePointDialog';

export interface VirtualInfrastructureRestorePointColumnProps extends Omit<VirtualInfrastructureRestorePointProps, 'deactivate'> {
    value: string | number | null;
}

export const VirtualInfrastructureRestorePointColumn = ({ value, ...restProps }: VirtualInfrastructureRestorePointColumnProps) => (
    <ActionLinkColumn
        content={isPositiveNumber(value) ? value : ColumnTexts.DashSymbol}
        isLink={isPositiveNumber(value)}
        isExtMode={true}
        renderModal={({ deactivate }) => (
            <VirtualInfrastructureRestorePointDialog
                {...restProps}
                deactivate={deactivate}
            />
        )}
    />
);

