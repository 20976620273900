/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { CompanyWizardStoreContext } from '../stores';

import type { CompanyWizardStore } from '../stores';

export const useCompanyWizardStore = (): CompanyWizardStore => useContext(CompanyWizardStoreContext);
