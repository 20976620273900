/**
 * Copyright © Veeam Software Group GmbH.
 */

import { ColumnTexts, dataSizeToString } from '@veeam-vspc/components';

import type { Vb365BackupRepository } from '@veeam-vspc/models/rest';

import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const usedSpaceMessage = (lang: LangsServiceBase, repo?: Vb365BackupRepository) => {
    if (repo) {
        if (repo.capacityBytes) {
            const usedMsg = dataSizeToString(repo.capacityBytes - repo.freeSpaceBytes, 1);
            const totalMsg = dataSizeToString(repo.capacityBytes, 1);

            return `${usedMsg} ${lang.OF} ${totalMsg}`;
        }

        if (Number.isInteger(repo.usedSpaceBytes)) {
            const usedMsg = dataSizeToString(repo.usedSpaceBytes, 1);

            return usedMsg;
        }
    }

    return ColumnTexts.DashSymbol;
};
