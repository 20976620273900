/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';

import { PluginLibrary } from './components';

export const PLUGIN_LIBRARY_PAGE = 'PluginLibrary';

export const PluginLibraryPage: React.FC = props => (
    <PluginLibrary {...props} />
);
