/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    GridSortDirections,
    STACK_DIRECTION,
    STACK_GAP,
    StackView, TextColumn,
} from '@veeam-vspc/components';

import type { CellRendererProps } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { MAX_INTEGER_32 } from 'core/const';

import type { BackupServerFileJobSourceItem } from '../../interfaces';

export interface SourceItemsDialogProps {
    stateId: string;
    name: string;
    sourceItems: BackupServerFileJobSourceItem[];
    deactivate: () => void;
}

const getResponseByItems = items => ({
    data: items,
    meta: {
        pagingInfo: {
            total: items.length,
        },
    },
} as any);

export const SourceItemsDialog = ({ stateId, name, sourceItems, deactivate }: SourceItemsDialogProps) => {
    const lang = useLang();
    const columns = [
        {
            field: 'path',
            title: lang.BACKED_UP_ITEMS,
            cell: ({ cellData }: CellRendererProps<BackupServerFileJobSourceItem>) => <TextColumn>{cellData}</TextColumn>,
        },
        {
            field: 'fileMasks',
            title: lang.APPLIED_MASK,
            cell: ({ cellData }: CellRendererProps<BackupServerFileJobSourceItem>) => <TextColumn>{cellData}</TextColumn>,
        },
    ];

    return (
        <Dialog
            header={`${name} - ${lang.SOURCE_ITEMS}`}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <AdvancedGrid
                    stateId={stateId}
                    initialState={{
                        sort: {
                            direction: GridSortDirections.Asc,
                            key: columns?.[0]?.field,
                        },
                    }}
                    defaultColumnWidth={100}
                    columns={columns}
                    data={() => Promise.resolve(getResponseByItems(sourceItems))}
                    toggleable={false}
                    selection={{
                        field: 'path',
                        checkbox: false,
                        multiple: false,
                    }}
                    paginationLimit={MAX_INTEGER_32}
                />
            </StackView>
        </Dialog>
    );
};
