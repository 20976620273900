/**
 * Copyright © Veeam Software Group GmbH.
 */

/* eslint-disable @typescript-eslint/no-duplicate-imports */

import React, { useState } from 'react';
import { PortalSpinner, TOOLBAR_ITEM_TYPE, toolbarItemDecorators, useGridApi } from '@veeam-vspc/components';
import { EPublicCloudRestoreType } from '@veeam-vspc/models/web-controllers';

import type { GetVbRestoreLinkParams, PublicCloudProtectedVmModel, PublicCloudProtectedVmParam } from '@veeam-vspc/models/web-controllers';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';

import { useLang } from 'views/providers/LangProvider/hooks';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import restoreTypeIconSrc from 'images/actions/restore.svg';
import instanceRestoreTypeIconSrc from 'images/actions/instance-restore.svg';
import volumeRestoreTypeIconSrc from 'images/actions/volume-restore.svg';
import fileLevelRecoveryTypeIconSrc from 'images/actions/file-level-recovery.svg';
import { useAppServices, useAppStore } from 'views/providers/AppProvider/hooks';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';

export interface PublicCloudsActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items'> {
    method?: string;
}

export const PublicCloudsActionToolbar: React.FC<PublicCloudsActionToolbarProps> = (props) => {
    const { method = 'GetPublicCloudVm', ...restProps } = props;
    const lang = useLang();
    const { transportService } = useAppServices();
    const { portalUser } = useAppStore();
    const isReadOnlyUser = portalUser.isReadOnlyRole();
    const [loading, setLoading] = useState(false);
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSingleSelection(),
    ];
    const gridApi = useGridApi<PublicCloudProtectedVmModel, unknown, PublicCloudProtectedVmParam['filter']>();
    const vmRestoreGroupId = 'vmRestoreGroupId';
    const getLink = (restoreType: EPublicCloudRestoreType) => {
        const { selected } = gridApi;

        setLoading(true);

        transportService.request<GetVbRestoreLinkParams, string>('/VbPlugin/GetRestoreLink', {
            uid: selected[0].instanceUid,
            vbServerId: selected[0].vbServerId,
            restoreType,
            backUrl: window.location.href,
        })
            .then((resp: BaseSuccessRequestResponse<string>) => {
                setLoading(false);
                window.open(resp.data as string, '_self');
            })
            .catch((err) => {
                if (err) {
                    console.error(err);
                }
                setLoading(false);
            });
    };

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                method={method}
                groups={[
                    {
                        iconSrc: restoreTypeIconSrc,
                        title: lang.RESTORE,
                        id: vmRestoreGroupId,
                        hidden: isReadOnlyUser,
                    },
                ]}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: instanceRestoreTypeIconSrc,
                        text: lang.INSTANCE_RESTORE,
                        onClick: () => getLink(EPublicCloudRestoreType.RestoreVmInstance),
                        groupId: vmRestoreGroupId,
                        decorators: defaultDisabledDecorators,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: volumeRestoreTypeIconSrc,
                        text: lang.VOLUME_RESTORE,
                        onClick: () => getLink(EPublicCloudRestoreType.RestoreVmVolume),
                        groupId: vmRestoreGroupId,
                        decorators: defaultDisabledDecorators,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: fileLevelRecoveryTypeIconSrc,
                        text: lang.FILE_LEVEL_RECOVERY,
                        onClick: () => getLink(EPublicCloudRestoreType.RestoreVmFileLevel),
                        groupId: vmRestoreGroupId,
                        decorators: defaultDisabledDecorators,
                    },
                ]}
            />

            {loading && <PortalSpinner />}
        </>
    );
};
