/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import { ComboboxKit, constants, FieldLayout } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';

import { useJobsOverviewStore } from '../../../../store';
import { useLang } from 'views/providers/LangProvider/hooks';
import { InfrastructureType } from '../../../../enums';

export const InfrastructureFilter: React.FC = observer(() => {
    const jobsOverviewStore = useJobsOverviewStore();
    const lang = useLang();

    return (
        <FieldLayout
            label={`${lang.INFRASTRUCTURE}:`}
        >
            <ComboboxKit
                size={constants.CONTROL_SIZE_XXM}
                value={jobsOverviewStore.infrastructureType}
                data={[
                    { id: InfrastructureType.All, name: lang.ALL },
                    { id: InfrastructureType.Cloud, name: lang.CLOUD },
                    { id: InfrastructureType.Local, name: lang.LOCAL },
                ]}
                valueGetter={item => item.id}
                textGetter={item => item.name}
                onChange={jobsOverviewStore.changeInfrastructureType}
            />
        </FieldLayout>
    );
});
