/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useMemo } from 'react';
import { createLinkControl } from '@veeam/components';
import { capitalize } from '@veeam-vspc/core';
import { colors as palette, CONTROL_SIZE } from '@veeam-vspc/components';
import { observer } from 'mobx-react-lite';
import { ManagedAgentMode } from '@veeam-vspc/models/web-controllers';

import { BaseTrendChart } from '../../../BaseTrendChart';
import { ManagedBackupAgentsContext } from './contexts/ManagedBackupAgentsContext';
import { ManagedBackupAgentsService } from './services/managed-backup-agents.service';
import { ManagedBackupAgentsStore } from './stores/ManagedBackupAgentsStore';
import { StatefulCombobox } from '../../../StatefulCombobox';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useManagedBackupAgentsStore } from './hooks/use-managed-backup-agents-store';

import type { ComboboxItem } from '../../../../interfaces';

export const ChartLayout = observer(() => {
    const lang = useLang();
    const managedAgentsStore = useManagedBackupAgentsStore();

    const params = {
        colors: [palette.B400, palette.Grn200],
        yTitle: lang.AGENTS,
    };

    const title = {
        text: capitalize(lang.MANAGED_BACKUP_AGENTS),
        tools: (
            <StatefulCombobox
                controlRenderer={createLinkControl()}
                onChange={managedAgentsStore.setManagedBackupAgentType}
                stateId={'trends.backup-services.managed-backup-agents.managed-backup-agent-type'}
                textGetter={(p: ComboboxItem) => p.text}
                value={managedAgentsStore.managedBackupAgentType}
                valueGetter={(p: ComboboxItem) => p.id}
                data={[
                    { id: ManagedAgentMode.All, text: lang.ALL },
                    { id: ManagedAgentMode.ByVSPC, text: lang.MANAGED_BY_CONSOLE },
                    { id: ManagedAgentMode.ByVBR, text: lang.MANAGED_BY_BACKUP_SERVER },
                ]}
                size={CONTROL_SIZE.m}
            />
        ),
    };

    return (
        <BaseTrendChart
            objectsType={managedAgentsStore.managedBackupAgentType}
            params={params}
            service={ManagedBackupAgentsService}
            title={title}
        />
    );
});

export const ManagedBackupAgents: React.FC = () => {
    const managedBackupAgentsStore = useMemo(() => new ManagedBackupAgentsStore(), []);

    return (
        <ManagedBackupAgentsContext.Provider value={managedBackupAgentsStore}>
            <ChartLayout />
        </ManagedBackupAgentsContext.Provider>
    );
};
