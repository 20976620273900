/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum LicenseRepresentations {
    Unknown = 0,
    Server = 1,
    Workstation = 2,
    Limited = 3,
}
