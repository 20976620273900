/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW,
    Dialog,
    DIALOG_SIZE,
    SortingMode,
    TextColumn,
} from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { GridStore } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { ActionsToolbar } from './components/ActionsToolbar';

import type { PublicIp } from '../../../../../../interfaces';
import type { BaseResponse } from 'core/services/transport/interfaces/base-response';

interface Props {
    deactivate: () => void;

    cloudConnectAgentUid: string;
}

export const PublicIpAddressesDialog = observer((props: Props) => {
    const lang = useLang();
    const gridApi = useRef<GridStore<PublicIp, void, void>>();
    const [loading, setLoading] = useState(false);

    const [items] = useState<PublicIp[]>([]);

    useEffect(() => {
        core.transportService
            .request<{ agentId: string; }, BaseResponse<PublicIp>>('CloudPublicIP/GetIPs', { agentId: props.cloudConnectAgentUid })
            .then((resp) => {
                items.push(...resp['data']);
                gridApi.current.reloadGrid();
            });
    }, []);

    return (
        <Dialog
            header={lang.NETWORK_SETTINGS}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        setLoading(true);
                        core.transportService
                            .request<{ agentId: string; ips: PublicIp[]; }, {}>('CloudPublicIP/UpdateIPs', {
                                agentId: props.cloudConnectAgentUid,
                                ips: items.map(item => ({ address: item.address, tenantUid: item.tenantUid })),
                            })
                            .then(() => {
                                setLoading(false);
                                props.deactivate();
                            });
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.xl,
            }}
            loading={loading}
        >
            <AdvancedGrid <PublicIp, void, void>
                defaultColumnWidth={100}
                columns={[
                    { field: 'address', title: capitalize(lang.IP_ADDRESS) },
                    {
                        field: 'tenantName',
                        title: lang.COMPANY,
                        cell: ({ rowData }) => (
                            <TextColumn
                                defaultValue={lang.NONE}
                            >
                                {rowData.tenantName}
                            </TextColumn>
                        ),
                    },
                ]}
                data={() => Promise.resolve({
                    data: items,
                    meta: {
                        pagingInfo: {
                            total: items?.length,
                        },
                    },
                } as BaseSuccessRequestResponse<PublicIp[]>)}
                toggleable={false}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'address',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => <ActionsToolbar items={items} />,
                ]}
                api={gridApi}
                sortingMode={SortingMode.Local}
            />
        </Dialog>
    );
});
