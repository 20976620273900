/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Search, Toolbar, TOOLBAR_ITEM_TYPE } from '@veeam-vspc/components';

import type { WizardStepData } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { MAX_INTEGER_32 } from 'core/const';
import { CloudTenantType } from 'core/enums';

import type { CompanyUserModel } from 'views/pages/RolesAndUsersPage/components/CompanyUser/interfaces/company-user-model';

export interface CompanyRow {
    id: number;
    name: string;
    siteName: string;
    tenantType: CloudTenantType;
    cloudConnectServerUid: string;
}

export interface CompanyFilterModel {
    name: string;
}

export const CompanyGrid = ({ data, onDataChange }: WizardStepData<CompanyUserModel>) => {
    const lang = useLang();
    const { transportService } = useAppServices();

    return (
        <AdvancedGrid
            columns={[
                { field: 'name', title: lang.COMPANY },
                { field: 'siteName', title: lang.SITE },
            ]}
            initialState={{
                selected: data.companyID ? [{ id: data.companyID } as CompanyRow] : undefined,
                filters: {
                    name: data.companyFilterInitValue,
                },
            }}
            data={requestParams => transportService.request<typeof requestParams & { disableGlobalFilters: boolean; }, CompanyRow[]>(
                'Company/GetCompanies',
                { disableGlobalFilters: true, ...requestParams }
            )}
            toggleable={false}
            selection={{
                field: 'id',
                multiple: false,
                checkbox: false,
            }}
            onSelectionChange={(selected) => {
                const selectedItem = selected[0];
                data.companyID = selectedItem?.id;
                data.cloudAgentUid = selectedItem?.cloudConnectServerUid;

                if (selectedItem?.tenantType !== undefined) {
                    data.isVcdCompany = selectedItem?.tenantType === CloudTenantType.Vcd;
                }

                onDataChange(data);
            }}
            paginationLimit={MAX_INTEGER_32}
            toolbars={[
                () => (
                    <Toolbar
                        items={[
                            {
                                type: TOOLBAR_ITEM_TYPE.customControl,
                                render: () => (
                                    <Search
                                        name='name'
                                        placeholder={lang.COMPANY_NAME}
                                        hasSearchButton={false}
                                    />
                                ),
                            },
                        ]}
                    />
                ),
            ]}
        />
    );
};
