/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';
import { deepCopy } from '@veeam-vspc/core';


import { useLang } from 'views/providers/LangProvider/hooks';
import { IpAddressField } from 'components/controls/IpAddressField';
import { getIp4Validator } from 'core/utils/validators';

import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { PublicIp } from '../../../../../../../../interfaces';

interface Props {
    deactivate: () => void;
    onConfirm: (data: PublicIp) => void;

    data?: PublicIp;
    existingItems: PublicIp[];
}

const formValidate = (data: PublicIp, lang: LangsServiceBase, existingItems: PublicIp[]) => {
    const validator = new FormValidator(data);

    validator.validate('address')
        .string()
        .required()
        .check(getIp4Validator(lang))
        .check((value => existingItems.find(item => item.address === value)
            ? lang.SPECIFIED_IP_IS_ALREADY_ASSIGNED
            : ''));

    return validator.result();
};

const getDefaultData = (): PublicIp => ({
    address: '',
    tenantName: '',
    tenantUid: null,
});

export const PublicAddressDialog: React.FC<Props> = (props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;

    const isEdit = !!props.data;
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.address === props.data.address), 1);
        }
        return result;
    });
    const [data, setData] = useState<PublicIp>(isEdit ? deepCopy(props.data) : getDefaultData());

    const formApi = useExternalFormApi<PublicIp>();
    const [validationState] = useState(new ValidationState());

    return (
        <Dialog
            header={lang.PUBLIC_IP_ADDRESS}
            description={lang.SPECIFY_PUBLIC_IP_ADDRESS}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            props.onConfirm && props.onConfirm(formApi.value);
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.auto,
            }}
        >
            <Form <PublicIp>
                value={data}
                validate={data => formValidate(data, lang, existingItems)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <IpAddressField
                        name={'address'}
                        size={defaultFieldWidth}
                    />
                </FormLayout>
            </Form>
        </Dialog>
    );
};
