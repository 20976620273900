/**
 * Copyright © Veeam Software Group GmbH.
 */

import { capitalize } from '@veeam-vspc/core';
import { PublicCloudVmRestoreType } from '@veeam-vspc/models/web-controllers';

const {
    Backup,
    Snapshot,
    RemoteSnapshot,
    Archive,
    Tape,
    Copy,
} = PublicCloudVmRestoreType;

const getDialogTitle = (type: PublicCloudVmRestoreType, lang: Record<string, string>): string => {
    let title = '';

    switch (type) {
        case (Backup):
        case (Copy):
            title = lang.BACKUPS;
            break;
        case (Snapshot):
        case (RemoteSnapshot):
            title = lang.SNAPSHOTS;
            break;
        case (Archive):
            title = lang.ARCHIVES;
            break;
        case (Tape):
            title = lang.BACKUPS_ON_TAPE;
            break;
        default:
            title = 'Wrong restore type';
            break;
    }

    return title;
};

const getDestinationColumnHeader = (type: PublicCloudVmRestoreType, lang: Record<string, string>): string => {
    let title = '';

    switch (type) {
        case (Backup):
        case (Copy):
            title = lang.REPOSITORY;
            break;
        case (Snapshot):
        case (RemoteSnapshot):
            title = lang.VM_REGION;
            break;
        case (Archive):
            title = lang.ARCHIVES;
            break;
        case (Tape):
            title = lang.MEDIA_POOL;
            break;
        default:
            title = 'Wrong restore type';
            break;
    }

    return title;
};

const getJobNameColumnHeader = (type: PublicCloudVmRestoreType, lang: Record<string, string>): string => {
    let title = '';

    switch (type) {
        case (Snapshot):
        case (RemoteSnapshot):
            title = capitalize(lang.POLICY_NAME);
            break;
        default:
            title = lang.JOB;
            break;
    }

    return title;
};

const getSourceSizeColumnHeader = (type: PublicCloudVmRestoreType, lang: Record<string, string>): string => {
    let title = '';

    switch (type) {
        case (Snapshot):
        case (RemoteSnapshot):
            title = lang.VM_SIZE;
            break;
        default:
            title = lang.SOURCE_SIZE;
            break;
    }

    return title;
};

const getRestorePointColumnHeader = (type: PublicCloudVmRestoreType, lang: Record<string, string>): string => {
    let title = '';

    switch (type) {
        case (Snapshot):
        case (RemoteSnapshot):
            title = lang.SNAPSHOTS;
            break;
        default:
            title = lang.RESTORE_POINTS;
            break;
    }

    return title;
};

const getBackupSizeColumnHeader = (type: PublicCloudVmRestoreType, lang: Record<string, string>): string => {
    let title = '';

    switch (type) {
        case (Snapshot):
        case (RemoteSnapshot):
            title = lang.SNAPSHOT_SIZE;
            break;
        default:
            title = lang.BACKUP_SIZE;
            break;
    }

    return title;
};

const getSearchFieldPlaceholder = (type: PublicCloudVmRestoreType, lang: Record<string, string>): string => {
    let title = '';

    switch (type) {
        case (Snapshot):
        case (RemoteSnapshot):
            title = lang.POLICY;
            break;
        default:
            title = lang.JOB;
            break;
    }

    return title;
};

export {
    getDialogTitle,
    getDestinationColumnHeader,
    getJobNameColumnHeader,
    getSourceSizeColumnHeader,
    getRestorePointColumnHeader,
    getBackupSizeColumnHeader,
    getSearchFieldPlaceholder,
};
