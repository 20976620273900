/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    ACTION_VIEW, Combobox,
    CONTROL_SIZE,
    Dialog,
    DIALOG_SIZE,
    Form,
    FormLayout,
    FormValidator,
    NumberInput,
    STACK_DIRECTION,
    useExternalFormApi,
    ValidationState,
} from '@veeam-vspc/components';
import { capitalize, deepCopy } from '@veeam-vspc/core';

import type { ResellerCloudConnectAgent } from '@veeam-vspc/models/web-controllers';

import { useLang } from 'views/providers/LangProvider/hooks';
import { ToggleField } from 'components/controls/ToggleField';
import { useResellerWizardStore } from '../../../../hooks';
import { DEFAULT_TENANT_QUOTA } from '../../const';
import { useAppServices } from 'views/providers/AppProvider/hooks';

interface Props {
    deactivate: () => void;
    onConfirm: (data: ResellerCloudConnectAgent) => void;

    data?: ResellerCloudConnectAgent;
    existingItems: ResellerCloudConnectAgent[];
}

const MAX_TENANT_QUOTA = 99999;

const formValidate = (data: ResellerCloudConnectAgent, lang: any, isTenantQuotaEnabled: boolean, tenantQuotaUsage: number) => {
    const validator = new FormValidator(data);

    validator.validate('cloudConnectAgentUid')
        .string()
        .required();

    if (isTenantQuotaEnabled) {
        validator.validate('tenantQuota')
            .number()
            .required()
            .min(tenantQuotaUsage || 1)
            .max(MAX_TENANT_QUOTA);
    }

    return validator.result();
};

const getDefaultData = (): ResellerCloudConnectAgent => ({
    cloudConnectAgentUid: null,
    cloudConnectAgentName: '',
    tenantQuota: DEFAULT_TENANT_QUOTA,
});

export const SiteDialog = observer((props: Props) => {
    const lang = useLang();
    const defaultFieldWidth = CONTROL_SIZE.full;
    const wizardStore = useResellerWizardStore();
    const { notificationService } = useAppServices();

    const [isEdit] = useState(!!props.data);
    const [existingItems] = useState(() => {
        const result = [...props.existingItems];
        if (isEdit) {
            result.splice(result.findIndex(item => item.cloudConnectAgentUid === props.data.cloudConnectAgentUid), 1);
        }
        return result;
    });
    const [isTenantQuotaEnabled, setIsTenantQuotaEnabled] = useState(props.data ? props.data.tenantQuota > -1 : true);
    const [filteredCloudConnectAgents] = useState(wizardStore.cloudConnectAgentsCache.value
        .filter(item => !existingItems
            .find(existingItem => existingItem.cloudConnectAgentUid === item.agentGuid)
        )
    );
    const [data, setData] = useState(() => {
        if (props.data) {
            const result = deepCopy(props.data);
            if (result.tenantQuota === -1) {
                result.tenantQuota = DEFAULT_TENANT_QUOTA;
            }
            return result;
        }
        return getDefaultData();
    });
    const [tenantQuotaUsage, setTenantQuotaUsage] = useState(1);

    const formApi = useExternalFormApi<ResellerCloudConnectAgent>();
    const [validationState] = useState(new ValidationState());

    const [windowTitle] = useState(`${isEdit ? lang.EDIT : lang.ADD} ${lang.SITE}`);

    return (
        <Dialog
            header={windowTitle}
            description={lang.SELECT_SITE_AND_SET_QUOTA}
            actions={[
                {
                    text: lang.OK,
                    onClick: () => {
                        if (formApi.validate()) {
                            if (props.data && props.data.cloudConnectAgentUid !== data.cloudConnectAgentUid && props.data.tenantUsage > 0) {
                                notificationService.error(windowTitle, lang.CANNOT_EDIT_THE_SITE);
                                return;
                            }

                            props.onConfirm && props.onConfirm({ ...data, tenantQuota: isTenantQuotaEnabled ? data.tenantQuota : -1 });
                            props.deactivate();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    onClick: () => props.deactivate(),
                    view: ACTION_VIEW.secondary,
                },
            ]}
            onRequestClose={props.deactivate}
            size={{
                width: DIALOG_SIZE.xs,
                height: DIALOG_SIZE.auto,
            }}
        >
            <Form <ResellerCloudConnectAgent>
                value={data}
                validate={data => formValidate(data, lang, isTenantQuotaEnabled, tenantQuotaUsage)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={(v) => {
                    setData({ ...v });
                }}
            >
                <FormLayout>
                    <Combobox
                        name={'cloudConnectAgentUid'}
                        label={lang.SITE}
                        data={filteredCloudConnectAgents}
                        valueGetter={item => item.agentGuid}
                        textGetter={item => item.siteName}
                        size={defaultFieldWidth}
                        onChange={(value, itemIndex) => {
                            const item = filteredCloudConnectAgents[itemIndex];
                            formApi.setValue('cloudConnectAgentName', item.siteName);
                            setTenantQuotaUsage(item.tenantQuotaUsage);
                        }}
                    />

                    <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                        <ToggleField
                            value={isTenantQuotaEnabled}
                            onChange={value => setIsTenantQuotaEnabled(value)}
                            label={lang.QUOTA}
                            showSuffix={true}
                        />

                        <NumberInput
                            inlineLabel={true}
                            name={'tenantQuota'}
                            suffix={capitalize(lang.MANAGED_COMPANIES)}
                            disabled={!isTenantQuotaEnabled}
                            minValue={tenantQuotaUsage || 1}
                            maxValue={MAX_TENANT_QUOTA}
                        />
                    </FormLayout>
                </FormLayout>
            </Form>
        </Dialog>
    );
});
