/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Form,
    FormValidator,
    FormLayout,
    isValid,
    useExternalFormApi,
    NoLabelGroup,
    LinkButton,
    FormTitle,
} from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/use-page-wizard-store';
import { capitalize, formatStr } from '@veeam-vspc/core';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import settingsIcon from 'images/actions/settings.svg';
import { PerMonthField } from 'components/controls/PerMonthField';
import { FormNote } from 'components/controls/FormNote';
import { CloudConnectServicesAdvanced } from './components/CloudConnectServicesAdvanced';
import { CloudConnectReplicationAdvanced } from './components/CloudConnectReplicationAdvanced';

import type { SubscriptionPlanModel } from 'core/interfaces';

export const stepValidate = (data: SubscriptionPlanModel) => {
    const validator = new FormValidator(data);

    validator
        .validate('cbServerPrice')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('cbWorkstationPrice')
        .number()
        .min(0)
        .max(1000000);
    validator
        .validate('cbVmPrice')
        .number()
        .min(0)
        .max(1000000);

    validator
        .validate('crVmPrice')
        .number()
        .min(0)
        .max(1000000);

    return validator.result();
};

export const getCloudConnectServicesStep = (title: string): WizardStep<SubscriptionPlanModel> => ({
    title,
    validate: ({ data, stepData, isEdit }) => isValid(stepValidate, data),
    render: data => <CloudConnectServicesStep {...data} />,
});

const CloudConnectServicesStep = (props: WizardStepData<SubscriptionPlanModel>) => {
    const lang = useLang();
    const { data, validationState, onDataChange } = props;
    const formApi = useExternalFormApi();
    const wizardContext = usePageWizardStore();
    const showPanel = () => wizardContext.openPanel(hide => <CloudConnectServicesAdvanced {...props} hide={hide} />);
    const showReplicaPanel = () => wizardContext.openPanel(hide => <CloudConnectReplicationAdvanced {...props} hide={hide} />);
    const currencyPerAgent = formatStr(lang.CURRENCY_PER_AGENT, data.currency);
    const currencyPerVm = formatStr(lang.CURRENCY_PER_VM, data.currency);

    return (
        <StepLayout
            title={capitalize(lang.VEEAM_CLOUD_CONNECT)}
            description={lang.DEFINE_SUBSCRIPTION_PLAN_PRICE_FOR_CLOUD}
        >
            <Form
                value={data}
                validate={stepValidate}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{lang.VEEAM_CLOUD_CONNECT_BACKUP_RESOURCES_PER}</FormTitle>

                    <PerMonthField
                        name='cbServerPrice'
                        label={lang.SERVER_BACKUP}
                        suffix={currencyPerAgent}
                    />

                    <PerMonthField
                        name='cbWorkstationPrice'
                        label={lang.WORKSTATION_BACKUP}
                        suffix={currencyPerAgent}
                    />

                    <PerMonthField
                        name='cbVmPrice'
                        label={capitalize(lang.VM_BACKUP, true)}
                        suffix={currencyPerVm}
                    />

                    <NoLabelGroup
                        content={(
                            <div>
                                <LinkButton
                                    onClick={showPanel}
                                    iconBefore={settingsIcon}
                                >
                                    {`${lang.ADVANCED_SETTINGS}...`}
                                </LinkButton>

                                <FormNote>{lang.IN_THE_ADVANCED_SETTINGS_YOU_CAN_SELECT}</FormNote>
                            </div>
                        )}
                    />

                    <FormTitle>{lang.VEEAM_CLOUD_CONNECT_REPLICATION_RESOURCES_PER}</FormTitle>

                    <PerMonthField
                        name='crVmPrice'
                        label={lang.REPLICATED_VM}
                        suffix={currencyPerVm}
                    />

                    <NoLabelGroup
                        content={(
                            <div>
                                <LinkButton
                                    onClick={showReplicaPanel}
                                    iconBefore={settingsIcon}
                                >
                                    {`${lang.ADVANCED_SETTINGS}...`}
                                </LinkButton>

                                <FormNote>{lang.IN_THE_ADVANCED_SETTINGS_YOU_CAN_SET_PRICE}</FormNote>
                            </div>
                        )}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
};
