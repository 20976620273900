/**
 * Copyright © Veeam Software Group GmbH.
 */

import type { BaseFailedRequestResponse, BaseRequestResponse, NotificationService } from '@veeam-vspc/core';

export class ResponseHandlerService {
    private notificationService: NotificationService;

    constructor(notificationService: NotificationService) {
        this.notificationService = notificationService;
    }

    handleErrors(resp: BaseRequestResponse<Object>, title: string): Promise<Object> {
        // at the time of writing the code, the BaseRequestResponse type did not match the final format
        const failedResp = resp as BaseFailedRequestResponse;

        if (failedResp && failedResp.errors) {
            const message = failedResp.errors.length > 1 ? failedResp.errors.map(error => ({
                text: error.message,
                listItem: true,
            })) : failedResp.errors[0].message;

            return this.notificationService.error(title || RCOP.Lang.ERROR, message);
        } else if (failedResp) {
            console.error(failedResp);
        }

        return undefined;
    }
}
