/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { AppStorageProvider as KitAppStorageProvider } from '@veeam-vspc/components';

import { useAppContext } from '../AppProvider/hooks';

export interface AppStorageProviderProps {
    children: React.ReactElement;
}

/**
 * @description Should be under NotificationProvider, because in future will show dialog about version change
 */
export const AppStorageProvider: React.FC<AppStorageProviderProps> = ({ children }) => {
    const { appStorage } = useAppContext(); // @TODO When finish switching to full React App, should create AppStorage instance here NOT pass it

    return (
        <KitAppStorageProvider appStorage={appStorage}>
            {children}
        </KitAppStorageProvider>
    );
};
