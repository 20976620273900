/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useMemo, useRef } from 'react';
import { Icon, ICON_SIZES, StackView, STACK_ALIGN, STACK_GAP, Text, TEXT_SIZE } from '@veeam-vspc/components';
import { GoogleWorkloads, ProtectedDataFilterBy, ProtectionType } from '@veeam-vspc/models/web-controllers';
import { observer } from 'mobx-react-lite';

import type { BaseProtectedDataWorkloadOverviewGoogleWorkloads, ProtectedDataOverviewRequest } from '@veeam-vspc/models/web-controllers';

import { TableWidget } from 'views/components/TableWidget';
import { NameColumn } from 'views/components/TableWidget/components/NameColumn';
import successIcon from 'images/success.svg';
import sqlDatabase from 'images/pages/protected-data-summary/gcp-sql-database.svg';
import virtualMachine from 'images/pages/protected-data-summary/gcp-virtual-machine.svg';
import notConnectedMachine from 'images/pages/protected-data-summary/gcp-not-connected.svg';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useIntervalRequest } from 'views/hooks';
import { NoInfoColumn } from 'views/components/TableWidget/components/NoInfoColumn';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { getHelpResourceLink } from 'core/utils';
import { useProtectedDataSummaryStore } from '../../store';

const icons = {
    [GoogleWorkloads.SqlDatabases]: sqlDatabase,
    [GoogleWorkloads.VirtualMachines]: virtualMachine,
};

export const GoogleCloud: React.FC = observer(() => {
    const store = useProtectedDataSummaryStore();
    const lang = useLang();
    const didMount = useRef(false);
    const abortController = useRef(new AbortController());
    const { portalUser } = useAppStore();

    const names = useMemo(() => ({
        [GoogleWorkloads.SqlDatabases]: lang.CLOUD_SQL,
        [GoogleWorkloads.VirtualMachines]: lang.VIRTUAL_MACHINES,
    }), []);

    const request = useIntervalRequest<ProtectedDataOverviewRequest, BaseProtectedDataWorkloadOverviewGoogleWorkloads[]>(
        '/ProtectedOverview/Google',
        {
            rpoDays: store.rpoDays,
            filterType: ProtectedDataFilterBy.Rpo,
        },
        {
            signal: abortController.current.signal,
        });

    useEffect(() => {
        if (didMount.current) {
            abortController.current.abort();

            abortController.current = new AbortController();
            return request.forceRequest();
        }

        didMount.current = true;
    }, [store.rpoDays]);

    const isResellerUser = portalUser.isServiceProviderGroup();
    const noDataUrl = isResellerUser ? getHelpResourceLink('656') : getHelpResourceLink('655');

    const items = request.isError || !Array.isArray(request.data)
        ? []
        : (request.data as BaseProtectedDataWorkloadOverviewGoogleWorkloads[]);

    return (
        <TableWidget
            data={items}
            columns={[
                {
                    name: lang.GOOGLE_CLOUD,
                    cellRenderer: ({ rowData, key }) => (
                        <NameColumn
                            icon={icons[rowData.workloadType]}
                            name={names[rowData.workloadType]}
                            key={key}
                        />
                    ),
                },
                {
                    name: lang.SNAPSHOTS,
                    cellRenderer: ({ rowData, key }) => {
                        const snapshots = rowData.protectionData.find(x => x.protectionType === ProtectionType.Snapshot);
                        const protectedCount = snapshots.protectedCount.value;
                        const overallCount = snapshots.overallCount;

                        if (overallCount === 0) {
                            return (
                                <StackView
                                    align={STACK_ALIGN.center}
                                    gap={STACK_GAP.s}
                                    key={key}
                                >
                                    <NoInfoColumn />
                                </StackView>
                            );
                        }

                        if (protectedCount !== overallCount) {
                            return (
                                <StackView
                                    align={STACK_ALIGN.end}
                                    gap={STACK_GAP.s}
                                    key={key}
                                >
                                    <Text size={TEXT_SIZE.xl}>{protectedCount}</Text>
                                    <Text size={TEXT_SIZE.l}>of</Text>
                                    <Text size={TEXT_SIZE.xl}>{overallCount}</Text>
                                </StackView>
                            );
                        }

                        return (
                            <StackView
                                align={STACK_ALIGN.center}
                                gap={STACK_GAP.s}
                                key={key}
                            >
                                <Icon src={successIcon} size={ICON_SIZES.xl} />
                                <Text size={TEXT_SIZE.xl}>{overallCount}</Text>
                            </StackView>
                        );
                    },
                },
                {
                    name: lang.BACKUPS,
                    cellRenderer: ({ rowData, key }) => {
                        const snapshots = rowData.protectionData.find(x => x.protectionType === ProtectionType.Backup);
                        const protectedCount = snapshots.protectedCount.value;
                        const overallCount = snapshots.overallCount;

                        if (overallCount === 0) {
                            return (
                                <StackView
                                    align={STACK_ALIGN.center}
                                    gap={STACK_GAP.s}
                                    key={key}
                                >
                                    <NoInfoColumn />
                                </StackView>
                            );
                        }

                        if (protectedCount !== overallCount) {
                            return (
                                <StackView
                                    align={STACK_ALIGN.end}
                                    gap={STACK_GAP.s}
                                    key={key}
                                >
                                    <Text size={TEXT_SIZE.xl}>{protectedCount}</Text>
                                    <Text size={TEXT_SIZE.l}>of</Text>
                                    <Text size={TEXT_SIZE.xl}>{overallCount}</Text>
                                </StackView>
                            );
                        }

                        return (
                            <StackView
                                align={STACK_ALIGN.center}
                                gap={STACK_GAP.s}
                                key={key}
                            >
                                <Icon src={successIcon} size={ICON_SIZES.xl} />
                                <Text size={TEXT_SIZE.xl}>{overallCount}</Text>
                            </StackView>
                        );
                    },
                },
            ]}
            loading={request.loading}
            noDataConfig={{
                linkText: lang.START_USING_VEEAM_BACKUP_FOR_GOOGLE_CLOUD,
                icon: notConnectedMachine,
                linkHandler: () => window.open(noDataUrl, '_blank', 'noopener, noreferrer'),
            }}
        />
    );
});
