/**
 * Copyright © Veeam Software Group GmbH.
 */

import '@highcharts/map-collection';
import { extractCountryId } from './extract-country-id';
import { WORLD_MAP_ID } from '../configs';

export const maps = new Map<string, string>();

// convert mapIndex to the flat array of ids
for (const [, groups] of Object.entries((Highcharts as any).mapDataIndex)) {
    if (typeof groups === 'object') {
        for (const [mapName, mapPath] of Object.entries(groups)) {
            maps.set(mapPath, mapName);
        }
    }
}

export const checkIfMapExist = (mapId: string): boolean => {
    if (mapId === WORLD_MAP_ID) return true;

    const mapPath = `countries/${extractCountryId(mapId)}/${mapId}-all.js`;

    return maps.has(mapPath);
};
