/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ACTION_VIEW, SortingMode, TextColumn } from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { WizardStepData, GridStore } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { ActionsToolbar } from './components/ActionsToolbar';

import type { CompanyVcdNetworkExtension, CompanyData } from '../../../../interfaces';

export interface Props extends WizardStepData<CompanyData> {
    hidePanel: () => void;
}

export const VcdNetworkExtensionSettings = observer((props: Props) => {
    const lang = useLang();
    const { hidePanel, data, onDataChange } = props;
    const gridApi = useRef<GridStore<CompanyVcdNetworkExtension, void, void>>();

    const [networks] = useState([...data.vcdNetworksExtensions]);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={capitalize(lang.NETWORK_EXTENSION_APPLIANCE)}
            description={lang.SELECT_NETWORK_EXTENSION}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        data.vcdNetworksExtensions = gridApi.current.pageData;

                        onDataChange(data);

                        hidePanel();
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <AdvancedGrid <CompanyVcdNetworkExtension, void, void>
                defaultColumnWidth={100}
                columns={[
                    { field: 'name', title: lang.NAME },
                    { field: 'dataCenterName', title: capitalize(lang.VIRTUAL_DATA_CENTER) },
                    { field: 'networkName', title: lang.EXTERNAL_NETWORK },
                    {
                        field: 'ipAddress',
                        title: capitalize(lang.IP_ADDRESS),
                        cell: ({ rowData }) => (
                            <TextColumn>{rowData.dhcpEnabled ? lang.OBTAIN_AUTOMATICALLY : rowData.ipAddress}</TextColumn>
                        ),
                    },
                ]}
                data={() => Promise.resolve({
                    data: networks,
                    meta: {
                        pagingInfo: {
                            total: networks?.length,
                        },
                    },
                } as BaseSuccessRequestResponse<CompanyVcdNetworkExtension[]>)}
                toggleable={false}
                disablePagination={true}
                disableAutoUpdate={true}
                selection={{
                    field: 'dataCenterUid',
                    multiple: false,
                    checkbox: false,
                }}
                toolbars={[
                    () => <ActionsToolbar items={networks} />,
                ]}
                api={gridApi}
                sortingMode={SortingMode.Local}
            />
        </SidePanelForm>
    );
});
