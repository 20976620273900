/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled, { createGlobalStyle } from 'styled-components';
import { constants } from '@veeam-vspc/components';

export const TooltipStyles = createGlobalStyle`
    .customTheme {
        border: ${constants.BASE_BORDER_WIDTH} solid #C0C0C0 !important;
        opacity: 1 !important;
        box-shadow: ${constants.SHADOWS_NORMAL} !important;
        min-width: 206px !important;
        border-radius: 0 !important;
        padding: ${constants.SPACING_S} !important;
        box-sizing: border-box !important;
    }

    .customTheme::after {
        content: none !important;
    }
`;

export const JobsCount = styled.div`
    margin: ${constants.SPACING_S} 0;
    font-weight: ${constants.BASE_FONT_BOLD_WEIGHT};
`;

export const Company = styled.div`
    margin-top: ${constants.SPACING_S};
`;

export const MoreCompanies = Company;

export const CompaniesCount = styled.div`
    margin-top: ${constants.SPACING_M};
    font-weight: bold;
`;
