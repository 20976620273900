/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
    processDecoratorsForDisabledByPriority,
} from '@veeam-vspc/components';
import { NotificationDialogsTextKeys } from '@veeam-vspc/core';

import type { NotificationResponse } from '@veeam-vspc/core';
import type { ToolbarItems } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import addIcon from 'images/actions/add.svg';
import removeIcon from 'images/actions/remove.svg';
import editIcon from 'images/actions/edit.svg';
import favoriteIcon from 'images/actions/favorite.svg';
import { core } from 'core/core-module/core-module';
import { QuotaDialog } from '../QuotaDialog';

import type { BackupResource } from '../../../../../../interfaces';

interface Props {
    quotas: BackupResource[];
    companyId?: number;
    cloudConnectAgentUid: string;
    onQuotasUpdated: (quotas: BackupResource[]) => void;
}

export const ActionsToolbar = ({ quotas, companyId, cloudConnectAgentUid, onQuotasUpdated }: Props) => {
    const lang = useLang();
    const gridApi = useGridApi<BackupResource, BackupResource, void>();

    const [quotaDialog, quotaDialogActions] = useModal<BackupResource>({
        render: ({ deactivate, data }) => (
            <QuotaDialog
                data={data}
                existingItems={quotas}
                companyId={companyId}
                cloudConnectAgentUid={cloudConnectAgentUid}
                onConfirm={(newData) => {
                    if (data) {
                        quotas[quotas.findIndex(item => item.cloudRepositoryFriendlyName === data.cloudRepositoryFriendlyName)] = newData;
                    } else {
                        if (quotas.length === 0) {
                            newData.isDefault = true;
                        }
                        quotas.push(newData);
                    }
                    onQuotasUpdated(quotas);
                    gridApi.reloadGrid();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const setAsDefaultDecorator = ({ selectedItems }) => {
        let disabled = false;

        if (selectedItems && selectedItems.length === 1) {
            disabled = (selectedItems[0] as BackupResource).isDefault;
        }

        return { disabled };
    };

    const toolbarItems: ToolbarItems = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: addIcon,
            text: lang.ADD,
            onClick: () => {
                quotaDialogActions.activate();
            },
            decorators: [
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: editIcon,
            text: lang.EDIT,
            onClick: async() => {
                const selected = gridApi.selected[0];
                quotaDialogActions.activate(selected);
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: favoriteIcon,
            text: lang.SET_AS_DEFAULT,
            onClick: async() => {
                const selected = gridApi.selected[0];
                const selectedIndex = quotas.findIndex(item => item.cloudRepositoryFriendlyName === selected.cloudRepositoryFriendlyName);
                quotas.forEach((item, index) => {
                    if (index === selectedIndex) {
                        item.isDefault = true;
                    } else {
                        item.isDefault = false;
                    }
                });
                quotas.find(item => item.cloudRepositoryFriendlyName === selected.cloudRepositoryFriendlyName).isDefault = true;
                gridApi.reloadGrid();
            },
            decorators: [processDecoratorsForDisabledByPriority([
                toolbarItemDecorators.disallowWithoutSingleSelection(),
                setAsDefaultDecorator,
            ])],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: removeIcon,
            text: lang.REMOVE,
            onClick: async() => {
                const selected = gridApi.selected[0];
                const remove = () => {
                    quotas.splice(quotas.findIndex(item => item.cloudRepositoryFriendlyName === selected.cloudRepositoryFriendlyName), 1);
                    onQuotasUpdated(quotas);
                    gridApi.reloadGrid();
                };

                if (selected.isDefault && quotas.length > 1) {
                    core.notificationService.error(lang.DELETE_BACKUP_RESOURCES, lang.THIS_REPOSITORY_IS_SET_AS_DEFAULT);
                } else if (selected.countOfSubtenants > 0) {
                    core.notificationService.confirm(lang.DELETE_BACKUP_RESOURCES, lang.THIS_QUOTA_IS_ALSO_BY_SUBTENANTS)
                        .then((btnKey: NotificationResponse) => {
                            if (btnKey === NotificationDialogsTextKeys.Yes) {
                                remove();
                            }
                        });
                } else {
                    remove();
                }
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
    ];

    return (
        <>
            <Toolbar items={toolbarItems} />
            {quotaDialog}
        </>
    );
};
