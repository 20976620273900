/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, TextColumn } from '@veeam-vspc/components';

import { useAppStore } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAsyncAction, useGridExportParams } from 'views/hooks';
import { isAssignTagAllowed } from './utils/assign-tag-helper';
import { SetCustomNoteDialog } from './components/SetCustomNoteDialog/SetCustomNoteDialog';

import type { AssignTagItemParams } from './interfaces';

export interface CustomNoteColumnProps {
    value: string | null;
    itemParams?: AssignTagItemParams;
    isMultiAction?: boolean;
    idProperty?: string;
    agentUid: string;
}

export const CustomNoteColumn: React.FC<CustomNoteColumnProps> = (props) => {
    const {
        value: baseValue,
        itemParams = {},
        agentUid,
        idProperty = 'agentId',
        isMultiAction = false,
        ...restProps
    } = props;
    const lang = useLang();
    const { portalUser } = useAppStore();
    const { value, canEdit } = isAssignTagAllowed(baseValue, portalUser.userRole, itemParams);
    const { data } = useGridExportParams({ selectedOnly: true, idProperty, forceSelectedId: agentUid });
    const columnText = value?.trim() || `${lang.NOT_SET}...`;
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();

    if (canEdit) {
        return (
            <>
                <ActionLinkColumn
                    renderContent={() => <TextColumn style={{ color: 'inherit' }}>{columnText}</TextColumn>}
                    isLink={canEdit}
                    isExtMode={true}
                    renderModal={({ deactivate }) => (
                        <SetCustomNoteDialog
                            {...restProps}
                            value={baseValue}
                            deactivate={deactivate}
                            onSubmit={(note: string) => {
                                deactivate();

                                if (isMultiAction) {
                                    return doAsyncAction('/Agent/SetCustomNote', lang.ASSIGN_TAG, { ...data, note });
                                }

                                return doAsyncAction('/Agent/SetAgentCustomNote', lang.ASSIGN_TAG, { agentUid: agentUid, note });
                            }}
                        />
                    )}
                />

                {asyncActionLoader}
            </>
        );
    }

    return (
        <TextColumn>
            {columnText}
        </TextColumn>
    );
};
