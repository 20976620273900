/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useEffect, useState } from 'react';

import type { LangsService } from 'core/services';
import type { LangsServiceBase } from 'core/services/langs/interfaces';
import type { UseLangServiceData } from '../interfaces';

const initialState = {
    hasLang: false,
    lang: null,
    locale: null,
};

export const useLangService = (langService: LangsService<LangsServiceBase>, newLocale: string): UseLangServiceData => {
    const [langState, setLangState] = useState(initialState);
    const { hasLang, lang, locale } = langState;

    useEffect(() => {
        if (locale !== newLocale) {
            langService.loadLangFor(newLocale)
                .then(() => setLangState({ hasLang: true, lang: langService.getLang(), locale: newLocale }))
                .catch((err) => {
                    console.error(err);
                    setLangState({ hasLang, lang, locale: newLocale });
                });
        }
    }, [locale, newLocale, langService, hasLang, lang]);

    return { lang, locale, hasLang };
};
