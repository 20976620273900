/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    ACTION_VIEW,
    disableForSelectionWithPropValueCallback,
    disableForSelectionWithPropValues,
    processDecoratorsForDisabledByPriority,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
} from '@veeam-vspc/components';
import { NotificationDialogsTextKeys } from '@veeam-vspc/core';

import type { BackupServerJobFilter, BackupServerJobModel } from '@veeam-vspc/models/web-controllers';

import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { useLang } from 'views/providers/LangProvider/hooks';
import startActionIconSrc from 'images/actions/start.svg';
import stopActionIconSrc from 'images/actions/stop.svg';
import retryActionIconSrc from 'images/actions/retry.png';
import enableActionIconSrc from 'images/actions/enable.png';
import disableActionIconSrc from 'images/actions/disable.svg';
import downloadLogsActionIconSrc from 'images/actions/download-logs.svg';
import downloadVbrActionIconSrc from 'images/actions/download-vbr.png';
import powershellActionIconSrc from 'images/actions/powershell.png';
import { EJobStates, JobTypes } from 'core/enums';
import { useAsyncAction } from 'views/hooks';
import { useAppServices, useAppStore } from 'views/providers/AppProvider/hooks';
import { ConfigSectionIds } from 'core/services/configurator/enums';
import { FeatureNames } from 'core/entries/portal-features/enums';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';

export interface VirtualInfrastructureActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items'> {
    method?: string;
    existsVbrShell: boolean;
}

export const VirtualInfrastructureActionToolbar: React.FC<VirtualInfrastructureActionToolbarProps> = (props) => {
    const { method = 'GetJobs', existsVbrShell, ...restProps } = props;
    const lang = useLang();
    const { portalUser, portalFeatures } = useAppStore();
    const { transportService, notificationService } = useAppServices();
    const gridApi = useGridApi<BackupServerJobModel, unknown, BackupServerJobFilter>();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSelection(),
    ];

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                method={method}
                sectionId={ConfigSectionIds.BackupJobsVirtualMachinesVirtualInfrastructure}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: startActionIconSrc,
                        text: lang.START,
                        onClick: () => doAsyncAction('/BackupServerJob/StartJob', lang.START, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.SqlLogBackup,
                                    JobTypes.OracleLogBackup,
                                    JobTypes.CdpReplica,
                                ]),
                                disableForSelectionWithPropValues('lastSessionStateId', [
                                    EJobStates.Running,
                                    EJobStates.Starting,
                                    EJobStates.Stopping,
                                ]),
                                disableForSelectionWithPropValueCallback<BackupServerJobModel>('jobScheduleState', (value, item) => (
                                    value === lang.DISABLED && item.jobType === JobTypes.BackupCopy
                                )),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopActionIconSrc,
                        text: lang.STOP,
                        onClick: () => {
                            const { selected } = gridApi;
                            const doAction = (gracefully: boolean) => doAsyncAction('/BackupServerJob/StopJob', lang.STOP, {
                                ...gridApi.requestParamsForActions,
                                gracefully,
                            });
                            const skipGracefullyFor = [
                                JobTypes.SureBackup,
                                JobTypes.FileTapeBackup,
                                JobTypes.VmTapeBackup,
                            ];

                            if (selected.every(item => skipGracefullyFor.includes(item.jobType))) {
                                return doAction(false);
                            }

                            return notificationService.info(lang.STOP_JOB, lang.WE_CAN_STOP_THIS_JOB_GRACEFULLY, {
                                buttons: [
                                    {
                                        type: NotificationDialogsTextKeys.Ok,
                                        text: lang.GRACEFULLY,
                                    },
                                    {
                                        type: NotificationDialogsTextKeys.Yes,
                                        text: lang.IMMEDIATELY,
                                    },
                                    {
                                        type: NotificationDialogsTextKeys.Cancel,
                                        text: lang.CANCEL,
                                        view: ACTION_VIEW.secondary,
                                    },
                                ],
                            })
                                .then((btn) => {
                                    if (btn === NotificationDialogsTextKeys.Ok || btn === NotificationDialogsTextKeys.Yes) {
                                        return doAction(btn === NotificationDialogsTextKeys.Ok);
                                    }
                                });
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.SqlLogBackup,
                                    JobTypes.OracleLogBackup,
                                    JobTypes.CdpReplica,
                                ]),
                                disableForSelectionWithPropValueCallback('lastSessionStateId', value => value !== EJobStates.Running),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: retryActionIconSrc,
                        text: lang.RETRY,
                        onClick: () => doAsyncAction('/BackupServerJob/RetryJob', lang.RETRY, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.BackupCopy,
                                    JobTypes.SqlLogBackup,
                                    JobTypes.OracleLogBackup,
                                    JobTypes.SureBackup,
                                    JobTypes.VmTapeBackup,
                                    JobTypes.VmCopy,
                                ]),
                                disableForSelectionWithPropValueCallback('lastSessionStateId', value => value !== EJobStates.Failed),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: enableActionIconSrc,
                        text: lang.ENABLE,
                        onClick: () => doAsyncAction('/BackupServerJob/EnableJob', lang.ENABLE, {
                            ...gridApi.requestParamsForActions,
                            enable: true,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.SqlLogBackup,
                                    JobTypes.OracleLogBackup,
                                ]),
                                disableForSelectionWithPropValues('lastSessionStateId', [
                                    EJobStates.Enabling,
                                    EJobStates.Disabling,
                                ]),
                                disableForSelectionWithPropValueCallback('jobScheduleState', value => value !== lang.DISABLED),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: disableActionIconSrc,
                        text: lang.DISABLE,
                        onClick: () => doAsyncAction('/BackupServerJob/EnableJob', lang.DISABLE, {
                            ...gridApi.requestParamsForActions,
                            enable: false,
                        }),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('jobType', [
                                    JobTypes.SqlLogBackup,
                                    JobTypes.OracleLogBackup,
                                ]),
                                disableForSelectionWithPropValues('lastSessionStateId', [
                                    EJobStates.Enabling,
                                    EJobStates.Disabling,
                                ]),
                                disableForSelectionWithPropValueCallback('jobScheduleState', value => (
                                    [
                                        lang.DISABLED.toLowerCase(),
                                        lang.NOT_SCHEDULED.toLowerCase(),
                                    ].includes((value as string)?.toLowerCase())
                                )),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: downloadLogsActionIconSrc,
                        text: lang.DOWNLOAD_LOGS,
                        onClick: () => {
                            Ext.create('RCOP.utils.DownloadLogsManager', {
                                title: lang.DOWNLOAD_LOGS,
                                url: '/BackupServerJob/DownloadJobLogs',
                                params: gridApi.requestParamsForActions,
                                showDialog: true,
                            }).start();
                        },
                        decorators: defaultDisabledDecorators,
                    },
                    {
                        id: 'separatorBeforeDownloadRemoteConsole',
                        type: TOOLBAR_ITEM_TYPE.separator,
                        hidden: !existsVbrShell,
                    },
                    {
                        id: 'downloadRemoteConsole',
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: downloadVbrActionIconSrc,
                        text: lang.DOWNLOAD_REMOTE_BACKUP_CONSOLE,
                        onClick: () => transportService.downloadFile('/BackupServer/DownloadVbrShell'),
                        hidden: !existsVbrShell,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: powershellActionIconSrc,
                        text: lang.POWERSHELL_SESSION,
                        onClick: () => {
                            const { selected } = gridApi;
                            const backupServerUid = selected[0].backupServerUid;
                            const backupServerName = selected[0].serverName;
                            const queryParams = [
                                `backupServerUid=${backupServerUid}`,
                                `backupServerName=${backupServerName}`,
                            ];
                            const isFeatureEnabled = portalFeatures.isFeatureEnabled(FeatureNames.RemoteComputerTerminalSessions);

                            if (isFeatureEnabled) {
                                return window.open(
                                    `${window.location.origin}?${queryParams.join('&')}#backupServer/terminal`,
                                    '_blank',
                                    'noopener,noreferrer'
                                );
                            }

                            const infoMessage = portalUser.isPortalAdministrator()
                                ? lang.CANNOT_START_THE_REMOTE_POWERSHELL_SESSION
                                : lang.CANNOT_START_THE_REMOTE_POWERSHELL_SESSION_CONTACT;

                            return notificationService.info(lang.POWERSHELL_SESSION, infoMessage);
                        },
                        decorators: [
                            toolbarItemDecorators.disallowWithoutSingleSelection(),
                        ],
                    },
                ]}
            />

            {asyncActionLoader}
        </>
    );
};
