/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    disableForSelectionWithPropValues,
    PortalSpinner,
    processDecoratorsForDisabledByPriority,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
} from '@veeam-vspc/components';
import { NotificationDialogsTextKeys } from '@veeam-vspc/core';
import { EPublicCloudObjectCreatingState } from '@veeam-vspc/models/web-controllers';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type {
    PublicCloudAvailableVbModel,
    PublicCloudRdsJobFilter,
    PublicCloudRdsJobModel,
    UserUiData,
    VbPolicyActionSettingJson,
    VbPolicyCreateActionSettingJson,
} from '@veeam-vspc/models/web-controllers';

import { useLang } from 'views/providers/LangProvider/hooks';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import startActionIconSrc from 'images/actions/start.svg';
import stopActionIconSrc from 'images/actions/stop.svg';
import enableActionIconSrc from 'images/actions/enable.png';
import disableActionIconSrc from 'images/actions/disable.svg';
import createJobActionIconSrc from 'images/actions/add.svg';
import editJobActionIconSrc from 'images/actions/edit.svg';
import deleteJobActionIconSrc from 'images/actions/remove.svg';
import { useAsyncAction } from 'views/hooks';
import { SelectVbDialog } from '../../../SelectVbDialog';
import { ConfigSectionIds } from 'core/services';

import type { ToolbarWithExportProps } from 'views/components/ToolbarWithExport';

export interface CloudDatabasesJobActionToolbarProps extends Omit<ToolbarWithExportProps, 'method' | 'items'> {
    method?: string;
}

export const CloudDatabasesJobActionToolbar: React.FC<CloudDatabasesJobActionToolbarProps> = (props) => {
    const { method = 'GetPublicCloudRdsJobs', ...restProps } = props;
    const lang = useLang();
    const { transportService, notificationService } = useAppServices();
    const [loading, setLoading] = useState(false);
    const defaultDisabledDecorators = [
        toolbarItemDecorators.disallowWithoutSelection(),
    ];
    const gridApi = useGridApi<PublicCloudRdsJobModel, unknown, PublicCloudRdsJobFilter>();
    const [asyncActionLoader, doAsyncAction] = useAsyncAction();
    const [selectVbDialog, selectVbDialogActions] = useModal({
        render: ({ deactivate }) => (
            <SelectVbDialog
                onClose={deactivate}
                onSelect={({ serverTag }: PublicCloudAvailableVbModel) => {
                    setLoading(true);
                    deactivate();

                    transportService.request<VbPolicyCreateActionSettingJson, string>('/PublicCloudRdsJob/CreatePolicyLink', {
                        serverTag,
                        backUrl: window.location.href,
                    })
                        .then((resp: BaseSuccessRequestResponse<string>) => {
                            setLoading(false);
                            window.open(resp.data as string, '_self');
                        })
                        .catch((err) => {
                            setLoading(false);

                            if (err) {
                                console.error(err);
                            }
                        });
                }}
            />
        ),
    });

    return (
        <>
            <ToolbarWithExport
                {...restProps}
                sectionId={ConfigSectionIds.BackupJobsCloudDatabases}
                method={method}
                items={[
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: startActionIconSrc,
                        text: lang.START,
                        onClick: () => doAsyncAction('/PublicCloudRdsJob/StartPolicy', lang.START, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: stopActionIconSrc,
                        text: lang.STOP,
                        onClick: () => doAsyncAction('/PublicCloudRdsJob/StopPolicy', lang.STOP, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: enableActionIconSrc,
                        text: lang.ENABLE,
                        onClick: () => doAsyncAction('/PublicCloudRdsJob/EnablePolicy', lang.ENABLE, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('enabled', [
                                    true,
                                ]),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: disableActionIconSrc,
                        text: lang.DISABLE,
                        onClick: () => doAsyncAction('/PublicCloudRdsJob/DisablePolicy', lang.DISABLE, gridApi.requestParamsForActions),
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('enabled', [
                                    false,
                                ]),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.separator,
                    },
                    {
                        hidden: !(RCOP.UI_PREFERENCES as UserUiData).visibility.infrastructure.publicCloud,
                        iconSrc: createJobActionIconSrc,
                        text: lang.CREATE_POLICY,
                        type: TOOLBAR_ITEM_TYPE.button,

                        onClick: () => {
                            selectVbDialogActions.activate();
                        },
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: editJobActionIconSrc,
                        text: lang.EDIT,
                        onClick: () => {
                            const { selected } = gridApi;

                            setLoading(true);

                            return transportService.request<VbPolicyActionSettingJson, string>('/PublicCloudRdsJob/EditPolicyLink', {
                                uid: selected[0].id,
                                backUrl: window.location.href,
                            })
                                .then((resp: BaseSuccessRequestResponse<string>) => {
                                    setLoading(false);

                                    window.open(resp.data as string, '_self');
                                })
                                .catch((err) => {
                                    setLoading(false);

                                    if (err) {
                                        console.error(err);
                                    }
                                });
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('vbLinksEnabled', [false]),
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                    EPublicCloudObjectCreatingState.Failed,
                                ]),
                            ]),
                        ],
                    },
                    {
                        type: TOOLBAR_ITEM_TYPE.button,
                        iconSrc: deleteJobActionIconSrc,
                        text: lang.REMOVE,
                        onClick: () => {
                            notificationService.confirm(lang.REMOVE_POLICY, lang.THIS_ACTION_WILL_REMOVE_ALL_SELECTED_BACKUP_POLICIES)
                                .then((btnKey) => {
                                    if (btnKey === NotificationDialogsTextKeys.Yes) {
                                        doAsyncAction('/PublicCloudRdsJob/DeletePolicy', lang.REMOVE_POLICY, gridApi.requestParamsForActions);
                                    }
                                });
                        },
                        decorators: [
                            processDecoratorsForDisabledByPriority([
                                ...defaultDisabledDecorators,
                                disableForSelectionWithPropValues('creatingState.state', [
                                    EPublicCloudObjectCreatingState.Creating,
                                ]),
                            ]),
                        ],
                    },
                ]}
            />

            {loading && <PortalSpinner />}
            {asyncActionLoader}
            {selectVbDialog}
        </>
    );
};
