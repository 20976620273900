/**
 * Copyright © Veeam Software Group GmbH.
 */

import styled from 'styled-components';
import { SPACING_M } from '@veeam-vspc/components';

export const WidgetColumnsStyled = styled.div`
    display: grid;
    gap: ${SPACING_M};
    grid-template-columns: 1fr 1fr;
    overflow: auto;
    width: 100%;
`;
