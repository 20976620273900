/**
 * Copyright © Veeam Software Group GmbH.
 */

import { capitalize, formatStr } from '@veeam-vspc/core';
import React from 'react';
import {
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    toolbarItemDecorators,
    useGridApi,
    useModal,
    useWizardData,
} from '@veeam-vspc/components';

import type { ResellerCloudConnectAgent } from '@veeam-vspc/models/web-controllers';
import type { ToolbarItems, PageWizardStore } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { SiteDialog } from '../SiteDialog';
import addIcon from 'images/actions/add.svg';
import removeIcon from 'images/actions/remove.svg';
import editIcon from 'images/actions/edit.svg';
import setQuotaIcon from 'images/actions/set-companies-quota.svg';
import { TenantQuotaDialog } from '../TenantQuotaDialog';
import { getResellerCcAgent } from '../../utils';

import type { GridItem } from '../../interfaces';
import type { ResellerData } from '../../../../interfaces';

interface Props {
    items: ResellerCloudConnectAgent[];
}

export const ActionsToolbar = ({ items }: Props) => {
    const lang = useLang();
    const gridApi = useGridApi<GridItem, void, void>();
    const wizardData = useWizardData<PageWizardStore<ResellerData>>().data;

    const [siteDialog, siteDialogActions] = useModal<ResellerCloudConnectAgent>({
        render: ({ deactivate, data }) => (
            <SiteDialog
                data={data}
                existingItems={items}
                onConfirm={(newData) => {
                    if (data) {
                        items[items.findIndex(item => item.cloudConnectAgentUid === data.cloudConnectAgentUid)] = newData;
                    } else {
                        items.push(newData);
                    }
                    gridApi.reloadGrid();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const [tenantQuotaDialog, tenantQuotaDialogActions] = useModal<ResellerCloudConnectAgent[]>({
        render: ({ deactivate, data }) => (
            <TenantQuotaDialog
                items={data}
                onConfirm={() => {
                    gridApi.reloadGrid();
                }}
                deactivate={deactivate}
            />
        ),
    });

    const toolbarItems: ToolbarItems = [
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: addIcon,
            text: lang.ADD,
            onClick: () => {
                siteDialogActions.activate();
            },
            decorators: [
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: editIcon,
            text: lang.EDIT,
            onClick: () => {
                const selected = gridApi.selected[0];
                siteDialogActions.activate({
                    cloudConnectAgentUid: selected.agentGuid,
                    cloudConnectAgentName: selected.siteName,
                    tenantQuota: selected.tenantQuota,
                    tenantUsage: selected.tenantUsage,
                });
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: removeIcon,
            text: lang.REMOVE,
            onClick: async() => {
                const withManaged: string[] = [];
                const toDelete: string[] = [];
                const selected = gridApi.selected;

                selected.forEach((item) => {
                    if (item.tenantUsage > 0) {
                        withManaged.push(item.siteName);
                    } else {
                        toDelete.push(item.agentGuid);
                    }
                });

                if (withManaged.length > 0) {
                    const title = capitalize(lang.REMOVE_SITE);
                    const thisStr = withManaged.length === 1 ? lang.THIS_SITE_CONTAINS : lang.THESE_SITES_CONTAIN;
                    const site = withManaged.length === 1 ? lang.SITE : lang.SITES;
                    const message = formatStr(RCOP.Lang.CANNOT_REMOVE_THE_SELECTED, site, withManaged.join(', '), thisStr);

                    core.notificationService.error(title, message);
                }

                if (toDelete.length > 0) {
                    toDelete.forEach((id) => {
                        items.splice(items.findIndex(it => it.cloudConnectAgentUid === id), 1);

                        // remove related resources
                        wizardData.cloudBackupResources = wizardData.cloudBackupResources.filter(item => item.cloudConnectAgentUid !== id);
                        wizardData.cloudReplicationResources = wizardData.cloudReplicationResources.filter(item => item.cloudConnectAgentUid !== id);
                        wizardData.cloudVcdResources = wizardData.cloudVcdResources.filter(item => item.cloudConnectAgentUid !== id);
                    });

                    gridApi.reloadGrid();
                }
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSelection(),
            ],
        },
        {
            type: TOOLBAR_ITEM_TYPE.button,
            iconSrc: setQuotaIcon,
            text: `${capitalize(lang.SET_QUOTA)}...`,
            onClick: () => {
                tenantQuotaDialogActions.activate(gridApi.selected.map(selectedAgent => getResellerCcAgent(items, selectedAgent)));
            },
            decorators: [
                toolbarItemDecorators.disallowWithoutSelection(),
            ],
        },
    ];

    return (
        <>
            <Toolbar items={toolbarItems} />
            {siteDialog}
            {tenantQuotaDialog}
        </>
    );
};
