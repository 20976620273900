/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useState } from 'react';
import {
    ACTION_VIEW,
    Form,
    FormLayout,
    FormValidator,
    isValid,
    Radiobutton,
    NoLabelGroup,
    FormDescription,
    FormTitle,
    LabelGroup,
} from '@veeam-vspc/components';
import { capitalize, FileSizeUnitsInBytes, formatStr } from '@veeam-vspc/core';

import type { WizardStepData } from '@veeam-vspc/components';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { DataSizeInBytes } from 'components/controls/DataSizeInBytes';
import { PerBytesField } from 'components/controls/PerBytesField';
import { CloudStorageSpaceMode } from 'core/enums';

import type { SubscriptionPlanModel } from 'core/interfaces';

export const panelValidate = (data: SubscriptionPlanModel, maxErrorTextTemplate = '-') => {
    const validator = new FormValidator(data);
    const gbError = `${formatStr(maxErrorTextTemplate, 1024 * 1024)}!immediately`;
    const tbError = `${formatStr(maxErrorTextTemplate, 1024)}!immediately`;
    const getMaxErrorText = (propName: string) => {
        const val = data[propName] as number;
        if (val && val % FileSizeUnitsInBytes.TB === 0) {
            return tbError;
        }

        return gbError;
    };

    if (data.cbRepoCountAlgorithm === CloudStorageSpaceMode.AllocatedSpaceMode) {
        validator
            .validate('cbRepoAllocatedSpacePrice')
            .number()
            .min(0)
            .max(1000000);
    }

    if (data.cbRepoCountAlgorithm === CloudStorageSpaceMode.ConsumedSpaceMode) {
        validator.validate('cbVmRepoUsedSpacePrice')
            .number()
            .min(0)
            .max(1000000);
        validator.validate('cbVmRepoUsedSpaceFree')
            .number()
            .min(0)
            .max(FileSizeUnitsInBytes.TB * 1024, getMaxErrorText('cbVmRepoUsedSpaceFree'));
    }

    if (data.cbRepoCountAlgorithm === CloudStorageSpaceMode.GranularSpaceMode) {
        validator.validate('cbPerformanceTierUsedSpacePrice')
            .number()
            .min(0)
            .max(1000000);
        validator.validate('cbCapacityTierUsedSpacePrice')
            .number()
            .min(0)
            .max(1000000);
        validator.validate('cbArchiveTierUsedSpacePrice')
            .number()
            .min(0)
            .max(1000000);
    }

    validator.validate('cbInsiderProtectionUsedSpacePrice')
        .number()
        .min(0)
        .max(1000000);

    validator
        .validate('cbVmTrafficPrice')
        .number()
        .min(0)
        .max(1000000);

    return validator.result();
};

interface Props extends WizardStepData<SubscriptionPlanModel> {
    hide: () => void;
}

export const CloudConnectServicesAdvanced = ({ hide, onDataChange, validationState, data }: Props) => {
    const lang = useLang();
    const [localData] = useState<SubscriptionPlanModel>(() => Object.assign({}, data));
    const currencyPer = formatStr(lang.CURRENCY_PER, data.currency);

    return (
        <SidePanelForm
            onRequestClose={hide}
            title={lang.VEEAM_CLOUD_CONNECT_BACKUP_ADVANCED_SETTINGS}
            description={lang.SELECT_SELECT_THE_CHARGING_MODEL}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        if (isValid(panelValidate, localData)) {
                            Object.assign(data, localData);
                            onDataChange(data);
                            hide();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hide,
                },
            ]}
        >
            <Form
                value={localData}
                validate={data => panelValidate(data, lang.MAX_VALUE_IS)}
                validationState={validationState}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <FormTitle>{capitalize(lang.CLOUD_REPOSITORY, true)}</FormTitle>
                    <FormDescription>{lang.SELECT_THE_CHARGING_MODEL}</FormDescription>

                    <LabelGroup
                        label={(
                            <Radiobutton
                                name='cbRepoCountAlgorithm'
                                value={CloudStorageSpaceMode.AllocatedSpaceMode}
                            >
                                {`${lang.ALLOCATED_SPACE_QUOTA}:`}
                            </Radiobutton>
                        )}
                    >
                        <FormLayout
                            disabled={localData.cbRepoCountAlgorithm !== CloudStorageSpaceMode.AllocatedSpaceMode}
                        >
                            <PerBytesField
                                valueName={'cbRepoAllocatedSpacePrice'}
                                unitsName={'cbRepoAllocatedSpaceMultiplier'}
                                suffix={currencyPer}
                            />
                        </FormLayout>
                    </LabelGroup>

                    <LabelGroup
                        label={(
                            <Radiobutton
                                name='cbRepoCountAlgorithm'
                                value={CloudStorageSpaceMode.ConsumedSpaceMode}
                            >
                                {`${lang.CONSUMED_SPACE}:`}
                            </Radiobutton>
                        )}
                    >

                        <FormLayout
                            disabled={localData.cbRepoCountAlgorithm !== CloudStorageSpaceMode.ConsumedSpaceMode}
                        >
                            <PerBytesField
                                valueName={'cbVmRepoUsedSpacePrice'}
                                unitsName={'cbVmRepoUsedSpaceMultiplier'}
                                suffix={currencyPer}
                            />

                            <DataSizeInBytes
                                name={'cbVmRepoUsedSpaceFree'}
                                label={lang.FREE_OF_CHARGE}
                                allowDecimal={false}
                            />
                        </FormLayout>
                    </LabelGroup>

                    <NoLabelGroup
                        content={(
                            <Radiobutton
                                name='cbRepoCountAlgorithm'
                                value={CloudStorageSpaceMode.GranularSpaceMode}
                            >
                                {lang.GRANULAR_FOR_EACH_SCALE_OUT_BACKUP}
                            </Radiobutton>
                        )}
                    />

                    <PerBytesField
                        label={`${lang.PERFORMANCE_TIER}:`}
                        valueName={'cbPerformanceTierUsedSpacePrice'}
                        unitsName={'cbPerformanceTierUsedSpaceMultiplier'}
                        suffix={currencyPer}
                        hasSubOffset={true}
                        disabled={localData.cbRepoCountAlgorithm !== CloudStorageSpaceMode.GranularSpaceMode}
                    />

                    <PerBytesField
                        label={`${lang.CAPACITY_TIER}:`}
                        valueName={'cbCapacityTierUsedSpacePrice'}
                        unitsName={'cbCapacityTierUsedSpaceMultiplier'}
                        suffix={currencyPer}
                        hasSubOffset={true}
                        disabled={localData.cbRepoCountAlgorithm !== CloudStorageSpaceMode.GranularSpaceMode}
                    />

                    <PerBytesField
                        label={`${lang.ARCHIVE_TIER}:`}
                        valueName={'cbArchiveTierUsedSpacePrice'}
                        unitsName={'cbArchiveTierUsedSpaceMultiplier'}
                        suffix={currencyPer}
                        hasSubOffset={true}
                        disabled={localData.cbRepoCountAlgorithm !== CloudStorageSpaceMode.GranularSpaceMode}
                    />

                    <FormTitle>{capitalize(lang.DELETED_BACKUPS_RECYCLE_BIN, true)}</FormTitle>

                    <NoLabelGroup
                        content={(
                            <FormLayout inlineLabel>
                                <FormDescription>{lang.SET_PRICE_FOR_STORAGE_CONSUMED}</FormDescription>

                                <PerBytesField
                                    label={`${lang.CONSUMED_SPACE}:`}
                                    valueName={'cbInsiderProtectionUsedSpacePrice'}
                                    unitsName={'cbInsiderProtectionUsedSpaceMultiplier'}
                                    suffix={currencyPer}
                                />
                            </FormLayout>
                        )}
                    />

                    <FormTitle>{capitalize(lang.DATA_TRANSFER_OUT, true)}</FormTitle>

                    <NoLabelGroup
                        content={(
                            <FormLayout inlineLabel>
                                <PerBytesField
                                    label={`${capitalize(RCOP.Lang.DATA_TRANSFER_OUT, true)}:`}
                                    valueName={'cbVmTrafficPrice'}
                                    unitsName={'cbVmTrafficMultiplier'}
                                    suffix={currencyPer}
                                />
                            </FormLayout>
                        )}
                    />
                </FormLayout>
            </Form>
        </SidePanelForm>
    );
};
