/**
 * Copyright © Veeam Software Group GmbH.
 */

import {
    TransportActions,
    TransportMethods,
} from '@veeam-vspc/core';

import type {
    RequestAdditionalParams,
    TransportActionsPreparedRequestData,
    Operation,
    TransportConfig,
} from '@veeam-vspc/core';

import type { VspcTransportActionsServices, VspcTransportBaseData } from '../interfaces';
import type { GlobalFiltersData } from '../../global-filters/interfaces';
import type { GlobalFiltersService } from '../../global-filters';

export class VspcTransportActions extends TransportActions {

    readonly globalFiltersService: GlobalFiltersService;

    constructor({ globalFiltersService, ...rest }: VspcTransportActionsServices, config: TransportConfig) {
        super(rest, config);

        this.globalFiltersService = globalFiltersService;
    }

    private hasAutoUpdate<T>(data: unknown): data is T {
        return data && data.hasOwnProperty('rcopAutoUpdate');
    }

    prepareRequest<T extends VspcTransportBaseData>(
        url: string,
        data?: T | Operation[],
        customOptions: RequestInit = {},
        params: RequestAdditionalParams = {},
    ): Promise<TransportActionsPreparedRequestData> {
        const isPatch = params.isRest && customOptions.method === TransportMethods.Patch;
        const isGet = params.isRest && customOptions.method === TransportMethods.Get;

        // TODO: remove when extjs gone
        this.globalFiltersService.setPortalUser(RCOP?.REACT_CURRENT_USER);

        const vspcData: T | Operation[] = data;

        if (!isPatch) {
            url = this.globalFiltersService.addGlobalFiltersToUrl(url, data as T, isGet);
        }

        if (!(this.hasAutoUpdate<T>(vspcData) && vspcData.rcopAutoUpdate)) {
            this.setLastActiveTime();
        }

        if (this.hasAutoUpdate<T>(vspcData) && params.isRest) {
            delete vspcData.rcopAutoUpdate;
        }

        // remove excess slash for avoid it duplicate in result url
        if (url[0] === '/' && !params.isRest) {
            url = url.substring(1);
        }

        return super.prepareRequest<T & GlobalFiltersData>(url, vspcData, customOptions, params);
    }

    beforeRequest<T extends VspcTransportBaseData>(
        preparedRequestData: TransportActionsPreparedRequestData,
        data?: T | Operation[],
        params: RequestAdditionalParams = {},
    ): Promise<TransportActionsPreparedRequestData> {
        if (this.config.getIsAutoUpdateBlocked() && data && (data as T).rcopAutoUpdate) {
            return this.rejectAuthError();
        }

        return Promise.resolve(preparedRequestData);
    }
}
