/**
 * Copyright © Veeam Software Group GmbH.
 */

import { Link } from '@veeam-vspc/components/src/index';
import React from 'react';

import { useLang } from 'views/providers/LangProvider/hooks';

export const SelectUserLink = ({ openPanel }: { openPanel: () => void; }) => {
    const lang = useLang();

    return (
        <Link onClick={openPanel}>
            {lang.SELECT}
        </Link>
    );
};
