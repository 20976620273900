/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Dialog,
    DIALOG_SIZE,
    TextColumn,
} from '@veeam-vspc/components';
import { transformRequestParams } from '@veeam-vspc/components/src/Grid/helpers';

import type { CellRendererProps } from '@veeam-vspc/components';
import type { Vb365RestorePoint } from '@veeam-vspc/models/rest';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ToolbarWithExport } from 'views/components/ToolbarWithExport';

import type { RestorePointsDialogProps } from '../../interfaces';

export const RestorePointsDialog: React.FC<RestorePointsDialogProps> = ({
    stateId,
    name,
    vb365ProtectedObjectId,
    vb365ServerUid,
    vb365BackupRepositoryUid,
    deactivate,
}) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const columns = [
        {
            field: 'backupTime',
            title: lang.DATE,
            cell: ({ cellData }: CellRendererProps<Vb365RestorePoint>) => <GridDateColumn value={cellData} />,
        },
        {
            field: 'processedObjectTypesStr',
            title: lang.PROCESSING_OPTIONS,
            cell: ({ rowData }: CellRendererProps<Vb365RestorePoint>) => (
                <TextColumn>{rowData.processedObjectTypesStr}</TextColumn>
            ),
        },
        {
            field: 'repositoryName',
            title: lang.TARGET_REPOSITORY,
            cell: ({ cellData }: CellRendererProps<Vb365RestorePoint>) => <TextColumn>{cellData}</TextColumn>,
        },
    ];

    return (
        <Dialog
            header={name}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: lang.CLOSE, onClick: deactivate },
            ]}
        >
            <AdvancedGrid
                stateId={stateId}
                defaultColumnWidth={100}
                columns={columns}
                data={
                    params => transportService
                        .get<{}, Vb365RestorePoint[]>(
                            `/protectedWorkloads/vb365ProtectedObjects/${vb365ProtectedObjectId}/restorePoints`,
                            {
                                ...transformRequestParams(params),
                                vb365ServerUid,
                                vb365BackupRepositoryUid,
                            }
                        )
                }
                toggleable={false}
                selection={{
                    field: 'id',
                    checkbox: false,
                    multiple: false,
                }}
                paginationLimit={500}
                toolbars={[
                    () => (
                        <ToolbarWithExport
                            idProperty='id'
                            RESTMethod={`protectedWorkloads/vb365ProtectedObjects/${vb365ProtectedObjectId}/restorePoints/`}
                            selectedOnly
                            extraParams={{ vb365ServerUid, vb365BackupRepositoryUid }}
                        />
                    ),
                ]}
            />
        </Dialog>
    );
};

