/**
 * Copyright © Veeam Software Group GmbH.
 */

import { capitalize } from '@veeam-vspc/core';

import type { AdvancedFilterValue, FilterGroup } from '@veeam-vspc/components';

import { JobTypes } from 'core/enums';
import backupJobTypeIconSrc from 'images/filters/job-types/backup.png';
import replicaJobTypeIconSrc from 'images/filters/job-types/replication.png';
import vcdReplicaJobTypeIconSrc from 'images/filters/job-types/vcd.png';
import sureBackupJobTypeIconSrc from 'images/filters/job-types/sure-backup.png';
import backupCopyJobTypeIconSrc from 'images/filters/job-types/backup-copy.png';
import vmTapeBackupJobTypeIconSrc from 'images/filters/job-types/backup-to-tape.png';
import vmCopyJobTypeIconSrc from 'images/filters/job-types/copy.png';
import sqlLogBackupJobTypeIconSrc from 'images/filters/job-types/sql-log-backup.png';
import oracleLogBackupJobTypeIconSrc from 'images/filters/job-types/oracle-log-backup.png';
import nutanixSnapshotJobTypeIconSrc from 'images/filters/job-types/nutanix.png';
import fileShareBackupJobTypeIconSrc from 'images/filters/protected-files/nas-backup.png';
import fileShareBackupCopyJobTypeIconSrc from 'images/filters/protected-files/nas-backup-copy.png';
import fileCopyJobTypeIconSrc from 'images/filters/protected-files/copy.png';
import fileTapeBackupJobTypeIconSrc from 'images/filters/protected-files/file-to-tape.png';
import cdpJobTypeIconSrc from 'images/filters/job-types/cdp.png';
import { useLang } from 'views/providers/LangProvider/hooks';

export interface UseJobTypesAdvancedFilterProps {
    propName?: string;
}

export const useJobTypesAdvancedFilter = ({ propName = 'types' }: UseJobTypesAdvancedFilterProps = {}): FilterGroup<AdvancedFilterValue> => {
    const lang = useLang();

    return {
        title: lang.TYPE,
        items: [
            {
                iconSrc: backupJobTypeIconSrc,
                label: lang.BACKUP,
                value: {
                    id: JobTypes.Backup,
                    name: propName,
                },
            },
            {
                iconSrc: replicaJobTypeIconSrc,
                label: lang.REPLICATION,
                value: {
                    id: JobTypes.Replica,
                    name: propName,
                },
            },
            {
                iconSrc: vcdReplicaJobTypeIconSrc,
                label: lang.VMWARE_CLOUD_DIRECTOR_REPLICATION,
                value: {
                    id: JobTypes.VcdReplica,
                    name: propName,
                },
            },
            {
                iconSrc: sureBackupJobTypeIconSrc,
                label: lang.SURE_BACKUP,
                value: {
                    id: JobTypes.SureBackup,
                    name: propName,
                },
            },
            {
                iconSrc: backupCopyJobTypeIconSrc,
                label: lang.BACKUP_COPY,
                value: {
                    id: JobTypes.BackupCopy,
                    name: propName,
                },
            },
            {
                iconSrc: vmTapeBackupJobTypeIconSrc,
                label: lang.BACKUP_TO_TAPE,
                value: {
                    id: JobTypes.VmTapeBackup,
                    name: propName,
                },
            },
            {
                iconSrc: vmCopyJobTypeIconSrc,
                label: lang.VM_COPY,
                value: {
                    id: JobTypes.VmCopy,
                    name: propName,
                },
            },
            {
                iconSrc: sqlLogBackupJobTypeIconSrc,
                label: lang.SQL_DATABASE_LOG_BACKUP_JOB,
                value: {
                    id: JobTypes.SqlLogBackup,
                    name: propName,
                },
            },
            {
                iconSrc: oracleLogBackupJobTypeIconSrc,
                label: lang.ORACLE_DATABASE_LOG_BACKUP_JOB,
                value: {
                    id: JobTypes.OracleLogBackup,
                    name: propName,
                },
            },
            {
                iconSrc: nutanixSnapshotJobTypeIconSrc,
                label: lang.STORAGE_SNAPSHOT,
                value: {
                    id: JobTypes.NutanixSnapshotJob,
                    name: propName,
                },
            },
            {
                iconSrc: cdpJobTypeIconSrc,
                label: lang.CDP_POLICY,
                value: {
                    id: JobTypes.CdpReplica,
                    name: propName,
                },
            },
        ],
    };
};

export const useJobFileTypesAdvancedFilter = ({ propName = 'types' }: UseJobTypesAdvancedFilterProps = {}): FilterGroup<AdvancedFilterValue> => {
    const lang = useLang();

    return {
        title: lang.TYPE,
        items: [
            {
                iconSrc: fileShareBackupJobTypeIconSrc,
                label: capitalize(lang.FILE_SHARE_BACKUP, true),
                value: {
                    id: JobTypes.Nas,
                    name: propName,
                },
            },
            {
                iconSrc: fileShareBackupCopyJobTypeIconSrc,
                label: lang.FILE_SHARE_BACKUP_COPY,
                value: {
                    id: JobTypes.NasBackupCopy,
                    name: propName,
                },
            },
            {
                iconSrc: fileCopyJobTypeIconSrc,
                label: lang.FILE_COPY,
                value: {
                    id: JobTypes.FileCopy,
                    name: propName,
                },
            },
            {
                iconSrc: fileTapeBackupJobTypeIconSrc,
                label: lang.FILE_TO_TAPE,
                value: {
                    id: JobTypes.FileTapeBackup,
                    name: propName,
                },
            },
        ],
    };
};
