/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum PortalUserCreationSources {
    Unknown,
    AD,
    Win,
    VAC,
    VBR,
    VCD,
}
