/**
 * Copyright © Veeam Software Group GmbH.
 */

export enum Product {
    Unknown,
    CloudConnect,
    VBR,
    VONE,
    VBM,
    VBA,
}