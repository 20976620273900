/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    Combobox,
    Form,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
} from '@veeam-vspc/components';
import { EPrincipalRoleTypeRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep } from '@veeam-vspc/components';
import type { WizardStepData } from '@veeam-vspc/components/src/index';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { NoteBar } from 'components/layouts/NoteBar';
import { getAdminDocUrl } from '../../../Base/helpers/getAdminDocUrl';
import { useAppStore } from 'views/providers/AppProvider/hooks';

import type { AdminUserModel } from '../../interfaces';

export const Role: React.FC<WizardStepData<AdminUserModel>> = ({ data, validationState, onDataChange }) => {
    const lang = useLang();
    const { portalUser } = useAppStore();
    const roles = [
        { value: EPrincipalRoleTypeRepresentation.Readonly, text: lang.PORTAL_READONLY_OPERATOR },
        { value: EPrincipalRoleTypeRepresentation.Operator, text: lang.PORTAL_OPERATOR },
    ];

    if (portalUser.isPortalAdministrator()) {
        roles.push({ value: EPrincipalRoleTypeRepresentation.Administrator, text: lang.PORTAL_ADMINISTRATOR });
    }

    return (
        <Form
            value={data}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={lang.ROLE}
                description={lang.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='model.roleType'
                        data={roles}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        label={lang.ROLE}
                        inlineLabel
                    />

                    <NoteBar>
                        {data.model.roleType === EPrincipalRoleTypeRepresentation.Readonly && <p>{lang.READ_ONLY_USERS_CAN_MONITOR_DATA}</p>}
                        {data.model.roleType === EPrincipalRoleTypeRepresentation.Operator && <p>{lang.PORTAL_OPERATOR_CAN_PERFORM}</p>}
                        {data.model.roleType === EPrincipalRoleTypeRepresentation.Administrator && <p>{lang.PORTAL_ADMINISTRATOR_CAN_PERFORM}</p>}

                        <p>
                            {lang.CLICK}
                            {' '}

                            <a
                                href={getAdminDocUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {lang.HERE}
                            </a>

                            {' '}
                            {lang.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<AdminUserModel> => ({
    title,
    render: data => <Role {...data} />,
});
