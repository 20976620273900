/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { ConfiguratorContext } from '../stores';

import type { ConfiguratorService } from 'core/services';


export const useConfigurator = (): ConfiguratorService => useContext<ConfiguratorService>(ConfiguratorContext);
