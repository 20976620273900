/**
 * Copyright © Veeam Software Group GmbH.
 */

import { PortalUserRoles } from '../../../entries/portal-user/enums';
import { baseConfig } from './base.config';

import type { BaseUserConfig } from '../interfaces';

export const portalOperatorConfig: BaseUserConfig = {
    baseConfig: baseConfig,
    roleInt: PortalUserRoles.PortalOperator,
    roleName: PortalUserRoles[PortalUserRoles.PortalOperator] as keyof typeof PortalUserRoles,
    routes: [],
    sections: [],
};
