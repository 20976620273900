/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ColumnTexts, TextColumn } from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';
import { Vb365Job } from '@veeam-vspc/models/rest';

import type { CellRendererProps } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';

export const JobTypeColumn: React.FC<CellRendererProps<Vb365Job>> = ({ rowData }) => {
    const lang = useLang();
    const { jobType } = rowData;

    const typeMapper = {
        [Vb365Job.JobTypeEnum.BackupJob]: lang.BACKUP,
        [Vb365Job.JobTypeEnum.CopyJob]: capitalize(lang.BACKUP_COPY),
        [Vb365Job.JobTypeEnum.Unknown]: ColumnTexts.DashSymbol,
    };

    return (
        <TextColumn>
            {typeMapper[jobType]}
        </TextColumn>
    );
};

