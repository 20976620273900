/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { GridSortDirections, TextColumn } from '@veeam-vspc/components';
import { capitalize } from '@veeam-vspc/core';

import type { CellRendererProps, RequestParams } from '@veeam-vspc/components';
import type { PublicCloudEfsJobModel, PublicCloudEfsJobParam } from '@veeam-vspc/models/web-controllers';

import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { FileSharesPublicCloudsActionToolbar, FileSharesPublicCloudsFilterToolbar } from './components';
import { StatusAgoColumn } from '../../../StatusAgoColumn';
import { GridDateColumn } from 'views/components/columns/GridDateColumn';
import { ConfigSectionIds } from 'core/services';

export interface FileSharesPublicCloudsProps {
    stateId?: string;
}

export const FileSharesPublicClouds: React.FC<FileSharesPublicCloudsProps> = ({ stateId }) => {
    const lang = useLang();
    const { transportService } = useAppServices();
    const gridStateId = `${stateId}.FileSharesPublicClouds`;
    const defaultColumnRender = ({ cellData }: CellRendererProps<PublicCloudEfsJobModel>) => <TextColumn>{cellData}</TextColumn>;
    const columns = [
        {
            field: 'vmName',
            title: lang.NAME,
            cell: defaultColumnRender,
        },
        {
            field: 'vbServerName',
            title: lang.BACKUP_APPLIANCE,
            cell: defaultColumnRender,
        },
        {
            field: 'company',
            title: lang.COMPANY,
            cell: defaultColumnRender,
        },
        {
            field: 'site',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'location',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'resourceId',
            title: lang.RESOURCE_ID,
            cell: defaultColumnRender,
        },
        {
            field: 'platformTypeName',
            title: lang.PLATFORM,
            cell: defaultColumnRender,
        },
        {
            field: 'jobName',
            title: lang.POLICY,
            cell: defaultColumnRender,
        },
        {
            field: 'enabled',
            title: lang.POLICY_STATE,
            cell: ({ cellData }: CellRendererProps<PublicCloudEfsJobModel>) => (
                <TextColumn>{cellData ? lang.ENABLED : lang.DISABLED}</TextColumn>
            ),
            hidden: true,
        },
        {
            field: 'lastBackup.sessionStateName',
            title: lang.LAST_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudEfsJobModel>) => (
                <StatusAgoColumn
                    url={'/PublicCloudEfsJob/GetBackupTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastBackup}
                />
            ),
        },
        {
            field: 'lastBackupCopy.sessionStateName',
            title: lang.LAST_REPLICA_SNAPSHOT,
            cell: ({ rowData }: CellRendererProps<PublicCloudEfsJobModel>) => (
                <StatusAgoColumn
                    url={'/PublicCloudEfsJob/GetBackupCopyTasksFailureMessages'}
                    backupServerUid={rowData.backupServerUid}
                    vmUid={rowData.vmUid}
                    platform={rowData.platformType}
                    session={rowData.lastBackupCopy}
                />
            ),
        },
        {
            field: 'nextRun',
            title: lang.NEXT_RUN,
            cell: ({ cellData }: CellRendererProps<PublicCloudEfsJobModel>) => (
                <GridDateColumn value={cellData} />
            ),
            hidden: true,
        },
        {
            field: 'backupServerName',
            title: lang.SERVER_NAME,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'targetVault',
            title: capitalize(lang.BACKUP_TARGET),
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'backupCopyTarget',
            title: lang.BACKUP_COPY_TARGET,
            cell: defaultColumnRender,
            hidden: true,
        },
    ];

    return (
        <AdvancedGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.BackupJobsFileSharesPublicClouds}
            defaultColumnWidth={100}
            initialState={{
                sort: {
                    key: 'status',
                    direction: GridSortDirections.Asc,
                },
            }}
            columns={columns}
            data={(requestParams: RequestParams<PublicCloudEfsJobParam>) => transportService
                .request<typeof requestParams, PublicCloudEfsJobModel[]>('/PublicCloudEfsJob/GetEfsJob', { ...requestParams })}
            isRowInactive={({ rowData }) => !rowData.enabled}
            toggleable={true}
            selection={{
                field: 'id',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <FileSharesPublicCloudsFilterToolbar />,
                () => <FileSharesPublicCloudsActionToolbar idProperty={'id'} selectedOnly />,
            ]}
        />
    );
};
