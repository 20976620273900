/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { capitalize } from '@veeam-vspc/core';
import { GridColumnJustify, GridSortDirections, TextColumn } from '@veeam-vspc/components';

import type { CellRendererProps, RequestParams } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { ProtectedVirtualMachineRestoreTypes } from 'core/enums';
import { useAppServices } from 'views/providers/AppProvider/hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { RestorePointSizeColumn } from 'views/components/columns/RestorePointSizeColumn';
import { TimeAgoColumn } from 'views/components/columns/TimeAgoColumn';
import { VirtualInfrastructureColumn, VirtualInfrastructureToolbar } from './components';
import { ConfigSectionIds } from 'core/services';

import type { VirtualInfrastructureFilterModel, VirtualInfrastructureItem } from './interfaces';

export interface VirtualInfrastructureProps {
    stateId: string;
}

export const VirtualInfrastructure: React.FC<VirtualInfrastructureProps> = ({ stateId }) => {
    const { transportService, enumMapperService } = useAppServices();
    const lang = useLang();
    const gridStateId = `${stateId}.VirtualInfrastructure`;
    const platformType = 0; // old value for "Virtual"
    const defaultColumnRender = ({ cellData }: CellRendererProps<VirtualInfrastructureItem>) => <TextColumn>{cellData}</TextColumn>;
    const columns = [
        {
            field: 'vmName',
            title: lang.VM,
            cell: defaultColumnRender,
        },
        {
            field: 'platform',
            hidden: true,
            title: lang.PLATFORM,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <TextColumn>
                    {enumMapperService.getEnumDescription('ProtectedVmVirtualPlatformType', rowData.platform)}
                </TextColumn>
            ),
        },
        {
            field: 'serverName',
            hidden: true,
            title: capitalize(lang.BACKUP_SERVER),
            cell: defaultColumnRender,
        },
        {
            field: 'siteName',
            title: lang.SITE,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'companyName',
            title: lang.COMPANY,
            cell: defaultColumnRender,
        },
        {
            field: 'locationName',
            title: lang.LOCATION,
            cell: defaultColumnRender,
            hidden: true,
        },
        {
            field: 'sourceSize',
            title: lang.SOURCE_SIZE,
            cell: ({ cellData }: CellRendererProps<VirtualInfrastructureItem>) => <RestorePointSizeColumn value={cellData as any} />,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backup.restorePoints',
            title: lang.BACKUPS,
            width: 90,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <VirtualInfrastructureColumn
                    value={rowData.backup?.restorePoints}
                    stateId={`${gridStateId}.Dialogs.VirtualInfrastructure.Backup`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.serverId,
                        platformType,
                        protectionType: ProtectedVirtualMachineRestoreTypes.Backup,
                        vmInstanceUid: rowData.instanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backup.lastRestorePointDate',
            hidden: true,
            title: lang.LAST_BACKUP,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <TimeAgoColumn
                    value={rowData.backup?.lastRestorePointDate}
                />
            ),
        },
        {
            field: 'backup.backupSize',
            title: lang.BACKUP_SIZE,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <RestorePointSizeColumn
                    value={rowData.backup?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backup.destination',
            title: capitalize(lang.BACKUP_TARGET),
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => <TextColumn>{rowData.backup?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'replication.restorePoints',
            title: lang.REPLICAS,
            width: 90,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <VirtualInfrastructureColumn
                    value={rowData.replication?.restorePoints}
                    stateId={`${gridStateId}.Dialogs.VirtualInfrastructure.Replica`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.serverId,
                        platformType,
                        protectionType: ProtectedVirtualMachineRestoreTypes.Replica,
                        vmInstanceUid: rowData.instanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'replication.lastRestorePointDate',
            hidden: true,
            title: lang.LAST_REPLICATION,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <TimeAgoColumn
                    value={rowData.replication?.lastRestorePointDate}
                />
            ),
        },
        {
            field: 'replication.destination',
            title: lang.REPLICATION_TARGET,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => <TextColumn>{rowData.replication?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'cdpReplication.restorePoints',
            title: lang.CDP_REPLICAS,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <VirtualInfrastructureColumn
                    value={rowData.cdpReplication?.restorePoints}
                    stateId={`${gridStateId}.Dialogs.VirtualInfrastructure.CdpReplica`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.serverId,
                        platformType,
                        protectionType: ProtectedVirtualMachineRestoreTypes.CdpReplica,
                        vmInstanceUid: rowData.instanceUid,
                    }}
                    vmInstanceUid={rowData.vmInstanceUid}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'cdpReplication.lastRestorePointDate',
            hidden: true,
            title: lang.LAST_CDP_REPLICATION,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <TimeAgoColumn
                    value={rowData.cdpReplication?.lastRestorePointDate}
                />
            ),
        },
        {
            field: 'cdpReplication.destination',
            title: lang.CDP_REPLICATION_TARGET,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => <TextColumn>{rowData.cdpReplication?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'backupCopy.restorePoints',
            title: lang.BACKUP_COPIES,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <VirtualInfrastructureColumn
                    value={rowData.backupCopy?.restorePoints}
                    stateId={`${gridStateId}.Dialogs.VirtualInfrastructure.Copy`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.serverId,
                        platformType,
                        protectionType: ProtectedVirtualMachineRestoreTypes.Copy,
                        vmInstanceUid: rowData.instanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backupCopy.lastRestorePointDate',
            hidden: true,
            title: lang.LAST_BACKUP_COPY,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <TimeAgoColumn
                    value={rowData.backupCopy?.lastRestorePointDate}
                />
            ),
        },
        {
            field: 'backupCopy.backupSize',
            title: lang.BACKUP_COPY_SIZE,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <RestorePointSizeColumn
                    value={rowData.backupCopy?.backupSize}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'backupCopy.destination',
            title: lang.BACKUP_COPY_TARGET,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => <TextColumn>{rowData.backupCopy?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'tape.restorePoints',
            title: lang.BACKUPS_ON_TAPE,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <VirtualInfrastructureColumn
                    value={rowData.tape?.restorePoints}
                    stateId={`${gridStateId}.Dialogs.VirtualInfrastructure.Tape`}
                    name={rowData.vmName}
                    extraParams={{
                        backupServerUid: rowData.serverId,
                        platformType,
                        protectionType: ProtectedVirtualMachineRestoreTypes.Tape,
                        vmInstanceUid: rowData.instanceUid,
                    }}
                />
            ),
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
            hidden: true,
        },
        {
            field: 'tape.lastRestorePointDate',
            hidden: true,
            title: lang.LAST_BACKUP_ON_TAPE,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <TimeAgoColumn
                    value={rowData.tape?.lastRestorePointDate}
                />
            ),
        },
        {
            field: 'tape.backupSize',
            title: lang.BACKUPS_ON_TAPE_SIZE,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => (
                <RestorePointSizeColumn
                    value={rowData.tape?.backupSize}
                />
            ),
            hidden: true,
            headerJustify: GridColumnJustify.Right,
            justify: GridColumnJustify.Right,
        },
        {
            field: 'tape.destination',
            title: lang.TAPE_MEDIA_POOL,
            cell: ({ rowData }: CellRendererProps<VirtualInfrastructureItem>) => <TextColumn>{rowData.tape?.destination}</TextColumn>,
            hidden: true,
        },
        {
            field: 'resourceId',
            title: lang.RESOURCE_ID,
            cell: defaultColumnRender,
            hidden: true,
        },
    ];

    return (
        <AdvancedGrid
            stateId={gridStateId}
            sectionId={ConfigSectionIds.ProtectedDataVirtualMachinesVirtualInfrastructure}
            initialState={{
                sort: {
                    direction: GridSortDirections.Asc,
                    key: columns?.[0]?.field,
                },
            }}
            defaultColumnWidth={100}
            columns={columns}
            data={(requestParams: RequestParams<VirtualInfrastructureFilterModel>) => transportService
                .request<typeof requestParams, VirtualInfrastructureItem[]>('/ProtectedWorkloads/GetVms', { ...requestParams })}
            toggleable={true}
            selection={{
                field: 'instanceUid',
                checkbox: true,
                multiple: true,
            }}
            toolbars={[
                () => <VirtualInfrastructureToolbar idProperty={'instanceUid'} selectedOnly />,
            ]}
        />
    );
};
