/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { INDENT, StackView, Text } from '@veeam-vspc/components';

export interface JobStatisticsLabelTextProps {
    text: React.ReactNode;
    isFirst?: boolean;
    isBold?: boolean;
}

export const JobStatisticsLabelText: React.FC<JobStatisticsLabelTextProps> = ({ text, isFirst = false, isBold = false }) => (
    <StackView spaceLeft={isFirst ? INDENT.s : INDENT.none}>
        <Text style={isBold ? { fontWeight: 'bold' } : {}}>{text}</Text>
    </StackView>
);
