/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { Combobox, FieldLayout } from '@veeam/components';
import { useAppStorage } from '@veeam-vspc/components';

import type { ComboboxProps } from '@veeam-vspc/components';

interface ComboboxWithStateProps extends ComboboxProps<any, any> {
    label?: string;
    stateId: string;
}

export const StatefulCombobox = (props: ComboboxWithStateProps) => {
    const appStorage = useAppStorage();
    const { label = null, onChange, stateId, value, ...restProps } = props;

    if (!appStorage.getObjItem(stateId)) {
        appStorage.setObjItem(stateId, value);
    } else {
        onChange && onChange(appStorage.getObjItem(stateId), 0);
    }

    return (
        <FieldLayout
            inlineLabel
            label={label}
        >
            <Combobox
                {...restProps}
                value={appStorage.getObjItem(stateId)}
                onChange={(v, i) => {
                    appStorage.setObjItem(stateId, v);
                    onChange && onChange(v, i);
                }}
            />
        </FieldLayout>
    );
};
