/**
 * Copyright © Veeam Software Group GmbH.
 */

import { colors as palette } from '@veeam/components/lib/themes/colors/colors';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import type { Options, SeriesOptionsType } from 'highcharts';

import { MapTooltip } from '../MapTooltip';
import { checkMapLayer, MapLayer } from 'views/pages/OverviewPage/helpers/check-map-layer';

import type { Point } from 'views/pages/OverviewPage/interfaces';
import type { MapStore } from 'views/pages/OverviewPage/interfaces/map-store';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const getMapOptions = (
    mapStore: MapStore,
    lang: LangsServiceBase,
): Options => ({
    chart: {
        map: mapStore.map,
        style: {
            fontFamily: '"Open Sans", sans-serif',
        },
        animation: false,
    },
    title: {
        text: '',
    },
    legend: {
        enabled: true,
        itemStyle: {
            fontWeight: 'normal',
            cursor: 'default',
        },
    },
    credits: {
        enabled: false,
    },
    mapNavigation: {
        enabled: true,
        buttonOptions: {
            align: 'right',
            verticalAlign: 'middle',
        },
    },
    tooltip: {
        backgroundColor: palette.G000,
        borderColor: palette.G300,
        borderRadius: 0,
        hideDelay: 0,
        useHTML: true,
        formatter: function() {
            const point = this.point as unknown as Point;

            return renderToStaticMarkup(<MapTooltip lang={lang} {...point} />);
        },
        shape: 'square',
    },
    plotOptions: {
        map: {
            allAreas: false,
            joinBy: ['hc-key', 'mapId'],
            tooltip: {
                headerFormat: '',
                followPointer: true,
            },
            dataLabels: {
                enabled: true,
                color: '#444444',
                borderColor: '#FFFFFF',
                style: {
                    fontSize: '12px',
                },
                formatter: function() {
                    return this.point.name;
                },
            },
        },
        series: {
            states: {
                inactive: {
                    enabled: false,
                },
            },
            point: {
                events: {
                    click: function() {
                        const mapId = this['mapId'] as string;

                        const mapLayer = checkMapLayer(mapId);

                        if (mapLayer === MapLayer.Region) {
                            return;
                        }

                        mapStore.downloadMap(mapId);
                        mapStore.handleMapChange(mapId);
                    },
                },
            },
            events: {
                legendItemClick: (e) => {
                    e.preventDefault();
                },
            },
        },
    },
    exporting: {
        enabled: false,
    },
    series: [
        {
            name: lang.NO_INFO,
            color: '#EEEEEE',
            showInLegend: false,
            data: mapStore.map.features.map(feature => ({ mapId: feature.properties['hc-key'] })),
            enableMouseTracking: false,
            dataLabels: {
                enabled: false,
            },
        },
        {
            name: lang.ERROR,
            color: '#E8595A',
            data: mapStore.series.errorSeriesData,
        },
        {
            name: lang.WARNING,
            color: '#F2C973',
            data: mapStore.series.warningSeriesData,
        },
        {
            name: lang.HEALTHY,
            color: '#54B948',
            data: mapStore.series.successSeriesData,
        },
    ] as Array<SeriesOptionsType>,
});
