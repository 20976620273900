/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Checkbox,
    CONTROL_SIZE,
    Form,
    FormLayout,
    FormValidator,
    isValid,
    LinkButton,
    Spacer,
    STACK_DIRECTION,
    Textarea,
    useExternalFormApi,
} from '@veeam-vspc/components';
import { formatStr } from '@veeam-vspc/core';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/use-page-wizard-store';

import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { CloudTenantType } from 'core/enums/cloud-tenant-type';
import { DisplayField } from 'components/controls/DisplayField';
import { CheckItemExistType } from 'core/enums/check-item-exist-type';
import { CommonService } from 'core/services/common-service/common-service';
import { allowEditVcd } from '../../utils';
import { useCompanyWizardStore } from '../../hooks';
import { getOrganization, getOrganizationData } from './constants';
import { Organizations } from './components/Organizations';

import type { IsItemExistsParams } from 'core/interfaces/is-item-exists-params';
import type { StepData } from './interfaces';
import type { CompanyData } from '../../interfaces';
import type { CompanyWizardStore } from '../../stores';
import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';

const formValidate = (data: CompanyData, lang: any) => {
    const validator = new FormValidator(data);

    validator.validate('description')
        .string()
        .maxLength(1000);

    return validator.result();
};

const stepValidate = async(data: CompanyData, lang: any, isEdit: boolean, wizardStore: CompanyWizardStore, stepData: StepData) => {
    if (isValid(value => formValidate(value, lang), data)) {
        let message = null;

        if (!data.vcdOrganizationUid) {
            message = `${lang.SELECT_THE_VMWARE_CLOUD_DIRECTOR}.`;
        } else {
            const organizationData = getOrganizationData(wizardStore.vcdOrganizationsDataItemsCache.value, data.vcdOrganizationUid);
            if (!isEdit && organizationData) {
                const tenantQuota = organizationData.tenantQuota || 0;
                const tenantQuotaUsage = organizationData.tenantQuotaUsage || -1;

                if (tenantQuota > -1 && tenantQuotaUsage >= tenantQuota) {
                    message = RCOP.Lang.CANNOT_CREATE_A_NEW_COMPANY_PLEASE_SELECT;
                }
            }

            if (!message && wizardStore.vcdOrganizationsDataItemsCache.value) {
                const checkData: IsItemExistsParams = {
                    id: isEdit ? data.userId : null,
                    type: CheckItemExistType.Tenant,
                    name: getOrganization(wizardStore.vcdOrganizationsDataItemsCache.value, data.vcdOrganizationUid).organizationName,
                    cloudConnectAgentUid: data.cloudConnectAgentUid,
                };

                if (await CommonService.isItemExist(checkData)) {
                    message = formatStr(lang.WITH_THE_SAME_NAME_ALREADY_EXISTS, lang.VMWARE_CLOUD_DIRECTOR_COMPANY);
                }
            }
        }

        if (message) {
            core.notificationService.error(lang.ERROR, message);
            stepData?.setBrowseButtonError?.(true);
            return false;
        } else {
            return true;
        }
    }

    return false;
};

export const getVcdUserInfoStep = (lang: LangsServiceBase, wizardStore: CompanyWizardStore): WizardStep<CompanyData> => ({
    title: lang.USER_INFO,
    validate: ({ data, isEdit, stepData }) => stepValidate(data, lang, isEdit, wizardStore, stepData),
    render: stepData => <VcdUserInfoStep {...stepData} />,
    isHidden: ({ data }) => data.tenantType !== CloudTenantType.Vcd,
});

const VcdUserInfoStep = observer((wizardStepData: WizardStepData<CompanyData>) => {
    const lang = useLang();
    const { data, validationState, onDataChange, isEdit } = wizardStepData;
    const formApi = useExternalFormApi();
    const wizardContext = usePageWizardStore<CompanyData>();
    const wizardStore = useCompanyWizardStore();
    const showOrganizations = () => wizardContext.openPanel(hide => <Organizations {...wizardStepData} hidePanel={hide} />);

    const [browseButtonError, setBrowseButtonError] = useState(false);
    (wizardStepData.stepData as StepData).setBrowseButtonError = setBrowseButtonError;

    useEffect(() => {
        wizardStore.checkIsRestAvailable(data.id);
        wizardStore.vcdOrganizationsDataItemsCache.load({
            cloudConnectAgentUid: isEdit && !allowEditVcd(data.companyStatus) ? data.cloudConnectAgentUid : null,
            vcdOrganizationUid: isEdit && !allowEditVcd(data.companyStatus) ? data.vcdOrganizationUid : null,
        });
    }, []);

    return (
        <StepLayout
            title={lang.USER_INFO}
            description={lang.SELECT_A_HOSTING_SITE}
        >
            <Form
                value={data}
                validate={(data: CompanyData) => formValidate(data, lang)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout>
                    {wizardStore.isRestAvailable &&
                        <Checkbox name={'restEnabled'}>{lang.ENABLE_ACCESS_TO_REST_API}</Checkbox>}

                    <FormLayout inlineLabel direction={STACK_DIRECTION.row}>
                        <DisplayField
                            isTruncate
                            label={lang.VMWARE_CLOUD_DIRECTOR_ORGANIZATION}
                            value={
                                getOrganization(wizardStore.vcdOrganizationsDataItemsCache.value, data.vcdOrganizationUid)?.cloudConnectResourceName
                            }
                        />

                        <Spacer />

                        <LinkButton
                            error={browseButtonError}
                            onClick={showOrganizations}
                            disabled={isEdit && !allowEditVcd(data.companyStatus)}
                        >
                            {lang.BROWSE}
                        </LinkButton>
                    </FormLayout>

                    <Textarea
                        name={'description'}
                        label={lang.DESCRIPTION}
                        size={CONTROL_SIZE.full}
                        rows={10}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
