/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { htmlDecode } from '@veeam-vspc/core';

import { sanitize } from '../../../core/utils';

export interface SanitizedInnerHtmlProps {
    html: string;
    isPreSanitized?: boolean;
}

export const SanitizedInnerHtml: React.FC<SanitizedInnerHtmlProps> = ({ html, isPreSanitized = false }) => (
    // eslint-disable-next-line @typescript-eslint/naming-convention
    <div dangerouslySetInnerHTML={{ __html: isPreSanitized ? html : htmlDecode(sanitize(html)) }} />
);
