/**
 * Copyright © Veeam Software Group GmbH.
 */

import { constants, STACK_ALIGN, STACK_DISTRIBUTION, StackView } from '@veeam-vspc/components';
import React from 'react';

import { FailedLabel, SuccessLabel, WarningLabel } from './Legend.styled';

export const Legend: React.FC = () => (
    <StackView
        distribution={STACK_DISTRIBUTION.center}
        align={STACK_ALIGN.center}
        style={{
            height: constants.SIZE_XXL,
        }}
    >
        <FailedLabel>Failed</FailedLabel>
        <WarningLabel>Warning</WarningLabel>
        <SuccessLabel>Success</SuccessLabel>
    </StackView>
);
