/**
 * Copyright © Veeam Software Group GmbH.
 */

import { PortalUserRoles } from '../../entries/portal-user/enums';
import { ConfigService } from './services';

import type { UserConfig } from './interfaces';
import type { RouteConfigItem, SectionConfigItem } from './entries';

export class ConfiguratorService {

    userRoleName: keyof typeof PortalUserRoles = PortalUserRoles[PortalUserRoles.Unknown] as keyof typeof PortalUserRoles;

    protected routes: UserConfig['routes'] = [];
    protected sections: Record<string, SectionConfigItem> = {};

    protected configService: ConfigService;

    constructor(protected userRole: PortalUserRoles) {
        this.configService = new ConfigService();
    }

    loadConfig(): Promise<void> {
        return this.configService.getConfigFor(this.userRole)
            .then((config) => {
                this.setConfig(config);
            });
    }

    getRoutesConfig(): RouteConfigItem[] {
        // @TODO Do RouteConfigItem as entry class if it needed
        return this.routes;
    }

    getSectionConfig(sectionId: string): SectionConfigItem | null {
        return this.sections[sectionId] || null;
    }

    protected setConfig({ roleName, routes, sections }: UserConfig): void {
        this.userRoleName = roleName;
        this.routes = routes;
        this.sections = Object.fromEntries(sections.map(section => [section.id, section]));
    }

}
