/**
 * Copyright © Veeam Software Group GmbH.
 */

import { XmlFileParams } from '../../../enums';
import { getTagValueAttr, getXmlTag } from '../../../helpers';

export const checkReboot = (xml: XMLDocument): boolean => {
    const rebootTag = getXmlTag(xml, XmlFileParams.RebootIfRequired);

    return [XmlFileParams.DeclineValue, undefined, ''].includes(getTagValueAttr(rebootTag));
};
