/**
 * Copyright © Veeam Software Group GmbH.
 */

import { OAuth2ClientSettings, SmtpSettings } from '@veeam-vspc/models/rest';

import type { UseModalActions } from '@veeam-vspc/components';
import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { OAuth2SignInParameters, NotificationSettings, Oauth2SigninBody } from '@veeam-vspc/models/rest';

import { URL_PARAMS } from 'core/const';
import { core } from 'core/core-module';
import { trimTextFields } from 'core/utils';
import { useEmailServerSettingsStore } from '../../../hooks';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useAppStore } from 'views/providers/AppProvider/hooks';
import { AuthTypes, FormFieldNames, UrlProtocols } from '../enums';
import { useEmailServerSettingsDialogParams } from '../../../hooks/use-email-server-settings-dialog-params';

import type { EmailServerSettingsFormData, SmtpFormData, UntrustedCertificateDialogData } from '../interfaces';

export interface ControllerReturnedValues {
    dialogTitle: string;
    onFormChange: (value: EmailServerSettingsFormData) => Promise<void>;
    onSaveActionClick: (callback: UseModalActions['deactivate']) => void;
}

export const useEmailServerSettingsDialogController = (untrustedCertDialogActions: UseModalActions<UntrustedCertificateDialogData>):
    ControllerReturnedValues => {

    const lang = useLang();
    const dialogTitle = lang.EMAIL_SERVER_SETTINGS;
    const { portalUser: { organizationUid, userRole } } = useAppStore();
    const serverSettingsStore = useEmailServerSettingsStore();
    const validationState = serverSettingsStore.storeFormValidationState;
    const originalEmailServerSettings = serverSettingsStore.originalEmailServerSettings;
    const { onSuccessfulServerSettingsPatchCb, setIsLoading } = useEmailServerSettingsDialogParams();

    const saveEmailServerSettings = (
        originalData: NotificationSettings,
        currentData: NotificationSettings,
        deactivateModalCb: UseModalActions['deactivate'],
    ): void => {
        setIsLoading(true);

        core.transportService
            .patch(`/organizations/${organizationUid}/configuration/notification`,
                {
                    original: originalData,
                    current: currentData,
                }
            )
            .then(deactivateModalCb)
            .then(onSuccessfulServerSettingsPatchCb)
            .then(() => setIsLoading(false))
            .then(() => core.notificationService.success(
                lang.EMAIL_SERVER_SETTINGS,
                lang.EMAIL_SERVER_SETTINGS_HAVE_BEEN_SAVED
            ));
    };

    const onFormChange = async(formData: EmailServerSettingsFormData): Promise<void> => {
        const formApi = serverSettingsStore.storeFormApi;
        const { useAuth, smtp, authType } = (serverSettingsStore.storeFormData = formData);

        const isDirty = formApi.isDataChanged();
        const password = smtp.passwordCredential.password;
        const userName = smtp.passwordCredential.userName;

        if (isDirty && useAuth && (password === serverSettingsStore.defaultPasswordValue)) {
            await formApi.setValue(FormFieldNames.Password, '');
            serverSettingsStore.passwordInputHasEye = true;
        }

        if (!useAuth && userName) {
            await formApi.setValue(FormFieldNames.UserName, '');
            await formApi.setValue(FormFieldNames.Password, '');
            serverSettingsStore.passwordInputHasEye = true;
        }

        if (authType === AuthTypes.Modern) {
            serverSettingsStore.isOAuth2Authorized = !isDirty;
        }
    };

    const basicSettingsHandler = (deactivateDialogCb: UseModalActions['deactivate']): void => {
        const { host, port, useAuth, smtp: { passwordCredential, timeout, tlsMode } } = serverSettingsStore.storeFormData;

        const emailServerSmtpSettings: SmtpSettings = {
            tlsMode,
            timeout,
            oAuth2Credential: null,
            serverAddress: `${UrlProtocols.Smtp}${host}:${port}`,
        };

        if (useAuth) {
            emailServerSmtpSettings.passwordCredential = {
                userName: passwordCredential.userName,
                password: passwordCredential.password,
            };
        }

        setIsLoading(true);

        core.transportService
            .post<SmtpSettings, SmtpSettings>(
                `/organizations/${organizationUid}/configuration/notification/smtp/test`,
                emailServerSmtpSettings,
                undefined,
                { notShowDefaultError: true }
            )
            .then((testResponse) => {
                const useSSL = tlsMode !== SmtpSettings.TlsModeEnum.None;
                const { data: testResponseData } = (testResponse as BaseSuccessRequestResponse<SmtpSettings>);
                const {
                    serverCertificate,
                    exclusivelyAcceptedCertificateHash,
                } = (serverSettingsStore.emailServerTestSmtpSettings = testResponseData);

                const newEmailServerSmtpSettings: NotificationSettings = {
                    ...originalEmailServerSettings,
                    smtp: emailServerSmtpSettings,
                };

                if (!useSSL || !serverCertificate) {
                    return saveEmailServerSettings(
                        originalEmailServerSettings,
                        newEmailServerSmtpSettings,
                        deactivateDialogCb,
                    );
                }

                const originalServerAddress = originalEmailServerSettings.smtp?.serverAddress;
                const { host: originalHost } = serverSettingsStore.getHostAndPortFromURL(originalServerAddress);
                const originalCertificateThumbprint = originalEmailServerSettings.smtp?.exclusivelyAcceptedCertificateHash;

                const isValidServerCert = serverCertificate.isValid;
                const hashesMatch = originalCertificateThumbprint === serverCertificate.hash;
                const isHashesUntrustedCert = !isValidServerCert && !hashesMatch;
                const isHostsUntrustedCert = !isValidServerCert && hashesMatch && (originalHost !== host);
                const isUntrustedCert = isHashesUntrustedCert || isHostsUntrustedCert;

                if (isUntrustedCert) {
                    newEmailServerSmtpSettings.smtp.exclusivelyAcceptedCertificateHash = exclusivelyAcceptedCertificateHash;

                    return untrustedCertDialogActions.activate({
                        onSaveCallback() {
                            saveEmailServerSettings(
                                originalEmailServerSettings,
                                newEmailServerSmtpSettings,
                                deactivateDialogCb,
                            );
                        },
                    });
                }

                return saveEmailServerSettings(
                    originalEmailServerSettings,
                    newEmailServerSmtpSettings,
                    deactivateDialogCb,
                );
            })
            .catch(error => core.responseHandlerService.handleErrors(error, dialogTitle))
            .finally(() => setIsLoading(false));
    };

    const modernSettingsHandler = (smtpSettings: SmtpFormData): void => {
        const redirectUrl = serverSettingsStore.redirectUrl;
        const { oAuth2Credential: { clientSettings } } = smtpSettings;
        const isAzureModernType = clientSettings.kind === OAuth2ClientSettings.KindEnum.Azure;
        const stateValue = isAzureModernType
            ? `${URL_PARAMS.SmtpOAuthAzureRedirectFlag}${userRole}`
            : `${URL_PARAMS.SmtpOAuthGoogleRedirectFlag}${userRole}`;

        const oAuth2Settings: Oauth2SigninBody = {
            redirectUrl,
            state: stateValue,
            clientSettings: {
                kind: clientSettings.kind,
                clientId: clientSettings.clientId,
                clientSecret: clientSettings.clientSecret,
                tenantId: isAzureModernType ? clientSettings.tenantId : null,
            },
        };

        core.transportService
            .post<Oauth2SigninBody, OAuth2SignInParameters>(
                `/organizations/${organizationUid}/configuration/notification/oauth2/signin`,
                oAuth2Settings,
                undefined,
                { notShowDefaultError: true }
            )
            .then((signInResponse: BaseSuccessRequestResponse<OAuth2SignInParameters>) => {
                const { data: { location } } = signInResponse;
                window.location.assign(location);
            })
            .catch(error => core.responseHandlerService.handleErrors(error, dialogTitle));
    };

    const onSaveActionClick = (deactivateDialogCb: UseModalActions['deactivate']): void => {
        const formApi = serverSettingsStore.storeFormApi;
        const formData = serverSettingsStore.storeFormData;

        trimData(formData);

        const { smtp, authType } = formData;
        const isBasicType = authType === AuthTypes.Basic;
        const isDirty = formApi.isDataChanged();

        if (!isDirty && isBasicType) {
            deactivateDialogCb();
        } else if (formApi.validate()) {
            if (isBasicType) {
                basicSettingsHandler(deactivateDialogCb);
            } else {
                modernSettingsHandler(smtp);
            }
        } else {
            validationState.markAsForce();
        }
    };

    const trimData = (data: EmailServerSettingsFormData): void => {
        trimTextFields<EmailServerSettingsFormData>(data, ['host']);
        trimTextFields<OAuth2ClientSettings>(data.smtp.oAuth2Credential.clientSettings, ['clientId', 'clientSecret', 'tenantId']);
    };

    return {
        dialogTitle,
        onFormChange,
        onSaveActionClick,
    };
};
