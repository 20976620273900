/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';

import { ThemeProvider } from '../ThemeProvider';
import { LangProvider } from '../LangProvider';
import { NotificationProvider } from '../NotificationProvider';
import { AppStorageProvider } from '../AppStorageProvider';
import { ConfiguratorProvider } from '../ConfiguratorProvider';
import { GlobalFiltersProvider } from '../GlobalFiltersProvider';

export interface PageProviderProps {
    children: React.ReactElement;
    withoutConfigurator?: boolean;
}

export const PageProvider: React.FC<PageProviderProps> = ({ children, withoutConfigurator }) => (
    <ThemeProvider>
        <LangProvider>
            <NotificationProvider>
                <AppStorageProvider>
                    <ConfiguratorProvider withoutConfigurator={withoutConfigurator}>
                        <GlobalFiltersProvider>
                            {children}
                        </GlobalFiltersProvider>
                    </ConfiguratorProvider>
                </AppStorageProvider>
            </NotificationProvider>
        </LangProvider>
    </ThemeProvider>
);
