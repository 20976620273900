/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import {
    colors,
    ComboboxKit,
    constants,
    CONTROL_SIZE,
    createSearchableControl,
    INDENT,
    LinkButton,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TitleH2,
    useAppStorage,
} from '@veeam-vspc/components';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import upIcon from 'images/actions/up.svg';
import { useMapStore } from 'views/pages/OverviewPage/hooks/use-map-store';
import { MapWrapper } from '../MapWrapper';
import { getMapOptions } from 'views/pages/OverviewPage/components/StateByRegions/components/RegionsMap/get-map-options';
import { useLang } from 'views/providers/LangProvider/hooks';
import { UnspecifiedCompaniesWidget } from '../UnspecifiedCompaniesWidget';

const REGION_KEY = 'overview.stateByRegion.region';

export const RegionsMap: React.FC = () => {
    const appStorage = useAppStorage();
    const region = appStorage.getItem(REGION_KEY);
    const mapStore = useMapStore({
        initialMapId: region,
        onMapChange: handleMapChange,
    });
    const { unspecifiedCompanies, loading } = mapStore;
    const lang = useLang();
    const mapOptions: Highcharts.Options = getMapOptions(mapStore, lang);

    function handleMapChange(mapId: string) {
        appStorage.setItem(REGION_KEY, mapId);
    }

    return (
        <StackView
            direction={STACK_DIRECTION.column}
            spaceAll={INDENT.m}
            style={{
                backgroundColor: colors.G000,
                flex: 1,
            }}
        >
            <TitleH2 style={{ marginBottom: constants.SPACING_M }}>{lang.COMPANIES_STATE_BY_REGIONS}</TitleH2>

            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                <StackView
                    gap={STACK_GAP.m}
                >
                    <ComboboxKit
                        value={mapStore.mapId}
                        onChange={mapStore.downloadMap}
                        data={mapStore.availableMaps}
                        size={CONTROL_SIZE.full}
                        disabled={mapStore.availableMaps.length === 0}
                        valueGetter={item => item.id as string}
                        textGetter={item => item.name}
                        controlRenderer={createSearchableControl()}
                        placeholder={lang.CHOOSE_REGION_WITH_MANAGED_COMPANIES}
                    />

                    <LinkButton
                        iconBefore={upIcon}
                        ref={mapStore.upButtonRef}
                        onClick={mapStore.upMap}
                        className='up-region-button'
                        disabled={mapStore.isWorldMap}
                    >
                        {lang.GO_BACK}
                    </LinkButton>
                </StackView>

                <UnspecifiedCompaniesWidget
                    unspecifiedCompanies={unspecifiedCompanies}
                    style={{ visibility: loading || unspecifiedCompanies.total === 0 ? 'hidden' : 'visible' }}
                />
            </StackView>

            <MapWrapper>
                <HighchartsReact
                    constructorType={'mapChart'}
                    highcharts={Highcharts}
                    options={mapOptions}
                    ref={mapStore.mapChartRef}
                />
            </MapWrapper>
        </StackView>
    );
};
