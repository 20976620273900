/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { AppContext } from '../stores';

import type { AppContextData } from '../interfaces';
import type { AppStore } from '../stores';

export const useAppStore = (): AppStore => useContext<AppContextData>(AppContext).data;
